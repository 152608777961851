// package: unifiedplatform.gamification
// file: gamification.proto

var gamification_pb = require("./gamification_pb");
var Badge_pb = require("./Badge_pb");
var Goal_pb = require("./Goal_pb");
var program_pb = require("./program_pb");
var common_common_pb = require("./common/common_pb");
var google_protobuf_wrappers_pb = require("google-protobuf/google/protobuf/wrappers_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var GamificationService = (function () {
  function GamificationService() {}
  GamificationService.serviceName = "unifiedplatform.gamification.GamificationService";
  return GamificationService;
}());

GamificationService.Test = {
  methodName: "Test",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.Empty,
  responseType: gamification_pb.TestResponse
};

GamificationService.AddNewBadge = {
  methodName: "AddNewBadge",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: Badge_pb.AddNewBadgeRequest,
  responseType: Badge_pb.Badge
};

GamificationService.GetAllBadges = {
  methodName: "GetAllBadges",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.Empty,
  responseType: Badge_pb.GetAllBadgesResponse
};

GamificationService.DeleteBadgeById = {
  methodName: "DeleteBadgeById",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: Badge_pb.DeleteBadgeByIdRequest,
  responseType: Badge_pb.DeleteBadgeByIdResponse
};

GamificationService.UpdateBadgeById = {
  methodName: "UpdateBadgeById",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: Badge_pb.UpdateBadgeByIdRequest,
  responseType: Badge_pb.Badge
};

GamificationService.FindDefaultBadge = {
  methodName: "FindDefaultBadge",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.Empty,
  responseType: Badge_pb.Badge
};

GamificationService.AddNewGoal = {
  methodName: "AddNewGoal",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: Goal_pb.AddNewGoalRequest,
  responseType: gamification_pb.ResponseGoal
};

GamificationService.UpdateGoal = {
  methodName: "UpdateGoal",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: Goal_pb.Goal,
  responseType: gamification_pb.ResponseGoal
};

GamificationService.GetAllGoals = {
  methodName: "GetAllGoals",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.Empty,
  responseType: gamification_pb.GetAllGoalsResponse
};

GamificationService.DeleteGoalByID = {
  methodName: "DeleteGoalByID",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.DeleteGoalByIdRequest,
  responseType: gamification_pb.DeleteGoalByIdResponse
};

GamificationService.UploadFile = {
  methodName: "UploadFile",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.UploadFileRequest,
  responseType: gamification_pb.UploadFileResponse
};

GamificationService.DeleteFileFromGCP = {
  methodName: "DeleteFileFromGCP",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.DeleteFileRequest,
  responseType: gamification_pb.ResponseStatus
};

GamificationService.GetBadgeByID = {
  methodName: "GetBadgeByID",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.RequestGetBadgeByID,
  responseType: Badge_pb.Badge
};

GamificationService.GetGoalByID = {
  methodName: "GetGoalByID",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.GetGoalByIdRequest,
  responseType: gamification_pb.ResponseGoal
};

GamificationService.GetBadgeByName = {
  methodName: "GetBadgeByName",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.GetBadgeByNameRequest,
  responseType: gamification_pb.GetBadgeByNameResponse
};

GamificationService.AddNewBadgeInfo = {
  methodName: "AddNewBadgeInfo",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: Badge_pb.AddNewBadgeInfoRequest,
  responseType: Badge_pb.BadgeDetails
};

GamificationService.UpdateBadgeInfoById = {
  methodName: "UpdateBadgeInfoById",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: Badge_pb.BadgeDetails,
  responseType: Badge_pb.BadgeDetails
};

GamificationService.DeleteBadgeDetailsById = {
  methodName: "DeleteBadgeDetailsById",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: Badge_pb.DeleteBadgeByIdRequest,
  responseType: Badge_pb.DeleteBadgeDetailsByIdResponse
};

GamificationService.GetBadgeDetailsByID = {
  methodName: "GetBadgeDetailsByID",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.RequestGetBadgeByID,
  responseType: Badge_pb.Badge
};

GamificationService.GetBadgeDetailsByName = {
  methodName: "GetBadgeDetailsByName",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.GetBadgeDetailsByNameRequest,
  responseType: gamification_pb.GetBadgeByNameResponse
};

GamificationService.GetAllBadgesDetails = {
  methodName: "GetAllBadgesDetails",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.RequestForGetAllBadgeDetails,
  responseType: Badge_pb.GetAllBadgesDetailsResponse
};

GamificationService.AddNewGoalDetails = {
  methodName: "AddNewGoalDetails",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: Goal_pb.AddNewGoalDetailsRequest,
  responseType: gamification_pb.ResponseGoalDetails
};

GamificationService.UpdateGoalDetails = {
  methodName: "UpdateGoalDetails",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: Goal_pb.GoalDetails,
  responseType: gamification_pb.ResponseGoalDetails
};

GamificationService.GetAllGoalsDetails = {
  methodName: "GetAllGoalsDetails",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.RequestForGetAllGoalDetails,
  responseType: gamification_pb.GetAllGoalDetailsResponse
};

GamificationService.GetAllDefaultGoalDetails = {
  methodName: "GetAllDefaultGoalDetails",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.Empty,
  responseType: gamification_pb.GetAllGoalDetailsResponse
};

GamificationService.DeleteGoalDetailsByID = {
  methodName: "DeleteGoalDetailsByID",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.DeleteGoalByIdRequest,
  responseType: gamification_pb.DeleteGoalDetailsByIdResponse
};

GamificationService.GetGoalDetailsByID = {
  methodName: "GetGoalDetailsByID",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.GetGoalByIdRequestWithSelectedLanguage,
  responseType: gamification_pb.ResponseGoal
};

GamificationService.AddNewGoalInfoV2 = {
  methodName: "AddNewGoalInfoV2",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.AddNewGoalInfoV2Request,
  responseType: gamification_pb.ResponseGoalInfoV2
};

GamificationService.UpdateGoalInfoV2 = {
  methodName: "UpdateGoalInfoV2",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.updateGoalInfoV2Request,
  responseType: gamification_pb.ResponseGoalInfoV2
};

GamificationService.GetAllGoalInfoV2 = {
  methodName: "GetAllGoalInfoV2",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.GetAllGoalInfoV2Request,
  responseType: gamification_pb.ResponseForGetAllGoalInfoV2
};

GamificationService.DeleteGoalInfoV2ByID = {
  methodName: "DeleteGoalInfoV2ByID",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.ID,
  responseType: gamification_pb.DeleteGoalInfoV2ByIdResponse
};

GamificationService.GetGoalInfoV2ById = {
  methodName: "GetGoalInfoV2ById",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.GetGoalInfoV2ByIdRequest,
  responseType: gamification_pb.GetGoalInfoV2Response
};

GamificationService.GetAllGoalInfoV2ForAdminV2 = {
  methodName: "GetAllGoalInfoV2ForAdminV2",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.RequestPageable,
  responseType: gamification_pb.GetAllGoalInfoV2ResponseForAdminV2
};

GamificationService.GetAllBadgeInfoDetailsForAdmin = {
  methodName: "GetAllBadgeInfoDetailsForAdmin",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.RequestPageable,
  responseType: gamification_pb.GetAllBadgeDetailsForAdminResponse
};

GamificationService.AddGoalCategory = {
  methodName: "AddGoalCategory",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.AddGoalCategoryDetailsRequest,
  responseType: gamification_pb.GoalCategoryDetailsResponse
};

GamificationService.UpdateGoalCategory = {
  methodName: "UpdateGoalCategory",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.ResponseForUpdateGoalCategory,
  responseType: gamification_pb.Status
};

GamificationService.GetAllGoalStatusByUser = {
  methodName: "GetAllGoalStatusByUser",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.GetAllGoalStatusByUserRequest,
  responseType: gamification_pb.GetAllGoalStatusByUserResponse
};

GamificationService.GetGoalCountByUser = {
  methodName: "GetGoalCountByUser",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.ID,
  responseType: gamification_pb.GetGoalStatusCount
};

GamificationService.GetUserAcceptedGoalIds = {
  methodName: "GetUserAcceptedGoalIds",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.GetAllGoalStatusByUserRequest,
  responseType: gamification_pb.ListOfIDs
};

GamificationService.EndGoalById = {
  methodName: "EndGoalById",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.ID,
  responseType: gamification_pb.Status
};

GamificationService.CreateDonation = {
  methodName: "CreateDonation",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.DonationCreateRequest,
  responseType: gamification_pb.DonationDetails
};

GamificationService.UpdateDonation = {
  methodName: "UpdateDonation",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.DonationDetails,
  responseType: gamification_pb.DonationDetails
};

GamificationService.DeleteDonationByID = {
  methodName: "DeleteDonationByID",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.ID,
  responseType: gamification_pb.ResponseStatus
};

GamificationService.GetDonationByID = {
  methodName: "GetDonationByID",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.GetGoalInfoV2ByIdRequest,
  responseType: gamification_pb.DonationResponseWithLocale
};

GamificationService.GetAllDonationDetailsForAdmin = {
  methodName: "GetAllDonationDetailsForAdmin",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.RequestPageable,
  responseType: gamification_pb.ListOfDonationDetailsResponse
};

GamificationService.DeleteGoalCategoryById = {
  methodName: "DeleteGoalCategoryById",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.AddGoalCategoryDetailsRequest,
  responseType: gamification_pb.Status
};

GamificationService.AddMoodDetails = {
  methodName: "AddMoodDetails",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.AddMoodRequest,
  responseType: gamification_pb.Status
};

GamificationService.GetAllMoodDetails = {
  methodName: "GetAllMoodDetails",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.Empty,
  responseType: gamification_pb.ListOfMoodDetailsResponse
};

GamificationService.UpdateMoodDetails = {
  methodName: "UpdateMoodDetails",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.MoodDetails,
  responseType: gamification_pb.MoodDetails
};

GamificationService.DeleteMood = {
  methodName: "DeleteMood",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.ID,
  responseType: gamification_pb.Status
};

GamificationService.GetMoodDetailsByID = {
  methodName: "GetMoodDetailsByID",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.ID,
  responseType: gamification_pb.MoodDetails
};

GamificationService.GetMoodDetailsByIDWithLocale = {
  methodName: "GetMoodDetailsByIDWithLocale",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.MoodIdWithSelectedLanguageRequest,
  responseType: gamification_pb.MoodDetailsWithSelectedLanguageResponse
};

GamificationService.GetAllMoodListWithSelectedLanguage = {
  methodName: "GetAllMoodListWithSelectedLanguage",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.LocaleType,
  responseType: gamification_pb.MoodDetailsListWithLocaleResponse
};

GamificationService.AddContent = {
  methodName: "AddContent",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.ContentRequest,
  responseType: gamification_pb.ContentResponse
};

GamificationService.UpdateContent = {
  methodName: "UpdateContent",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.ContentResponse,
  responseType: gamification_pb.ContentResponse
};

GamificationService.GetContentByID = {
  methodName: "GetContentByID",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.GetGoalByIdRequestWithSelectedLanguage,
  responseType: gamification_pb.ContentLocaleResponse
};

GamificationService.DeleteContentByIDAndCategory = {
  methodName: "DeleteContentByIDAndCategory",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.ContentCategoryRequest,
  responseType: gamification_pb.Status
};

GamificationService.GetAllContent = {
  methodName: "GetAllContent",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.GetAllContentRequest,
  responseType: gamification_pb.ListOfContentResponse
};

GamificationService.GetContentByCategoryAndId = {
  methodName: "GetContentByCategoryAndId",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.ContentCategoryRequest,
  responseType: gamification_pb.ContentLocaleResponse
};

GamificationService.GetContentByCategory = {
  methodName: "GetContentByCategory",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.ContentCategoryRequest,
  responseType: gamification_pb.ListOfContentResponse
};

GamificationService.GetSelfDevelopmentContentByCategory = {
  methodName: "GetSelfDevelopmentContentByCategory",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.SelfDevelopmentContentRequest,
  responseType: gamification_pb.SelfDevelopmentContentResponse
};

GamificationService.GetSelfDevelopmentContentWithAllLanguage = {
  methodName: "GetSelfDevelopmentContentWithAllLanguage",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.SelfDevelopmentContentRequest,
  responseType: gamification_pb.SelfDevelopmentContentResponseWithAllLanguage
};

GamificationService.GetContentByCategories = {
  methodName: "GetContentByCategories",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.ContentCategoriesRequest,
  responseType: gamification_pb.ContentCategoriesResponse
};

GamificationService.AddPersonalityQuiz = {
  methodName: "AddPersonalityQuiz",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.PersonalityQuizRequest,
  responseType: gamification_pb.PersonalityQuizResponse
};

GamificationService.UpdatePersonalityQuiz = {
  methodName: "UpdatePersonalityQuiz",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.PersonalityQuizResponse,
  responseType: gamification_pb.PersonalityQuizResponse
};

GamificationService.GetPersonalityQuizByID = {
  methodName: "GetPersonalityQuizByID",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.GetGoalByIdRequestWithSelectedLanguage,
  responseType: gamification_pb.PersonalityQuizLocale
};

GamificationService.GetAllPersonalityQuiz = {
  methodName: "GetAllPersonalityQuiz",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.RequestPageable,
  responseType: gamification_pb.ListOfPersonalityQuizResponse
};

GamificationService.DeletePersonalityQuizByID = {
  methodName: "DeletePersonalityQuizByID",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.DeleteGoalByIdRequest,
  responseType: gamification_pb.Status
};

GamificationService.AddQuotes = {
  methodName: "AddQuotes",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.AddQuoteRequest,
  responseType: gamification_pb.Status
};

GamificationService.GetAllQuotes = {
  methodName: "GetAllQuotes",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.RequestPageable,
  responseType: gamification_pb.ListOfQuoteDetails
};

GamificationService.UpdateQuotes = {
  methodName: "UpdateQuotes",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.QuoteDetails,
  responseType: gamification_pb.QuoteDetails
};

GamificationService.DeleteQuotes = {
  methodName: "DeleteQuotes",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.ID,
  responseType: gamification_pb.Status
};

GamificationService.BulkUploadQuotes = {
  methodName: "BulkUploadQuotes",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.URLRequest,
  responseType: gamification_pb.BulkUploadQuoteResponse
};

GamificationService.GetQuoteById = {
  methodName: "GetQuoteById",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.ID,
  responseType: gamification_pb.QuoteDetails
};

GamificationService.GetQuoteByRandom = {
  methodName: "GetQuoteByRandom",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.Empty,
  responseType: gamification_pb.QuoteDetails
};

GamificationService.GetQuoteByScreenName = {
  methodName: "GetQuoteByScreenName",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.ScreenNameRequest,
  responseType: gamification_pb.QuoteDetails
};

GamificationService.AddContentFilter = {
  methodName: "AddContentFilter",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.ContentFilterRequest,
  responseType: gamification_pb.ContentFilterResponse
};

GamificationService.UpdateContentFilter = {
  methodName: "UpdateContentFilter",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.ContentFilterResponse,
  responseType: gamification_pb.ContentFilterResponse
};

GamificationService.DeleteContentFilter = {
  methodName: "DeleteContentFilter",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.ID,
  responseType: gamification_pb.Status
};

GamificationService.GetAllContentFilter = {
  methodName: "GetAllContentFilter",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.LocaleType,
  responseType: gamification_pb.GetAllContentFilterResponse
};

GamificationService.GetAllContentFilterForAdmin = {
  methodName: "GetAllContentFilterForAdmin",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.RequestPageable,
  responseType: gamification_pb.GetAllContentFilterResponse
};

GamificationService.AddMoodTrigger = {
  methodName: "AddMoodTrigger",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.MoodTriggerRequest,
  responseType: gamification_pb.MoodTriggerResponse
};

GamificationService.UpdateMoodTrigger = {
  methodName: "UpdateMoodTrigger",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.MoodTriggerResponse,
  responseType: gamification_pb.MoodTriggerResponse
};

GamificationService.DeleteMoodTrigger = {
  methodName: "DeleteMoodTrigger",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.ID,
  responseType: gamification_pb.Status
};

GamificationService.GetAllMoodTrigger = {
  methodName: "GetAllMoodTrigger",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.LocaleType,
  responseType: gamification_pb.GetAllMoodTriggerResponse
};

GamificationService.GetAllMoodTriggerForAdmin = {
  methodName: "GetAllMoodTriggerForAdmin",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.RequestPageable,
  responseType: gamification_pb.GetAllMoodTriggerResponse
};

GamificationService.GetQuoteByRandomWithLocale = {
  methodName: "GetQuoteByRandomWithLocale",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.LocaleType,
  responseType: gamification_pb.QuoteDetailsWithLocaleSupport
};

GamificationService.GetQuoteByScreenNameWithLocale = {
  methodName: "GetQuoteByScreenNameWithLocale",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.ScreenNameLocaleTypeRequest,
  responseType: gamification_pb.QuoteDetailsWithLocaleSupport
};

GamificationService.AddFAQs = {
  methodName: "AddFAQs",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.FAQsRequest,
  responseType: gamification_pb.FAQsResponse
};

GamificationService.UpdateFAQs = {
  methodName: "UpdateFAQs",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.FAQsResponse,
  responseType: gamification_pb.FAQsResponse
};

GamificationService.DeleteFAQsByID = {
  methodName: "DeleteFAQsByID",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.ID,
  responseType: gamification_pb.Status
};

GamificationService.GetAllFAQs = {
  methodName: "GetAllFAQs",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.RequestPageable,
  responseType: gamification_pb.GetAllFAQsResponse
};

GamificationService.GetAllFAQsWithLanguageSupport = {
  methodName: "GetAllFAQsWithLanguageSupport",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.LocaleType,
  responseType: gamification_pb.GetAllFAQsWithLanguageSupportResponse
};

GamificationService.CreateOrUpdateContentTags = {
  methodName: "CreateOrUpdateContentTags",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.Tag,
  responseType: gamification_pb.Tag
};

GamificationService.GetAllContentTags = {
  methodName: "GetAllContentTags",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.RequestPageable,
  responseType: gamification_pb.TagsList
};

GamificationService.GetContentByTag = {
  methodName: "GetContentByTag",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.Tag,
  responseType: gamification_pb.TagWithContent
};

GamificationService.AddOrUpdateMultipleTags = {
  methodName: "AddOrUpdateMultipleTags",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.Tags,
  responseType: gamification_pb.Tags
};

GamificationService.GetRecentlySharedDocumentsForPreceptor = {
  methodName: "GetRecentlySharedDocumentsForPreceptor",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.SharedInfoRequest,
  responseType: gamification_pb.ListOfAllContentLocaleResponse
};

GamificationService.ShareDocumentToSeekers = {
  methodName: "ShareDocumentToSeekers",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.SeekerInfoRequest,
  responseType: google_protobuf_wrappers_pb.BoolValue
};

GamificationService.GetListOfContentByIdsAndContentCategories = {
  methodName: "GetListOfContentByIdsAndContentCategories",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.ContentCategoryWithListOfContentIds,
  responseType: gamification_pb.ListOfContentResponse
};

GamificationService.DeleteGoalsByUserId = {
  methodName: "DeleteGoalsByUserId",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.ID,
  responseType: gamification_pb.Status
};

GamificationService.ScheduleGoal = {
  methodName: "ScheduleGoal",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.RequestToScheduleGoal,
  responseType: gamification_pb.Status
};

GamificationService.LogMeditation = {
  methodName: "LogMeditation",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.TrackerInfo,
  responseType: gamification_pb.TrackerWithBadge
};

GamificationService.UpdateUserTrackerDetailsV2 = {
  methodName: "UpdateUserTrackerDetailsV2",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.TrackerInfo,
  responseType: gamification_pb.TrackerWithBadge
};

GamificationService.CreateCustomGoals = {
  methodName: "CreateCustomGoals",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.CustomGoalRequest,
  responseType: gamification_pb.CustomGoalResponse
};

GamificationService.GetAllGoalsForMySignUpV2 = {
  methodName: "GetAllGoalsForMySignUpV2",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.RequestV2GetAllMySignUp,
  responseType: gamification_pb.ResponseV2GetAllMySignUp
};

GamificationService.GetMeditationCategoryForDate = {
  methodName: "GetMeditationCategoryForDate",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.MeditationCategoryForDate,
  responseType: gamification_pb.MeditationCategoryForDate
};

GamificationService.GetGoalCardsForHomePage = {
  methodName: "GetGoalCardsForHomePage",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.RequestV2GetAllMySignUp,
  responseType: gamification_pb.GetAllGoalStatusByUserResponse
};

GamificationService.MeditationTrackerStatusById = {
  methodName: "MeditationTrackerStatusById",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.RequestGoalByUserId,
  responseType: gamification_pb.MeditationTrackerStatusResponse
};

GamificationService.GetGoalStatusByUserId = {
  methodName: "GetGoalStatusByUserId",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.RequestGoalByUserId,
  responseType: gamification_pb.ResponseGoalInfoV2
};

GamificationService.GetWeeklyStatsForGoals = {
  methodName: "GetWeeklyStatsForGoals",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.RequestUserWeeklyStats,
  responseType: gamification_pb.GetGoalStatusCount
};

GamificationService.CreateProgram = {
  methodName: "CreateProgram",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: program_pb.ProgramDetails,
  responseType: program_pb.ProgramDetails
};

GamificationService.UpdateProgram = {
  methodName: "UpdateProgram",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: program_pb.ProgramDetails,
  responseType: program_pb.ProgramDetails
};

GamificationService.GetProgramById = {
  methodName: "GetProgramById",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.IdAndSelectedLanguageRequest,
  responseType: program_pb.ProgramWithSelectedLanguage
};

GamificationService.DeleteProgram = {
  methodName: "DeleteProgram",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.ID,
  responseType: program_pb.ProgramDetails
};

GamificationService.GetAllPrograms = {
  methodName: "GetAllPrograms",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: program_pb.GetAllProgramsRequest,
  responseType: program_pb.ProgramList
};

GamificationService.RegisterProgram = {
  methodName: "RegisterProgram",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: program_pb.RegisterProgram,
  responseType: gamification_pb.Status
};

GamificationService.IsUserRegisteredForProgram = {
  methodName: "IsUserRegisteredForProgram",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.ID,
  responseType: gamification_pb.Status
};

GamificationService.CreateTask = {
  methodName: "CreateTask",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: program_pb.TaskDetails,
  responseType: program_pb.TaskDetails
};

GamificationService.UpdateTask = {
  methodName: "UpdateTask",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: program_pb.TaskDetails,
  responseType: program_pb.TaskDetails
};

GamificationService.GetTaskById = {
  methodName: "GetTaskById",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.IdAndSelectedLanguageRequest,
  responseType: program_pb.TaskWithSelectedLanguage
};

GamificationService.DeleteTask = {
  methodName: "DeleteTask",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.ID,
  responseType: program_pb.TaskDetails
};

GamificationService.GetTaskList = {
  methodName: "GetTaskList",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: common_common_pb.RequestPageable,
  responseType: program_pb.ListOfTask
};

GamificationService.GetTaskListBetweenDates = {
  methodName: "GetTaskListBetweenDates",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.RequestBetweenDates,
  responseType: program_pb.ListOfTask
};

GamificationService.AddProgramTaskTrackerDetails = {
  methodName: "AddProgramTaskTrackerDetails",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: program_pb.ProgramTaskTrackerDetails,
  responseType: program_pb.ProgramTaskTrackerDetails
};

GamificationService.GetProgramWithTasksAndTheme = {
  methodName: "GetProgramWithTasksAndTheme",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: program_pb.ProgramWithTaskAndThemeRequest,
  responseType: program_pb.ProgramWithTaskAndTheme
};

GamificationService.CreateTheme = {
  methodName: "CreateTheme",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: program_pb.ThemeDetails,
  responseType: program_pb.ThemeDetails
};

GamificationService.UpdateTheme = {
  methodName: "UpdateTheme",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: program_pb.ThemeDetails,
  responseType: program_pb.ThemeDetails
};

GamificationService.DeleteTheme = {
  methodName: "DeleteTheme",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.ID,
  responseType: program_pb.ThemeDetails
};

GamificationService.GetThemeById = {
  methodName: "GetThemeById",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.ID,
  responseType: program_pb.ThemeDetails
};

GamificationService.GetAllThemes = {
  methodName: "GetAllThemes",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: program_pb.GetAllThemesRequest,
  responseType: program_pb.ThemeList
};

GamificationService.GetUserBadgesByMeditationHours = {
  methodName: "GetUserBadgesByMeditationHours",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.GetUserBadgesByMeditationHoursRequest,
  responseType: Badge_pb.GetAllBadgesDetailsResponse
};

GamificationService.CreateCampaign = {
  methodName: "CreateCampaign",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.CampaignDetails,
  responseType: gamification_pb.CampaignDetails
};

GamificationService.UpdateCampaign = {
  methodName: "UpdateCampaign",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.CampaignDetails,
  responseType: gamification_pb.CampaignDetails
};

GamificationService.GetAllCampaign = {
  methodName: "GetAllCampaign",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.CampaignDetails,
  responseType: gamification_pb.CampaignDetailsList
};

GamificationService.DeleteCampaign = {
  methodName: "DeleteCampaign",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.ID,
  responseType: gamification_pb.Status
};

GamificationService.CreateCampaignChannel = {
  methodName: "CreateCampaignChannel",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.CampaignChannelDetails,
  responseType: gamification_pb.CampaignChannelDetails
};

GamificationService.UpdateCampaignChannel = {
  methodName: "UpdateCampaignChannel",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.CampaignChannelDetails,
  responseType: gamification_pb.CampaignChannelDetails
};

GamificationService.GetAllCampaignChannel = {
  methodName: "GetAllCampaignChannel",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.ID,
  responseType: gamification_pb.CampaignChannelDetailsList
};

GamificationService.DeleteCampaignChannel = {
  methodName: "DeleteCampaignChannel",
  service: GamificationService,
  requestStream: false,
  responseStream: false,
  requestType: gamification_pb.ID,
  responseType: gamification_pb.Status
};

exports.GamificationService = GamificationService;

function GamificationServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

GamificationServiceClient.prototype.test = function test(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.Test, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.addNewBadge = function addNewBadge(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.AddNewBadge, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getAllBadges = function getAllBadges(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetAllBadges, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.deleteBadgeById = function deleteBadgeById(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.DeleteBadgeById, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.updateBadgeById = function updateBadgeById(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.UpdateBadgeById, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.findDefaultBadge = function findDefaultBadge(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.FindDefaultBadge, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.addNewGoal = function addNewGoal(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.AddNewGoal, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.updateGoal = function updateGoal(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.UpdateGoal, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getAllGoals = function getAllGoals(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetAllGoals, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.deleteGoalByID = function deleteGoalByID(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.DeleteGoalByID, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.uploadFile = function uploadFile(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.UploadFile, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.deleteFileFromGCP = function deleteFileFromGCP(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.DeleteFileFromGCP, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getBadgeByID = function getBadgeByID(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetBadgeByID, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getGoalByID = function getGoalByID(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetGoalByID, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getBadgeByName = function getBadgeByName(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetBadgeByName, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.addNewBadgeInfo = function addNewBadgeInfo(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.AddNewBadgeInfo, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.updateBadgeInfoById = function updateBadgeInfoById(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.UpdateBadgeInfoById, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.deleteBadgeDetailsById = function deleteBadgeDetailsById(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.DeleteBadgeDetailsById, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getBadgeDetailsByID = function getBadgeDetailsByID(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetBadgeDetailsByID, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getBadgeDetailsByName = function getBadgeDetailsByName(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetBadgeDetailsByName, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getAllBadgesDetails = function getAllBadgesDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetAllBadgesDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.addNewGoalDetails = function addNewGoalDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.AddNewGoalDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.updateGoalDetails = function updateGoalDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.UpdateGoalDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getAllGoalsDetails = function getAllGoalsDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetAllGoalsDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getAllDefaultGoalDetails = function getAllDefaultGoalDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetAllDefaultGoalDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.deleteGoalDetailsByID = function deleteGoalDetailsByID(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.DeleteGoalDetailsByID, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getGoalDetailsByID = function getGoalDetailsByID(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetGoalDetailsByID, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.addNewGoalInfoV2 = function addNewGoalInfoV2(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.AddNewGoalInfoV2, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.updateGoalInfoV2 = function updateGoalInfoV2(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.UpdateGoalInfoV2, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getAllGoalInfoV2 = function getAllGoalInfoV2(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetAllGoalInfoV2, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.deleteGoalInfoV2ByID = function deleteGoalInfoV2ByID(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.DeleteGoalInfoV2ByID, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getGoalInfoV2ById = function getGoalInfoV2ById(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetGoalInfoV2ById, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getAllGoalInfoV2ForAdminV2 = function getAllGoalInfoV2ForAdminV2(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetAllGoalInfoV2ForAdminV2, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getAllBadgeInfoDetailsForAdmin = function getAllBadgeInfoDetailsForAdmin(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetAllBadgeInfoDetailsForAdmin, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.addGoalCategory = function addGoalCategory(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.AddGoalCategory, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.updateGoalCategory = function updateGoalCategory(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.UpdateGoalCategory, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getAllGoalStatusByUser = function getAllGoalStatusByUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetAllGoalStatusByUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getGoalCountByUser = function getGoalCountByUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetGoalCountByUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getUserAcceptedGoalIds = function getUserAcceptedGoalIds(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetUserAcceptedGoalIds, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.endGoalById = function endGoalById(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.EndGoalById, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.createDonation = function createDonation(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.CreateDonation, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.updateDonation = function updateDonation(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.UpdateDonation, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.deleteDonationByID = function deleteDonationByID(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.DeleteDonationByID, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getDonationByID = function getDonationByID(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetDonationByID, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getAllDonationDetailsForAdmin = function getAllDonationDetailsForAdmin(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetAllDonationDetailsForAdmin, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.deleteGoalCategoryById = function deleteGoalCategoryById(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.DeleteGoalCategoryById, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.addMoodDetails = function addMoodDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.AddMoodDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getAllMoodDetails = function getAllMoodDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetAllMoodDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.updateMoodDetails = function updateMoodDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.UpdateMoodDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.deleteMood = function deleteMood(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.DeleteMood, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getMoodDetailsByID = function getMoodDetailsByID(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetMoodDetailsByID, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getMoodDetailsByIDWithLocale = function getMoodDetailsByIDWithLocale(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetMoodDetailsByIDWithLocale, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getAllMoodListWithSelectedLanguage = function getAllMoodListWithSelectedLanguage(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetAllMoodListWithSelectedLanguage, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.addContent = function addContent(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.AddContent, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.updateContent = function updateContent(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.UpdateContent, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getContentByID = function getContentByID(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetContentByID, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.deleteContentByIDAndCategory = function deleteContentByIDAndCategory(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.DeleteContentByIDAndCategory, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getAllContent = function getAllContent(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetAllContent, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getContentByCategoryAndId = function getContentByCategoryAndId(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetContentByCategoryAndId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getContentByCategory = function getContentByCategory(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetContentByCategory, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getSelfDevelopmentContentByCategory = function getSelfDevelopmentContentByCategory(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetSelfDevelopmentContentByCategory, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getSelfDevelopmentContentWithAllLanguage = function getSelfDevelopmentContentWithAllLanguage(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetSelfDevelopmentContentWithAllLanguage, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getContentByCategories = function getContentByCategories(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetContentByCategories, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.addPersonalityQuiz = function addPersonalityQuiz(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.AddPersonalityQuiz, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.updatePersonalityQuiz = function updatePersonalityQuiz(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.UpdatePersonalityQuiz, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getPersonalityQuizByID = function getPersonalityQuizByID(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetPersonalityQuizByID, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getAllPersonalityQuiz = function getAllPersonalityQuiz(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetAllPersonalityQuiz, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.deletePersonalityQuizByID = function deletePersonalityQuizByID(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.DeletePersonalityQuizByID, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.addQuotes = function addQuotes(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.AddQuotes, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getAllQuotes = function getAllQuotes(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetAllQuotes, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.updateQuotes = function updateQuotes(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.UpdateQuotes, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.deleteQuotes = function deleteQuotes(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.DeleteQuotes, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.bulkUploadQuotes = function bulkUploadQuotes(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.BulkUploadQuotes, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getQuoteById = function getQuoteById(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetQuoteById, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getQuoteByRandom = function getQuoteByRandom(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetQuoteByRandom, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getQuoteByScreenName = function getQuoteByScreenName(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetQuoteByScreenName, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.addContentFilter = function addContentFilter(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.AddContentFilter, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.updateContentFilter = function updateContentFilter(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.UpdateContentFilter, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.deleteContentFilter = function deleteContentFilter(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.DeleteContentFilter, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getAllContentFilter = function getAllContentFilter(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetAllContentFilter, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getAllContentFilterForAdmin = function getAllContentFilterForAdmin(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetAllContentFilterForAdmin, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.addMoodTrigger = function addMoodTrigger(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.AddMoodTrigger, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.updateMoodTrigger = function updateMoodTrigger(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.UpdateMoodTrigger, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.deleteMoodTrigger = function deleteMoodTrigger(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.DeleteMoodTrigger, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getAllMoodTrigger = function getAllMoodTrigger(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetAllMoodTrigger, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getAllMoodTriggerForAdmin = function getAllMoodTriggerForAdmin(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetAllMoodTriggerForAdmin, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getQuoteByRandomWithLocale = function getQuoteByRandomWithLocale(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetQuoteByRandomWithLocale, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getQuoteByScreenNameWithLocale = function getQuoteByScreenNameWithLocale(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetQuoteByScreenNameWithLocale, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.addFAQs = function addFAQs(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.AddFAQs, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.updateFAQs = function updateFAQs(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.UpdateFAQs, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.deleteFAQsByID = function deleteFAQsByID(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.DeleteFAQsByID, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getAllFAQs = function getAllFAQs(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetAllFAQs, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getAllFAQsWithLanguageSupport = function getAllFAQsWithLanguageSupport(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetAllFAQsWithLanguageSupport, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.createOrUpdateContentTags = function createOrUpdateContentTags(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.CreateOrUpdateContentTags, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getAllContentTags = function getAllContentTags(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetAllContentTags, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getContentByTag = function getContentByTag(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetContentByTag, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.addOrUpdateMultipleTags = function addOrUpdateMultipleTags(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.AddOrUpdateMultipleTags, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getRecentlySharedDocumentsForPreceptor = function getRecentlySharedDocumentsForPreceptor(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetRecentlySharedDocumentsForPreceptor, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.shareDocumentToSeekers = function shareDocumentToSeekers(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.ShareDocumentToSeekers, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getListOfContentByIdsAndContentCategories = function getListOfContentByIdsAndContentCategories(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetListOfContentByIdsAndContentCategories, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.deleteGoalsByUserId = function deleteGoalsByUserId(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.DeleteGoalsByUserId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.scheduleGoal = function scheduleGoal(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.ScheduleGoal, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.logMeditation = function logMeditation(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.LogMeditation, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.updateUserTrackerDetailsV2 = function updateUserTrackerDetailsV2(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.UpdateUserTrackerDetailsV2, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.createCustomGoals = function createCustomGoals(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.CreateCustomGoals, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getAllGoalsForMySignUpV2 = function getAllGoalsForMySignUpV2(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetAllGoalsForMySignUpV2, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getMeditationCategoryForDate = function getMeditationCategoryForDate(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetMeditationCategoryForDate, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getGoalCardsForHomePage = function getGoalCardsForHomePage(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetGoalCardsForHomePage, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.meditationTrackerStatusById = function meditationTrackerStatusById(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.MeditationTrackerStatusById, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getGoalStatusByUserId = function getGoalStatusByUserId(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetGoalStatusByUserId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getWeeklyStatsForGoals = function getWeeklyStatsForGoals(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetWeeklyStatsForGoals, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.createProgram = function createProgram(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.CreateProgram, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.updateProgram = function updateProgram(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.UpdateProgram, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getProgramById = function getProgramById(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetProgramById, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.deleteProgram = function deleteProgram(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.DeleteProgram, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getAllPrograms = function getAllPrograms(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetAllPrograms, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.registerProgram = function registerProgram(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.RegisterProgram, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.isUserRegisteredForProgram = function isUserRegisteredForProgram(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.IsUserRegisteredForProgram, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.createTask = function createTask(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.CreateTask, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.updateTask = function updateTask(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.UpdateTask, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getTaskById = function getTaskById(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetTaskById, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.deleteTask = function deleteTask(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.DeleteTask, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getTaskList = function getTaskList(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetTaskList, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getTaskListBetweenDates = function getTaskListBetweenDates(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetTaskListBetweenDates, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.addProgramTaskTrackerDetails = function addProgramTaskTrackerDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.AddProgramTaskTrackerDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getProgramWithTasksAndTheme = function getProgramWithTasksAndTheme(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetProgramWithTasksAndTheme, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.createTheme = function createTheme(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.CreateTheme, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.updateTheme = function updateTheme(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.UpdateTheme, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.deleteTheme = function deleteTheme(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.DeleteTheme, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getThemeById = function getThemeById(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetThemeById, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getAllThemes = function getAllThemes(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetAllThemes, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getUserBadgesByMeditationHours = function getUserBadgesByMeditationHours(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetUserBadgesByMeditationHours, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.createCampaign = function createCampaign(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.CreateCampaign, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.updateCampaign = function updateCampaign(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.UpdateCampaign, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getAllCampaign = function getAllCampaign(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetAllCampaign, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.deleteCampaign = function deleteCampaign(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.DeleteCampaign, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.createCampaignChannel = function createCampaignChannel(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.CreateCampaignChannel, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.updateCampaignChannel = function updateCampaignChannel(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.UpdateCampaignChannel, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.getAllCampaignChannel = function getAllCampaignChannel(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.GetAllCampaignChannel, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GamificationServiceClient.prototype.deleteCampaignChannel = function deleteCampaignChannel(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GamificationService.DeleteCampaignChannel, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.GamificationServiceClient = GamificationServiceClient;

