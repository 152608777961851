/**
 * @author : Godwin Vinny Carole Kati
 * @created : 4141 28 2021, DDD, 01:04 A
 * @organisation : Codeprism Technologies Pvt Ltd
 **/ import {
  Card,
  CardActionArea,
  Button,
  Avatar,
  Box,
  CardContent,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  IconButton,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ImageOutlined, MusicNoteOutlined } from "@mui/icons-material";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { Alert } from "@mui/material";
import AudioPlayer from "components/audio-player/audio-player";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store/store";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { dataURLtoFile, getCroppedImg, toUploadFile } from "utils/helpers";
import { useFileUploadService } from "services/FileUplaodService";
import { UploadEventResponse } from "components/UploadManager/UploadListItem";
import ImageCrop from "components/image-crop/image-crop";
import PreviewProfileImage from "../../../components/image-preview/profileImagePreview";
import {
  MeditationFileDetails,
  DurationFile,
  ID,
} from "api/models/guided-meditation-service/guided-meditation_pb";
import { firebaseAuth } from "config/firebase";
import { GuidedMeditationService } from "api/models/guided-meditation-service/guided-meditation_pb_service";
import {
  GAMIFICATION_SERVICE_HOST,
  GUIDED_MEDITATION_SERVICE_HOST,
} from "api/serviceEndpoints";
import { grpc } from "@improbable-eng/grpc-web";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import DeleteIcon from "@mui/icons-material/Delete";
import DialogBox from "components/dialogBox/dialog";
import {
  getAllMeditation,
  getDurationDetailsbyID,
} from "redux/actions/guidedMeditationAction";
import { Locale } from "api/models/guided-meditation-service/common/common_pb";
import { GamificationService } from "api/models/gamification-service/gamification_pb_service";
import {
  DeleteFileRequest,
  ResponseStatus,
} from "api/models/gamification-service/gamification_pb";

const PREFIX = "UpdateGuidedMeditation";

const classes = {
  root: `${PREFIX}-root`,
  imgCardHeader: `${PREFIX}-imgCardHeader`,
  progress: `${PREFIX}-progress`,
  formBtn: `${PREFIX}-formBtn`,
  thumbNail: `${PREFIX}-thumbNail`,
  media: `${PREFIX}-media`,
  actionIcon: `${PREFIX}-actionIcon`,
  audioPlayer: `${PREFIX}-audioPlayer`,
  paper: `${PREFIX}-paper`,
  toggle: `${PREFIX}-toggle`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    height: 250,
    width: "100%",
    //overflow: "scroll",
  },

  [`& .${classes.imgCardHeader}`]: {
    height: "20%",
    textOverflow: "elipse",
  },

  [`& .${classes.progress}`]: {
    color: theme.palette.primary.main,
    position: "absolute",
    left: "50%",
  },

  [`& .${classes.formBtn}`]: {
    marginRight: "20px",
  },

  [`& .${classes.thumbNail}`]: {
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignContent: "cenrter",
    alignItems: "center",
  },

  [`& .${classes.media}`]: {
    height: "60%",
  },

  [`& .${classes.actionIcon}`]: {
    alignSelf: "center",
    left: "45%",
  },

  [`& .${classes.audioPlayer}`]: {
    height: "100%",
  },

  [`& .${classes.paper}`]: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    top: `40%`,
    left: `40%`,
  },

  [`& .${classes.toggle}`]: {
    "&.MuiToggleButton-root": {
      padding: "15px 11px",
    },
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
  },
}));

interface FormProps {
  isEditing?: boolean;
  editItem?: any;
  onClickBack?: any;
  pageNumber: number;
  pageSize: number;
}
interface GuidedMeditation {
  id?: any;
  durationfileid?: any;
  meditationtype?: 0 | 1 | 2 | 3 | 4;
  title?: any;
  subtitle?: any;
  description?: any;
  durationofmeditationclip?: number;
  tag?: string;
  rating?: number;
  likes?: number;
  imageUrl?: string;
  audiourl?: any;
  durationfile?: any;
}
interface Crop {
  unit: "%" | "px" | undefined;
  width: number;
  aspect: number;
}
interface State {
  editing?: boolean;
  editItem?: any;
  showDeleteModal?: boolean;
  deleteItemId?: number | null;
}
const meditationType = [
  "UNKNOWN_MEDITATION_TYPE",
  "MEDITATE_WITH_PURPOSE",
  "MEDITATE_WITH_GUIDANCE",
  "MEDITATE_WITH_PRECEPTOR",
  "MEDITATE_ANY_RECORDED_CONTEN",
];

export const UpdateGuidedMeditation = React.forwardRef<
  HTMLDivElement,
  FormProps
>((props, ref): ReactElement => {
  const dispatch = useDispatch();
  const languages = useSelector((state: RootState) => state.app.languages);
  const durationFile = useSelector(
    (state: RootState) =>
      state.guidedMeditation.meditationList.currentDurationFile
  );

  const hiddenFileInput: any = React.useRef(null);
  const hiddenSelectedImageInput: any = React.useRef(null);
  const hiddenAudioInput: any = React.useRef(null);

  const initialDeleteState = {
    showDeleteModal: false,
    deleteItemId: 0,
  };

  const cropDef: Crop = {
    unit: "%",
    width: 30,
    aspect: 16 / 16,
  };

  const [meditationId, setmeditationId] = useState<number>(0);
  const [durationId, setdurationId] = useState<number>(0);
  const [title, setTitle] = useState<any>("");
  const [duration, setDuration] = useState<any>(0);
  const [rating, setrating] = useState<any>("");
  const [audioUploadUrl, setAudioUploadUrl] = useState<any>();

  const [imageRef, setImageRef] = useState<any>();
  const [src, setSrc] = useState<any>(null);
  const [crop, setCrop] = useState<Crop>(cropDef);
  const [imageFile, setImageFile] = useState<any>(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState<any>(null);
  const [isAdding, setIsAdding] = useState(false);

  const [selectedImageRef, setselectedImageRef] = useState<any>();
  const [selectedImagesrc, setselectedImageSrc] = useState<any>(null);
  const [selectedImagecrop, setselectedImageCrop] = useState<Crop>(cropDef);
  const [selectedImageFile, setselectedImageFile] = useState<any>(null);
  const [selectedcroppedImageUrl, setselectedCroppedImageUrl] =
    useState<any>(null);

  const [language, setLanguage] = useState<any>(1);
  //const [isLangSelected, setIsLangSelected] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editMeditation, seteditMeditation] = useState<boolean>(false);
  const [open, setopen] = useState(false);
  const [deleteMeditation, setdeleteMeditation] =
    useState<State>(initialDeleteState);
  const [isUploading, setIsUploading] = useState({
    image: false,
    selectedImage: false,
    audio: false,
  });
  const [snackBar, setSnackBar] = useState<any>({
    open: false,
    severity: "success",
    message: "",
  });

  const { appendFilesToUploadManager } = useFileUploadService();

  const onClickEdit = useCallback(
    (item: GuidedMeditation) => {
      //setState({ editing: true });
      switch (language) {
        case 1:
          setAudioUploadUrl(props.editItem.audiourl.defaultcontent);
          setTitle(props.editItem.title.defaultcontent);
          break;
        case 2:
          setAudioUploadUrl(props.editItem.audiourl.en);
          setTitle(props.editItem.title.en);
          break;
        case 3:
          setAudioUploadUrl(props.editItem.audiourl.hi);
          setTitle(props.editItem.title.hi);
          break;
        case 4:
          setAudioUploadUrl(props.editItem.audiourl.te);
          setTitle(props.editItem.title.te);
          break;
        case 5:
          setAudioUploadUrl(props.editItem.audiourl.gu);
          setTitle(props.editItem.title.gu);
          break;
        case 6:
          setAudioUploadUrl(props.editItem.audiourl.ta);
          setTitle(props.editItem.title.ta);
          break;
        case 7:
          setAudioUploadUrl(props.editItem.audiourl.ka);
          setTitle(props.editItem.title.ka);
          break;
        // setType(item.meditationtype);
        //setmeditationState(item);
      }
      setmeditationId(item.id);
      setdurationId(item.durationfileid);
      //setSubTitle(item.subtitle);
      setDuration(item.durationofmeditationclip);
      //setTag(item.tag);
      setrating(item.rating);
      setAudioUploadUrl(item.audiourl.defaultcontent);
      setCroppedImageUrl(durationFile.imageurl);
      setselectedCroppedImageUrl(durationFile.selectedimageurl);
    },
    [durationFile.imageurl, durationFile.selectedimageurl, language]
  );

  useEffect(() => {
    if (props.isEditing) {
      onClickEdit(props.editItem);
    }
  }, [props.isEditing, props.editItem, durationFile, language, onClickEdit]);

  useEffect(() => {
    dispatch(getDurationDetailsbyID(props.editItem.durationfileid));
  }, [dispatch]);

  function setTitleLocale() {}
  const _onOpenImage = () => {
    setopen(true);
  };
  const _onCloseImage = () => {
    setopen(false);
    setIsUploading({ ...isUploading, image: false, selectedImage: false });
  };

  const onDeleteMeditation = (meditationId: number) => {
    setdeleteMeditation({ showDeleteModal: true, deleteItemId: meditationId });
  };
  const handleDeleteClose = () => {
    setdeleteMeditation({ showDeleteModal: false });
  };

  const _deleteSession = () => {
    // dispatch(getAllMeditation(props.pageSize, props.pageNumber, 2));
    const data = {
      ID: deleteMeditation.deleteItemId,
    };
    _deleteMeditation(data);
  };

  // --------------------------image upload--------------------------------------->
  const handleUploadClick = () => {
    hiddenFileInput.current.click();
    console.log("clicked on image upload");
  };
  const onSelectImage = (event: any) => {
    if (event.target.files[0]) {
      setIsUploading({ ...isUploading, image: true });
      setImageFile(event.target.files[0]);
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = () => {
        setSrc(reader.result);
        setopen(true);
      };
    }
  };
  const onImageLoaded = (image: any) => {
    setImageRef(image);
  };

  const onCropDone = async () => {
    setIsUploading({ ...isUploading, image: true });
    const cr: any = crop;
    if (imageRef && cr.width && cr.height) {
      const croppedUrl = await getCroppedImg(imageRef, cr);
      const croppedFile: File = dataURLtoFile(
        croppedUrl,
        imageFile.name.replaceAll(" ", "")
      );
      setCroppedImageUrl(croppedUrl);
      const uploadFile = toUploadFile(
        croppedFile,
        "MeditationFile".replaceAll(" ", "")
      );
      appendFilesToUploadManager([uploadFile]);
      window.addEventListener(
        uploadFile.token,
        (res: CustomEventInit<UploadEventResponse>) => {
          if (res.detail?.code === 200) {
            setCroppedImageUrl(res.detail.response);
            console.log("image url res", res.detail.response);
            setIsUploading({ ...isUploading, image: false });
          } else {
            setIsUploading({ ...isUploading, image: false });
          }
        }
      );
    }
    setSrc(null);
  };

  const onCropChange = (crop: any) => {
    setCrop(crop);
  };
  const onCropComplete = async () => {
    //console.log("state crop", crop, imageRef);
  };
  const onImageClose = () => {
    setSrc(null);
    setImageFile(null);
    _deleteFileFromGcp(croppedImageUrl);
    setCroppedImageUrl("");
    setCrop(cropDef);
  };

  // -------------------------- selected image upload--------------------------------------->
  const _handleUploadClick = () => {
    hiddenSelectedImageInput.current.click();
    console.log("clicked on image upload");
  };
  const _onSelectImage = (event: any) => {
    if (event.target.files[0]) {
      setIsUploading({ ...isUploading, selectedImage: true });
      setselectedImageFile(event.target.files[0]);
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = () => {
        setselectedImageSrc(reader.result);
        setopen(true);
      };
    }
  };
  const _onImageLoaded = (image: any) => {
    setselectedImageRef(image);
  };

  const _onCropDone = async () => {
    setIsUploading({ ...isUploading, selectedImage: true });
    const cr: any = selectedImagecrop;
    if (selectedImageRef && cr.width && cr.height) {
      const croppedUrl = await getCroppedImg(selectedImageRef, cr);
      const croppedFile: File = dataURLtoFile(
        croppedUrl,
        selectedImageFile.name.replaceAll(" ", "")
      );
      setselectedCroppedImageUrl(croppedUrl);
      const uploadFile = toUploadFile(
        croppedFile,
        "MeditationFile".replaceAll(" ", "")
      );
      appendFilesToUploadManager([uploadFile]);
      window.addEventListener(
        uploadFile.token,
        (res: CustomEventInit<UploadEventResponse>) => {
          if (res.detail?.code === 200) {
            setselectedCroppedImageUrl(res.detail.response);
            console.log("image url res", res.detail.response);
            setIsUploading({ ...isUploading, selectedImage: false });
          } else {
            setIsUploading({ ...isUploading, selectedImage: false });
          }
        }
      );
    }
    setselectedImageSrc(null);
  };

  const _onCropChange = (crop: any) => {
    setselectedImageCrop(crop);
  };
  const _onCropComplete = async () => {
    //console.log("state crop", crop, imageRef);
  };
  const _onImageClose = () => {
    setselectedImageSrc(null);
    _deleteFileFromGcp(selectedcroppedImageUrl);
    setselectedCroppedImageUrl("");
    setselectedImageFile(null);
    setselectedImageCrop(cropDef);
  };

  const _deleteFileFromGcp = async (data: any) => {
    try {
      console.log("image url to delete from gcp", data);
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: DeleteFileRequest = new DeleteFileRequest();
      reqBody.setImgpath(data);
      grpc.unary(GamificationService.DeleteFileFromGCP, {
        host: GAMIFICATION_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<ResponseStatus>) => {
          const { status, message, statusMessage } = res;
          console.log("statusMessage", statusMessage);
          if (status === grpc.Code.OK && message) {
            if (message) {
              //setIsLoading(false);
              console.log(
                "data coming from delete file from gcp",
                message.toObject()
              );
              //setCroppedImageUrl("");
            }
          } else {
            setIsLoading(false);
          }
        },
      });
    } catch (err) {
      console.log(err);
    }
  };
  //---------------------------audio upload------------------------------------->
  const handleAudioClick = () => {
    hiddenAudioInput.current.click();
    //console.log("clicked on audio upload");
  };

  const onAudioSelection = async (event: any) => {
    setIsUploading({ ...isUploading, audio: true });
    const uploadFile = toUploadFile(
      event.target.files[0],
      "MeditationFile".replaceAll(" ", "")
    );
    appendFilesToUploadManager([uploadFile]);
    window.addEventListener(
      uploadFile.token,
      (res: CustomEventInit<UploadEventResponse>) => {
        if (res.detail?.code === 200) {
          setAudioUploadUrl(res.detail.response);
          setIsUploading({ ...isUploading, audio: false });
        } else {
          setIsUploading({ ...isUploading, audio: false });
        }
      }
    );
  };

  const onAudioClose = () => {
    setAudioUploadUrl("");
  };

  //---------------------------audio upload end------------------------------------->

  // const onChangeTitle = (e: any) => {
  //   const titleCopy = title ? { ...title } : {};
  //   titleLang.toLowerCase() === "en" && (titleCopy["default"] = e.target.value);
  //   titleCopy[titleLang.toLowerCase()] = e.target.value;
  //   setTitle(titleCopy);
  // };

  const validateMeditaion = () => {
    if (title.trim() === "") {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Meditation title can not be empty",
      });
    } else if (duration === 0 || duration === "0" || duration === "") {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Duration must be greater than 0",
      });
    } else if (croppedImageUrl === "" || croppedImageUrl === null) {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Image can not be empty",
      });
    } else if (
      selectedcroppedImageUrl === "" ||
      selectedcroppedImageUrl === null
    ) {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Image can not be empty",
      });
    } else if (audioUploadUrl === "" || audioUploadUrl === null) {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Audio can not be empty",
      });
    } else {
      return true;
    }
  };

  const _updateMeditation = () => {
    if (validateMeditaion()) {
      const newData = {
        id: durationId,
        durationInSeconds: duration,
        imageURL: croppedImageUrl,
        selectedImageURL: selectedcroppedImageUrl,
      };
      //const res = addMeditation(data);
      //console.log("res addMeditation", res);
      setIsAdding(true);
      _updateDurationFile(newData);
    }
  };
  const _updateDurationFile = async (data: any) => {
    setIsLoading(true);
    try {
      var updateDurationReq = new DurationFile();
      updateDurationReq.setId(data.id);
      updateDurationReq.setDurationinseconds(data.durationInSeconds);
      updateDurationReq.setImageurl(data.imageURL);
      updateDurationReq.setSelectedimageurl(data.selectedImageURL);

      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      grpc.unary(GuidedMeditationService.UpdateDurationFileDetails, {
        request: updateDurationReq,
        host: GUIDED_MEDITATION_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<DurationFile>) => {
          const { status, message } = res;
          if (status === grpc.Code.OK && message) {
            console.log("all ok: ", message.toObject());
            const id = message.toObject().id;
            console.log("id", id);
            // setdurationFileId(id);
            _updateMed();
          } else {
            setIsLoading(false);
            setSnackBar({
              open: true,
              severity: "error",
              message: "Error in updating Duration file",
            });
          }
        },
      });
    } catch (error) {
      setIsLoading(false);
      setSnackBar({
        open: true,
        severity: "error",
        message: "something went wrong in update Duration file",
      });
      console.error("something went wrong in update Duration file", error);
    }
  };

  const localeObject = {
    defaultcontent: "",
    en: "",
    hi: "",
    te: "",
    gu: "",
    ta: "",
    ka: "",
    mr: "",
    it: "",
    ru: "",
    es: "",
    fr: "",
    pt: "",
    de: "",
    bn: "",
    ml: "",
    uk: "",
    od: "",
    enUs: "",
    enAu: "",
    pa: "",
  };

  const decideLocale = () => {
    switch (language) {
      case 1:
        return {
          reqTitle: {
            ...localeObject,
            defaultcontent: props.editItem.title.defaultcontent,
            en: title,
          },
          reqSubTitle: {
            ...localeObject,
            defaultcontent: props.editItem.title.defaultcontent,
            en: title,
          },
          reqAudioUrl: {
            ...localeObject,
            defaultcontent: props.editItem.audiourl.defaultcontent,
            en: audioUploadUrl,
          },
        };
      case 2:
        return {
          reqTitle: {
            ...localeObject,
            defaultcontent: props.editItem.title.defaultcontent,
            en: title,
          },
          reqSubTitle: {
            ...localeObject,
            defaultcontent: props.editItem.title.defaultcontent,
            en: title,
          },
          reqAudioUrl: {
            ...localeObject,
            defaultcontent: props.editItem.audiourl.defaultcontent,
            en: audioUploadUrl,
          },
        };
      case 3:
        return {
          reqTitle: {
            ...localeObject,
            defaultcontent: props.editItem.title.defaultcontent,
            hi: title,
          },
          reqSubTitle: {
            ...localeObject,
            defaultcontent: props.editItem.title.defaultcontent,
            hi: title,
          },
          reqAudioUrl: {
            ...localeObject,
            defaultcontent: props.editItem.audiourl.defaultcontent,
            hi: audioUploadUrl,
          },
        };
      case 4:
        return {
          reqTitle: {
            ...localeObject,
            defaultcontent: props.editItem.title.defaultcontent,
            te: title,
          },
          reqSubTitle: {
            ...localeObject,
            defaultcontent: props.editItem.title.defaultcontent,
            te: title,
          },
          reqAudioUrl: {
            ...localeObject,
            defaultcontent: props.editItem.audiourl.defaultcontent,
            te: audioUploadUrl,
          },
        };
      case 5:
        return {
          reqTitle: {
            ...localeObject,
            defaultcontent: props.editItem.title.defaultcontent,
            gu: title,
          },
          reqSubTitle: {
            ...localeObject,
            defaultcontent: props.editItem.title.defaultcontent,
            gu: title,
          },
          reqAudioUrl: {
            ...localeObject,
            defaultcontent: props.editItem.audiourl.defaultcontent,
            gu: audioUploadUrl,
          },
        };
      case 6:
        return {
          reqTitle: {
            ...localeObject,
            defaultcontent: props.editItem.title.defaultcontent,
            ta: title,
          },
          reqSubTitle: {
            ...localeObject,
            defaultcontent: props.editItem.title.defaultcontent,
            ta: title,
          },
          reqAudioUrl: {
            ...localeObject,
            defaultcontent: props.editItem.audiourl.defaultcontent,
            ta: audioUploadUrl,
          },
        };
      case 7:
        return {
          reqTitle: {
            ...localeObject,
            defaultcontent: props.editItem.title.defaultcontent,
            ka: title,
          },
          reqSubTitle: {
            ...localeObject,
            defaultcontent: props.editItem.title.defaultcontent,
            ka: title,
          },

          reqAudioUrl: {
            ...localeObject,
            defaultcontent: props.editItem.audiourl.defaultcontent,
            ka: audioUploadUrl,
          },
        };
    }
  };
  const _updateMed = () => {
    // if (validateMeditaion()) {
    const data: any = {
      id: meditationId,
      meditationType: 2,
      title: decideLocale()?.reqTitle,
      subTitle: decideLocale()?.reqSubTitle,
      description: "",
      duration: Number(duration),
      tag: "https://storage.googleapis.com/heartintune-dev-storage/purposes/LS_HOLDER.png",
      rating: Number(rating),
      likes: 5,
      audioUrl: decideLocale()?.reqAudioUrl,
      imageUrl: "",
      purposeFileId: 7,
      durationFileId: durationId,
    };
    _updateMeditationFile(data);
    // }
  };
  const _updateMeditationFile = async (data: any) => {
    console.log("meditaion data", data);
    try {
      const updateMeditationReq = new MeditationFileDetails();

      const titleLocale = new Locale();
      titleLocale.setDefaultcontent(data.title.defaultcontent);
      titleLocale.setEn(data.title.en);
      titleLocale.setHi(data.title.hi);
      titleLocale.setTe(data.title.te);
      titleLocale.setGu(data.title.gu);
      titleLocale.setTa(data.title.ta);
      titleLocale.setKa(data.title.ka);
      updateMeditationReq.setTitle(titleLocale);

      const subtitleLocale = new Locale();
      subtitleLocale.setDefaultcontent(data.subTitle.defaultcontent);
      subtitleLocale.setEn(data.subTitle.en);
      subtitleLocale.setHi(data.subTitle.hi);
      subtitleLocale.setTe(data.subTitle.te);
      subtitleLocale.setGu(data.subTitle.gu);
      subtitleLocale.setTa(data.subTitle.ta);
      subtitleLocale.setKa(data.subTitle.ka);
      updateMeditationReq.setSubtitle(subtitleLocale);

      const descriptionLocale = new Locale();
      descriptionLocale.setDefaultcontent(data.description);
      descriptionLocale.setEn(data.description);
      updateMeditationReq.setDescription(descriptionLocale);

      const audiourlLocale = new Locale();
      audiourlLocale.setDefaultcontent(data.audioUrl.defaultcontent);
      audiourlLocale.setEn(data.audioUrl.en);
      audiourlLocale.setHi(data.audioUrl.hi);
      audiourlLocale.setTe(data.audioUrl.te);
      audiourlLocale.setGu(data.audioUrl.gu);
      audiourlLocale.setTa(data.audioUrl.ta);
      audiourlLocale.setKa(data.audioUrl.ka);
      updateMeditationReq.setAudiourl(audiourlLocale);

      const imageurlLocale = new Locale();
      imageurlLocale.setDefaultcontent(data.imageUrl);
      imageurlLocale.setEn(data.imageUrl);
      updateMeditationReq.setImageurl(imageurlLocale);

      const thumbnailimgurlLocale = new Locale();
      thumbnailimgurlLocale.setDefaultcontent("");
      thumbnailimgurlLocale.setEn("");
      updateMeditationReq.setThumbnailimgurl(thumbnailimgurlLocale);

      updateMeditationReq.setId(data.id);
      updateMeditationReq.setLikes(data.likes);
      updateMeditationReq.setMeditationtype(data.meditationType);
      updateMeditationReq.setRating(data.rating);
      updateMeditationReq.setTag(data.tag);
      updateMeditationReq.setDurationofmeditationclip(data.duration);
      updateMeditationReq.setPurposefileid(data.purposeFileId);
      updateMeditationReq.setDurationfileid(data.durationFileId);

      console.log(updateMeditationReq);

      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      console.log("sending request data :", updateMeditationReq.toObject());
      grpc.unary(GuidedMeditationService.updateMeditationFileDetails, {
        request: updateMeditationReq,
        host: GUIDED_MEDITATION_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<MeditationFileDetails>) => {
          const { status, statusMessage, message } = res;
          console.log("statusMessage", statusMessage);
          //console.log("grpc code", grpc.Code);

          if (status === grpc.Code.OK && message) {
            console.log(
              "response coming from updateMeditationFileDetails ",
              message.toObject()
            );
            dispatch(getAllMeditation(10, 0, 2));
            setIsLoading(false);
            setSnackBar({
              open: true,
              severity: "success",
              message: "Guided meditation updated successfully",
            });
            setTimeout(() => props.onClickBack(), 2000);
            return message;
          } else {
            setIsLoading(false);
            setSnackBar({
              open: true,
              severity: "error",
              message: "error in updating Guided meditation ",
            });
          }
        },
      });
    } catch (error) {
      setIsLoading(false);
      setSnackBar({
        open: true,
        severity: "error",
        message: "something went wrong in update Meditation",
      });
      console.error("something went wrong in update Meditation", error);
    }
  };

  const _deleteMeditation = async (data: any) => {
    // console.log("Delete User data", data);
    try {
      console.log("Delete User data", data);
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: ID = new ID();
      reqBody.setId(data.ID);
      setIsLoading(true);
      grpc.unary(GuidedMeditationService.DeleteDurationFileDetailsById, {
        host: GUIDED_MEDITATION_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: any) => {
          const { status, message, statusMessage } = res;
          console.log("statusMessage", statusMessage);
          if (status === grpc.Code.OK && message) {
            if (message) {
              console.log("delete meditation response", message.toObject());
              setIsLoading(false);
              dispatch(getAllMeditation(10, 0, 2));
              setdeleteMeditation({ showDeleteModal: false });
              setTimeout(() => props.onClickBack(), 2000);
              setSnackBar({
                open: true,
                severity: "success",
                message: " Deleted Meditation successfully",
              });
            }
          } else {
            setIsLoading(false);
            setSnackBar({
              open: true,
              severity: "error",
              message: "error in  deleting Guided Meditation",
            });
            setdeleteMeditation({ showDeleteModal: false });
          }
        },
      });
    } catch (err) {
      setIsLoading(false);
      setSnackBar({
        open: true,
        severity: "error",
        message: "error in  deleting User",
      });
      console.log(err);
    }
  };

  return (
    <Root ref={ref}>
      {isLoading && (
        <CircularProgress
          style={{ position: "absolute", top: "50%", right: "50%", zIndex: 5 }}
        />
      )}
      <Grid
        container
        className="p-2 align-items-center"
        justifyContent="space-between"
      >
        <Grid item>
          <Grid container alignItems="center">
            <Grid>
              <IconButton onClick={props.onClickBack} size="large">
                <KeyboardBackspaceIcon color="action" />
              </IconButton>
            </Grid>
            <Grid>
              <Typography variant="h6">Update Meditation</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item className="d-flex justify-content-end">
          <Button
            variant="contained"
            color="primary"
            className="mr-2"
            disabled={editMeditation}
            //startIcon={<DeleteIcon />}
            onClick={() => {
              seteditMeditation(true);
              //onDeleteMeditation(sessionId)
            }}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<DeleteIcon />}
            onClick={() => onDeleteMeditation(durationFile.id)}
            // disabled
          >
            Delete
          </Button>
        </Grid>
      </Grid>
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item md={12} xs={12} className="d-flex">
            <TextField
              fullWidth
              type="text"
              value={title}
              disabled={editMeditation ? false : true}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              InputProps={{
                readOnly: editMeditation ? false : true,
              }}
              label="Meditation Title"
              name="meditationTitle"
              required
              variant="outlined"
              className="mr-3"
            />
            <Grid item xs={6} sm={4} md={2}>
              <FormControl
                variant="outlined"
                fullWidth
                disabled={editMeditation ? false : true}
              >
                <InputLabel id="language-select">Language</InputLabel>
                {languages && (
                  <Select
                    defaultValue=""
                    labelId="language-select"
                    value={language}
                    label="Language"
                    inputProps={{
                      readOnly: editMeditation ? false : true,
                    }}
                    onChange={(e) => {
                      setLanguage(e.target.value as number);
                      // setIsLangSelected(true);
                      setTitle(setTitleLocale());
                      console.log(`language: ${language}; title: ${title}`);
                    }}
                  >
                    {languages.map((lang, i) => (
                      <MenuItem key={i} value={lang.code}>
                        {lang.displayName}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </FormControl>
            </Grid>
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              type="text"
              disabled
              //helperText={`Please enter the description of the meditation`}
              label="Meditation Type"
              name="type"
              variant="outlined"
              value={meditationType[2]}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              type="number"
              disabled={editMeditation ? false : true}
              value={duration}
              onChange={(e) => {
                setDuration(e.target.value);
              }}
              // helperText={`Please enter the duration of the meditation`}
              label="Meditation duration(in sec)"
              name="duration"
              required
              variant="outlined"
              InputProps={{
                readOnly: editMeditation ? false : true,
              }}
            />
          </Grid>

          <Grid item md={4} xs={12} sm={6}>
            <FormControl variant="outlined" fullWidth>
              <Card variant="outlined" className={classes.root}>
                {editMeditation ? (
                  <>
                    {croppedImageUrl ? (
                      <PreviewProfileImage
                        onImageClose={onImageClose}
                        src={croppedImageUrl}
                      />
                    ) : (
                      <>
                        {isUploading.image === true ? (
                          <Card className={classes.thumbNail}>
                            <Grid
                              container
                              justifyContent="center"
                              alignItems="center"
                              className="pt-5"
                            >
                              <Typography className="pt-4">
                                Uploading...
                              </Typography>
                            </Grid>
                          </Card>
                        ) : (
                          <CardActionArea
                            className={classes.thumbNail}
                            onClick={() => {
                              handleUploadClick();
                            }}
                          >
                            <InputLabel htmlFor="thumbnail">
                              Upload image
                            </InputLabel>
                            <Avatar
                              className={classes.actionIcon}
                              variant="square"
                            >
                              <ImageOutlined />
                            </Avatar>
                            <input
                              accept="image/*"
                              id="contained-button-file"
                              type="file"
                              hidden
                              onChange={onSelectImage}
                              ref={hiddenFileInput}
                            />
                          </CardActionArea>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    className="mt-4"
                  >
                    {croppedImageUrl === "" || croppedImageUrl === null ? (
                      <Avatar
                        src=""
                        style={{ height: "200px", width: "200px" }}
                      />
                    ) : (
                      <img
                        alt="coach Profile"
                        src={croppedImageUrl}
                        style={{
                          height: "200px",
                          width: "200px",
                          borderRadius: "50%",
                        }}
                      />
                    )}
                  </Grid>
                )}
              </Card>
            </FormControl>
          </Grid>
          <Grid item md={4} xs={12} sm={6}>
            <FormControl variant="outlined" fullWidth>
              <Card variant="outlined" className={classes.root}>
                {editMeditation ? (
                  <>
                    {selectedcroppedImageUrl ? (
                      <PreviewProfileImage
                        onImageClose={_onImageClose}
                        src={selectedcroppedImageUrl}
                      />
                    ) : (
                      <>
                        {isUploading.selectedImage === true ? (
                          <Card className={classes.thumbNail}>
                            <Grid
                              container
                              justifyContent="center"
                              alignItems="center"
                              className="pt-5"
                            >
                              <Typography className="pt-4">
                                Uploading...
                              </Typography>
                            </Grid>
                          </Card>
                        ) : (
                          <CardActionArea
                            className={classes.thumbNail}
                            onClick={() => {
                              _handleUploadClick();
                            }}
                          >
                            <InputLabel htmlFor="thumbnail">
                              Upload selected image
                            </InputLabel>
                            <Avatar
                              className={classes.actionIcon}
                              variant="square"
                            >
                              <ImageOutlined />
                            </Avatar>
                            <input
                              accept="image/*"
                              id="contained-button-file"
                              type="file"
                              hidden
                              onChange={_onSelectImage}
                              ref={hiddenSelectedImageInput}
                            />
                          </CardActionArea>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    className="mt-4"
                  >
                    {selectedcroppedImageUrl === "" ||
                    selectedcroppedImageUrl === null ? (
                      <Avatar
                        src=""
                        style={{ height: "200px", width: "200px" }}
                      />
                    ) : (
                      <img
                        alt="coach Profile"
                        src={selectedcroppedImageUrl}
                        style={{
                          height: "200px",
                          width: "200px",
                          borderRadius: "50%",
                        }}
                      />
                    )}
                  </Grid>
                )}
              </Card>
            </FormControl>
          </Grid>
          <Grid item md={4} xs={12} sm={6}>
            <FormControl variant="outlined" fullWidth>
              <Card variant="outlined" className={classes.root}>
                {editMeditation ? (
                  <>
                    {audioUploadUrl ? (
                      <>
                        <Card className={classes.audioPlayer}>
                          <AudioPlayer
                            src={audioUploadUrl}
                            // audioTitle={`${audioFile.file.name}`}
                            onAudioClose={onAudioClose}
                          />
                        </Card>
                      </>
                    ) : (
                      <>
                        {isUploading.audio === true ? (
                          <Card className={classes.thumbNail}>
                            <Grid
                              container
                              justifyContent="center"
                              alignItems="center"
                              className="pt-5"
                            >
                              <Typography className="pt-4">
                                Uploading...
                              </Typography>
                            </Grid>
                          </Card>
                        ) : (
                          <CardActionArea
                            className={classes.thumbNail}
                            onClick={() => {
                              handleAudioClick();
                            }}
                          >
                            <InputLabel htmlFor="thumbnail">
                              Upload Audio
                            </InputLabel>
                            <Avatar
                              className={classes.actionIcon}
                              variant="square"
                            >
                              <MusicNoteOutlined />
                            </Avatar>
                            <input
                              accept="audio/*"
                              id="audioFile"
                              type="file"
                              hidden
                              onChange={onAudioSelection}
                              ref={hiddenAudioInput}
                            />
                          </CardActionArea>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <Grid
                    container
                    justifyContent="center"
                    className="align-items-center mt-5 pt-5"
                  >
                    <audio controls>
                      <source src={audioUploadUrl} />
                    </audio>
                  </Grid>
                )}
              </Card>
            </FormControl>
          </Grid>
        </Grid>
        <DialogBox
          open={deleteMeditation.showDeleteModal || false}
          onClose={handleDeleteClose}
          onCancel={handleDeleteClose}
          onConfirm={_deleteSession}
          type={"delete"}
          title={`Sure !! Want to delete this Item ?`}
        />
      </CardContent>
      <Divider />
      <Box p={2} display="flex" justifyContent="flex-end">
        <Button
          className={classes.formBtn}
          onClick={() => {
            // state.editing ? updateMed(123) : _addMeditation();
            seteditMeditation(false);
            onClickEdit(props.editItem);
          }}
          color="primary"
          variant="contained"
          disabled={!editMeditation}
        >
          Cancel
        </Button>

        <Button
          className={classes.formBtn}
          onClick={() => {
            // state.editing ? updateMed(123) : _addMeditation();
            _updateMeditation();
          }}
          color="primary"
          variant="contained"
          disabled={!editMeditation || isAdding}
        >
          Save
        </Button>
      </Box>
      {src && (
        <ImageCrop
          src={src.toString()}
          crop={crop}
          onImageLoaded={onImageLoaded}
          onCropComplete={onCropComplete}
          onCropChange={onCropChange}
          onPressDone={onCropDone}
          onImageClose={onImageClose}
          open={open}
          onOpen={_onOpenImage}
          onClose={_onCloseImage}
        />
      )}
      {selectedImagesrc && (
        <ImageCrop
          src={selectedImagesrc.toString()}
          crop={selectedImagecrop}
          onImageLoaded={_onImageLoaded}
          onCropComplete={_onCropComplete}
          onCropChange={_onCropChange}
          onPressDone={_onCropDone}
          onImageClose={_onImageClose}
          open={open}
          onOpen={_onOpenImage}
          onClose={_onCloseImage}
        />
      )}
      <Snackbar
        open={snackBar.open}
        autoHideDuration={10000}
        onClose={() => {
          setSnackBar({ ...snackBar, open: false });
        }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Alert
          severity={snackBar.severity}
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
        >
          {snackBar.message}
        </Alert>
      </Snackbar>
    </Root>
  );
});
