// source: guided-meditation.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var common_common_pb = require('./common/common_pb.js');
goog.object.extend(proto, common_common_pb);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.AddDurationFileDetailsRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.AddDurationFileRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.AddPurposeFileDetailsRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.AddPurposeFileRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.DeleteMeditationFileByIdResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.DeleteMeditationFileDetailsByIdResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.DeletePurposeFileByIdResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.DeletePurposeFileDetailByIdResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.DurationFile', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.DurationFileDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.Empty', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.ID', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetailsLocale', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.LocalType', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.MeditationFile', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.MeditationFileAndUserID', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.MeditationFileDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.MeditationFileV2', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.PurposeFile', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.PurposeFileDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.PurposeFileDetailsResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.RecentlyPracticedMeditationsByUserRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.RequestForGetAllMeditationFiles', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetailsWithSelectedLanguage', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.RequestGetAllPurpose', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.RequestGetAllPurposeFileDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.RequestPageable', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.ResponseAllDurationFiles', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.ResponseAllDurationFilesDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFiles', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFilesDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.ResponsePageable', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.ResponseStatus', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.SelectMeditationFileDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.SelectMeditationFiles', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.SelectedFileDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponseV2', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.SelectedFiles', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.SelectedMeditaionBckgndDetailsRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.SelectedMeditationFiles', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.Tag', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.TagWithMeditation', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.TagsList', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.TagsRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.Test', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.TestResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.guidedMeditation.URLRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.Test = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.Test, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.Test.displayName = 'proto.unifiedplatform.guidedMeditation.Test';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.Empty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.Empty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.Empty.displayName = 'proto.unifiedplatform.guidedMeditation.Empty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.TestResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.TestResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.TestResponse.displayName = 'proto.unifiedplatform.guidedMeditation.TestResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.SelectMeditationFiles = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.SelectMeditationFiles, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.SelectMeditationFiles.displayName = 'proto.unifiedplatform.guidedMeditation.SelectMeditationFiles';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.MeditationFile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.guidedMeditation.MeditationFile.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.MeditationFile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.MeditationFile.displayName = 'proto.unifiedplatform.guidedMeditation.MeditationFile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest.displayName = 'proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.PurposeFile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.PurposeFile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.PurposeFile.displayName = 'proto.unifiedplatform.guidedMeditation.PurposeFile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.DurationFile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.DurationFile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.DurationFile.displayName = 'proto.unifiedplatform.guidedMeditation.DurationFile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.AddPurposeFileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.AddPurposeFileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.AddPurposeFileRequest.displayName = 'proto.unifiedplatform.guidedMeditation.AddPurposeFileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.AddDurationFileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.AddDurationFileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.AddDurationFileRequest.displayName = 'proto.unifiedplatform.guidedMeditation.AddDurationFileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.SelectedFiles = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.guidedMeditation.SelectedFiles.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.SelectedFiles, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.SelectedFiles.displayName = 'proto.unifiedplatform.guidedMeditation.SelectedFiles';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFiles = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFiles.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFiles, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFiles.displayName = 'proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFiles';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.ResponseAllDurationFiles = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.guidedMeditation.ResponseAllDurationFiles.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.ResponseAllDurationFiles, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.ResponseAllDurationFiles.displayName = 'proto.unifiedplatform.guidedMeditation.ResponseAllDurationFiles';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.MeditationFileAndUserID = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.MeditationFileAndUserID, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.MeditationFileAndUserID.displayName = 'proto.unifiedplatform.guidedMeditation.MeditationFileAndUserID';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.ID = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.ID, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.ID.displayName = 'proto.unifiedplatform.guidedMeditation.ID';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.ResponseStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.ResponseStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.ResponseStatus.displayName = 'proto.unifiedplatform.guidedMeditation.ResponseStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.DeleteMeditationFileByIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.DeleteMeditationFileByIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.DeleteMeditationFileByIdResponse.displayName = 'proto.unifiedplatform.guidedMeditation.DeleteMeditationFileByIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.DeletePurposeFileByIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.DeletePurposeFileByIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.DeletePurposeFileByIdResponse.displayName = 'proto.unifiedplatform.guidedMeditation.DeletePurposeFileByIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.displayName = 'proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.guidedMeditation.MeditationFileDetails.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.MeditationFileDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.MeditationFileDetails.displayName = 'proto.unifiedplatform.guidedMeditation.MeditationFileDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponse.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponse.displayName = 'proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse.displayName = 'proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.SelectedMeditationFiles = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.guidedMeditation.SelectedMeditationFiles.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.SelectedMeditationFiles, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.SelectedMeditationFiles.displayName = 'proto.unifiedplatform.guidedMeditation.SelectedMeditationFiles';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.DeleteMeditationFileDetailsByIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.DeleteMeditationFileDetailsByIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.DeleteMeditationFileDetailsByIdResponse.displayName = 'proto.unifiedplatform.guidedMeditation.DeleteMeditationFileDetailsByIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.AddPurposeFileDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.AddPurposeFileDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.AddPurposeFileDetailsRequest.displayName = 'proto.unifiedplatform.guidedMeditation.AddPurposeFileDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.PurposeFileDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.PurposeFileDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.PurposeFileDetails.displayName = 'proto.unifiedplatform.guidedMeditation.PurposeFileDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.PurposeFileDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.PurposeFileDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.PurposeFileDetailsResponse.displayName = 'proto.unifiedplatform.guidedMeditation.PurposeFileDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.AddDurationFileDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.AddDurationFileDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.AddDurationFileDetailsRequest.displayName = 'proto.unifiedplatform.guidedMeditation.AddDurationFileDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.DurationFileDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.DurationFileDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.DurationFileDetails.displayName = 'proto.unifiedplatform.guidedMeditation.DurationFileDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.SelectMeditationFileDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.SelectMeditationFileDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.SelectMeditationFileDetails.displayName = 'proto.unifiedplatform.guidedMeditation.SelectMeditationFileDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.SelectedFileDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.guidedMeditation.SelectedFileDetails.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.SelectedFileDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.SelectedFileDetails.displayName = 'proto.unifiedplatform.guidedMeditation.SelectedFileDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.DeletePurposeFileDetailByIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.DeletePurposeFileDetailByIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.DeletePurposeFileDetailByIdResponse.displayName = 'proto.unifiedplatform.guidedMeditation.DeletePurposeFileDetailByIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFilesDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFilesDetails.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFilesDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFilesDetails.displayName = 'proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFilesDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.ResponseAllDurationFilesDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.guidedMeditation.ResponseAllDurationFilesDetails.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.ResponseAllDurationFilesDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.ResponseAllDurationFilesDetails.displayName = 'proto.unifiedplatform.guidedMeditation.ResponseAllDurationFilesDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.RequestGetAllPurpose = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.RequestGetAllPurpose, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.RequestGetAllPurpose.displayName = 'proto.unifiedplatform.guidedMeditation.RequestGetAllPurpose';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.RequestGetAllPurposeFileDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.RequestGetAllPurposeFileDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.RequestGetAllPurposeFileDetails.displayName = 'proto.unifiedplatform.guidedMeditation.RequestGetAllPurposeFileDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.RequestPageable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.RequestPageable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.RequestPageable.displayName = 'proto.unifiedplatform.guidedMeditation.RequestPageable';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetailsWithSelectedLanguage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetailsWithSelectedLanguage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetailsWithSelectedLanguage.displayName = 'proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetailsWithSelectedLanguage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.RequestForGetAllMeditationFiles = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.RequestForGetAllMeditationFiles, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.RequestForGetAllMeditationFiles.displayName = 'proto.unifiedplatform.guidedMeditation.RequestForGetAllMeditationFiles';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.LocalType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.LocalType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.LocalType.displayName = 'proto.unifiedplatform.guidedMeditation.LocalType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetails.displayName = 'proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponseV2 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponseV2.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponseV2, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponseV2.displayName = 'proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponseV2';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.displayName = 'proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.displayName = 'proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.guidedMeditation.MeditationFileV2.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.MeditationFileV2, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.MeditationFileV2.displayName = 'proto.unifiedplatform.guidedMeditation.MeditationFileV2';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.URLRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.URLRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.URLRequest.displayName = 'proto.unifiedplatform.guidedMeditation.URLRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails.displayName = 'proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale.displayName = 'proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetails.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetails.displayName = 'proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetailsLocale = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetailsLocale.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetailsLocale, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetailsLocale.displayName = 'proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetailsLocale';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.SelectedMeditaionBckgndDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.SelectedMeditaionBckgndDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.SelectedMeditaionBckgndDetailsRequest.displayName = 'proto.unifiedplatform.guidedMeditation.SelectedMeditaionBckgndDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.ResponsePageable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.ResponsePageable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.ResponsePageable.displayName = 'proto.unifiedplatform.guidedMeditation.ResponsePageable';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.Tag = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.Tag, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.Tag.displayName = 'proto.unifiedplatform.guidedMeditation.Tag';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.TagWithMeditation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.guidedMeditation.TagWithMeditation.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.TagWithMeditation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.TagWithMeditation.displayName = 'proto.unifiedplatform.guidedMeditation.TagWithMeditation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.TagsList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.guidedMeditation.TagsList.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.TagsList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.TagsList.displayName = 'proto.unifiedplatform.guidedMeditation.TagsList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.TagsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.guidedMeditation.TagsRequest.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.TagsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.TagsRequest.displayName = 'proto.unifiedplatform.guidedMeditation.TagsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.guidedMeditation.RecentlyPracticedMeditationsByUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.guidedMeditation.RecentlyPracticedMeditationsByUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.guidedMeditation.RecentlyPracticedMeditationsByUserRequest.displayName = 'proto.unifiedplatform.guidedMeditation.RecentlyPracticedMeditationsByUserRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.Test.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.Test.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.Test} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.Test.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.Test}
 */
proto.unifiedplatform.guidedMeditation.Test.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.Test;
  return proto.unifiedplatform.guidedMeditation.Test.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.Test} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.Test}
 */
proto.unifiedplatform.guidedMeditation.Test.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.Test.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.Test.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.Test} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.Test.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string result = 1;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.Test.prototype.getResult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.Test} returns this
 */
proto.unifiedplatform.guidedMeditation.Test.prototype.setResult = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.Empty.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.Empty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.Empty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.Empty.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.Empty}
 */
proto.unifiedplatform.guidedMeditation.Empty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.Empty;
  return proto.unifiedplatform.guidedMeditation.Empty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.Empty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.Empty}
 */
proto.unifiedplatform.guidedMeditation.Empty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.Empty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.Empty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.Empty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.Empty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.TestResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.TestResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.TestResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.TestResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    test: (f = msg.getTest()) && proto.unifiedplatform.guidedMeditation.Test.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.TestResponse}
 */
proto.unifiedplatform.guidedMeditation.TestResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.TestResponse;
  return proto.unifiedplatform.guidedMeditation.TestResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.TestResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.TestResponse}
 */
proto.unifiedplatform.guidedMeditation.TestResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.guidedMeditation.Test;
      reader.readMessage(value,proto.unifiedplatform.guidedMeditation.Test.deserializeBinaryFromReader);
      msg.setTest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.TestResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.TestResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.TestResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.TestResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.unifiedplatform.guidedMeditation.Test.serializeBinaryToWriter
    );
  }
};


/**
 * optional Test test = 1;
 * @return {?proto.unifiedplatform.guidedMeditation.Test}
 */
proto.unifiedplatform.guidedMeditation.TestResponse.prototype.getTest = function() {
  return /** @type{?proto.unifiedplatform.guidedMeditation.Test} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.guidedMeditation.Test, 1));
};


/**
 * @param {?proto.unifiedplatform.guidedMeditation.Test|undefined} value
 * @return {!proto.unifiedplatform.guidedMeditation.TestResponse} returns this
*/
proto.unifiedplatform.guidedMeditation.TestResponse.prototype.setTest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.guidedMeditation.TestResponse} returns this
 */
proto.unifiedplatform.guidedMeditation.TestResponse.prototype.clearTest = function() {
  return this.setTest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.TestResponse.prototype.hasTest = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.SelectMeditationFiles.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.SelectMeditationFiles.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.SelectMeditationFiles} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.SelectMeditationFiles.toObject = function(includeInstance, msg) {
  var f, obj = {
    purpose: jspb.Message.getFieldWithDefault(msg, 1, ""),
    duration: jspb.Message.getFieldWithDefault(msg, 2, 0),
    usertype: jspb.Message.getFieldWithDefault(msg, 3, 0),
    meditationtype: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.SelectMeditationFiles}
 */
proto.unifiedplatform.guidedMeditation.SelectMeditationFiles.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.SelectMeditationFiles;
  return proto.unifiedplatform.guidedMeditation.SelectMeditationFiles.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.SelectMeditationFiles} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.SelectMeditationFiles}
 */
proto.unifiedplatform.guidedMeditation.SelectMeditationFiles.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPurpose(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDuration(value);
      break;
    case 3:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.UserType} */ (reader.readEnum());
      msg.setUsertype(value);
      break;
    case 4:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.MeditationType} */ (reader.readEnum());
      msg.setMeditationtype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.SelectMeditationFiles.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.SelectMeditationFiles.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.SelectMeditationFiles} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.SelectMeditationFiles.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPurpose();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getUsertype();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getMeditationtype();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string purpose = 1;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.SelectMeditationFiles.prototype.getPurpose = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.SelectMeditationFiles} returns this
 */
proto.unifiedplatform.guidedMeditation.SelectMeditationFiles.prototype.setPurpose = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 duration = 2;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.SelectMeditationFiles.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.SelectMeditationFiles} returns this
 */
proto.unifiedplatform.guidedMeditation.SelectMeditationFiles.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional unifiedplatform.heartintune.common.UserType userType = 3;
 * @return {!proto.unifiedplatform.heartintune.common.UserType}
 */
proto.unifiedplatform.guidedMeditation.SelectMeditationFiles.prototype.getUsertype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.UserType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.UserType} value
 * @return {!proto.unifiedplatform.guidedMeditation.SelectMeditationFiles} returns this
 */
proto.unifiedplatform.guidedMeditation.SelectMeditationFiles.prototype.setUsertype = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional unifiedplatform.heartintune.common.MeditationType meditationType = 4;
 * @return {!proto.unifiedplatform.heartintune.common.MeditationType}
 */
proto.unifiedplatform.guidedMeditation.SelectMeditationFiles.prototype.getMeditationtype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.MeditationType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.MeditationType} value
 * @return {!proto.unifiedplatform.guidedMeditation.SelectMeditationFiles} returns this
 */
proto.unifiedplatform.guidedMeditation.SelectMeditationFiles.prototype.setMeditationtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.repeatedFields_ = [18,19];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.MeditationFile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.MeditationFile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    meditationtype: jspb.Message.getFieldWithDefault(msg, 2, 0),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    subtitle: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    durationofmeditationclip: jspb.Message.getFieldWithDefault(msg, 6, 0),
    tag: jspb.Message.getFieldWithDefault(msg, 7, ""),
    rating: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    likes: jspb.Message.getFieldWithDefault(msg, 9, 0),
    audiourl: jspb.Message.getFieldWithDefault(msg, 10, ""),
    imageurl: jspb.Message.getFieldWithDefault(msg, 11, ""),
    durationfile: (f = msg.getDurationfile()) && proto.unifiedplatform.guidedMeditation.DurationFile.toObject(includeInstance, f),
    purposefile: (f = msg.getPurposefile()) && proto.unifiedplatform.guidedMeditation.PurposeFile.toObject(includeInstance, f),
    thumbnailimgurl: jspb.Message.getFieldWithDefault(msg, 14, ""),
    audiourlv2: jspb.Message.getFieldWithDefault(msg, 15, ""),
    durationofinstructionclip: jspb.Message.getFieldWithDefault(msg, 16, 0),
    backgroundimageurl: jspb.Message.getFieldWithDefault(msg, 17, ""),
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    proto.unifiedplatform.guidedMeditation.Tag.toObject, includeInstance),
    tagidsList: (f = jspb.Message.getRepeatedField(msg, 19)) == null ? undefined : f,
    attribute: jspb.Message.getFieldWithDefault(msg, 20, ""),
    meditationcategory: jspb.Message.getFieldWithDefault(msg, 21, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFile}
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.MeditationFile;
  return proto.unifiedplatform.guidedMeditation.MeditationFile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.MeditationFile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFile}
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.MeditationType} */ (reader.readEnum());
      msg.setMeditationtype(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubtitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDurationofmeditationclip(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTag(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRating(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLikes(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAudiourl(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageurl(value);
      break;
    case 12:
      var value = new proto.unifiedplatform.guidedMeditation.DurationFile;
      reader.readMessage(value,proto.unifiedplatform.guidedMeditation.DurationFile.deserializeBinaryFromReader);
      msg.setDurationfile(value);
      break;
    case 13:
      var value = new proto.unifiedplatform.guidedMeditation.PurposeFile;
      reader.readMessage(value,proto.unifiedplatform.guidedMeditation.PurposeFile.deserializeBinaryFromReader);
      msg.setPurposefile(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbnailimgurl(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setAudiourlv2(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDurationofinstructionclip(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setBackgroundimageurl(value);
      break;
    case 18:
      var value = new proto.unifiedplatform.guidedMeditation.Tag;
      reader.readMessage(value,proto.unifiedplatform.guidedMeditation.Tag.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    case 19:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTagids(values[i]);
      }
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttribute(value);
      break;
    case 21:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.MeditationCategory} */ (reader.readEnum());
      msg.setMeditationcategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.MeditationFile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.MeditationFile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMeditationtype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSubtitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDurationofmeditationclip();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getTag();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getRating();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getLikes();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getAudiourl();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getImageurl();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getDurationfile();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.unifiedplatform.guidedMeditation.DurationFile.serializeBinaryToWriter
    );
  }
  f = message.getPurposefile();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.unifiedplatform.guidedMeditation.PurposeFile.serializeBinaryToWriter
    );
  }
  f = message.getThumbnailimgurl();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getAudiourlv2();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getDurationofinstructionclip();
  if (f !== 0) {
    writer.writeUint32(
      16,
      f
    );
  }
  f = message.getBackgroundimageurl();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      18,
      f,
      proto.unifiedplatform.guidedMeditation.Tag.serializeBinaryToWriter
    );
  }
  f = message.getTagidsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      19,
      f
    );
  }
  f = message.getAttribute();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getMeditationcategory();
  if (f !== 0.0) {
    writer.writeEnum(
      21,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFile} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.MeditationType meditationType = 2;
 * @return {!proto.unifiedplatform.heartintune.common.MeditationType}
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.getMeditationtype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.MeditationType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.MeditationType} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFile} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.setMeditationtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFile} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string subTitle = 4;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.getSubtitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFile} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.setSubtitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFile} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint32 durationOfMeditationClip = 6;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.getDurationofmeditationclip = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFile} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.setDurationofmeditationclip = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string tag = 7;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.getTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFile} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.setTag = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional float rating = 8;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.getRating = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFile} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.setRating = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional uint32 likes = 9;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.getLikes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFile} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.setLikes = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string audioUrl = 10;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.getAudiourl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFile} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.setAudiourl = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string imageUrl = 11;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.getImageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFile} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.setImageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional DurationFile durationFile = 12;
 * @return {?proto.unifiedplatform.guidedMeditation.DurationFile}
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.getDurationfile = function() {
  return /** @type{?proto.unifiedplatform.guidedMeditation.DurationFile} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.guidedMeditation.DurationFile, 12));
};


/**
 * @param {?proto.unifiedplatform.guidedMeditation.DurationFile|undefined} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFile} returns this
*/
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.setDurationfile = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFile} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.clearDurationfile = function() {
  return this.setDurationfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.hasDurationfile = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional PurposeFile purposeFile = 13;
 * @return {?proto.unifiedplatform.guidedMeditation.PurposeFile}
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.getPurposefile = function() {
  return /** @type{?proto.unifiedplatform.guidedMeditation.PurposeFile} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.guidedMeditation.PurposeFile, 13));
};


/**
 * @param {?proto.unifiedplatform.guidedMeditation.PurposeFile|undefined} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFile} returns this
*/
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.setPurposefile = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFile} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.clearPurposefile = function() {
  return this.setPurposefile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.hasPurposefile = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string thumbnailImgUrl = 14;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.getThumbnailimgurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFile} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.setThumbnailimgurl = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string audioUrlV2 = 15;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.getAudiourlv2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFile} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.setAudiourlv2 = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional uint32 durationOfInstructionClip = 16;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.getDurationofinstructionclip = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFile} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.setDurationofinstructionclip = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional string backgroundImageUrl = 17;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.getBackgroundimageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFile} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.setBackgroundimageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * repeated Tag tags = 18;
 * @return {!Array<!proto.unifiedplatform.guidedMeditation.Tag>}
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.guidedMeditation.Tag>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.guidedMeditation.Tag, 18));
};


/**
 * @param {!Array<!proto.unifiedplatform.guidedMeditation.Tag>} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFile} returns this
*/
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 18, value);
};


/**
 * @param {!proto.unifiedplatform.guidedMeditation.Tag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.guidedMeditation.Tag}
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 18, opt_value, proto.unifiedplatform.guidedMeditation.Tag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFile} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * repeated int64 tagIds = 19;
 * @return {!Array<number>}
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.getTagidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 19));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFile} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.setTagidsList = function(value) {
  return jspb.Message.setField(this, 19, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFile} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.addTagids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 19, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFile} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.clearTagidsList = function() {
  return this.setTagidsList([]);
};


/**
 * optional string attribute = 20;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.getAttribute = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFile} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.setAttribute = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional unifiedplatform.heartintune.common.MeditationCategory meditationCategory = 21;
 * @return {!proto.unifiedplatform.heartintune.common.MeditationCategory}
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.getMeditationcategory = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.MeditationCategory} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.MeditationCategory} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFile} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFile.prototype.setMeditationcategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 21, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest.repeatedFields_ = [13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    meditationtype: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    subtitle: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    durationofmeditationclip: jspb.Message.getFieldWithDefault(msg, 5, 0),
    tag: jspb.Message.getFieldWithDefault(msg, 6, ""),
    rating: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    likes: jspb.Message.getFieldWithDefault(msg, 8, 0),
    audiourl: jspb.Message.getFieldWithDefault(msg, 9, ""),
    imageurl: jspb.Message.getFieldWithDefault(msg, 10, ""),
    purposefileid: jspb.Message.getFieldWithDefault(msg, 11, 0),
    durationfileid: jspb.Message.getFieldWithDefault(msg, 12, 0),
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    proto.unifiedplatform.guidedMeditation.Tag.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest;
  return proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.MeditationType} */ (reader.readEnum());
      msg.setMeditationtype(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubtitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDurationofmeditationclip(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTag(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRating(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLikes(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAudiourl(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageurl(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPurposefileid(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDurationfileid(value);
      break;
    case 13:
      var value = new proto.unifiedplatform.guidedMeditation.Tag;
      reader.readMessage(value,proto.unifiedplatform.guidedMeditation.Tag.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeditationtype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSubtitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDurationofmeditationclip();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getTag();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRating();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getLikes();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getAudiourl();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getImageurl();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getPurposefileid();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getDurationfileid();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.unifiedplatform.guidedMeditation.Tag.serializeBinaryToWriter
    );
  }
};


/**
 * optional unifiedplatform.heartintune.common.MeditationType meditationType = 1;
 * @return {!proto.unifiedplatform.heartintune.common.MeditationType}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest.prototype.getMeditationtype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.MeditationType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.MeditationType} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest.prototype.setMeditationtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string subTitle = 3;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest.prototype.getSubtitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest.prototype.setSubtitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint32 durationOfMeditationClip = 5;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest.prototype.getDurationofmeditationclip = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest.prototype.setDurationofmeditationclip = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string tag = 6;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest.prototype.getTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest.prototype.setTag = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional float rating = 7;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest.prototype.getRating = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest.prototype.setRating = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional uint32 likes = 8;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest.prototype.getLikes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest.prototype.setLikes = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string audioUrl = 9;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest.prototype.getAudiourl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest.prototype.setAudiourl = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string imageUrl = 10;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest.prototype.getImageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest.prototype.setImageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int64 purposeFileID = 11;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest.prototype.getPurposefileid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest.prototype.setPurposefileid = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 durationFileID = 12;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest.prototype.getDurationfileid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest.prototype.setDurationfileid = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * repeated Tag tags = 13;
 * @return {!Array<!proto.unifiedplatform.guidedMeditation.Tag>}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.guidedMeditation.Tag>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.guidedMeditation.Tag, 13));
};


/**
 * @param {!Array<!proto.unifiedplatform.guidedMeditation.Tag>} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest} returns this
*/
proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.unifiedplatform.guidedMeditation.Tag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.guidedMeditation.Tag}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.unifiedplatform.guidedMeditation.Tag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileRequest.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.PurposeFile.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.PurposeFile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.PurposeFile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.PurposeFile.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    purpose: jspb.Message.getFieldWithDefault(msg, 2, ""),
    imageurl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    withoutborderimageurl: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.PurposeFile}
 */
proto.unifiedplatform.guidedMeditation.PurposeFile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.PurposeFile;
  return proto.unifiedplatform.guidedMeditation.PurposeFile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.PurposeFile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.PurposeFile}
 */
proto.unifiedplatform.guidedMeditation.PurposeFile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPurpose(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageurl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setWithoutborderimageurl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.PurposeFile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.PurposeFile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.PurposeFile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.PurposeFile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPurpose();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getImageurl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getWithoutborderimageurl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.PurposeFile.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.PurposeFile} returns this
 */
proto.unifiedplatform.guidedMeditation.PurposeFile.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string purpose = 2;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.PurposeFile.prototype.getPurpose = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.PurposeFile} returns this
 */
proto.unifiedplatform.guidedMeditation.PurposeFile.prototype.setPurpose = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string imageURL = 3;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.PurposeFile.prototype.getImageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.PurposeFile} returns this
 */
proto.unifiedplatform.guidedMeditation.PurposeFile.prototype.setImageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string withoutBorderImageUrl = 4;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.PurposeFile.prototype.getWithoutborderimageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.PurposeFile} returns this
 */
proto.unifiedplatform.guidedMeditation.PurposeFile.prototype.setWithoutborderimageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.DurationFile.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.DurationFile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.DurationFile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.DurationFile.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    durationinseconds: jspb.Message.getFieldWithDefault(msg, 2, 0),
    imageurl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    selectedimageurl: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.DurationFile}
 */
proto.unifiedplatform.guidedMeditation.DurationFile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.DurationFile;
  return proto.unifiedplatform.guidedMeditation.DurationFile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.DurationFile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.DurationFile}
 */
proto.unifiedplatform.guidedMeditation.DurationFile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDurationinseconds(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageurl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSelectedimageurl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.DurationFile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.DurationFile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.DurationFile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.DurationFile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDurationinseconds();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getImageurl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSelectedimageurl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.DurationFile.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.DurationFile} returns this
 */
proto.unifiedplatform.guidedMeditation.DurationFile.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 durationInSeconds = 2;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.DurationFile.prototype.getDurationinseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.DurationFile} returns this
 */
proto.unifiedplatform.guidedMeditation.DurationFile.prototype.setDurationinseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string imageURL = 3;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.DurationFile.prototype.getImageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.DurationFile} returns this
 */
proto.unifiedplatform.guidedMeditation.DurationFile.prototype.setImageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string selectedImageURL = 4;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.DurationFile.prototype.getSelectedimageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.DurationFile} returns this
 */
proto.unifiedplatform.guidedMeditation.DurationFile.prototype.setSelectedimageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.AddPurposeFileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.AddPurposeFileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.AddPurposeFileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.AddPurposeFileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    purpose: jspb.Message.getFieldWithDefault(msg, 1, ""),
    imageurl: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.AddPurposeFileRequest}
 */
proto.unifiedplatform.guidedMeditation.AddPurposeFileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.AddPurposeFileRequest;
  return proto.unifiedplatform.guidedMeditation.AddPurposeFileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.AddPurposeFileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.AddPurposeFileRequest}
 */
proto.unifiedplatform.guidedMeditation.AddPurposeFileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPurpose(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageurl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.AddPurposeFileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.AddPurposeFileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.AddPurposeFileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.AddPurposeFileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPurpose();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getImageurl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string purpose = 1;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.AddPurposeFileRequest.prototype.getPurpose = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddPurposeFileRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.AddPurposeFileRequest.prototype.setPurpose = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string imageURL = 2;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.AddPurposeFileRequest.prototype.getImageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddPurposeFileRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.AddPurposeFileRequest.prototype.setImageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.AddDurationFileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.AddDurationFileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.AddDurationFileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.AddDurationFileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    durationinseconds: jspb.Message.getFieldWithDefault(msg, 1, 0),
    imageurl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    selectedimageurl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.AddDurationFileRequest}
 */
proto.unifiedplatform.guidedMeditation.AddDurationFileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.AddDurationFileRequest;
  return proto.unifiedplatform.guidedMeditation.AddDurationFileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.AddDurationFileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.AddDurationFileRequest}
 */
proto.unifiedplatform.guidedMeditation.AddDurationFileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDurationinseconds(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageurl(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSelectedimageurl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.AddDurationFileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.AddDurationFileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.AddDurationFileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.AddDurationFileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDurationinseconds();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getImageurl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSelectedimageurl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 durationInSeconds = 1;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.AddDurationFileRequest.prototype.getDurationinseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddDurationFileRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.AddDurationFileRequest.prototype.setDurationinseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string imageURL = 2;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.AddDurationFileRequest.prototype.getImageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddDurationFileRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.AddDurationFileRequest.prototype.setImageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string selectedImageURL = 3;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.AddDurationFileRequest.prototype.getSelectedimageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddDurationFileRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.AddDurationFileRequest.prototype.setSelectedimageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.guidedMeditation.SelectedFiles.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.SelectedFiles.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.SelectedFiles.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.SelectedFiles} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.SelectedFiles.toObject = function(includeInstance, msg) {
  var f, obj = {
    selectedmeditationfileList: jspb.Message.toObjectList(msg.getSelectedmeditationfileList(),
    proto.unifiedplatform.guidedMeditation.MeditationFile.toObject, includeInstance),
    selectedpagesize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    selectedpagenumber: jspb.Message.getFieldWithDefault(msg, 3, 0),
    totalcount: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.SelectedFiles}
 */
proto.unifiedplatform.guidedMeditation.SelectedFiles.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.SelectedFiles;
  return proto.unifiedplatform.guidedMeditation.SelectedFiles.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.SelectedFiles} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.SelectedFiles}
 */
proto.unifiedplatform.guidedMeditation.SelectedFiles.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.guidedMeditation.MeditationFile;
      reader.readMessage(value,proto.unifiedplatform.guidedMeditation.MeditationFile.deserializeBinaryFromReader);
      msg.addSelectedmeditationfile(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSelectedpagesize(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSelectedpagenumber(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalcount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.SelectedFiles.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.SelectedFiles.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.SelectedFiles} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.SelectedFiles.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSelectedmeditationfileList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.guidedMeditation.MeditationFile.serializeBinaryToWriter
    );
  }
  f = message.getSelectedpagesize();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getSelectedpagenumber();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getTotalcount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * repeated MeditationFile selectedMeditationFile = 1;
 * @return {!Array<!proto.unifiedplatform.guidedMeditation.MeditationFile>}
 */
proto.unifiedplatform.guidedMeditation.SelectedFiles.prototype.getSelectedmeditationfileList = function() {
  return /** @type{!Array<!proto.unifiedplatform.guidedMeditation.MeditationFile>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.guidedMeditation.MeditationFile, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.guidedMeditation.MeditationFile>} value
 * @return {!proto.unifiedplatform.guidedMeditation.SelectedFiles} returns this
*/
proto.unifiedplatform.guidedMeditation.SelectedFiles.prototype.setSelectedmeditationfileList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.guidedMeditation.MeditationFile=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFile}
 */
proto.unifiedplatform.guidedMeditation.SelectedFiles.prototype.addSelectedmeditationfile = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.guidedMeditation.MeditationFile, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.guidedMeditation.SelectedFiles} returns this
 */
proto.unifiedplatform.guidedMeditation.SelectedFiles.prototype.clearSelectedmeditationfileList = function() {
  return this.setSelectedmeditationfileList([]);
};


/**
 * optional uint32 selectedPageSize = 2;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.SelectedFiles.prototype.getSelectedpagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.SelectedFiles} returns this
 */
proto.unifiedplatform.guidedMeditation.SelectedFiles.prototype.setSelectedpagesize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 selectedPageNumber = 3;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.SelectedFiles.prototype.getSelectedpagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.SelectedFiles} returns this
 */
proto.unifiedplatform.guidedMeditation.SelectedFiles.prototype.setSelectedpagenumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 totalCount = 4;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.SelectedFiles.prototype.getTotalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.SelectedFiles} returns this
 */
proto.unifiedplatform.guidedMeditation.SelectedFiles.prototype.setTotalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFiles.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFiles.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFiles.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFiles} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFiles.toObject = function(includeInstance, msg) {
  var f, obj = {
    listofpurposefileList: jspb.Message.toObjectList(msg.getListofpurposefileList(),
    proto.unifiedplatform.guidedMeditation.PurposeFile.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFiles}
 */
proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFiles.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFiles;
  return proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFiles.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFiles} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFiles}
 */
proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFiles.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.guidedMeditation.PurposeFile;
      reader.readMessage(value,proto.unifiedplatform.guidedMeditation.PurposeFile.deserializeBinaryFromReader);
      msg.addListofpurposefile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFiles.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFiles.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFiles} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFiles.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListofpurposefileList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.guidedMeditation.PurposeFile.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PurposeFile listOfPurposeFile = 1;
 * @return {!Array<!proto.unifiedplatform.guidedMeditation.PurposeFile>}
 */
proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFiles.prototype.getListofpurposefileList = function() {
  return /** @type{!Array<!proto.unifiedplatform.guidedMeditation.PurposeFile>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.guidedMeditation.PurposeFile, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.guidedMeditation.PurposeFile>} value
 * @return {!proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFiles} returns this
*/
proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFiles.prototype.setListofpurposefileList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.guidedMeditation.PurposeFile=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.guidedMeditation.PurposeFile}
 */
proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFiles.prototype.addListofpurposefile = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.guidedMeditation.PurposeFile, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFiles} returns this
 */
proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFiles.prototype.clearListofpurposefileList = function() {
  return this.setListofpurposefileList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.guidedMeditation.ResponseAllDurationFiles.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.ResponseAllDurationFiles.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.ResponseAllDurationFiles.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.ResponseAllDurationFiles} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.ResponseAllDurationFiles.toObject = function(includeInstance, msg) {
  var f, obj = {
    listofdurationfilesList: jspb.Message.toObjectList(msg.getListofdurationfilesList(),
    proto.unifiedplatform.guidedMeditation.DurationFile.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.ResponseAllDurationFiles}
 */
proto.unifiedplatform.guidedMeditation.ResponseAllDurationFiles.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.ResponseAllDurationFiles;
  return proto.unifiedplatform.guidedMeditation.ResponseAllDurationFiles.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.ResponseAllDurationFiles} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.ResponseAllDurationFiles}
 */
proto.unifiedplatform.guidedMeditation.ResponseAllDurationFiles.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.guidedMeditation.DurationFile;
      reader.readMessage(value,proto.unifiedplatform.guidedMeditation.DurationFile.deserializeBinaryFromReader);
      msg.addListofdurationfiles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.ResponseAllDurationFiles.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.ResponseAllDurationFiles.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.ResponseAllDurationFiles} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.ResponseAllDurationFiles.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListofdurationfilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.guidedMeditation.DurationFile.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DurationFile listOfDurationFiles = 1;
 * @return {!Array<!proto.unifiedplatform.guidedMeditation.DurationFile>}
 */
proto.unifiedplatform.guidedMeditation.ResponseAllDurationFiles.prototype.getListofdurationfilesList = function() {
  return /** @type{!Array<!proto.unifiedplatform.guidedMeditation.DurationFile>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.guidedMeditation.DurationFile, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.guidedMeditation.DurationFile>} value
 * @return {!proto.unifiedplatform.guidedMeditation.ResponseAllDurationFiles} returns this
*/
proto.unifiedplatform.guidedMeditation.ResponseAllDurationFiles.prototype.setListofdurationfilesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.guidedMeditation.DurationFile=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.guidedMeditation.DurationFile}
 */
proto.unifiedplatform.guidedMeditation.ResponseAllDurationFiles.prototype.addListofdurationfiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.guidedMeditation.DurationFile, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.guidedMeditation.ResponseAllDurationFiles} returns this
 */
proto.unifiedplatform.guidedMeditation.ResponseAllDurationFiles.prototype.clearListofdurationfilesList = function() {
  return this.setListofdurationfilesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileAndUserID.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.MeditationFileAndUserID.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.MeditationFileAndUserID} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.MeditationFileAndUserID.toObject = function(includeInstance, msg) {
  var f, obj = {
    meditationfileid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileAndUserID}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileAndUserID.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.MeditationFileAndUserID;
  return proto.unifiedplatform.guidedMeditation.MeditationFileAndUserID.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.MeditationFileAndUserID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileAndUserID}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileAndUserID.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMeditationfileid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileAndUserID.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.MeditationFileAndUserID.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.MeditationFileAndUserID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.MeditationFileAndUserID.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeditationfileid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 meditationFileID = 1;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileAndUserID.prototype.getMeditationfileid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileAndUserID} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileAndUserID.prototype.setMeditationfileid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 userID = 2;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileAndUserID.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileAndUserID} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileAndUserID.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.ID.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.ID.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.ID} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.ID.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.ID}
 */
proto.unifiedplatform.guidedMeditation.ID.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.ID;
  return proto.unifiedplatform.guidedMeditation.ID.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.ID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.ID}
 */
proto.unifiedplatform.guidedMeditation.ID.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.ID.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.ID.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.ID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.ID.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 ID = 1;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.ID.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.ID} returns this
 */
proto.unifiedplatform.guidedMeditation.ID.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.ResponseStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.ResponseStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.ResponseStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.ResponseStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    statussuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.ResponseStatus}
 */
proto.unifiedplatform.guidedMeditation.ResponseStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.ResponseStatus;
  return proto.unifiedplatform.guidedMeditation.ResponseStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.ResponseStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.ResponseStatus}
 */
proto.unifiedplatform.guidedMeditation.ResponseStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatussuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.ResponseStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.ResponseStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.ResponseStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.ResponseStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatussuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool statusSuccess = 1;
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.ResponseStatus.prototype.getStatussuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.guidedMeditation.ResponseStatus} returns this
 */
proto.unifiedplatform.guidedMeditation.ResponseStatus.prototype.setStatussuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.DeleteMeditationFileByIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.DeleteMeditationFileByIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.DeleteMeditationFileByIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.DeleteMeditationFileByIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    meditationfile: (f = msg.getMeditationfile()) && proto.unifiedplatform.guidedMeditation.MeditationFile.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.DeleteMeditationFileByIdResponse}
 */
proto.unifiedplatform.guidedMeditation.DeleteMeditationFileByIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.DeleteMeditationFileByIdResponse;
  return proto.unifiedplatform.guidedMeditation.DeleteMeditationFileByIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.DeleteMeditationFileByIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.DeleteMeditationFileByIdResponse}
 */
proto.unifiedplatform.guidedMeditation.DeleteMeditationFileByIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.guidedMeditation.MeditationFile;
      reader.readMessage(value,proto.unifiedplatform.guidedMeditation.MeditationFile.deserializeBinaryFromReader);
      msg.setMeditationfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.DeleteMeditationFileByIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.DeleteMeditationFileByIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.DeleteMeditationFileByIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.DeleteMeditationFileByIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMeditationfile();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.guidedMeditation.MeditationFile.serializeBinaryToWriter
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.DeleteMeditationFileByIdResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.DeleteMeditationFileByIdResponse} returns this
 */
proto.unifiedplatform.guidedMeditation.DeleteMeditationFileByIdResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional MeditationFile meditationFile = 2;
 * @return {?proto.unifiedplatform.guidedMeditation.MeditationFile}
 */
proto.unifiedplatform.guidedMeditation.DeleteMeditationFileByIdResponse.prototype.getMeditationfile = function() {
  return /** @type{?proto.unifiedplatform.guidedMeditation.MeditationFile} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.guidedMeditation.MeditationFile, 2));
};


/**
 * @param {?proto.unifiedplatform.guidedMeditation.MeditationFile|undefined} value
 * @return {!proto.unifiedplatform.guidedMeditation.DeleteMeditationFileByIdResponse} returns this
*/
proto.unifiedplatform.guidedMeditation.DeleteMeditationFileByIdResponse.prototype.setMeditationfile = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.guidedMeditation.DeleteMeditationFileByIdResponse} returns this
 */
proto.unifiedplatform.guidedMeditation.DeleteMeditationFileByIdResponse.prototype.clearMeditationfile = function() {
  return this.setMeditationfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.DeleteMeditationFileByIdResponse.prototype.hasMeditationfile = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.DeletePurposeFileByIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.DeletePurposeFileByIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.DeletePurposeFileByIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.DeletePurposeFileByIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    purposefile: (f = msg.getPurposefile()) && proto.unifiedplatform.guidedMeditation.PurposeFile.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.DeletePurposeFileByIdResponse}
 */
proto.unifiedplatform.guidedMeditation.DeletePurposeFileByIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.DeletePurposeFileByIdResponse;
  return proto.unifiedplatform.guidedMeditation.DeletePurposeFileByIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.DeletePurposeFileByIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.DeletePurposeFileByIdResponse}
 */
proto.unifiedplatform.guidedMeditation.DeletePurposeFileByIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.guidedMeditation.PurposeFile;
      reader.readMessage(value,proto.unifiedplatform.guidedMeditation.PurposeFile.deserializeBinaryFromReader);
      msg.setPurposefile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.DeletePurposeFileByIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.DeletePurposeFileByIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.DeletePurposeFileByIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.DeletePurposeFileByIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPurposefile();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.guidedMeditation.PurposeFile.serializeBinaryToWriter
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.DeletePurposeFileByIdResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.DeletePurposeFileByIdResponse} returns this
 */
proto.unifiedplatform.guidedMeditation.DeletePurposeFileByIdResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PurposeFile PurposeFile = 2;
 * @return {?proto.unifiedplatform.guidedMeditation.PurposeFile}
 */
proto.unifiedplatform.guidedMeditation.DeletePurposeFileByIdResponse.prototype.getPurposefile = function() {
  return /** @type{?proto.unifiedplatform.guidedMeditation.PurposeFile} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.guidedMeditation.PurposeFile, 2));
};


/**
 * @param {?proto.unifiedplatform.guidedMeditation.PurposeFile|undefined} value
 * @return {!proto.unifiedplatform.guidedMeditation.DeletePurposeFileByIdResponse} returns this
*/
proto.unifiedplatform.guidedMeditation.DeletePurposeFileByIdResponse.prototype.setPurposefile = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.guidedMeditation.DeletePurposeFileByIdResponse} returns this
 */
proto.unifiedplatform.guidedMeditation.DeletePurposeFileByIdResponse.prototype.clearPurposefile = function() {
  return this.setPurposefile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.DeletePurposeFileByIdResponse.prototype.hasPurposefile = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.repeatedFields_ = [14,15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    meditationtype: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: (f = msg.getTitle()) && common_common_pb.Locale.toObject(includeInstance, f),
    subtitle: (f = msg.getSubtitle()) && common_common_pb.Locale.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && common_common_pb.Locale.toObject(includeInstance, f),
    durationofmeditationclip: jspb.Message.getFieldWithDefault(msg, 5, 0),
    tag: jspb.Message.getFieldWithDefault(msg, 6, ""),
    rating: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    likes: jspb.Message.getFieldWithDefault(msg, 8, 0),
    audiourl: (f = msg.getAudiourl()) && common_common_pb.Locale.toObject(includeInstance, f),
    imageurl: (f = msg.getImageurl()) && common_common_pb.Locale.toObject(includeInstance, f),
    purposefileid: jspb.Message.getFieldWithDefault(msg, 11, 0),
    durationfileid: jspb.Message.getFieldWithDefault(msg, 12, 0),
    thumbnailimgurl: (f = msg.getThumbnailimgurl()) && common_common_pb.Locale.toObject(includeInstance, f),
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    proto.unifiedplatform.guidedMeditation.Tag.toObject, includeInstance),
    tagidsList: (f = jspb.Message.getRepeatedField(msg, 15)) == null ? undefined : f,
    attribute: (f = msg.getAttribute()) && common_common_pb.Locale.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest;
  return proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.MeditationType} */ (reader.readEnum());
      msg.setMeditationtype(value);
      break;
    case 2:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setTitle(value);
      break;
    case 3:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setSubtitle(value);
      break;
    case 4:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDurationofmeditationclip(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTag(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRating(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLikes(value);
      break;
    case 9:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setAudiourl(value);
      break;
    case 10:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setImageurl(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPurposefileid(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDurationfileid(value);
      break;
    case 13:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setThumbnailimgurl(value);
      break;
    case 14:
      var value = new proto.unifiedplatform.guidedMeditation.Tag;
      reader.readMessage(value,proto.unifiedplatform.guidedMeditation.Tag.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    case 15:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTagids(values[i]);
      }
      break;
    case 16:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setAttribute(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeditationtype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getSubtitle();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getDurationofmeditationclip();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getTag();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRating();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getLikes();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getAudiourl();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getImageurl();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getPurposefileid();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getDurationfileid();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getThumbnailimgurl();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.unifiedplatform.guidedMeditation.Tag.serializeBinaryToWriter
    );
  }
  f = message.getTagidsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      15,
      f
    );
  }
  f = message.getAttribute();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
};


/**
 * optional unifiedplatform.heartintune.common.MeditationType meditationType = 1;
 * @return {!proto.unifiedplatform.heartintune.common.MeditationType}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.getMeditationtype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.MeditationType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.MeditationType} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.setMeditationtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.Locale title = 2;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.getTitle = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 2));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest} returns this
*/
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.setTitle = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.clearTitle = function() {
  return this.setTitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale subTitle = 3;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.getSubtitle = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 3));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest} returns this
*/
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.setSubtitle = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.clearSubtitle = function() {
  return this.setSubtitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.hasSubtitle = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale description = 4;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.getDescription = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 4));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest} returns this
*/
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional uint32 durationOfMeditationClip = 5;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.getDurationofmeditationclip = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.setDurationofmeditationclip = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string tag = 6;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.getTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.setTag = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional float rating = 7;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.getRating = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.setRating = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional uint32 likes = 8;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.getLikes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.setLikes = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional unifiedplatform.heartintune.common.Locale audioUrl = 9;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.getAudiourl = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 9));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest} returns this
*/
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.setAudiourl = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.clearAudiourl = function() {
  return this.setAudiourl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.hasAudiourl = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale imageUrl = 10;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.getImageurl = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 10));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest} returns this
*/
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.setImageurl = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.clearImageurl = function() {
  return this.setImageurl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.hasImageurl = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional int64 purposeFileID = 11;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.getPurposefileid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.setPurposefileid = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 durationFileID = 12;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.getDurationfileid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.setDurationfileid = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional unifiedplatform.heartintune.common.Locale thumbnailImgUrl = 13;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.getThumbnailimgurl = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 13));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest} returns this
*/
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.setThumbnailimgurl = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.clearThumbnailimgurl = function() {
  return this.setThumbnailimgurl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.hasThumbnailimgurl = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * repeated Tag tags = 14;
 * @return {!Array<!proto.unifiedplatform.guidedMeditation.Tag>}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.guidedMeditation.Tag>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.guidedMeditation.Tag, 14));
};


/**
 * @param {!Array<!proto.unifiedplatform.guidedMeditation.Tag>} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest} returns this
*/
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.unifiedplatform.guidedMeditation.Tag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.guidedMeditation.Tag}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.unifiedplatform.guidedMeditation.Tag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * repeated int64 tagIds = 15;
 * @return {!Array<number>}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.getTagidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 15));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.setTagidsList = function(value) {
  return jspb.Message.setField(this, 15, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.addTagids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 15, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.clearTagidsList = function() {
  return this.setTagidsList([]);
};


/**
 * optional unifiedplatform.heartintune.common.Locale attribute = 16;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.getAttribute = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 16));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest} returns this
*/
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.setAttribute = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.clearAttribute = function() {
  return this.setAttribute(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequest.prototype.hasAttribute = function() {
  return jspb.Message.getField(this, 16) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.repeatedFields_ = [15,16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.MeditationFileDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.MeditationFileDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    meditationtype: jspb.Message.getFieldWithDefault(msg, 2, 0),
    title: (f = msg.getTitle()) && common_common_pb.Locale.toObject(includeInstance, f),
    subtitle: (f = msg.getSubtitle()) && common_common_pb.Locale.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && common_common_pb.Locale.toObject(includeInstance, f),
    durationofmeditationclip: jspb.Message.getFieldWithDefault(msg, 6, 0),
    tag: jspb.Message.getFieldWithDefault(msg, 7, ""),
    rating: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    likes: jspb.Message.getFieldWithDefault(msg, 9, 0),
    audiourl: (f = msg.getAudiourl()) && common_common_pb.Locale.toObject(includeInstance, f),
    imageurl: (f = msg.getImageurl()) && common_common_pb.Locale.toObject(includeInstance, f),
    thumbnailimgurl: (f = msg.getThumbnailimgurl()) && common_common_pb.Locale.toObject(includeInstance, f),
    purposefileid: jspb.Message.getFieldWithDefault(msg, 13, 0),
    durationfileid: jspb.Message.getFieldWithDefault(msg, 14, 0),
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    proto.unifiedplatform.guidedMeditation.Tag.toObject, includeInstance),
    tagidsList: (f = jspb.Message.getRepeatedField(msg, 16)) == null ? undefined : f,
    attribute: (f = msg.getAttribute()) && common_common_pb.Locale.toObject(includeInstance, f),
    meditationcategory: jspb.Message.getFieldWithDefault(msg, 21, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetails}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.MeditationFileDetails;
  return proto.unifiedplatform.guidedMeditation.MeditationFileDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.MeditationFileDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetails}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.MeditationType} */ (reader.readEnum());
      msg.setMeditationtype(value);
      break;
    case 3:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setTitle(value);
      break;
    case 4:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setSubtitle(value);
      break;
    case 5:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDurationofmeditationclip(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTag(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRating(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLikes(value);
      break;
    case 10:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setAudiourl(value);
      break;
    case 11:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setImageurl(value);
      break;
    case 12:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setThumbnailimgurl(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPurposefileid(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDurationfileid(value);
      break;
    case 15:
      var value = new proto.unifiedplatform.guidedMeditation.Tag;
      reader.readMessage(value,proto.unifiedplatform.guidedMeditation.Tag.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    case 16:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTagids(values[i]);
      }
      break;
    case 17:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setAttribute(value);
      break;
    case 21:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.MeditationCategory} */ (reader.readEnum());
      msg.setMeditationcategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.MeditationFileDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.MeditationFileDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMeditationtype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getSubtitle();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getDurationofmeditationclip();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getTag();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getRating();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getLikes();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getAudiourl();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getImageurl();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getThumbnailimgurl();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getPurposefileid();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getDurationfileid();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.unifiedplatform.guidedMeditation.Tag.serializeBinaryToWriter
    );
  }
  f = message.getTagidsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      16,
      f
    );
  }
  f = message.getAttribute();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getMeditationcategory();
  if (f !== 0.0) {
    writer.writeEnum(
      21,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetails} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.MeditationType meditationType = 2;
 * @return {!proto.unifiedplatform.heartintune.common.MeditationType}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.getMeditationtype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.MeditationType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.MeditationType} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetails} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.setMeditationtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional unifiedplatform.heartintune.common.Locale title = 3;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.getTitle = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 3));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetails} returns this
*/
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.setTitle = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetails} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.clearTitle = function() {
  return this.setTitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale subTitle = 4;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.getSubtitle = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 4));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetails} returns this
*/
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.setSubtitle = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetails} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.clearSubtitle = function() {
  return this.setSubtitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.hasSubtitle = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale description = 5;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.getDescription = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 5));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetails} returns this
*/
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetails} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional uint32 durationOfMeditationClip = 6;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.getDurationofmeditationclip = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetails} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.setDurationofmeditationclip = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string tag = 7;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.getTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetails} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.setTag = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional float rating = 8;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.getRating = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetails} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.setRating = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional uint32 likes = 9;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.getLikes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetails} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.setLikes = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional unifiedplatform.heartintune.common.Locale audioUrl = 10;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.getAudiourl = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 10));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetails} returns this
*/
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.setAudiourl = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetails} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.clearAudiourl = function() {
  return this.setAudiourl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.hasAudiourl = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale imageUrl = 11;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.getImageurl = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 11));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetails} returns this
*/
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.setImageurl = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetails} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.clearImageurl = function() {
  return this.setImageurl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.hasImageurl = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale thumbnailImgUrl = 12;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.getThumbnailimgurl = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 12));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetails} returns this
*/
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.setThumbnailimgurl = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetails} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.clearThumbnailimgurl = function() {
  return this.setThumbnailimgurl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.hasThumbnailimgurl = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional int64 purposeFileID = 13;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.getPurposefileid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetails} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.setPurposefileid = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int64 durationFileID = 14;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.getDurationfileid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetails} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.setDurationfileid = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * repeated Tag tags = 15;
 * @return {!Array<!proto.unifiedplatform.guidedMeditation.Tag>}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.guidedMeditation.Tag>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.guidedMeditation.Tag, 15));
};


/**
 * @param {!Array<!proto.unifiedplatform.guidedMeditation.Tag>} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetails} returns this
*/
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.unifiedplatform.guidedMeditation.Tag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.guidedMeditation.Tag}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.unifiedplatform.guidedMeditation.Tag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetails} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * repeated int64 tagIds = 16;
 * @return {!Array<number>}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.getTagidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 16));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetails} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.setTagidsList = function(value) {
  return jspb.Message.setField(this, 16, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetails} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.addTagids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 16, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetails} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.clearTagidsList = function() {
  return this.setTagidsList([]);
};


/**
 * optional unifiedplatform.heartintune.common.Locale attribute = 17;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.getAttribute = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 17));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetails} returns this
*/
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.setAttribute = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetails} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.clearAttribute = function() {
  return this.setAttribute(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.hasAttribute = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional unifiedplatform.heartintune.common.MeditationCategory meditationCategory = 21;
 * @return {!proto.unifiedplatform.heartintune.common.MeditationCategory}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.getMeditationcategory = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.MeditationCategory} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.MeditationCategory} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetails} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetails.prototype.setMeditationcategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 21, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meditationfiledetailsresponseList: jspb.Message.toObjectList(msg.getMeditationfiledetailsresponseList(),
    proto.unifiedplatform.guidedMeditation.MeditationFile.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponse}
 */
proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponse;
  return proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponse}
 */
proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.guidedMeditation.MeditationFile;
      reader.readMessage(value,proto.unifiedplatform.guidedMeditation.MeditationFile.deserializeBinaryFromReader);
      msg.addMeditationfiledetailsresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeditationfiledetailsresponseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.guidedMeditation.MeditationFile.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MeditationFile meditationFileDetailsResponse = 1;
 * @return {!Array<!proto.unifiedplatform.guidedMeditation.MeditationFile>}
 */
proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponse.prototype.getMeditationfiledetailsresponseList = function() {
  return /** @type{!Array<!proto.unifiedplatform.guidedMeditation.MeditationFile>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.guidedMeditation.MeditationFile, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.guidedMeditation.MeditationFile>} value
 * @return {!proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponse} returns this
*/
proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponse.prototype.setMeditationfiledetailsresponseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.guidedMeditation.MeditationFile=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFile}
 */
proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponse.prototype.addMeditationfiledetailsresponse = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.guidedMeditation.MeditationFile, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponse} returns this
 */
proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponse.prototype.clearMeditationfiledetailsresponseList = function() {
  return this.setMeditationfiledetailsresponseList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    meditationtype: jspb.Message.getFieldWithDefault(msg, 2, 0),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    subtitle: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    durationofmeditationclip: jspb.Message.getFieldWithDefault(msg, 6, 0),
    tag: jspb.Message.getFieldWithDefault(msg, 7, ""),
    rating: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    likes: jspb.Message.getFieldWithDefault(msg, 9, 0),
    audiourl: jspb.Message.getFieldWithDefault(msg, 10, ""),
    imageurl: jspb.Message.getFieldWithDefault(msg, 11, ""),
    thumbnailimgurl: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse;
  return proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.MeditationType} */ (reader.readEnum());
      msg.setMeditationtype(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubtitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDurationofmeditationclip(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTag(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRating(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLikes(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAudiourl(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageurl(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbnailimgurl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMeditationtype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSubtitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDurationofmeditationclip();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getTag();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getRating();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getLikes();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getAudiourl();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getImageurl();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getThumbnailimgurl();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.MeditationType meditationType = 2;
 * @return {!proto.unifiedplatform.heartintune.common.MeditationType}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse.prototype.getMeditationtype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.MeditationType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.MeditationType} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse.prototype.setMeditationtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string subTitle = 4;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse.prototype.getSubtitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse.prototype.setSubtitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint32 durationOfMeditationClip = 6;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse.prototype.getDurationofmeditationclip = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse.prototype.setDurationofmeditationclip = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string tag = 7;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse.prototype.getTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse.prototype.setTag = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional float rating = 8;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse.prototype.getRating = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse.prototype.setRating = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional uint32 likes = 9;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse.prototype.getLikes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse.prototype.setLikes = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string audioUrl = 10;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse.prototype.getAudiourl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse.prototype.setAudiourl = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string imageUrl = 11;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse.prototype.getImageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse.prototype.setImageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string thumbnailImgUrl = 12;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse.prototype.getThumbnailimgurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsResponse.prototype.setThumbnailimgurl = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.guidedMeditation.SelectedMeditationFiles.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.SelectedMeditationFiles.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.SelectedMeditationFiles.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.SelectedMeditationFiles} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.SelectedMeditationFiles.toObject = function(includeInstance, msg) {
  var f, obj = {
    selectedmeditationfileList: jspb.Message.toObjectList(msg.getSelectedmeditationfileList(),
    proto.unifiedplatform.guidedMeditation.MeditationFileDetails.toObject, includeInstance),
    selectedpagesize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    selectedpagenumber: jspb.Message.getFieldWithDefault(msg, 3, 0),
    totalcount: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.SelectedMeditationFiles}
 */
proto.unifiedplatform.guidedMeditation.SelectedMeditationFiles.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.SelectedMeditationFiles;
  return proto.unifiedplatform.guidedMeditation.SelectedMeditationFiles.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.SelectedMeditationFiles} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.SelectedMeditationFiles}
 */
proto.unifiedplatform.guidedMeditation.SelectedMeditationFiles.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.guidedMeditation.MeditationFileDetails;
      reader.readMessage(value,proto.unifiedplatform.guidedMeditation.MeditationFileDetails.deserializeBinaryFromReader);
      msg.addSelectedmeditationfile(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSelectedpagesize(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSelectedpagenumber(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalcount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.SelectedMeditationFiles.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.SelectedMeditationFiles.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.SelectedMeditationFiles} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.SelectedMeditationFiles.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSelectedmeditationfileList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.guidedMeditation.MeditationFileDetails.serializeBinaryToWriter
    );
  }
  f = message.getSelectedpagesize();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getSelectedpagenumber();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getTotalcount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * repeated MeditationFileDetails selectedMeditationFile = 1;
 * @return {!Array<!proto.unifiedplatform.guidedMeditation.MeditationFileDetails>}
 */
proto.unifiedplatform.guidedMeditation.SelectedMeditationFiles.prototype.getSelectedmeditationfileList = function() {
  return /** @type{!Array<!proto.unifiedplatform.guidedMeditation.MeditationFileDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.guidedMeditation.MeditationFileDetails, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.guidedMeditation.MeditationFileDetails>} value
 * @return {!proto.unifiedplatform.guidedMeditation.SelectedMeditationFiles} returns this
*/
proto.unifiedplatform.guidedMeditation.SelectedMeditationFiles.prototype.setSelectedmeditationfileList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.guidedMeditation.MeditationFileDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetails}
 */
proto.unifiedplatform.guidedMeditation.SelectedMeditationFiles.prototype.addSelectedmeditationfile = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.guidedMeditation.MeditationFileDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.guidedMeditation.SelectedMeditationFiles} returns this
 */
proto.unifiedplatform.guidedMeditation.SelectedMeditationFiles.prototype.clearSelectedmeditationfileList = function() {
  return this.setSelectedmeditationfileList([]);
};


/**
 * optional uint32 selectedPageSize = 2;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.SelectedMeditationFiles.prototype.getSelectedpagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.SelectedMeditationFiles} returns this
 */
proto.unifiedplatform.guidedMeditation.SelectedMeditationFiles.prototype.setSelectedpagesize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 selectedPageNumber = 3;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.SelectedMeditationFiles.prototype.getSelectedpagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.SelectedMeditationFiles} returns this
 */
proto.unifiedplatform.guidedMeditation.SelectedMeditationFiles.prototype.setSelectedpagenumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 totalCount = 4;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.SelectedMeditationFiles.prototype.getTotalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.SelectedMeditationFiles} returns this
 */
proto.unifiedplatform.guidedMeditation.SelectedMeditationFiles.prototype.setTotalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.DeleteMeditationFileDetailsByIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.DeleteMeditationFileDetailsByIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.DeleteMeditationFileDetailsByIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.DeleteMeditationFileDetailsByIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    meditationfile: (f = msg.getMeditationfile()) && proto.unifiedplatform.guidedMeditation.MeditationFileDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.DeleteMeditationFileDetailsByIdResponse}
 */
proto.unifiedplatform.guidedMeditation.DeleteMeditationFileDetailsByIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.DeleteMeditationFileDetailsByIdResponse;
  return proto.unifiedplatform.guidedMeditation.DeleteMeditationFileDetailsByIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.DeleteMeditationFileDetailsByIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.DeleteMeditationFileDetailsByIdResponse}
 */
proto.unifiedplatform.guidedMeditation.DeleteMeditationFileDetailsByIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.guidedMeditation.MeditationFileDetails;
      reader.readMessage(value,proto.unifiedplatform.guidedMeditation.MeditationFileDetails.deserializeBinaryFromReader);
      msg.setMeditationfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.DeleteMeditationFileDetailsByIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.DeleteMeditationFileDetailsByIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.DeleteMeditationFileDetailsByIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.DeleteMeditationFileDetailsByIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMeditationfile();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.guidedMeditation.MeditationFileDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.DeleteMeditationFileDetailsByIdResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.DeleteMeditationFileDetailsByIdResponse} returns this
 */
proto.unifiedplatform.guidedMeditation.DeleteMeditationFileDetailsByIdResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional MeditationFileDetails meditationFile = 2;
 * @return {?proto.unifiedplatform.guidedMeditation.MeditationFileDetails}
 */
proto.unifiedplatform.guidedMeditation.DeleteMeditationFileDetailsByIdResponse.prototype.getMeditationfile = function() {
  return /** @type{?proto.unifiedplatform.guidedMeditation.MeditationFileDetails} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.guidedMeditation.MeditationFileDetails, 2));
};


/**
 * @param {?proto.unifiedplatform.guidedMeditation.MeditationFileDetails|undefined} value
 * @return {!proto.unifiedplatform.guidedMeditation.DeleteMeditationFileDetailsByIdResponse} returns this
*/
proto.unifiedplatform.guidedMeditation.DeleteMeditationFileDetailsByIdResponse.prototype.setMeditationfile = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.guidedMeditation.DeleteMeditationFileDetailsByIdResponse} returns this
 */
proto.unifiedplatform.guidedMeditation.DeleteMeditationFileDetailsByIdResponse.prototype.clearMeditationfile = function() {
  return this.setMeditationfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.DeleteMeditationFileDetailsByIdResponse.prototype.hasMeditationfile = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.AddPurposeFileDetailsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.AddPurposeFileDetailsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.AddPurposeFileDetailsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.AddPurposeFileDetailsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    purpose: (f = msg.getPurpose()) && common_common_pb.Locale.toObject(includeInstance, f),
    imageurl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    withoutborderimageurl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.AddPurposeFileDetailsRequest}
 */
proto.unifiedplatform.guidedMeditation.AddPurposeFileDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.AddPurposeFileDetailsRequest;
  return proto.unifiedplatform.guidedMeditation.AddPurposeFileDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.AddPurposeFileDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.AddPurposeFileDetailsRequest}
 */
proto.unifiedplatform.guidedMeditation.AddPurposeFileDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setPurpose(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageurl(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setWithoutborderimageurl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.AddPurposeFileDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.AddPurposeFileDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.AddPurposeFileDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.AddPurposeFileDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPurpose();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getImageurl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getWithoutborderimageurl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional unifiedplatform.heartintune.common.Locale purpose = 1;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.guidedMeditation.AddPurposeFileDetailsRequest.prototype.getPurpose = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 1));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddPurposeFileDetailsRequest} returns this
*/
proto.unifiedplatform.guidedMeditation.AddPurposeFileDetailsRequest.prototype.setPurpose = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.guidedMeditation.AddPurposeFileDetailsRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.AddPurposeFileDetailsRequest.prototype.clearPurpose = function() {
  return this.setPurpose(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.AddPurposeFileDetailsRequest.prototype.hasPurpose = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string imageURL = 2;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.AddPurposeFileDetailsRequest.prototype.getImageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddPurposeFileDetailsRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.AddPurposeFileDetailsRequest.prototype.setImageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string withoutBorderImageUrl = 3;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.AddPurposeFileDetailsRequest.prototype.getWithoutborderimageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddPurposeFileDetailsRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.AddPurposeFileDetailsRequest.prototype.setWithoutborderimageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.PurposeFileDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.PurposeFileDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.PurposeFileDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.PurposeFileDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    purpose: (f = msg.getPurpose()) && common_common_pb.Locale.toObject(includeInstance, f),
    imageurl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    withoutborderimageurl: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.PurposeFileDetails}
 */
proto.unifiedplatform.guidedMeditation.PurposeFileDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.PurposeFileDetails;
  return proto.unifiedplatform.guidedMeditation.PurposeFileDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.PurposeFileDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.PurposeFileDetails}
 */
proto.unifiedplatform.guidedMeditation.PurposeFileDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setPurpose(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageurl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setWithoutborderimageurl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.PurposeFileDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.PurposeFileDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.PurposeFileDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.PurposeFileDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPurpose();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getImageurl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getWithoutborderimageurl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.PurposeFileDetails.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.PurposeFileDetails} returns this
 */
proto.unifiedplatform.guidedMeditation.PurposeFileDetails.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.Locale purpose = 2;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.guidedMeditation.PurposeFileDetails.prototype.getPurpose = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 2));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.guidedMeditation.PurposeFileDetails} returns this
*/
proto.unifiedplatform.guidedMeditation.PurposeFileDetails.prototype.setPurpose = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.guidedMeditation.PurposeFileDetails} returns this
 */
proto.unifiedplatform.guidedMeditation.PurposeFileDetails.prototype.clearPurpose = function() {
  return this.setPurpose(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.PurposeFileDetails.prototype.hasPurpose = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string imageURL = 3;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.PurposeFileDetails.prototype.getImageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.PurposeFileDetails} returns this
 */
proto.unifiedplatform.guidedMeditation.PurposeFileDetails.prototype.setImageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string withoutBorderImageUrl = 4;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.PurposeFileDetails.prototype.getWithoutborderimageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.PurposeFileDetails} returns this
 */
proto.unifiedplatform.guidedMeditation.PurposeFileDetails.prototype.setWithoutborderimageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.PurposeFileDetailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.PurposeFileDetailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.PurposeFileDetailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.PurposeFileDetailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    purpose: jspb.Message.getFieldWithDefault(msg, 2, ""),
    imageurl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.PurposeFileDetailsResponse}
 */
proto.unifiedplatform.guidedMeditation.PurposeFileDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.PurposeFileDetailsResponse;
  return proto.unifiedplatform.guidedMeditation.PurposeFileDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.PurposeFileDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.PurposeFileDetailsResponse}
 */
proto.unifiedplatform.guidedMeditation.PurposeFileDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPurpose(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageurl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.PurposeFileDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.PurposeFileDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.PurposeFileDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.PurposeFileDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPurpose();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getImageurl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.PurposeFileDetailsResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.PurposeFileDetailsResponse} returns this
 */
proto.unifiedplatform.guidedMeditation.PurposeFileDetailsResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string purpose = 2;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.PurposeFileDetailsResponse.prototype.getPurpose = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.PurposeFileDetailsResponse} returns this
 */
proto.unifiedplatform.guidedMeditation.PurposeFileDetailsResponse.prototype.setPurpose = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string imageURL = 3;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.PurposeFileDetailsResponse.prototype.getImageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.PurposeFileDetailsResponse} returns this
 */
proto.unifiedplatform.guidedMeditation.PurposeFileDetailsResponse.prototype.setImageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.AddDurationFileDetailsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.AddDurationFileDetailsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.AddDurationFileDetailsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.AddDurationFileDetailsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    durationinseconds: jspb.Message.getFieldWithDefault(msg, 1, 0),
    imageurl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    selectedimageurl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.AddDurationFileDetailsRequest}
 */
proto.unifiedplatform.guidedMeditation.AddDurationFileDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.AddDurationFileDetailsRequest;
  return proto.unifiedplatform.guidedMeditation.AddDurationFileDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.AddDurationFileDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.AddDurationFileDetailsRequest}
 */
proto.unifiedplatform.guidedMeditation.AddDurationFileDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDurationinseconds(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageurl(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSelectedimageurl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.AddDurationFileDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.AddDurationFileDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.AddDurationFileDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.AddDurationFileDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDurationinseconds();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getImageurl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSelectedimageurl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 durationInSeconds = 1;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.AddDurationFileDetailsRequest.prototype.getDurationinseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddDurationFileDetailsRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.AddDurationFileDetailsRequest.prototype.setDurationinseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string imageURL = 2;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.AddDurationFileDetailsRequest.prototype.getImageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddDurationFileDetailsRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.AddDurationFileDetailsRequest.prototype.setImageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string selectedImageURL = 3;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.AddDurationFileDetailsRequest.prototype.getSelectedimageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddDurationFileDetailsRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.AddDurationFileDetailsRequest.prototype.setSelectedimageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.DurationFileDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.DurationFileDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.DurationFileDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.DurationFileDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    durationinseconds: jspb.Message.getFieldWithDefault(msg, 2, 0),
    imageurl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    selectedimageurl: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.DurationFileDetails}
 */
proto.unifiedplatform.guidedMeditation.DurationFileDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.DurationFileDetails;
  return proto.unifiedplatform.guidedMeditation.DurationFileDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.DurationFileDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.DurationFileDetails}
 */
proto.unifiedplatform.guidedMeditation.DurationFileDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDurationinseconds(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageurl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSelectedimageurl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.DurationFileDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.DurationFileDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.DurationFileDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.DurationFileDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDurationinseconds();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getImageurl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSelectedimageurl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.DurationFileDetails.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.DurationFileDetails} returns this
 */
proto.unifiedplatform.guidedMeditation.DurationFileDetails.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 durationInSeconds = 2;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.DurationFileDetails.prototype.getDurationinseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.DurationFileDetails} returns this
 */
proto.unifiedplatform.guidedMeditation.DurationFileDetails.prototype.setDurationinseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string imageURL = 3;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.DurationFileDetails.prototype.getImageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.DurationFileDetails} returns this
 */
proto.unifiedplatform.guidedMeditation.DurationFileDetails.prototype.setImageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string selectedImageURL = 4;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.DurationFileDetails.prototype.getSelectedimageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.DurationFileDetails} returns this
 */
proto.unifiedplatform.guidedMeditation.DurationFileDetails.prototype.setSelectedimageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.SelectMeditationFileDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.SelectMeditationFileDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.SelectMeditationFileDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.SelectMeditationFileDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    purpose: jspb.Message.getFieldWithDefault(msg, 1, 0),
    duration: jspb.Message.getFieldWithDefault(msg, 2, 0),
    usertype: jspb.Message.getFieldWithDefault(msg, 3, 0),
    meditationtype: jspb.Message.getFieldWithDefault(msg, 4, 0),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 5, 0),
    isrequestcomingfromv2: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.SelectMeditationFileDetails}
 */
proto.unifiedplatform.guidedMeditation.SelectMeditationFileDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.SelectMeditationFileDetails;
  return proto.unifiedplatform.guidedMeditation.SelectMeditationFileDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.SelectMeditationFileDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.SelectMeditationFileDetails}
 */
proto.unifiedplatform.guidedMeditation.SelectMeditationFileDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPurpose(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDuration(value);
      break;
    case 3:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.UserType} */ (reader.readEnum());
      msg.setUsertype(value);
      break;
    case 4:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.MeditationType} */ (reader.readEnum());
      msg.setMeditationtype(value);
      break;
    case 5:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsrequestcomingfromv2(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.SelectMeditationFileDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.SelectMeditationFileDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.SelectMeditationFileDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.SelectMeditationFileDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPurpose();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getUsertype();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getMeditationtype();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getIsrequestcomingfromv2();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional int64 purpose = 1;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.SelectMeditationFileDetails.prototype.getPurpose = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.SelectMeditationFileDetails} returns this
 */
proto.unifiedplatform.guidedMeditation.SelectMeditationFileDetails.prototype.setPurpose = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 duration = 2;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.SelectMeditationFileDetails.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.SelectMeditationFileDetails} returns this
 */
proto.unifiedplatform.guidedMeditation.SelectMeditationFileDetails.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional unifiedplatform.heartintune.common.UserType userType = 3;
 * @return {!proto.unifiedplatform.heartintune.common.UserType}
 */
proto.unifiedplatform.guidedMeditation.SelectMeditationFileDetails.prototype.getUsertype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.UserType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.UserType} value
 * @return {!proto.unifiedplatform.guidedMeditation.SelectMeditationFileDetails} returns this
 */
proto.unifiedplatform.guidedMeditation.SelectMeditationFileDetails.prototype.setUsertype = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional unifiedplatform.heartintune.common.MeditationType meditationType = 4;
 * @return {!proto.unifiedplatform.heartintune.common.MeditationType}
 */
proto.unifiedplatform.guidedMeditation.SelectMeditationFileDetails.prototype.getMeditationtype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.MeditationType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.MeditationType} value
 * @return {!proto.unifiedplatform.guidedMeditation.SelectMeditationFileDetails} returns this
 */
proto.unifiedplatform.guidedMeditation.SelectMeditationFileDetails.prototype.setMeditationtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 5;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.guidedMeditation.SelectMeditationFileDetails.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.guidedMeditation.SelectMeditationFileDetails} returns this
 */
proto.unifiedplatform.guidedMeditation.SelectMeditationFileDetails.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional bool isRequestComingFromV2 = 6;
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.SelectMeditationFileDetails.prototype.getIsrequestcomingfromv2 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.guidedMeditation.SelectMeditationFileDetails} returns this
 */
proto.unifiedplatform.guidedMeditation.SelectMeditationFileDetails.prototype.setIsrequestcomingfromv2 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.guidedMeditation.SelectedFileDetails.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.SelectedFileDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.SelectedFileDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.SelectedFileDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.SelectedFileDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    selectedmeditationfileList: jspb.Message.toObjectList(msg.getSelectedmeditationfileList(),
    proto.unifiedplatform.guidedMeditation.MeditationFileDetails.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.SelectedFileDetails}
 */
proto.unifiedplatform.guidedMeditation.SelectedFileDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.SelectedFileDetails;
  return proto.unifiedplatform.guidedMeditation.SelectedFileDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.SelectedFileDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.SelectedFileDetails}
 */
proto.unifiedplatform.guidedMeditation.SelectedFileDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.guidedMeditation.MeditationFileDetails;
      reader.readMessage(value,proto.unifiedplatform.guidedMeditation.MeditationFileDetails.deserializeBinaryFromReader);
      msg.addSelectedmeditationfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.SelectedFileDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.SelectedFileDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.SelectedFileDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.SelectedFileDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSelectedmeditationfileList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.guidedMeditation.MeditationFileDetails.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MeditationFileDetails selectedMeditationFile = 1;
 * @return {!Array<!proto.unifiedplatform.guidedMeditation.MeditationFileDetails>}
 */
proto.unifiedplatform.guidedMeditation.SelectedFileDetails.prototype.getSelectedmeditationfileList = function() {
  return /** @type{!Array<!proto.unifiedplatform.guidedMeditation.MeditationFileDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.guidedMeditation.MeditationFileDetails, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.guidedMeditation.MeditationFileDetails>} value
 * @return {!proto.unifiedplatform.guidedMeditation.SelectedFileDetails} returns this
*/
proto.unifiedplatform.guidedMeditation.SelectedFileDetails.prototype.setSelectedmeditationfileList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.guidedMeditation.MeditationFileDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetails}
 */
proto.unifiedplatform.guidedMeditation.SelectedFileDetails.prototype.addSelectedmeditationfile = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.guidedMeditation.MeditationFileDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.guidedMeditation.SelectedFileDetails} returns this
 */
proto.unifiedplatform.guidedMeditation.SelectedFileDetails.prototype.clearSelectedmeditationfileList = function() {
  return this.setSelectedmeditationfileList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.DeletePurposeFileDetailByIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.DeletePurposeFileDetailByIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.DeletePurposeFileDetailByIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.DeletePurposeFileDetailByIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    purposefile: (f = msg.getPurposefile()) && proto.unifiedplatform.guidedMeditation.PurposeFile.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.DeletePurposeFileDetailByIdResponse}
 */
proto.unifiedplatform.guidedMeditation.DeletePurposeFileDetailByIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.DeletePurposeFileDetailByIdResponse;
  return proto.unifiedplatform.guidedMeditation.DeletePurposeFileDetailByIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.DeletePurposeFileDetailByIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.DeletePurposeFileDetailByIdResponse}
 */
proto.unifiedplatform.guidedMeditation.DeletePurposeFileDetailByIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.guidedMeditation.PurposeFile;
      reader.readMessage(value,proto.unifiedplatform.guidedMeditation.PurposeFile.deserializeBinaryFromReader);
      msg.setPurposefile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.DeletePurposeFileDetailByIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.DeletePurposeFileDetailByIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.DeletePurposeFileDetailByIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.DeletePurposeFileDetailByIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPurposefile();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.guidedMeditation.PurposeFile.serializeBinaryToWriter
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.DeletePurposeFileDetailByIdResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.DeletePurposeFileDetailByIdResponse} returns this
 */
proto.unifiedplatform.guidedMeditation.DeletePurposeFileDetailByIdResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PurposeFile PurposeFile = 2;
 * @return {?proto.unifiedplatform.guidedMeditation.PurposeFile}
 */
proto.unifiedplatform.guidedMeditation.DeletePurposeFileDetailByIdResponse.prototype.getPurposefile = function() {
  return /** @type{?proto.unifiedplatform.guidedMeditation.PurposeFile} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.guidedMeditation.PurposeFile, 2));
};


/**
 * @param {?proto.unifiedplatform.guidedMeditation.PurposeFile|undefined} value
 * @return {!proto.unifiedplatform.guidedMeditation.DeletePurposeFileDetailByIdResponse} returns this
*/
proto.unifiedplatform.guidedMeditation.DeletePurposeFileDetailByIdResponse.prototype.setPurposefile = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.guidedMeditation.DeletePurposeFileDetailByIdResponse} returns this
 */
proto.unifiedplatform.guidedMeditation.DeletePurposeFileDetailByIdResponse.prototype.clearPurposefile = function() {
  return this.setPurposefile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.DeletePurposeFileDetailByIdResponse.prototype.hasPurposefile = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFilesDetails.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFilesDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFilesDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFilesDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFilesDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    listofpurposefileList: jspb.Message.toObjectList(msg.getListofpurposefileList(),
    proto.unifiedplatform.guidedMeditation.PurposeFileDetailsResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFilesDetails}
 */
proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFilesDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFilesDetails;
  return proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFilesDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFilesDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFilesDetails}
 */
proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFilesDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.guidedMeditation.PurposeFileDetailsResponse;
      reader.readMessage(value,proto.unifiedplatform.guidedMeditation.PurposeFileDetailsResponse.deserializeBinaryFromReader);
      msg.addListofpurposefile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFilesDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFilesDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFilesDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFilesDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListofpurposefileList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.guidedMeditation.PurposeFileDetailsResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PurposeFileDetailsResponse listOfPurposeFile = 1;
 * @return {!Array<!proto.unifiedplatform.guidedMeditation.PurposeFileDetailsResponse>}
 */
proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFilesDetails.prototype.getListofpurposefileList = function() {
  return /** @type{!Array<!proto.unifiedplatform.guidedMeditation.PurposeFileDetailsResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.guidedMeditation.PurposeFileDetailsResponse, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.guidedMeditation.PurposeFileDetailsResponse>} value
 * @return {!proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFilesDetails} returns this
*/
proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFilesDetails.prototype.setListofpurposefileList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.guidedMeditation.PurposeFileDetailsResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.guidedMeditation.PurposeFileDetailsResponse}
 */
proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFilesDetails.prototype.addListofpurposefile = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.guidedMeditation.PurposeFileDetailsResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFilesDetails} returns this
 */
proto.unifiedplatform.guidedMeditation.ResponseAllPurposeFilesDetails.prototype.clearListofpurposefileList = function() {
  return this.setListofpurposefileList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.guidedMeditation.ResponseAllDurationFilesDetails.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.ResponseAllDurationFilesDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.ResponseAllDurationFilesDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.ResponseAllDurationFilesDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.ResponseAllDurationFilesDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    listofdurationfilesList: jspb.Message.toObjectList(msg.getListofdurationfilesList(),
    proto.unifiedplatform.guidedMeditation.DurationFile.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.ResponseAllDurationFilesDetails}
 */
proto.unifiedplatform.guidedMeditation.ResponseAllDurationFilesDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.ResponseAllDurationFilesDetails;
  return proto.unifiedplatform.guidedMeditation.ResponseAllDurationFilesDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.ResponseAllDurationFilesDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.ResponseAllDurationFilesDetails}
 */
proto.unifiedplatform.guidedMeditation.ResponseAllDurationFilesDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.guidedMeditation.DurationFile;
      reader.readMessage(value,proto.unifiedplatform.guidedMeditation.DurationFile.deserializeBinaryFromReader);
      msg.addListofdurationfiles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.ResponseAllDurationFilesDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.ResponseAllDurationFilesDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.ResponseAllDurationFilesDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.ResponseAllDurationFilesDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListofdurationfilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.guidedMeditation.DurationFile.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DurationFile listOfDurationFiles = 1;
 * @return {!Array<!proto.unifiedplatform.guidedMeditation.DurationFile>}
 */
proto.unifiedplatform.guidedMeditation.ResponseAllDurationFilesDetails.prototype.getListofdurationfilesList = function() {
  return /** @type{!Array<!proto.unifiedplatform.guidedMeditation.DurationFile>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.guidedMeditation.DurationFile, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.guidedMeditation.DurationFile>} value
 * @return {!proto.unifiedplatform.guidedMeditation.ResponseAllDurationFilesDetails} returns this
*/
proto.unifiedplatform.guidedMeditation.ResponseAllDurationFilesDetails.prototype.setListofdurationfilesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.guidedMeditation.DurationFile=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.guidedMeditation.DurationFile}
 */
proto.unifiedplatform.guidedMeditation.ResponseAllDurationFilesDetails.prototype.addListofdurationfiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.guidedMeditation.DurationFile, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.guidedMeditation.ResponseAllDurationFilesDetails} returns this
 */
proto.unifiedplatform.guidedMeditation.ResponseAllDurationFilesDetails.prototype.clearListofdurationfilesList = function() {
  return this.setListofdurationfilesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.RequestGetAllPurpose.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.RequestGetAllPurpose.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.RequestGetAllPurpose} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.RequestGetAllPurpose.toObject = function(includeInstance, msg) {
  var f, obj = {
    usertype: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.RequestGetAllPurpose}
 */
proto.unifiedplatform.guidedMeditation.RequestGetAllPurpose.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.RequestGetAllPurpose;
  return proto.unifiedplatform.guidedMeditation.RequestGetAllPurpose.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.RequestGetAllPurpose} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.RequestGetAllPurpose}
 */
proto.unifiedplatform.guidedMeditation.RequestGetAllPurpose.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.UserType} */ (reader.readEnum());
      msg.setUsertype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.RequestGetAllPurpose.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.RequestGetAllPurpose.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.RequestGetAllPurpose} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.RequestGetAllPurpose.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsertype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional unifiedplatform.heartintune.common.UserType userType = 1;
 * @return {!proto.unifiedplatform.heartintune.common.UserType}
 */
proto.unifiedplatform.guidedMeditation.RequestGetAllPurpose.prototype.getUsertype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.UserType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.UserType} value
 * @return {!proto.unifiedplatform.guidedMeditation.RequestGetAllPurpose} returns this
 */
proto.unifiedplatform.guidedMeditation.RequestGetAllPurpose.prototype.setUsertype = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.RequestGetAllPurposeFileDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.RequestGetAllPurposeFileDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.RequestGetAllPurposeFileDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.RequestGetAllPurposeFileDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    usertype: jspb.Message.getFieldWithDefault(msg, 1, 0),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.RequestGetAllPurposeFileDetails}
 */
proto.unifiedplatform.guidedMeditation.RequestGetAllPurposeFileDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.RequestGetAllPurposeFileDetails;
  return proto.unifiedplatform.guidedMeditation.RequestGetAllPurposeFileDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.RequestGetAllPurposeFileDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.RequestGetAllPurposeFileDetails}
 */
proto.unifiedplatform.guidedMeditation.RequestGetAllPurposeFileDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.UserType} */ (reader.readEnum());
      msg.setUsertype(value);
      break;
    case 2:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.RequestGetAllPurposeFileDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.RequestGetAllPurposeFileDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.RequestGetAllPurposeFileDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.RequestGetAllPurposeFileDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsertype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional unifiedplatform.heartintune.common.UserType userType = 1;
 * @return {!proto.unifiedplatform.heartintune.common.UserType}
 */
proto.unifiedplatform.guidedMeditation.RequestGetAllPurposeFileDetails.prototype.getUsertype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.UserType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.UserType} value
 * @return {!proto.unifiedplatform.guidedMeditation.RequestGetAllPurposeFileDetails} returns this
 */
proto.unifiedplatform.guidedMeditation.RequestGetAllPurposeFileDetails.prototype.setUsertype = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 2;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.guidedMeditation.RequestGetAllPurposeFileDetails.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.guidedMeditation.RequestGetAllPurposeFileDetails} returns this
 */
proto.unifiedplatform.guidedMeditation.RequestGetAllPurposeFileDetails.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.RequestPageable.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.RequestPageable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.RequestPageable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.RequestPageable.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagesize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pagenumber: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.RequestPageable}
 */
proto.unifiedplatform.guidedMeditation.RequestPageable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.RequestPageable;
  return proto.unifiedplatform.guidedMeditation.RequestPageable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.RequestPageable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.RequestPageable}
 */
proto.unifiedplatform.guidedMeditation.RequestPageable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPagesize(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPagenumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.RequestPageable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.RequestPageable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.RequestPageable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.RequestPageable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagesize();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getPagenumber();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 pageSize = 1;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.RequestPageable.prototype.getPagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.RequestPageable} returns this
 */
proto.unifiedplatform.guidedMeditation.RequestPageable.prototype.setPagesize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 pageNumber = 2;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.RequestPageable.prototype.getPagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.RequestPageable} returns this
 */
proto.unifiedplatform.guidedMeditation.RequestPageable.prototype.setPagenumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetailsWithSelectedLanguage.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetailsWithSelectedLanguage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetailsWithSelectedLanguage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetailsWithSelectedLanguage.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 2, 0),
    requestpageable: (f = msg.getRequestpageable()) && proto.unifiedplatform.guidedMeditation.RequestPageable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetailsWithSelectedLanguage}
 */
proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetailsWithSelectedLanguage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetailsWithSelectedLanguage;
  return proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetailsWithSelectedLanguage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetailsWithSelectedLanguage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetailsWithSelectedLanguage}
 */
proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetailsWithSelectedLanguage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    case 3:
      var value = new proto.unifiedplatform.guidedMeditation.RequestPageable;
      reader.readMessage(value,proto.unifiedplatform.guidedMeditation.RequestPageable.deserializeBinaryFromReader);
      msg.setRequestpageable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetailsWithSelectedLanguage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetailsWithSelectedLanguage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetailsWithSelectedLanguage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetailsWithSelectedLanguage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getRequestpageable();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.unifiedplatform.guidedMeditation.RequestPageable.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetailsWithSelectedLanguage.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetailsWithSelectedLanguage} returns this
 */
proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetailsWithSelectedLanguage.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 2;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetailsWithSelectedLanguage.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetailsWithSelectedLanguage} returns this
 */
proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetailsWithSelectedLanguage.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional RequestPageable requestPageable = 3;
 * @return {?proto.unifiedplatform.guidedMeditation.RequestPageable}
 */
proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetailsWithSelectedLanguage.prototype.getRequestpageable = function() {
  return /** @type{?proto.unifiedplatform.guidedMeditation.RequestPageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.guidedMeditation.RequestPageable, 3));
};


/**
 * @param {?proto.unifiedplatform.guidedMeditation.RequestPageable|undefined} value
 * @return {!proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetailsWithSelectedLanguage} returns this
*/
proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetailsWithSelectedLanguage.prototype.setRequestpageable = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetailsWithSelectedLanguage} returns this
 */
proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetailsWithSelectedLanguage.prototype.clearRequestpageable = function() {
  return this.setRequestpageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetailsWithSelectedLanguage.prototype.hasRequestpageable = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.RequestForGetAllMeditationFiles.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.RequestForGetAllMeditationFiles.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.RequestForGetAllMeditationFiles} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.RequestForGetAllMeditationFiles.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestpageable: (f = msg.getRequestpageable()) && proto.unifiedplatform.guidedMeditation.RequestPageable.toObject(includeInstance, f),
    meditationtype: jspb.Message.getFieldWithDefault(msg, 2, 0),
    mediationcategory: jspb.Message.getFieldWithDefault(msg, 4, 0),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 5, 0),
    category: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.RequestForGetAllMeditationFiles}
 */
proto.unifiedplatform.guidedMeditation.RequestForGetAllMeditationFiles.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.RequestForGetAllMeditationFiles;
  return proto.unifiedplatform.guidedMeditation.RequestForGetAllMeditationFiles.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.RequestForGetAllMeditationFiles} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.RequestForGetAllMeditationFiles}
 */
proto.unifiedplatform.guidedMeditation.RequestForGetAllMeditationFiles.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.guidedMeditation.RequestPageable;
      reader.readMessage(value,proto.unifiedplatform.guidedMeditation.RequestPageable.deserializeBinaryFromReader);
      msg.setRequestpageable(value);
      break;
    case 2:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.MeditationType} */ (reader.readEnum());
      msg.setMeditationtype(value);
      break;
    case 4:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.MeditationCategory} */ (reader.readEnum());
      msg.setMediationcategory(value);
      break;
    case 5:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.RequestForGetAllMeditationFiles.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.RequestForGetAllMeditationFiles.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.RequestForGetAllMeditationFiles} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.RequestForGetAllMeditationFiles.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestpageable();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.unifiedplatform.guidedMeditation.RequestPageable.serializeBinaryToWriter
    );
  }
  f = message.getMeditationtype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getMediationcategory();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getCategory();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional RequestPageable requestPageable = 1;
 * @return {?proto.unifiedplatform.guidedMeditation.RequestPageable}
 */
proto.unifiedplatform.guidedMeditation.RequestForGetAllMeditationFiles.prototype.getRequestpageable = function() {
  return /** @type{?proto.unifiedplatform.guidedMeditation.RequestPageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.guidedMeditation.RequestPageable, 1));
};


/**
 * @param {?proto.unifiedplatform.guidedMeditation.RequestPageable|undefined} value
 * @return {!proto.unifiedplatform.guidedMeditation.RequestForGetAllMeditationFiles} returns this
*/
proto.unifiedplatform.guidedMeditation.RequestForGetAllMeditationFiles.prototype.setRequestpageable = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.guidedMeditation.RequestForGetAllMeditationFiles} returns this
 */
proto.unifiedplatform.guidedMeditation.RequestForGetAllMeditationFiles.prototype.clearRequestpageable = function() {
  return this.setRequestpageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.RequestForGetAllMeditationFiles.prototype.hasRequestpageable = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional unifiedplatform.heartintune.common.MeditationType meditationType = 2;
 * @return {!proto.unifiedplatform.heartintune.common.MeditationType}
 */
proto.unifiedplatform.guidedMeditation.RequestForGetAllMeditationFiles.prototype.getMeditationtype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.MeditationType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.MeditationType} value
 * @return {!proto.unifiedplatform.guidedMeditation.RequestForGetAllMeditationFiles} returns this
 */
proto.unifiedplatform.guidedMeditation.RequestForGetAllMeditationFiles.prototype.setMeditationtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional unifiedplatform.heartintune.common.MeditationCategory mediationCategory = 4;
 * @return {!proto.unifiedplatform.heartintune.common.MeditationCategory}
 */
proto.unifiedplatform.guidedMeditation.RequestForGetAllMeditationFiles.prototype.getMediationcategory = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.MeditationCategory} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.MeditationCategory} value
 * @return {!proto.unifiedplatform.guidedMeditation.RequestForGetAllMeditationFiles} returns this
 */
proto.unifiedplatform.guidedMeditation.RequestForGetAllMeditationFiles.prototype.setMediationcategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 5;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.guidedMeditation.RequestForGetAllMeditationFiles.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.guidedMeditation.RequestForGetAllMeditationFiles} returns this
 */
proto.unifiedplatform.guidedMeditation.RequestForGetAllMeditationFiles.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional bool category = 6;
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.RequestForGetAllMeditationFiles.prototype.getCategory = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.guidedMeditation.RequestForGetAllMeditationFiles} returns this
 */
proto.unifiedplatform.guidedMeditation.RequestForGetAllMeditationFiles.prototype.setCategory = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.LocalType.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.LocalType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.LocalType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.LocalType.toObject = function(includeInstance, msg) {
  var f, obj = {
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.LocalType}
 */
proto.unifiedplatform.guidedMeditation.LocalType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.LocalType;
  return proto.unifiedplatform.guidedMeditation.LocalType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.LocalType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.LocalType}
 */
proto.unifiedplatform.guidedMeditation.LocalType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.LocalType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.LocalType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.LocalType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.LocalType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 1;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.guidedMeditation.LocalType.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.guidedMeditation.LocalType} returns this
 */
proto.unifiedplatform.guidedMeditation.LocalType.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestpageable: (f = msg.getRequestpageable()) && proto.unifiedplatform.guidedMeditation.RequestPageable.toObject(includeInstance, f),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 2, 0),
    usertype: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetails}
 */
proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetails;
  return proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetails}
 */
proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.guidedMeditation.RequestPageable;
      reader.readMessage(value,proto.unifiedplatform.guidedMeditation.RequestPageable.deserializeBinaryFromReader);
      msg.setRequestpageable(value);
      break;
    case 2:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    case 3:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.UserType} */ (reader.readEnum());
      msg.setUsertype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestpageable();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.unifiedplatform.guidedMeditation.RequestPageable.serializeBinaryToWriter
    );
  }
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getUsertype();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional RequestPageable requestPageable = 1;
 * @return {?proto.unifiedplatform.guidedMeditation.RequestPageable}
 */
proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetails.prototype.getRequestpageable = function() {
  return /** @type{?proto.unifiedplatform.guidedMeditation.RequestPageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.guidedMeditation.RequestPageable, 1));
};


/**
 * @param {?proto.unifiedplatform.guidedMeditation.RequestPageable|undefined} value
 * @return {!proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetails} returns this
*/
proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetails.prototype.setRequestpageable = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetails} returns this
 */
proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetails.prototype.clearRequestpageable = function() {
  return this.setRequestpageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetails.prototype.hasRequestpageable = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 2;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetails.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetails} returns this
 */
proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetails.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional unifiedplatform.heartintune.common.UserType userType = 3;
 * @return {!proto.unifiedplatform.heartintune.common.UserType}
 */
proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetails.prototype.getUsertype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.UserType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.UserType} value
 * @return {!proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetails} returns this
 */
proto.unifiedplatform.guidedMeditation.RequestForGetMeditationFileDetails.prototype.setUsertype = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponseV2.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponseV2.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponseV2.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponseV2} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponseV2.toObject = function(includeInstance, msg) {
  var f, obj = {
    meditationfilev2detailsresponseList: jspb.Message.toObjectList(msg.getMeditationfilev2detailsresponseList(),
    proto.unifiedplatform.guidedMeditation.MeditationFileV2.toObject, includeInstance),
    meditationfiledetailsv2List: jspb.Message.toObjectList(msg.getMeditationfiledetailsv2List(),
    proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.toObject, includeInstance),
    selectedpagesize: jspb.Message.getFieldWithDefault(msg, 3, 0),
    selectedpagenumber: jspb.Message.getFieldWithDefault(msg, 4, 0),
    totalcount: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponseV2}
 */
proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponseV2.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponseV2;
  return proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponseV2.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponseV2} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponseV2}
 */
proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponseV2.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.guidedMeditation.MeditationFileV2;
      reader.readMessage(value,proto.unifiedplatform.guidedMeditation.MeditationFileV2.deserializeBinaryFromReader);
      msg.addMeditationfilev2detailsresponse(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2;
      reader.readMessage(value,proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.deserializeBinaryFromReader);
      msg.addMeditationfiledetailsv2(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSelectedpagesize(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSelectedpagenumber(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalcount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponseV2.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponseV2.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponseV2} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponseV2.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeditationfilev2detailsresponseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.guidedMeditation.MeditationFileV2.serializeBinaryToWriter
    );
  }
  f = message.getMeditationfiledetailsv2List();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.serializeBinaryToWriter
    );
  }
  f = message.getSelectedpagesize();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getSelectedpagenumber();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getTotalcount();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * repeated MeditationFileV2 meditationFileV2DetailsResponse = 1;
 * @return {!Array<!proto.unifiedplatform.guidedMeditation.MeditationFileV2>}
 */
proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponseV2.prototype.getMeditationfilev2detailsresponseList = function() {
  return /** @type{!Array<!proto.unifiedplatform.guidedMeditation.MeditationFileV2>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.guidedMeditation.MeditationFileV2, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.guidedMeditation.MeditationFileV2>} value
 * @return {!proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponseV2} returns this
*/
proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponseV2.prototype.setMeditationfilev2detailsresponseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.guidedMeditation.MeditationFileV2=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileV2}
 */
proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponseV2.prototype.addMeditationfilev2detailsresponse = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.guidedMeditation.MeditationFileV2, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponseV2} returns this
 */
proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponseV2.prototype.clearMeditationfilev2detailsresponseList = function() {
  return this.setMeditationfilev2detailsresponseList([]);
};


/**
 * repeated MeditationFileDetailsV2 meditationFileDetailsV2 = 2;
 * @return {!Array<!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2>}
 */
proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponseV2.prototype.getMeditationfiledetailsv2List = function() {
  return /** @type{!Array<!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2, 2));
};


/**
 * @param {!Array<!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2>} value
 * @return {!proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponseV2} returns this
*/
proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponseV2.prototype.setMeditationfiledetailsv2List = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2}
 */
proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponseV2.prototype.addMeditationfiledetailsv2 = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponseV2} returns this
 */
proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponseV2.prototype.clearMeditationfiledetailsv2List = function() {
  return this.setMeditationfiledetailsv2List([]);
};


/**
 * optional uint32 selectedPageSize = 3;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponseV2.prototype.getSelectedpagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponseV2} returns this
 */
proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponseV2.prototype.setSelectedpagesize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 selectedPageNumber = 4;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponseV2.prototype.getSelectedpagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponseV2} returns this
 */
proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponseV2.prototype.setSelectedpagenumber = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 totalCount = 5;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponseV2.prototype.getTotalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponseV2} returns this
 */
proto.unifiedplatform.guidedMeditation.SelectedFileDetailsResponseV2.prototype.setTotalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.repeatedFields_ = [17];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.toObject = function(includeInstance, msg) {
  var f, obj = {
    meditationtype: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: (f = msg.getTitle()) && common_common_pb.Locale.toObject(includeInstance, f),
    subtitle: (f = msg.getSubtitle()) && common_common_pb.Locale.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && common_common_pb.Locale.toObject(includeInstance, f),
    durationofmeditationclip: jspb.Message.getFieldWithDefault(msg, 5, 0),
    tag: jspb.Message.getFieldWithDefault(msg, 6, ""),
    rating: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    likes: jspb.Message.getFieldWithDefault(msg, 8, 0),
    audiourl: (f = msg.getAudiourl()) && common_common_pb.Locale.toObject(includeInstance, f),
    imageurl: (f = msg.getImageurl()) && common_common_pb.Locale.toObject(includeInstance, f),
    purposefileid: jspb.Message.getFieldWithDefault(msg, 11, 0),
    durationfileid: jspb.Message.getFieldWithDefault(msg, 12, 0),
    thumbnailimgurl: (f = msg.getThumbnailimgurl()) && common_common_pb.Locale.toObject(includeInstance, f),
    durationofinstructionclip: jspb.Message.getFieldWithDefault(msg, 14, 0),
    audiourlv2: (f = msg.getAudiourlv2()) && common_common_pb.Locale.toObject(includeInstance, f),
    backgroundimageurl: jspb.Message.getFieldWithDefault(msg, 16, ""),
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    proto.unifiedplatform.guidedMeditation.Tag.toObject, includeInstance),
    attribute: (f = msg.getAttribute()) && common_common_pb.Locale.toObject(includeInstance, f),
    meditationcategory: jspb.Message.getFieldWithDefault(msg, 19, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2;
  return proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.MeditationType} */ (reader.readEnum());
      msg.setMeditationtype(value);
      break;
    case 2:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setTitle(value);
      break;
    case 3:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setSubtitle(value);
      break;
    case 4:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDurationofmeditationclip(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTag(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRating(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLikes(value);
      break;
    case 9:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setAudiourl(value);
      break;
    case 10:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setImageurl(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPurposefileid(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDurationfileid(value);
      break;
    case 13:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setThumbnailimgurl(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDurationofinstructionclip(value);
      break;
    case 15:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setAudiourlv2(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setBackgroundimageurl(value);
      break;
    case 17:
      var value = new proto.unifiedplatform.guidedMeditation.Tag;
      reader.readMessage(value,proto.unifiedplatform.guidedMeditation.Tag.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    case 18:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setAttribute(value);
      break;
    case 19:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.MeditationCategory} */ (reader.readEnum());
      msg.setMeditationcategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeditationtype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getSubtitle();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getDurationofmeditationclip();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getTag();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRating();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getLikes();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getAudiourl();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getImageurl();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getPurposefileid();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getDurationfileid();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getThumbnailimgurl();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getDurationofinstructionclip();
  if (f !== 0) {
    writer.writeUint32(
      14,
      f
    );
  }
  f = message.getAudiourlv2();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getBackgroundimageurl();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.unifiedplatform.guidedMeditation.Tag.serializeBinaryToWriter
    );
  }
  f = message.getAttribute();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getMeditationcategory();
  if (f !== 0.0) {
    writer.writeEnum(
      19,
      f
    );
  }
};


/**
 * optional unifiedplatform.heartintune.common.MeditationType meditationType = 1;
 * @return {!proto.unifiedplatform.heartintune.common.MeditationType}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.getMeditationtype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.MeditationType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.MeditationType} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2} returns this
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.setMeditationtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.Locale title = 2;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.getTitle = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 2));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2} returns this
*/
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.setTitle = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2} returns this
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.clearTitle = function() {
  return this.setTitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale subTitle = 3;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.getSubtitle = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 3));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2} returns this
*/
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.setSubtitle = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2} returns this
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.clearSubtitle = function() {
  return this.setSubtitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.hasSubtitle = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale description = 4;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.getDescription = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 4));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2} returns this
*/
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2} returns this
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional uint32 durationOfMeditationClip = 5;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.getDurationofmeditationclip = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2} returns this
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.setDurationofmeditationclip = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string tag = 6;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.getTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2} returns this
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.setTag = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional float rating = 7;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.getRating = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2} returns this
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.setRating = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional uint32 likes = 8;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.getLikes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2} returns this
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.setLikes = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional unifiedplatform.heartintune.common.Locale audioUrl = 9;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.getAudiourl = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 9));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2} returns this
*/
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.setAudiourl = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2} returns this
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.clearAudiourl = function() {
  return this.setAudiourl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.hasAudiourl = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale imageUrl = 10;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.getImageurl = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 10));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2} returns this
*/
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.setImageurl = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2} returns this
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.clearImageurl = function() {
  return this.setImageurl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.hasImageurl = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional int64 purposeFileID = 11;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.getPurposefileid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2} returns this
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.setPurposefileid = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 durationFileID = 12;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.getDurationfileid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2} returns this
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.setDurationfileid = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional unifiedplatform.heartintune.common.Locale thumbnailImgUrl = 13;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.getThumbnailimgurl = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 13));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2} returns this
*/
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.setThumbnailimgurl = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2} returns this
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.clearThumbnailimgurl = function() {
  return this.setThumbnailimgurl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.hasThumbnailimgurl = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional uint32 durationOfInstructionClip = 14;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.getDurationofinstructionclip = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2} returns this
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.setDurationofinstructionclip = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional unifiedplatform.heartintune.common.Locale audioUrlV2 = 15;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.getAudiourlv2 = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 15));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2} returns this
*/
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.setAudiourlv2 = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2} returns this
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.clearAudiourlv2 = function() {
  return this.setAudiourlv2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.hasAudiourlv2 = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional string backgroundImageUrl = 16;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.getBackgroundimageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2} returns this
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.setBackgroundimageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * repeated Tag tags = 17;
 * @return {!Array<!proto.unifiedplatform.guidedMeditation.Tag>}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.guidedMeditation.Tag>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.guidedMeditation.Tag, 17));
};


/**
 * @param {!Array<!proto.unifiedplatform.guidedMeditation.Tag>} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2} returns this
*/
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.unifiedplatform.guidedMeditation.Tag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.guidedMeditation.Tag}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.unifiedplatform.guidedMeditation.Tag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2} returns this
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * optional unifiedplatform.heartintune.common.Locale attribute = 18;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.getAttribute = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 18));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2} returns this
*/
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.setAttribute = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2} returns this
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.clearAttribute = function() {
  return this.setAttribute(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.hasAttribute = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional unifiedplatform.heartintune.common.MeditationCategory meditationCategory = 19;
 * @return {!proto.unifiedplatform.heartintune.common.MeditationCategory}
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.getMeditationcategory = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.MeditationCategory} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.MeditationCategory} value
 * @return {!proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2} returns this
 */
proto.unifiedplatform.guidedMeditation.AddMeditationFileDetailsRequestV2.prototype.setMeditationcategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 19, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.repeatedFields_ = [18,19];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    meditationtype: jspb.Message.getFieldWithDefault(msg, 2, 0),
    title: (f = msg.getTitle()) && common_common_pb.Locale.toObject(includeInstance, f),
    subtitle: (f = msg.getSubtitle()) && common_common_pb.Locale.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && common_common_pb.Locale.toObject(includeInstance, f),
    durationofmeditationclip: jspb.Message.getFieldWithDefault(msg, 6, 0),
    tag: jspb.Message.getFieldWithDefault(msg, 7, ""),
    rating: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    likes: jspb.Message.getFieldWithDefault(msg, 9, 0),
    audiourl: (f = msg.getAudiourl()) && common_common_pb.Locale.toObject(includeInstance, f),
    imageurl: (f = msg.getImageurl()) && common_common_pb.Locale.toObject(includeInstance, f),
    thumbnailimgurl: (f = msg.getThumbnailimgurl()) && common_common_pb.Locale.toObject(includeInstance, f),
    purposefileid: jspb.Message.getFieldWithDefault(msg, 13, 0),
    durationfileid: jspb.Message.getFieldWithDefault(msg, 14, 0),
    durationofinstructionclip: jspb.Message.getFieldWithDefault(msg, 15, 0),
    audiourlv2: (f = msg.getAudiourlv2()) && common_common_pb.Locale.toObject(includeInstance, f),
    backgroundimageurl: jspb.Message.getFieldWithDefault(msg, 17, ""),
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    proto.unifiedplatform.guidedMeditation.Tag.toObject, includeInstance),
    tagidsList: (f = jspb.Message.getRepeatedField(msg, 19)) == null ? undefined : f,
    attribute: (f = msg.getAttribute()) && common_common_pb.Locale.toObject(includeInstance, f),
    meditationcategory: jspb.Message.getFieldWithDefault(msg, 21, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2;
  return proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.MeditationType} */ (reader.readEnum());
      msg.setMeditationtype(value);
      break;
    case 3:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setTitle(value);
      break;
    case 4:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setSubtitle(value);
      break;
    case 5:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDurationofmeditationclip(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTag(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRating(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLikes(value);
      break;
    case 10:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setAudiourl(value);
      break;
    case 11:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setImageurl(value);
      break;
    case 12:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setThumbnailimgurl(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPurposefileid(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDurationfileid(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDurationofinstructionclip(value);
      break;
    case 16:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setAudiourlv2(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setBackgroundimageurl(value);
      break;
    case 18:
      var value = new proto.unifiedplatform.guidedMeditation.Tag;
      reader.readMessage(value,proto.unifiedplatform.guidedMeditation.Tag.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    case 19:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTagids(values[i]);
      }
      break;
    case 20:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setAttribute(value);
      break;
    case 21:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.MeditationCategory} */ (reader.readEnum());
      msg.setMeditationcategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMeditationtype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getSubtitle();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getDurationofmeditationclip();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getTag();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getRating();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getLikes();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getAudiourl();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getImageurl();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getThumbnailimgurl();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getPurposefileid();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getDurationfileid();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getDurationofinstructionclip();
  if (f !== 0) {
    writer.writeUint32(
      15,
      f
    );
  }
  f = message.getAudiourlv2();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getBackgroundimageurl();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      18,
      f,
      proto.unifiedplatform.guidedMeditation.Tag.serializeBinaryToWriter
    );
  }
  f = message.getTagidsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      19,
      f
    );
  }
  f = message.getAttribute();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getMeditationcategory();
  if (f !== 0.0) {
    writer.writeEnum(
      21,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.MeditationType meditationType = 2;
 * @return {!proto.unifiedplatform.heartintune.common.MeditationType}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.getMeditationtype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.MeditationType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.MeditationType} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.setMeditationtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional unifiedplatform.heartintune.common.Locale title = 3;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.getTitle = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 3));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2} returns this
*/
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.setTitle = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.clearTitle = function() {
  return this.setTitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale subTitle = 4;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.getSubtitle = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 4));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2} returns this
*/
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.setSubtitle = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.clearSubtitle = function() {
  return this.setSubtitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.hasSubtitle = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale description = 5;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.getDescription = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 5));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2} returns this
*/
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional uint32 durationOfMeditationClip = 6;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.getDurationofmeditationclip = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.setDurationofmeditationclip = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string tag = 7;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.getTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.setTag = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional float rating = 8;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.getRating = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.setRating = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional uint32 likes = 9;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.getLikes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.setLikes = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional unifiedplatform.heartintune.common.Locale audioUrl = 10;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.getAudiourl = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 10));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2} returns this
*/
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.setAudiourl = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.clearAudiourl = function() {
  return this.setAudiourl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.hasAudiourl = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale imageUrl = 11;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.getImageurl = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 11));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2} returns this
*/
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.setImageurl = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.clearImageurl = function() {
  return this.setImageurl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.hasImageurl = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale thumbnailImgUrl = 12;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.getThumbnailimgurl = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 12));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2} returns this
*/
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.setThumbnailimgurl = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.clearThumbnailimgurl = function() {
  return this.setThumbnailimgurl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.hasThumbnailimgurl = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional int64 purposeFileID = 13;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.getPurposefileid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.setPurposefileid = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int64 durationFileID = 14;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.getDurationfileid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.setDurationfileid = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional uint32 durationOfInstructionClip = 15;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.getDurationofinstructionclip = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.setDurationofinstructionclip = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional unifiedplatform.heartintune.common.Locale audioUrlV2 = 16;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.getAudiourlv2 = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 16));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2} returns this
*/
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.setAudiourlv2 = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.clearAudiourlv2 = function() {
  return this.setAudiourlv2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.hasAudiourlv2 = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional string backgroundImageUrl = 17;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.getBackgroundimageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.setBackgroundimageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * repeated Tag tags = 18;
 * @return {!Array<!proto.unifiedplatform.guidedMeditation.Tag>}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.guidedMeditation.Tag>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.guidedMeditation.Tag, 18));
};


/**
 * @param {!Array<!proto.unifiedplatform.guidedMeditation.Tag>} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2} returns this
*/
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 18, value);
};


/**
 * @param {!proto.unifiedplatform.guidedMeditation.Tag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.guidedMeditation.Tag}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 18, opt_value, proto.unifiedplatform.guidedMeditation.Tag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * repeated int64 tagIds = 19;
 * @return {!Array<number>}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.getTagidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 19));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.setTagidsList = function(value) {
  return jspb.Message.setField(this, 19, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.addTagids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 19, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.clearTagidsList = function() {
  return this.setTagidsList([]);
};


/**
 * optional unifiedplatform.heartintune.common.Locale attribute = 20;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.getAttribute = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 20));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2} returns this
*/
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.setAttribute = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.clearAttribute = function() {
  return this.setAttribute(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.hasAttribute = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional unifiedplatform.heartintune.common.MeditationCategory meditationCategory = 21;
 * @return {!proto.unifiedplatform.heartintune.common.MeditationCategory}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.getMeditationcategory = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.MeditationCategory} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.MeditationCategory} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileDetailsV2.prototype.setMeditationcategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 21, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.repeatedFields_ = [18,19];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.MeditationFileV2.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.MeditationFileV2} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    meditationtype: jspb.Message.getFieldWithDefault(msg, 2, 0),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    subtitle: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    durationofmeditationclip: jspb.Message.getFieldWithDefault(msg, 6, 0),
    tag: jspb.Message.getFieldWithDefault(msg, 7, ""),
    rating: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    likes: jspb.Message.getFieldWithDefault(msg, 9, 0),
    audiourl: jspb.Message.getFieldWithDefault(msg, 10, ""),
    imageurl: jspb.Message.getFieldWithDefault(msg, 11, ""),
    durationfile: (f = msg.getDurationfile()) && proto.unifiedplatform.guidedMeditation.DurationFile.toObject(includeInstance, f),
    purposefile: (f = msg.getPurposefile()) && proto.unifiedplatform.guidedMeditation.PurposeFile.toObject(includeInstance, f),
    thumbnailimgurl: jspb.Message.getFieldWithDefault(msg, 14, ""),
    durationofinstructionclip: jspb.Message.getFieldWithDefault(msg, 15, 0),
    audiourlv2: jspb.Message.getFieldWithDefault(msg, 16, ""),
    backgroundimageurl: jspb.Message.getFieldWithDefault(msg, 17, ""),
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    proto.unifiedplatform.guidedMeditation.Tag.toObject, includeInstance),
    tagidsList: (f = jspb.Message.getRepeatedField(msg, 19)) == null ? undefined : f,
    attribute: jspb.Message.getFieldWithDefault(msg, 20, ""),
    meditationcategory: jspb.Message.getFieldWithDefault(msg, 21, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileV2}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.MeditationFileV2;
  return proto.unifiedplatform.guidedMeditation.MeditationFileV2.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.MeditationFileV2} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileV2}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.MeditationType} */ (reader.readEnum());
      msg.setMeditationtype(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubtitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDurationofmeditationclip(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTag(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRating(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLikes(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAudiourl(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageurl(value);
      break;
    case 12:
      var value = new proto.unifiedplatform.guidedMeditation.DurationFile;
      reader.readMessage(value,proto.unifiedplatform.guidedMeditation.DurationFile.deserializeBinaryFromReader);
      msg.setDurationfile(value);
      break;
    case 13:
      var value = new proto.unifiedplatform.guidedMeditation.PurposeFile;
      reader.readMessage(value,proto.unifiedplatform.guidedMeditation.PurposeFile.deserializeBinaryFromReader);
      msg.setPurposefile(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbnailimgurl(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDurationofinstructionclip(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setAudiourlv2(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setBackgroundimageurl(value);
      break;
    case 18:
      var value = new proto.unifiedplatform.guidedMeditation.Tag;
      reader.readMessage(value,proto.unifiedplatform.guidedMeditation.Tag.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    case 19:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTagids(values[i]);
      }
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttribute(value);
      break;
    case 21:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.MeditationCategory} */ (reader.readEnum());
      msg.setMeditationcategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.MeditationFileV2.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.MeditationFileV2} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMeditationtype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSubtitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDurationofmeditationclip();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getTag();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getRating();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getLikes();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getAudiourl();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getImageurl();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getDurationfile();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.unifiedplatform.guidedMeditation.DurationFile.serializeBinaryToWriter
    );
  }
  f = message.getPurposefile();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.unifiedplatform.guidedMeditation.PurposeFile.serializeBinaryToWriter
    );
  }
  f = message.getThumbnailimgurl();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getDurationofinstructionclip();
  if (f !== 0) {
    writer.writeUint32(
      15,
      f
    );
  }
  f = message.getAudiourlv2();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getBackgroundimageurl();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      18,
      f,
      proto.unifiedplatform.guidedMeditation.Tag.serializeBinaryToWriter
    );
  }
  f = message.getTagidsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      19,
      f
    );
  }
  f = message.getAttribute();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getMeditationcategory();
  if (f !== 0.0) {
    writer.writeEnum(
      21,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileV2} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.MeditationType meditationType = 2;
 * @return {!proto.unifiedplatform.heartintune.common.MeditationType}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.getMeditationtype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.MeditationType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.MeditationType} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileV2} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.setMeditationtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileV2} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string subTitle = 4;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.getSubtitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileV2} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.setSubtitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileV2} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint32 durationOfMeditationClip = 6;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.getDurationofmeditationclip = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileV2} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.setDurationofmeditationclip = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string tag = 7;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.getTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileV2} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.setTag = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional float rating = 8;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.getRating = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileV2} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.setRating = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional uint32 likes = 9;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.getLikes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileV2} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.setLikes = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string audioUrl = 10;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.getAudiourl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileV2} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.setAudiourl = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string imageUrl = 11;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.getImageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileV2} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.setImageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional DurationFile durationFile = 12;
 * @return {?proto.unifiedplatform.guidedMeditation.DurationFile}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.getDurationfile = function() {
  return /** @type{?proto.unifiedplatform.guidedMeditation.DurationFile} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.guidedMeditation.DurationFile, 12));
};


/**
 * @param {?proto.unifiedplatform.guidedMeditation.DurationFile|undefined} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileV2} returns this
*/
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.setDurationfile = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileV2} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.clearDurationfile = function() {
  return this.setDurationfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.hasDurationfile = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional PurposeFile purposeFile = 13;
 * @return {?proto.unifiedplatform.guidedMeditation.PurposeFile}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.getPurposefile = function() {
  return /** @type{?proto.unifiedplatform.guidedMeditation.PurposeFile} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.guidedMeditation.PurposeFile, 13));
};


/**
 * @param {?proto.unifiedplatform.guidedMeditation.PurposeFile|undefined} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileV2} returns this
*/
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.setPurposefile = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileV2} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.clearPurposefile = function() {
  return this.setPurposefile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.hasPurposefile = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string thumbnailImgUrl = 14;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.getThumbnailimgurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileV2} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.setThumbnailimgurl = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional uint32 durationOfInstructionClip = 15;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.getDurationofinstructionclip = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileV2} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.setDurationofinstructionclip = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional string audioUrlV2 = 16;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.getAudiourlv2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileV2} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.setAudiourlv2 = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string backgroundImageUrl = 17;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.getBackgroundimageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileV2} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.setBackgroundimageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * repeated Tag tags = 18;
 * @return {!Array<!proto.unifiedplatform.guidedMeditation.Tag>}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.guidedMeditation.Tag>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.guidedMeditation.Tag, 18));
};


/**
 * @param {!Array<!proto.unifiedplatform.guidedMeditation.Tag>} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileV2} returns this
*/
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 18, value);
};


/**
 * @param {!proto.unifiedplatform.guidedMeditation.Tag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.guidedMeditation.Tag}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 18, opt_value, proto.unifiedplatform.guidedMeditation.Tag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileV2} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * repeated int64 tagIds = 19;
 * @return {!Array<number>}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.getTagidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 19));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileV2} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.setTagidsList = function(value) {
  return jspb.Message.setField(this, 19, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileV2} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.addTagids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 19, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileV2} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.clearTagidsList = function() {
  return this.setTagidsList([]);
};


/**
 * optional string attribute = 20;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.getAttribute = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileV2} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.setAttribute = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional unifiedplatform.heartintune.common.MeditationCategory meditationCategory = 21;
 * @return {!proto.unifiedplatform.heartintune.common.MeditationCategory}
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.getMeditationcategory = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.MeditationCategory} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.MeditationCategory} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileV2} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditationFileV2.prototype.setMeditationcategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 21, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.URLRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.URLRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.URLRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.URLRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, ""),
    backgroundtype: jspb.Message.getFieldWithDefault(msg, 2, 0),
    title: (f = msg.getTitle()) && common_common_pb.Locale.toObject(includeInstance, f),
    thumbnailimgurl: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.URLRequest}
 */
proto.unifiedplatform.guidedMeditation.URLRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.URLRequest;
  return proto.unifiedplatform.guidedMeditation.URLRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.URLRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.URLRequest}
 */
proto.unifiedplatform.guidedMeditation.URLRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 2:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.BackGroundType} */ (reader.readEnum());
      msg.setBackgroundtype(value);
      break;
    case 3:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbnailimgurl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.URLRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.URLRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.URLRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.URLRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBackgroundtype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getThumbnailimgurl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.URLRequest.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.URLRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.URLRequest.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.BackGroundType backGroundType = 2;
 * @return {!proto.unifiedplatform.heartintune.common.BackGroundType}
 */
proto.unifiedplatform.guidedMeditation.URLRequest.prototype.getBackgroundtype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.BackGroundType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.BackGroundType} value
 * @return {!proto.unifiedplatform.guidedMeditation.URLRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.URLRequest.prototype.setBackgroundtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional unifiedplatform.heartintune.common.Locale title = 3;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.guidedMeditation.URLRequest.prototype.getTitle = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 3));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.guidedMeditation.URLRequest} returns this
*/
proto.unifiedplatform.guidedMeditation.URLRequest.prototype.setTitle = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.guidedMeditation.URLRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.URLRequest.prototype.clearTitle = function() {
  return this.setTitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.URLRequest.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string thumbnailImgUrl = 4;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.URLRequest.prototype.getThumbnailimgurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.URLRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.URLRequest.prototype.setThumbnailimgurl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    url: jspb.Message.getFieldWithDefault(msg, 2, ""),
    backgroundtype: jspb.Message.getFieldWithDefault(msg, 3, 0),
    title: (f = msg.getTitle()) && common_common_pb.Locale.toObject(includeInstance, f),
    thumbnailimgurl: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails}
 */
proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails;
  return proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails}
 */
proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 3:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.BackGroundType} */ (reader.readEnum());
      msg.setBackgroundtype(value);
      break;
    case 4:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setTitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbnailimgurl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBackgroundtype();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getTitle();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getThumbnailimgurl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string url = 2;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional unifiedplatform.heartintune.common.BackGroundType backGroundType = 3;
 * @return {!proto.unifiedplatform.heartintune.common.BackGroundType}
 */
proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails.prototype.getBackgroundtype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.BackGroundType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.BackGroundType} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails.prototype.setBackgroundtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional unifiedplatform.heartintune.common.Locale title = 4;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails.prototype.getTitle = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 4));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails} returns this
*/
proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails.prototype.setTitle = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails.prototype.clearTitle = function() {
  return this.setTitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string thumbnailImgUrl = 5;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails.prototype.getThumbnailimgurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails.prototype.setThumbnailimgurl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    url: jspb.Message.getFieldWithDefault(msg, 2, ""),
    backgroundtype: jspb.Message.getFieldWithDefault(msg, 3, 0),
    title: jspb.Message.getFieldWithDefault(msg, 4, ""),
    thumbnailimgurl: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale}
 */
proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale;
  return proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale}
 */
proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 3:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.BackGroundType} */ (reader.readEnum());
      msg.setBackgroundtype(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbnailimgurl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBackgroundtype();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getThumbnailimgurl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string url = 2;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional unifiedplatform.heartintune.common.BackGroundType backGroundType = 3;
 * @return {!proto.unifiedplatform.heartintune.common.BackGroundType}
 */
proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale.prototype.getBackgroundtype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.BackGroundType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.BackGroundType} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale.prototype.setBackgroundtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string title = 4;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string thumbnailImgUrl = 5;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale.prototype.getThumbnailimgurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale} returns this
 */
proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale.prototype.setThumbnailimgurl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetails.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    meditaionbckgndimgList: jspb.Message.toObjectList(msg.getMeditaionbckgndimgList(),
    proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails.toObject, includeInstance),
    meditaionbckgndmusicList: jspb.Message.toObjectList(msg.getMeditaionbckgndmusicList(),
    proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetails}
 */
proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetails;
  return proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetails}
 */
proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails;
      reader.readMessage(value,proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails.deserializeBinaryFromReader);
      msg.addMeditaionbckgndimg(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails;
      reader.readMessage(value,proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails.deserializeBinaryFromReader);
      msg.addMeditaionbckgndmusic(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeditaionbckgndimgList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails.serializeBinaryToWriter
    );
  }
  f = message.getMeditaionbckgndmusicList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MeditaionBckgndDetails meditaionBckgndImg = 1;
 * @return {!Array<!proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails>}
 */
proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetails.prototype.getMeditaionbckgndimgList = function() {
  return /** @type{!Array<!proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails>} value
 * @return {!proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetails} returns this
*/
proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetails.prototype.setMeditaionbckgndimgList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails}
 */
proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetails.prototype.addMeditaionbckgndimg = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetails} returns this
 */
proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetails.prototype.clearMeditaionbckgndimgList = function() {
  return this.setMeditaionbckgndimgList([]);
};


/**
 * repeated MeditaionBckgndDetails meditaionBckgndMusic = 2;
 * @return {!Array<!proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails>}
 */
proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetails.prototype.getMeditaionbckgndmusicList = function() {
  return /** @type{!Array<!proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails, 2));
};


/**
 * @param {!Array<!proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails>} value
 * @return {!proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetails} returns this
*/
proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetails.prototype.setMeditaionbckgndmusicList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails}
 */
proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetails.prototype.addMeditaionbckgndmusic = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetails} returns this
 */
proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetails.prototype.clearMeditaionbckgndmusicList = function() {
  return this.setMeditaionbckgndmusicList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetailsLocale.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetailsLocale.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetailsLocale.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetailsLocale} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetailsLocale.toObject = function(includeInstance, msg) {
  var f, obj = {
    meditaionbckgndimgList: jspb.Message.toObjectList(msg.getMeditaionbckgndimgList(),
    proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale.toObject, includeInstance),
    meditaionbckgndmusicList: jspb.Message.toObjectList(msg.getMeditaionbckgndmusicList(),
    proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetailsLocale}
 */
proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetailsLocale.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetailsLocale;
  return proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetailsLocale.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetailsLocale} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetailsLocale}
 */
proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetailsLocale.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale;
      reader.readMessage(value,proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale.deserializeBinaryFromReader);
      msg.addMeditaionbckgndimg(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale;
      reader.readMessage(value,proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale.deserializeBinaryFromReader);
      msg.addMeditaionbckgndmusic(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetailsLocale.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetailsLocale.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetailsLocale} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetailsLocale.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeditaionbckgndimgList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale.serializeBinaryToWriter
    );
  }
  f = message.getMeditaionbckgndmusicList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MeditaionBckgndDetailsLocale meditaionBckgndImg = 1;
 * @return {!Array<!proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale>}
 */
proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetailsLocale.prototype.getMeditaionbckgndimgList = function() {
  return /** @type{!Array<!proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale>} value
 * @return {!proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetailsLocale} returns this
*/
proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetailsLocale.prototype.setMeditaionbckgndimgList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale}
 */
proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetailsLocale.prototype.addMeditaionbckgndimg = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetailsLocale} returns this
 */
proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetailsLocale.prototype.clearMeditaionbckgndimgList = function() {
  return this.setMeditaionbckgndimgList([]);
};


/**
 * repeated MeditaionBckgndDetailsLocale meditaionBckgndMusic = 2;
 * @return {!Array<!proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale>}
 */
proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetailsLocale.prototype.getMeditaionbckgndmusicList = function() {
  return /** @type{!Array<!proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale, 2));
};


/**
 * @param {!Array<!proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale>} value
 * @return {!proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetailsLocale} returns this
*/
proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetailsLocale.prototype.setMeditaionbckgndmusicList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale}
 */
proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetailsLocale.prototype.addMeditaionbckgndmusic = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.unifiedplatform.guidedMeditation.MeditaionBckgndDetailsLocale, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetailsLocale} returns this
 */
proto.unifiedplatform.guidedMeditation.ListOfSelectedMeditaionBckgndDetailsLocale.prototype.clearMeditaionbckgndmusicList = function() {
  return this.setMeditaionbckgndmusicList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.SelectedMeditaionBckgndDetailsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.SelectedMeditaionBckgndDetailsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.SelectedMeditaionBckgndDetailsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.SelectedMeditaionBckgndDetailsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    getonlyimage: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    getonlymusic: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.SelectedMeditaionBckgndDetailsRequest}
 */
proto.unifiedplatform.guidedMeditation.SelectedMeditaionBckgndDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.SelectedMeditaionBckgndDetailsRequest;
  return proto.unifiedplatform.guidedMeditation.SelectedMeditaionBckgndDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.SelectedMeditaionBckgndDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.SelectedMeditaionBckgndDetailsRequest}
 */
proto.unifiedplatform.guidedMeditation.SelectedMeditaionBckgndDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGetonlyimage(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGetonlymusic(value);
      break;
    case 3:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.SelectedMeditaionBckgndDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.SelectedMeditaionBckgndDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.SelectedMeditaionBckgndDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.SelectedMeditaionBckgndDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGetonlyimage();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getGetonlymusic();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional bool getOnlyImage = 1;
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.SelectedMeditaionBckgndDetailsRequest.prototype.getGetonlyimage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.guidedMeditation.SelectedMeditaionBckgndDetailsRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.SelectedMeditaionBckgndDetailsRequest.prototype.setGetonlyimage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool getOnlyMusic = 2;
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.SelectedMeditaionBckgndDetailsRequest.prototype.getGetonlymusic = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.guidedMeditation.SelectedMeditaionBckgndDetailsRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.SelectedMeditaionBckgndDetailsRequest.prototype.setGetonlymusic = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 3;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.guidedMeditation.SelectedMeditaionBckgndDetailsRequest.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.guidedMeditation.SelectedMeditaionBckgndDetailsRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.SelectedMeditaionBckgndDetailsRequest.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.ResponsePageable.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.ResponsePageable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.ResponsePageable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.ResponsePageable.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalcount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    selectedpagesize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    selectedpagenumber: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.ResponsePageable}
 */
proto.unifiedplatform.guidedMeditation.ResponsePageable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.ResponsePageable;
  return proto.unifiedplatform.guidedMeditation.ResponsePageable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.ResponsePageable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.ResponsePageable}
 */
proto.unifiedplatform.guidedMeditation.ResponsePageable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalcount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSelectedpagesize(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSelectedpagenumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.ResponsePageable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.ResponsePageable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.ResponsePageable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.ResponsePageable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalcount();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSelectedpagesize();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getSelectedpagenumber();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional int64 totalCount = 1;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.ResponsePageable.prototype.getTotalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.ResponsePageable} returns this
 */
proto.unifiedplatform.guidedMeditation.ResponsePageable.prototype.setTotalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 selectedPageSize = 2;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.ResponsePageable.prototype.getSelectedpagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.ResponsePageable} returns this
 */
proto.unifiedplatform.guidedMeditation.ResponsePageable.prototype.setSelectedpagesize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 selectedPageNumber = 3;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.ResponsePageable.prototype.getSelectedpagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.ResponsePageable} returns this
 */
proto.unifiedplatform.guidedMeditation.ResponsePageable.prototype.setSelectedpagenumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.Tag.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.Tag.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.Tag} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.Tag.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.Tag}
 */
proto.unifiedplatform.guidedMeditation.Tag.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.Tag;
  return proto.unifiedplatform.guidedMeditation.Tag.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.Tag} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.Tag}
 */
proto.unifiedplatform.guidedMeditation.Tag.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.Tag.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.Tag.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.Tag} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.Tag.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.Tag.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.Tag} returns this
 */
proto.unifiedplatform.guidedMeditation.Tag.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.Tag.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.Tag} returns this
 */
proto.unifiedplatform.guidedMeditation.Tag.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.guidedMeditation.TagWithMeditation.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.TagWithMeditation.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.TagWithMeditation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.TagWithMeditation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.TagWithMeditation.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    contentsList: jspb.Message.toObjectList(msg.getContentsList(),
    proto.unifiedplatform.guidedMeditation.MeditationFileV2.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.TagWithMeditation}
 */
proto.unifiedplatform.guidedMeditation.TagWithMeditation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.TagWithMeditation;
  return proto.unifiedplatform.guidedMeditation.TagWithMeditation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.TagWithMeditation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.TagWithMeditation}
 */
proto.unifiedplatform.guidedMeditation.TagWithMeditation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.unifiedplatform.guidedMeditation.MeditationFileV2;
      reader.readMessage(value,proto.unifiedplatform.guidedMeditation.MeditationFileV2.deserializeBinaryFromReader);
      msg.addContents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.TagWithMeditation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.TagWithMeditation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.TagWithMeditation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.TagWithMeditation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getContentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.unifiedplatform.guidedMeditation.MeditationFileV2.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.TagWithMeditation.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.TagWithMeditation} returns this
 */
proto.unifiedplatform.guidedMeditation.TagWithMeditation.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.TagWithMeditation.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.TagWithMeditation} returns this
 */
proto.unifiedplatform.guidedMeditation.TagWithMeditation.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated MeditationFileV2 contents = 3;
 * @return {!Array<!proto.unifiedplatform.guidedMeditation.MeditationFileV2>}
 */
proto.unifiedplatform.guidedMeditation.TagWithMeditation.prototype.getContentsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.guidedMeditation.MeditationFileV2>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.guidedMeditation.MeditationFileV2, 3));
};


/**
 * @param {!Array<!proto.unifiedplatform.guidedMeditation.MeditationFileV2>} value
 * @return {!proto.unifiedplatform.guidedMeditation.TagWithMeditation} returns this
*/
proto.unifiedplatform.guidedMeditation.TagWithMeditation.prototype.setContentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.unifiedplatform.guidedMeditation.MeditationFileV2=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.guidedMeditation.MeditationFileV2}
 */
proto.unifiedplatform.guidedMeditation.TagWithMeditation.prototype.addContents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.unifiedplatform.guidedMeditation.MeditationFileV2, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.guidedMeditation.TagWithMeditation} returns this
 */
proto.unifiedplatform.guidedMeditation.TagWithMeditation.prototype.clearContentsList = function() {
  return this.setContentsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.guidedMeditation.TagsList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.TagsList.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.TagsList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.TagsList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.TagsList.toObject = function(includeInstance, msg) {
  var f, obj = {
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    proto.unifiedplatform.guidedMeditation.Tag.toObject, includeInstance),
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.guidedMeditation.ResponsePageable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.TagsList}
 */
proto.unifiedplatform.guidedMeditation.TagsList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.TagsList;
  return proto.unifiedplatform.guidedMeditation.TagsList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.TagsList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.TagsList}
 */
proto.unifiedplatform.guidedMeditation.TagsList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.guidedMeditation.Tag;
      reader.readMessage(value,proto.unifiedplatform.guidedMeditation.Tag.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.guidedMeditation.ResponsePageable;
      reader.readMessage(value,proto.unifiedplatform.guidedMeditation.ResponsePageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.TagsList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.TagsList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.TagsList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.TagsList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.guidedMeditation.Tag.serializeBinaryToWriter
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.guidedMeditation.ResponsePageable.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Tag tags = 1;
 * @return {!Array<!proto.unifiedplatform.guidedMeditation.Tag>}
 */
proto.unifiedplatform.guidedMeditation.TagsList.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.guidedMeditation.Tag>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.guidedMeditation.Tag, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.guidedMeditation.Tag>} value
 * @return {!proto.unifiedplatform.guidedMeditation.TagsList} returns this
*/
proto.unifiedplatform.guidedMeditation.TagsList.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.guidedMeditation.Tag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.guidedMeditation.Tag}
 */
proto.unifiedplatform.guidedMeditation.TagsList.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.guidedMeditation.Tag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.guidedMeditation.TagsList} returns this
 */
proto.unifiedplatform.guidedMeditation.TagsList.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * optional ResponsePageable pageable = 2;
 * @return {?proto.unifiedplatform.guidedMeditation.ResponsePageable}
 */
proto.unifiedplatform.guidedMeditation.TagsList.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.guidedMeditation.ResponsePageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.guidedMeditation.ResponsePageable, 2));
};


/**
 * @param {?proto.unifiedplatform.guidedMeditation.ResponsePageable|undefined} value
 * @return {!proto.unifiedplatform.guidedMeditation.TagsList} returns this
*/
proto.unifiedplatform.guidedMeditation.TagsList.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.guidedMeditation.TagsList} returns this
 */
proto.unifiedplatform.guidedMeditation.TagsList.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.TagsList.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.guidedMeditation.TagsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.TagsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.TagsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.TagsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.TagsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    proto.unifiedplatform.guidedMeditation.Tag.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.TagsRequest}
 */
proto.unifiedplatform.guidedMeditation.TagsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.TagsRequest;
  return proto.unifiedplatform.guidedMeditation.TagsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.TagsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.TagsRequest}
 */
proto.unifiedplatform.guidedMeditation.TagsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.guidedMeditation.Tag;
      reader.readMessage(value,proto.unifiedplatform.guidedMeditation.Tag.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.TagsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.TagsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.TagsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.TagsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.guidedMeditation.Tag.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Tag tags = 1;
 * @return {!Array<!proto.unifiedplatform.guidedMeditation.Tag>}
 */
proto.unifiedplatform.guidedMeditation.TagsRequest.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.guidedMeditation.Tag>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.guidedMeditation.Tag, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.guidedMeditation.Tag>} value
 * @return {!proto.unifiedplatform.guidedMeditation.TagsRequest} returns this
*/
proto.unifiedplatform.guidedMeditation.TagsRequest.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.guidedMeditation.Tag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.guidedMeditation.Tag}
 */
proto.unifiedplatform.guidedMeditation.TagsRequest.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.guidedMeditation.Tag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.guidedMeditation.TagsRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.TagsRequest.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.guidedMeditation.RecentlyPracticedMeditationsByUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.guidedMeditation.RecentlyPracticedMeditationsByUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.guidedMeditation.RecentlyPracticedMeditationsByUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.RecentlyPracticedMeditationsByUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    meditationtype: jspb.Message.getFieldWithDefault(msg, 2, 0),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 3, 0),
    requestpageable: (f = msg.getRequestpageable()) && proto.unifiedplatform.guidedMeditation.RequestPageable.toObject(includeInstance, f),
    firebaseid: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.guidedMeditation.RecentlyPracticedMeditationsByUserRequest}
 */
proto.unifiedplatform.guidedMeditation.RecentlyPracticedMeditationsByUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.guidedMeditation.RecentlyPracticedMeditationsByUserRequest;
  return proto.unifiedplatform.guidedMeditation.RecentlyPracticedMeditationsByUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.guidedMeditation.RecentlyPracticedMeditationsByUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.guidedMeditation.RecentlyPracticedMeditationsByUserRequest}
 */
proto.unifiedplatform.guidedMeditation.RecentlyPracticedMeditationsByUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.MeditationType} */ (reader.readEnum());
      msg.setMeditationtype(value);
      break;
    case 3:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    case 4:
      var value = new proto.unifiedplatform.guidedMeditation.RequestPageable;
      reader.readMessage(value,proto.unifiedplatform.guidedMeditation.RequestPageable.deserializeBinaryFromReader);
      msg.setRequestpageable(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirebaseid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.guidedMeditation.RecentlyPracticedMeditationsByUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.guidedMeditation.RecentlyPracticedMeditationsByUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.guidedMeditation.RecentlyPracticedMeditationsByUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.guidedMeditation.RecentlyPracticedMeditationsByUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMeditationtype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getRequestpageable();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.unifiedplatform.guidedMeditation.RequestPageable.serializeBinaryToWriter
    );
  }
  f = message.getFirebaseid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int64 userId = 1;
 * @return {number}
 */
proto.unifiedplatform.guidedMeditation.RecentlyPracticedMeditationsByUserRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.guidedMeditation.RecentlyPracticedMeditationsByUserRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.RecentlyPracticedMeditationsByUserRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.MeditationType meditationType = 2;
 * @return {!proto.unifiedplatform.heartintune.common.MeditationType}
 */
proto.unifiedplatform.guidedMeditation.RecentlyPracticedMeditationsByUserRequest.prototype.getMeditationtype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.MeditationType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.MeditationType} value
 * @return {!proto.unifiedplatform.guidedMeditation.RecentlyPracticedMeditationsByUserRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.RecentlyPracticedMeditationsByUserRequest.prototype.setMeditationtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 3;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.guidedMeditation.RecentlyPracticedMeditationsByUserRequest.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.guidedMeditation.RecentlyPracticedMeditationsByUserRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.RecentlyPracticedMeditationsByUserRequest.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional RequestPageable requestPageable = 4;
 * @return {?proto.unifiedplatform.guidedMeditation.RequestPageable}
 */
proto.unifiedplatform.guidedMeditation.RecentlyPracticedMeditationsByUserRequest.prototype.getRequestpageable = function() {
  return /** @type{?proto.unifiedplatform.guidedMeditation.RequestPageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.guidedMeditation.RequestPageable, 4));
};


/**
 * @param {?proto.unifiedplatform.guidedMeditation.RequestPageable|undefined} value
 * @return {!proto.unifiedplatform.guidedMeditation.RecentlyPracticedMeditationsByUserRequest} returns this
*/
proto.unifiedplatform.guidedMeditation.RecentlyPracticedMeditationsByUserRequest.prototype.setRequestpageable = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.guidedMeditation.RecentlyPracticedMeditationsByUserRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.RecentlyPracticedMeditationsByUserRequest.prototype.clearRequestpageable = function() {
  return this.setRequestpageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.guidedMeditation.RecentlyPracticedMeditationsByUserRequest.prototype.hasRequestpageable = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string firebaseId = 5;
 * @return {string}
 */
proto.unifiedplatform.guidedMeditation.RecentlyPracticedMeditationsByUserRequest.prototype.getFirebaseid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.guidedMeditation.RecentlyPracticedMeditationsByUserRequest} returns this
 */
proto.unifiedplatform.guidedMeditation.RecentlyPracticedMeditationsByUserRequest.prototype.setFirebaseid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


goog.object.extend(exports, proto.unifiedplatform.guidedMeditation);
