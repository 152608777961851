// source: stats.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var device_pb = require('./device_pb.js');
goog.object.extend(proto, device_pb);
var common_common_pb = require('./common/common_pb.js');
goog.object.extend(proto, common_common_pb);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.stats.CurrentStatus', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.stats.MeditationScheduled', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.stats.StatsUpdateStatus', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats.displayName = 'proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationScheduled = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.stats.MeditationScheduled, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.stats.MeditationScheduled.displayName = 'proto.unifiedplatform.heartIntuneProfile.stats.MeditationScheduled';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.stats.StatsUpdateStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.stats.StatsUpdateStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.stats.StatsUpdateStatus.displayName = 'proto.unifiedplatform.heartIntuneProfile.stats.StatsUpdateStatus';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    meditationattendedid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    typeofmeditation: jspb.Message.getFieldWithDefault(msg, 3, 0),
    starttime: (f = msg.getStarttime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endtime: (f = msg.getEndtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    pausecount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    playcount: jspb.Message.getFieldWithDefault(msg, 7, 0),
    totaldurationinmilliseconds: jspb.Message.getFieldWithDefault(msg, 8, 0),
    completedfullmeditation: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    uuid: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats}
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats;
  return proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats}
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMeditationattendedid(value);
      break;
    case 3:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.MeditationType} */ (reader.readEnum());
      msg.setTypeofmeditation(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStarttime(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndtime(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPausecount(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPlaycount(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotaldurationinmilliseconds(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompletedfullmeditation(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMeditationattendedid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getTypeofmeditation();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getStarttime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndtime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPausecount();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getPlaycount();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getTotaldurationinmilliseconds();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getCompletedfullmeditation();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats} returns this
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 meditationAttendedID = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats.prototype.getMeditationattendedid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats} returns this
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats.prototype.setMeditationattendedid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional unifiedplatform.heartintune.common.MeditationType typeOfMeditation = 3;
 * @return {!proto.unifiedplatform.heartintune.common.MeditationType}
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats.prototype.getTypeofmeditation = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.MeditationType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.MeditationType} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats} returns this
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats.prototype.setTypeofmeditation = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp startTime = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats.prototype.getStarttime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats} returns this
*/
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats.prototype.setStarttime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats} returns this
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats.prototype.clearStarttime = function() {
  return this.setStarttime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats.prototype.hasStarttime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp endTime = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats.prototype.getEndtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats} returns this
*/
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats.prototype.setEndtime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats} returns this
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats.prototype.clearEndtime = function() {
  return this.setEndtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats.prototype.hasEndtime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional uint32 pauseCount = 6;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats.prototype.getPausecount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats} returns this
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats.prototype.setPausecount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 playCount = 7;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats.prototype.getPlaycount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats} returns this
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats.prototype.setPlaycount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 totalDurationInMilliSeconds = 8;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats.prototype.getTotaldurationinmilliseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats} returns this
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats.prototype.setTotaldurationinmilliseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional bool completedFullMeditation = 9;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats.prototype.getCompletedfullmeditation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats} returns this
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats.prototype.setCompletedfullmeditation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional string UUID = 10;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats} returns this
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStats.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    meditationattendedid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    typeofmeditation: jspb.Message.getFieldWithDefault(msg, 3, 0),
    starttime: (f = msg.getStarttime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endtime: (f = msg.getEndtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    pausecount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    playcount: jspb.Message.getFieldWithDefault(msg, 7, 0),
    totaldurationinmilliseconds: jspb.Message.getFieldWithDefault(msg, 8, 0),
    completedfullmeditation: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    uuid: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest}
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest;
  return proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest}
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMeditationattendedid(value);
      break;
    case 3:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.MeditationType} */ (reader.readEnum());
      msg.setTypeofmeditation(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStarttime(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndtime(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPausecount(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPlaycount(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotaldurationinmilliseconds(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompletedfullmeditation(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeditationattendedid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getTypeofmeditation();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getStarttime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndtime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPausecount();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getPlaycount();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getTotaldurationinmilliseconds();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getCompletedfullmeditation();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional int64 meditationAttendedID = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest.prototype.getMeditationattendedid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest.prototype.setMeditationattendedid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional unifiedplatform.heartintune.common.MeditationType typeOfMeditation = 3;
 * @return {!proto.unifiedplatform.heartintune.common.MeditationType}
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest.prototype.getTypeofmeditation = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.MeditationType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.MeditationType} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest.prototype.setTypeofmeditation = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp startTime = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest.prototype.getStarttime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest} returns this
*/
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest.prototype.setStarttime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest.prototype.clearStarttime = function() {
  return this.setStarttime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest.prototype.hasStarttime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp endTime = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest.prototype.getEndtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest} returns this
*/
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest.prototype.setEndtime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest.prototype.clearEndtime = function() {
  return this.setEndtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest.prototype.hasEndtime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional uint32 pauseCount = 6;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest.prototype.getPausecount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest.prototype.setPausecount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 playCount = 7;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest.prototype.getPlaycount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest.prototype.setPlaycount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 totalDurationInMilliSeconds = 8;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest.prototype.getTotaldurationinmilliseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest.prototype.setTotaldurationinmilliseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional bool completedFullMeditation = 9;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest.prototype.getCompletedfullmeditation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest.prototype.setCompletedfullmeditation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional string UUID = 10;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationStatsRequest.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationScheduled.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.stats.MeditationScheduled.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.stats.MeditationScheduled} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationScheduled.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    associatedgoalid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    scheduleddateandtime: (f = msg.getScheduleddateandtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.stats.MeditationScheduled}
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationScheduled.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.stats.MeditationScheduled;
  return proto.unifiedplatform.heartIntuneProfile.stats.MeditationScheduled.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.stats.MeditationScheduled} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.stats.MeditationScheduled}
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationScheduled.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAssociatedgoalid(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setScheduleddateandtime(value);
      break;
    case 4:
      var value = /** @type {!proto.unifiedplatform.heartIntuneProfile.stats.CurrentStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationScheduled.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.stats.MeditationScheduled.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.stats.MeditationScheduled} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationScheduled.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAssociatedgoalid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getScheduleddateandtime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationScheduled.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.stats.MeditationScheduled} returns this
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationScheduled.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 associatedGoalID = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationScheduled.prototype.getAssociatedgoalid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.stats.MeditationScheduled} returns this
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationScheduled.prototype.setAssociatedgoalid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp scheduledDateAndTime = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationScheduled.prototype.getScheduleddateandtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.stats.MeditationScheduled} returns this
*/
proto.unifiedplatform.heartIntuneProfile.stats.MeditationScheduled.prototype.setScheduleddateandtime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.stats.MeditationScheduled} returns this
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationScheduled.prototype.clearScheduleddateandtime = function() {
  return this.setScheduleddateandtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationScheduled.prototype.hasScheduleddateandtime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional CurrentStatus status = 4;
 * @return {!proto.unifiedplatform.heartIntuneProfile.stats.CurrentStatus}
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationScheduled.prototype.getStatus = function() {
  return /** @type {!proto.unifiedplatform.heartIntuneProfile.stats.CurrentStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.stats.CurrentStatus} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.stats.MeditationScheduled} returns this
 */
proto.unifiedplatform.heartIntuneProfile.stats.MeditationScheduled.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.stats.StatsUpdateStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.stats.StatsUpdateStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.stats.StatsUpdateStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.stats.StatsUpdateStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    statsupdatedsuccessfully: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.stats.StatsUpdateStatus}
 */
proto.unifiedplatform.heartIntuneProfile.stats.StatsUpdateStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.stats.StatsUpdateStatus;
  return proto.unifiedplatform.heartIntuneProfile.stats.StatsUpdateStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.stats.StatsUpdateStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.stats.StatsUpdateStatus}
 */
proto.unifiedplatform.heartIntuneProfile.stats.StatsUpdateStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatsupdatedsuccessfully(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.stats.StatsUpdateStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.stats.StatsUpdateStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.stats.StatsUpdateStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.stats.StatsUpdateStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatsupdatedsuccessfully();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool statsUpdatedSuccessfully = 1;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.stats.StatsUpdateStatus.prototype.getStatsupdatedsuccessfully = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.stats.StatsUpdateStatus} returns this
 */
proto.unifiedplatform.heartIntuneProfile.stats.StatsUpdateStatus.prototype.setStatsupdatedsuccessfully = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * @enum {number}
 */
proto.unifiedplatform.heartIntuneProfile.stats.CurrentStatus = {
  UNKNOWN_STATUS: 0,
  SCHEDULED: 1,
  ONGOING: 2,
  COMPLETED: 3
};

goog.object.extend(exports, proto.unifiedplatform.heartIntuneProfile.stats);
