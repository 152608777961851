import { grpc } from "@improbable-eng/grpc-web";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/styles";
import {
  DeleteFileRequest,
  ResponseStatus,
} from "api/models/gamification-service/gamification_pb";
import { GuidedMeditationService } from "api/models/guided-meditation-service/guided-meditation_pb_service";
import { GamificationService } from "api/models/gamification-service/gamification_pb_service";
import { Locale } from "api/models/guided-meditation-service/common/common_pb";
import {
  AddPurposeFileDetailsRequest,
  DeletePurposeFileDetailByIdResponse,
  ID,
  PurposeFileDetails,
} from "api/models/guided-meditation-service/guided-meditation_pb";
import {
  GAMIFICATION_SERVICE_HOST,
  GUIDED_MEDITATION_SERVICE_HOST,
} from "api/serviceEndpoints";
import { UploadEventResponse } from "components/UploadManager/UploadListItem";
import { firebaseAuth } from "config/firebase";
import React, { useState, useEffect, useCallback, ReactElement } from "react";
import { Crop } from "react-image-crop";
import { useDispatch, useSelector } from "react-redux";
import { useFileUploadService } from "services/FileUplaodService";
import { dataURLtoFile, getCroppedImg, toUploadFile } from "utils/helpers";
import {
  getAllPurposeFile,
  getPurposeFileDetailsById,
} from "redux/actions/guidedMeditationAction";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import DialogBox from "components/dialogBox/dialog";
import ImageCrop from "components/image-crop/image-crop";
import { ImageOutlined } from "@mui/icons-material";
import PreviewProfileImage from "components/image-preview/profileImagePreview";
import DeleteIcon from "@mui/icons-material/Delete";
import { RootState } from "redux/store/store";

const PREFIX = "PurposeCategoryForm";

const classes = {
  root: `${PREFIX}-root`,
  imgCardHeader: `${PREFIX}-imgCardHeader`,
  progress: `${PREFIX}-progress`,
  formBtn: `${PREFIX}-formBtn`,
  thumbNail: `${PREFIX}-thumbNail`,
  media: `${PREFIX}-media`,
  actionIcon: `${PREFIX}-actionIcon`,
  audioPlayer: `${PREFIX}-audioPlayer`,
  paper: `${PREFIX}-paper`,
  toggle: `${PREFIX}-toggle`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    height: 250,
    width: "100%",
  },

  [`& .${classes.imgCardHeader}`]: {
    height: "20%",
    textOverflow: "elipse",
  },

  [`& .${classes.progress}`]: {
    color: theme.palette.primary.main,
    position: "absolute",
    left: "50%",
  },

  [`& .${classes.formBtn}`]: {
    marginRight: "20px",
  },

  [`& .${classes.thumbNail}`]: {
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignContent: "cenrter",
    alignItems: "center",
    display: "block",
  },

  [`& .${classes.media}`]: {
    height: "60%",
  },

  [`& .${classes.actionIcon}`]: {
    alignSelf: "center",
    left: "45%",
  },

  [`& .${classes.audioPlayer}`]: {
    height: 250,
    width: "100%",
  },

  [`& .${classes.paper}`]: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    top: `40%`,
    left: `40%`,
  },

  [`& .${classes.toggle}`]: {
    "&.MuiToggleButton-root": {
      padding: "15px 11px",
    },
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
  },
}));

interface State {
  editing?: boolean;
  editItem?: any;
  showDeleteModal?: boolean;
  deleteItemId?: number;
}

interface purposeProps {
  id: any;
  purpose: any;
  imageurl: any;
  withoutborderimageurl: any;
}

interface FormProps {
  isEditing?: boolean;
  editItem?: any;
  onClickBack: any;
  lang:
    | 0
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | 13
    | 14
    | 15
    | 16
    | 17
    | 18
    | 19
    | 20
    | 21;
}

export const PurposeCategoryForm: any = React.forwardRef<
  HTMLDivElement,
  FormProps
>((props, ref): ReactElement => {
  const dispatch = useDispatch();
  const languages = useSelector((state: RootState) => state.app.languages);
  const purposeFile = useSelector(
    (state: RootState) => state.guidedMeditation.meditationList
  );
  const purposeFileData = purposeFile.purposeFile;
  const { appendFilesToUploadManager } = useFileUploadService();

  let cropDef: Crop = {
    x: 25,
    y: 25,
    height: 20,
    width: 20,
    unit: "%",
  };

  const initialState = {
    editing: false,
    editItem: null,
    showDeleteModal: false,
    deleteItemId: 0,
  };

  const hiddenFileInput: any = React.useRef(null);
  const hiddenFileInput2: any = React.useRef(null);
  const [language, setLanguage] = useState<
    | 0
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | 13
    | 14
    | 15
    | 16
    | 17
    | 18
    | 19
    | 20
    | 21
  >(props.lang);
  const [states, setStates] = useState<State>(initialState);
  const [id, setId] = useState<any>(null);
  const [purpose, setPurpose] = useState("");

  const [imageURL, setImageURL] = useState<string>("");
  const [imageRef, setImageRef] = useState<any>();
  const [src, setSrc] = useState<any>(null);
  const [crop, setCrop] = useState<any>(cropDef);
  const [imageFile, setImageFile] = useState<any>(null);
  const [croppedImage, setCroppedImage] = useState<any>(null);

  const [withoutBorderImageURL, setWithoutBorderImageURL] =
    useState<string>("");
  const [withoutBorderImageRef, setWithoutBorderImageRef] = useState<any>();
  const [withoutBorderImageSrc, setWithoutBorderSrc] = useState<any>(null);
  const [withoutBorderImageCrop, setWithoutBorderImageCrop] =
    useState<any>(cropDef);
  const [withoutBorderImageFile, setWithoutBorderImageFile] =
    useState<any>(null);
  const [withoutBorderCroppedImage, setWithoutBorderCroppedImage] =
    useState<any>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [open, setopen] = useState(false);
  const [defaultPurpose, setDefaultPurpose] = useState<string>("");
  const [isAdding, setIsAdding] = useState(false);

  const [isUploading, setIsUploading] = useState({
    image: false,
    selectedImage: false,
    withoutBorderImage: false,
  });

  const [snackBar, setSnackBar] = useState<any>({
    open: false,
    severity: "success",
    message: "",
  });

  const onClickEdit = useCallback(
    (item: purposeProps) => {
      console.log("Edit badge details: ", item);
      dispatch(getPurposeFileDetailsById(props.editItem.id));
      // setDefaultPurpose(purposeFile.purpose.defaultcontent);
      setStates({ editing: true });
      setPurpose(item.purpose);
      setImageURL(item.imageurl);
      setWithoutBorderImageURL(item.withoutborderimageurl);
      setId(item.id);
      setLanguage(props.lang);
    },
    [props.lang]
  );

  useEffect(() => {
    if (props.isEditing) {
      onClickEdit(props.editItem);
    }
  }, [props.isEditing, props.editItem, onClickEdit]);

  useEffect(() => {}, [props.editItem]);

  const _deleteFileFromGcp = async (data: any) => {
    try {
      console.log("image url to delete from gcp", data);
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: DeleteFileRequest = new DeleteFileRequest();
      reqBody.setImgpath(data);
      grpc.unary(GamificationService.DeleteFileFromGCP, {
        host: GAMIFICATION_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<ResponseStatus>) => {
          const { status, message, statusMessage } = res;
          console.log("statusMessage", statusMessage);
          if (status === grpc.Code.OK && message) {
            if (message) {
              console.log(
                "data coming from delete file from gcp",
                message.toObject()
              );
            }
          } else {
            setIsLoading(false);
          }
        },
      });
    } catch (err) {
      console.log("Error ", err);
    }
  };

  const _onOpenImage = () => {
    setopen(true);
  };
  const _onCloseImage = () => {
    setSrc("");
    setopen(false);
    setCrop(cropDef);
    setIsUploading({
      ...isUploading,
      image: false,
      selectedImage: false,
      withoutBorderImage: false,
    });
  };

  const _onCloseImageWithoutBorder = () => {
    setWithoutBorderSrc("");
    setopen(false);
    setWithoutBorderImageCrop(cropDef);
    setIsUploading({
      ...isUploading,
      image: false,
      selectedImage: false,
      withoutBorderImage: false,
    });
  };

  // --------------------------image upload--------------------------------------->
  const handleUploadClick = () => {
    hiddenFileInput.current.click();
  };
  const onSelectImage = (event: any) => {
    if (event.target.files[0]) {
      setIsUploading({ ...isUploading, image: true });
      setImageFile(event.target.files[0]);
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = () => {
        setSrc(reader.result);
        setopen(true);
      };
    }
  };
  const onImageLoaded = (image: any) => {
    setImageRef(image);
  };

  const onCropDone = async () => {
    const cr: any = crop;
    if (imageRef && cr.width && cr.height) {
      const croppedUrl: any = await getCroppedImg(imageRef, cr);
      const croppedFile: File = dataURLtoFile(
        croppedUrl,
        `${new Date().getTime().toString()}_${imageFile.name.replaceAll(
          " ",
          ""
        )}`
      );
      setImageURL(croppedUrl);
      const uploadFile = toUploadFile(
        croppedFile,
        `${
          language === 1
            ? "purposes/category-images"
            : `purposes/category-images/${languages[language - 1].displayName
                .toLowerCase()
                .replaceAll(" ", "")}`
        }`
      );
      appendFilesToUploadManager([uploadFile]);
      window.addEventListener(
        uploadFile.token,
        (res: CustomEventInit<UploadEventResponse>) => {
          if (res.detail?.code === 200) {
            setImageURL(res.detail.response);
            console.log("image url res", res.detail.response);
            setIsUploading({ ...isUploading, image: false });
          } else {
            setIsUploading({ ...isUploading, image: false });
          }
        }
      );
      setCroppedImage(croppedFile);
    }
    setSrc(null);
  };

  const onCropChange = (crop: any) => {
    setCrop(crop);
  };
  const onCropComplete = async () => {};

  const onImageClose = () => {
    setSrc(null);
    setImageFile(null);
    setCroppedImage(null);
    setCrop(cropDef);
    _deleteFileFromGcp(imageURL);
    setImageURL("");
  };

  const handleWithoutBorderImageUploadClick = () => {
    hiddenFileInput2.current.click();
  };
  const onWithoutBorderImageSelectImage = (event: any) => {
    if (event.target.files[0]) {
      setIsUploading({ ...isUploading, withoutBorderImage: true });
      setWithoutBorderImageFile(event.target.files[0]);
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = () => {
        setWithoutBorderSrc(reader.result);
        setopen(true);
      };
    }
  };
  const onWithoutBorderImageLoaded = (image: any) => {
    setWithoutBorderImageRef(image);
  };

  const onWithoutBorderImageCropDone = async () => {
    const cr: any = withoutBorderImageCrop;
    if (withoutBorderImageRef && cr.width && cr.height) {
      const croppedUrl: any = await getCroppedImg(withoutBorderImageRef, cr);
      const croppedFile: File = dataURLtoFile(
        croppedUrl,
        `${new Date()
          .getTime()
          .toString()}_${withoutBorderImageFile.name.replaceAll(" ", "")}`
      );
      setWithoutBorderImageURL(croppedUrl);
      const uploadFile = toUploadFile(
        croppedFile,
        `${
          language === 1
            ? "purposes/category-images/without-border-image"
            : `purposes/category-images/without-border-image/${languages[
                language - 1
              ].displayName
                .toLowerCase()
                .replaceAll(" ", "")}`
        }`
      );
      appendFilesToUploadManager([uploadFile]);
      window.addEventListener(
        uploadFile.token,
        (res: CustomEventInit<UploadEventResponse>) => {
          if (res.detail?.code === 200) {
            setWithoutBorderImageURL(res.detail.response);
            console.log("image url res", res.detail.response);
            setIsUploading({ ...isUploading, withoutBorderImage: false });
          } else {
            setIsUploading({ ...isUploading, withoutBorderImage: false });
          }
        }
      );
      setWithoutBorderCroppedImage(croppedFile);
    }
    setWithoutBorderSrc(null);
  };

  const onWithoutBorderImageCropChange = (crop: any) => {
    setWithoutBorderImageCrop(crop);
  };
  const onWithoutBorderImageCropComplete = async () => {};

  const onWithoutBorderImageClose = () => {
    setWithoutBorderSrc(null);
    setWithoutBorderImageFile(null);
    setWithoutBorderCroppedImage(null);
    setWithoutBorderImageCrop(cropDef);
    _deleteFileFromGcp(withoutBorderImageURL);
    setWithoutBorderImageURL("");
  };

  const validateForm = () => {
    if (purpose.trim() === "") {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Purpose can not be empty",
      });
    } else if (imageURL === "") {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Image can not be empty",
      });
    } else if (withoutBorderImageURL === "") {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Image can not be empty",
      });
    } else {
      return true;
    }
  };

  const emptyData = () => {
    setPurpose("");
    setImageURL("");
    setWithoutBorderImageURL("");
  };

  const decideLocale = () => {
    switch (language) {
      case 1:
        return {
          reqPurpose: {
            defaultContent: purpose,
            en: purpose,
            hi: "",
            te: "",
            gu: "",
            ta: "",
            ka: "",
            mr: "",
            it: "",
            ru: "",
            es: "",
            fr: "",
            pt: "",
            de: "",
            bn: "",
            ml: "",
            uk: "",
            od: "",
            enUs: "",
            enAu: "",
pa: "",
          },
        };
      case 2:
        return {
          reqPurpose: {
            defaultContent: purpose,
            en: purpose,
            hi: "",
            te: "",
            gu: "",
            ta: "",
            ka: "",
            mr: "",
            it: "",
            ru: "",
            es: "",
            fr: "",
            pt: "",
            de: "",
            bn: "",
            ml: "",
            uk: "",
            od: "",
            enUs: "",
            enAu: "",
pa: "",
          },
        };
      case 3:
        return {
          reqPurpose: {
            defaultContent: purposeFileData.purpose.defaultcontent,
            en: "",
            hi: purpose,
            te: "",
            gu: "",
            ta: "",
            ka: "",
            mr: "",
            it: "",
            ru: "",
            es: "",
            fr: "",
            pt: "",
            de: "",
            bn: "",
            ml: "",
            uk: "",
            od: "",
            enUs: "",
            enAu: "",
pa: "",
          },
        };
      case 4:
        return {
          reqPurpose: {
            defaultContent: purposeFileData.purpose.defaultcontent,
            en: "",
            hi: "",
            te: purpose,
            gu: "",
            ta: "",
            ka: "",
            mr: "",
            it: "",
            ru: "",
            es: "",
            fr: "",
            pt: "",
            de: "",
            bn: "",
            ml: "",
            uk: "",
            od: "",
            enUs: "",
            enAu: "",
pa: "",
          },
        };
      case 5:
        return {
          reqPurpose: {
            defaultContent: purposeFileData.purpose.defaultcontent,
            en: "",
            hi: "",
            te: "",
            gu: purpose,
            ta: "",
            ka: "",
            mr: "",
            it: "",
            ru: "",
            es: "",
            fr: "",
            pt: "",
            de: "",
            bn: "",
            ml: "",
            uk: "",
            od: "",
            enUs: "",
            enAu: "",
pa: "",
          },
        };
      case 6:
        return {
          reqPurpose: {
            defaultContent: purposeFileData.purpose.defaultcontent,
            en: "",
            hi: "",
            te: "",
            gu: "",
            ta: purpose,
            ka: "",
            mr: "",
            it: "",
            ru: "",
            es: "",
            fr: "",
            pt: "",
            de: "",
            bn: "",
            ml: "",
            uk: "",
            od: "",
            enUs: "",
            enAu: "",
pa: "",
          },
        };
      case 7:
        return {
          reqPurpose: {
            defaultContent: purposeFileData.purpose.defaultcontent,
            en: "",
            hi: "",
            te: "",
            gu: "",
            ta: "",
            ka: purpose,
            mr: "",
            it: "",
            ru: "",
            es: "",
            fr: "",
            pt: "",
            de: "",
            bn: "",
            ml: "",
            uk: "",
            od: "",
            enUs: "",
            enAu: "",
pa: "",
          },
        };
      case 8:
        return {
          reqPurpose: {
            defaultContent: purposeFileData.purpose.defaultcontent,
            en: "",
            hi: "",
            te: "",
            gu: "",
            ta: "",
            ka: "",
            mr: purpose,
            it: "",
            ru: "",
            es: "",
            fr: "",
            pt: "",
            de: "",
            bn: "",
            ml: "",
            uk: "",
            od: "",
            enUs: "",
            enAu: "",
pa: "",
          },
        };
      case 9:
        return {
          reqPurpose: {
            defaultContent: purposeFileData.purpose.defaultcontent,
            en: "",
            hi: "",
            te: "",
            gu: "",
            ta: "",
            ka: "",
            mr: "",
            it: purpose,
            ru: "",
            es: "",
            fr: "",
            pt: "",
            de: "",
            bn: "",
            ml: "",
            uk: "",
            od: "",
            enUs: "",
            enAu: "",
pa: "",
          },
        };
      case 10:
        return {
          reqPurpose: {
            defaultContent: purposeFileData.purpose.defaultcontent,
            en: "",
            hi: "",
            te: "",
            gu: "",
            ta: "",
            ka: "",
            mr: "",
            it: "",
            ru: purpose,
            es: "",
            fr: "",
            pt: "",
            de: "",
            bn: "",
            ml: "",
            uk: "",
            od: "",
            enUs: "",
            enAu: "",
pa: "",
          },
        };
      case 11:
        return {
          reqPurpose: {
            defaultContent: purposeFileData.purpose.defaultcontent,
            en: "",
            hi: "",
            te: "",
            gu: "",
            ta: "",
            ka: "",
            mr: "",
            it: "",
            ru: "",
            es: purpose,
            fr: "",
            pt: "",
            de: "",
            bn: "",
            ml: "",
            uk: "",
            od: "",
            enUs: "",
            enAu: "",
pa: "",
          },
        };
      case 12:
        return {
          reqPurpose: {
            defaultContent: purposeFileData.purpose.defaultcontent,
            en: "",
            hi: "",
            te: "",
            gu: "",
            ta: "",
            ka: "",
            mr: "",
            it: "",
            ru: "",
            es: "",
            fr: purpose,
            pt: "",
            de: "",
            bn: "",
            ml: "",
            uk: "",
            od: "",
            enUs: "",
            enAu: "",
pa: "",
          },
        };
      case 13:
        return {
          reqPurpose: {
            defaultContent: purposeFileData.purpose.defaultcontent,
            en: "",
            hi: "",
            te: "",
            gu: "",
            ta: "",
            ka: "",
            mr: "",
            it: "",
            ru: "",
            es: "",
            fr: "",
            pt: purpose,
            de: "",
            bn: "",
            ml: "",
            uk: "",
            od: "",
            enUs: "",
            enAu: "",
pa: "",
          },
        };
      case 14:
        return {
          reqPurpose: {
            defaultContent: purposeFileData.purpose.defaultcontent,
            en: "",
            hi: "",
            te: "",
            gu: "",
            ta: "",
            ka: "",
            mr: "",
            it: "",
            ru: "",
            es: "",
            fr: "",
            pt: "",
            de: purpose,
            bn: "",
            ml: "",
            uk: "",
            od: "",
            enUs: "",
            enAu: "",
pa: "",
          },
        };
      case 15:
        return {
          reqPurpose: {
            defaultContent: purposeFileData.purpose.defaultcontent,
            en: "",
            hi: "",
            te: "",
            gu: "",
            ta: "",
            ka: "",
            mr: "",
            it: "",
            ru: "",
            es: "",
            fr: "",
            pt: "",
            de: "",
            bn: purpose,
            ml: "",
            uk: "",
            od: "",
            enUs: "",
            enAu: "",
pa: "",
          },
        };
      case 16:
        return {
          reqPurpose: {
            defaultContent: purposeFileData.purpose.defaultcontent,
            en: "",
            hi: "",
            te: "",
            gu: "",
            ta: "",
            ka: "",
            mr: "",
            it: "",
            ru: "",
            es: "",
            fr: "",
            pt: "",
            de: "",
            bn: "",
            ml: purpose,
            uk: "",
            od: "",
            enUs: "",
            enAu: "",
pa: "",
          },
        };
      case 17:
        return {
          reqPurpose: {
            defaultContent: purposeFileData.purpose.defaultcontent,
            en: "",
            hi: "",
            te: "",
            gu: "",
            ta: "",
            ka: "",
            mr: "",
            it: "",
            ru: "",
            es: "",
            fr: "",
            pt: "",
            de: "",
            bn: "",
            ml: "",
            uk: purpose,
            od: "",
            enUs: "",
            enAu: "",
pa: "",
          },
        };
      case 18:
        return {
          reqPurpose: {
            defaultContent: purposeFileData.purpose.defaultcontent,
            en: "",
            hi: "",
            te: "",
            gu: "",
            ta: "",
            ka: "",
            mr: "",
            it: "",
            ru: "",
            es: "",
            fr: "",
            pt: "",
            de: "",
            bn: "",
            ml: "",
            uk: "",
            od: purpose,
            enUs: "",
            enAu: "",
pa: "",
          },
        };
      case 19:
        return {
          reqPurpose: {
            defaultContent: purposeFileData.purpose.defaultcontent,
            en: "",
            hi: "",
            te: "",
            gu: "",
            ta: "",
            ka: "",
            mr: "",
            it: "",
            ru: "",
            es: "",
            fr: "",
            pt: "",
            de: "",
            bn: "",
            ml: "",
            uk: "",
            od: "",
            enUs: purpose,
            enAu: "",
pa: "",
          },
        };
      case 20:
        return {
          reqPurpose: {
            defaultContent: purposeFileData.purpose.defaultcontent,
            en: "",
            hi: "",
            te: "",
            gu: "",
            ta: "",
            ka: "",
            mr: "",
            it: "",
            ru: "",
            es: "",
            fr: "",
            pt: "",
            de: "",
            bn: "",
            ml: "",
            uk: "",
            od: "",
            enUs: "",
            enAu: purpose,
			pa: "",
          },
        };
		case 21:
        return {
          reqPurpose: {
            defaultContent: purposeFileData.purpose.defaultcontent,
            en: "",
            hi: "",
            te: "",
            gu: "",
            ta: "",
            ka: "",
            mr: "",
            it: "",
            ru: "",
            es: "",
            fr: "",
            pt: "",
            de: "",
            bn: "",
            ml: "",
            uk: "",
            od: "",
            enUs: "",
            enAu: "",
			pa: purpose,
          },
        };
    }
  };

  const addPurpose = async (data: purposeProps) => {
    console.log(`Add purpose request ${data}`);
    try {
      const reqBody: AddPurposeFileDetailsRequest =
        new AddPurposeFileDetailsRequest();
      const purposeLocale: Locale = new Locale();
      purposeLocale.setDefaultcontent(data.purpose.defaultContent);
      purposeLocale.setEn(data.purpose.en);
      purposeLocale.setHi(data.purpose.hi);
      purposeLocale.setTe(data.purpose.te);
      purposeLocale.setGu(data.purpose.gu);
      purposeLocale.setTa(data.purpose.ta);
      purposeLocale.setKa(data.purpose.ka);
      purposeLocale.setMr(data.purpose.mr);
      purposeLocale.setIt(data.purpose.it);
      purposeLocale.setRu(data.purpose.ru);
      purposeLocale.setEs(data.purpose.es);
      purposeLocale.setFr(data.purpose.fr);
      purposeLocale.setPt(data.purpose.pt);
      purposeLocale.setDe(data.purpose.de);
      purposeLocale.setBn(data.purpose.bn);
      purposeLocale.setMl(data.purpose.ml);
      purposeLocale.setUk(data.purpose.uk);
      purposeLocale.setOd(data.purpose.od);
      purposeLocale.setEnUs(data.purpose.enUs);
      purposeLocale.setEnAu(data.purpose.enAu);
      purposeLocale.setPa(data.purpose.pa);

      reqBody.setPurpose(purposeLocale);
      reqBody.setImageurl(data.imageurl);
      reqBody.setWithoutborderimageurl(data.withoutborderimageurl);

      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      console.log("payload", reqBody.toObject());

      grpc.unary(GuidedMeditationService.addPurposeFileDetails, {
        request: reqBody,
        host: GUIDED_MEDITATION_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<PurposeFileDetails>) => {
          const { status, message } = res;
          console.log("Message", message?.toObject());
          if (status === grpc.Code.OK && message) {
            setSnackBar({
              open: true,
              severity: "success",
              message: "Purpose added successfully",
            });
            emptyData();
            setTimeout(() => {
              props.onClickBack();
            }, 2000);
            dispatch(getAllPurposeFile(language));
          } else {
            setSnackBar({
              open: true,
              severity: "error",
              message: "Error in adding purpose",
            });
          }
        },
      });
    } catch (error) {
      setSnackBar({
        open: true,
        severity: "error",
        message: "something went wrong in addpurposefiledetails",
      });
      console.log("Something wrong with purpose data", error);
    }
  };

  const updatePurpose = async (data: purposeProps) => {
    setIsLoading(true);
    try {
      const reqBody: PurposeFileDetails = new PurposeFileDetails();
      reqBody.setId(data.id);
      const purposeLocale: Locale = new Locale();
      purposeLocale.setDefaultcontent(data.purpose.defaultContent);
      purposeLocale.setEn(data.purpose.en);
      purposeLocale.setHi(data.purpose.hi);
      purposeLocale.setTe(data.purpose.te);
      purposeLocale.setGu(data.purpose.gu);
      purposeLocale.setTa(data.purpose.ta);
      purposeLocale.setKa(data.purpose.ka);
      purposeLocale.setMr(data.purpose.mr);
      purposeLocale.setIt(data.purpose.it);
      purposeLocale.setRu(data.purpose.ru);
      purposeLocale.setEs(data.purpose.es);
      purposeLocale.setFr(data.purpose.fr);
      purposeLocale.setPt(data.purpose.pt);
      purposeLocale.setDe(data.purpose.de);
      purposeLocale.setBn(data.purpose.bn);
      purposeLocale.setMl(data.purpose.ml);
      purposeLocale.setUk(data.purpose.uk);
      purposeLocale.setOd(data.purpose.od);
      purposeLocale.setEnUs(data.purpose.enUs);
      purposeLocale.setEnAu(data.purpose.enAu);
      purposeLocale.setPa(data.purpose.pa);

      reqBody.setPurpose(purposeLocale);
      reqBody.setImageurl(data.imageurl);
      reqBody.setWithoutborderimageurl(data.withoutborderimageurl);

      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      console.log("payload", reqBody.toObject());

      grpc.unary(GuidedMeditationService.updatePurposeFileDetails, {
        request: reqBody,
        host: GUIDED_MEDITATION_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<PurposeFileDetails>) => {
          const { status, message } = res;
          console.log("Message", message?.toObject());
          if (status === grpc.Code.OK && message) {
            setIsLoading(false);
            setSnackBar({
              open: true,
              severity: "success",
              message: "Purpose updated successfully",
            });
            emptyData();
            setTimeout(() => {
              props.onClickBack();
            }, 2000);
            dispatch(getAllPurposeFile(language));
          } else {
            setIsLoading(false);
            setSnackBar({
              open: true,
              severity: "error",
              message: "Error in updating purpose",
            });
          }
        },
      });
    } catch (error) {
      setSnackBar({
        open: true,
        severity: "error",
        message: "something went wrong in updatepurposefiledetails",
      });
      console.log("Something wrong with purpose data", error);
    }
  };

  const _updatePurpose = () => {
    if (validateForm()) {
      setIsAdding(true);
      const newPurposeData: any = {
        id: id,
        purpose: decideLocale()?.reqPurpose,
        imageurl: imageURL,
        withoutborderimageurl: withoutBorderImageURL,
      };
      props.isEditing
        ? updatePurpose(newPurposeData)
        : addPurpose(newPurposeData);
    }
  };

  const onClickDelete = (purposeId: number) => {
    setStates({ showDeleteModal: true, deleteItemId: purposeId });
  };

  const handleDeleteClose = () => {
    setStates({ showDeleteModal: false });
  };

  const deletePurpose = async () => {
    deletePurposeById(Number(states.deleteItemId));
  };

  const deletePurposeById = async (purposeId: number) => {
    setIsLoading(true);
    console.log("Delete request for purpose id", purposeId);
    try {
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: ID = new ID();
      reqBody.setId(purposeId);
      grpc.unary(GuidedMeditationService.deletePurposeDetailsByID, {
        host: GUIDED_MEDITATION_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<DeletePurposeFileDetailByIdResponse>) => {
          const { status, message } = res;

          if (status === grpc.Code.OK && message) {
            if (message) {
              setIsLoading(false);
              console.log("data coming", message.toObject().message);
              setSnackBar({
                open: true,
                severity: "success",
                message: "Purpose deleted successfully",
              });

              _deleteFileFromGcp(props.editItem.icon);
              setStates({ showDeleteModal: false });
              setTimeout(() => props.onClickBack(), 2000);
              dispatch(getAllPurposeFile(language));
            }
          } else {
            setIsLoading(false);
            setStates({ showDeleteModal: false });
            setSnackBar({
              open: true,
              severity: "error",
              message: "error in  deleting purpose",
            });
          }
        },
      });
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    <Root ref={ref} className={classes.root}>
      {isLoading && (
        <CircularProgress
          style={{ position: "absolute", top: "50%", right: "50%" }}
        />
      )}
      <Grid
        container
        className="p-2 align-items-center"
        justifyContent="space-between"
      >
        <Grid item>
          <Grid container alignItems="center">
            <Grid>
              <IconButton onClick={props.onClickBack} size="large">
                <KeyboardBackspaceIcon color="action" />
              </IconButton>
            </Grid>
            <Grid>
              <Typography variant="h6">
                {props.isEditing ? "Edit Purpose" : "Add New Purpose"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<DeleteIcon />}
            onClick={() => onClickDelete(props.editItem.id)}
            disabled
          >
            Delete
          </Button>
        </Grid>
      </Grid>
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              type="text"
              label="Purpose Title"
              name="purposeTitle"
              required
              value={purpose}
              variant="outlined"
              onChange={(e) => {
                setPurpose(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="outlined" fullWidth>
              <Card variant="outlined">
                {imageURL ? (
                  <PreviewProfileImage
                    onImageClose={onImageClose}
                    src={imageURL}
                    file={croppedImage}
                  />
                ) : (
                  <>
                    {isUploading.image ? (
                      <Card className={classes.thumbNail}>
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                          className="pt-5"
                        >
                          <Typography className="pt-4">Uploading...</Typography>
                        </Grid>
                      </Card>
                    ) : (
                      <CardActionArea
                        className={classes.root}
                        onClick={() => {
                          handleUploadClick();
                        }}
                      >
                        {/* <InputLabel htmlFor="thumbnail">
                          Upload image
                        </InputLabel> */}
                        <Avatar className={classes.actionIcon} variant="square">
                          <ImageOutlined />
                        </Avatar>
                        <input
                          accept="image/*"
                          id="contained-button-file"
                          type="file"
                          hidden
                          onChange={onSelectImage}
                          ref={hiddenFileInput}
                        />
                      </CardActionArea>
                    )}
                  </>
                )}
              </Card>
              <Typography variant="overline" align="center" gutterBottom>
                Image
              </Typography>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="outlined" fullWidth>
              <Card variant="outlined">
                {withoutBorderImageURL ? (
                  <PreviewProfileImage
                    onImageClose={onWithoutBorderImageClose}
                    src={withoutBorderImageURL}
                    file={withoutBorderCroppedImage}
                  />
                ) : (
                  <>
                    {isUploading.withoutBorderImage ? (
                      <Card className={classes.thumbNail}>
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                          className="pt-5"
                        >
                          <Typography className="pt-4">Uploading...</Typography>
                        </Grid>
                      </Card>
                    ) : (
                      <CardActionArea
                        className={classes.root}
                        onClick={() => {
                          handleWithoutBorderImageUploadClick();
                        }}
                      >
                        {/* <InputLabel htmlFor="thumbnail">
                          Upload image
                        </InputLabel> */}
                        <Avatar className={classes.actionIcon} variant="square">
                          <ImageOutlined />
                        </Avatar>
                        <input
                          accept="image/*"
                          id="contained-button-file"
                          type="file"
                          hidden
                          onChange={onWithoutBorderImageSelectImage}
                          ref={hiddenFileInput2}
                        />
                      </CardActionArea>
                    )}
                  </>
                )}
              </Card>
              <Typography variant="overline" align="center" gutterBottom>
                Without Border Image
              </Typography>
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <Box p={2} display="flex" justifyContent="flex-end">
        <Button
          color="primary"
          variant="contained"
          disabled={isAdding}
          onClick={() => {
            _updatePurpose();
          }}
        >
          {props.isEditing ? "Update Purpose" : "Add Purpose"}
        </Button>
      </Box>
      {src && (
        <ImageCrop
          src={src.toString()}
          crop={crop}
          onImageLoaded={onImageLoaded}
          onCropComplete={onCropComplete}
          onCropChange={onCropChange}
          onPressDone={onCropDone}
          onImageClose={onImageClose}
          open={open}
          onOpen={_onOpenImage}
          onClose={_onCloseImage}
        />
      )}
      {withoutBorderImageSrc && (
        <ImageCrop
          src={withoutBorderImageSrc.toString()}
          crop={withoutBorderImageCrop}
          onImageLoaded={onWithoutBorderImageLoaded}
          onCropComplete={onWithoutBorderImageCropComplete}
          onCropChange={onWithoutBorderImageCropChange}
          onPressDone={onWithoutBorderImageCropDone}
          onImageClose={onWithoutBorderImageClose}
          open={open}
          onOpen={_onOpenImage}
          onClose={_onCloseImageWithoutBorder}
        />
      )}
      <DialogBox
        open={states.showDeleteModal || false}
        onClose={handleDeleteClose}
        onCancel={handleDeleteClose}
        onConfirm={deletePurpose}
        type={"delete"}
        title={`Sure !! Want to delete this Item ?`}
      />
      <Snackbar
        open={snackBar.open}
        autoHideDuration={10000}
        onClose={() => {
          setSnackBar({ ...snackBar, open: false });
        }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Alert
          severity={snackBar.severity}
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
        >
          {snackBar.message}
        </Alert>
      </Snackbar>
    </Root>
  );
});
