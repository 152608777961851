import {
  Card,
  CardActionArea,
  Button,
  Avatar,
  Box,
  CardContent,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  TextField,
  Typography,
  IconButton,
  Snackbar,
  CircularProgress,
  Select,
  MenuItem,
  OutlinedInput,
  Checkbox,
  ListItemText,
  TablePagination,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ImageOutlined, MusicNoteOutlined } from "@mui/icons-material";
import React, { ReactElement, useEffect, useState, useCallback } from "react";
import { Alert } from "@mui/material";
//import AudioPlayer from "components/audio-player/audio-player";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store/store";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { dataURLtoFile, getCroppedImg, toUploadFile } from "utils/helpers";
import { useFileUploadService } from "services/FileUplaodService";
import { UploadEventResponse } from "components/UploadManager/UploadListItem";
import ImageCrop from "components/image-crop/image-crop";
import PreviewProfileImage from "../../../../components/image-preview/profileImagePreview";
import {
  ID,
  MeditationFileDetailsV2,
  DeleteMeditationFileDetailsByIdResponse,
  AddDurationFileRequest,
} from "api/models/guided-meditation-service/guided-meditation_pb";
import { firebaseAuth } from "config/firebase";
import { GuidedMeditationService } from "api/models/guided-meditation-service/guided-meditation_pb_service";
import { GUIDED_MEDITATION_SERVICE_HOST } from "api/serviceEndpoints";
import { grpc } from "@improbable-eng/grpc-web";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import DeleteIcon from "@mui/icons-material/Delete";
import DialogBox from "components/dialogBox/dialog";
import {
  getAllMeditationFileDetailsV2,
  getAllPurposeFile,
} from "redux/actions/guidedMeditationAction";
import { Locale } from "api/models/guided-meditation-service/common/common_pb";
import AudioPlayer from "components/audio-player/audio-player";
import {
  DeleteFileRequest,
  ResponseStatus,
} from "api/models/gamification-service/gamification_pb";
import { Tag } from "api/models/guided-meditation-service/guided-meditation_pb";
import { GamificationService } from "api/models/gamification-service/gamification_pb_service";
import { GAMIFICATION_SERVICE_HOST } from "api/serviceEndpoints";
import { Crop } from "react-image-crop";
import { getAllMeditationTags } from "redux/actions/tagActions";
import { meditationCategories } from "constants/meditation";

const PREFIX = "UpdateMeditationWithPurpose";

const classes = {
  root: `${PREFIX}-root`,
  imgCardHeader: `${PREFIX}-imgCardHeader`,
  progress: `${PREFIX}-progress`,
  formBtn: `${PREFIX}-formBtn`,
  thumbNail: `${PREFIX}-thumbNail`,
  media: `${PREFIX}-media`,
  actionIcon: `${PREFIX}-actionIcon`,
  audioPlayer: `${PREFIX}-audioPlayer`,
  paper: `${PREFIX}-paper`,
  toggle: `${PREFIX}-toggle`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    height: 250,
    width: "100%",
    //overflow: "scroll",
  },

  [`& .${classes.imgCardHeader}`]: {
    height: "20%",
    textOverflow: "elipse",
  },

  [`& .${classes.progress}`]: {
    color: theme.palette.primary.main,
    position: "absolute",
    left: "50%",
  },

  [`& .${classes.formBtn}`]: {
    marginRight: "20px",
  },

  [`& .${classes.thumbNail}`]: {
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignContent: "cenrter",
    alignItems: "center",
  },

  [`& .${classes.media}`]: {
    height: "60%",
  },

  [`& .${classes.actionIcon}`]: {
    alignSelf: "center",
    left: "45%",
  },

  [`& .${classes.audioPlayer}`]: {
    height: "100%",
  },

  [`& .${classes.paper}`]: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    top: `40%`,
    left: `40%`,
  },

  [`& .${classes.toggle}`]: {
    "&.MuiToggleButton-root": {
      padding: "15px 11px",
    },
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
  },
}));

interface FormProps {
  isEditing?: boolean;
  editItem?: any;
  medType?: any;
  onClickBack?: any;
  pageSize?: any;
  pageNumber?: any;
  language?: any;
}
interface GuidedMeditation {
  id?: any;
  durationid?: any;
  meditationtype?: 0 | 1 | 2 | 3 | 4;
  title?: any;
  subtitle?: any;
  description?: any;
  durationofmeditationclip?: number;
  durationofinstructionclip?: number;
  tag?: any;
  rating?: number;
  likes?: number;
  imageurl?: any;
  audiourl?: any;
  durationfile?: any;
  purposefileid?: any;
  thumbnailimgurl: any;
  backgroundimageurl: any;
}
interface State {
  editing?: boolean;
  editItem?: any;
  showDeleteModal?: boolean;
  deleteItemId?: number | null;
}
export const UpdateMeditationWithGuidance = React.forwardRef<any, FormProps>(
  (props, ref): ReactElement => {
    const dispatch = useDispatch();
    const languages = useSelector((state: RootState) => state.app.languages);
    const tagsList = useSelector((state: RootState) => state.tags.tagList);
    const hiddenFileInput: any = React.useRef(null);
    const hiddenSelectedImageInput: any = React.useRef(null);
    const hiddenBackgroundImageInput: any = React.useRef(null);
    const hiddenAudioInput: any = React.useRef(null);

    const initialDeleteState = {
      showDeleteModal: false,
      deleteItemId: 0,
    };

    const cropDef: Crop = {
      x: 25,
      y: 25,
      height: 20,
      width: 20,
      unit: "%",
    };

    const [meditationId, setmeditationId] = useState<number>(0);
    const [title, setTitle] = useState<any>("");
    const [subTitle, setSubTitle] = useState<any>("");
    const [description, setDescription] = useState<string>("");
    const [duration, setDuration] = useState<number | string>(0);
    const [rating, setrating] = useState<any>("");
    // const [likes, setlikes] = useState<string>("");
    const [audioUploadUrl, setAudioUploadUrl] = useState<string>("");
    const [prevAudioUploadUrl, setPrevAudioUploadUrl] = useState<string>("");
    const [instructionDuration, setInstructionDuration] = useState<
      number | string
    >(0);

    const [imageRef, setImageRef] = useState<any>();
    const [src, setSrc] = useState<any>(null);
    const [crop, setCrop] = useState<Crop>(cropDef);
    const [imageFile, setImageFile] = useState<any>(null);
    const [croppedImageUrl, setCroppedImageUrl] = useState<any>(null);

    const [selectedImageRef, setselectedImageRef] = useState<any>();
    const [selectedImagesrc, setselectedImageSrc] = useState<any>(null);
    const [selectedImagecrop, setselectedImageCrop] = useState<Crop>(cropDef);
    const [selectedImageFile, setselectedImageFile] = useState<any>(null);
    const [selectedcroppedImageUrl, setselectedCroppedImageUrl] =
      useState<any>(null);

    const [backgroundImageRef, setBackgroundImageRef] = useState<any>();
    const [backgroundImageSrc, setBackgroundImageSrc] = useState<any>(null);
    const [backgroundImageCrop, setBackgroundImageCrop] =
      useState<Crop>(cropDef);
    const [backgroundImageFile, setBackgroundImageFile] = useState<any>(null);
    const [backgroundImageUrl, setBackgroundImageUrl] = useState<any>(null);

    const [language, setLanguage] = useState<any>(props.language);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [editMeditation, seteditMeditation] = useState<boolean>(false);
    const [selectedTagsId, setSelectedTagsId] = useState<number[]>([]);
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const [page, setPage] = useState<number>(0);
    const [open, setopen] = useState(false);
    const [deleteMeditation, setdeleteMeditation] =
      useState<State>(initialDeleteState);
    const [activityType, setActivityType] = useState<string>("");

    // const [value, setValue] = React.useState<any>(1);
    const [isUploading, setIsUploading] = useState({
      image: false,
      selectedImage: false,
      backgroundImage: false,
      audio: false,
    });
    const [snackBar, setSnackBar] = useState<any>({
      open: false,
      severity: "success",
      message: "",
    });
    const [isAdding, setIsAdding] = useState(false);

    const [isDurationChanged, setIsDurationChanged] = useState(false);
    const [isInstructionDurationChanged, setIsInstructionDurationChanged] =
      useState(false);
    //const [meditationType, SetMeditationType] = useState<0 | 1 | 2 | 3>(0);

    const [meditationCategory, setMeditationCategory] = useState<0 | 1 | 2 | 3>(
      0
    );
    const { appendFilesToUploadManager } = useFileUploadService();

    const onClickEdit = useCallback(
      (item: any) => {
        console.log("item", item);
        switch (language) {
          case 1:
            setTitle(item.title.defaultcontent);
            setSubTitle(item.subtitle.defaultcontent);
            setDescription(item.description.defaultcontent);
            setCroppedImageUrl(item.thumbnailimgurl.defaultcontent);
            setselectedCroppedImageUrl(item.imageurl.defaultcontent);
            setAudioUploadUrl(item.audiourl.defaultcontent);
            setPrevAudioUploadUrl(item.audiourl.defaultcontent);
            setActivityType(item.attribute.defaultcontent);
            break;
          case 2:
            setTitle(item.title.en);
            setSubTitle(item.subtitle.en);
            setDescription(item.description.en);
            setCroppedImageUrl(item.thumbnailimgurl.en);
            setselectedCroppedImageUrl(item.imageurl.en);
            setAudioUploadUrl(item.audiourl.en);
            setPrevAudioUploadUrl(item.audiourl.en);
            setActivityType(item.attribute.en);
            break;
          case 3:
            setTitle(item.title.hi);
            setSubTitle(item.subtitle.hi);
            setDescription(item.description.hi);
            setCroppedImageUrl(item.thumbnailimgurl.hi);
            setselectedCroppedImageUrl(item.imageurl.hi);
            setAudioUploadUrl(item.audiourl.hi);
            setPrevAudioUploadUrl(item.audiourl.hi);
            setActivityType(item.attribute.hi);
            break;
          case 4:
            setTitle(item.title.te);
            setSubTitle(item.subtitle.te);
            setDescription(item.description.te);
            setCroppedImageUrl(item.thumbnailimgurl.te);
            setselectedCroppedImageUrl(item.imageurl.te);
            setAudioUploadUrl(item.audiourl.te);
            setPrevAudioUploadUrl(item.audiourl.te);
            setActivityType(item.attribute.te);
            break;
          case 5:
            setTitle(item.title.gu);
            setSubTitle(item.subtitle.gu);
            setDescription(item.description.gu);
            setCroppedImageUrl(item.thumbnailimgurl.gu);
            setselectedCroppedImageUrl(item.imageurl.gu);
            setAudioUploadUrl(item.audiourl.gu);
            setPrevAudioUploadUrl(item.audiourl.gu);
            setActivityType(item.attribute.gu);
            break;
          case 6:
            setTitle(item.title.ta);
            setSubTitle(item.subtitle.ta);
            setDescription(item.description.ta);
            setCroppedImageUrl(item.thumbnailimgurl.ta);
            setselectedCroppedImageUrl(item.imageurl.ta);
            setAudioUploadUrl(item.audiourl.ta);
            setPrevAudioUploadUrl(item.audiourl.ta);
            setActivityType(item.attribute.ta);
            break;
          case 7:
            setTitle(item.title.ka);
            setSubTitle(item.subtitle.ka);
            setDescription(item.description.ka);
            setCroppedImageUrl(item.thumbnailimgurl.ka);
            setselectedCroppedImageUrl(item.imageurl.ka);
            setAudioUploadUrl(item.audiourl.ka);
            setPrevAudioUploadUrl(item.audiourl.ka);
            setActivityType(item.attribute.ka);
            break;
          case 8:
            setTitle(item.title.mr);
            setSubTitle(item.subtitle.mr);
            setDescription(item.description.mr);
            setCroppedImageUrl(item.thumbnailimgurl.mr);
            setselectedCroppedImageUrl(item.imageurl.mr);
            setAudioUploadUrl(item.audiourl.mr);
            setPrevAudioUploadUrl(item.audiourl.mr);
            setActivityType(item.attribute.mr);

            break;
          case 9:
            setTitle(item.title.it);
            setSubTitle(item.subtitle.it);
            setDescription(item.description.it);
            setCroppedImageUrl(item.thumbnailimgurl.it);
            setselectedCroppedImageUrl(item.imageurl.it);
            setAudioUploadUrl(item.audiourl.it);
            setPrevAudioUploadUrl(item.audiourl.it);
            setActivityType(item.attribute.it);

            break;
          case 10:
            setTitle(item.title.ru);
            setSubTitle(item.subtitle.ru);
            setDescription(item.description.ru);
            setCroppedImageUrl(item.thumbnailimgurl.ru);
            setselectedCroppedImageUrl(item.imageurl.ru);
            setAudioUploadUrl(item.audiourl.ru);
            setPrevAudioUploadUrl(item.audiourl.ru);
            setActivityType(item.attribute.ru);

            break;
          case 11:
            setTitle(item.title.es);
            setSubTitle(item.subtitle.es);
            setDescription(item.description.es);
            setCroppedImageUrl(item.thumbnailimgurl.es);
            setselectedCroppedImageUrl(item.imageurl.es);
            setAudioUploadUrl(item.audiourl.es);
            setPrevAudioUploadUrl(item.audiourl.es);
            setActivityType(item.attribute.es);

            break;
          case 12:
            setTitle(item.title.fr);
            setSubTitle(item.subtitle.fr);
            setDescription(item.description.fr);
            setCroppedImageUrl(item.thumbnailimgurl.fr);
            setselectedCroppedImageUrl(item.imageurl.fr);
            setAudioUploadUrl(item.audiourl.fr);
            setPrevAudioUploadUrl(item.audiourl.fr);
            setActivityType(item.attribute.fr);

            break;
          case 13:
            setTitle(item.title.pt);
            setSubTitle(item.subtitle.pt);
            setDescription(item.description.pt);
            setCroppedImageUrl(item.thumbnailimgurl.pt);
            setselectedCroppedImageUrl(item.imageurl.pt);
            setAudioUploadUrl(item.audiourl.pt);
            setPrevAudioUploadUrl(item.audiourl.pt);
            setActivityType(item.attribute.pt);
            break;
          case 14:
            setTitle(item.title.de);
            setSubTitle(item.subtitle.de);
            setDescription(item.description.de);
            setCroppedImageUrl(item.thumbnailimgurl.de);
            setselectedCroppedImageUrl(item.imageurl.de);
            setAudioUploadUrl(item.audiourl.de);
            setPrevAudioUploadUrl(item.audiourl.de);
            setActivityType(item.attribute.de);
            break;
          case 15:
            setTitle(item.title.bn);
            setSubTitle(item.subtitle.bn);
            setDescription(item.description.bn);
            setCroppedImageUrl(item.thumbnailimgurl.bn);
            setselectedCroppedImageUrl(item.imageurl.bn);
            setAudioUploadUrl(item.audiourl.bn);
            setPrevAudioUploadUrl(item.audiourl.bn);
            setActivityType(item.attribute.bn);
            break;
          case 16:
            setTitle(item.title.ml);
            setSubTitle(item.subtitle.ml);
            setDescription(item.description.ml);
            setCroppedImageUrl(item.thumbnailimgurl.ml);
            setselectedCroppedImageUrl(item.imageurl.ml);
            setAudioUploadUrl(item.audiourl.ml);
            setPrevAudioUploadUrl(item.audiourl.ml);
            setActivityType(item.attribute.ml);
            break;
          case 17:
            setTitle(item.title.uk);
            setSubTitle(item.subtitle.uk);
            setDescription(item.description.uk);
            setCroppedImageUrl(item.thumbnailimgurl.uk);
            setselectedCroppedImageUrl(item.imageurl.uk);
            setAudioUploadUrl(item.audiourl.uk);
            setPrevAudioUploadUrl(item.audiourl.uk);
            setActivityType(item.attribute.uk);
            break;
          case 18:
            setTitle(item.title.od);
            setSubTitle(item.subtitle.od);
            setDescription(item.description.od);
            setCroppedImageUrl(item.thumbnailimgurl.od);
            setselectedCroppedImageUrl(item.imageurl.od);
            setAudioUploadUrl(item.audiourl.od);
            setPrevAudioUploadUrl(item.audiourl.od);
            setActivityType(item.attribute.od);
            break;
          case 19:
            setTitle(item.title.enUs);
            setSubTitle(item.subtitle.enUs);
            setDescription(item.description.enUs);
            setCroppedImageUrl(item.thumbnailimgurl.enUs);
            setselectedCroppedImageUrl(item.imageurl.enUs);
            setAudioUploadUrl(item.audiourl.enUs);
            setPrevAudioUploadUrl(item.audiourl.enUs);
            setActivityType(item.attribute.enUs);
            break;
          case 20:
            setTitle(item.title.enAu);
            setSubTitle(item.subtitle.enAu);
            setDescription(item.description.enAu);
            setCroppedImageUrl(item.thumbnailimgurl.enAu);
            setselectedCroppedImageUrl(item.imageurl.enAu);
            setAudioUploadUrl(item.audiourl.enAu);
            setPrevAudioUploadUrl(item.audiourl.enAu);
            setActivityType(item.attribute.enAu);
            break;
            case 21:
              setTitle(item.title.pa);
              setSubTitle(item.subtitle.pa);
              setDescription(item.description.pa);
              setCroppedImageUrl(item.thumbnailimgurl.pa);
              setselectedCroppedImageUrl(item.imageurl.pa);
              setAudioUploadUrl(item.audiourl.pa);
              setPrevAudioUploadUrl(item.audiourl.pa);
              setActivityType(item.attribute.pa);
              break;
        }
        setBackgroundImageUrl(item.backgroundimageurl);
        setmeditationId(item.id);
        setDuration(item.durationofmeditationclip);
        setInstructionDuration(item.durationofinstructionclip);
        setrating(item.rating);
        setSelectedTagsId(item.tagidsList);
        setSelectedTags(item.tagsList.map((item) => item.name));
        setMeditationCategory(item.meditationcategory);
      },
      [language]
    );

    useEffect(() => {
      console.log("EditItem: ", props.editItem);
      if (props.isEditing) {
        onClickEdit(props.editItem);
      }
    }, [props.isEditing, props.editItem, language, onClickEdit]);

    const _onOpenImage = () => {
      setopen(true);
    };
    const _onCloseImage = () => {
      setSrc("");
      setCrop(cropDef);
      setopen(false);
      setIsUploading({
        ...isUploading,
        image: false,
        selectedImage: false,
        backgroundImage: false,
      });
    };

    const _onCloseSelectedImage = () => {
      setselectedImageSrc("");
      setselectedImageCrop(cropDef);
      setopen(false);
      setIsUploading({
        ...isUploading,
        image: false,
        selectedImage: false,
        backgroundImage: false,
      });
    };

    const _onCloseBackgroundImage = () => {
      setBackgroundImageSrc("");
      setBackgroundImageCrop(cropDef);
      setopen(false);
      setIsUploading({
        ...isUploading,
        image: false,
        selectedImage: false,
        backgroundImage: false,
      });
    };
    const onDeleteMeditation = (meditationId: number) => {
      setdeleteMeditation({
        showDeleteModal: true,
        deleteItemId: meditationId,
      });
    };
    const handleDeleteClose = () => {
      setdeleteMeditation({ showDeleteModal: false });
    };

    const _deleteSession = async () => {
      const data = {
        ID: deleteMeditation.deleteItemId,
      };
      _deleteMeditation(data);
    };

    // --------------------------image upload--------------------------------------->
    const handleUploadClick = () => {
      hiddenFileInput.current.click();
    };
    const onSelectImage = (event: any) => {
      if (event.target.files[0]) {
        setIsUploading({ ...isUploading, image: true });
        setImageFile(event.target.files[0]);
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onloadend = () => {
          setSrc(reader.result);
          setopen(true);
        };
      }
    };
    const onImageLoaded = (image: any) => {
      setImageRef(image);
    };

    const onCropDone = async () => {
      setIsUploading({ ...isUploading, image: true });
      const cr: any = crop;
      if (imageRef && cr.width && cr.height) {
        const croppedUrl = await getCroppedImg(imageRef, cr);
        const croppedFile: File = dataURLtoFile(
          croppedUrl,
          `${new Date().getTime().toString()}_${imageFile.name.replaceAll(
            " ",
            ""
          )}`
        );
        //setCroppedImageUrl(croppedUrl);
        const uploadFile = toUploadFile(
          croppedFile,
          `${
            language === 1
              ? "MeditateNow/thumbnails"
              : `MeditateNow/thumbnails/${languages[language - 1].displayName
                  .toLowerCase()
                  .replaceAll(" ", "")}`
          }`
        );
        appendFilesToUploadManager([uploadFile]);
        window.addEventListener(
          uploadFile.token,
          (res: CustomEventInit<UploadEventResponse>) => {
            if (res.detail?.code === 200) {
              setCroppedImageUrl(res.detail.response);
              console.log("image url res", res.detail.response);
              setIsUploading({ ...isUploading, image: false });
            } else {
              setIsUploading({ ...isUploading, image: false });
            }
          }
        );
      }
      setSrc(null);
    };

    const onCropChange = (crop: any) => {
      setCrop(crop);
    };
    const onCropComplete = async () => {
      //console.log("state crop", crop, imageRef);
    };
    const onImageClose = () => {
      setSrc(null);
      setImageFile(null);
      setCroppedImageUrl("");
      setCrop(cropDef);
    };

    // -------------------------- selected image upload--------------------------------------->
    const _handleUploadClick = () => {
      hiddenSelectedImageInput.current.click();
    };
    const _onSelectImage = (event: any) => {
      if (event.target.files[0]) {
        setIsUploading({ ...isUploading, selectedImage: true });
        setselectedImageFile(event.target.files[0]);
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onloadend = () => {
          setselectedImageSrc(reader.result);
          setopen(true);
        };
      }
    };
    const _onImageLoaded = (image: any) => {
      setselectedImageRef(image);
    };

    const _onCropDone = async () => {
      const cr: any = selectedImagecrop;
      if (selectedImageRef && cr.width && cr.height) {
        const croppedUrl = await getCroppedImg(selectedImageRef, cr);
        const croppedFile: File = dataURLtoFile(
          croppedUrl,
          `${new Date()
            .getTime()
            .toString()}_${selectedImageFile.name.replaceAll(" ", "")}`
        );
        setselectedCroppedImageUrl(croppedUrl);
        const uploadFile = toUploadFile(
          croppedFile,
          `${
            language === 1
              ? "MeditateNow/favourite-images"
              : `MeditateNow/favourite-images/${languages[
                  language - 1
                ].displayName
                  .toLowerCase()
                  .replaceAll(" ", "")}`
          }`
        );
        appendFilesToUploadManager([uploadFile]);
        window.addEventListener(
          uploadFile.token,
          (res: CustomEventInit<UploadEventResponse>) => {
            if (res.detail?.code === 200) {
              setselectedCroppedImageUrl(res.detail.response);
              console.log("image url res", res.detail.response);
              setIsUploading({ ...isUploading, selectedImage: false });
            } else {
              setIsUploading({ ...isUploading, selectedImage: false });
            }
          }
        );
      }
      setselectedImageSrc(null);
    };

    const _onCropChange = (crop: any) => {
      setselectedImageCrop(crop);
    };
    const _onCropComplete = async () => {
      //console.log("state crop", crop, imageRef);
    };
    const _onImageClose = () => {
      setselectedImageSrc(null);
      setselectedCroppedImageUrl("");
      setselectedImageFile(null);
      setselectedImageCrop(cropDef);
    };

    //---------------------------background image upload------------------------------------->
    const backgroundHandleUploadClick = () => {
      hiddenBackgroundImageInput.current.click();
      console.log("clicked on image upload");
    };

    const onSelectBackgroundImage = (event: any) => {
      if (event.target.files[0]) {
        setIsUploading({ ...isUploading, backgroundImage: true });
        setBackgroundImageFile(event.target.files[0]);
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onloadend = () => {
          setBackgroundImageSrc(reader.result);
          setopen(true);
        };
      }
    };

    const onBackgroundCropComplete = async () => {
      //console.log("state crop", crop, imageRef);
    };

    const onBackgroundImageLoaded = (image: any) => {
      setBackgroundImageRef(image);
    };

    const onBackgroundCropChange = (crop: any) => {
      setBackgroundImageCrop(crop);
    };

    const onBackgroundCropDone = async () => {
      setIsUploading({ ...isUploading, backgroundImage: true });
      const cr: any = backgroundImageCrop;
      if (backgroundImageRef && cr.width && cr.height) {
        const croppedUrl = await getCroppedImg(backgroundImageRef, cr);
        const croppedFile: File = dataURLtoFile(
          croppedUrl,
          `${new Date()
            .getTime()
            .toString()}_${backgroundImageFile.name.replaceAll(" ", "")}`
        );
        // setselectedCroppedImageUrl(croppedUrl);
        console.log("image", croppedFile);
        const uploadFile = toUploadFile(
          croppedFile,
          `${
            language === 1
              ? "MeditateNow/background-images"
              : `MeditateNow/background-images/${languages[
                  language - 1
                ].displayName
                  .toLowerCase()
                  .replaceAll(" ", "")}`
          }`
        );
        appendFilesToUploadManager([uploadFile]);
        window.addEventListener(
          uploadFile.token,
          (res: CustomEventInit<UploadEventResponse>) => {
            if (res.detail?.code === 200) {
              setBackgroundImageUrl(res.detail.response);
              console.log("image url res", res.detail.response);
              setIsUploading({ ...isUploading, backgroundImage: false });
            } else {
              setIsUploading({ ...isUploading, backgroundImage: false });
            }
          }
        );
      }
      setBackgroundImageSrc(null);
    };

    const onBackgroundImageClose = () => {
      setBackgroundImageSrc(null);
      // _deleteFileFromGcp(backgroundImageUrl);
      setBackgroundImageUrl("");
      setBackgroundImageFile(null);
      setBackgroundImageCrop(cropDef);
    };

    //---------------------------audio upload------------------------------------->

    const handleAudioClick = () => {
      hiddenAudioInput.current.click();
    };

    const onAudioSelection = async (event: any) => {
      const file = event.target.files[0];
      if (file && file.type.startsWith("audio/")) {
        setIsUploading({ ...isUploading, audio: true });
        const data = new File(
          [event.target.files[0]],
          event.target.files[0].name.replaceAll(" ", "")
        );
        const uploadFile = toUploadFile(
          data,
          `${
            language === 1
              ? "MeditateNow/audios"
              : `MeditateNow/audios/${languages[language - 1].displayName
                  .toLowerCase()
                  .replaceAll(" ", "")}`
          }`
        );
        appendFilesToUploadManager([uploadFile]);
        window.addEventListener(
          uploadFile.token,
          (res: CustomEventInit<UploadEventResponse>) => {
            if (res.detail?.code === 200) {
              setAudioUploadUrl(res.detail.response);
              setIsUploading({ ...isUploading, audio: false });
            } else {
              setIsUploading({ ...isUploading, audio: false });
            }
          }
        );
      } else {
        alert("Please select a audio file.");
      }
    };

    const onAudioClose = () => {
      setAudioUploadUrl("");
    };

    //---------------------------audio upload end------------------------------------->

    const handleMultipleTagChange = (event: any) => {
      const {
        target: { value },
      } = event;
      // console.log(value);

      const arrOfNum: number[] = (
        typeof value === "string" ? value.split(",") : value
      ).map((str) => {
        return Number(str);
      });
      const val = selectedTagsId.filter((item) => value.indexOf(item) === -1);
      const tagIndex = selectedTagsId.indexOf(val[0]);

      setSelectedTagsId(arrOfNum);

      if (tagIndex > -1) {
        setSelectedTags(() => {
          const updatedTags = [...selectedTags];
          updatedTags.splice(tagIndex, 1);
          // console.log("updated Tags: ", updatedTags);
          return updatedTags;
        });
      } else if (tagIndex === -1) {
        setSelectedTags(() => {
          const temp = tagsList.tags.tagsList.filter((item) =>
            value.includes(item.id)
          );

          const arrOfString = temp.map((item: any) => item.name);
          return [...new Set([...selectedTags, ...arrOfString])];
        });
      }
    };
    useEffect(() => {
      dispatch(getAllMeditationTags(10, page));
    }, [page]);

    const handleChangePage = (
      event: React.MouseEvent<HTMLButtonElement> | null,
      newPage: number
    ) => {
      setPage(newPage);
    };

    const tagsArray = () => {
      let arr: Tag[] = [];
      for (const tagIndex in selectedTags) {
        const temp = new Tag();
        temp.setId(selectedTagsId[tagIndex]);
        temp.setName(selectedTags[tagIndex]);
        arr.push(temp);
      }
      return arr;
    };

    const validateMeditaion = () => {
      if (title.trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Meditation title can not be empty",
        });
      } else if (+duration === 0) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Duration must be greater than 0",
        });
      } else if (+instructionDuration > +duration) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Instruction clip time must be less than duration",
        });
      } else if (description.trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Description can not be empty",
        });
        // } else if (activityType.trim() === "") {
        //   setSnackBar({
        //     open: true,
        //     severity: "error",
        //     message: "Activity type  can not be empty",
        //   });
      } else if (croppedImageUrl === "" || croppedImageUrl === null) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Thumbnail can not be empty",
        });
      } else if (
        selectedcroppedImageUrl === "" ||
        selectedcroppedImageUrl === null
      ) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Favourite image can not be empty",
        });
      } else if (backgroundImageUrl === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Background image can not be empty",
        });
      } else if (audioUploadUrl === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Audio can not be empty",
        });
      } else if (selectedTagsId.length === 0) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Minimum 1 tag needs to be selected",
        });
      } else if (selectedTagsId.length > 3) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Maximum 3 tags can be selected",
        });
        // } else if (activityType === "") {
        //   setSnackBar({
        //     open: true,
        //     severity: "error",
        //     message: "Activity type can not be empty",
        //   });
      } else {
        return true;
      }
    };

    const localeObject = {
      defaultcontent: "",
      en: "",
      hi: "",
      te: "",
      gu: "",
      ta: "",
      ka: "",
      mr: "",
      it: "",
      ru: "",
      es: "",
      fr: "",
      pt: "",
      de: "",
      bn: "",
      ml: "",
      uk: "",
      od: "",
      enUs: "",
      enAu: "",
      pa: "",
    };

    const _deleteFileFromGcp = async (data: any) => {
      try {
        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        const reqBody: DeleteFileRequest = new DeleteFileRequest();
        reqBody.setImgpath(data);
        grpc.unary(GamificationService.DeleteFileFromGCP, {
          host: GAMIFICATION_SERVICE_HOST,
          request: reqBody,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<ResponseStatus>) => {
            const { status, message, statusMessage } = res;
            console.log("statusMessage", statusMessage);
            if (status === grpc.Code.OK && message) {
              if (message) {
                console.log(
                  "data coming from delete file from gcp",
                  message.toObject()
                );
              }
            } else {
              setIsLoading(false);
            }
          },
        });
      } catch (err) {
        console.log("Error ", err);
      }
    };

    const deleteLocaleFile = (item: any) => {
      if (item.defaultcontent) {
        _deleteFileFromGcp(item.defaultcontent);
      }
      if (item.en) {
        _deleteFileFromGcp(item.en);
      }
      if (item.hi) {
        _deleteFileFromGcp(item.hi);
      }
      if (item.te) {
        _deleteFileFromGcp(item.te);
      }
      if (item.gu) {
        _deleteFileFromGcp(item.gu);
      }
      if (item.ta) {
        _deleteFileFromGcp(item.ta);
      }
      if (item.ka) {
        _deleteFileFromGcp(item.ka);
      }
      if (item.mr) {
        _deleteFileFromGcp(item.mr);
      }
      if (item.it) {
        _deleteFileFromGcp(item.it);
      }
      if (item.ru) {
        _deleteFileFromGcp(item.ru);
      }
      if (item.es) {
        _deleteFileFromGcp(item.es);
      }
      if (item.fr) {
        _deleteFileFromGcp(item.fr);
      }
      if (item.pt) {
        _deleteFileFromGcp(item.pt);
      }
      if (item.de) {
        _deleteFileFromGcp(item.de);
      }
      if (item.bn) {
        _deleteFileFromGcp(item.bn);
      }
      if (item.ml) {
        _deleteFileFromGcp(item.ml);
      }
      if (item.uk) {
        _deleteFileFromGcp(item.uk);
      }
      if (item.od) {
        _deleteFileFromGcp(item.od);
      }
      if (item.enUs) {
        _deleteFileFromGcp(item.enUs);
      }
      if (item.enAu) {
        _deleteFileFromGcp(item.enAu);
      }
      if (item.pa) {
        _deleteFileFromGcp(item.pa);
      }
    };

    const decideLocale = () => {
      //console.log(props.editItem)
      switch (language) {
        case 1:
          return {
            reqTitle: {
              ...localeObject,
              defaultcontent: title,
              en: title,
            },
            reqSubTitle: {
              ...localeObject,
              defaultcontent: subTitle,
              en: subTitle,
            },
            reqDescription: {
              ...localeObject,
              defaultcontent: description,
              en: description,
            },
            reqThumbnail: {
              ...localeObject,
              defaultcontent: croppedImageUrl,
              en: croppedImageUrl,
            },
            reqImageUrl: {
              ...localeObject,
              defaultcontent: selectedcroppedImageUrl,
              en: selectedcroppedImageUrl,
            },
            reqAudioUrl: {
              ...localeObject,
              defaultcontent: audioUploadUrl,
              en: audioUploadUrl,
            },
            reqAttribute: {
              ...localeObject,
              defaultcontent: activityType,
              en: activityType,
            },
          };
        case 2:
          return {
            reqTitle: {
              ...localeObject,
              defaultcontent: title,
              en: title,
            },
            reqSubTitle: {
              ...localeObject,
              defaultcontent: subTitle,
              en: subTitle,
            },
            reqDescription: {
              ...localeObject,
              defaultcontent: description,
              en: description,
            },
            reqThumbnail: {
              ...localeObject,
              defaultcontent: croppedImageUrl,
              en: croppedImageUrl,
            },
            reqImageUrl: {
              ...localeObject,
              defaultcontent: selectedcroppedImageUrl,
              en: selectedcroppedImageUrl,
            },
            reqAudioUrl: {
              ...localeObject,
              defaultcontent: audioUploadUrl,
              en: audioUploadUrl,
            },
            reqAttribute: {
              ...localeObject,
              defaultcontent: activityType,
              en: activityType,
            },
          };
        case 3:
          return {
            reqTitle: {
              ...localeObject,
              defaultcontent: props.editItem.title.defaultcontent,
              hi: title,
            },
            reqSubTitle: {
              ...localeObject,
              defaultcontent: props.editItem.subtitle.defaultcontent,
              hi: subTitle,
            },
            reqDescription: {
              ...localeObject,
              defaultcontent: props.editItem.description.defaultcontent,
              hi: description,
            },
            reqThumbnail: {
              ...localeObject,
              defaultcontent: props.editItem.thumbnailimgurl.defaultcontent,
              hi: croppedImageUrl,
            },
            reqImageUrl: {
              ...localeObject,
              defaultcontent: props.editItem.imageurl.defaultcontent,
              hi: selectedcroppedImageUrl,
            },
            reqAudioUrl: {
              ...localeObject,
              defaultcontent: props.editItem.audiourl.defaultcontent,
              hi: audioUploadUrl,
            },
            reqAttribute: {
              ...localeObject,
              defaultcontent: activityType,
              hi: activityType,
            },
          };
        case 4:
          return {
            reqTitle: {
              ...localeObject,
              defaultcontent: props.editItem.title.defaultcontent,
              te: title,
            },
            reqSubTitle: {
              ...localeObject,
              defaultcontent: props.editItem.subtitle.defaultcontent,
              te: subTitle,
            },
            reqDescription: {
              ...localeObject,
              defaultcontent: props.editItem.description.defaultcontent,
              te: description,
            },
            reqThumbnail: {
              ...localeObject,
              defaultcontent: props.editItem.thumbnailimgurl.defaultcontent,
              te: croppedImageUrl,
            },
            reqImageUrl: {
              ...localeObject,
              defaultcontent: props.editItem.imageurl.defaultcontent,
              te: selectedcroppedImageUrl,
            },
            reqAudioUrl: {
              ...localeObject,
              defaultcontent: props.editItem.audiourl.defaultcontent,
              te: audioUploadUrl,
            },
            reqAttribute: {
              ...localeObject,
              defaultcontent: activityType,
              te: activityType,
            },
          };
        case 5:
          return {
            reqTitle: {
              ...localeObject,
              defaultcontent: props.editItem.title.defaultcontent,
              gu: title,
            },
            reqSubTitle: {
              ...localeObject,
              defaultcontent: props.editItem.subtitle.defaultcontent,
              gu: subTitle,
            },
            reqDescription: {
              ...localeObject,
              defaultcontent: props.editItem.description.defaultcontent,
              gu: description,
            },
            reqThumbnail: {
              ...localeObject,
              defaultcontent: props.editItem.thumbnailimgurl.defaultcontent,
              gu: croppedImageUrl,
            },
            reqImageUrl: {
              ...localeObject,
              defaultcontent: props.editItem.imageurl.defaultcontent,
              gu: selectedcroppedImageUrl,
            },
            reqAudioUrl: {
              ...localeObject,
              defaultcontent: props.editItem.audiourl.defaultcontent,
              gu: audioUploadUrl,
            },
            reqAttribute: {
              ...localeObject,
              defaultcontent: activityType,
              gu: activityType,
            },
          };
        case 6:
          return {
            reqTitle: {
              ...localeObject,
              defaultcontent: props.editItem.title.defaultcontent,
              ta: title,
            },
            reqSubTitle: {
              ...localeObject,
              defaultcontent: props.editItem.subtitle.defaultcontent,
              ta: subTitle,
            },
            reqDescription: {
              ...localeObject,
              defaultcontent: props.editItem.description.defaultcontent,
              ta: description,
            },
            reqThumbnail: {
              ...localeObject,
              defaultcontent: props.editItem.thumbnailimgurl.defaultcontent,
              ta: croppedImageUrl,
            },
            reqImageUrl: {
              ...localeObject,
              defaultcontent: props.editItem.imageurl.defaultcontent,
              ta: selectedcroppedImageUrl,
            },
            reqAudioUrl: {
              ...localeObject,
              defaultcontent: props.editItem.audiourl.defaultcontent,
              ta: audioUploadUrl,
            },
            reqAttribute: {
              ...localeObject,
              defaultcontent: activityType,
              ta: activityType,
            },
          };
        case 7:
          return {
            reqTitle: {
              ...localeObject,
              defaultcontent: props.editItem.title.defaultcontent,
              ka: title,
            },
            reqSubTitle: {
              ...localeObject,
              defaultcontent: props.editItem.subtitle.defaultcontent,
              ka: subTitle,
            },
            reqDescription: {
              ...localeObject,
              defaultcontent: props.editItem.description.defaultcontent,
              ka: description,
            },
            reqThumbnail: {
              ...localeObject,
              defaultcontent: props.editItem.thumbnailimgurl.defaultcontent,
              ka: croppedImageUrl,
            },
            reqImageUrl: {
              ...localeObject,
              defaultcontent: props.editItem.imageurl.defaultcontent,
              ka: selectedcroppedImageUrl,
            },
            reqAudioUrl: {
              ...localeObject,
              defaultcontent: props.editItem.audiourl.defaultcontent,
              ka: audioUploadUrl,
            },
            reqAttribute: {
              ...localeObject,
              defaultcontent: activityType,
              ka: activityType,
            },
          };
        case 8:
          return {
            reqTitle: {
              ...localeObject,
              defaultcontent: props.editItem.title.defaultcontent,
              mr: title,
            },
            reqSubTitle: {
              ...localeObject,
              defaultcontent: props.editItem.subtitle.defaultcontent,
              mr: subTitle,
            },
            reqDescription: {
              ...localeObject,
              defaultcontent: props.editItem.description.defaultcontent,
              mr: description,
            },
            reqThumbnail: {
              ...localeObject,
              defaultcontent: props.editItem.thumbnailimgurl.defaultcontent,
              mr: croppedImageUrl,
            },
            reqImageUrl: {
              ...localeObject,
              defaultcontent: props.editItem.imageurl.defaultcontent,
              mr: selectedcroppedImageUrl,
            },
            reqAudioUrl: {
              ...localeObject,
              defaultcontent: props.editItem.audiourl.defaultcontent,
              mr: audioUploadUrl,
            },
            reqAttribute: {
              ...localeObject,
              defaultcontent: activityType,
              mr: activityType,
            },
          };
        case 9:
          return {
            reqTitle: {
              ...localeObject,
              defaultcontent: props.editItem.title.defaultcontent,
              it: title,
            },
            reqSubTitle: {
              ...localeObject,
              defaultcontent: props.editItem.subtitle.defaultcontent,
              it: subTitle,
            },
            reqDescription: {
              ...localeObject,
              defaultcontent: props.editItem.description.defaultcontent,
              it: description,
            },
            reqThumbnail: {
              ...localeObject,
              defaultcontent: props.editItem.thumbnailimgurl.defaultcontent,
              it: croppedImageUrl,
            },
            reqImageUrl: {
              ...localeObject,
              defaultcontent: props.editItem.imageurl.defaultcontent,
              it: selectedcroppedImageUrl,
            },
            reqAudioUrl: {
              ...localeObject,
              defaultcontent: props.editItem.audiourl.defaultcontent,
              it: audioUploadUrl,
            },
            reqAttribute: {
              ...localeObject,
              defaultcontent: activityType,
              it: activityType,
            },
          };
        case 10:
          return {
            reqTitle: {
              ...localeObject,
              defaultcontent: props.editItem.title.defaultcontent,
              ru: title,
            },
            reqSubTitle: {
              ...localeObject,
              defaultcontent: props.editItem.subtitle.defaultcontent,
              ru: subTitle,
            },
            reqDescription: {
              ...localeObject,
              defaultcontent: props.editItem.description.defaultcontent,
              ru: description,
            },
            reqThumbnail: {
              ...localeObject,
              defaultcontent: props.editItem.thumbnailimgurl.defaultcontent,
              ru: croppedImageUrl,
            },
            reqImageUrl: {
              ...localeObject,
              defaultcontent: props.editItem.imageurl.defaultcontent,
              ru: selectedcroppedImageUrl,
            },
            reqAudioUrl: {
              ...localeObject,
              defaultcontent: props.editItem.audiourl.defaultcontent,
              ru: audioUploadUrl,
            },
            reqAttribute: {
              ...localeObject,
              defaultcontent: activityType,
              ru: activityType,
            },
          };
        case 11:
          return {
            reqTitle: {
              ...localeObject,
              defaultcontent: props.editItem.title.defaultcontent,
              es: title,
            },
            reqSubTitle: {
              ...localeObject,
              defaultcontent: props.editItem.subtitle.defaultcontent,
              es: subTitle,
            },
            reqDescription: {
              ...localeObject,
              defaultcontent: props.editItem.description.defaultcontent,
              es: description,
            },
            reqThumbnail: {
              ...localeObject,
              defaultcontent: props.editItem.thumbnailimgurl.defaultcontent,
              es: croppedImageUrl,
            },
            reqImageUrl: {
              ...localeObject,
              defaultcontent: props.editItem.imageurl.defaultcontent,
              es: selectedcroppedImageUrl,
            },
            reqAudioUrl: {
              ...localeObject,
              defaultcontent: props.editItem.audiourl.defaultcontent,
              es: audioUploadUrl,
            },
            reqAttribute: {
              ...localeObject,
              defaultcontent: activityType,
              es: activityType,
            },
          };
        case 12:
          return {
            reqTitle: {
              ...localeObject,
              defaultcontent: props.editItem.title.defaultcontent,
              fr: title,
            },
            reqSubTitle: {
              ...localeObject,
              defaultcontent: props.editItem.subtitle.defaultcontent,
              fr: subTitle,
            },
            reqDescription: {
              ...localeObject,
              defaultcontent: props.editItem.description.defaultcontent,
              fr: description,
            },
            reqThumbnail: {
              ...localeObject,
              defaultcontent: props.editItem.thumbnailimgurl.defaultcontent,
              fr: croppedImageUrl,
            },
            reqImageUrl: {
              ...localeObject,
              defaultcontent: props.editItem.imageurl.defaultcontent,
              fr: selectedcroppedImageUrl,
            },
            reqAudioUrl: {
              ...localeObject,
              defaultcontent: props.editItem.audiourl.defaultcontent,
              fr: audioUploadUrl,
            },
            reqAttribute: {
              ...localeObject,
              defaultcontent: activityType,
              fr: activityType,
            },
          };
        case 13:
          return {
            reqTitle: {
              ...localeObject,
              defaultcontent: props.editItem.title.defaultcontent,
              pt: title,
            },
            reqSubTitle: {
              ...localeObject,
              defaultcontent: props.editItem.subtitle.defaultcontent,
              pt: subTitle,
            },
            reqDescription: {
              ...localeObject,
              defaultcontent: props.editItem.description.defaultcontent,
              pt: description,
            },
            reqThumbnail: {
              ...localeObject,
              defaultcontent: props.editItem.thumbnailimgurl.defaultcontent,
              pt: croppedImageUrl,
            },
            reqImageUrl: {
              ...localeObject,
              defaultcontent: props.editItem.imageurl.defaultcontent,
              pt: selectedcroppedImageUrl,
            },
            reqAudioUrl: {
              ...localeObject,
              defaultcontent: props.editItem.audiourl.defaultcontent,
              pt: audioUploadUrl,
            },
            reqAttribute: {
              ...localeObject,
              defaultcontent: activityType,
              pt: activityType,
            },
          };
        case 14:
          return {
            reqTitle: {
              ...localeObject,
              defaultcontent: props.editItem.title.defaultcontent,
              de: title,
            },
            reqSubTitle: {
              ...localeObject,
              defaultcontent: props.editItem.subtitle.defaultcontent,
              de: subTitle,
            },
            reqDescription: {
              ...localeObject,
              defaultcontent: props.editItem.description.defaultcontent,
              de: description,
            },
            reqThumbnail: {
              ...localeObject,
              defaultcontent: props.editItem.thumbnailimgurl.defaultcontent,
              de: croppedImageUrl,
            },
            reqImageUrl: {
              ...localeObject,
              defaultcontent: props.editItem.imageurl.defaultcontent,
              de: selectedcroppedImageUrl,
            },
            reqAudioUrl: {
              ...localeObject,
              defaultcontent: props.editItem.audiourl.defaultcontent,
              de: audioUploadUrl,
            },
            reqAttribute: {
              ...localeObject,
              defaultcontent: activityType,
              de: activityType,
            },
          };
        case 15:
          return {
            reqTitle: {
              ...localeObject,
              defaultcontent: props.editItem.title.defaultcontent,
              bn: title,
            },
            reqSubTitle: {
              ...localeObject,
              defaultcontent: props.editItem.subtitle.defaultcontent,
              bn: subTitle,
            },
            reqDescription: {
              ...localeObject,
              defaultcontent: props.editItem.description.defaultcontent,
              bn: description,
            },
            reqThumbnail: {
              ...localeObject,
              defaultcontent: props.editItem.thumbnailimgurl.defaultcontent,
              bn: croppedImageUrl,
            },
            reqImageUrl: {
              ...localeObject,
              defaultcontent: props.editItem.imageurl.defaultcontent,
              bn: selectedcroppedImageUrl,
            },
            reqAudioUrl: {
              ...localeObject,
              defaultcontent: props.editItem.audiourl.defaultcontent,
              bn: audioUploadUrl,
            },
            reqAttribute: {
              ...localeObject,
              defaultcontent: activityType,
              bn: activityType,
            },
          };
        case 16:
          return {
            reqTitle: {
              ...localeObject,
              defaultcontent: props.editItem.title.defaultcontent,
              ml: title,
            },
            reqSubTitle: {
              ...localeObject,
              defaultcontent: props.editItem.subtitle.defaultcontent,
              ml: subTitle,
            },
            reqDescription: {
              ...localeObject,
              defaultcontent: props.editItem.description.defaultcontent,
              ml: description,
            },
            reqThumbnail: {
              ...localeObject,
              defaultcontent: props.editItem.thumbnailimgurl.defaultcontent,
              ml: croppedImageUrl,
            },
            reqImageUrl: {
              ...localeObject,
              defaultcontent: props.editItem.imageurl.defaultcontent,
              ml: selectedcroppedImageUrl,
            },
            reqAudioUrl: {
              ...localeObject,
              defaultcontent: props.editItem.audiourl.defaultcontent,
              ml: audioUploadUrl,
            },
            reqAttribute: {
              ...localeObject,
              defaultcontent: activityType,
              ml: activityType,
            },
          };
        case 17:
          return {
            reqTitle: {
              ...localeObject,
              defaultcontent: props.editItem.title.defaultcontent,
              uk: title,
            },
            reqSubTitle: {
              ...localeObject,
              defaultcontent: props.editItem.subtitle.defaultcontent,
              uk: subTitle,
            },
            reqDescription: {
              ...localeObject,
              defaultcontent: props.editItem.description.defaultcontent,
              uk: description,
            },
            reqThumbnail: {
              ...localeObject,
              defaultcontent: props.editItem.thumbnailimgurl.defaultcontent,
              uk: croppedImageUrl,
            },
            reqImageUrl: {
              ...localeObject,
              defaultcontent: props.editItem.imageurl.defaultcontent,
              uk: selectedcroppedImageUrl,
            },
            reqAudioUrl: {
              ...localeObject,
              defaultcontent: props.editItem.audiourl.defaultcontent,
              uk: audioUploadUrl,
            },
            reqAttribute: {
              ...localeObject,
              defaultcontent: activityType,
              uk: activityType,
            },
          };
        case 18:
          return {
            reqTitle: {
              ...localeObject,
              defaultcontent: props.editItem.title.defaultcontent,
              od: title,
            },
            reqSubTitle: {
              ...localeObject,
              defaultcontent: props.editItem.subtitle.defaultcontent,
              od: subTitle,
            },
            reqDescription: {
              ...localeObject,
              defaultcontent: props.editItem.description.defaultcontent,
              od: description,
            },
            reqThumbnail: {
              ...localeObject,
              defaultcontent: props.editItem.thumbnailimgurl.defaultcontent,
              od: croppedImageUrl,
            },
            reqImageUrl: {
              ...localeObject,
              defaultcontent: props.editItem.imageurl.defaultcontent,
              od: selectedcroppedImageUrl,
            },
            reqAudioUrl: {
              ...localeObject,
              defaultcontent: props.editItem.audiourl.defaultcontent,
              od: audioUploadUrl,
            },
            reqAttribute: {
              ...localeObject,
              defaultcontent: activityType,
              od: activityType,
            },
          };
        case 19:
          return {
            reqTitle: {
              ...localeObject,
              defaultcontent: props.editItem.title.defaultcontent,
              enUs: title,
            },
            reqSubTitle: {
              ...localeObject,
              defaultcontent: props.editItem.subtitle.defaultcontent,
              enUs: subTitle,
            },
            reqDescription: {
              ...localeObject,
              defaultcontent: props.editItem.description.defaultcontent,
              enUs: description,
            },
            reqThumbnail: {
              ...localeObject,
              defaultcontent: props.editItem.thumbnailimgurl.defaultcontent,
              enUs: croppedImageUrl,
            },
            reqImageUrl: {
              ...localeObject,
              defaultcontent: props.editItem.imageurl.defaultcontent,
              enUs: selectedcroppedImageUrl,
            },
            reqAudioUrl: {
              ...localeObject,
              defaultcontent: props.editItem.audiourl.defaultcontent,
              enUs: audioUploadUrl,
            },
            reqAttribute: {
              ...localeObject,
              defaultcontent: activityType,
              enUs: activityType,
            },
          };
        case 20:
          return {
            reqTitle: {
              ...localeObject,
              defaultcontent: props.editItem.title.defaultcontent,
              enAu: title,
            },
            reqSubTitle: {
              ...localeObject,
              defaultcontent: props.editItem.subtitle.defaultcontent,
              enAu: subTitle,
            },
            reqDescription: {
              ...localeObject,
              defaultcontent: props.editItem.description.defaultcontent,
              enAu: description,
            },
            reqThumbnail: {
              ...localeObject,
              defaultcontent: props.editItem.thumbnailimgurl.defaultcontent,
              enAu: croppedImageUrl,
            },
            reqImageUrl: {
              ...localeObject,
              defaultcontent: props.editItem.imageurl.defaultcontent,
              enAu: selectedcroppedImageUrl,
            },
            reqAudioUrl: {
              ...localeObject,
              defaultcontent: props.editItem.audiourl.defaultcontent,
              enAu: audioUploadUrl,
            },
            reqAttribute: {
              ...localeObject,
              defaultcontent: activityType,
              enAu: activityType,
            },
          };
          case 21:
          return {
            reqTitle: {
              ...localeObject,
              defaultcontent: props.editItem.title.defaultcontent,
              pa: title,
            },
            reqSubTitle: {
              ...localeObject,
              defaultcontent: props.editItem.subtitle.defaultcontent,
              pa: subTitle,
            },
            reqDescription: {
              ...localeObject,
              defaultcontent: props.editItem.description.defaultcontent,
              pa: description,
            },
            reqThumbnail: {
              ...localeObject,
              defaultcontent: props.editItem.thumbnailimgurl.defaultcontent,
              pa: croppedImageUrl,
            },
            reqImageUrl: {
              ...localeObject,
              defaultcontent: props.editItem.imageurl.defaultcontent,
              pa: selectedcroppedImageUrl,
            },
            reqAudioUrl: {
              ...localeObject,
              defaultcontent: props.editItem.audiourl.defaultcontent,
              pa: audioUploadUrl,
            },
            reqAttribute: {
              ...localeObject,
              defaultcontent: activityType,
              pa: activityType,
            },
          };
      }
    };

    const _updateMeditation = () => {
      if (validateMeditaion()) {
        const data: any = {
          id: meditationId,
          meditationType: 2,
          meditationcategory: meditationCategory,
          title: decideLocale()?.reqTitle,
          subTitle: decideLocale()?.reqSubTitle,
          attribute: decideLocale()?.reqAttribute,
          description: decideLocale()?.reqDescription,
          duration: Number(duration),
          instructionDuration: Number(instructionDuration),
          durationFileId: props.editItem.durationfileid,
          purposeFileId: 7,
          tag: "",
          rating: Number(rating),
          likes: 5,
          audioUrl: decideLocale()?.reqAudioUrl,
          audioUrlV2: decideLocale()?.reqAudioUrl,
          imageUrl: decideLocale()?.reqImageUrl,
          thumbnailImgUrl: decideLocale()?.reqThumbnail,
          backgroundImageUrl: backgroundImageUrl,
        };
        setIsAdding(true);
        _updateMeditationFile(data);
      }
    };

    const _updateMeditationFile = async (data: any) => {
      console.log("Update meditaion data", data);
      try {
        const reqBody = new MeditationFileDetailsV2();

        const titleLocale = new Locale();
        titleLocale.setDefaultcontent(data.title.defaultcontent);
        titleLocale.setEn(data.title.en);
        titleLocale.setHi(data.title.hi);
        titleLocale.setTe(data.title.te);
        titleLocale.setGu(data.title.gu);
        titleLocale.setTa(data.title.ta);
        titleLocale.setKa(data.title.ka);
        titleLocale.setMr(data.title.mr);
        titleLocale.setIt(data.title.it);
        titleLocale.setRu(data.title.ru);
        titleLocale.setEs(data.title.es);
        titleLocale.setFr(data.title.fr);
        titleLocale.setPt(data.title.pt);
        titleLocale.setDe(data.title.de);
        titleLocale.setBn(data.title.bn);
        titleLocale.setMl(data.title.ml);
        titleLocale.setUk(data.title.uk);
        titleLocale.setOd(data.title.od);
        titleLocale.setEnUs(data.title.enUs);
        titleLocale.setEnAu(data.title.enAu);
        titleLocale.setPa(data.title.pa);
        reqBody.setTitle(titleLocale);

        const subtitleLocale = new Locale();
        subtitleLocale.setDefaultcontent(data.subTitle.defaultcontent);
        subtitleLocale.setEn(data.subTitle.en);
        subtitleLocale.setHi(data.subTitle.hi);
        subtitleLocale.setTe(data.subTitle.te);
        subtitleLocale.setGu(data.subTitle.gu);
        subtitleLocale.setTa(data.subTitle.ta);
        subtitleLocale.setKa(data.subTitle.ka);
        subtitleLocale.setMr(data.subTitle.mr);
        subtitleLocale.setIt(data.subTitle.it);
        subtitleLocale.setRu(data.subTitle.ru);
        subtitleLocale.setEs(data.subTitle.es);
        subtitleLocale.setFr(data.subTitle.fr);
        subtitleLocale.setPt(data.subTitle.pt);
        subtitleLocale.setDe(data.subTitle.de);
        subtitleLocale.setBn(data.subTitle.bn);
        subtitleLocale.setMl(data.subTitle.ml);
        subtitleLocale.setUk(data.subTitle.uk);
        subtitleLocale.setOd(data.subTitle.od);
        subtitleLocale.setEnUs(data.subTitle.enUs);
        subtitleLocale.setEnAu(data.subTitle.enAu);
        subtitleLocale.setPa(data.subTitle.pa);
        reqBody.setSubtitle(subtitleLocale);

        const descriptionLocale = new Locale();
        descriptionLocale.setDefaultcontent(data.description.defaultcontent);
        descriptionLocale.setEn(data.description.en);
        descriptionLocale.setHi(data.description.hi);
        descriptionLocale.setTe(data.description.te);
        descriptionLocale.setGu(data.description.gu);
        descriptionLocale.setTa(data.description.ta);
        descriptionLocale.setKa(data.description.ka);
        descriptionLocale.setMr(data.description.mr);
        descriptionLocale.setIt(data.description.it);
        descriptionLocale.setRu(data.description.ru);
        descriptionLocale.setEs(data.description.es);
        descriptionLocale.setFr(data.description.fr);
        descriptionLocale.setPt(data.description.pt);
        descriptionLocale.setDe(data.description.de);
        descriptionLocale.setBn(data.description.bn);
        descriptionLocale.setMl(data.description.ml);
        descriptionLocale.setUk(data.description.uk);
        descriptionLocale.setOd(data.description.od);
        descriptionLocale.setEnUs(data.description.enUs);
        descriptionLocale.setEnAu(data.description.enAu);
        descriptionLocale.setPa(data.description.pa);

        reqBody.setDescription(descriptionLocale);

        const audiourlLocale = new Locale();
        audiourlLocale.setDefaultcontent(data.audioUrl.defaultcontent);
        audiourlLocale.setEn(data.audioUrl.en);
        audiourlLocale.setHi(data.audioUrl.hi);
        audiourlLocale.setTe(data.audioUrl.te);
        audiourlLocale.setGu(data.audioUrl.gu);
        audiourlLocale.setTa(data.audioUrl.ta);
        audiourlLocale.setKa(data.audioUrl.ka);
        audiourlLocale.setMr(data.audioUrl.mr);
        audiourlLocale.setIt(data.audioUrl.it);
        audiourlLocale.setRu(data.audioUrl.ru);
        audiourlLocale.setEs(data.audioUrl.es);
        audiourlLocale.setFr(data.audioUrl.fr);
        audiourlLocale.setPt(data.audioUrl.pt);
        audiourlLocale.setDe(data.audioUrl.de);
        audiourlLocale.setBn(data.audioUrl.bn);
        audiourlLocale.setMl(data.audioUrl.ml);
        audiourlLocale.setUk(data.audioUrl.uk);
        audiourlLocale.setOd(data.audioUrl.od);
        audiourlLocale.setEnUs(data.audioUrl.enUs);
        audiourlLocale.setEnAu(data.audioUrl.enAu);
        audiourlLocale.setPa(data.audioUrl.pa);
        reqBody.setAudiourl(audiourlLocale);

        const audiourlLocaleV2 = new Locale();
        audiourlLocaleV2.setDefaultcontent(data.audioUrlV2.defaultcontent);
        audiourlLocaleV2.setEn(data.audioUrlV2.en);
        audiourlLocaleV2.setHi(data.audioUrlV2.hi);
        audiourlLocaleV2.setTe(data.audioUrlV2.te);
        audiourlLocaleV2.setGu(data.audioUrlV2.gu);
        audiourlLocaleV2.setTa(data.audioUrlV2.ta);
        audiourlLocaleV2.setKa(data.audioUrlV2.ka);
        audiourlLocaleV2.setMr(data.audioUrlV2.mr);
        audiourlLocaleV2.setIt(data.audioUrlV2.it);
        audiourlLocaleV2.setRu(data.audioUrlV2.ru);
        audiourlLocaleV2.setEs(data.audioUrlV2.es);
        audiourlLocaleV2.setFr(data.audioUrlV2.fr);
        audiourlLocaleV2.setPt(data.audioUrlV2.pt);
        audiourlLocaleV2.setDe(data.audioUrlV2.de);
        audiourlLocaleV2.setBn(data.audioUrlV2.bn);
        audiourlLocaleV2.setMl(data.audioUrlV2.ml);
        audiourlLocaleV2.setUk(data.audioUrlV2.uk);
        audiourlLocaleV2.setOd(data.audioUrlV2.od);
        audiourlLocaleV2.setEnUs(data.audioUrlV2.enUs);
        audiourlLocaleV2.setEnAu(data.audioUrlV2.enAu);
        audiourlLocaleV2.setPa(data.audioUrlV2.pa);
        reqBody.setAudiourlv2(audiourlLocaleV2);

        const imageurlLocale = new Locale();
        imageurlLocale.setDefaultcontent(data.imageUrl.defaultcontent);
        imageurlLocale.setEn(data.imageUrl.en);
        imageurlLocale.setHi(data.imageUrl.hi);
        imageurlLocale.setTe(data.imageUrl.te);
        imageurlLocale.setGu(data.imageUrl.gu);
        imageurlLocale.setTa(data.imageUrl.ta);
        imageurlLocale.setKa(data.imageUrl.ka);
        imageurlLocale.setMr(data.imageUrl.mr);
        imageurlLocale.setIt(data.imageUrl.it);
        imageurlLocale.setRu(data.imageUrl.ru);
        imageurlLocale.setEs(data.imageUrl.es);
        imageurlLocale.setFr(data.imageUrl.fr);
        imageurlLocale.setPt(data.imageUrl.pt);
        imageurlLocale.setDe(data.imageUrl.de);
        imageurlLocale.setBn(data.imageUrl.bn);
        imageurlLocale.setMl(data.imageUrl.ml);
        imageurlLocale.setUk(data.imageUrl.uk);
        imageurlLocale.setOd(data.imageUrl.od);
        imageurlLocale.setEnUs(data.imageUrl.enUs);
        imageurlLocale.setEnAu(data.imageUrl.enAu);
        imageurlLocale.setPa(data.imageUrl.pa);
        reqBody.setImageurl(imageurlLocale);

        const thumbnailimgurlLocale = new Locale();
        thumbnailimgurlLocale.setDefaultcontent(
          data.thumbnailImgUrl.defaultcontent
        );
        thumbnailimgurlLocale.setEn(data.thumbnailImgUrl.en);
        thumbnailimgurlLocale.setHi(data.thumbnailImgUrl.hi);
        thumbnailimgurlLocale.setTe(data.thumbnailImgUrl.te);
        thumbnailimgurlLocale.setGu(data.thumbnailImgUrl.gu);
        thumbnailimgurlLocale.setTa(data.thumbnailImgUrl.ta);
        thumbnailimgurlLocale.setKa(data.thumbnailImgUrl.ka);
        thumbnailimgurlLocale.setMr(data.thumbnailImgUrl.mr);
        thumbnailimgurlLocale.setIt(data.thumbnailImgUrl.it);
        thumbnailimgurlLocale.setRu(data.thumbnailImgUrl.ru);
        thumbnailimgurlLocale.setEs(data.thumbnailImgUrl.es);
        thumbnailimgurlLocale.setFr(data.thumbnailImgUrl.fr);
        thumbnailimgurlLocale.setPt(data.thumbnailImgUrl.pt);
        thumbnailimgurlLocale.setDe(data.thumbnailImgUrl.de);
        thumbnailimgurlLocale.setBn(data.thumbnailImgUrl.bn);
        thumbnailimgurlLocale.setMl(data.thumbnailImgUrl.ml);
        thumbnailimgurlLocale.setUk(data.thumbnailImgUrl.uk);
        thumbnailimgurlLocale.setOd(data.thumbnailImgUrl.od);
        thumbnailimgurlLocale.setEnUs(data.thumbnailImgUrl.enUs);
        thumbnailimgurlLocale.setEnAu(data.thumbnailImgUrl.enAu);
        thumbnailimgurlLocale.setPa(data.thumbnailImgUrl.pa);
        reqBody.setThumbnailimgurl(thumbnailimgurlLocale);

        const attributeLocale: Locale = new Locale();
        attributeLocale.setDefaultcontent(data.attribute.defaultContent);
        attributeLocale.setEn(data.attribute.en);
        attributeLocale.setHi(data.attribute.hi);
        attributeLocale.setTe(data.attribute.te);
        attributeLocale.setGu(data.attribute.gu);
        attributeLocale.setTa(data.attribute.ta);
        attributeLocale.setKa(data.attribute.ka);
        attributeLocale.setMr(data.attribute.mr);
        attributeLocale.setIt(data.attribute.it);
        attributeLocale.setRu(data.attribute.ru);
        attributeLocale.setEs(data.attribute.es);
        attributeLocale.setFr(data.attribute.fr);
        attributeLocale.setPt(data.attribute.pt);
        attributeLocale.setDe(data.attribute.de);
        attributeLocale.setBn(data.attribute.bn);
        attributeLocale.setMl(data.attribute.ml);
        attributeLocale.setUk(data.attribute.uk);
        attributeLocale.setOd(data.attribute.od);
        attributeLocale.setEnUs(data.attribute.enUs);
        attributeLocale.setEnAu(data.attribute.enAu);
        attributeLocale.setPa(data.attribute.pa);
        reqBody.setAttribute(attributeLocale);

        reqBody.setId(data.id);
        reqBody.setLikes(data.likes);
        reqBody.setMeditationtype(data.meditationType);
        reqBody.setRating(data.rating);
        reqBody.setTag(data.tag);
        reqBody.setDurationofmeditationclip(data.duration);
        reqBody.setDurationofinstructionclip(data.instructionDuration);
        reqBody.setPurposefileid(data.purposeFileId);
        reqBody.setDurationfileid(data.durationFileId);
        reqBody.setBackgroundimageurl(data.backgroundImageUrl);
        reqBody.setTagsList(tagsArray());
        reqBody.setMeditationcategory(data.meditationcategory);

        console.log(
          "reqbody for updatemeditationwithguidance",
          reqBody.toObject()
        );

        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        grpc.unary(GuidedMeditationService.updateMeditationFileDetailsV2, {
          request: reqBody,
          host: GUIDED_MEDITATION_SERVICE_HOST,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<MeditationFileDetailsV2>) => {
            const { status, statusMessage, message } = res;
            console.log("statusMessage", statusMessage);
            console.log("grpc code", grpc.Code);

            if (status === grpc.Code.OK && message) {
              console.log(
                "response coming from updateMeditationFileDetailsV2 ",
                message.toObject()
              );
              if (audioUploadUrl !== prevAudioUploadUrl) {
                _deleteFileFromGcp(prevAudioUploadUrl);
              }
              dispatch(getAllMeditationFileDetailsV2(10, 0, 1));
              setIsLoading(false);
              setSnackBar({
                open: true,
                severity: "success",
                message: "Meditation with guidance updated successfully",
              });
              setTimeout(() => props.onClickBack(), 2000);
              return message;
            } else {
              setIsLoading(false);
              setSnackBar({
                open: true,
                severity: "error",
                message: "error in updating Meditation with guidance ",
              });
            }
          },
        });
      } catch (error) {
        setIsLoading(false);
        setSnackBar({
          open: true,
          severity: "error",
          message: "something went wrong in update Meditation with guidance",
        });
        console.error(
          "something went wrong in update Meditation with guidance",
          error
        );
      }
    };

    const _deleteMeditation = async (data: any) => {
      try {
        console.log("Delete Usermeditation data", data);
        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        const reqBody: ID = new ID();
        reqBody.setId(data.ID);
        setIsLoading(true);
        grpc.unary(GuidedMeditationService.deleteMeditationFileDetailsByID, {
          host: GUIDED_MEDITATION_SERVICE_HOST,
          request: reqBody,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (
            res: UnaryOutput<DeleteMeditationFileDetailsByIdResponse>
          ) => {
            const { status, message } = res;
            if (status === grpc.Code.OK && message) {
              if (message) {
                console.log("delete meditation response", message.toObject());
                deleteLocaleFile(props.editItem.thumbnailimgurl);
                deleteLocaleFile(props.editItem.imageurl);
                deleteLocaleFile(props.editItem.audiourl);
                _deleteFileFromGcp(backgroundImageUrl);
                setIsLoading(false);
                dispatch(getAllMeditationFileDetailsV2(10, 0, 1));
                setdeleteMeditation({ showDeleteModal: false });
                setTimeout(() => props.onClickBack(), 2000);
                setSnackBar({
                  open: true,
                  severity: "success",
                  message: " Deleted Meditation successfully",
                });
              }
            } else {
              setIsLoading(false);
              setSnackBar({
                open: true,
                severity: "error",
                message: "error in  deleting meditatate with guidance",
              });
              setdeleteMeditation({ showDeleteModal: false });
            }
          },
        });
      } catch (err) {
        setIsLoading(false);
        setSnackBar({
          open: true,
          severity: "error",
          message: "error in  deleting meditatate with guidance",
        });
        console.log(err);
      }
    };
    return (
      <Root ref={ref} className="classes.root">
        {isLoading && (
          <CircularProgress
            style={{
              position: "absolute",
              top: "50%",
              right: "50%",
              zIndex: 5,
            }}
          />
        )}
        <Grid
          container
          className="p-2 align-items-center"
          justifyContent="space-between"
        >
          <Grid item>
            <Grid container alignItems="center">
              <Grid>
                <IconButton onClick={props.onClickBack} size="large">
                  <KeyboardBackspaceIcon color="action" />
                </IconButton>
              </Grid>
              <Grid>
                <Typography variant="h6">
                  Update Meditation With Guidance
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item className="d-flex justify-content-end">
            <Button
              variant="contained"
              color="primary"
              className="mr-2"
              disabled={editMeditation}
              //startIcon={<DeleteIcon />}
              onClick={() => {
                seteditMeditation(true);
                //onDeleteMeditation(sessionId)
              }}
            >
              Edit
            </Button>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<DeleteIcon />}
              onClick={() => onDeleteMeditation(props.editItem.id)}
            >
              Delete
            </Button>
          </Grid>
        </Grid>
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                type="text"
                value={title}
                onChange={(e) => {
                  //onChangeTitle(e);
                  setTitle(e.target.value);
                }}
                InputProps={{
                  readOnly: editMeditation ? false : true,
                }}
                label="Meditation Title"
                name="meditationTitle"
                required
                variant="outlined"
                //value={title ? title[titleLang.toLowerCase()] || "" : ""}
                className="mr-3"
                disabled={!editMeditation}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="language-select">Language</InputLabel>
                {languages && (
                  <Select
                    labelId="language-select"
                    value={language}
                    disabled={!editMeditation}
                    label="Language"
                    onChange={(e) => {
                      setLanguage(e.target.value as number);
                      dispatch(getAllPurposeFile(e.target.value as any));
                    }}
                  >
                    {languages.map((lang, i) => (
                      <MenuItem key={i} value={lang.code}>
                        {lang.displayName}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                type="text"
                value={subTitle}
                onChange={(e) => {
                  setSubTitle(e.target.value);
                }}
                label="Meditation Sub-Title"
                name="meditationTitle"
                required
                variant="outlined"
                className="mr-3"
                InputProps={{
                  readOnly: editMeditation ? false : true,
                }}
                disabled={!editMeditation}
              />
            </Grid>
            {/* <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                required
                type="text"
                name="activityType"
                value={activityType}
                onChange={(e) => {
                  setActivityType(e.target.value);
                }}
                label="Activity Type"
                disabled={!editMeditation}
              />
            </Grid> */}
            <Grid item xs={12} md={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="medType">Meditation Category</InputLabel>
                <Select
                  labelId="medType"
                  value={meditationCategory}
                  required
                  onChange={(value: any) => {
                    setMeditationCategory(value.target.value);
                    // console.log("selected val", value.target.value);
                  }}
                  label="Meditation Category"
                >
                  {meditationCategories.map((item, index) => {
                    return (
                      <MenuItem key={`meditation${index}`} value={index + 1}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                type="text"
                required
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                label="Description"
                name="description"
                rows={1}
                variant="outlined"
                multiline
                value={description}
                InputProps={{
                  readOnly: editMeditation ? false : true,
                }}
                disabled={!editMeditation}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                type="number"
                value={duration}
                onChange={(e) => {
                  if (!isDurationChanged) {
                    setIsDurationChanged(true);
                  }
                  setDuration(e.target.value);
                }}
                onBlur={() => {
                  (duration === "" || +duration < 0) && setDuration(0);
                }}
                label="Meditation duration (in sec)"
                name="duration"
                required
                variant="outlined"
                InputProps={{
                  readOnly: editMeditation ? false : true,
                }}
                disabled={!editMeditation}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                type="number"
                onChange={(e) => {
                  if (!isInstructionDurationChanged) {
                    setIsInstructionDurationChanged(true);
                  }
                  setInstructionDuration(e.target.value);
                  console.log(duration, instructionDuration);
                }}
                onBlur={() => {
                  (instructionDuration === "" || +instructionDuration < 0) &&
                    setInstructionDuration(0);
                }}
                // helperText={`Please enter the duration of the meditation`}
                label="Instruction clip duration (in sec)"
                name="instructionDuration"
                required
                error={isInstructionDurationChanged}
                helperText={
                  isInstructionDurationChanged && "This field is not localized"
                }
                variant="outlined"
                value={instructionDuration}
                InputProps={{
                  readOnly: editMeditation ? false : true,
                }}
                disabled={!editMeditation}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="demo-multiple-checkbox-label">Tags</InputLabel>
                <Select
                  labelId="tags-multiple-checkbox-label"
                  id="tags-multiple-checkbox"
                  multiple
                  inputProps={{
                    readOnly: editMeditation ? false : true,
                  }}
                  value={selectedTagsId}
                  onChange={handleMultipleTagChange}
                  input={<OutlinedInput label="Tags" />}
                  renderValue={() => selectedTags.join(",")}
                  MenuProps={{
                    sx: {
                      maxHeight: "500px",
                    },
                  }}
                  disabled={!editMeditation}
                >
                  {tagsList.loading ? (
                    <div style={{ textAlign: "center" }}>
                      <CircularProgress />
                    </div>
                  ) : (
                    tagsList.tags.tagsList.map((x: any) => (
                      <MenuItem key={x.id} value={x.id}>
                        <Checkbox checked={selectedTagsId.indexOf(x.id) > -1} />
                        <ListItemText primary={String(x.name)} />
                      </MenuItem>
                    ))
                  )}
                  <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={tagsList.tags.pageable?.totalcount!}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={10}
                    style={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)" }}
                  />
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4} xs={12} sm={6}>
              <FormControl variant="outlined" fullWidth>
                <Card variant="outlined" className={classes.root}>
                  {editMeditation ? (
                    <>
                      {croppedImageUrl ? (
                        <PreviewProfileImage
                          onImageClose={onImageClose}
                          src={croppedImageUrl}
                          rectangular={true}
                        />
                      ) : (
                        <>
                          {isUploading.image ? (
                            <Card className={classes.thumbNail}>
                              <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                className="pt-5"
                              >
                                <Typography className="pt-4">
                                  Uploading...
                                </Typography>
                              </Grid>
                            </Card>
                          ) : (
                            <CardActionArea
                              className={classes.thumbNail}
                              onClick={() => {
                                handleUploadClick();
                              }}
                            >
                              {/* <InputLabel htmlFor="thumbnail">
                                Upload thumbnail
                              </InputLabel> */}
                              <Avatar
                                className={classes.actionIcon}
                                variant="square"
                              >
                                <ImageOutlined />
                              </Avatar>
                              <input
                                accept="image/*"
                                id="contained-button-file"
                                type="file"
                                hidden
                                onChange={onSelectImage}
                                ref={hiddenFileInput}
                              />
                            </CardActionArea>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      className="mt-4"
                    >
                      {croppedImageUrl === "" || croppedImageUrl === null ? (
                        <Avatar
                          src=""
                          style={{ height: "200px", width: "200px" }}
                        />
                      ) : (
                        <img
                          alt="coach Profile"
                          src={croppedImageUrl}
                          style={{
                            height: "200px",
                            width: "200px",
                            borderRadius: "8%",
                          }}
                        />
                      )}
                    </Grid>
                  )}
                </Card>
                <Typography variant="overline" align="center" gutterBottom>
                  Thumbnail
                </Typography>
              </FormControl>
            </Grid>
            <Grid item md={4} xs={12} sm={6}>
              <FormControl variant="outlined" fullWidth>
                <Card variant="outlined" className={classes.root}>
                  {editMeditation ? (
                    <>
                      {selectedcroppedImageUrl ? (
                        <PreviewProfileImage
                          onImageClose={_onImageClose}
                          src={selectedcroppedImageUrl}
                          rectangular={true}
                        />
                      ) : (
                        <>
                          {isUploading.selectedImage === true ? (
                            <Card className={classes.thumbNail}>
                              <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                className="pt-5"
                              >
                                <Typography className="pt-4">
                                  Uploading...
                                </Typography>
                              </Grid>
                            </Card>
                          ) : (
                            <CardActionArea
                              className={classes.thumbNail}
                              onClick={() => {
                                _handleUploadClick();
                              }}
                            >
                              {/* <InputLabel htmlFor="thumbnail">
                                Upload favourite image
                              </InputLabel> */}
                              <Avatar
                                className={classes.actionIcon}
                                variant="square"
                              >
                                <ImageOutlined />
                              </Avatar>
                              <input
                                accept="image/*"
                                id="contained-button-file"
                                type="file"
                                hidden
                                onChange={_onSelectImage}
                                ref={hiddenSelectedImageInput}
                              />
                            </CardActionArea>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      className="mt-4"
                    >
                      {selectedcroppedImageUrl === "" ||
                      selectedcroppedImageUrl === null ? (
                        <Avatar
                          src=""
                          style={{ height: "200px", width: "200px" }}
                        />
                      ) : (
                        <img
                          alt=""
                          src={selectedcroppedImageUrl}
                          style={{
                            height: "200px",
                            width: "200px",
                            borderRadius: "5px",
                          }}
                        />
                      )}
                    </Grid>
                  )}
                </Card>
                <Typography variant="overline" align="center" gutterBottom>
                  Favourite Image
                </Typography>
              </FormControl>
            </Grid>
            <Grid item md={4} xs={12} sm={6}>
              <FormControl variant="outlined" fullWidth>
                <Card variant="outlined" className={classes.root}>
                  {editMeditation ? (
                    <>
                      {backgroundImageUrl ? (
                        <PreviewProfileImage
                          onImageClose={onBackgroundImageClose}
                          src={backgroundImageUrl}
                          rectangular={true}
                        />
                      ) : (
                        <>
                          {isUploading.backgroundImage === true ? (
                            <Card className={classes.thumbNail}>
                              <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                className="pt-5"
                              >
                                <Typography className="pt-4">
                                  Uploading...
                                </Typography>
                              </Grid>
                            </Card>
                          ) : (
                            <CardActionArea
                              className={classes.thumbNail}
                              onClick={() => {
                                backgroundHandleUploadClick();
                              }}
                            >
                              {/* <InputLabel htmlFor="thumbnail">
                                Upload background image
                              </InputLabel> */}
                              <Avatar
                                className={classes.actionIcon}
                                variant="square"
                              >
                                <ImageOutlined />
                              </Avatar>
                              <input
                                accept="image/*"
                                id="contained-button-file"
                                type="file"
                                hidden
                                onChange={onSelectBackgroundImage}
                                ref={hiddenBackgroundImageInput}
                              />
                            </CardActionArea>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      className="mt-4"
                    >
                      {backgroundImageUrl === "" ||
                      backgroundImageUrl === null ? (
                        <Avatar
                          src=""
                          style={{ height: "200px", width: "200px" }}
                        />
                      ) : (
                        <img
                          alt=""
                          src={backgroundImageUrl}
                          style={{
                            height: "200px",
                            width: "200px",
                            borderRadius: "5px",
                          }}
                        />
                      )}
                    </Grid>
                  )}
                </Card>
                <Typography variant="overline" align="center" gutterBottom>
                  Background Image
                </Typography>
              </FormControl>
            </Grid>
            <Grid item md={4} xs={6}>
              <FormControl variant="outlined" fullWidth>
                <Card variant="outlined" className={classes.root}>
                  {editMeditation ? (
                    <>
                      {audioUploadUrl ? (
                        <>
                          {editMeditation ? (
                            <AudioPlayer
                              src={audioUploadUrl}
                              onAudioClose={onAudioClose}
                            />
                          ) : (
                            <AudioPlayer src={audioUploadUrl} />
                          )}
                        </>
                      ) : (
                        <>
                          {isUploading.audio === true ? (
                            <Card className={classes.thumbNail}>
                              <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                className="pt-5"
                              >
                                <Typography className="pt-4">
                                  Uploading...
                                </Typography>
                              </Grid>
                            </Card>
                          ) : (
                            <CardActionArea
                              className={classes.thumbNail}
                              onClick={() => {
                                handleAudioClick();
                              }}
                            >
                              {/* <InputLabel htmlFor="thumbnail">
                            Upload Audio
                          </InputLabel> */}
                              <Avatar
                                className={classes.actionIcon}
                                variant="square"
                              >
                                <MusicNoteOutlined />
                              </Avatar>
                              <input
                                accept="audio/*"
                                id="audioFile"
                                type="file"
                                hidden
                                onChange={onAudioSelection}
                                ref={hiddenAudioInput}
                              />
                            </CardActionArea>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      height="100%"
                    >
                      {audioUploadUrl === "" || audioUploadUrl === null ? (
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="flex-start"
                        >
                          <Avatar variant="square">
                            <MusicNoteOutlined />
                          </Avatar>
                        </Grid>
                      ) : (
                        <AudioPlayer src={audioUploadUrl} type="view" />
                      )}
                    </Grid>
                  )}
                </Card>
                <Typography variant="overline" align="center" gutterBottom>
                  Audio
                </Typography>
              </FormControl>
            </Grid>
          </Grid>
          <DialogBox
            open={deleteMeditation.showDeleteModal || false}
            onClose={handleDeleteClose}
            onCancel={handleDeleteClose}
            onConfirm={_deleteSession}
            type={"delete"}
            title={`Sure !! Want to delete this Item ?`}
          />
        </CardContent>
        <Divider />
        <Box p={2} display="flex" justifyContent="flex-end">
          <Button
            style={{ marginRight: "8px" }}
            className={classes.formBtn}
            onClick={() => {
              seteditMeditation(false);
              onClickEdit(props.editItem);
              setLanguage(1);
              dispatch(getAllPurposeFile(1));
            }}
            color="primary"
            variant="contained"
            disabled={!editMeditation}
          >
            Cancel
          </Button>

          <Button
            className={classes.formBtn}
            onClick={() => {
              _updateMeditation();
            }}
            color="primary"
            variant="contained"
            disabled={!editMeditation || isAdding}
          >
            Save
          </Button>
        </Box>

        {src && (
          <ImageCrop
            src={src.toString()}
            crop={crop}
            onImageLoaded={onImageLoaded}
            onCropComplete={onCropComplete}
            onCropChange={onCropChange}
            onPressDone={onCropDone}
            onImageClose={onImageClose}
            open={open}
            onOpen={_onOpenImage}
            onClose={_onCloseImage}
          />
        )}
        {selectedImagesrc && (
          <ImageCrop
            src={selectedImagesrc.toString()}
            crop={selectedImagecrop}
            onImageLoaded={_onImageLoaded}
            onCropComplete={_onCropComplete}
            onCropChange={_onCropChange}
            onPressDone={_onCropDone}
            onImageClose={_onImageClose}
            open={open}
            onOpen={_onOpenImage}
            onClose={_onCloseSelectedImage}
          />
        )}
        {backgroundImageSrc && (
          <ImageCrop
            src={backgroundImageSrc.toString()}
            crop={backgroundImageCrop}
            onImageLoaded={onBackgroundImageLoaded}
            onCropComplete={onBackgroundCropComplete}
            onCropChange={onBackgroundCropChange}
            onPressDone={onBackgroundCropDone}
            onImageClose={onBackgroundImageClose}
            open={open}
            onOpen={_onOpenImage}
            onClose={_onCloseBackgroundImage}
          />
        )}
        <Snackbar
          open={snackBar.open}
          autoHideDuration={10000}
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert
            severity={snackBar.severity}
            onClose={() => {
              setSnackBar({ ...snackBar, open: false });
            }}
          >
            {snackBar.message}
          </Alert>
        </Snackbar>
      </Root>
    );
  }
);
