// source: common/common.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.unifiedplatform.heartintune.common.Activity', null, global);
goog.exportSymbol('proto.unifiedplatform.heartintune.common.BackGroundType', null, global);
goog.exportSymbol('proto.unifiedplatform.heartintune.common.BhandaraHelpLineType', null, global);
goog.exportSymbol('proto.unifiedplatform.heartintune.common.Channel', null, global);
goog.exportSymbol('proto.unifiedplatform.heartintune.common.ContentCategory', null, global);
goog.exportSymbol('proto.unifiedplatform.heartintune.common.DayFrequency', null, global);
goog.exportSymbol('proto.unifiedplatform.heartintune.common.Discover', null, global);
goog.exportSymbol('proto.unifiedplatform.heartintune.common.DocumentCategory', null, global);
goog.exportSymbol('proto.unifiedplatform.heartintune.common.EnableFeature', null, global);
goog.exportSymbol('proto.unifiedplatform.heartintune.common.FeaturedContent', null, global);
goog.exportSymbol('proto.unifiedplatform.heartintune.common.GoalCategory', null, global);
goog.exportSymbol('proto.unifiedplatform.heartintune.common.GoalStatus', null, global);
goog.exportSymbol('proto.unifiedplatform.heartintune.common.InviteStatus', null, global);
goog.exportSymbol('proto.unifiedplatform.heartintune.common.JournalType', null, global);
goog.exportSymbol('proto.unifiedplatform.heartintune.common.LiveMeditationRating', null, global);
goog.exportSymbol('proto.unifiedplatform.heartintune.common.Locale', null, global);
goog.exportSymbol('proto.unifiedplatform.heartintune.common.LocaleType', null, global);
goog.exportSymbol('proto.unifiedplatform.heartintune.common.LocaleTypeLong', null, global);
goog.exportSymbol('proto.unifiedplatform.heartintune.common.LogMeditation', null, global);
goog.exportSymbol('proto.unifiedplatform.heartintune.common.MeditationCategory', null, global);
goog.exportSymbol('proto.unifiedplatform.heartintune.common.MeditationType', null, global);
goog.exportSymbol('proto.unifiedplatform.heartintune.common.MoodTriggerType', null, global);
goog.exportSymbol('proto.unifiedplatform.heartintune.common.MoodType', null, global);
goog.exportSymbol('proto.unifiedplatform.heartintune.common.PreRecordedType', null, global);
goog.exportSymbol('proto.unifiedplatform.heartintune.common.PublishContent', null, global);
goog.exportSymbol('proto.unifiedplatform.heartintune.common.Rating', null, global);
goog.exportSymbol('proto.unifiedplatform.heartintune.common.ReminderType', null, global);
goog.exportSymbol('proto.unifiedplatform.heartintune.common.RequestPageable', null, global);
goog.exportSymbol('proto.unifiedplatform.heartintune.common.ResponsePageable', null, global);
goog.exportSymbol('proto.unifiedplatform.heartintune.common.Screens', null, global);
goog.exportSymbol('proto.unifiedplatform.heartintune.common.SharedContent', null, global);
goog.exportSymbol('proto.unifiedplatform.heartintune.common.SharedVia', null, global);
goog.exportSymbol('proto.unifiedplatform.heartintune.common.UserType', null, global);
goog.exportSymbol('proto.unifiedplatform.heartintune.common.VideoCategory', null, global);
goog.exportSymbol('proto.unifiedplatform.heartintune.common.VisibilityType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartintune.common.Locale = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartintune.common.Locale, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartintune.common.Locale.displayName = 'proto.unifiedplatform.heartintune.common.Locale';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartintune.common.LocaleTypeLong = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartintune.common.LocaleTypeLong, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartintune.common.LocaleTypeLong.displayName = 'proto.unifiedplatform.heartintune.common.LocaleTypeLong';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartintune.common.RequestPageable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartintune.common.RequestPageable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartintune.common.RequestPageable.displayName = 'proto.unifiedplatform.heartintune.common.RequestPageable';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartintune.common.ResponsePageable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartintune.common.ResponsePageable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartintune.common.ResponsePageable.displayName = 'proto.unifiedplatform.heartintune.common.ResponsePageable';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartintune.common.Locale.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartintune.common.Locale.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartintune.common.Locale} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartintune.common.Locale.toObject = function(includeInstance, msg) {
  var f, obj = {
    defaultcontent: jspb.Message.getFieldWithDefault(msg, 1, ""),
    en: jspb.Message.getFieldWithDefault(msg, 2, ""),
    hi: jspb.Message.getFieldWithDefault(msg, 3, ""),
    te: jspb.Message.getFieldWithDefault(msg, 4, ""),
    gu: jspb.Message.getFieldWithDefault(msg, 5, ""),
    ta: jspb.Message.getFieldWithDefault(msg, 6, ""),
    ka: jspb.Message.getFieldWithDefault(msg, 7, ""),
    mr: jspb.Message.getFieldWithDefault(msg, 8, ""),
    it: jspb.Message.getFieldWithDefault(msg, 9, ""),
    ru: jspb.Message.getFieldWithDefault(msg, 10, ""),
    es: jspb.Message.getFieldWithDefault(msg, 11, ""),
    fr: jspb.Message.getFieldWithDefault(msg, 12, ""),
    pt: jspb.Message.getFieldWithDefault(msg, 13, ""),
    de: jspb.Message.getFieldWithDefault(msg, 14, ""),
    bn: jspb.Message.getFieldWithDefault(msg, 15, ""),
    ml: jspb.Message.getFieldWithDefault(msg, 16, ""),
    uk: jspb.Message.getFieldWithDefault(msg, 17, ""),
    od: jspb.Message.getFieldWithDefault(msg, 18, ""),
    enUs: jspb.Message.getFieldWithDefault(msg, 19, ""),
    enAu: jspb.Message.getFieldWithDefault(msg, 20, ""),
    pa: jspb.Message.getFieldWithDefault(msg, 21, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.heartintune.common.Locale.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartintune.common.Locale;
  return proto.unifiedplatform.heartintune.common.Locale.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartintune.common.Locale} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.heartintune.common.Locale.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefaultcontent(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEn(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setHi(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTe(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setGu(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTa(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setKa(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setMr(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setIt(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setRu(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setEs(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setFr(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setPt(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setDe(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setBn(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setMl(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setUk(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setOd(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnUs(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnAu(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setPa(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartintune.common.Locale.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartintune.common.Locale.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartintune.common.Locale} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartintune.common.Locale.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDefaultcontent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEn();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHi();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTe();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getGu();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTa();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getKa();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getMr();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getIt();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getRu();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getEs();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getFr();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getPt();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getDe();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getBn();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getMl();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getUk();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getOd();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getEnUs();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getEnAu();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getPa();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
};


/**
 * optional string defaultContent = 1;
 * @return {string}
 */
proto.unifiedplatform.heartintune.common.Locale.prototype.getDefaultcontent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartintune.common.Locale} returns this
 */
proto.unifiedplatform.heartintune.common.Locale.prototype.setDefaultcontent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string en = 2;
 * @return {string}
 */
proto.unifiedplatform.heartintune.common.Locale.prototype.getEn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartintune.common.Locale} returns this
 */
proto.unifiedplatform.heartintune.common.Locale.prototype.setEn = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string hi = 3;
 * @return {string}
 */
proto.unifiedplatform.heartintune.common.Locale.prototype.getHi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartintune.common.Locale} returns this
 */
proto.unifiedplatform.heartintune.common.Locale.prototype.setHi = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string te = 4;
 * @return {string}
 */
proto.unifiedplatform.heartintune.common.Locale.prototype.getTe = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartintune.common.Locale} returns this
 */
proto.unifiedplatform.heartintune.common.Locale.prototype.setTe = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string gu = 5;
 * @return {string}
 */
proto.unifiedplatform.heartintune.common.Locale.prototype.getGu = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartintune.common.Locale} returns this
 */
proto.unifiedplatform.heartintune.common.Locale.prototype.setGu = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string ta = 6;
 * @return {string}
 */
proto.unifiedplatform.heartintune.common.Locale.prototype.getTa = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartintune.common.Locale} returns this
 */
proto.unifiedplatform.heartintune.common.Locale.prototype.setTa = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string ka = 7;
 * @return {string}
 */
proto.unifiedplatform.heartintune.common.Locale.prototype.getKa = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartintune.common.Locale} returns this
 */
proto.unifiedplatform.heartintune.common.Locale.prototype.setKa = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string mr = 8;
 * @return {string}
 */
proto.unifiedplatform.heartintune.common.Locale.prototype.getMr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartintune.common.Locale} returns this
 */
proto.unifiedplatform.heartintune.common.Locale.prototype.setMr = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string it = 9;
 * @return {string}
 */
proto.unifiedplatform.heartintune.common.Locale.prototype.getIt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartintune.common.Locale} returns this
 */
proto.unifiedplatform.heartintune.common.Locale.prototype.setIt = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string ru = 10;
 * @return {string}
 */
proto.unifiedplatform.heartintune.common.Locale.prototype.getRu = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartintune.common.Locale} returns this
 */
proto.unifiedplatform.heartintune.common.Locale.prototype.setRu = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string es = 11;
 * @return {string}
 */
proto.unifiedplatform.heartintune.common.Locale.prototype.getEs = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartintune.common.Locale} returns this
 */
proto.unifiedplatform.heartintune.common.Locale.prototype.setEs = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string fr = 12;
 * @return {string}
 */
proto.unifiedplatform.heartintune.common.Locale.prototype.getFr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartintune.common.Locale} returns this
 */
proto.unifiedplatform.heartintune.common.Locale.prototype.setFr = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string pt = 13;
 * @return {string}
 */
proto.unifiedplatform.heartintune.common.Locale.prototype.getPt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartintune.common.Locale} returns this
 */
proto.unifiedplatform.heartintune.common.Locale.prototype.setPt = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string de = 14;
 * @return {string}
 */
proto.unifiedplatform.heartintune.common.Locale.prototype.getDe = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartintune.common.Locale} returns this
 */
proto.unifiedplatform.heartintune.common.Locale.prototype.setDe = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string bn = 15;
 * @return {string}
 */
proto.unifiedplatform.heartintune.common.Locale.prototype.getBn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartintune.common.Locale} returns this
 */
proto.unifiedplatform.heartintune.common.Locale.prototype.setBn = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string ml = 16;
 * @return {string}
 */
proto.unifiedplatform.heartintune.common.Locale.prototype.getMl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartintune.common.Locale} returns this
 */
proto.unifiedplatform.heartintune.common.Locale.prototype.setMl = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string uk = 17;
 * @return {string}
 */
proto.unifiedplatform.heartintune.common.Locale.prototype.getUk = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartintune.common.Locale} returns this
 */
proto.unifiedplatform.heartintune.common.Locale.prototype.setUk = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string od = 18;
 * @return {string}
 */
proto.unifiedplatform.heartintune.common.Locale.prototype.getOd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartintune.common.Locale} returns this
 */
proto.unifiedplatform.heartintune.common.Locale.prototype.setOd = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string en_us = 19;
 * @return {string}
 */
proto.unifiedplatform.heartintune.common.Locale.prototype.getEnUs = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartintune.common.Locale} returns this
 */
proto.unifiedplatform.heartintune.common.Locale.prototype.setEnUs = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string en_au = 20;
 * @return {string}
 */
proto.unifiedplatform.heartintune.common.Locale.prototype.getEnAu = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartintune.common.Locale} returns this
 */
proto.unifiedplatform.heartintune.common.Locale.prototype.setEnAu = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string pa = 21;
 * @return {string}
 */
proto.unifiedplatform.heartintune.common.Locale.prototype.getPa = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartintune.common.Locale} returns this
 */
proto.unifiedplatform.heartintune.common.Locale.prototype.setPa = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartintune.common.LocaleTypeLong.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartintune.common.LocaleTypeLong.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartintune.common.LocaleTypeLong} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartintune.common.LocaleTypeLong.toObject = function(includeInstance, msg) {
  var f, obj = {
    defaultcontent: jspb.Message.getFieldWithDefault(msg, 1, 0),
    en: jspb.Message.getFieldWithDefault(msg, 2, 0),
    hi: jspb.Message.getFieldWithDefault(msg, 3, 0),
    te: jspb.Message.getFieldWithDefault(msg, 4, 0),
    gu: jspb.Message.getFieldWithDefault(msg, 5, 0),
    ta: jspb.Message.getFieldWithDefault(msg, 6, 0),
    ka: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartintune.common.LocaleTypeLong}
 */
proto.unifiedplatform.heartintune.common.LocaleTypeLong.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartintune.common.LocaleTypeLong;
  return proto.unifiedplatform.heartintune.common.LocaleTypeLong.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartintune.common.LocaleTypeLong} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartintune.common.LocaleTypeLong}
 */
proto.unifiedplatform.heartintune.common.LocaleTypeLong.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDefaultcontent(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEn(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHi(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTe(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGu(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTa(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setKa(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartintune.common.LocaleTypeLong.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartintune.common.LocaleTypeLong.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartintune.common.LocaleTypeLong} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartintune.common.LocaleTypeLong.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDefaultcontent();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEn();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getHi();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getTe();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getGu();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getTa();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getKa();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
};


/**
 * optional int64 defaultContent = 1;
 * @return {number}
 */
proto.unifiedplatform.heartintune.common.LocaleTypeLong.prototype.getDefaultcontent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartintune.common.LocaleTypeLong} returns this
 */
proto.unifiedplatform.heartintune.common.LocaleTypeLong.prototype.setDefaultcontent = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 en = 2;
 * @return {number}
 */
proto.unifiedplatform.heartintune.common.LocaleTypeLong.prototype.getEn = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartintune.common.LocaleTypeLong} returns this
 */
proto.unifiedplatform.heartintune.common.LocaleTypeLong.prototype.setEn = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 hi = 3;
 * @return {number}
 */
proto.unifiedplatform.heartintune.common.LocaleTypeLong.prototype.getHi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartintune.common.LocaleTypeLong} returns this
 */
proto.unifiedplatform.heartintune.common.LocaleTypeLong.prototype.setHi = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 te = 4;
 * @return {number}
 */
proto.unifiedplatform.heartintune.common.LocaleTypeLong.prototype.getTe = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartintune.common.LocaleTypeLong} returns this
 */
proto.unifiedplatform.heartintune.common.LocaleTypeLong.prototype.setTe = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 gu = 5;
 * @return {number}
 */
proto.unifiedplatform.heartintune.common.LocaleTypeLong.prototype.getGu = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartintune.common.LocaleTypeLong} returns this
 */
proto.unifiedplatform.heartintune.common.LocaleTypeLong.prototype.setGu = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 ta = 6;
 * @return {number}
 */
proto.unifiedplatform.heartintune.common.LocaleTypeLong.prototype.getTa = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartintune.common.LocaleTypeLong} returns this
 */
proto.unifiedplatform.heartintune.common.LocaleTypeLong.prototype.setTa = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 ka = 7;
 * @return {number}
 */
proto.unifiedplatform.heartintune.common.LocaleTypeLong.prototype.getKa = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartintune.common.LocaleTypeLong} returns this
 */
proto.unifiedplatform.heartintune.common.LocaleTypeLong.prototype.setKa = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartintune.common.RequestPageable.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartintune.common.RequestPageable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartintune.common.RequestPageable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartintune.common.RequestPageable.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagesize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pagenumber: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartintune.common.RequestPageable}
 */
proto.unifiedplatform.heartintune.common.RequestPageable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartintune.common.RequestPageable;
  return proto.unifiedplatform.heartintune.common.RequestPageable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartintune.common.RequestPageable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartintune.common.RequestPageable}
 */
proto.unifiedplatform.heartintune.common.RequestPageable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPagesize(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPagenumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartintune.common.RequestPageable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartintune.common.RequestPageable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartintune.common.RequestPageable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartintune.common.RequestPageable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagesize();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getPagenumber();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 pageSize = 1;
 * @return {number}
 */
proto.unifiedplatform.heartintune.common.RequestPageable.prototype.getPagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartintune.common.RequestPageable} returns this
 */
proto.unifiedplatform.heartintune.common.RequestPageable.prototype.setPagesize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 pageNumber = 2;
 * @return {number}
 */
proto.unifiedplatform.heartintune.common.RequestPageable.prototype.getPagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartintune.common.RequestPageable} returns this
 */
proto.unifiedplatform.heartintune.common.RequestPageable.prototype.setPagenumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartintune.common.ResponsePageable.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartintune.common.ResponsePageable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartintune.common.ResponsePageable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartintune.common.ResponsePageable.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalcount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    selectedpagesize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    selectedpagenumber: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartintune.common.ResponsePageable}
 */
proto.unifiedplatform.heartintune.common.ResponsePageable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartintune.common.ResponsePageable;
  return proto.unifiedplatform.heartintune.common.ResponsePageable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartintune.common.ResponsePageable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartintune.common.ResponsePageable}
 */
proto.unifiedplatform.heartintune.common.ResponsePageable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalcount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSelectedpagesize(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSelectedpagenumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartintune.common.ResponsePageable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartintune.common.ResponsePageable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartintune.common.ResponsePageable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartintune.common.ResponsePageable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalcount();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSelectedpagesize();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getSelectedpagenumber();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional int64 totalCount = 1;
 * @return {number}
 */
proto.unifiedplatform.heartintune.common.ResponsePageable.prototype.getTotalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartintune.common.ResponsePageable} returns this
 */
proto.unifiedplatform.heartintune.common.ResponsePageable.prototype.setTotalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 selectedPageSize = 2;
 * @return {number}
 */
proto.unifiedplatform.heartintune.common.ResponsePageable.prototype.getSelectedpagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartintune.common.ResponsePageable} returns this
 */
proto.unifiedplatform.heartintune.common.ResponsePageable.prototype.setSelectedpagesize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 selectedPageNumber = 3;
 * @return {number}
 */
proto.unifiedplatform.heartintune.common.ResponsePageable.prototype.getSelectedpagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartintune.common.ResponsePageable} returns this
 */
proto.unifiedplatform.heartintune.common.ResponsePageable.prototype.setSelectedpagenumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.unifiedplatform.heartintune.common.MeditationType = {
  UNKNOWN_MEDITATION_TYPE: 0,
  MEDITATE_WITH_PURPOSE: 1,
  MEDITATE_WITH_GUIDANCE: 2,
  MEDITATE_WITH_PRECEPTOR: 3,
  MEDITATE_ANY_RECORDED_CONTENT: 4,
  MEDITATE_WITH_GROUPS: 5,
  MEDITATE_WITH_CIRCLE: 6,
  MEDITATE_WITH_TRAINER: 7,
  MEDITATE_WITH_OFFLINE: 8
};

/**
 * @enum {number}
 */
proto.unifiedplatform.heartintune.common.UserType = {
  UNKNOWN_USER: 0,
  GUEST_USER: 1,
  REGISTERED_USER: 2
};

/**
 * @enum {number}
 */
proto.unifiedplatform.heartintune.common.LocaleType = {
  UNKNOWN_TYPE: 0,
  DEFAULT: 1,
  EN: 2,
  HI: 3,
  TE: 4,
  GU: 5,
  TA: 6,
  KA: 7,
  MR: 8,
  IT: 9,
  RU: 10,
  ES: 11,
  FR: 12,
  PT: 13,
  DE: 14,
  BN: 15,
  ML: 16,
  UK: 17,
  OD: 18,
  EN_US: 19,
  EN_AU: 20,
  PA: 21
};

/**
 * @enum {number}
 */
proto.unifiedplatform.heartintune.common.Rating = {
  UNKNOWN: 0,
  HAPPY: 1,
  NEUTRAL: 2,
  SAD: 3
};

/**
 * @enum {number}
 */
proto.unifiedplatform.heartintune.common.Activity = {
  UNKNOWN_ACTIVITY: 0,
  GUIDED_MEDITATION_ACTIVITY: 1,
  PURPOSE_MEDITATION_ACTIVITY: 2,
  LIVE_MEDITATION_ACTIVITY: 3,
  JOURNAL_ENTRY_ACTIVITY: 4,
  DONATION_MADE_ACTIVITY: 5,
  READ_ARTICLE_ACTIVITY: 6,
  OFFLINE_HEARTFULNESS_PRACTICE_ACTIVITY: 7,
  SOCIAL_MEDIA_ACTIONS_ACTIVITY: 8,
  SHARE_LIVE_MEDITATION_ACTIVITY: 9,
  REFER_APP_ACTIVITY: 10,
  MEDITATE_ON_YOUR_OWN_ACTIVITY: 11,
  GOALS_ACTIVITY: 12,
  GROUPS_ACTIVIVTY: 13,
  OTHERS_ACITIVITY: 14,
  CIRCLE_INVITATION_ACTIVITY: 15,
  CIRCLE_ACTIVITY_INVITATION_ACTIVITY: 16,
  THREE_DAYS_MASTER_CLASS_ACTIVITY: 17
};

/**
 * @enum {number}
 */
proto.unifiedplatform.heartintune.common.LiveMeditationRating = {
  UNKNOWN_RATING: 0,
  SAD_RATING: 1,
  NEUTRAL_RATING: 2,
  HAPPY_RATING: 3,
  RATING_FOUR: 4,
  RATING_FIVE: 5
};

/**
 * @enum {number}
 */
proto.unifiedplatform.heartintune.common.GoalCategory = {
  UNKNOWN_GOAL_TYPE: 0,
  PURPOSEFUL_MEDITATION: 1,
  LIVE_MEDITATION_GOAL: 2,
  MEDITATION_STREAK_GOAL: 3,
  OFFLINE_HEARTFULNESS_PRACTICE: 4,
  JOURNAL_OBSERVATIONS: 5,
  SOCIAL_MEDIA_ACTIONS: 6,
  SHARE_LIVE_MEDITATION: 7,
  REFER_APP: 8,
  MEDITATE_ON_YOUR_OWN: 9,
  COMPOSITE_GOAL: 10,
  GUIDED_MEDITATION: 11
};

/**
 * @enum {number}
 */
proto.unifiedplatform.heartintune.common.BhandaraHelpLineType = {
  UNKNOWN_HELP_LINE: 0,
  LOST: 1,
  FOUND: 2,
  REPORT_ISSUE: 3
};

/**
 * @enum {number}
 */
proto.unifiedplatform.heartintune.common.PublishContent = {
  UNKNOWN_CONTENT: 0,
  PUBLISH: 1,
  UNPUBLISH: 2
};

/**
 * @enum {number}
 */
proto.unifiedplatform.heartintune.common.JournalType = {
  GENERAL: 0,
  MEDITATION: 2,
  MOODS: 1,
  OFFLINE_JOURNAL: 3
};

/**
 * @enum {number}
 */
proto.unifiedplatform.heartintune.common.GoalStatus = {
  UNKNOWN_STATUS: 0,
  COMPLETED: 1,
  IN_PROGRESS: 2,
  MISSED: 3
};

/**
 * @enum {number}
 */
proto.unifiedplatform.heartintune.common.MoodTriggerType = {
  UNKNOWN_TRIGGER: 0,
  WORK: 1,
  RELATIONSHIP: 2,
  FRIENDS: 3,
  HEALTH: 4,
  OTHER: 5
};

/**
 * @enum {number}
 */
proto.unifiedplatform.heartintune.common.ReminderType = {
  UNKNOWN_REMINDER: 0,
  DAILY_SESSION: 1,
  MOOD_MORNING: 2,
  MOOD_AFTERNOON: 3,
  MOOD_EVENING: 4,
  WAKE_UP: 5,
  BED_TIME: 6
};

/**
 * @enum {number}
 */
proto.unifiedplatform.heartintune.common.SharedContent = {
  UNKNOWN_SHARED_CONTENT: 0,
  APP: 1,
  GOAL: 2,
  LIVE_SESSION: 3,
  ACHIEVEMENTS: 4
};

/**
 * @enum {number}
 */
proto.unifiedplatform.heartintune.common.SharedVia = {
  UNKNOWN_SOCIAL_MEDIA: 0,
  FACEBOOK: 1,
  INSTAGRAM: 2,
  TWITTER: 3,
  YOUTUBE: 4,
  WHATSAPP: 5,
  LINKEDIN: 6,
  EMAIL: 7,
  TEXT: 8,
  OTHERS: 9
};

/**
 * @enum {number}
 */
proto.unifiedplatform.heartintune.common.ContentCategory = {
  UNKNOWN_CONTENT_CATEGORY: 0,
  ARTICLE: 1,
  BLOGS: 2,
  BOOKS: 3,
  VIDEO: 4,
  STORY: 5,
  PDF_ARTICLE: 6,
  YOUTUBE_VIDEOS: 7,
  YATRA_GARDEN: 8,
  DAAJI_MESSAGES: 9
};

/**
 * @enum {number}
 */
proto.unifiedplatform.heartintune.common.BackGroundType = {
  UNKNOWN_BACKGROUND: 0,
  IMAGE: 1,
  MUSIC: 2
};

/**
 * @enum {number}
 */
proto.unifiedplatform.heartintune.common.LogMeditation = {
  INTERNAL_APP: 0,
  EXTERNAL_APP: 1,
  OFFLINE: 2
};

/**
 * @enum {number}
 */
proto.unifiedplatform.heartintune.common.VideoCategory = {
  UNKNOWN_VIDEO_CATEGORY: 0,
  FEATURED: 1,
  DAAJI_TALKS: 2,
  ALL_VIDEOS: 3,
  WHY_I_MEDITATE: 4,
  HEARTFULNESS_PRACTICES: 5
};

/**
 * @enum {number}
 */
proto.unifiedplatform.heartintune.common.FeaturedContent = {
  UNKNOWN_FEATURED_CONTENT: 0,
  FEATURED_CONTENT: 1,
  ALL_CONTENT: 2
};

/**
 * @enum {number}
 */
proto.unifiedplatform.heartintune.common.MoodType = {
  UNKNOWN_MOOD_TYPE: 0,
  MOOD_HAPPY: 1,
  MOOD_SAD: 2
};

/**
 * @enum {number}
 */
proto.unifiedplatform.heartintune.common.Screens = {
  HOME_SCREEN: 0,
  LIVE_SESSION_DETAILS_SCREEN: 1,
  MOODS_SUMMERY_SCREEN: 2,
  CONTRIBUTE_SCREEN: 3,
  HEART_SPOT_SCREEN: 4
};

/**
 * @enum {number}
 */
proto.unifiedplatform.heartintune.common.Discover = {
  RESOURCES: 0,
  MIND: 1,
  BODY: 2,
  SPIRIT: 3
};

/**
 * @enum {number}
 */
proto.unifiedplatform.heartintune.common.PreRecordedType = {
  GROUP: 0,
  CIRCLE: 1,
  LIVESESSION: 2
};

/**
 * @enum {number}
 */
proto.unifiedplatform.heartintune.common.InviteStatus = {
  UNKNOWN_REQUEST: 0,
  ACCEPTED: 1,
  PENDING: 2,
  REJECTED: 3,
  LEFT: 4,
  DELETE: 5,
  HOLD: 6
};

/**
 * @enum {number}
 */
proto.unifiedplatform.heartintune.common.DayFrequency = {
  NEVER: 0,
  CUSTOM_DAY: 1,
  DAILY: 2,
  WEEKLY: 3
};

/**
 * @enum {number}
 */
proto.unifiedplatform.heartintune.common.VisibilityType = {
  UNKNOWN_VISIBILITY: 0,
  VISIBILITY_TO_ALL: 1,
  VISIBILITY_TO_FRIENDS: 2,
  VISIBILITY_TO_ME: 3
};

/**
 * @enum {number}
 */
proto.unifiedplatform.heartintune.common.DocumentCategory = {
  UNKNOWN_DOCUMENT_CATEGORY: 0,
  ANXIETY: 1,
  SLEEP: 2,
  MEDITATE: 3,
  CLEANING: 4,
  ALL_DOCUMENT_CATEGORY: 5
};

/**
 * @enum {number}
 */
proto.unifiedplatform.heartintune.common.EnableFeature = {
  UNKNOWN_FEATURE: 0,
  ENABLE_EMAIL: 1,
  ENABLE_WHATSAPP: 2,
  ENABLE_PUSH: 3,
  DISABLE_EMAIL: 4,
  DISABLE_PUSH: 5
};

/**
 * @enum {number}
 */
proto.unifiedplatform.heartintune.common.MeditationCategory = {
  ANY_MEDITATION: 0,
  PRAYER_MEDITATION: 1,
  CLEANING_MEDITATION: 2,
  GUIDANCE_MEDITATION: 3
};

/**
 * @enum {number}
 */
proto.unifiedplatform.heartintune.common.Channel = {
  UNKNOWN_CHANNEL: 0,
  SMS: 1,
  PUSH: 2,
  EMAIL_ADDRESS: 3
};

goog.object.extend(exports, proto.unifiedplatform.heartintune.common);
