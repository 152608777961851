// source: tracker.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var common_common_pb = require('./common/common_pb.js');
goog.object.extend(proto, common_common_pb);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.tracker.TrackerByDateAndUserId', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.tracker.TrackerMethod', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.tracker.TrackerResponseForGamification', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.tracker.Trackers', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo.displayName = 'proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.displayName = 'proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerByDateAndUserId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.tracker.TrackerByDateAndUserId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.tracker.TrackerByDateAndUserId.displayName = 'proto.unifiedplatform.heartIntuneProfile.tracker.TrackerByDateAndUserId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification.displayName = 'proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerResponseForGamification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.tracker.TrackerResponseForGamification.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.tracker.TrackerResponseForGamification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.tracker.TrackerResponseForGamification.displayName = 'proto.unifiedplatform.heartIntuneProfile.tracker.TrackerResponseForGamification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.tracker.Trackers = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.tracker.Trackers.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.tracker.Trackers, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.tracker.Trackers.displayName = 'proto.unifiedplatform.heartIntuneProfile.tracker.Trackers';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    meditationtype: jspb.Message.getFieldWithDefault(msg, 1, 0),
    startdate: (f = msg.getStartdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    enddate: (f = msg.getEnddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    playcount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    pausecount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    totalseconds: jspb.Message.getFieldWithDefault(msg, 6, 0),
    completedpercentage: jspb.Message.getFieldWithDefault(msg, 7, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 8, 0),
    meditationid: jspb.Message.getFieldWithDefault(msg, 9, 0),
    livesessionid: jspb.Message.getFieldWithDefault(msg, 10, 0),
    usergroupregister: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    respectiveid: jspb.Message.getFieldWithDefault(msg, 12, 0),
    logmeditationtype: jspb.Message.getFieldWithDefault(msg, 13, 0),
    meditationcategory: jspb.Message.getFieldWithDefault(msg, 14, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo;
  return proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.MeditationType} */ (reader.readEnum());
      msg.setMeditationtype(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartdate(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnddate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPlaycount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPausecount(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalseconds(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCompletedpercentage(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMeditationid(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLivesessionid(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUsergroupregister(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRespectiveid(value);
      break;
    case 13:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LogMeditation} */ (reader.readEnum());
      msg.setLogmeditationtype(value);
      break;
    case 14:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.MeditationCategory} */ (reader.readEnum());
      msg.setMeditationcategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeditationtype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getStartdate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnddate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPlaycount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getPausecount();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getTotalseconds();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getCompletedpercentage();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getMeditationid();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getLivesessionid();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getUsergroupregister();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getRespectiveid();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getLogmeditationtype();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getMeditationcategory();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
};


/**
 * optional unifiedplatform.heartintune.common.MeditationType meditationType = 1;
 * @return {!proto.unifiedplatform.heartintune.common.MeditationType}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo.prototype.getMeditationtype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.MeditationType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.MeditationType} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo.prototype.setMeditationtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp startDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo.prototype.getStartdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo} returns this
*/
proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo.prototype.setStartdate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo.prototype.clearStartdate = function() {
  return this.setStartdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo.prototype.hasStartdate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp endDate = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo.prototype.getEnddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo} returns this
*/
proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo.prototype.setEnddate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo.prototype.clearEnddate = function() {
  return this.setEnddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo.prototype.hasEnddate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 playCount = 4;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo.prototype.getPlaycount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo.prototype.setPlaycount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 pauseCount = 5;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo.prototype.getPausecount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo.prototype.setPausecount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 totalSeconds = 6;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo.prototype.getTotalseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo.prototype.setTotalseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 completedPercentage = 7;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo.prototype.getCompletedpercentage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo.prototype.setCompletedpercentage = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 userId = 8;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 meditationId = 9;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo.prototype.getMeditationid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo.prototype.setMeditationid = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 liveSessionId = 10;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo.prototype.getLivesessionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo.prototype.setLivesessionid = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional bool userGroupRegister = 11;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo.prototype.getUsergroupregister = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo.prototype.setUsergroupregister = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional int64 respectiveId = 12;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo.prototype.getRespectiveid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo.prototype.setRespectiveid = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional unifiedplatform.heartintune.common.LogMeditation logMeditationType = 13;
 * @return {!proto.unifiedplatform.heartintune.common.LogMeditation}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo.prototype.getLogmeditationtype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LogMeditation} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LogMeditation} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo.prototype.setLogmeditationtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional unifiedplatform.heartintune.common.MeditationCategory meditationCategory = 14;
 * @return {!proto.unifiedplatform.heartintune.common.MeditationCategory}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo.prototype.getMeditationcategory = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.MeditationCategory} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.MeditationCategory} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.tracker.RequestTrackerInfo.prototype.setMeditationcategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    meditationtype: jspb.Message.getFieldWithDefault(msg, 2, 0),
    startdate: (f = msg.getStartdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    enddate: (f = msg.getEnddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    playcount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    pausecount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    totalseconds: jspb.Message.getFieldWithDefault(msg, 7, 0),
    completedpercentage: jspb.Message.getFieldWithDefault(msg, 8, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 9, 0),
    meditationid: jspb.Message.getFieldWithDefault(msg, 10, 0),
    livesessionid: jspb.Message.getFieldWithDefault(msg, 11, 0),
    useractivity: jspb.Message.getFieldWithDefault(msg, 12, 0),
    logmeditationtype: jspb.Message.getFieldWithDefault(msg, 13, 0),
    meditationcategory: jspb.Message.getFieldWithDefault(msg, 14, 0),
    livemeditationrating: jspb.Message.getFieldWithDefault(msg, 15, 0),
    logmeditation: jspb.Message.getFieldWithDefault(msg, 16, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo;
  return proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.MeditationType} */ (reader.readEnum());
      msg.setMeditationtype(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartdate(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnddate(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPlaycount(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPausecount(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalseconds(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCompletedpercentage(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMeditationid(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLivesessionid(value);
      break;
    case 12:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.Activity} */ (reader.readEnum());
      msg.setUseractivity(value);
      break;
    case 13:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LogMeditation} */ (reader.readEnum());
      msg.setLogmeditationtype(value);
      break;
    case 14:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.MeditationCategory} */ (reader.readEnum());
      msg.setMeditationcategory(value);
      break;
    case 15:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LiveMeditationRating} */ (reader.readEnum());
      msg.setLivemeditationrating(value);
      break;
    case 16:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LogMeditation} */ (reader.readEnum());
      msg.setLogmeditation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMeditationtype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getStartdate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnddate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPlaycount();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getPausecount();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getTotalseconds();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getCompletedpercentage();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getMeditationid();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getLivesessionid();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getUseractivity();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getLogmeditationtype();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getMeditationcategory();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getLivemeditationrating();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getLogmeditation();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.MeditationType meditationType = 2;
 * @return {!proto.unifiedplatform.heartintune.common.MeditationType}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.prototype.getMeditationtype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.MeditationType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.MeditationType} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.prototype.setMeditationtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp startDate = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.prototype.getStartdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo} returns this
*/
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.prototype.setStartdate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.prototype.clearStartdate = function() {
  return this.setStartdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.prototype.hasStartdate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp endDate = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.prototype.getEnddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo} returns this
*/
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.prototype.setEnddate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.prototype.clearEnddate = function() {
  return this.setEnddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.prototype.hasEnddate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int64 playCount = 5;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.prototype.getPlaycount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.prototype.setPlaycount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 pauseCount = 6;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.prototype.getPausecount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.prototype.setPausecount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 totalSeconds = 7;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.prototype.getTotalseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.prototype.setTotalseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 completedPercentage = 8;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.prototype.getCompletedpercentage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.prototype.setCompletedpercentage = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 userId = 9;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 meditationId = 10;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.prototype.getMeditationid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.prototype.setMeditationid = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 liveSessionId = 11;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.prototype.getLivesessionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.prototype.setLivesessionid = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional unifiedplatform.heartintune.common.Activity userActivity = 12;
 * @return {!proto.unifiedplatform.heartintune.common.Activity}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.prototype.getUseractivity = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.Activity} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.Activity} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.prototype.setUseractivity = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional unifiedplatform.heartintune.common.LogMeditation logMeditationType = 13;
 * @return {!proto.unifiedplatform.heartintune.common.LogMeditation}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.prototype.getLogmeditationtype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LogMeditation} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LogMeditation} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.prototype.setLogmeditationtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional unifiedplatform.heartintune.common.MeditationCategory meditationCategory = 14;
 * @return {!proto.unifiedplatform.heartintune.common.MeditationCategory}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.prototype.getMeditationcategory = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.MeditationCategory} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.MeditationCategory} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.prototype.setMeditationcategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional unifiedplatform.heartintune.common.LiveMeditationRating liveMeditationRating = 15;
 * @return {!proto.unifiedplatform.heartintune.common.LiveMeditationRating}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.prototype.getLivemeditationrating = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LiveMeditationRating} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LiveMeditationRating} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.prototype.setLivemeditationrating = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * optional unifiedplatform.heartintune.common.LogMeditation logMeditation = 16;
 * @return {!proto.unifiedplatform.heartintune.common.LogMeditation}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.prototype.getLogmeditation = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LogMeditation} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LogMeditation} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.prototype.setLogmeditation = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerByDateAndUserId.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.tracker.TrackerByDateAndUserId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerByDateAndUserId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerByDateAndUserId.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    date: (f = msg.getDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerByDateAndUserId}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerByDateAndUserId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.tracker.TrackerByDateAndUserId;
  return proto.unifiedplatform.heartIntuneProfile.tracker.TrackerByDateAndUserId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerByDateAndUserId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerByDateAndUserId}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerByDateAndUserId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerByDateAndUserId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.tracker.TrackerByDateAndUserId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerByDateAndUserId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerByDateAndUserId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 userId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerByDateAndUserId.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerByDateAndUserId} returns this
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerByDateAndUserId.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp date = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerByDateAndUserId.prototype.getDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerByDateAndUserId} returns this
*/
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerByDateAndUserId.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerByDateAndUserId} returns this
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerByDateAndUserId.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerByDateAndUserId.prototype.hasDate = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification.toObject = function(includeInstance, msg) {
  var f, obj = {
    trackermethod: jspb.Message.getFieldWithDefault(msg, 1, 0),
    trackerDetails: (f = msg.getTrackerDetails()) && proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.toObject(includeInstance, f),
    trackerid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 4, 0),
    meditationcategory: jspb.Message.getFieldWithDefault(msg, 5, 0),
    startDateTime: (f = msg.getStartDateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endDateTime: (f = msg.getEndDateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    islogmeditation: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification;
  return proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerMethod} */ (reader.readEnum());
      msg.setTrackermethod(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.deserializeBinaryFromReader);
      msg.setTrackerDetails(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTrackerid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 5:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.MeditationCategory} */ (reader.readEnum());
      msg.setMeditationcategory(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartDateTime(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndDateTime(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIslogmeditation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrackermethod();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getTrackerDetails();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.serializeBinaryToWriter
    );
  }
  f = message.getTrackerid();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getMeditationcategory();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getStartDateTime();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndDateTime();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIslogmeditation();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional TrackerMethod trackerMethod = 1;
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerMethod}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification.prototype.getTrackermethod = function() {
  return /** @type {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerMethod} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerMethod} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification} returns this
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification.prototype.setTrackermethod = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional TrackerInfo tracker_details = 2;
 * @return {?proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification.prototype.getTrackerDetails = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo, 2));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification} returns this
*/
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification.prototype.setTrackerDetails = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification} returns this
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification.prototype.clearTrackerDetails = function() {
  return this.setTrackerDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification.prototype.hasTrackerDetails = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 trackerId = 3;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification.prototype.getTrackerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification} returns this
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification.prototype.setTrackerid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 userId = 4;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification} returns this
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional unifiedplatform.heartintune.common.MeditationCategory meditationCategory = 5;
 * @return {!proto.unifiedplatform.heartintune.common.MeditationCategory}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification.prototype.getMeditationcategory = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.MeditationCategory} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.MeditationCategory} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification} returns this
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification.prototype.setMeditationcategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp start_date_time = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification.prototype.getStartDateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification} returns this
*/
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification.prototype.setStartDateTime = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification} returns this
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification.prototype.clearStartDateTime = function() {
  return this.setStartDateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification.prototype.hasStartDateTime = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp end_date_time = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification.prototype.getEndDateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification} returns this
*/
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification.prototype.setEndDateTime = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification} returns this
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification.prototype.clearEndDateTime = function() {
  return this.setEndDateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification.prototype.hasEndDateTime = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool isLogMeditation = 8;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification.prototype.getIslogmeditation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification} returns this
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerRequestFromGamification.prototype.setIslogmeditation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerResponseForGamification.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerResponseForGamification.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.tracker.TrackerResponseForGamification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerResponseForGamification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerResponseForGamification.toObject = function(includeInstance, msg) {
  var f, obj = {
    trackerinfo: (f = msg.getTrackerinfo()) && proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.toObject(includeInstance, f),
    trackersList: jspb.Message.toObjectList(msg.getTrackersList(),
    proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.toObject, includeInstance),
    countorsum: jspb.Message.getFieldWithDefault(msg, 3, 0),
    badgeid: jspb.Message.getFieldWithDefault(msg, 4, 0),
    badgecount: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerResponseForGamification}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerResponseForGamification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.tracker.TrackerResponseForGamification;
  return proto.unifiedplatform.heartIntuneProfile.tracker.TrackerResponseForGamification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerResponseForGamification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerResponseForGamification}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerResponseForGamification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.deserializeBinaryFromReader);
      msg.setTrackerinfo(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.deserializeBinaryFromReader);
      msg.addTrackers(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCountorsum(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBadgeid(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBadgecount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerResponseForGamification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.tracker.TrackerResponseForGamification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerResponseForGamification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerResponseForGamification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrackerinfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.serializeBinaryToWriter
    );
  }
  f = message.getTrackersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.serializeBinaryToWriter
    );
  }
  f = message.getCountorsum();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getBadgeid();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getBadgecount();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional TrackerInfo trackerInfo = 1;
 * @return {?proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerResponseForGamification.prototype.getTrackerinfo = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo, 1));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerResponseForGamification} returns this
*/
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerResponseForGamification.prototype.setTrackerinfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerResponseForGamification} returns this
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerResponseForGamification.prototype.clearTrackerinfo = function() {
  return this.setTrackerinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerResponseForGamification.prototype.hasTrackerinfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated TrackerInfo trackers = 2;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo>}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerResponseForGamification.prototype.getTrackersList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo, 2));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerResponseForGamification} returns this
*/
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerResponseForGamification.prototype.setTrackersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerResponseForGamification.prototype.addTrackers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerResponseForGamification} returns this
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerResponseForGamification.prototype.clearTrackersList = function() {
  return this.setTrackersList([]);
};


/**
 * optional int64 countOrSum = 3;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerResponseForGamification.prototype.getCountorsum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerResponseForGamification} returns this
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerResponseForGamification.prototype.setCountorsum = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 badgeId = 4;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerResponseForGamification.prototype.getBadgeid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerResponseForGamification} returns this
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerResponseForGamification.prototype.setBadgeid = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 badgeCount = 5;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerResponseForGamification.prototype.getBadgecount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerResponseForGamification} returns this
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerResponseForGamification.prototype.setBadgecount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.tracker.Trackers.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.Trackers.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.tracker.Trackers.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.tracker.Trackers} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.tracker.Trackers.toObject = function(includeInstance, msg) {
  var f, obj = {
    trackersList: jspb.Message.toObjectList(msg.getTrackersList(),
    proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.Trackers}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.Trackers.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.tracker.Trackers;
  return proto.unifiedplatform.heartIntuneProfile.tracker.Trackers.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.tracker.Trackers} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.Trackers}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.Trackers.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.deserializeBinaryFromReader);
      msg.addTrackers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.Trackers.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.tracker.Trackers.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.tracker.Trackers} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.tracker.Trackers.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrackersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TrackerInfo trackers = 1;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo>}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.Trackers.prototype.getTrackersList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.Trackers} returns this
*/
proto.unifiedplatform.heartIntuneProfile.tracker.Trackers.prototype.setTrackersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.Trackers.prototype.addTrackers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.heartIntuneProfile.tracker.TrackerInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.tracker.Trackers} returns this
 */
proto.unifiedplatform.heartIntuneProfile.tracker.Trackers.prototype.clearTrackersList = function() {
  return this.setTrackersList([]);
};


/**
 * @enum {number}
 */
proto.unifiedplatform.heartIntuneProfile.tracker.TrackerMethod = {
  SAVE_UPDATE_TRACKER: 0,
  FIND_TRACKER_BY_USER_AND_START_DATE_BETWEEN: 1,
  FIND_TRACKER_FOR_DATE: 2,
  TOTAL_SECONDS_FOR_DATE: 3
};

goog.object.extend(exports, proto.unifiedplatform.heartIntuneProfile.tracker);
