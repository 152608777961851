import VideoLibraryOutlinedIcon from "@mui/icons-material/VideoLibraryOutlined";
import {
  BarChartRounded as DashboardIcon,
  // SpeakerOutlined as GuidedMeditationIcon,
  LiveTvOutlined as LiveMeditationIcon,
  RecordVoiceOverOutlined as CoachesIcon,
  // GroupOutlined as MeditatorsIcon,
  //FilterVintageOutlined as GolasIcon,
  // TimelineRounded as AnalyticsIcon,
  // SettingsOutlined as SettingsIcon,
  FlutterDash as ProgramIcon,
  AddTask as TaskIcon,
} from "@mui/icons-material";
import GolfCourseIcon from "@mui/icons-material/GolfCourse";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import EventIcon from "@mui/icons-material/Event";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import CampaignIcon from "@mui/icons-material/Campaign";
import PaymentIcon from "@mui/icons-material/Payment";
import MoodIcon from "@mui/icons-material/Mood";
import CategoryIcon from "@mui/icons-material/Category";
import VideoIcon from "@mui/icons-material/SlowMotionVideo";
import ArticleIcon from "@mui/icons-material/Article";
import BlogIcon from "@mui/icons-material/Newspaper";
import QuoteIcon from "@mui/icons-material/FormatQuote";
import FilterAltRoundedIcon from "@mui/icons-material/FilterAltRounded";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import EventNoteIcon from "@mui/icons-material/EventNote";
import BookIcon from "@mui/icons-material/MenuBook";
import ArchiveIcon from "@mui/icons-material/Archive";
import StyleIcon from "@mui/icons-material/Style";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";

// UNKNOWN_ROLE = 0;
// SUPER_ADMIN = 1;
// ADMIN = 2;
// CONTENT_OWNER = 3;
// USER = 4;
// PRECEPTOR = 5;
// CUSTOM_ROLE = 6;

// Super Admin(1) - -> All tabs
// Admin(2) --> except users, all tabs
// Preceptor(5) --> Only Live Meditation Tab
// Content Owner -->(3) Only Meditation with Guidance, Meditate with Purpose and Goals
// For Users and custom Role(4,6) - error saying that not authorized

export const navItems = [
  {
    href: "/app/users",
    icon: PersonAddIcon,
    title: "User",
    role: [1],
    category: "User Management",
  },
  {
    href: "/app/dashboard",
    icon: DashboardIcon,
    title: "Gems",
    category: "Gamification",
  },
  // {
  //   href: "/app/guided",
  //   icon: GuidedMeditationIcon,
  //   title: "Meditate With Guidance",
  //   role: [1, 2, 3],
  //   category: "Meditations",
  // },

  {
    href: "/app/purpose",
    icon: VideoLibraryOutlinedIcon,
    title: "Meditations",
    role: [1, 2, 3],
    category: "Guided Meditations",
  },
  {
    href: "/app/meditation_background",
    icon: VideoLibraryOutlinedIcon,
    title: "Meditations Background",
    role: [1, 2, 3],
    category: "Guided Meditations",
  },
  {
    href: "/app/purpose_categories",
    icon: CategoryIcon,
    title: "Purpose Category",
    role: [1, 2, 3],
    category: "Guided Meditations",
  },
  {
    href: "/app/guided-meditation-tags",
    icon: StyleIcon,
    title: "Tags",
    role: [1, 2],
    category: "Guided Meditations",
  },

  {
    href: "/app/live",
    icon: LiveMeditationIcon,
    title: "Live Sessions",
    role: [1, 2, 5],
    category: "Live Sessions",
  },
  {
    href: "/app/define_session",
    icon: LiveMeditationIcon,
    title: "Define Session",
    role: [1, 2],
    category: "Live Sessions",
  },
  {
    href: "/app/assign_session",
    icon: LiveMeditationIcon,
    title: "Assign Session",
    role: [1, 2],
    category: "Live Sessions",
  },
  {
    href: "/app/live-session-tags",
    icon: StyleIcon,
    title: "Tags",
    role: [1, 2],
    category: "Live Sessions",
  },
  // {
  //   href: "/app/benefits",
  //   icon: RedeemIcon,
  //   title: "Benefits",
  // },
  {
    href: "/app/coaches",
    icon: CoachesIcon,
    title: "Coaches",
    role: [1, 2],
    category: "User Management",
  },
  {
    href: "/app/faq",
    icon: QuestionAnswerIcon,
    title: "FAQ",
    role: [1, 2],
    category: "User Management",
  },

  // {
  //   href: "/app/meditators",
  //   icon: MeditatorsIcon,
  //   title: "Meditators",
  // },
  {
    href: "/app/goals",
    icon: GolfCourseIcon,
    title: "Goals",
    role: [1, 2, 3],
    category: "Gamification",
  },
  {
    href: "/app/goalsv2",
    icon: GolfCourseIcon,
    title: "Goals V2",
    role: [1, 2, 3],
    category: "Gamification",
  },
  {
    href: "/app/layout",
    icon: SelfImprovementIcon,
    title: "Layout Manager",
    role: [1, 2, 3],
    category: "Gamification",
  },
  {
    href: "/app/donations",
    icon: PaymentIcon,
    title: "Donations",
    role: [1, 2],
    category: "Gamification",
  },
  {
    href: "/app/donations_list",
    icon: PaymentIcon,
    title: "Donations List",
    role: [1, 2],
    category: "Gamification",
  },
  {
    href: "/app/moods",
    icon: MoodIcon,
    title: "Moods",
    role: [1, 2],
    category: "Gamification",
  },
  {
    href: "/app/tags",
    icon: StyleIcon,
    title: "Tags",
    role: [1, 2],
    category: "Gamification",
  },
  {
    href: "/app/triggers",
    icon: MoodIcon,
    title: "Moods triggers",
    role: [1, 2],
    category: "Gamification",
  },
  {
    href: "/app/articles",
    icon: ArticleIcon,
    title: "Articles",
    role: [1, 2],
    category: "Resources",
  },
  // !!!!! blogs are hided for sometime
  // {
  //   href: "/app/blogs",
  //   icon: BlogIcon,
  //   title: "Blogs",
  //   role: [1, 2],
  //   category: "Resources",
  // },
  {
    href: "/app/quotes",
    icon: QuoteIcon,
    title: "Quotes",
    role: [1, 2],
    category: "Resources",
  },
  {
    href: "/app/videos",
    icon: VideoIcon,
    title: "Videos",
    role: [1, 2],
    category: "Resources",
  },
  {
    href: "/app/filters",
    icon: FilterAltRoundedIcon,
    title: "Filters",
    role: [1, 2],
    category: "Resources",
  },

  {
    href: "/app/pdfs",
    icon: PictureAsPdfIcon,
    title: "PDFs",
    role: [1, 2],
    category: "HeartsApp",
  },
  {
    href: "/app/heartsapp_videos",
    icon: PlayCircleIcon,
    title: "Videos",
    role: [1, 2],
    category: "HeartsApp",
  },
  {
    href: "/app/self_development_articles",
    icon: ArticleIcon,
    title: "Articles",
    role: [1, 2],
    category: "Self Development",
  },
  {
    href: "/app/self_development_stories",
    icon: BlogIcon,
    title: "Stories",
    role: [1, 2],
    category: "Self Development",
  },
  {
    href: "/app/self_development_videos",
    icon: VideoIcon,
    title: "Videos",
    role: [1, 2],
    category: "Self Development",
  },
  {
    href: "/app/self_development_books",
    icon: BookIcon,
    title: "Books",
    role: [1, 2],
    category: "Self Development",
  },
  // {
  //   href: "/app/analytics",
  //   icon: AnalyticsIcon,
  //   title: "Analytics",
  // },
  // {
  //   href: "/app/settings",
  //   icon: SettingsIcon,
  //   title: "Settings",
  // },
  // {
  //   href: "/app/notifications",
  //   icon: NotificationsIcon,
  //   title: "Notifications",
  // },
  {
    href: "/app/pre-recorded-videos",
    icon: OndemandVideoIcon,
    title: "Videos",
    category: "Groups",
  },
  {
    href: "/app/schedule-pre-recorded-videos",
    icon: EventNoteIcon,
    title: "Schedule",
    category: "Groups",
  },
  {
    href: "/app/archive",
    icon: ArchiveIcon,
    title: "Archive",
    role: [1],
    category: "Bhandara",
  },
  {
    href: "/app/bhandara-tags",
    icon: ArchiveIcon,
    title: "Tags",
    role: [1],
    category: "Bhandara",
  },
  {
    href: "/app/daaji-message",
    icon: ArchiveIcon,
    title: "Daaji's Message",
    role: [1],
    category: "Bhandara",
  },
  {
    href: "/app/yatra-garden",
    icon: ArchiveIcon,
    title: "Yatra Garden",
    role: [1],
    category: "Bhandara",
  },
];
const superAdminItems = [
  {
    href: "/app/users",
    icon: PersonAddIcon,
    title: "User",
    category: "User Management",
  },
  {
    href: "/app/dashboard",
    icon: DashboardIcon,
    title: "Gems",
    category: "Gamification",
  },
  // {
  //   href: "/app/guided",
  //   icon: GuidedMeditationIcon,
  //   title: "Meditate With Guidance",
  //   category: "Meditations",
  // },

  {
    href: "/app/purpose",
    icon: VideoLibraryOutlinedIcon,
    title: "Meditations",
    category: "Guided Meditations",
  },
  {
    href: "/app/meditation_background",
    icon: VideoLibraryOutlinedIcon,
    title: "Meditations Background",
    role: [1, 2, 3],
    category: "Guided Meditations",
  },

  {
    href: "/app/purpose_categories",
    icon: CategoryIcon,
    title: "Purpose Category",
    role: [1, 2, 3],
    category: "Guided Meditations",
  },
  {
    href: "/app/guided-meditation-tags",
    icon: StyleIcon,
    title: "Tags",
    role: [1, 2],
    category: "Guided Meditations",
  },

  {
    href: "/app/live",
    icon: LiveMeditationIcon,
    title: "Live Sessions",
    category: "Live Sessions",
  },
  {
    href: "/app/define_session",
    icon: LiveMeditationIcon,
    title: "Define Session",
    category: "Live Sessions",
  },
  {
    href: "/app/assign_session",
    icon: LiveMeditationIcon,
    title: "Assign Session",
    category: "Live Sessions",
  },
  {
    href: "/app/live-session-tags",
    icon: StyleIcon,
    title: "Tags",
    role: [1, 2],
    category: "Live Sessions",
  },
  // {
  //   href: "/app/benefits",
  //   icon: RedeemIcon,
  //   title: "Benefits",
  // },
  {
    href: "/app/coaches",
    icon: CoachesIcon,
    title: "Coaches",
    category: "User Management",
  },
  {
    href: "/app/faq",
    icon: QuestionAnswerIcon,
    title: "FAQ",
    role: [1, 2],
    category: "User Management",
  },

  // {
  //   href: "/app/meditators",
  //   icon: MeditatorsIcon,
  //   title: "Meditators",
  // },
  {
    href: "/app/goals",
    icon: GolfCourseIcon,
    title: "Goals",
    category: "Gamification",
  },
  {
    href: "/app/goalsv2",
    icon: GolfCourseIcon,
    title: "Goals V2",
    category: "Gamification",
  },
  {
    href: "/app/layout",
    icon: SelfImprovementIcon,
    title: "Layout Manager",
    category: "Gamification",
  },
  {
    href: "/app/tags",
    icon: StyleIcon,
    title: "Tags",
    role: [1, 2],
    category: "Gamification",
  },
  {
    href: "/app/homebanner",
    icon: ArchiveIcon,
    title: "Home Banner",
    category: "Bhandara",
  },
  {
    href: "/app/events",
    icon: EventIcon,
    title: "Events",
    category: "Bhandara",
  },
  {
    href: "/app/locations",
    icon: LocationOnIcon,
    title: "Locations",
    category: "Bhandara",
  },
  {
    href: "/app/announcements",
    icon: AnnouncementIcon,
    title: "Announcements",
    category: "Bhandara",
  },
  {
    href: "/app/lnf",
    icon: CampaignIcon,
    title: "Lost & Found",
    category: "Bhandara",
  },
  {
    href: "/app/essentials",
    icon: SelfImprovementIcon,
    title: "Essential Services",
    category: "Bhandara",
  },
  {
    href: "/app/donations",
    icon: PaymentIcon,
    title: "Donations",
    role: [1, 2],
    category: "Gamification",
  },
  {
    href: "/app/donations_list",
    icon: PaymentIcon,
    title: "Donations List",
    role: [1, 2],
    category: "Gamification",
  },
  {
    href: "/app/pdfs",
    icon: PictureAsPdfIcon,
    title: "PDFs",
    role: [1, 2],
    category: "HeartsApp",
  },
  {
    href: "/app/heartsapp_videos",
    icon: PlayCircleIcon,
    title: "Videos",
    role: [1, 2],
    category: "HeartsApp",
  },
  {
    href: "/app/moods",
    icon: MoodIcon,
    title: "Moods",
    role: [1, 2],
    category: "Gamification",
  },
  {
    href: "/app/triggers",
    icon: MoodIcon,
    title: "Moods triggers",
    role: [1, 2],
    category: "Gamification",
  },
  {
    href: "/app/articles",
    icon: ArticleIcon,
    title: "Articles",
    role: [1, 2],
    category: "Resources",
  },
  // !!!!! blogs are hided for sometime
  // {
  //   href: "/app/blogs",
  //   icon: BlogIcon,
  //   title: "Blogs",
  //   role: [1, 2],
  //   category: "Resources",
  // },
  {
    href: "/app/quotes",
    icon: QuoteIcon,
    title: "Quotes",
    role: [1, 2],
    category: "Resources",
  },
  {
    href: "/app/videos",
    icon: VideoIcon,
    title: "Videos",
    role: [1, 2],
    category: "Resources",
  },
  {
    href: "/app/filters",
    icon: FilterAltRoundedIcon,
    title: "Filters",
    role: [1, 2],
    category: "Resources",
  },
  {
    href: "/app/self_development_articles",
    icon: ArticleIcon,
    title: "Articles",
    category: "Self Development",
  },
  {
    href: "/app/self_development_stories",
    icon: BlogIcon,
    title: "Stories",
    category: "Self Development",
  },
  {
    href: "/app/self_development_videos",
    icon: VideoIcon,
    title: "Videos",
    category: "Self Development",
  },
  {
    href: "/app/self_development_books",
    icon: BookIcon,
    title: "Books",
    category: "Self Development",
  },
  {
    href: "/app/pre-recorded-videos",
    icon: OndemandVideoIcon,
    title: "Videos",
    category: "Groups",
  },
  {
    href: "/app/schedule-pre-recorded-videos",
    icon: EventNoteIcon,
    title: "Schedule",
    category: "Groups",
  },
  {
    href: "/app/archive",
    icon: ArchiveIcon,
    title: "Archive",
    category: "Bhandara",
  },
  {
    href: "/app/bhandara-tags",
    icon: ArchiveIcon,
    title: "Tags",
    category: "Bhandara",
  },
  {
    href: "/app/daaji's-message",
    icon: ArchiveIcon,
    title: "Daaji's Message",
    category: "Bhandara",
  },
  {
    href: "/app/yatra-garden",
    icon: ArchiveIcon,
    title: "Yatra Garden",
    category: "Bhandara",
  },
  {
    href: "/app/theme",
    icon: ProgramIcon,
    title: "Theme",
    category: "Program",
  },
  {
    href: "/app/task",
    icon: TaskIcon,
    title: "Task",
    category: "Program",
  },
];
export const adminItems = [
  {
    href: "/app/dashboard",
    icon: DashboardIcon,
    title: "Gems",
    category: "Gamification",
  },
  // {
  //   href: "/app/guided",
  //   icon: GuidedMeditationIcon,
  //   title: "Meditate With Guidance",
  //   category: "Meditations",
  // },

  {
    href: "/app/purpose",
    icon: VideoLibraryOutlinedIcon,
    title: "Meditations",
    category: "Guided Meditations",
  },
  {
    href: "/app/meditation_background",
    icon: VideoLibraryOutlinedIcon,
    title: "Meditations Background",
    role: [1, 2, 3],
    category: "Guided Meditations",
  },

  {
    href: "/app/purpose_categories",
    icon: CategoryIcon,
    title: "Purpose Category",
    role: [1, 2, 3],
    category: "Guided Meditations",
  },
  {
    href: "/app/guided-meditation-tags",
    icon: StyleIcon,
    title: "Tags",
    role: [1, 2],
    category: "Guided Meditations",
  },

  {
    href: "/app/live",
    icon: LiveMeditationIcon,
    title: "Live Sessions",
    category: "User Management",
  },
  {
    href: "/app/define_session",
    icon: LiveMeditationIcon,
    title: "Define Session",
    category: "Live Sessions",
  },
  {
    href: "/app/assign_session",
    icon: LiveMeditationIcon,
    title: "Assign Session",
    category: "Live Sessions",
  },
  {
    href: "/app/live-session-tags",
    icon: StyleIcon,
    title: "Tags",
    role: [1, 2],
    category: "Live Sessions",
  },
  // {
  //   href: "/app/benefits",
  //   icon: RedeemIcon,
  //   title: "Benefits",
  // },
  {
    href: "/app/coaches",
    icon: CoachesIcon,
    title: "Coaches",
    category: "User Management",
  },
  {
    href: "/app/faq",
    icon: QuestionAnswerIcon,
    title: "FAQ",
    role: [1, 2],
    category: "User Management",
  },
  {
    href: "/app/layout",
    icon: SelfImprovementIcon,
    title: "Layout Manager",
    category: "Gamification",
  },

  // {
  //   href: "/app/meditators",
  //   icon: MeditatorsIcon,
  //   title: "Meditators",
  // },
  {
    href: "/app/goals",
    icon: GolfCourseIcon,
    title: "Goals",
    category: "Gamifications",
  },
  {
    href: "/app/goalsv2",
    icon: GolfCourseIcon,
    title: "Goals V2",
    category: "Gamifications",
  },
  {
    href: "/app/tags",
    icon: StyleIcon,
    title: "Tags",
    role: [1, 2],
    category: "Gamification",
  },
  {
    href: "/app/events",
    icon: EventIcon,
    title: "Events",
    category: "Bhandara",
  },
  {
    href: "/app/locations",
    icon: LocationOnIcon,
    title: "Locations",
    category: "Bhandara",
  },
  {
    href: "/app/announcements",
    icon: AnnouncementIcon,
    title: "Announcements",
    category: "Bhandara",
  },
  {
    href: "/app/lnf",
    icon: CampaignIcon,
    title: "Lost & Found",
    category: "Bhandara",
  },
  {
    href: "/app/essentials",
    icon: SelfImprovementIcon,
    title: "Essential Services",
    category: "Bhandara",
  },
  {
    href: "/app/donations",
    icon: PaymentIcon,
    title: "Donations",
    role: [1, 2],
    category: "Gamification",
  },
  {
    href: "/app/donations_list",
    icon: PaymentIcon,
    title: "Donations List",
    role: [1, 2],
    category: "Gamification",
  },
  {
    href: "/app/moods",
    icon: MoodIcon,
    title: "Moods",
    role: [1, 2],
    category: "Gamification",
  },
  {
    href: "/app/triggers",
    icon: MoodIcon,
    title: "Moods triggers",
    role: [1, 2],
    category: "Gamification",
  },
  {
    href: "/app/articles",
    icon: ArticleIcon,
    title: "Articles",
    role: [1, 2],
    category: "Resources",
  },
  // !!!!! blogs are hided for sometime
  // {
  //   href: "/app/blogs",
  //   icon: BlogIcon,
  //   title: "Blogs",
  //   role: [1, 2],
  //   category: "Resources",
  // },
  {
    href: "/app/quotes",
    icon: QuoteIcon,
    title: "Quotes",
    role: [1, 2],
    category: "Resources",
  },
  {
    href: "/app/videos",
    icon: VideoIcon,
    title: "Video",
    role: [1, 2],
    category: "Resources",
  },
  {
    href: "/app/filters",
    icon: FilterAltRoundedIcon,
    title: "Filters",
    role: [1, 2],
    category: "Resources",
  },
  {
    href: "/app/self_development_articles",
    icon: ArticleIcon,
    title: "Articles",
    category: "Self Development",
  },
  {
    href: "/app/self_development_stories",
    icon: BlogIcon,
    title: "Stories",
    category: "Self Development",
  },
  {
    href: "/app/self_development_videos",
    icon: VideoIcon,
    title: "Videos",
    category: "Self Development",
  },
  {
    href: "/app/self_development_books",
    icon: BookIcon,
    title: "Books",
    category: "Self Development",
  },
  {
    href: "/app/pre-recorded-videos",
    icon: OndemandVideoIcon,
    title: "Videos",
    category: "Groups",
  },
  {
    href: "/app/schedule-pre-recorded-videos",
    icon: EventNoteIcon,
    title: "Schedule",
    category: "Groups",
  },
  {
    href: "/app/archive",
    icon: ArchiveIcon,
    title: "Archive",
    role: [1],
    category: "Bhandara",
  },
  {
    href: "/app/bhandara-tags",
    icon: ArchiveIcon,
    title: "Tags",
    role: [1],
    category: "Bhandara",
  },
  {
    href: "/app/daaji's-message",
    icon: ArchiveIcon,
    title: "Daaji's Message",
    role: [1],
    category: "Bhandara",
  },
  {
    href: "/app/yatra-garden",
    icon: ArchiveIcon,
    title: "Yatra Garden",
    role: [1],
    category: "Bhandara",
  },
  {
    href: "/app/theme",
    icon: ProgramIcon,
    title: "Theme",
    category: "Program",
  },
  {
    href: "/app/task",
    icon: TaskIcon,
    title: "Task",
    category: "Program",
  },
];
export const contentOwnerItems = [
  {
    href: "/app/dashboard",
    icon: DashboardIcon,
    title: "Gems",
    category: "Gamification",
  },

  // {
  //   href: "/app/guided",
  //   icon: GuidedMeditationIcon,
  //   title: "Meditate With Guidance",
  //   category: "Meditations",
  // },

  {
    href: "/app/purpose",
    icon: VideoLibraryOutlinedIcon,
    title: "Meditations",
    category: "Guided Meditations",
  },
  {
    href: "/app/meditation_background",
    icon: VideoLibraryOutlinedIcon,
    title: "Meditations Background",
    role: [1, 2, 3],
    category: "Guided Meditations",
  },

  {
    href: "/app/purpose_categories",
    icon: CategoryIcon,
    title: "Purpose Category",
    role: [1, 2, 3],
    category: "Guided Meditations",
  },

  {
    href: "/app/goals",
    icon: GolfCourseIcon,
    title: "Goals",
    category: "Gamification",
  },
  {
    href: "/app/pre-recorded-videos",
    icon: OndemandVideoIcon,
    title: "Videos",
    category: "Groups",
  },
  {
    href: "/app/schedule-pre-recorded-videos",
    icon: EventNoteIcon,
    title: "Schedule",
    category: "Groups",
  },
  {
    href: "/app/theme",
    icon: ProgramIcon,
    title: "Theme",
    category: "Program",
  },
  {
    href: "/app/task",
    icon: TaskIcon,
    title: "Task",
    category: "Program",
  },
];
export const preceptorItems = [
  {
    href: "/app/live",
    icon: LiveMeditationIcon,
    title: "Live Sessions",
    category: "Live Sessions",
  },
  // {
  //   href: "/app/pre-recorded-videos",
  //   icon: OndemandVideoIcon,
  //   title: "Videos",
  //   category: "Groups",
  // },
  // {
  //   href: "/app/schedule-pre-recorded-videos",
  //   icon: EventNoteIcon,
  //   title: "Schedule",
  //   category: "Groups",
  // },
];
export const eventAdminItems = [
  {
    href: "/app/events",
    icon: EventIcon,
    title: "Events",
    category: "Bhandara",
  },
  {
    href: "/app/announcements",
    icon: AnnouncementIcon,
    title: "Announcements",
    category: "Bhandara",
  },
];
export const selectNavItemByRole = (role: number) => {
  switch (role) {
    case 1:
      return superAdminItems;
    case 2:
      return adminItems;
    case 3:
      return contentOwnerItems;
    case 5:
      return preceptorItems;
    case 8:
      return eventAdminItems;
    default:
      return preceptorItems;
  }
};
