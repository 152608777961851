// source: live-meditation.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var common_common_pb = require('./common/common_pb.js');
goog.object.extend(proto, common_common_pb);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.AddCoachRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.AddQuestionRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.AwsChannelInfo', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.AwsChannelRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.AwsChannelResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.AwsChannelViewerCountResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.AwsStreamKey', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.ChannelArn', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.CoachProfile', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.DeleteCoachByIdResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.DeleteScheduleSessionByIDResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.DeleteScheduleSessionDetailsByIDResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.Empty', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.GetAllPreRecordedResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.GetLocaleCoachByIdRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.GroupIdRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.GroupOccurrencesDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.GroupsRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.GroupsResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.GroupsUserCountRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.Headers', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.ID', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.ListOfCoach', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.ListOfGroupOccurrences', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.ListOfGroups', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.ListOfGroupsWithSelectLanguage', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.ListOfLiveMeditations', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.ListOfPreRecordedLocaleResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.ListOfQuestion', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.ListOfSessionViewerCount', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.ListOfUserFeedBacks', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.LiveMeditation', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.LiveSessionState', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.LocaleCoachProfile', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.LocaleType', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.PreRecordedRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.PreRecordedResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.PreRecordedTypeRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.PublishOrUnPublishGroupRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.Question', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.ReportTypeWithIdRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.RequestAddBenefitAndSessionIDs', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.RequestAddSessionBenefitsDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.RequestBenefitAndSessionIDs', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.RequestForGetAllBenefits', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.RequestForGetAllBenefitsByCoachId', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.RequestForGetAllQuestionsWithRegisterCount', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.RequestForGetLiveSessionByCoachId', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.RequestForGetZoomToken', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.RequestForLiveSessionExist', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.RequestForLiveSessionState', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.RequestForLiveSessionsBetween', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.RequestForRating', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.RequestForUpcomingGetAllLiveMediationV2', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.RequestForUserFeedBack', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.RequestForZoomWrapper', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.RequestGetAllLiveSession', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionV2', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionWithSelectedLanguage', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.RequestGroupsBetweenDateRange', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.RequestLiveSessionId', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.RequestLiveSessionV2ByCoachIdOnSpecifiedTime', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.RequestPageable', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.RequestPageableWithId', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.RequestPreceptorSession', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.RequestQuestionBySessionId', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.RequestSessionBenefits', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.RequestSessionBenefitsDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.RequestSetLiveSessionRegisterCount', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.RequestUpdateBenefits', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.RequestUserWeeklyStats', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.ResponseAverageRating', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefits', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefitsByCoachId', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.ResponseForGetAllQuestionsWithRegisterCount', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.ResponseForGetLiveSessionByCoachId', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.ResponseForGetZoomToken', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.ResponseForLiveSessionFeedBacks', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.ResponseForLiveSessionState', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.ResponseForLiveSessionsBetween', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.ResponseForRating', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.ResponseForUpcomingGetAllLiveMeditationsV2', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.ResponseForZoomWrapper', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.ResponseListOfBenefits', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.ResponseListOfBenefitsDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditations', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsWithSelectedLanguage', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.ResponseLiveMeditation', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.ResponsePageable', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.ResponseSessionBenefits', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.ResponseSessionBenefitsDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.ResponseStatus', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.SessionBenefits', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.SessionBenefitsDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.SessionBenefitsList', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.SessionInfo', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.SessionInfoDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.SessionScheduledSuccessfully', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.SessionViewerCountResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.Setting', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.Status', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.Tag', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.TagWithPrerecorded', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.TagWithSession', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.Tags', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.TagsList', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.Test', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.TestResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.UpcomingGroupsRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.UpdateBenefits', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.UserReportType', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.Value', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.Value.KindCase', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.ZoomEventRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.ZoomEventResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.ZoomLiveStreamActionRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.ZoomUrlsResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.requestAddLiveMeditationForNextFewDays', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.requestScheduleSessionsDateRange', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.responseScheduleSessionsDateRange', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.scheduleSessionWithCoach', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.zoomObject', null, global);
goog.exportSymbol('proto.unifiedplatform.liveMeditation.zoomPayload', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.Test = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.Test, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.Test.displayName = 'proto.unifiedplatform.liveMeditation.Test';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.Empty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.Empty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.Empty.displayName = 'proto.unifiedplatform.liveMeditation.Empty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.TestResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.TestResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.TestResponse.displayName = 'proto.unifiedplatform.liveMeditation.TestResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.CoachProfile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.CoachProfile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.CoachProfile.displayName = 'proto.unifiedplatform.liveMeditation.CoachProfile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.LocaleCoachProfile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.LocaleCoachProfile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.LocaleCoachProfile.displayName = 'proto.unifiedplatform.liveMeditation.LocaleCoachProfile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.GetLocaleCoachByIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.GetLocaleCoachByIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.GetLocaleCoachByIdRequest.displayName = 'proto.unifiedplatform.liveMeditation.GetLocaleCoachByIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.AddCoachRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.AddCoachRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.AddCoachRequest.displayName = 'proto.unifiedplatform.liveMeditation.AddCoachRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.SessionBenefits = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.SessionBenefits, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.SessionBenefits.displayName = 'proto.unifiedplatform.liveMeditation.SessionBenefits';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.ResponseSessionBenefits = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.ResponseSessionBenefits.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.ResponseSessionBenefits, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.ResponseSessionBenefits.displayName = 'proto.unifiedplatform.liveMeditation.ResponseSessionBenefits';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.SessionBenefitsList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.SessionBenefitsList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.SessionBenefitsList.displayName = 'proto.unifiedplatform.liveMeditation.SessionBenefitsList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.RequestSessionBenefits = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.RequestSessionBenefits.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.RequestSessionBenefits, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.RequestSessionBenefits.displayName = 'proto.unifiedplatform.liveMeditation.RequestSessionBenefits';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.UpdateBenefits = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.UpdateBenefits, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.UpdateBenefits.displayName = 'proto.unifiedplatform.liveMeditation.UpdateBenefits';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.RequestUpdateBenefits = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.RequestUpdateBenefits.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.RequestUpdateBenefits, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.RequestUpdateBenefits.displayName = 'proto.unifiedplatform.liveMeditation.RequestUpdateBenefits';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.ResponseListOfBenefits = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.ResponseListOfBenefits.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.ResponseListOfBenefits, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.ResponseListOfBenefits.displayName = 'proto.unifiedplatform.liveMeditation.ResponseListOfBenefits';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.RequestForRating = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.RequestForRating, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.RequestForRating.displayName = 'proto.unifiedplatform.liveMeditation.RequestForRating';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.ResponseForRating = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.ResponseForRating, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.ResponseForRating.displayName = 'proto.unifiedplatform.liveMeditation.ResponseForRating';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.SessionInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.SessionInfo.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.SessionInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.SessionInfo.displayName = 'proto.unifiedplatform.liveMeditation.SessionInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest.displayName = 'proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.displayName = 'proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.ResponseLiveMeditation.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.ResponseLiveMeditation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.ResponseLiveMeditation.displayName = 'proto.unifiedplatform.liveMeditation.ResponseLiveMeditation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage.displayName = 'proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.LiveMeditation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.LiveMeditation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.LiveMeditation.displayName = 'proto.unifiedplatform.liveMeditation.LiveMeditation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.Question = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.Question, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.Question.displayName = 'proto.unifiedplatform.liveMeditation.Question';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.AddQuestionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.AddQuestionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.AddQuestionRequest.displayName = 'proto.unifiedplatform.liveMeditation.AddQuestionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.ListOfLiveMeditations = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.ListOfLiveMeditations.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.ListOfLiveMeditations, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.ListOfLiveMeditations.displayName = 'proto.unifiedplatform.liveMeditation.ListOfLiveMeditations';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditations = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditations.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditations, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditations.displayName = 'proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditations';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsWithSelectedLanguage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsWithSelectedLanguage.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsWithSelectedLanguage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsWithSelectedLanguage.displayName = 'proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsWithSelectedLanguage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.SessionScheduledSuccessfully = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.SessionScheduledSuccessfully, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.SessionScheduledSuccessfully.displayName = 'proto.unifiedplatform.liveMeditation.SessionScheduledSuccessfully';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.ID = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.ID, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.ID.displayName = 'proto.unifiedplatform.liveMeditation.ID';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.RequestLiveSessionId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.RequestLiveSessionId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.RequestLiveSessionId.displayName = 'proto.unifiedplatform.liveMeditation.RequestLiveSessionId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.RequestBenefitAndSessionIDs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.RequestBenefitAndSessionIDs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.RequestBenefitAndSessionIDs.displayName = 'proto.unifiedplatform.liveMeditation.RequestBenefitAndSessionIDs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.RequestAddBenefitAndSessionIDs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.RequestAddBenefitAndSessionIDs.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.RequestAddBenefitAndSessionIDs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.RequestAddBenefitAndSessionIDs.displayName = 'proto.unifiedplatform.liveMeditation.RequestAddBenefitAndSessionIDs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.ResponseStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.ResponseStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.ResponseStatus.displayName = 'proto.unifiedplatform.liveMeditation.ResponseStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.ListOfCoach = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.ListOfCoach.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.ListOfCoach, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.ListOfCoach.displayName = 'proto.unifiedplatform.liveMeditation.ListOfCoach';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.DeleteCoachByIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.DeleteCoachByIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.DeleteCoachByIdResponse.displayName = 'proto.unifiedplatform.liveMeditation.DeleteCoachByIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.DeleteScheduleSessionByIDResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.DeleteScheduleSessionByIDResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.DeleteScheduleSessionByIDResponse.displayName = 'proto.unifiedplatform.liveMeditation.DeleteScheduleSessionByIDResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.ListOfQuestion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.ListOfQuestion.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.ListOfQuestion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.ListOfQuestion.displayName = 'proto.unifiedplatform.liveMeditation.ListOfQuestion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.RequestQuestionBySessionId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.RequestQuestionBySessionId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.RequestQuestionBySessionId.displayName = 'proto.unifiedplatform.liveMeditation.RequestQuestionBySessionId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSession = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.RequestGetAllLiveSession, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.RequestGetAllLiveSession.displayName = 'proto.unifiedplatform.liveMeditation.RequestGetAllLiveSession';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionWithSelectedLanguage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionWithSelectedLanguage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionWithSelectedLanguage.displayName = 'proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionWithSelectedLanguage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.Status = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.Status, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.Status.displayName = 'proto.unifiedplatform.liveMeditation.Status';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails.displayName = 'proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.SessionInfoDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.SessionInfoDetails.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.SessionInfoDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.SessionInfoDetails.displayName = 'proto.unifiedplatform.liveMeditation.SessionInfoDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.DeleteScheduleSessionDetailsByIDResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.DeleteScheduleSessionDetailsByIDResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.DeleteScheduleSessionDetailsByIDResponse.displayName = 'proto.unifiedplatform.liveMeditation.DeleteScheduleSessionDetailsByIDResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsDetails.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsDetails.displayName = 'proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails.displayName = 'proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.RequestSessionBenefitsDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.RequestSessionBenefitsDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.RequestSessionBenefitsDetails.displayName = 'proto.unifiedplatform.liveMeditation.RequestSessionBenefitsDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.RequestAddSessionBenefitsDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.RequestAddSessionBenefitsDetails.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.RequestAddSessionBenefitsDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.RequestAddSessionBenefitsDetails.displayName = 'proto.unifiedplatform.liveMeditation.RequestAddSessionBenefitsDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.SessionBenefitsDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.SessionBenefitsDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.SessionBenefitsDetails.displayName = 'proto.unifiedplatform.liveMeditation.SessionBenefitsDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.ResponseListOfBenefitsDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.ResponseListOfBenefitsDetails.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.ResponseListOfBenefitsDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.ResponseListOfBenefitsDetails.displayName = 'proto.unifiedplatform.liveMeditation.ResponseListOfBenefitsDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.ResponseSessionBenefitsDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.ResponseSessionBenefitsDetails.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.ResponseSessionBenefitsDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.ResponseSessionBenefitsDetails.displayName = 'proto.unifiedplatform.liveMeditation.ResponseSessionBenefitsDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.AwsChannelRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.AwsChannelRequest.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.AwsChannelRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.AwsChannelRequest.displayName = 'proto.unifiedplatform.liveMeditation.AwsChannelRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.AwsChannelResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.AwsChannelResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.AwsChannelResponse.displayName = 'proto.unifiedplatform.liveMeditation.AwsChannelResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.AwsChannelInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.AwsChannelInfo.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.AwsChannelInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.AwsChannelInfo.displayName = 'proto.unifiedplatform.liveMeditation.AwsChannelInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.AwsStreamKey = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.AwsStreamKey.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.AwsStreamKey, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.AwsStreamKey.displayName = 'proto.unifiedplatform.liveMeditation.AwsStreamKey';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.ChannelArn = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.ChannelArn, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.ChannelArn.displayName = 'proto.unifiedplatform.liveMeditation.ChannelArn';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.AwsChannelViewerCountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.AwsChannelViewerCountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.AwsChannelViewerCountResponse.displayName = 'proto.unifiedplatform.liveMeditation.AwsChannelViewerCountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.requestAddLiveMeditationForNextFewDays = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.requestAddLiveMeditationForNextFewDays, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.requestAddLiveMeditationForNextFewDays.displayName = 'proto.unifiedplatform.liveMeditation.requestAddLiveMeditationForNextFewDays';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.ResponseAverageRating = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.ResponseAverageRating, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.ResponseAverageRating.displayName = 'proto.unifiedplatform.liveMeditation.ResponseAverageRating';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.RequestForLiveSessionsBetween = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.RequestForLiveSessionsBetween, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.RequestForLiveSessionsBetween.displayName = 'proto.unifiedplatform.liveMeditation.RequestForLiveSessionsBetween';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.ResponseForLiveSessionsBetween = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.ResponseForLiveSessionsBetween.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.ResponseForLiveSessionsBetween, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.ResponseForLiveSessionsBetween.displayName = 'proto.unifiedplatform.liveMeditation.ResponseForLiveSessionsBetween';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.RequestForLiveSessionExist = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.RequestForLiveSessionExist.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.RequestForLiveSessionExist, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.RequestForLiveSessionExist.displayName = 'proto.unifiedplatform.liveMeditation.RequestForLiveSessionExist';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.RequestForGetLiveSessionByCoachId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.RequestForGetLiveSessionByCoachId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.RequestForGetLiveSessionByCoachId.displayName = 'proto.unifiedplatform.liveMeditation.RequestForGetLiveSessionByCoachId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.ResponseForGetLiveSessionByCoachId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.ResponseForGetLiveSessionByCoachId.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.ResponseForGetLiveSessionByCoachId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.ResponseForGetLiveSessionByCoachId.displayName = 'proto.unifiedplatform.liveMeditation.ResponseForGetLiveSessionByCoachId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.requestScheduleSessionsDateRange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.requestScheduleSessionsDateRange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.requestScheduleSessionsDateRange.displayName = 'proto.unifiedplatform.liveMeditation.requestScheduleSessionsDateRange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.scheduleSessionWithCoach = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.scheduleSessionWithCoach, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.scheduleSessionWithCoach.displayName = 'proto.unifiedplatform.liveMeditation.scheduleSessionWithCoach';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.responseScheduleSessionsDateRange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.responseScheduleSessionsDateRange.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.responseScheduleSessionsDateRange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.responseScheduleSessionsDateRange.displayName = 'proto.unifiedplatform.liveMeditation.responseScheduleSessionsDateRange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefits = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefits.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefits, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefits.displayName = 'proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefits';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.RequestForGetAllBenefits = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.RequestForGetAllBenefits, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.RequestForGetAllBenefits.displayName = 'proto.unifiedplatform.liveMeditation.RequestForGetAllBenefits';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.RequestSetLiveSessionRegisterCount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.RequestSetLiveSessionRegisterCount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.RequestSetLiveSessionRegisterCount.displayName = 'proto.unifiedplatform.liveMeditation.RequestSetLiveSessionRegisterCount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.RequestForGetAllBenefitsByCoachId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.RequestForGetAllBenefitsByCoachId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.RequestForGetAllBenefitsByCoachId.displayName = 'proto.unifiedplatform.liveMeditation.RequestForGetAllBenefitsByCoachId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefitsByCoachId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefitsByCoachId.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefitsByCoachId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefitsByCoachId.displayName = 'proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefitsByCoachId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.RequestForGetAllQuestionsWithRegisterCount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.RequestForGetAllQuestionsWithRegisterCount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.RequestForGetAllQuestionsWithRegisterCount.displayName = 'proto.unifiedplatform.liveMeditation.RequestForGetAllQuestionsWithRegisterCount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.ResponseForGetAllQuestionsWithRegisterCount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.ResponseForGetAllQuestionsWithRegisterCount.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.ResponseForGetAllQuestionsWithRegisterCount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.ResponseForGetAllQuestionsWithRegisterCount.displayName = 'proto.unifiedplatform.liveMeditation.ResponseForGetAllQuestionsWithRegisterCount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.RequestForUserFeedBack = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.RequestForUserFeedBack, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.RequestForUserFeedBack.displayName = 'proto.unifiedplatform.liveMeditation.RequestForUserFeedBack';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack.displayName = 'proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.ListOfUserFeedBacks = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.ListOfUserFeedBacks.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.ListOfUserFeedBacks, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.ListOfUserFeedBacks.displayName = 'proto.unifiedplatform.liveMeditation.ListOfUserFeedBacks';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.RequestPageable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.RequestPageable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.RequestPageable.displayName = 'proto.unifiedplatform.liveMeditation.RequestPageable';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.ResponsePageable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.ResponsePageable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.ResponsePageable.displayName = 'proto.unifiedplatform.liveMeditation.ResponsePageable';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.RequestPageableWithId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.RequestPageableWithId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.RequestPageableWithId.displayName = 'proto.unifiedplatform.liveMeditation.RequestPageableWithId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.RequestForLiveSessionState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.RequestForLiveSessionState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.RequestForLiveSessionState.displayName = 'proto.unifiedplatform.liveMeditation.RequestForLiveSessionState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.ResponseForLiveSessionState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.ResponseForLiveSessionState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.ResponseForLiveSessionState.displayName = 'proto.unifiedplatform.liveMeditation.ResponseForLiveSessionState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.RequestPreceptorSession = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.RequestPreceptorSession, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.RequestPreceptorSession.displayName = 'proto.unifiedplatform.liveMeditation.RequestPreceptorSession';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.Headers = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.Headers, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.Headers.displayName = 'proto.unifiedplatform.liveMeditation.Headers';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.RequestForGetZoomToken = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.RequestForGetZoomToken, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.RequestForGetZoomToken.displayName = 'proto.unifiedplatform.liveMeditation.RequestForGetZoomToken';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.ResponseForGetZoomToken = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.ResponseForGetZoomToken, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.ResponseForGetZoomToken.displayName = 'proto.unifiedplatform.liveMeditation.ResponseForGetZoomToken';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.RequestForZoomWrapper = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.RequestForZoomWrapper, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.RequestForZoomWrapper.displayName = 'proto.unifiedplatform.liveMeditation.RequestForZoomWrapper';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.Setting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.Setting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.Setting.displayName = 'proto.unifiedplatform.liveMeditation.Setting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.Value = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.unifiedplatform.liveMeditation.Value.oneofGroups_);
};
goog.inherits(proto.unifiedplatform.liveMeditation.Value, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.Value.displayName = 'proto.unifiedplatform.liveMeditation.Value';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.ResponseForZoomWrapper = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.ResponseForZoomWrapper, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.ResponseForZoomWrapper.displayName = 'proto.unifiedplatform.liveMeditation.ResponseForZoomWrapper';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.ResponseForLiveSessionFeedBacks = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.ResponseForLiveSessionFeedBacks.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.ResponseForLiveSessionFeedBacks, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.ResponseForLiveSessionFeedBacks.displayName = 'proto.unifiedplatform.liveMeditation.ResponseForLiveSessionFeedBacks';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.RequestForUpcomingGetAllLiveMediationV2 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.RequestForUpcomingGetAllLiveMediationV2, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.RequestForUpcomingGetAllLiveMediationV2.displayName = 'proto.unifiedplatform.liveMeditation.RequestForUpcomingGetAllLiveMediationV2';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.ResponseForUpcomingGetAllLiveMeditationsV2 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.ResponseForUpcomingGetAllLiveMeditationsV2.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.ResponseForUpcomingGetAllLiveMeditationsV2, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.ResponseForUpcomingGetAllLiveMeditationsV2.displayName = 'proto.unifiedplatform.liveMeditation.ResponseForUpcomingGetAllLiveMeditationsV2';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionV2 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionV2.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionV2, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionV2.displayName = 'proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionV2';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.RequestLiveSessionV2ByCoachIdOnSpecifiedTime = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.RequestLiveSessionV2ByCoachIdOnSpecifiedTime, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.RequestLiveSessionV2ByCoachIdOnSpecifiedTime.displayName = 'proto.unifiedplatform.liveMeditation.RequestLiveSessionV2ByCoachIdOnSpecifiedTime';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime.displayName = 'proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.ZoomUrlsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.ZoomUrlsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.ZoomUrlsResponse.displayName = 'proto.unifiedplatform.liveMeditation.ZoomUrlsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.ZoomEventRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.ZoomEventRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.ZoomEventRequest.displayName = 'proto.unifiedplatform.liveMeditation.ZoomEventRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.zoomPayload = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.zoomPayload, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.zoomPayload.displayName = 'proto.unifiedplatform.liveMeditation.zoomPayload';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.zoomObject = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.zoomObject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.zoomObject.displayName = 'proto.unifiedplatform.liveMeditation.zoomObject';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.ZoomLiveStreamActionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.ZoomLiveStreamActionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.ZoomLiveStreamActionRequest.displayName = 'proto.unifiedplatform.liveMeditation.ZoomLiveStreamActionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.ReportTypeWithIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.ReportTypeWithIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.ReportTypeWithIdRequest.displayName = 'proto.unifiedplatform.liveMeditation.ReportTypeWithIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.ZoomEventResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.ZoomEventResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.ZoomEventResponse.displayName = 'proto.unifiedplatform.liveMeditation.ZoomEventResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.ListOfSessionViewerCount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.ListOfSessionViewerCount.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.ListOfSessionViewerCount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.ListOfSessionViewerCount.displayName = 'proto.unifiedplatform.liveMeditation.ListOfSessionViewerCount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.SessionViewerCountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.SessionViewerCountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.SessionViewerCountResponse.displayName = 'proto.unifiedplatform.liveMeditation.SessionViewerCountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.PreRecordedRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.PreRecordedRequest.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.PreRecordedRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.PreRecordedRequest.displayName = 'proto.unifiedplatform.liveMeditation.PreRecordedRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.PreRecordedResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.PreRecordedResponse.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.PreRecordedResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.PreRecordedResponse.displayName = 'proto.unifiedplatform.liveMeditation.PreRecordedResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.LocaleType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.LocaleType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.LocaleType.displayName = 'proto.unifiedplatform.liveMeditation.LocaleType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse.displayName = 'proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.GetAllPreRecordedResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.GetAllPreRecordedResponse.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.GetAllPreRecordedResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.GetAllPreRecordedResponse.displayName = 'proto.unifiedplatform.liveMeditation.GetAllPreRecordedResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.GroupsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.GroupsRequest.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.GroupsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.GroupsRequest.displayName = 'proto.unifiedplatform.liveMeditation.GroupsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.GroupsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.GroupsResponse.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.GroupsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.GroupsResponse.displayName = 'proto.unifiedplatform.liveMeditation.GroupsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType.displayName = 'proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.ListOfGroups = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.ListOfGroups.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.ListOfGroups, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.ListOfGroups.displayName = 'proto.unifiedplatform.liveMeditation.ListOfGroups';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.ListOfGroupsWithSelectLanguage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.ListOfGroupsWithSelectLanguage.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.ListOfGroupsWithSelectLanguage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.ListOfGroupsWithSelectLanguage.displayName = 'proto.unifiedplatform.liveMeditation.ListOfGroupsWithSelectLanguage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.RequestGroupsBetweenDateRange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.RequestGroupsBetweenDateRange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.RequestGroupsBetweenDateRange.displayName = 'proto.unifiedplatform.liveMeditation.RequestGroupsBetweenDateRange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.UpcomingGroupsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.UpcomingGroupsRequest.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.UpcomingGroupsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.UpcomingGroupsRequest.displayName = 'proto.unifiedplatform.liveMeditation.UpcomingGroupsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.GroupsUserCountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.GroupsUserCountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.GroupsUserCountRequest.displayName = 'proto.unifiedplatform.liveMeditation.GroupsUserCountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.PreRecordedTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.PreRecordedTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.PreRecordedTypeRequest.displayName = 'proto.unifiedplatform.liveMeditation.PreRecordedTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.ListOfPreRecordedLocaleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.ListOfPreRecordedLocaleResponse.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.ListOfPreRecordedLocaleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.ListOfPreRecordedLocaleResponse.displayName = 'proto.unifiedplatform.liveMeditation.ListOfPreRecordedLocaleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.PublishOrUnPublishGroupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.PublishOrUnPublishGroupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.PublishOrUnPublishGroupRequest.displayName = 'proto.unifiedplatform.liveMeditation.PublishOrUnPublishGroupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.GroupIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.GroupIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.GroupIdRequest.displayName = 'proto.unifiedplatform.liveMeditation.GroupIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.GroupOccurrencesDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.GroupOccurrencesDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.GroupOccurrencesDetails.displayName = 'proto.unifiedplatform.liveMeditation.GroupOccurrencesDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.ListOfGroupOccurrences = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.ListOfGroupOccurrences.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.ListOfGroupOccurrences, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.ListOfGroupOccurrences.displayName = 'proto.unifiedplatform.liveMeditation.ListOfGroupOccurrences';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.Tag = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.Tag, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.Tag.displayName = 'proto.unifiedplatform.liveMeditation.Tag';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.TagWithSession = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.TagWithSession.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.TagWithSession, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.TagWithSession.displayName = 'proto.unifiedplatform.liveMeditation.TagWithSession';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.TagWithPrerecorded = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.TagWithPrerecorded.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.TagWithPrerecorded, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.TagWithPrerecorded.displayName = 'proto.unifiedplatform.liveMeditation.TagWithPrerecorded';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.TagsList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.TagsList.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.TagsList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.TagsList.displayName = 'proto.unifiedplatform.liveMeditation.TagsList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.RequestUserWeeklyStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.RequestUserWeeklyStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.RequestUserWeeklyStats.displayName = 'proto.unifiedplatform.liveMeditation.RequestUserWeeklyStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.liveMeditation.Tags = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.liveMeditation.Tags.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.liveMeditation.Tags, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.liveMeditation.Tags.displayName = 'proto.unifiedplatform.liveMeditation.Tags';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.Test.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.Test.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.Test} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.Test.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.Test}
 */
proto.unifiedplatform.liveMeditation.Test.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.Test;
  return proto.unifiedplatform.liveMeditation.Test.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.Test} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.Test}
 */
proto.unifiedplatform.liveMeditation.Test.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.Test.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.Test.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.Test} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.Test.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string result = 1;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.Test.prototype.getResult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.Test} returns this
 */
proto.unifiedplatform.liveMeditation.Test.prototype.setResult = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.Empty.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.Empty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.Empty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.Empty.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.Empty}
 */
proto.unifiedplatform.liveMeditation.Empty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.Empty;
  return proto.unifiedplatform.liveMeditation.Empty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.Empty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.Empty}
 */
proto.unifiedplatform.liveMeditation.Empty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.Empty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.Empty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.Empty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.Empty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.TestResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.TestResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.TestResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.TestResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    test: (f = msg.getTest()) && proto.unifiedplatform.liveMeditation.Test.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.TestResponse}
 */
proto.unifiedplatform.liveMeditation.TestResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.TestResponse;
  return proto.unifiedplatform.liveMeditation.TestResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.TestResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.TestResponse}
 */
proto.unifiedplatform.liveMeditation.TestResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.liveMeditation.Test;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.Test.deserializeBinaryFromReader);
      msg.setTest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.TestResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.TestResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.TestResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.TestResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.unifiedplatform.liveMeditation.Test.serializeBinaryToWriter
    );
  }
};


/**
 * optional Test test = 1;
 * @return {?proto.unifiedplatform.liveMeditation.Test}
 */
proto.unifiedplatform.liveMeditation.TestResponse.prototype.getTest = function() {
  return /** @type{?proto.unifiedplatform.liveMeditation.Test} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.liveMeditation.Test, 1));
};


/**
 * @param {?proto.unifiedplatform.liveMeditation.Test|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.TestResponse} returns this
*/
proto.unifiedplatform.liveMeditation.TestResponse.prototype.setTest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.TestResponse} returns this
 */
proto.unifiedplatform.liveMeditation.TestResponse.prototype.clearTest = function() {
  return this.setTest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.TestResponse.prototype.hasTest = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.CoachProfile.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.CoachProfile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.CoachProfile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.CoachProfile.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    profiletitle: (f = msg.getProfiletitle()) && common_common_pb.Locale.toObject(includeInstance, f),
    profilesubtitle: (f = msg.getProfilesubtitle()) && common_common_pb.Locale.toObject(includeInstance, f),
    profiledescription: (f = msg.getProfiledescription()) && common_common_pb.Locale.toObject(includeInstance, f),
    imageurl: jspb.Message.getFieldWithDefault(msg, 7, ""),
    videourl: jspb.Message.getFieldWithDefault(msg, 8, ""),
    playbackurl: jspb.Message.getFieldWithDefault(msg, 9, ""),
    thumbnailimgurl: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.CoachProfile}
 */
proto.unifiedplatform.liveMeditation.CoachProfile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.CoachProfile;
  return proto.unifiedplatform.liveMeditation.CoachProfile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.CoachProfile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.CoachProfile}
 */
proto.unifiedplatform.liveMeditation.CoachProfile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setProfiletitle(value);
      break;
    case 5:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setProfilesubtitle(value);
      break;
    case 6:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setProfiledescription(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageurl(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setVideourl(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaybackurl(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbnailimgurl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.CoachProfile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.CoachProfile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.CoachProfile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.CoachProfile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProfiletitle();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getProfilesubtitle();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getProfiledescription();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getImageurl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getVideourl();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPlaybackurl();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getThumbnailimgurl();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.CoachProfile.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.CoachProfile} returns this
 */
proto.unifiedplatform.liveMeditation.CoachProfile.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.CoachProfile.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.CoachProfile} returns this
 */
proto.unifiedplatform.liveMeditation.CoachProfile.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.CoachProfile.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.CoachProfile} returns this
 */
proto.unifiedplatform.liveMeditation.CoachProfile.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional unifiedplatform.heartintune.common.Locale profileTitle = 4;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.liveMeditation.CoachProfile.prototype.getProfiletitle = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 4));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.CoachProfile} returns this
*/
proto.unifiedplatform.liveMeditation.CoachProfile.prototype.setProfiletitle = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.CoachProfile} returns this
 */
proto.unifiedplatform.liveMeditation.CoachProfile.prototype.clearProfiletitle = function() {
  return this.setProfiletitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.CoachProfile.prototype.hasProfiletitle = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale profileSubTitle = 5;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.liveMeditation.CoachProfile.prototype.getProfilesubtitle = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 5));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.CoachProfile} returns this
*/
proto.unifiedplatform.liveMeditation.CoachProfile.prototype.setProfilesubtitle = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.CoachProfile} returns this
 */
proto.unifiedplatform.liveMeditation.CoachProfile.prototype.clearProfilesubtitle = function() {
  return this.setProfilesubtitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.CoachProfile.prototype.hasProfilesubtitle = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale profileDescription = 6;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.liveMeditation.CoachProfile.prototype.getProfiledescription = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 6));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.CoachProfile} returns this
*/
proto.unifiedplatform.liveMeditation.CoachProfile.prototype.setProfiledescription = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.CoachProfile} returns this
 */
proto.unifiedplatform.liveMeditation.CoachProfile.prototype.clearProfiledescription = function() {
  return this.setProfiledescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.CoachProfile.prototype.hasProfiledescription = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string imageURL = 7;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.CoachProfile.prototype.getImageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.CoachProfile} returns this
 */
proto.unifiedplatform.liveMeditation.CoachProfile.prototype.setImageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string videoURL = 8;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.CoachProfile.prototype.getVideourl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.CoachProfile} returns this
 */
proto.unifiedplatform.liveMeditation.CoachProfile.prototype.setVideourl = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string playBackUrl = 9;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.CoachProfile.prototype.getPlaybackurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.CoachProfile} returns this
 */
proto.unifiedplatform.liveMeditation.CoachProfile.prototype.setPlaybackurl = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string thumbnailImgUrl = 10;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.CoachProfile.prototype.getThumbnailimgurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.CoachProfile} returns this
 */
proto.unifiedplatform.liveMeditation.CoachProfile.prototype.setThumbnailimgurl = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.LocaleCoachProfile.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.LocaleCoachProfile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.LocaleCoachProfile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.LocaleCoachProfile.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    profiletitle: jspb.Message.getFieldWithDefault(msg, 4, ""),
    profilesubtitle: jspb.Message.getFieldWithDefault(msg, 5, ""),
    profiledescription: jspb.Message.getFieldWithDefault(msg, 6, ""),
    imageurl: jspb.Message.getFieldWithDefault(msg, 7, ""),
    videourl: jspb.Message.getFieldWithDefault(msg, 8, ""),
    playbackurl: jspb.Message.getFieldWithDefault(msg, 9, ""),
    thumbnailimgurl: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.LocaleCoachProfile}
 */
proto.unifiedplatform.liveMeditation.LocaleCoachProfile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.LocaleCoachProfile;
  return proto.unifiedplatform.liveMeditation.LocaleCoachProfile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.LocaleCoachProfile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.LocaleCoachProfile}
 */
proto.unifiedplatform.liveMeditation.LocaleCoachProfile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfiletitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilesubtitle(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfiledescription(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageurl(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setVideourl(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaybackurl(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbnailimgurl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.LocaleCoachProfile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.LocaleCoachProfile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.LocaleCoachProfile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.LocaleCoachProfile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProfiletitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getProfilesubtitle();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getProfiledescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getImageurl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getVideourl();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPlaybackurl();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getThumbnailimgurl();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.LocaleCoachProfile.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.LocaleCoachProfile} returns this
 */
proto.unifiedplatform.liveMeditation.LocaleCoachProfile.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.LocaleCoachProfile.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.LocaleCoachProfile} returns this
 */
proto.unifiedplatform.liveMeditation.LocaleCoachProfile.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.LocaleCoachProfile.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.LocaleCoachProfile} returns this
 */
proto.unifiedplatform.liveMeditation.LocaleCoachProfile.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string profileTitle = 4;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.LocaleCoachProfile.prototype.getProfiletitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.LocaleCoachProfile} returns this
 */
proto.unifiedplatform.liveMeditation.LocaleCoachProfile.prototype.setProfiletitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string profileSubTitle = 5;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.LocaleCoachProfile.prototype.getProfilesubtitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.LocaleCoachProfile} returns this
 */
proto.unifiedplatform.liveMeditation.LocaleCoachProfile.prototype.setProfilesubtitle = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string profileDescription = 6;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.LocaleCoachProfile.prototype.getProfiledescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.LocaleCoachProfile} returns this
 */
proto.unifiedplatform.liveMeditation.LocaleCoachProfile.prototype.setProfiledescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string imageURL = 7;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.LocaleCoachProfile.prototype.getImageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.LocaleCoachProfile} returns this
 */
proto.unifiedplatform.liveMeditation.LocaleCoachProfile.prototype.setImageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string videoURL = 8;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.LocaleCoachProfile.prototype.getVideourl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.LocaleCoachProfile} returns this
 */
proto.unifiedplatform.liveMeditation.LocaleCoachProfile.prototype.setVideourl = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string playBackUrl = 9;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.LocaleCoachProfile.prototype.getPlaybackurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.LocaleCoachProfile} returns this
 */
proto.unifiedplatform.liveMeditation.LocaleCoachProfile.prototype.setPlaybackurl = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string thumbnailImgUrl = 10;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.LocaleCoachProfile.prototype.getThumbnailimgurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.LocaleCoachProfile} returns this
 */
proto.unifiedplatform.liveMeditation.LocaleCoachProfile.prototype.setThumbnailimgurl = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.GetLocaleCoachByIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.GetLocaleCoachByIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.GetLocaleCoachByIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.GetLocaleCoachByIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    locale: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.GetLocaleCoachByIdRequest}
 */
proto.unifiedplatform.liveMeditation.GetLocaleCoachByIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.GetLocaleCoachByIdRequest;
  return proto.unifiedplatform.liveMeditation.GetLocaleCoachByIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.GetLocaleCoachByIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.GetLocaleCoachByIdRequest}
 */
proto.unifiedplatform.liveMeditation.GetLocaleCoachByIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setLocale(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.GetLocaleCoachByIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.GetLocaleCoachByIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.GetLocaleCoachByIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.GetLocaleCoachByIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLocale();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.GetLocaleCoachByIdRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.GetLocaleCoachByIdRequest} returns this
 */
proto.unifiedplatform.liveMeditation.GetLocaleCoachByIdRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType locale = 2;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.liveMeditation.GetLocaleCoachByIdRequest.prototype.getLocale = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.liveMeditation.GetLocaleCoachByIdRequest} returns this
 */
proto.unifiedplatform.liveMeditation.GetLocaleCoachByIdRequest.prototype.setLocale = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.AddCoachRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.AddCoachRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.AddCoachRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.AddCoachRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    profiletitle: (f = msg.getProfiletitle()) && common_common_pb.Locale.toObject(includeInstance, f),
    profilesubtitle: (f = msg.getProfilesubtitle()) && common_common_pb.Locale.toObject(includeInstance, f),
    profiledescription: (f = msg.getProfiledescription()) && common_common_pb.Locale.toObject(includeInstance, f),
    imageurl: jspb.Message.getFieldWithDefault(msg, 6, ""),
    videourl: jspb.Message.getFieldWithDefault(msg, 7, ""),
    awschannelinfo: (f = msg.getAwschannelinfo()) && proto.unifiedplatform.liveMeditation.AwsChannelRequest.toObject(includeInstance, f),
    thumbnailimgurl: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.AddCoachRequest}
 */
proto.unifiedplatform.liveMeditation.AddCoachRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.AddCoachRequest;
  return proto.unifiedplatform.liveMeditation.AddCoachRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.AddCoachRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.AddCoachRequest}
 */
proto.unifiedplatform.liveMeditation.AddCoachRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setProfiletitle(value);
      break;
    case 4:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setProfilesubtitle(value);
      break;
    case 5:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setProfiledescription(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageurl(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setVideourl(value);
      break;
    case 13:
      var value = new proto.unifiedplatform.liveMeditation.AwsChannelRequest;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.AwsChannelRequest.deserializeBinaryFromReader);
      msg.setAwschannelinfo(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbnailimgurl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.AddCoachRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.AddCoachRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.AddCoachRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.AddCoachRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProfiletitle();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getProfilesubtitle();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getProfiledescription();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getImageurl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getVideourl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAwschannelinfo();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.unifiedplatform.liveMeditation.AwsChannelRequest.serializeBinaryToWriter
    );
  }
  f = message.getThumbnailimgurl();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.AddCoachRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.AddCoachRequest} returns this
 */
proto.unifiedplatform.liveMeditation.AddCoachRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.AddCoachRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.AddCoachRequest} returns this
 */
proto.unifiedplatform.liveMeditation.AddCoachRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional unifiedplatform.heartintune.common.Locale profileTitle = 3;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.liveMeditation.AddCoachRequest.prototype.getProfiletitle = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 3));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.AddCoachRequest} returns this
*/
proto.unifiedplatform.liveMeditation.AddCoachRequest.prototype.setProfiletitle = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.AddCoachRequest} returns this
 */
proto.unifiedplatform.liveMeditation.AddCoachRequest.prototype.clearProfiletitle = function() {
  return this.setProfiletitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.AddCoachRequest.prototype.hasProfiletitle = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale profileSubTitle = 4;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.liveMeditation.AddCoachRequest.prototype.getProfilesubtitle = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 4));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.AddCoachRequest} returns this
*/
proto.unifiedplatform.liveMeditation.AddCoachRequest.prototype.setProfilesubtitle = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.AddCoachRequest} returns this
 */
proto.unifiedplatform.liveMeditation.AddCoachRequest.prototype.clearProfilesubtitle = function() {
  return this.setProfilesubtitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.AddCoachRequest.prototype.hasProfilesubtitle = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale profileDescription = 5;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.liveMeditation.AddCoachRequest.prototype.getProfiledescription = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 5));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.AddCoachRequest} returns this
*/
proto.unifiedplatform.liveMeditation.AddCoachRequest.prototype.setProfiledescription = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.AddCoachRequest} returns this
 */
proto.unifiedplatform.liveMeditation.AddCoachRequest.prototype.clearProfiledescription = function() {
  return this.setProfiledescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.AddCoachRequest.prototype.hasProfiledescription = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string imageURL = 6;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.AddCoachRequest.prototype.getImageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.AddCoachRequest} returns this
 */
proto.unifiedplatform.liveMeditation.AddCoachRequest.prototype.setImageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string videoURL = 7;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.AddCoachRequest.prototype.getVideourl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.AddCoachRequest} returns this
 */
proto.unifiedplatform.liveMeditation.AddCoachRequest.prototype.setVideourl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional AwsChannelRequest awsChannelInfo = 13;
 * @return {?proto.unifiedplatform.liveMeditation.AwsChannelRequest}
 */
proto.unifiedplatform.liveMeditation.AddCoachRequest.prototype.getAwschannelinfo = function() {
  return /** @type{?proto.unifiedplatform.liveMeditation.AwsChannelRequest} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.liveMeditation.AwsChannelRequest, 13));
};


/**
 * @param {?proto.unifiedplatform.liveMeditation.AwsChannelRequest|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.AddCoachRequest} returns this
*/
proto.unifiedplatform.liveMeditation.AddCoachRequest.prototype.setAwschannelinfo = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.AddCoachRequest} returns this
 */
proto.unifiedplatform.liveMeditation.AddCoachRequest.prototype.clearAwschannelinfo = function() {
  return this.setAwschannelinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.AddCoachRequest.prototype.hasAwschannelinfo = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string thumbnailImgUrl = 14;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.AddCoachRequest.prototype.getThumbnailimgurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.AddCoachRequest} returns this
 */
proto.unifiedplatform.liveMeditation.AddCoachRequest.prototype.setThumbnailimgurl = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.SessionBenefits.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.SessionBenefits.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.SessionBenefits} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.SessionBenefits.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    imageurl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.SessionBenefits}
 */
proto.unifiedplatform.liveMeditation.SessionBenefits.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.SessionBenefits;
  return proto.unifiedplatform.liveMeditation.SessionBenefits.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.SessionBenefits} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.SessionBenefits}
 */
proto.unifiedplatform.liveMeditation.SessionBenefits.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageurl(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.SessionBenefits.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.SessionBenefits.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.SessionBenefits} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.SessionBenefits.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getImageurl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.SessionBenefits.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionBenefits} returns this
 */
proto.unifiedplatform.liveMeditation.SessionBenefits.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string imageURL = 2;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.SessionBenefits.prototype.getImageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionBenefits} returns this
 */
proto.unifiedplatform.liveMeditation.SessionBenefits.prototype.setImageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.SessionBenefits.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionBenefits} returns this
 */
proto.unifiedplatform.liveMeditation.SessionBenefits.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.ResponseSessionBenefits.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.ResponseSessionBenefits.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.ResponseSessionBenefits.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.ResponseSessionBenefits} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ResponseSessionBenefits.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionbenefitsList: jspb.Message.toObjectList(msg.getSessionbenefitsList(),
    proto.unifiedplatform.liveMeditation.SessionBenefits.toObject, includeInstance),
    livesessionid: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseSessionBenefits}
 */
proto.unifiedplatform.liveMeditation.ResponseSessionBenefits.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.ResponseSessionBenefits;
  return proto.unifiedplatform.liveMeditation.ResponseSessionBenefits.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.ResponseSessionBenefits} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseSessionBenefits}
 */
proto.unifiedplatform.liveMeditation.ResponseSessionBenefits.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.liveMeditation.SessionBenefits;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.SessionBenefits.deserializeBinaryFromReader);
      msg.addSessionbenefits(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLivesessionid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.ResponseSessionBenefits.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.ResponseSessionBenefits.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.ResponseSessionBenefits} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ResponseSessionBenefits.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionbenefitsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.liveMeditation.SessionBenefits.serializeBinaryToWriter
    );
  }
  f = message.getLivesessionid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated SessionBenefits sessionBenefits = 1;
 * @return {!Array<!proto.unifiedplatform.liveMeditation.SessionBenefits>}
 */
proto.unifiedplatform.liveMeditation.ResponseSessionBenefits.prototype.getSessionbenefitsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.liveMeditation.SessionBenefits>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.liveMeditation.SessionBenefits, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.liveMeditation.SessionBenefits>} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseSessionBenefits} returns this
*/
proto.unifiedplatform.liveMeditation.ResponseSessionBenefits.prototype.setSessionbenefitsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.liveMeditation.SessionBenefits=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.SessionBenefits}
 */
proto.unifiedplatform.liveMeditation.ResponseSessionBenefits.prototype.addSessionbenefits = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.liveMeditation.SessionBenefits, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseSessionBenefits} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseSessionBenefits.prototype.clearSessionbenefitsList = function() {
  return this.setSessionbenefitsList([]);
};


/**
 * optional int64 liveSessionID = 2;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.ResponseSessionBenefits.prototype.getLivesessionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseSessionBenefits} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseSessionBenefits.prototype.setLivesessionid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.SessionBenefitsList.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.SessionBenefitsList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.SessionBenefitsList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.SessionBenefitsList.toObject = function(includeInstance, msg) {
  var f, obj = {
    imageurl: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.SessionBenefitsList}
 */
proto.unifiedplatform.liveMeditation.SessionBenefitsList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.SessionBenefitsList;
  return proto.unifiedplatform.liveMeditation.SessionBenefitsList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.SessionBenefitsList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.SessionBenefitsList}
 */
proto.unifiedplatform.liveMeditation.SessionBenefitsList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageurl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.SessionBenefitsList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.SessionBenefitsList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.SessionBenefitsList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.SessionBenefitsList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImageurl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string imageURL = 1;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.SessionBenefitsList.prototype.getImageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionBenefitsList} returns this
 */
proto.unifiedplatform.liveMeditation.SessionBenefitsList.prototype.setImageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.SessionBenefitsList.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionBenefitsList} returns this
 */
proto.unifiedplatform.liveMeditation.SessionBenefitsList.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.RequestSessionBenefits.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.RequestSessionBenefits.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.RequestSessionBenefits.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.RequestSessionBenefits} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestSessionBenefits.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionbenefitslistList: jspb.Message.toObjectList(msg.getSessionbenefitslistList(),
    proto.unifiedplatform.liveMeditation.SessionBenefitsList.toObject, includeInstance),
    livesessionid: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.RequestSessionBenefits}
 */
proto.unifiedplatform.liveMeditation.RequestSessionBenefits.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.RequestSessionBenefits;
  return proto.unifiedplatform.liveMeditation.RequestSessionBenefits.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.RequestSessionBenefits} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.RequestSessionBenefits}
 */
proto.unifiedplatform.liveMeditation.RequestSessionBenefits.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.liveMeditation.SessionBenefitsList;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.SessionBenefitsList.deserializeBinaryFromReader);
      msg.addSessionbenefitslist(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLivesessionid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.RequestSessionBenefits.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.RequestSessionBenefits.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.RequestSessionBenefits} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestSessionBenefits.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionbenefitslistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.liveMeditation.SessionBenefitsList.serializeBinaryToWriter
    );
  }
  f = message.getLivesessionid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated SessionBenefitsList sessionBenefitsList = 1;
 * @return {!Array<!proto.unifiedplatform.liveMeditation.SessionBenefitsList>}
 */
proto.unifiedplatform.liveMeditation.RequestSessionBenefits.prototype.getSessionbenefitslistList = function() {
  return /** @type{!Array<!proto.unifiedplatform.liveMeditation.SessionBenefitsList>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.liveMeditation.SessionBenefitsList, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.liveMeditation.SessionBenefitsList>} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestSessionBenefits} returns this
*/
proto.unifiedplatform.liveMeditation.RequestSessionBenefits.prototype.setSessionbenefitslistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.liveMeditation.SessionBenefitsList=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.SessionBenefitsList}
 */
proto.unifiedplatform.liveMeditation.RequestSessionBenefits.prototype.addSessionbenefitslist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.liveMeditation.SessionBenefitsList, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.RequestSessionBenefits} returns this
 */
proto.unifiedplatform.liveMeditation.RequestSessionBenefits.prototype.clearSessionbenefitslistList = function() {
  return this.setSessionbenefitslistList([]);
};


/**
 * optional int64 liveSessionID = 2;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.RequestSessionBenefits.prototype.getLivesessionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestSessionBenefits} returns this
 */
proto.unifiedplatform.liveMeditation.RequestSessionBenefits.prototype.setLivesessionid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.UpdateBenefits.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.UpdateBenefits.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.UpdateBenefits} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.UpdateBenefits.toObject = function(includeInstance, msg) {
  var f, obj = {
    idofbenefit: jspb.Message.getFieldWithDefault(msg, 1, 0),
    imageurl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    removebenefit: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.UpdateBenefits}
 */
proto.unifiedplatform.liveMeditation.UpdateBenefits.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.UpdateBenefits;
  return proto.unifiedplatform.liveMeditation.UpdateBenefits.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.UpdateBenefits} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.UpdateBenefits}
 */
proto.unifiedplatform.liveMeditation.UpdateBenefits.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIdofbenefit(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageurl(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRemovebenefit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.UpdateBenefits.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.UpdateBenefits.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.UpdateBenefits} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.UpdateBenefits.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdofbenefit();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getImageurl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRemovebenefit();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional int64 idOfBenefit = 1;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.UpdateBenefits.prototype.getIdofbenefit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.UpdateBenefits} returns this
 */
proto.unifiedplatform.liveMeditation.UpdateBenefits.prototype.setIdofbenefit = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string imageURL = 2;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.UpdateBenefits.prototype.getImageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.UpdateBenefits} returns this
 */
proto.unifiedplatform.liveMeditation.UpdateBenefits.prototype.setImageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.UpdateBenefits.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.UpdateBenefits} returns this
 */
proto.unifiedplatform.liveMeditation.UpdateBenefits.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool removeBenefit = 4;
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.UpdateBenefits.prototype.getRemovebenefit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.liveMeditation.UpdateBenefits} returns this
 */
proto.unifiedplatform.liveMeditation.UpdateBenefits.prototype.setRemovebenefit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.RequestUpdateBenefits.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.RequestUpdateBenefits.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.RequestUpdateBenefits.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.RequestUpdateBenefits} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestUpdateBenefits.toObject = function(includeInstance, msg) {
  var f, obj = {
    updatebenefitsList: jspb.Message.toObjectList(msg.getUpdatebenefitsList(),
    proto.unifiedplatform.liveMeditation.UpdateBenefits.toObject, includeInstance),
    livesessionid: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.RequestUpdateBenefits}
 */
proto.unifiedplatform.liveMeditation.RequestUpdateBenefits.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.RequestUpdateBenefits;
  return proto.unifiedplatform.liveMeditation.RequestUpdateBenefits.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.RequestUpdateBenefits} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.RequestUpdateBenefits}
 */
proto.unifiedplatform.liveMeditation.RequestUpdateBenefits.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.liveMeditation.UpdateBenefits;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.UpdateBenefits.deserializeBinaryFromReader);
      msg.addUpdatebenefits(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLivesessionid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.RequestUpdateBenefits.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.RequestUpdateBenefits.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.RequestUpdateBenefits} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestUpdateBenefits.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdatebenefitsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.liveMeditation.UpdateBenefits.serializeBinaryToWriter
    );
  }
  f = message.getLivesessionid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated UpdateBenefits updateBenefits = 1;
 * @return {!Array<!proto.unifiedplatform.liveMeditation.UpdateBenefits>}
 */
proto.unifiedplatform.liveMeditation.RequestUpdateBenefits.prototype.getUpdatebenefitsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.liveMeditation.UpdateBenefits>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.liveMeditation.UpdateBenefits, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.liveMeditation.UpdateBenefits>} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestUpdateBenefits} returns this
*/
proto.unifiedplatform.liveMeditation.RequestUpdateBenefits.prototype.setUpdatebenefitsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.liveMeditation.UpdateBenefits=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.UpdateBenefits}
 */
proto.unifiedplatform.liveMeditation.RequestUpdateBenefits.prototype.addUpdatebenefits = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.liveMeditation.UpdateBenefits, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.RequestUpdateBenefits} returns this
 */
proto.unifiedplatform.liveMeditation.RequestUpdateBenefits.prototype.clearUpdatebenefitsList = function() {
  return this.setUpdatebenefitsList([]);
};


/**
 * optional int64 liveSessionID = 2;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.RequestUpdateBenefits.prototype.getLivesessionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestUpdateBenefits} returns this
 */
proto.unifiedplatform.liveMeditation.RequestUpdateBenefits.prototype.setLivesessionid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.ResponseListOfBenefits.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.ResponseListOfBenefits.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.ResponseListOfBenefits.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.ResponseListOfBenefits} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ResponseListOfBenefits.toObject = function(includeInstance, msg) {
  var f, obj = {
    livesessionid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    listofbenefitsList: jspb.Message.toObjectList(msg.getListofbenefitsList(),
    proto.unifiedplatform.liveMeditation.SessionBenefits.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseListOfBenefits}
 */
proto.unifiedplatform.liveMeditation.ResponseListOfBenefits.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.ResponseListOfBenefits;
  return proto.unifiedplatform.liveMeditation.ResponseListOfBenefits.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.ResponseListOfBenefits} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseListOfBenefits}
 */
proto.unifiedplatform.liveMeditation.ResponseListOfBenefits.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLivesessionid(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.liveMeditation.SessionBenefits;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.SessionBenefits.deserializeBinaryFromReader);
      msg.addListofbenefits(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.ResponseListOfBenefits.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.ResponseListOfBenefits.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.ResponseListOfBenefits} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ResponseListOfBenefits.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLivesessionid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getListofbenefitsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.unifiedplatform.liveMeditation.SessionBenefits.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 liveSessionID = 1;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.ResponseListOfBenefits.prototype.getLivesessionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseListOfBenefits} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseListOfBenefits.prototype.setLivesessionid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated SessionBenefits listOfBenefits = 2;
 * @return {!Array<!proto.unifiedplatform.liveMeditation.SessionBenefits>}
 */
proto.unifiedplatform.liveMeditation.ResponseListOfBenefits.prototype.getListofbenefitsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.liveMeditation.SessionBenefits>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.liveMeditation.SessionBenefits, 2));
};


/**
 * @param {!Array<!proto.unifiedplatform.liveMeditation.SessionBenefits>} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseListOfBenefits} returns this
*/
proto.unifiedplatform.liveMeditation.ResponseListOfBenefits.prototype.setListofbenefitsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.unifiedplatform.liveMeditation.SessionBenefits=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.SessionBenefits}
 */
proto.unifiedplatform.liveMeditation.ResponseListOfBenefits.prototype.addListofbenefits = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.unifiedplatform.liveMeditation.SessionBenefits, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseListOfBenefits} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseListOfBenefits.prototype.clearListofbenefitsList = function() {
  return this.setListofbenefitsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.RequestForRating.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.RequestForRating.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.RequestForRating} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestForRating.toObject = function(includeInstance, msg) {
  var f, obj = {
    livesessionid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    rating: jspb.Message.getFieldWithDefault(msg, 2, 0),
    decreaserating: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.RequestForRating}
 */
proto.unifiedplatform.liveMeditation.RequestForRating.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.RequestForRating;
  return proto.unifiedplatform.liveMeditation.RequestForRating.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.RequestForRating} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.RequestForRating}
 */
proto.unifiedplatform.liveMeditation.RequestForRating.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLivesessionid(value);
      break;
    case 2:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LiveMeditationRating} */ (reader.readEnum());
      msg.setRating(value);
      break;
    case 3:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LiveMeditationRating} */ (reader.readEnum());
      msg.setDecreaserating(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.RequestForRating.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.RequestForRating.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.RequestForRating} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestForRating.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLivesessionid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getRating();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getDecreaserating();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional int64 liveSessionID = 1;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.RequestForRating.prototype.getLivesessionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestForRating} returns this
 */
proto.unifiedplatform.liveMeditation.RequestForRating.prototype.setLivesessionid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.LiveMeditationRating rating = 2;
 * @return {!proto.unifiedplatform.heartintune.common.LiveMeditationRating}
 */
proto.unifiedplatform.liveMeditation.RequestForRating.prototype.getRating = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LiveMeditationRating} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LiveMeditationRating} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestForRating} returns this
 */
proto.unifiedplatform.liveMeditation.RequestForRating.prototype.setRating = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional unifiedplatform.heartintune.common.LiveMeditationRating decreaseRating = 3;
 * @return {!proto.unifiedplatform.heartintune.common.LiveMeditationRating}
 */
proto.unifiedplatform.liveMeditation.RequestForRating.prototype.getDecreaserating = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LiveMeditationRating} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LiveMeditationRating} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestForRating} returns this
 */
proto.unifiedplatform.liveMeditation.RequestForRating.prototype.setDecreaserating = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.ResponseForRating.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.ResponseForRating.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.ResponseForRating} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ResponseForRating.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForRating}
 */
proto.unifiedplatform.liveMeditation.ResponseForRating.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.ResponseForRating;
  return proto.unifiedplatform.liveMeditation.ResponseForRating.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.ResponseForRating} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForRating}
 */
proto.unifiedplatform.liveMeditation.ResponseForRating.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.ResponseForRating.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.ResponseForRating.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.ResponseForRating} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ResponseForRating.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool status = 1;
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.ResponseForRating.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForRating} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseForRating.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.SessionInfo.repeatedFields_ = [12,25,26];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.SessionInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.SessionInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.SessionInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    scheduledtime: (f = msg.getScheduledtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    sessiontitle: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sessionsubtitle: jspb.Message.getFieldWithDefault(msg, 4, ""),
    sessioninfo: jspb.Message.getFieldWithDefault(msg, 5, ""),
    duration: jspb.Message.getFieldWithDefault(msg, 6, 0),
    registeredusercount: jspb.Message.getFieldWithDefault(msg, 7, 0),
    liveparticipantscount: jspb.Message.getFieldWithDefault(msg, 8, 0),
    aboutsession: jspb.Message.getFieldWithDefault(msg, 9, ""),
    meditationsteps: jspb.Message.getFieldWithDefault(msg, 10, ""),
    associatedcoachid: jspb.Message.getFieldWithDefault(msg, 11, 0),
    associatedlistofbenefitsidList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
    imageurl: jspb.Message.getFieldWithDefault(msg, 13, ""),
    videourl: jspb.Message.getFieldWithDefault(msg, 14, ""),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 15, 0),
    viewercountstart: jspb.Message.getFieldWithDefault(msg, 16, 0),
    viewercountend: jspb.Message.getFieldWithDefault(msg, 18, 0),
    sessionstarttime: (f = msg.getSessionstarttime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    sessionendtime: (f = msg.getSessionendtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    prerecordedid: jspb.Message.getFieldWithDefault(msg, 21, 0),
    prerecordedurl: jspb.Message.getFieldWithDefault(msg, 22, ""),
    updateallocatedcoach: jspb.Message.getBooleanFieldWithDefault(msg, 23, false),
    link: jspb.Message.getFieldWithDefault(msg, 24, ""),
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    proto.unifiedplatform.liveMeditation.Tag.toObject, includeInstance),
    tagidsList: (f = jspb.Message.getRepeatedField(msg, 26)) == null ? undefined : f,
    attribute: jspb.Message.getFieldWithDefault(msg, 27, ""),
    topic: jspb.Message.getFieldWithDefault(msg, 28, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfo}
 */
proto.unifiedplatform.liveMeditation.SessionInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.SessionInfo;
  return proto.unifiedplatform.liveMeditation.SessionInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.SessionInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfo}
 */
proto.unifiedplatform.liveMeditation.SessionInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setScheduledtime(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionsubtitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessioninfo(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDuration(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRegisteredusercount(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLiveparticipantscount(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAboutsession(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeditationsteps(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAssociatedcoachid(value);
      break;
    case 12:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAssociatedlistofbenefitsid(values[i]);
      }
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageurl(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setVideourl(value);
      break;
    case 15:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setViewercountstart(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setViewercountend(value);
      break;
    case 19:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSessionstarttime(value);
      break;
    case 20:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSessionendtime(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPrerecordedid(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrerecordedurl(value);
      break;
    case 23:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUpdateallocatedcoach(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink(value);
      break;
    case 25:
      var value = new proto.unifiedplatform.liveMeditation.Tag;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.Tag.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    case 26:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTagids(values[i]);
      }
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttribute(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setTopic(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.SessionInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.SessionInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.SessionInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getScheduledtime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSessiontitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSessionsubtitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSessioninfo();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getRegisteredusercount();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getLiveparticipantscount();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getAboutsession();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getMeditationsteps();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getAssociatedcoachid();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getAssociatedlistofbenefitsidList();
  if (f.length > 0) {
    writer.writePackedInt64(
      12,
      f
    );
  }
  f = message.getImageurl();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getVideourl();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getViewercountstart();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
  f = message.getViewercountend();
  if (f !== 0) {
    writer.writeInt64(
      18,
      f
    );
  }
  f = message.getSessionstarttime();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSessionendtime();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPrerecordedid();
  if (f !== 0) {
    writer.writeInt64(
      21,
      f
    );
  }
  f = message.getPrerecordedurl();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getUpdateallocatedcoach();
  if (f) {
    writer.writeBool(
      23,
      f
    );
  }
  f = message.getLink();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      25,
      f,
      proto.unifiedplatform.liveMeditation.Tag.serializeBinaryToWriter
    );
  }
  f = message.getTagidsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      26,
      f
    );
  }
  f = message.getAttribute();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getTopic();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfo} returns this
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp scheduledTime = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.getScheduledtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfo} returns this
*/
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.setScheduledtime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfo} returns this
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.clearScheduledtime = function() {
  return this.setScheduledtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.hasScheduledtime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string sessionTitle = 3;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.getSessiontitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfo} returns this
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.setSessiontitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string sessionSubTitle = 4;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.getSessionsubtitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfo} returns this
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.setSessionsubtitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string sessionInfo = 5;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.getSessioninfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfo} returns this
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.setSessioninfo = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint32 duration = 6;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfo} returns this
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 registeredUserCount = 7;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.getRegisteredusercount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfo} returns this
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.setRegisteredusercount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 liveParticipantsCount = 8;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.getLiveparticipantscount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfo} returns this
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.setLiveparticipantscount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string aboutSession = 9;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.getAboutsession = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfo} returns this
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.setAboutsession = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string meditationSteps = 10;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.getMeditationsteps = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfo} returns this
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.setMeditationsteps = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int64 associatedCoachId = 11;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.getAssociatedcoachid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfo} returns this
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.setAssociatedcoachid = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * repeated int64 associatedListOfBenefitsID = 12;
 * @return {!Array<number>}
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.getAssociatedlistofbenefitsidList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfo} returns this
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.setAssociatedlistofbenefitsidList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfo} returns this
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.addAssociatedlistofbenefitsid = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfo} returns this
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.clearAssociatedlistofbenefitsidList = function() {
  return this.setAssociatedlistofbenefitsidList([]);
};


/**
 * optional string imageURL = 13;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.getImageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfo} returns this
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.setImageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string videoURL = 14;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.getVideourl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfo} returns this
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.setVideourl = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 15;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfo} returns this
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * optional int64 viewerCountStart = 16;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.getViewercountstart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfo} returns this
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.setViewercountstart = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional int64 viewerCountEnd = 18;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.getViewercountend = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfo} returns this
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.setViewercountend = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional google.protobuf.Timestamp sessionStartTime = 19;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.getSessionstarttime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 19));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfo} returns this
*/
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.setSessionstarttime = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfo} returns this
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.clearSessionstarttime = function() {
  return this.setSessionstarttime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.hasSessionstarttime = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional google.protobuf.Timestamp sessionEndTime = 20;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.getSessionendtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 20));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfo} returns this
*/
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.setSessionendtime = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfo} returns this
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.clearSessionendtime = function() {
  return this.setSessionendtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.hasSessionendtime = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional int64 preRecordedId = 21;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.getPrerecordedid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfo} returns this
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.setPrerecordedid = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional string preRecordedUrl = 22;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.getPrerecordedurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfo} returns this
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.setPrerecordedurl = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional bool updateAllocatedCoach = 23;
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.getUpdateallocatedcoach = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 23, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfo} returns this
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.setUpdateallocatedcoach = function(value) {
  return jspb.Message.setProto3BooleanField(this, 23, value);
};


/**
 * optional string link = 24;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.getLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfo} returns this
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.setLink = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * repeated Tag tags = 25;
 * @return {!Array<!proto.unifiedplatform.liveMeditation.Tag>}
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.liveMeditation.Tag>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.liveMeditation.Tag, 25));
};


/**
 * @param {!Array<!proto.unifiedplatform.liveMeditation.Tag>} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfo} returns this
*/
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 25, value);
};


/**
 * @param {!proto.unifiedplatform.liveMeditation.Tag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.Tag}
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 25, opt_value, proto.unifiedplatform.liveMeditation.Tag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfo} returns this
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * repeated int64 tagIds = 26;
 * @return {!Array<number>}
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.getTagidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 26));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfo} returns this
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.setTagidsList = function(value) {
  return jspb.Message.setField(this, 26, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfo} returns this
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.addTagids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 26, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfo} returns this
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.clearTagidsList = function() {
  return this.setTagidsList([]);
};


/**
 * optional string attribute = 27;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.getAttribute = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfo} returns this
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.setAttribute = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string Topic = 28;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.getTopic = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfo} returns this
 */
proto.unifiedplatform.liveMeditation.SessionInfo.prototype.setTopic = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest.repeatedFields_ = [14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    scheduledtime: (f = msg.getScheduledtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    sessiontitle: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sessionsubtitle: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sessioninfo: jspb.Message.getFieldWithDefault(msg, 4, ""),
    duration: jspb.Message.getFieldWithDefault(msg, 5, 0),
    registeredusercount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    liveparticipantscount: jspb.Message.getFieldWithDefault(msg, 7, 0),
    aboutsession: jspb.Message.getFieldWithDefault(msg, 8, ""),
    meditationsteps: jspb.Message.getFieldWithDefault(msg, 9, ""),
    associatedcoachid: jspb.Message.getFieldWithDefault(msg, 10, 0),
    imageurl: jspb.Message.getFieldWithDefault(msg, 11, ""),
    videourl: jspb.Message.getFieldWithDefault(msg, 12, ""),
    link: jspb.Message.getFieldWithDefault(msg, 13, ""),
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    proto.unifiedplatform.liveMeditation.Tag.toObject, includeInstance),
    attribute: jspb.Message.getFieldWithDefault(msg, 15, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest}
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest;
  return proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest}
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setScheduledtime(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionsubtitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessioninfo(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDuration(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRegisteredusercount(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLiveparticipantscount(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAboutsession(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeditationsteps(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAssociatedcoachid(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageurl(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setVideourl(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink(value);
      break;
    case 14:
      var value = new proto.unifiedplatform.liveMeditation.Tag;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.Tag.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttribute(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScheduledtime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSessiontitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSessionsubtitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSessioninfo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getRegisteredusercount();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getLiveparticipantscount();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getAboutsession();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getMeditationsteps();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAssociatedcoachid();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getImageurl();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getVideourl();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getLink();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.unifiedplatform.liveMeditation.Tag.serializeBinaryToWriter
    );
  }
  f = message.getAttribute();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp scheduledTime = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest.prototype.getScheduledtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest} returns this
*/
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest.prototype.setScheduledtime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest} returns this
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest.prototype.clearScheduledtime = function() {
  return this.setScheduledtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest.prototype.hasScheduledtime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionTitle = 2;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest.prototype.getSessiontitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest} returns this
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest.prototype.setSessiontitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string sessionSubTitle = 3;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest.prototype.getSessionsubtitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest} returns this
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest.prototype.setSessionsubtitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string sessionInfo = 4;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest.prototype.getSessioninfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest} returns this
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest.prototype.setSessioninfo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint32 duration = 5;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest} returns this
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 registeredUserCount = 6;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest.prototype.getRegisteredusercount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest} returns this
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest.prototype.setRegisteredusercount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 liveParticipantsCount = 7;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest.prototype.getLiveparticipantscount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest} returns this
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest.prototype.setLiveparticipantscount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string aboutSession = 8;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest.prototype.getAboutsession = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest} returns this
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest.prototype.setAboutsession = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string meditationSteps = 9;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest.prototype.getMeditationsteps = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest} returns this
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest.prototype.setMeditationsteps = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int64 associatedCoachId = 10;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest.prototype.getAssociatedcoachid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest} returns this
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest.prototype.setAssociatedcoachid = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string imageURL = 11;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest.prototype.getImageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest} returns this
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest.prototype.setImageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string videoURL = 12;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest.prototype.getVideourl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest} returns this
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest.prototype.setVideourl = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string link = 13;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest.prototype.getLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest} returns this
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest.prototype.setLink = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * repeated Tag tags = 14;
 * @return {!Array<!proto.unifiedplatform.liveMeditation.Tag>}
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.liveMeditation.Tag>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.liveMeditation.Tag, 14));
};


/**
 * @param {!Array<!proto.unifiedplatform.liveMeditation.Tag>} value
 * @return {!proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest} returns this
*/
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.unifiedplatform.liveMeditation.Tag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.Tag}
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.unifiedplatform.liveMeditation.Tag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest} returns this
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * optional string attribute = 15;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest.prototype.getAttribute = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest} returns this
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequest.prototype.setAttribute = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.repeatedFields_ = [17];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    scheduledtime: (f = msg.getScheduledtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    sessiontitle: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sessionsubtitle: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sessioninfo: jspb.Message.getFieldWithDefault(msg, 4, ""),
    duration: jspb.Message.getFieldWithDefault(msg, 5, 0),
    registeredusercount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    liveparticipantscount: jspb.Message.getFieldWithDefault(msg, 7, 0),
    aboutsession: jspb.Message.getFieldWithDefault(msg, 8, ""),
    meditationsteps: jspb.Message.getFieldWithDefault(msg, 9, ""),
    associatedcoachid: jspb.Message.getFieldWithDefault(msg, 10, 0),
    imageurl: jspb.Message.getFieldWithDefault(msg, 11, ""),
    videourl: jspb.Message.getFieldWithDefault(msg, 12, ""),
    languagetype: jspb.Message.getFieldWithDefault(msg, 13, 0),
    prerecordedid: jspb.Message.getFieldWithDefault(msg, 14, 0),
    prerecordedurl: jspb.Message.getFieldWithDefault(msg, 15, ""),
    link: jspb.Message.getFieldWithDefault(msg, 16, ""),
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    proto.unifiedplatform.liveMeditation.Tag.toObject, includeInstance),
    attribute: jspb.Message.getFieldWithDefault(msg, 18, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest}
 */
proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest;
  return proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest}
 */
proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setScheduledtime(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionsubtitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessioninfo(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDuration(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRegisteredusercount(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLiveparticipantscount(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAboutsession(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeditationsteps(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAssociatedcoachid(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageurl(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setVideourl(value);
      break;
    case 13:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setLanguagetype(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPrerecordedid(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrerecordedurl(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink(value);
      break;
    case 17:
      var value = new proto.unifiedplatform.liveMeditation.Tag;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.Tag.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttribute(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScheduledtime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSessiontitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSessionsubtitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSessioninfo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getRegisteredusercount();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getLiveparticipantscount();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getAboutsession();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getMeditationsteps();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAssociatedcoachid();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getImageurl();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getVideourl();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getLanguagetype();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getPrerecordedid();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getPrerecordedurl();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getLink();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.unifiedplatform.liveMeditation.Tag.serializeBinaryToWriter
    );
  }
  f = message.getAttribute();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp scheduledTime = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.prototype.getScheduledtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest} returns this
*/
proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.prototype.setScheduledtime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest} returns this
 */
proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.prototype.clearScheduledtime = function() {
  return this.setScheduledtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.prototype.hasScheduledtime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionTitle = 2;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.prototype.getSessiontitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest} returns this
 */
proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.prototype.setSessiontitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string sessionSubTitle = 3;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.prototype.getSessionsubtitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest} returns this
 */
proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.prototype.setSessionsubtitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string sessionInfo = 4;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.prototype.getSessioninfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest} returns this
 */
proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.prototype.setSessioninfo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint32 duration = 5;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest} returns this
 */
proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 registeredUserCount = 6;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.prototype.getRegisteredusercount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest} returns this
 */
proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.prototype.setRegisteredusercount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 liveParticipantsCount = 7;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.prototype.getLiveparticipantscount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest} returns this
 */
proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.prototype.setLiveparticipantscount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string aboutSession = 8;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.prototype.getAboutsession = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest} returns this
 */
proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.prototype.setAboutsession = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string meditationSteps = 9;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.prototype.getMeditationsteps = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest} returns this
 */
proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.prototype.setMeditationsteps = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int64 associatedCoachId = 10;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.prototype.getAssociatedcoachid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest} returns this
 */
proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.prototype.setAssociatedcoachid = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string imageURL = 11;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.prototype.getImageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest} returns this
 */
proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.prototype.setImageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string videoURL = 12;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.prototype.getVideourl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest} returns this
 */
proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.prototype.setVideourl = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType languageType = 13;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.prototype.getLanguagetype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest} returns this
 */
proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.prototype.setLanguagetype = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional int64 preRecordedId = 14;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.prototype.getPrerecordedid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest} returns this
 */
proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.prototype.setPrerecordedid = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional string preRecordedUrl = 15;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.prototype.getPrerecordedurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest} returns this
 */
proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.prototype.setPrerecordedurl = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string link = 16;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.prototype.getLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest} returns this
 */
proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.prototype.setLink = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * repeated Tag tags = 17;
 * @return {!Array<!proto.unifiedplatform.liveMeditation.Tag>}
 */
proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.liveMeditation.Tag>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.liveMeditation.Tag, 17));
};


/**
 * @param {!Array<!proto.unifiedplatform.liveMeditation.Tag>} value
 * @return {!proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest} returns this
*/
proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.unifiedplatform.liveMeditation.Tag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.Tag}
 */
proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.unifiedplatform.liveMeditation.Tag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest} returns this
 */
proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * optional string attribute = 18;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.prototype.getAttribute = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest} returns this
 */
proto.unifiedplatform.liveMeditation.CreateLiveMeditationRequest.prototype.setAttribute = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditation.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditation.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.ResponseLiveMeditation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.ResponseLiveMeditation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditation.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessioninfo: (f = msg.getSessioninfo()) && proto.unifiedplatform.liveMeditation.SessionInfo.toObject(includeInstance, f),
    associatecoach: (f = msg.getAssociatecoach()) && proto.unifiedplatform.liveMeditation.CoachProfile.toObject(includeInstance, f),
    listofbenefitsList: jspb.Message.toObjectList(msg.getListofbenefitsList(),
    proto.unifiedplatform.liveMeditation.SessionBenefits.toObject, includeInstance),
    listofquestionsList: jspb.Message.toObjectList(msg.getListofquestionsList(),
    proto.unifiedplatform.liveMeditation.Question.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseLiveMeditation}
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.ResponseLiveMeditation;
  return proto.unifiedplatform.liveMeditation.ResponseLiveMeditation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.ResponseLiveMeditation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseLiveMeditation}
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.liveMeditation.SessionInfo;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.SessionInfo.deserializeBinaryFromReader);
      msg.setSessioninfo(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.liveMeditation.CoachProfile;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.CoachProfile.deserializeBinaryFromReader);
      msg.setAssociatecoach(value);
      break;
    case 3:
      var value = new proto.unifiedplatform.liveMeditation.SessionBenefits;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.SessionBenefits.deserializeBinaryFromReader);
      msg.addListofbenefits(value);
      break;
    case 4:
      var value = new proto.unifiedplatform.liveMeditation.Question;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.Question.deserializeBinaryFromReader);
      msg.addListofquestions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.ResponseLiveMeditation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.ResponseLiveMeditation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessioninfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.unifiedplatform.liveMeditation.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getAssociatecoach();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.liveMeditation.CoachProfile.serializeBinaryToWriter
    );
  }
  f = message.getListofbenefitsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.unifiedplatform.liveMeditation.SessionBenefits.serializeBinaryToWriter
    );
  }
  f = message.getListofquestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.unifiedplatform.liveMeditation.Question.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo sessionInfo = 1;
 * @return {?proto.unifiedplatform.liveMeditation.SessionInfo}
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditation.prototype.getSessioninfo = function() {
  return /** @type{?proto.unifiedplatform.liveMeditation.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.liveMeditation.SessionInfo, 1));
};


/**
 * @param {?proto.unifiedplatform.liveMeditation.SessionInfo|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseLiveMeditation} returns this
*/
proto.unifiedplatform.liveMeditation.ResponseLiveMeditation.prototype.setSessioninfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseLiveMeditation} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditation.prototype.clearSessioninfo = function() {
  return this.setSessioninfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditation.prototype.hasSessioninfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CoachProfile associateCoach = 2;
 * @return {?proto.unifiedplatform.liveMeditation.CoachProfile}
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditation.prototype.getAssociatecoach = function() {
  return /** @type{?proto.unifiedplatform.liveMeditation.CoachProfile} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.liveMeditation.CoachProfile, 2));
};


/**
 * @param {?proto.unifiedplatform.liveMeditation.CoachProfile|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseLiveMeditation} returns this
*/
proto.unifiedplatform.liveMeditation.ResponseLiveMeditation.prototype.setAssociatecoach = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseLiveMeditation} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditation.prototype.clearAssociatecoach = function() {
  return this.setAssociatecoach(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditation.prototype.hasAssociatecoach = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated SessionBenefits listOfBenefits = 3;
 * @return {!Array<!proto.unifiedplatform.liveMeditation.SessionBenefits>}
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditation.prototype.getListofbenefitsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.liveMeditation.SessionBenefits>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.liveMeditation.SessionBenefits, 3));
};


/**
 * @param {!Array<!proto.unifiedplatform.liveMeditation.SessionBenefits>} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseLiveMeditation} returns this
*/
proto.unifiedplatform.liveMeditation.ResponseLiveMeditation.prototype.setListofbenefitsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.unifiedplatform.liveMeditation.SessionBenefits=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.SessionBenefits}
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditation.prototype.addListofbenefits = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.unifiedplatform.liveMeditation.SessionBenefits, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseLiveMeditation} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditation.prototype.clearListofbenefitsList = function() {
  return this.setListofbenefitsList([]);
};


/**
 * repeated Question listOfQuestions = 4;
 * @return {!Array<!proto.unifiedplatform.liveMeditation.Question>}
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditation.prototype.getListofquestionsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.liveMeditation.Question>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.liveMeditation.Question, 4));
};


/**
 * @param {!Array<!proto.unifiedplatform.liveMeditation.Question>} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseLiveMeditation} returns this
*/
proto.unifiedplatform.liveMeditation.ResponseLiveMeditation.prototype.setListofquestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.unifiedplatform.liveMeditation.Question=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.Question}
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditation.prototype.addListofquestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.unifiedplatform.liveMeditation.Question, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseLiveMeditation} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditation.prototype.clearListofquestionsList = function() {
  return this.setListofquestionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessioninfo: (f = msg.getSessioninfo()) && proto.unifiedplatform.liveMeditation.SessionInfo.toObject(includeInstance, f),
    associatecoach: (f = msg.getAssociatecoach()) && proto.unifiedplatform.liveMeditation.LocaleCoachProfile.toObject(includeInstance, f),
    listofbenefitsList: jspb.Message.toObjectList(msg.getListofbenefitsList(),
    proto.unifiedplatform.liveMeditation.SessionBenefits.toObject, includeInstance),
    listofquestionsList: jspb.Message.toObjectList(msg.getListofquestionsList(),
    proto.unifiedplatform.liveMeditation.Question.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage}
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage;
  return proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage}
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.liveMeditation.SessionInfo;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.SessionInfo.deserializeBinaryFromReader);
      msg.setSessioninfo(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.liveMeditation.LocaleCoachProfile;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.LocaleCoachProfile.deserializeBinaryFromReader);
      msg.setAssociatecoach(value);
      break;
    case 3:
      var value = new proto.unifiedplatform.liveMeditation.SessionBenefits;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.SessionBenefits.deserializeBinaryFromReader);
      msg.addListofbenefits(value);
      break;
    case 4:
      var value = new proto.unifiedplatform.liveMeditation.Question;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.Question.deserializeBinaryFromReader);
      msg.addListofquestions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessioninfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.unifiedplatform.liveMeditation.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getAssociatecoach();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.liveMeditation.LocaleCoachProfile.serializeBinaryToWriter
    );
  }
  f = message.getListofbenefitsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.unifiedplatform.liveMeditation.SessionBenefits.serializeBinaryToWriter
    );
  }
  f = message.getListofquestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.unifiedplatform.liveMeditation.Question.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo sessionInfo = 1;
 * @return {?proto.unifiedplatform.liveMeditation.SessionInfo}
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage.prototype.getSessioninfo = function() {
  return /** @type{?proto.unifiedplatform.liveMeditation.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.liveMeditation.SessionInfo, 1));
};


/**
 * @param {?proto.unifiedplatform.liveMeditation.SessionInfo|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage} returns this
*/
proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage.prototype.setSessioninfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage.prototype.clearSessioninfo = function() {
  return this.setSessioninfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage.prototype.hasSessioninfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional LocaleCoachProfile associateCoach = 2;
 * @return {?proto.unifiedplatform.liveMeditation.LocaleCoachProfile}
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage.prototype.getAssociatecoach = function() {
  return /** @type{?proto.unifiedplatform.liveMeditation.LocaleCoachProfile} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.liveMeditation.LocaleCoachProfile, 2));
};


/**
 * @param {?proto.unifiedplatform.liveMeditation.LocaleCoachProfile|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage} returns this
*/
proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage.prototype.setAssociatecoach = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage.prototype.clearAssociatecoach = function() {
  return this.setAssociatecoach(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage.prototype.hasAssociatecoach = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated SessionBenefits listOfBenefits = 3;
 * @return {!Array<!proto.unifiedplatform.liveMeditation.SessionBenefits>}
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage.prototype.getListofbenefitsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.liveMeditation.SessionBenefits>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.liveMeditation.SessionBenefits, 3));
};


/**
 * @param {!Array<!proto.unifiedplatform.liveMeditation.SessionBenefits>} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage} returns this
*/
proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage.prototype.setListofbenefitsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.unifiedplatform.liveMeditation.SessionBenefits=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.SessionBenefits}
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage.prototype.addListofbenefits = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.unifiedplatform.liveMeditation.SessionBenefits, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage.prototype.clearListofbenefitsList = function() {
  return this.setListofbenefitsList([]);
};


/**
 * repeated Question listOfQuestions = 4;
 * @return {!Array<!proto.unifiedplatform.liveMeditation.Question>}
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage.prototype.getListofquestionsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.liveMeditation.Question>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.liveMeditation.Question, 4));
};


/**
 * @param {!Array<!proto.unifiedplatform.liveMeditation.Question>} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage} returns this
*/
proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage.prototype.setListofquestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.unifiedplatform.liveMeditation.Question=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.Question}
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage.prototype.addListofquestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.unifiedplatform.liveMeditation.Question, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage.prototype.clearListofquestionsList = function() {
  return this.setListofquestionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.LiveMeditation.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.LiveMeditation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.LiveMeditation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.LiveMeditation.toObject = function(includeInstance, msg) {
  var f, obj = {
    coachinfo: (f = msg.getCoachinfo()) && proto.unifiedplatform.liveMeditation.CoachProfile.toObject(includeInstance, f),
    livemeditationsessiondetails: (f = msg.getLivemeditationsessiondetails()) && proto.unifiedplatform.liveMeditation.SessionInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.LiveMeditation}
 */
proto.unifiedplatform.liveMeditation.LiveMeditation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.LiveMeditation;
  return proto.unifiedplatform.liveMeditation.LiveMeditation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.LiveMeditation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.LiveMeditation}
 */
proto.unifiedplatform.liveMeditation.LiveMeditation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.liveMeditation.CoachProfile;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.CoachProfile.deserializeBinaryFromReader);
      msg.setCoachinfo(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.liveMeditation.SessionInfo;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.SessionInfo.deserializeBinaryFromReader);
      msg.setLivemeditationsessiondetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.LiveMeditation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.LiveMeditation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.LiveMeditation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.LiveMeditation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCoachinfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.unifiedplatform.liveMeditation.CoachProfile.serializeBinaryToWriter
    );
  }
  f = message.getLivemeditationsessiondetails();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.liveMeditation.SessionInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional CoachProfile coachInfo = 1;
 * @return {?proto.unifiedplatform.liveMeditation.CoachProfile}
 */
proto.unifiedplatform.liveMeditation.LiveMeditation.prototype.getCoachinfo = function() {
  return /** @type{?proto.unifiedplatform.liveMeditation.CoachProfile} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.liveMeditation.CoachProfile, 1));
};


/**
 * @param {?proto.unifiedplatform.liveMeditation.CoachProfile|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.LiveMeditation} returns this
*/
proto.unifiedplatform.liveMeditation.LiveMeditation.prototype.setCoachinfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.LiveMeditation} returns this
 */
proto.unifiedplatform.liveMeditation.LiveMeditation.prototype.clearCoachinfo = function() {
  return this.setCoachinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.LiveMeditation.prototype.hasCoachinfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo liveMeditationSessionDetails = 2;
 * @return {?proto.unifiedplatform.liveMeditation.SessionInfo}
 */
proto.unifiedplatform.liveMeditation.LiveMeditation.prototype.getLivemeditationsessiondetails = function() {
  return /** @type{?proto.unifiedplatform.liveMeditation.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.liveMeditation.SessionInfo, 2));
};


/**
 * @param {?proto.unifiedplatform.liveMeditation.SessionInfo|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.LiveMeditation} returns this
*/
proto.unifiedplatform.liveMeditation.LiveMeditation.prototype.setLivemeditationsessiondetails = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.LiveMeditation} returns this
 */
proto.unifiedplatform.liveMeditation.LiveMeditation.prototype.clearLivemeditationsessiondetails = function() {
  return this.setLivemeditationsessiondetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.LiveMeditation.prototype.hasLivemeditationsessiondetails = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.Question.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.Question.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.Question} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.Question.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    questiontext: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    livesessionid: jspb.Message.getFieldWithDefault(msg, 4, 0),
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    username: jspb.Message.getFieldWithDefault(msg, 6, ""),
    email: jspb.Message.getFieldWithDefault(msg, 7, ""),
    mobilenumber: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.Question}
 */
proto.unifiedplatform.liveMeditation.Question.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.Question;
  return proto.unifiedplatform.liveMeditation.Question.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.Question} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.Question}
 */
proto.unifiedplatform.liveMeditation.Question.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestiontext(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLivesessionid(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobilenumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.Question.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.Question.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.Question} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.Question.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getQuestiontext();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getLivesessionid();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getMobilenumber();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.Question.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.Question} returns this
 */
proto.unifiedplatform.liveMeditation.Question.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string questionText = 2;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.Question.prototype.getQuestiontext = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.Question} returns this
 */
proto.unifiedplatform.liveMeditation.Question.prototype.setQuestiontext = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 userID = 3;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.Question.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.Question} returns this
 */
proto.unifiedplatform.liveMeditation.Question.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 liveSessionID = 4;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.Question.prototype.getLivesessionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.Question} returns this
 */
proto.unifiedplatform.liveMeditation.Question.prototype.setLivesessionid = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp createdAt = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.liveMeditation.Question.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.Question} returns this
*/
proto.unifiedplatform.liveMeditation.Question.prototype.setCreatedat = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.Question} returns this
 */
proto.unifiedplatform.liveMeditation.Question.prototype.clearCreatedat = function() {
  return this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.Question.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string userName = 6;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.Question.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.Question} returns this
 */
proto.unifiedplatform.liveMeditation.Question.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string email = 7;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.Question.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.Question} returns this
 */
proto.unifiedplatform.liveMeditation.Question.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string mobileNumber = 8;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.Question.prototype.getMobilenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.Question} returns this
 */
proto.unifiedplatform.liveMeditation.Question.prototype.setMobilenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.AddQuestionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.AddQuestionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.AddQuestionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.AddQuestionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    questiontext: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    livesessionid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    username: jspb.Message.getFieldWithDefault(msg, 4, ""),
    email: jspb.Message.getFieldWithDefault(msg, 5, ""),
    mobileNumber: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.AddQuestionRequest}
 */
proto.unifiedplatform.liveMeditation.AddQuestionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.AddQuestionRequest;
  return proto.unifiedplatform.liveMeditation.AddQuestionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.AddQuestionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.AddQuestionRequest}
 */
proto.unifiedplatform.liveMeditation.AddQuestionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestiontext(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLivesessionid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobileNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.AddQuestionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.AddQuestionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.AddQuestionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.AddQuestionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestiontext();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getLivesessionid();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMobileNumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string questionText = 1;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.AddQuestionRequest.prototype.getQuestiontext = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.AddQuestionRequest} returns this
 */
proto.unifiedplatform.liveMeditation.AddQuestionRequest.prototype.setQuestiontext = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 userID = 2;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.AddQuestionRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.AddQuestionRequest} returns this
 */
proto.unifiedplatform.liveMeditation.AddQuestionRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 liveSessionID = 3;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.AddQuestionRequest.prototype.getLivesessionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.AddQuestionRequest} returns this
 */
proto.unifiedplatform.liveMeditation.AddQuestionRequest.prototype.setLivesessionid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string userName = 4;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.AddQuestionRequest.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.AddQuestionRequest} returns this
 */
proto.unifiedplatform.liveMeditation.AddQuestionRequest.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string email = 5;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.AddQuestionRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.AddQuestionRequest} returns this
 */
proto.unifiedplatform.liveMeditation.AddQuestionRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string mobile_number = 6;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.AddQuestionRequest.prototype.getMobileNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.AddQuestionRequest} returns this
 */
proto.unifiedplatform.liveMeditation.AddQuestionRequest.prototype.setMobileNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.ListOfLiveMeditations.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.ListOfLiveMeditations.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.ListOfLiveMeditations.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.ListOfLiveMeditations} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ListOfLiveMeditations.toObject = function(includeInstance, msg) {
  var f, obj = {
    listoflivemeditationsessionList: jspb.Message.toObjectList(msg.getListoflivemeditationsessionList(),
    proto.unifiedplatform.liveMeditation.LiveMeditation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.ListOfLiveMeditations}
 */
proto.unifiedplatform.liveMeditation.ListOfLiveMeditations.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.ListOfLiveMeditations;
  return proto.unifiedplatform.liveMeditation.ListOfLiveMeditations.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.ListOfLiveMeditations} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.ListOfLiveMeditations}
 */
proto.unifiedplatform.liveMeditation.ListOfLiveMeditations.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.liveMeditation.LiveMeditation;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.LiveMeditation.deserializeBinaryFromReader);
      msg.addListoflivemeditationsession(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.ListOfLiveMeditations.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.ListOfLiveMeditations.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.ListOfLiveMeditations} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ListOfLiveMeditations.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListoflivemeditationsessionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.liveMeditation.LiveMeditation.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LiveMeditation listOfLiveMeditationSession = 1;
 * @return {!Array<!proto.unifiedplatform.liveMeditation.LiveMeditation>}
 */
proto.unifiedplatform.liveMeditation.ListOfLiveMeditations.prototype.getListoflivemeditationsessionList = function() {
  return /** @type{!Array<!proto.unifiedplatform.liveMeditation.LiveMeditation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.liveMeditation.LiveMeditation, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.liveMeditation.LiveMeditation>} value
 * @return {!proto.unifiedplatform.liveMeditation.ListOfLiveMeditations} returns this
*/
proto.unifiedplatform.liveMeditation.ListOfLiveMeditations.prototype.setListoflivemeditationsessionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.liveMeditation.LiveMeditation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.LiveMeditation}
 */
proto.unifiedplatform.liveMeditation.ListOfLiveMeditations.prototype.addListoflivemeditationsession = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.liveMeditation.LiveMeditation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.ListOfLiveMeditations} returns this
 */
proto.unifiedplatform.liveMeditation.ListOfLiveMeditations.prototype.clearListoflivemeditationsessionList = function() {
  return this.setListoflivemeditationsessionList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditations.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditations.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditations.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditations} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditations.toObject = function(includeInstance, msg) {
  var f, obj = {
    listoflivemeditationsessionList: jspb.Message.toObjectList(msg.getListoflivemeditationsessionList(),
    proto.unifiedplatform.liveMeditation.ResponseLiveMeditation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditations}
 */
proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditations.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditations;
  return proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditations.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditations} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditations}
 */
proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditations.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.liveMeditation.ResponseLiveMeditation;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.ResponseLiveMeditation.deserializeBinaryFromReader);
      msg.addListoflivemeditationsession(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditations.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditations.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditations} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditations.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListoflivemeditationsessionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.liveMeditation.ResponseLiveMeditation.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ResponseLiveMeditation listOfLiveMeditationSession = 1;
 * @return {!Array<!proto.unifiedplatform.liveMeditation.ResponseLiveMeditation>}
 */
proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditations.prototype.getListoflivemeditationsessionList = function() {
  return /** @type{!Array<!proto.unifiedplatform.liveMeditation.ResponseLiveMeditation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.liveMeditation.ResponseLiveMeditation, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.liveMeditation.ResponseLiveMeditation>} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditations} returns this
*/
proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditations.prototype.setListoflivemeditationsessionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.liveMeditation.ResponseLiveMeditation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.ResponseLiveMeditation}
 */
proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditations.prototype.addListoflivemeditationsession = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.liveMeditation.ResponseLiveMeditation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditations} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditations.prototype.clearListoflivemeditationsessionList = function() {
  return this.setListoflivemeditationsessionList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsWithSelectedLanguage.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsWithSelectedLanguage.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsWithSelectedLanguage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsWithSelectedLanguage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsWithSelectedLanguage.toObject = function(includeInstance, msg) {
  var f, obj = {
    listoflivemeditationsessionList: jspb.Message.toObjectList(msg.getListoflivemeditationsessionList(),
    proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsWithSelectedLanguage}
 */
proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsWithSelectedLanguage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsWithSelectedLanguage;
  return proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsWithSelectedLanguage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsWithSelectedLanguage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsWithSelectedLanguage}
 */
proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsWithSelectedLanguage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage.deserializeBinaryFromReader);
      msg.addListoflivemeditationsession(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsWithSelectedLanguage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsWithSelectedLanguage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsWithSelectedLanguage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsWithSelectedLanguage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListoflivemeditationsessionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ResponseLiveMeditationWithSelectedLanguage listOfLiveMeditationSession = 1;
 * @return {!Array<!proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage>}
 */
proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsWithSelectedLanguage.prototype.getListoflivemeditationsessionList = function() {
  return /** @type{!Array<!proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage>} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsWithSelectedLanguage} returns this
*/
proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsWithSelectedLanguage.prototype.setListoflivemeditationsessionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage}
 */
proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsWithSelectedLanguage.prototype.addListoflivemeditationsession = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsWithSelectedLanguage} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsWithSelectedLanguage.prototype.clearListoflivemeditationsessionList = function() {
  return this.setListoflivemeditationsessionList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.SessionScheduledSuccessfully.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.SessionScheduledSuccessfully.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.SessionScheduledSuccessfully} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.SessionScheduledSuccessfully.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    sessioninfo: (f = msg.getSessioninfo()) && proto.unifiedplatform.liveMeditation.SessionInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.SessionScheduledSuccessfully}
 */
proto.unifiedplatform.liveMeditation.SessionScheduledSuccessfully.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.SessionScheduledSuccessfully;
  return proto.unifiedplatform.liveMeditation.SessionScheduledSuccessfully.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.SessionScheduledSuccessfully} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.SessionScheduledSuccessfully}
 */
proto.unifiedplatform.liveMeditation.SessionScheduledSuccessfully.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.liveMeditation.SessionInfo;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.SessionInfo.deserializeBinaryFromReader);
      msg.setSessioninfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.SessionScheduledSuccessfully.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.SessionScheduledSuccessfully.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.SessionScheduledSuccessfully} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.SessionScheduledSuccessfully.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getSessioninfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.liveMeditation.SessionInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool status = 1;
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.SessionScheduledSuccessfully.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionScheduledSuccessfully} returns this
 */
proto.unifiedplatform.liveMeditation.SessionScheduledSuccessfully.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional SessionInfo sessionInfo = 2;
 * @return {?proto.unifiedplatform.liveMeditation.SessionInfo}
 */
proto.unifiedplatform.liveMeditation.SessionScheduledSuccessfully.prototype.getSessioninfo = function() {
  return /** @type{?proto.unifiedplatform.liveMeditation.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.liveMeditation.SessionInfo, 2));
};


/**
 * @param {?proto.unifiedplatform.liveMeditation.SessionInfo|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionScheduledSuccessfully} returns this
*/
proto.unifiedplatform.liveMeditation.SessionScheduledSuccessfully.prototype.setSessioninfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.SessionScheduledSuccessfully} returns this
 */
proto.unifiedplatform.liveMeditation.SessionScheduledSuccessfully.prototype.clearSessioninfo = function() {
  return this.setSessioninfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.SessionScheduledSuccessfully.prototype.hasSessioninfo = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.ID.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.ID.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.ID} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ID.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.ID}
 */
proto.unifiedplatform.liveMeditation.ID.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.ID;
  return proto.unifiedplatform.liveMeditation.ID.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.ID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.ID}
 */
proto.unifiedplatform.liveMeditation.ID.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.ID.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.ID.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.ID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ID.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 ID = 1;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.ID.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.ID} returns this
 */
proto.unifiedplatform.liveMeditation.ID.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.RequestLiveSessionId.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.RequestLiveSessionId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.RequestLiveSessionId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestLiveSessionId.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 2, 0),
    isexpirycheck: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.RequestLiveSessionId}
 */
proto.unifiedplatform.liveMeditation.RequestLiveSessionId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.RequestLiveSessionId;
  return proto.unifiedplatform.liveMeditation.RequestLiveSessionId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.RequestLiveSessionId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.RequestLiveSessionId}
 */
proto.unifiedplatform.liveMeditation.RequestLiveSessionId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsexpirycheck(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.RequestLiveSessionId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.RequestLiveSessionId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.RequestLiveSessionId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestLiveSessionId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getIsexpirycheck();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional int64 ID = 1;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.RequestLiveSessionId.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestLiveSessionId} returns this
 */
proto.unifiedplatform.liveMeditation.RequestLiveSessionId.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 2;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.liveMeditation.RequestLiveSessionId.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestLiveSessionId} returns this
 */
proto.unifiedplatform.liveMeditation.RequestLiveSessionId.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional bool isExpiryCheck = 3;
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.RequestLiveSessionId.prototype.getIsexpirycheck = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestLiveSessionId} returns this
 */
proto.unifiedplatform.liveMeditation.RequestLiveSessionId.prototype.setIsexpirycheck = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.RequestBenefitAndSessionIDs.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.RequestBenefitAndSessionIDs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.RequestBenefitAndSessionIDs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestBenefitAndSessionIDs.toObject = function(includeInstance, msg) {
  var f, obj = {
    livesessionid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    benefitsid: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.RequestBenefitAndSessionIDs}
 */
proto.unifiedplatform.liveMeditation.RequestBenefitAndSessionIDs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.RequestBenefitAndSessionIDs;
  return proto.unifiedplatform.liveMeditation.RequestBenefitAndSessionIDs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.RequestBenefitAndSessionIDs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.RequestBenefitAndSessionIDs}
 */
proto.unifiedplatform.liveMeditation.RequestBenefitAndSessionIDs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLivesessionid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBenefitsid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.RequestBenefitAndSessionIDs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.RequestBenefitAndSessionIDs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.RequestBenefitAndSessionIDs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestBenefitAndSessionIDs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLivesessionid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getBenefitsid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 liveSessionID = 1;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.RequestBenefitAndSessionIDs.prototype.getLivesessionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestBenefitAndSessionIDs} returns this
 */
proto.unifiedplatform.liveMeditation.RequestBenefitAndSessionIDs.prototype.setLivesessionid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 benefitsID = 2;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.RequestBenefitAndSessionIDs.prototype.getBenefitsid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestBenefitAndSessionIDs} returns this
 */
proto.unifiedplatform.liveMeditation.RequestBenefitAndSessionIDs.prototype.setBenefitsid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.RequestAddBenefitAndSessionIDs.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.RequestAddBenefitAndSessionIDs.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.RequestAddBenefitAndSessionIDs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.RequestAddBenefitAndSessionIDs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestAddBenefitAndSessionIDs.toObject = function(includeInstance, msg) {
  var f, obj = {
    livesessionid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    benefitsidList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.RequestAddBenefitAndSessionIDs}
 */
proto.unifiedplatform.liveMeditation.RequestAddBenefitAndSessionIDs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.RequestAddBenefitAndSessionIDs;
  return proto.unifiedplatform.liveMeditation.RequestAddBenefitAndSessionIDs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.RequestAddBenefitAndSessionIDs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.RequestAddBenefitAndSessionIDs}
 */
proto.unifiedplatform.liveMeditation.RequestAddBenefitAndSessionIDs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLivesessionid(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addBenefitsid(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.RequestAddBenefitAndSessionIDs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.RequestAddBenefitAndSessionIDs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.RequestAddBenefitAndSessionIDs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestAddBenefitAndSessionIDs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLivesessionid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getBenefitsidList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 liveSessionID = 1;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.RequestAddBenefitAndSessionIDs.prototype.getLivesessionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestAddBenefitAndSessionIDs} returns this
 */
proto.unifiedplatform.liveMeditation.RequestAddBenefitAndSessionIDs.prototype.setLivesessionid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated int64 benefitsID = 2;
 * @return {!Array<number>}
 */
proto.unifiedplatform.liveMeditation.RequestAddBenefitAndSessionIDs.prototype.getBenefitsidList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestAddBenefitAndSessionIDs} returns this
 */
proto.unifiedplatform.liveMeditation.RequestAddBenefitAndSessionIDs.prototype.setBenefitsidList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.RequestAddBenefitAndSessionIDs} returns this
 */
proto.unifiedplatform.liveMeditation.RequestAddBenefitAndSessionIDs.prototype.addBenefitsid = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.RequestAddBenefitAndSessionIDs} returns this
 */
proto.unifiedplatform.liveMeditation.RequestAddBenefitAndSessionIDs.prototype.clearBenefitsidList = function() {
  return this.setBenefitsidList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.ResponseStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.ResponseStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.ResponseStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ResponseStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseStatus}
 */
proto.unifiedplatform.liveMeditation.ResponseStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.ResponseStatus;
  return proto.unifiedplatform.liveMeditation.ResponseStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.ResponseStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseStatus}
 */
proto.unifiedplatform.liveMeditation.ResponseStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.ResponseStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.ResponseStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.ResponseStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ResponseStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.ResponseStatus.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseStatus} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseStatus.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.ListOfCoach.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.ListOfCoach.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.ListOfCoach.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.ListOfCoach} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ListOfCoach.toObject = function(includeInstance, msg) {
  var f, obj = {
    listofcoachList: jspb.Message.toObjectList(msg.getListofcoachList(),
    proto.unifiedplatform.liveMeditation.CoachProfile.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.ListOfCoach}
 */
proto.unifiedplatform.liveMeditation.ListOfCoach.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.ListOfCoach;
  return proto.unifiedplatform.liveMeditation.ListOfCoach.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.ListOfCoach} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.ListOfCoach}
 */
proto.unifiedplatform.liveMeditation.ListOfCoach.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.liveMeditation.CoachProfile;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.CoachProfile.deserializeBinaryFromReader);
      msg.addListofcoach(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.ListOfCoach.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.ListOfCoach.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.ListOfCoach} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ListOfCoach.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListofcoachList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.liveMeditation.CoachProfile.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CoachProfile listOfCoach = 1;
 * @return {!Array<!proto.unifiedplatform.liveMeditation.CoachProfile>}
 */
proto.unifiedplatform.liveMeditation.ListOfCoach.prototype.getListofcoachList = function() {
  return /** @type{!Array<!proto.unifiedplatform.liveMeditation.CoachProfile>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.liveMeditation.CoachProfile, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.liveMeditation.CoachProfile>} value
 * @return {!proto.unifiedplatform.liveMeditation.ListOfCoach} returns this
*/
proto.unifiedplatform.liveMeditation.ListOfCoach.prototype.setListofcoachList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.liveMeditation.CoachProfile=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.CoachProfile}
 */
proto.unifiedplatform.liveMeditation.ListOfCoach.prototype.addListofcoach = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.liveMeditation.CoachProfile, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.ListOfCoach} returns this
 */
proto.unifiedplatform.liveMeditation.ListOfCoach.prototype.clearListofcoachList = function() {
  return this.setListofcoachList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.DeleteCoachByIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.DeleteCoachByIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.DeleteCoachByIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.DeleteCoachByIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    coachprofile: (f = msg.getCoachprofile()) && proto.unifiedplatform.liveMeditation.CoachProfile.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.DeleteCoachByIdResponse}
 */
proto.unifiedplatform.liveMeditation.DeleteCoachByIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.DeleteCoachByIdResponse;
  return proto.unifiedplatform.liveMeditation.DeleteCoachByIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.DeleteCoachByIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.DeleteCoachByIdResponse}
 */
proto.unifiedplatform.liveMeditation.DeleteCoachByIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.liveMeditation.CoachProfile;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.CoachProfile.deserializeBinaryFromReader);
      msg.setCoachprofile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.DeleteCoachByIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.DeleteCoachByIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.DeleteCoachByIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.DeleteCoachByIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCoachprofile();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.liveMeditation.CoachProfile.serializeBinaryToWriter
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.DeleteCoachByIdResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.DeleteCoachByIdResponse} returns this
 */
proto.unifiedplatform.liveMeditation.DeleteCoachByIdResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional CoachProfile coachProfile = 2;
 * @return {?proto.unifiedplatform.liveMeditation.CoachProfile}
 */
proto.unifiedplatform.liveMeditation.DeleteCoachByIdResponse.prototype.getCoachprofile = function() {
  return /** @type{?proto.unifiedplatform.liveMeditation.CoachProfile} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.liveMeditation.CoachProfile, 2));
};


/**
 * @param {?proto.unifiedplatform.liveMeditation.CoachProfile|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.DeleteCoachByIdResponse} returns this
*/
proto.unifiedplatform.liveMeditation.DeleteCoachByIdResponse.prototype.setCoachprofile = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.DeleteCoachByIdResponse} returns this
 */
proto.unifiedplatform.liveMeditation.DeleteCoachByIdResponse.prototype.clearCoachprofile = function() {
  return this.setCoachprofile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.DeleteCoachByIdResponse.prototype.hasCoachprofile = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.DeleteScheduleSessionByIDResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.DeleteScheduleSessionByIDResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.DeleteScheduleSessionByIDResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.DeleteScheduleSessionByIDResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessioninfo: (f = msg.getSessioninfo()) && proto.unifiedplatform.liveMeditation.SessionInfo.toObject(includeInstance, f),
    coachprofile: (f = msg.getCoachprofile()) && proto.unifiedplatform.liveMeditation.CoachProfile.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.DeleteScheduleSessionByIDResponse}
 */
proto.unifiedplatform.liveMeditation.DeleteScheduleSessionByIDResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.DeleteScheduleSessionByIDResponse;
  return proto.unifiedplatform.liveMeditation.DeleteScheduleSessionByIDResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.DeleteScheduleSessionByIDResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.DeleteScheduleSessionByIDResponse}
 */
proto.unifiedplatform.liveMeditation.DeleteScheduleSessionByIDResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.liveMeditation.SessionInfo;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.SessionInfo.deserializeBinaryFromReader);
      msg.setSessioninfo(value);
      break;
    case 3:
      var value = new proto.unifiedplatform.liveMeditation.CoachProfile;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.CoachProfile.deserializeBinaryFromReader);
      msg.setCoachprofile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.DeleteScheduleSessionByIDResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.DeleteScheduleSessionByIDResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.DeleteScheduleSessionByIDResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.DeleteScheduleSessionByIDResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessioninfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.liveMeditation.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getCoachprofile();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.unifiedplatform.liveMeditation.CoachProfile.serializeBinaryToWriter
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.DeleteScheduleSessionByIDResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.DeleteScheduleSessionByIDResponse} returns this
 */
proto.unifiedplatform.liveMeditation.DeleteScheduleSessionByIDResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional SessionInfo sessionInfo = 2;
 * @return {?proto.unifiedplatform.liveMeditation.SessionInfo}
 */
proto.unifiedplatform.liveMeditation.DeleteScheduleSessionByIDResponse.prototype.getSessioninfo = function() {
  return /** @type{?proto.unifiedplatform.liveMeditation.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.liveMeditation.SessionInfo, 2));
};


/**
 * @param {?proto.unifiedplatform.liveMeditation.SessionInfo|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.DeleteScheduleSessionByIDResponse} returns this
*/
proto.unifiedplatform.liveMeditation.DeleteScheduleSessionByIDResponse.prototype.setSessioninfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.DeleteScheduleSessionByIDResponse} returns this
 */
proto.unifiedplatform.liveMeditation.DeleteScheduleSessionByIDResponse.prototype.clearSessioninfo = function() {
  return this.setSessioninfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.DeleteScheduleSessionByIDResponse.prototype.hasSessioninfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional CoachProfile coachProfile = 3;
 * @return {?proto.unifiedplatform.liveMeditation.CoachProfile}
 */
proto.unifiedplatform.liveMeditation.DeleteScheduleSessionByIDResponse.prototype.getCoachprofile = function() {
  return /** @type{?proto.unifiedplatform.liveMeditation.CoachProfile} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.liveMeditation.CoachProfile, 3));
};


/**
 * @param {?proto.unifiedplatform.liveMeditation.CoachProfile|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.DeleteScheduleSessionByIDResponse} returns this
*/
proto.unifiedplatform.liveMeditation.DeleteScheduleSessionByIDResponse.prototype.setCoachprofile = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.DeleteScheduleSessionByIDResponse} returns this
 */
proto.unifiedplatform.liveMeditation.DeleteScheduleSessionByIDResponse.prototype.clearCoachprofile = function() {
  return this.setCoachprofile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.DeleteScheduleSessionByIDResponse.prototype.hasCoachprofile = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.ListOfQuestion.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.ListOfQuestion.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.ListOfQuestion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.ListOfQuestion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ListOfQuestion.toObject = function(includeInstance, msg) {
  var f, obj = {
    questionList: jspb.Message.toObjectList(msg.getQuestionList(),
    proto.unifiedplatform.liveMeditation.Question.toObject, includeInstance),
    totalcount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.ListOfQuestion}
 */
proto.unifiedplatform.liveMeditation.ListOfQuestion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.ListOfQuestion;
  return proto.unifiedplatform.liveMeditation.ListOfQuestion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.ListOfQuestion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.ListOfQuestion}
 */
proto.unifiedplatform.liveMeditation.ListOfQuestion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.liveMeditation.Question;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.Question.deserializeBinaryFromReader);
      msg.addQuestion(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalcount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.ListOfQuestion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.ListOfQuestion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.ListOfQuestion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ListOfQuestion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.liveMeditation.Question.serializeBinaryToWriter
    );
  }
  f = message.getTotalcount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated Question question = 1;
 * @return {!Array<!proto.unifiedplatform.liveMeditation.Question>}
 */
proto.unifiedplatform.liveMeditation.ListOfQuestion.prototype.getQuestionList = function() {
  return /** @type{!Array<!proto.unifiedplatform.liveMeditation.Question>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.liveMeditation.Question, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.liveMeditation.Question>} value
 * @return {!proto.unifiedplatform.liveMeditation.ListOfQuestion} returns this
*/
proto.unifiedplatform.liveMeditation.ListOfQuestion.prototype.setQuestionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.liveMeditation.Question=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.Question}
 */
proto.unifiedplatform.liveMeditation.ListOfQuestion.prototype.addQuestion = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.liveMeditation.Question, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.ListOfQuestion} returns this
 */
proto.unifiedplatform.liveMeditation.ListOfQuestion.prototype.clearQuestionList = function() {
  return this.setQuestionList([]);
};


/**
 * optional int64 totalCount = 2;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.ListOfQuestion.prototype.getTotalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.ListOfQuestion} returns this
 */
proto.unifiedplatform.liveMeditation.ListOfQuestion.prototype.setTotalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.RequestQuestionBySessionId.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.RequestQuestionBySessionId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.RequestQuestionBySessionId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestQuestionBySessionId.toObject = function(includeInstance, msg) {
  var f, obj = {
    livesessionid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pagesize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pagenumber: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.RequestQuestionBySessionId}
 */
proto.unifiedplatform.liveMeditation.RequestQuestionBySessionId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.RequestQuestionBySessionId;
  return proto.unifiedplatform.liveMeditation.RequestQuestionBySessionId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.RequestQuestionBySessionId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.RequestQuestionBySessionId}
 */
proto.unifiedplatform.liveMeditation.RequestQuestionBySessionId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLivesessionid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPagesize(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPagenumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.RequestQuestionBySessionId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.RequestQuestionBySessionId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.RequestQuestionBySessionId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestQuestionBySessionId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLivesessionid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPagesize();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getPagenumber();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional int64 liveSessionId = 1;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.RequestQuestionBySessionId.prototype.getLivesessionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestQuestionBySessionId} returns this
 */
proto.unifiedplatform.liveMeditation.RequestQuestionBySessionId.prototype.setLivesessionid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 pageSize = 2;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.RequestQuestionBySessionId.prototype.getPagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestQuestionBySessionId} returns this
 */
proto.unifiedplatform.liveMeditation.RequestQuestionBySessionId.prototype.setPagesize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 pageNumber = 3;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.RequestQuestionBySessionId.prototype.getPagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestQuestionBySessionId} returns this
 */
proto.unifiedplatform.liveMeditation.RequestQuestionBySessionId.prototype.setPagenumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSession.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.RequestGetAllLiveSession.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.RequestGetAllLiveSession} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSession.toObject = function(includeInstance, msg) {
  var f, obj = {
    scheduledtime: (f = msg.getScheduledtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    delayexpirybygivenminutes: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.RequestGetAllLiveSession}
 */
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSession.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.RequestGetAllLiveSession;
  return proto.unifiedplatform.liveMeditation.RequestGetAllLiveSession.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.RequestGetAllLiveSession} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.RequestGetAllLiveSession}
 */
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSession.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setScheduledtime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDelayexpirybygivenminutes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSession.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.RequestGetAllLiveSession.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.RequestGetAllLiveSession} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSession.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScheduledtime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDelayexpirybygivenminutes();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp scheduledTime = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSession.prototype.getScheduledtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestGetAllLiveSession} returns this
*/
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSession.prototype.setScheduledtime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.RequestGetAllLiveSession} returns this
 */
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSession.prototype.clearScheduledtime = function() {
  return this.setScheduledtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSession.prototype.hasScheduledtime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 delayExpiryByGivenMinutes = 2;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSession.prototype.getDelayexpirybygivenminutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestGetAllLiveSession} returns this
 */
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSession.prototype.setDelayexpirybygivenminutes = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionWithSelectedLanguage.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionWithSelectedLanguage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionWithSelectedLanguage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionWithSelectedLanguage.toObject = function(includeInstance, msg) {
  var f, obj = {
    scheduledtime: (f = msg.getScheduledtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    delayexpirybygivenminutes: jspb.Message.getFieldWithDefault(msg, 2, 0),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionWithSelectedLanguage}
 */
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionWithSelectedLanguage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionWithSelectedLanguage;
  return proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionWithSelectedLanguage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionWithSelectedLanguage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionWithSelectedLanguage}
 */
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionWithSelectedLanguage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setScheduledtime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDelayexpirybygivenminutes(value);
      break;
    case 3:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionWithSelectedLanguage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionWithSelectedLanguage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionWithSelectedLanguage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionWithSelectedLanguage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScheduledtime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDelayexpirybygivenminutes();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp scheduledTime = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionWithSelectedLanguage.prototype.getScheduledtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionWithSelectedLanguage} returns this
*/
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionWithSelectedLanguage.prototype.setScheduledtime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionWithSelectedLanguage} returns this
 */
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionWithSelectedLanguage.prototype.clearScheduledtime = function() {
  return this.setScheduledtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionWithSelectedLanguage.prototype.hasScheduledtime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 delayExpiryByGivenMinutes = 2;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionWithSelectedLanguage.prototype.getDelayexpirybygivenminutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionWithSelectedLanguage} returns this
 */
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionWithSelectedLanguage.prototype.setDelayexpirybygivenminutes = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 3;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionWithSelectedLanguage.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionWithSelectedLanguage} returns this
 */
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionWithSelectedLanguage.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.Status.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.Status.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.Status} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.Status.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    successmessage: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.Status}
 */
proto.unifiedplatform.liveMeditation.Status.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.Status;
  return proto.unifiedplatform.liveMeditation.Status.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.Status} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.Status}
 */
proto.unifiedplatform.liveMeditation.Status.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSuccessmessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.Status.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.Status.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.Status} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.Status.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getSuccessmessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool Success = 1;
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.Status.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.liveMeditation.Status} returns this
 */
proto.unifiedplatform.liveMeditation.Status.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string SuccessMessage = 2;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.Status.prototype.getSuccessmessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.Status} returns this
 */
proto.unifiedplatform.liveMeditation.Status.prototype.setSuccessmessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    scheduledtime: (f = msg.getScheduledtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    sessiontitle: (f = msg.getSessiontitle()) && common_common_pb.Locale.toObject(includeInstance, f),
    sessionsubtitle: (f = msg.getSessionsubtitle()) && common_common_pb.Locale.toObject(includeInstance, f),
    sessioninfo: (f = msg.getSessioninfo()) && common_common_pb.Locale.toObject(includeInstance, f),
    duration: jspb.Message.getFieldWithDefault(msg, 5, 0),
    registeredusercount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    liveparticipantscount: jspb.Message.getFieldWithDefault(msg, 7, 0),
    aboutsession: (f = msg.getAboutsession()) && common_common_pb.Locale.toObject(includeInstance, f),
    meditationsteps: (f = msg.getMeditationsteps()) && common_common_pb.Locale.toObject(includeInstance, f),
    associatedcoachid: jspb.Message.getFieldWithDefault(msg, 10, 0),
    imageurl: jspb.Message.getFieldWithDefault(msg, 11, ""),
    videourl: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails}
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails;
  return proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails}
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setScheduledtime(value);
      break;
    case 2:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setSessiontitle(value);
      break;
    case 3:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setSessionsubtitle(value);
      break;
    case 4:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setSessioninfo(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDuration(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRegisteredusercount(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLiveparticipantscount(value);
      break;
    case 8:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setAboutsession(value);
      break;
    case 9:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setMeditationsteps(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAssociatedcoachid(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageurl(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setVideourl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScheduledtime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSessiontitle();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getSessionsubtitle();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getSessioninfo();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getRegisteredusercount();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getLiveparticipantscount();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getAboutsession();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getMeditationsteps();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getAssociatedcoachid();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getImageurl();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getVideourl();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp scheduledTime = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails.prototype.getScheduledtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails} returns this
*/
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails.prototype.setScheduledtime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails} returns this
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails.prototype.clearScheduledtime = function() {
  return this.setScheduledtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails.prototype.hasScheduledtime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale sessionTitle = 2;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails.prototype.getSessiontitle = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 2));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails} returns this
*/
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails.prototype.setSessiontitle = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails} returns this
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails.prototype.clearSessiontitle = function() {
  return this.setSessiontitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails.prototype.hasSessiontitle = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale sessionSubTitle = 3;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails.prototype.getSessionsubtitle = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 3));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails} returns this
*/
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails.prototype.setSessionsubtitle = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails} returns this
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails.prototype.clearSessionsubtitle = function() {
  return this.setSessionsubtitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails.prototype.hasSessionsubtitle = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale sessionInfo = 4;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails.prototype.getSessioninfo = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 4));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails} returns this
*/
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails.prototype.setSessioninfo = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails} returns this
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails.prototype.clearSessioninfo = function() {
  return this.setSessioninfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails.prototype.hasSessioninfo = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional uint32 duration = 5;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails} returns this
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 registeredUserCount = 6;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails.prototype.getRegisteredusercount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails} returns this
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails.prototype.setRegisteredusercount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 liveParticipantsCount = 7;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails.prototype.getLiveparticipantscount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails} returns this
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails.prototype.setLiveparticipantscount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional unifiedplatform.heartintune.common.Locale aboutSession = 8;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails.prototype.getAboutsession = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 8));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails} returns this
*/
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails.prototype.setAboutsession = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails} returns this
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails.prototype.clearAboutsession = function() {
  return this.setAboutsession(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails.prototype.hasAboutsession = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale meditationSteps = 9;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails.prototype.getMeditationsteps = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 9));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails} returns this
*/
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails.prototype.setMeditationsteps = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails} returns this
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails.prototype.clearMeditationsteps = function() {
  return this.setMeditationsteps(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails.prototype.hasMeditationsteps = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional int64 associatedCoachId = 10;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails.prototype.getAssociatedcoachid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails} returns this
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails.prototype.setAssociatedcoachid = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string imageURL = 11;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails.prototype.getImageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails} returns this
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails.prototype.setImageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string videoURL = 12;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails.prototype.getVideourl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails} returns this
 */
proto.unifiedplatform.liveMeditation.AddLiveMeditationRequestDetails.prototype.setVideourl = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.SessionInfoDetails.repeatedFields_ = [12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.SessionInfoDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.SessionInfoDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.SessionInfoDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.SessionInfoDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    scheduledtime: (f = msg.getScheduledtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    sessiontitle: (f = msg.getSessiontitle()) && common_common_pb.Locale.toObject(includeInstance, f),
    sessionsubtitle: (f = msg.getSessionsubtitle()) && common_common_pb.Locale.toObject(includeInstance, f),
    sessioninfo: (f = msg.getSessioninfo()) && common_common_pb.Locale.toObject(includeInstance, f),
    duration: jspb.Message.getFieldWithDefault(msg, 6, 0),
    registeredusercount: jspb.Message.getFieldWithDefault(msg, 7, 0),
    liveparticipantscount: jspb.Message.getFieldWithDefault(msg, 8, 0),
    aboutsession: (f = msg.getAboutsession()) && common_common_pb.Locale.toObject(includeInstance, f),
    meditationsteps: (f = msg.getMeditationsteps()) && common_common_pb.Locale.toObject(includeInstance, f),
    associatedcoachid: jspb.Message.getFieldWithDefault(msg, 11, 0),
    associatedlistofbenefitsidList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
    imageurl: jspb.Message.getFieldWithDefault(msg, 13, ""),
    videourl: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfoDetails}
 */
proto.unifiedplatform.liveMeditation.SessionInfoDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.SessionInfoDetails;
  return proto.unifiedplatform.liveMeditation.SessionInfoDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.SessionInfoDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfoDetails}
 */
proto.unifiedplatform.liveMeditation.SessionInfoDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setScheduledtime(value);
      break;
    case 3:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setSessiontitle(value);
      break;
    case 4:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setSessionsubtitle(value);
      break;
    case 5:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setSessioninfo(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDuration(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRegisteredusercount(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLiveparticipantscount(value);
      break;
    case 9:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setAboutsession(value);
      break;
    case 10:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setMeditationsteps(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAssociatedcoachid(value);
      break;
    case 12:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAssociatedlistofbenefitsid(values[i]);
      }
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageurl(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setVideourl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.SessionInfoDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.SessionInfoDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.SessionInfoDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.SessionInfoDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getScheduledtime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSessiontitle();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getSessionsubtitle();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getSessioninfo();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getRegisteredusercount();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getLiveparticipantscount();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getAboutsession();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getMeditationsteps();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getAssociatedcoachid();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getAssociatedlistofbenefitsidList();
  if (f.length > 0) {
    writer.writePackedInt64(
      12,
      f
    );
  }
  f = message.getImageurl();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getVideourl();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.SessionInfoDetails.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfoDetails} returns this
 */
proto.unifiedplatform.liveMeditation.SessionInfoDetails.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp scheduledTime = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.liveMeditation.SessionInfoDetails.prototype.getScheduledtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfoDetails} returns this
*/
proto.unifiedplatform.liveMeditation.SessionInfoDetails.prototype.setScheduledtime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfoDetails} returns this
 */
proto.unifiedplatform.liveMeditation.SessionInfoDetails.prototype.clearScheduledtime = function() {
  return this.setScheduledtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.SessionInfoDetails.prototype.hasScheduledtime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale sessionTitle = 3;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.liveMeditation.SessionInfoDetails.prototype.getSessiontitle = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 3));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfoDetails} returns this
*/
proto.unifiedplatform.liveMeditation.SessionInfoDetails.prototype.setSessiontitle = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfoDetails} returns this
 */
proto.unifiedplatform.liveMeditation.SessionInfoDetails.prototype.clearSessiontitle = function() {
  return this.setSessiontitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.SessionInfoDetails.prototype.hasSessiontitle = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale sessionSubTitle = 4;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.liveMeditation.SessionInfoDetails.prototype.getSessionsubtitle = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 4));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfoDetails} returns this
*/
proto.unifiedplatform.liveMeditation.SessionInfoDetails.prototype.setSessionsubtitle = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfoDetails} returns this
 */
proto.unifiedplatform.liveMeditation.SessionInfoDetails.prototype.clearSessionsubtitle = function() {
  return this.setSessionsubtitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.SessionInfoDetails.prototype.hasSessionsubtitle = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale sessionInfo = 5;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.liveMeditation.SessionInfoDetails.prototype.getSessioninfo = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 5));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfoDetails} returns this
*/
proto.unifiedplatform.liveMeditation.SessionInfoDetails.prototype.setSessioninfo = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfoDetails} returns this
 */
proto.unifiedplatform.liveMeditation.SessionInfoDetails.prototype.clearSessioninfo = function() {
  return this.setSessioninfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.SessionInfoDetails.prototype.hasSessioninfo = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional uint32 duration = 6;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.SessionInfoDetails.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfoDetails} returns this
 */
proto.unifiedplatform.liveMeditation.SessionInfoDetails.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 registeredUserCount = 7;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.SessionInfoDetails.prototype.getRegisteredusercount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfoDetails} returns this
 */
proto.unifiedplatform.liveMeditation.SessionInfoDetails.prototype.setRegisteredusercount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 liveParticipantsCount = 8;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.SessionInfoDetails.prototype.getLiveparticipantscount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfoDetails} returns this
 */
proto.unifiedplatform.liveMeditation.SessionInfoDetails.prototype.setLiveparticipantscount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional unifiedplatform.heartintune.common.Locale aboutSession = 9;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.liveMeditation.SessionInfoDetails.prototype.getAboutsession = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 9));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfoDetails} returns this
*/
proto.unifiedplatform.liveMeditation.SessionInfoDetails.prototype.setAboutsession = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfoDetails} returns this
 */
proto.unifiedplatform.liveMeditation.SessionInfoDetails.prototype.clearAboutsession = function() {
  return this.setAboutsession(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.SessionInfoDetails.prototype.hasAboutsession = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale meditationSteps = 10;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.liveMeditation.SessionInfoDetails.prototype.getMeditationsteps = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 10));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfoDetails} returns this
*/
proto.unifiedplatform.liveMeditation.SessionInfoDetails.prototype.setMeditationsteps = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfoDetails} returns this
 */
proto.unifiedplatform.liveMeditation.SessionInfoDetails.prototype.clearMeditationsteps = function() {
  return this.setMeditationsteps(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.SessionInfoDetails.prototype.hasMeditationsteps = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional int64 associatedCoachId = 11;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.SessionInfoDetails.prototype.getAssociatedcoachid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfoDetails} returns this
 */
proto.unifiedplatform.liveMeditation.SessionInfoDetails.prototype.setAssociatedcoachid = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * repeated int64 associatedListOfBenefitsID = 12;
 * @return {!Array<number>}
 */
proto.unifiedplatform.liveMeditation.SessionInfoDetails.prototype.getAssociatedlistofbenefitsidList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfoDetails} returns this
 */
proto.unifiedplatform.liveMeditation.SessionInfoDetails.prototype.setAssociatedlistofbenefitsidList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfoDetails} returns this
 */
proto.unifiedplatform.liveMeditation.SessionInfoDetails.prototype.addAssociatedlistofbenefitsid = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfoDetails} returns this
 */
proto.unifiedplatform.liveMeditation.SessionInfoDetails.prototype.clearAssociatedlistofbenefitsidList = function() {
  return this.setAssociatedlistofbenefitsidList([]);
};


/**
 * optional string imageURL = 13;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.SessionInfoDetails.prototype.getImageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfoDetails} returns this
 */
proto.unifiedplatform.liveMeditation.SessionInfoDetails.prototype.setImageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string videoURL = 14;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.SessionInfoDetails.prototype.getVideourl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfoDetails} returns this
 */
proto.unifiedplatform.liveMeditation.SessionInfoDetails.prototype.setVideourl = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.DeleteScheduleSessionDetailsByIDResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.DeleteScheduleSessionDetailsByIDResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.DeleteScheduleSessionDetailsByIDResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.DeleteScheduleSessionDetailsByIDResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessioninfo: (f = msg.getSessioninfo()) && proto.unifiedplatform.liveMeditation.SessionInfoDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.DeleteScheduleSessionDetailsByIDResponse}
 */
proto.unifiedplatform.liveMeditation.DeleteScheduleSessionDetailsByIDResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.DeleteScheduleSessionDetailsByIDResponse;
  return proto.unifiedplatform.liveMeditation.DeleteScheduleSessionDetailsByIDResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.DeleteScheduleSessionDetailsByIDResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.DeleteScheduleSessionDetailsByIDResponse}
 */
proto.unifiedplatform.liveMeditation.DeleteScheduleSessionDetailsByIDResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.liveMeditation.SessionInfoDetails;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.SessionInfoDetails.deserializeBinaryFromReader);
      msg.setSessioninfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.DeleteScheduleSessionDetailsByIDResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.DeleteScheduleSessionDetailsByIDResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.DeleteScheduleSessionDetailsByIDResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.DeleteScheduleSessionDetailsByIDResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessioninfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.liveMeditation.SessionInfoDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.DeleteScheduleSessionDetailsByIDResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.DeleteScheduleSessionDetailsByIDResponse} returns this
 */
proto.unifiedplatform.liveMeditation.DeleteScheduleSessionDetailsByIDResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional SessionInfoDetails sessionInfo = 2;
 * @return {?proto.unifiedplatform.liveMeditation.SessionInfoDetails}
 */
proto.unifiedplatform.liveMeditation.DeleteScheduleSessionDetailsByIDResponse.prototype.getSessioninfo = function() {
  return /** @type{?proto.unifiedplatform.liveMeditation.SessionInfoDetails} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.liveMeditation.SessionInfoDetails, 2));
};


/**
 * @param {?proto.unifiedplatform.liveMeditation.SessionInfoDetails|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.DeleteScheduleSessionDetailsByIDResponse} returns this
*/
proto.unifiedplatform.liveMeditation.DeleteScheduleSessionDetailsByIDResponse.prototype.setSessioninfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.DeleteScheduleSessionDetailsByIDResponse} returns this
 */
proto.unifiedplatform.liveMeditation.DeleteScheduleSessionDetailsByIDResponse.prototype.clearSessioninfo = function() {
  return this.setSessioninfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.DeleteScheduleSessionDetailsByIDResponse.prototype.hasSessioninfo = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsDetails.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    listoflivemeditationsessionList: jspb.Message.toObjectList(msg.getListoflivemeditationsessionList(),
    proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsDetails}
 */
proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsDetails;
  return proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsDetails}
 */
proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails.deserializeBinaryFromReader);
      msg.addListoflivemeditationsession(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListoflivemeditationsessionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ResponseLiveMeditationDetails listOfLiveMeditationSession = 1;
 * @return {!Array<!proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails>}
 */
proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsDetails.prototype.getListoflivemeditationsessionList = function() {
  return /** @type{!Array<!proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails>} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsDetails} returns this
*/
proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsDetails.prototype.setListoflivemeditationsessionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails}
 */
proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsDetails.prototype.addListoflivemeditationsession = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsDetails} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseListOfLiveMeditationsDetails.prototype.clearListoflivemeditationsessionList = function() {
  return this.setListoflivemeditationsessionList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessioninfo: (f = msg.getSessioninfo()) && proto.unifiedplatform.liveMeditation.SessionInfoDetails.toObject(includeInstance, f),
    associatecoach: (f = msg.getAssociatecoach()) && proto.unifiedplatform.liveMeditation.CoachProfile.toObject(includeInstance, f),
    listofbenefitsList: jspb.Message.toObjectList(msg.getListofbenefitsList(),
    proto.unifiedplatform.liveMeditation.SessionBenefitsDetails.toObject, includeInstance),
    listofquestionsList: jspb.Message.toObjectList(msg.getListofquestionsList(),
    proto.unifiedplatform.liveMeditation.Question.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails}
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails;
  return proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails}
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.liveMeditation.SessionInfoDetails;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.SessionInfoDetails.deserializeBinaryFromReader);
      msg.setSessioninfo(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.liveMeditation.CoachProfile;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.CoachProfile.deserializeBinaryFromReader);
      msg.setAssociatecoach(value);
      break;
    case 3:
      var value = new proto.unifiedplatform.liveMeditation.SessionBenefitsDetails;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.SessionBenefitsDetails.deserializeBinaryFromReader);
      msg.addListofbenefits(value);
      break;
    case 4:
      var value = new proto.unifiedplatform.liveMeditation.Question;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.Question.deserializeBinaryFromReader);
      msg.addListofquestions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessioninfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.unifiedplatform.liveMeditation.SessionInfoDetails.serializeBinaryToWriter
    );
  }
  f = message.getAssociatecoach();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.liveMeditation.CoachProfile.serializeBinaryToWriter
    );
  }
  f = message.getListofbenefitsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.unifiedplatform.liveMeditation.SessionBenefitsDetails.serializeBinaryToWriter
    );
  }
  f = message.getListofquestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.unifiedplatform.liveMeditation.Question.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfoDetails sessionInfo = 1;
 * @return {?proto.unifiedplatform.liveMeditation.SessionInfoDetails}
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails.prototype.getSessioninfo = function() {
  return /** @type{?proto.unifiedplatform.liveMeditation.SessionInfoDetails} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.liveMeditation.SessionInfoDetails, 1));
};


/**
 * @param {?proto.unifiedplatform.liveMeditation.SessionInfoDetails|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails} returns this
*/
proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails.prototype.setSessioninfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails.prototype.clearSessioninfo = function() {
  return this.setSessioninfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails.prototype.hasSessioninfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CoachProfile associateCoach = 2;
 * @return {?proto.unifiedplatform.liveMeditation.CoachProfile}
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails.prototype.getAssociatecoach = function() {
  return /** @type{?proto.unifiedplatform.liveMeditation.CoachProfile} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.liveMeditation.CoachProfile, 2));
};


/**
 * @param {?proto.unifiedplatform.liveMeditation.CoachProfile|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails} returns this
*/
proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails.prototype.setAssociatecoach = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails.prototype.clearAssociatecoach = function() {
  return this.setAssociatecoach(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails.prototype.hasAssociatecoach = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated SessionBenefitsDetails listOfBenefits = 3;
 * @return {!Array<!proto.unifiedplatform.liveMeditation.SessionBenefitsDetails>}
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails.prototype.getListofbenefitsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.liveMeditation.SessionBenefitsDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.liveMeditation.SessionBenefitsDetails, 3));
};


/**
 * @param {!Array<!proto.unifiedplatform.liveMeditation.SessionBenefitsDetails>} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails} returns this
*/
proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails.prototype.setListofbenefitsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.unifiedplatform.liveMeditation.SessionBenefitsDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.SessionBenefitsDetails}
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails.prototype.addListofbenefits = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.unifiedplatform.liveMeditation.SessionBenefitsDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails.prototype.clearListofbenefitsList = function() {
  return this.setListofbenefitsList([]);
};


/**
 * repeated Question listOfQuestions = 4;
 * @return {!Array<!proto.unifiedplatform.liveMeditation.Question>}
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails.prototype.getListofquestionsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.liveMeditation.Question>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.liveMeditation.Question, 4));
};


/**
 * @param {!Array<!proto.unifiedplatform.liveMeditation.Question>} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails} returns this
*/
proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails.prototype.setListofquestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.unifiedplatform.liveMeditation.Question=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.Question}
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails.prototype.addListofquestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.unifiedplatform.liveMeditation.Question, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseLiveMeditationDetails.prototype.clearListofquestionsList = function() {
  return this.setListofquestionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.RequestSessionBenefitsDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.RequestSessionBenefitsDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.RequestSessionBenefitsDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestSessionBenefitsDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    imageurl: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: (f = msg.getDescription()) && common_common_pb.Locale.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.RequestSessionBenefitsDetails}
 */
proto.unifiedplatform.liveMeditation.RequestSessionBenefitsDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.RequestSessionBenefitsDetails;
  return proto.unifiedplatform.liveMeditation.RequestSessionBenefitsDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.RequestSessionBenefitsDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.RequestSessionBenefitsDetails}
 */
proto.unifiedplatform.liveMeditation.RequestSessionBenefitsDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageurl(value);
      break;
    case 2:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.RequestSessionBenefitsDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.RequestSessionBenefitsDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.RequestSessionBenefitsDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestSessionBenefitsDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImageurl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
};


/**
 * optional string imageURL = 1;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.RequestSessionBenefitsDetails.prototype.getImageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestSessionBenefitsDetails} returns this
 */
proto.unifiedplatform.liveMeditation.RequestSessionBenefitsDetails.prototype.setImageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.Locale description = 2;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.liveMeditation.RequestSessionBenefitsDetails.prototype.getDescription = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 2));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestSessionBenefitsDetails} returns this
*/
proto.unifiedplatform.liveMeditation.RequestSessionBenefitsDetails.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.RequestSessionBenefitsDetails} returns this
 */
proto.unifiedplatform.liveMeditation.RequestSessionBenefitsDetails.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.RequestSessionBenefitsDetails.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.RequestAddSessionBenefitsDetails.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.RequestAddSessionBenefitsDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.RequestAddSessionBenefitsDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.RequestAddSessionBenefitsDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestAddSessionBenefitsDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionbenefitslistList: jspb.Message.toObjectList(msg.getSessionbenefitslistList(),
    proto.unifiedplatform.liveMeditation.RequestSessionBenefitsDetails.toObject, includeInstance),
    livesessionid: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.RequestAddSessionBenefitsDetails}
 */
proto.unifiedplatform.liveMeditation.RequestAddSessionBenefitsDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.RequestAddSessionBenefitsDetails;
  return proto.unifiedplatform.liveMeditation.RequestAddSessionBenefitsDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.RequestAddSessionBenefitsDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.RequestAddSessionBenefitsDetails}
 */
proto.unifiedplatform.liveMeditation.RequestAddSessionBenefitsDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.liveMeditation.RequestSessionBenefitsDetails;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.RequestSessionBenefitsDetails.deserializeBinaryFromReader);
      msg.addSessionbenefitslist(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLivesessionid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.RequestAddSessionBenefitsDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.RequestAddSessionBenefitsDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.RequestAddSessionBenefitsDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestAddSessionBenefitsDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionbenefitslistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.liveMeditation.RequestSessionBenefitsDetails.serializeBinaryToWriter
    );
  }
  f = message.getLivesessionid();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * repeated RequestSessionBenefitsDetails sessionBenefitsList = 1;
 * @return {!Array<!proto.unifiedplatform.liveMeditation.RequestSessionBenefitsDetails>}
 */
proto.unifiedplatform.liveMeditation.RequestAddSessionBenefitsDetails.prototype.getSessionbenefitslistList = function() {
  return /** @type{!Array<!proto.unifiedplatform.liveMeditation.RequestSessionBenefitsDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.liveMeditation.RequestSessionBenefitsDetails, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.liveMeditation.RequestSessionBenefitsDetails>} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestAddSessionBenefitsDetails} returns this
*/
proto.unifiedplatform.liveMeditation.RequestAddSessionBenefitsDetails.prototype.setSessionbenefitslistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.liveMeditation.RequestSessionBenefitsDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.RequestSessionBenefitsDetails}
 */
proto.unifiedplatform.liveMeditation.RequestAddSessionBenefitsDetails.prototype.addSessionbenefitslist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.liveMeditation.RequestSessionBenefitsDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.RequestAddSessionBenefitsDetails} returns this
 */
proto.unifiedplatform.liveMeditation.RequestAddSessionBenefitsDetails.prototype.clearSessionbenefitslistList = function() {
  return this.setSessionbenefitslistList([]);
};


/**
 * optional int64 liveSessionID = 3;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.RequestAddSessionBenefitsDetails.prototype.getLivesessionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestAddSessionBenefitsDetails} returns this
 */
proto.unifiedplatform.liveMeditation.RequestAddSessionBenefitsDetails.prototype.setLivesessionid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.SessionBenefitsDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.SessionBenefitsDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.SessionBenefitsDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.SessionBenefitsDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    imageurl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: (f = msg.getDescription()) && common_common_pb.Locale.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.SessionBenefitsDetails}
 */
proto.unifiedplatform.liveMeditation.SessionBenefitsDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.SessionBenefitsDetails;
  return proto.unifiedplatform.liveMeditation.SessionBenefitsDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.SessionBenefitsDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.SessionBenefitsDetails}
 */
proto.unifiedplatform.liveMeditation.SessionBenefitsDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageurl(value);
      break;
    case 3:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.SessionBenefitsDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.SessionBenefitsDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.SessionBenefitsDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.SessionBenefitsDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getImageurl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.SessionBenefitsDetails.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionBenefitsDetails} returns this
 */
proto.unifiedplatform.liveMeditation.SessionBenefitsDetails.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string imageURL = 2;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.SessionBenefitsDetails.prototype.getImageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionBenefitsDetails} returns this
 */
proto.unifiedplatform.liveMeditation.SessionBenefitsDetails.prototype.setImageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional unifiedplatform.heartintune.common.Locale description = 3;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.liveMeditation.SessionBenefitsDetails.prototype.getDescription = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 3));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionBenefitsDetails} returns this
*/
proto.unifiedplatform.liveMeditation.SessionBenefitsDetails.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.SessionBenefitsDetails} returns this
 */
proto.unifiedplatform.liveMeditation.SessionBenefitsDetails.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.SessionBenefitsDetails.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.ResponseListOfBenefitsDetails.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.ResponseListOfBenefitsDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.ResponseListOfBenefitsDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.ResponseListOfBenefitsDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ResponseListOfBenefitsDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    livesessionid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    listofbenefitsdetailsList: jspb.Message.toObjectList(msg.getListofbenefitsdetailsList(),
    proto.unifiedplatform.liveMeditation.SessionBenefitsDetails.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseListOfBenefitsDetails}
 */
proto.unifiedplatform.liveMeditation.ResponseListOfBenefitsDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.ResponseListOfBenefitsDetails;
  return proto.unifiedplatform.liveMeditation.ResponseListOfBenefitsDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.ResponseListOfBenefitsDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseListOfBenefitsDetails}
 */
proto.unifiedplatform.liveMeditation.ResponseListOfBenefitsDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLivesessionid(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.liveMeditation.SessionBenefitsDetails;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.SessionBenefitsDetails.deserializeBinaryFromReader);
      msg.addListofbenefitsdetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.ResponseListOfBenefitsDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.ResponseListOfBenefitsDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.ResponseListOfBenefitsDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ResponseListOfBenefitsDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLivesessionid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getListofbenefitsdetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.unifiedplatform.liveMeditation.SessionBenefitsDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 liveSessionID = 1;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.ResponseListOfBenefitsDetails.prototype.getLivesessionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseListOfBenefitsDetails} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseListOfBenefitsDetails.prototype.setLivesessionid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated SessionBenefitsDetails listOfBenefitsDetails = 2;
 * @return {!Array<!proto.unifiedplatform.liveMeditation.SessionBenefitsDetails>}
 */
proto.unifiedplatform.liveMeditation.ResponseListOfBenefitsDetails.prototype.getListofbenefitsdetailsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.liveMeditation.SessionBenefitsDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.liveMeditation.SessionBenefitsDetails, 2));
};


/**
 * @param {!Array<!proto.unifiedplatform.liveMeditation.SessionBenefitsDetails>} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseListOfBenefitsDetails} returns this
*/
proto.unifiedplatform.liveMeditation.ResponseListOfBenefitsDetails.prototype.setListofbenefitsdetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.unifiedplatform.liveMeditation.SessionBenefitsDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.SessionBenefitsDetails}
 */
proto.unifiedplatform.liveMeditation.ResponseListOfBenefitsDetails.prototype.addListofbenefitsdetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.unifiedplatform.liveMeditation.SessionBenefitsDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseListOfBenefitsDetails} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseListOfBenefitsDetails.prototype.clearListofbenefitsdetailsList = function() {
  return this.setListofbenefitsdetailsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.ResponseSessionBenefitsDetails.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.ResponseSessionBenefitsDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.ResponseSessionBenefitsDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.ResponseSessionBenefitsDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ResponseSessionBenefitsDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionbenefitsdetailsList: jspb.Message.toObjectList(msg.getSessionbenefitsdetailsList(),
    proto.unifiedplatform.liveMeditation.SessionBenefitsDetails.toObject, includeInstance),
    livesessionid: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseSessionBenefitsDetails}
 */
proto.unifiedplatform.liveMeditation.ResponseSessionBenefitsDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.ResponseSessionBenefitsDetails;
  return proto.unifiedplatform.liveMeditation.ResponseSessionBenefitsDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.ResponseSessionBenefitsDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseSessionBenefitsDetails}
 */
proto.unifiedplatform.liveMeditation.ResponseSessionBenefitsDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.liveMeditation.SessionBenefitsDetails;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.SessionBenefitsDetails.deserializeBinaryFromReader);
      msg.addSessionbenefitsdetails(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLivesessionid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.ResponseSessionBenefitsDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.ResponseSessionBenefitsDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.ResponseSessionBenefitsDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ResponseSessionBenefitsDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionbenefitsdetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.liveMeditation.SessionBenefitsDetails.serializeBinaryToWriter
    );
  }
  f = message.getLivesessionid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated SessionBenefitsDetails sessionBenefitsDetails = 1;
 * @return {!Array<!proto.unifiedplatform.liveMeditation.SessionBenefitsDetails>}
 */
proto.unifiedplatform.liveMeditation.ResponseSessionBenefitsDetails.prototype.getSessionbenefitsdetailsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.liveMeditation.SessionBenefitsDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.liveMeditation.SessionBenefitsDetails, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.liveMeditation.SessionBenefitsDetails>} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseSessionBenefitsDetails} returns this
*/
proto.unifiedplatform.liveMeditation.ResponseSessionBenefitsDetails.prototype.setSessionbenefitsdetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.liveMeditation.SessionBenefitsDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.SessionBenefitsDetails}
 */
proto.unifiedplatform.liveMeditation.ResponseSessionBenefitsDetails.prototype.addSessionbenefitsdetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.liveMeditation.SessionBenefitsDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseSessionBenefitsDetails} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseSessionBenefitsDetails.prototype.clearSessionbenefitsdetailsList = function() {
  return this.setSessionbenefitsdetailsList([]);
};


/**
 * optional int64 liveSessionID = 2;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.ResponseSessionBenefitsDetails.prototype.getLivesessionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseSessionBenefitsDetails} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseSessionBenefitsDetails.prototype.setLivesessionid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.AwsChannelRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.AwsChannelRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.AwsChannelRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.AwsChannelRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.AwsChannelRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    authorized: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    latencymode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    channelname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    type: jspb.Message.getFieldWithDefault(msg, 4, ""),
    tagsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.AwsChannelRequest}
 */
proto.unifiedplatform.liveMeditation.AwsChannelRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.AwsChannelRequest;
  return proto.unifiedplatform.liveMeditation.AwsChannelRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.AwsChannelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.AwsChannelRequest}
 */
proto.unifiedplatform.liveMeditation.AwsChannelRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAuthorized(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLatencymode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addTags(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.AwsChannelRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.AwsChannelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.AwsChannelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.AwsChannelRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthorized();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getLatencymode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getChannelname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
};


/**
 * optional bool authorized = 1;
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.AwsChannelRequest.prototype.getAuthorized = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.liveMeditation.AwsChannelRequest} returns this
 */
proto.unifiedplatform.liveMeditation.AwsChannelRequest.prototype.setAuthorized = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string latencyMode = 2;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.AwsChannelRequest.prototype.getLatencymode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.AwsChannelRequest} returns this
 */
proto.unifiedplatform.liveMeditation.AwsChannelRequest.prototype.setLatencymode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string channelName = 3;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.AwsChannelRequest.prototype.getChannelname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.AwsChannelRequest} returns this
 */
proto.unifiedplatform.liveMeditation.AwsChannelRequest.prototype.setChannelname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string type = 4;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.AwsChannelRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.AwsChannelRequest} returns this
 */
proto.unifiedplatform.liveMeditation.AwsChannelRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated string tags = 5;
 * @return {!Array<string>}
 */
proto.unifiedplatform.liveMeditation.AwsChannelRequest.prototype.getTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.unifiedplatform.liveMeditation.AwsChannelRequest} returns this
 */
proto.unifiedplatform.liveMeditation.AwsChannelRequest.prototype.setTagsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.AwsChannelRequest} returns this
 */
proto.unifiedplatform.liveMeditation.AwsChannelRequest.prototype.addTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.AwsChannelRequest} returns this
 */
proto.unifiedplatform.liveMeditation.AwsChannelRequest.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.AwsChannelResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.AwsChannelResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.AwsChannelResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.AwsChannelResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    awschannelinfo: (f = msg.getAwschannelinfo()) && proto.unifiedplatform.liveMeditation.AwsChannelInfo.toObject(includeInstance, f),
    awsstreamkey: (f = msg.getAwsstreamkey()) && proto.unifiedplatform.liveMeditation.AwsStreamKey.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.AwsChannelResponse}
 */
proto.unifiedplatform.liveMeditation.AwsChannelResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.AwsChannelResponse;
  return proto.unifiedplatform.liveMeditation.AwsChannelResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.AwsChannelResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.AwsChannelResponse}
 */
proto.unifiedplatform.liveMeditation.AwsChannelResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.liveMeditation.AwsChannelInfo;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.AwsChannelInfo.deserializeBinaryFromReader);
      msg.setAwschannelinfo(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.liveMeditation.AwsStreamKey;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.AwsStreamKey.deserializeBinaryFromReader);
      msg.setAwsstreamkey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.AwsChannelResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.AwsChannelResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.AwsChannelResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.AwsChannelResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAwschannelinfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.unifiedplatform.liveMeditation.AwsChannelInfo.serializeBinaryToWriter
    );
  }
  f = message.getAwsstreamkey();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.liveMeditation.AwsStreamKey.serializeBinaryToWriter
    );
  }
};


/**
 * optional AwsChannelInfo awsChannelInfo = 1;
 * @return {?proto.unifiedplatform.liveMeditation.AwsChannelInfo}
 */
proto.unifiedplatform.liveMeditation.AwsChannelResponse.prototype.getAwschannelinfo = function() {
  return /** @type{?proto.unifiedplatform.liveMeditation.AwsChannelInfo} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.liveMeditation.AwsChannelInfo, 1));
};


/**
 * @param {?proto.unifiedplatform.liveMeditation.AwsChannelInfo|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.AwsChannelResponse} returns this
*/
proto.unifiedplatform.liveMeditation.AwsChannelResponse.prototype.setAwschannelinfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.AwsChannelResponse} returns this
 */
proto.unifiedplatform.liveMeditation.AwsChannelResponse.prototype.clearAwschannelinfo = function() {
  return this.setAwschannelinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.AwsChannelResponse.prototype.hasAwschannelinfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AwsStreamKey awsStreamKey = 2;
 * @return {?proto.unifiedplatform.liveMeditation.AwsStreamKey}
 */
proto.unifiedplatform.liveMeditation.AwsChannelResponse.prototype.getAwsstreamkey = function() {
  return /** @type{?proto.unifiedplatform.liveMeditation.AwsStreamKey} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.liveMeditation.AwsStreamKey, 2));
};


/**
 * @param {?proto.unifiedplatform.liveMeditation.AwsStreamKey|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.AwsChannelResponse} returns this
*/
proto.unifiedplatform.liveMeditation.AwsChannelResponse.prototype.setAwsstreamkey = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.AwsChannelResponse} returns this
 */
proto.unifiedplatform.liveMeditation.AwsChannelResponse.prototype.clearAwsstreamkey = function() {
  return this.setAwsstreamkey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.AwsChannelResponse.prototype.hasAwsstreamkey = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.AwsChannelInfo.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.AwsChannelInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.AwsChannelInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.AwsChannelInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.AwsChannelInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    arn: jspb.Message.getFieldWithDefault(msg, 2, ""),
    authorized: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    ingestendpoint: jspb.Message.getFieldWithDefault(msg, 4, ""),
    latencymode: jspb.Message.getFieldWithDefault(msg, 5, ""),
    channelname: jspb.Message.getFieldWithDefault(msg, 6, ""),
    playbackurl: jspb.Message.getFieldWithDefault(msg, 7, ""),
    tagsList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    type: jspb.Message.getFieldWithDefault(msg, 9, ""),
    associatedstreamid: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.AwsChannelInfo}
 */
proto.unifiedplatform.liveMeditation.AwsChannelInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.AwsChannelInfo;
  return proto.unifiedplatform.liveMeditation.AwsChannelInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.AwsChannelInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.AwsChannelInfo}
 */
proto.unifiedplatform.liveMeditation.AwsChannelInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setArn(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAuthorized(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setIngestendpoint(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLatencymode(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelname(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaybackurl(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addTags(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAssociatedstreamid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.AwsChannelInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.AwsChannelInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.AwsChannelInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.AwsChannelInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getArn();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAuthorized();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getIngestendpoint();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLatencymode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getChannelname();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPlaybackurl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAssociatedstreamid();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.AwsChannelInfo.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.AwsChannelInfo} returns this
 */
proto.unifiedplatform.liveMeditation.AwsChannelInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string arn = 2;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.AwsChannelInfo.prototype.getArn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.AwsChannelInfo} returns this
 */
proto.unifiedplatform.liveMeditation.AwsChannelInfo.prototype.setArn = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool authorized = 3;
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.AwsChannelInfo.prototype.getAuthorized = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.liveMeditation.AwsChannelInfo} returns this
 */
proto.unifiedplatform.liveMeditation.AwsChannelInfo.prototype.setAuthorized = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string ingestEndpoint = 4;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.AwsChannelInfo.prototype.getIngestendpoint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.AwsChannelInfo} returns this
 */
proto.unifiedplatform.liveMeditation.AwsChannelInfo.prototype.setIngestendpoint = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string latencyMode = 5;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.AwsChannelInfo.prototype.getLatencymode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.AwsChannelInfo} returns this
 */
proto.unifiedplatform.liveMeditation.AwsChannelInfo.prototype.setLatencymode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string channelName = 6;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.AwsChannelInfo.prototype.getChannelname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.AwsChannelInfo} returns this
 */
proto.unifiedplatform.liveMeditation.AwsChannelInfo.prototype.setChannelname = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string playBackUrl = 7;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.AwsChannelInfo.prototype.getPlaybackurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.AwsChannelInfo} returns this
 */
proto.unifiedplatform.liveMeditation.AwsChannelInfo.prototype.setPlaybackurl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated string tags = 8;
 * @return {!Array<string>}
 */
proto.unifiedplatform.liveMeditation.AwsChannelInfo.prototype.getTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.unifiedplatform.liveMeditation.AwsChannelInfo} returns this
 */
proto.unifiedplatform.liveMeditation.AwsChannelInfo.prototype.setTagsList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.AwsChannelInfo} returns this
 */
proto.unifiedplatform.liveMeditation.AwsChannelInfo.prototype.addTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.AwsChannelInfo} returns this
 */
proto.unifiedplatform.liveMeditation.AwsChannelInfo.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * optional string type = 9;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.AwsChannelInfo.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.AwsChannelInfo} returns this
 */
proto.unifiedplatform.liveMeditation.AwsChannelInfo.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int64 associatedStreamId = 10;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.AwsChannelInfo.prototype.getAssociatedstreamid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.AwsChannelInfo} returns this
 */
proto.unifiedplatform.liveMeditation.AwsChannelInfo.prototype.setAssociatedstreamid = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.AwsStreamKey.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.AwsStreamKey.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.AwsStreamKey.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.AwsStreamKey} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.AwsStreamKey.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    arn: jspb.Message.getFieldWithDefault(msg, 2, ""),
    channelarn: jspb.Message.getFieldWithDefault(msg, 3, ""),
    tagList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    value: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.AwsStreamKey}
 */
proto.unifiedplatform.liveMeditation.AwsStreamKey.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.AwsStreamKey;
  return proto.unifiedplatform.liveMeditation.AwsStreamKey.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.AwsStreamKey} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.AwsStreamKey}
 */
proto.unifiedplatform.liveMeditation.AwsStreamKey.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setArn(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelarn(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addTag(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.AwsStreamKey.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.AwsStreamKey.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.AwsStreamKey} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.AwsStreamKey.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getArn();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getChannelarn();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTagList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.AwsStreamKey.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.AwsStreamKey} returns this
 */
proto.unifiedplatform.liveMeditation.AwsStreamKey.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string arn = 2;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.AwsStreamKey.prototype.getArn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.AwsStreamKey} returns this
 */
proto.unifiedplatform.liveMeditation.AwsStreamKey.prototype.setArn = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string channelArn = 3;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.AwsStreamKey.prototype.getChannelarn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.AwsStreamKey} returns this
 */
proto.unifiedplatform.liveMeditation.AwsStreamKey.prototype.setChannelarn = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string tag = 4;
 * @return {!Array<string>}
 */
proto.unifiedplatform.liveMeditation.AwsStreamKey.prototype.getTagList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.unifiedplatform.liveMeditation.AwsStreamKey} returns this
 */
proto.unifiedplatform.liveMeditation.AwsStreamKey.prototype.setTagList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.AwsStreamKey} returns this
 */
proto.unifiedplatform.liveMeditation.AwsStreamKey.prototype.addTag = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.AwsStreamKey} returns this
 */
proto.unifiedplatform.liveMeditation.AwsStreamKey.prototype.clearTagList = function() {
  return this.setTagList([]);
};


/**
 * optional string value = 5;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.AwsStreamKey.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.AwsStreamKey} returns this
 */
proto.unifiedplatform.liveMeditation.AwsStreamKey.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.ChannelArn.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.ChannelArn.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.ChannelArn} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ChannelArn.toObject = function(includeInstance, msg) {
  var f, obj = {
    arn: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.ChannelArn}
 */
proto.unifiedplatform.liveMeditation.ChannelArn.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.ChannelArn;
  return proto.unifiedplatform.liveMeditation.ChannelArn.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.ChannelArn} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.ChannelArn}
 */
proto.unifiedplatform.liveMeditation.ChannelArn.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setArn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.ChannelArn.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.ChannelArn.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.ChannelArn} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ChannelArn.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getArn();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string arn = 1;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.ChannelArn.prototype.getArn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.ChannelArn} returns this
 */
proto.unifiedplatform.liveMeditation.ChannelArn.prototype.setArn = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.AwsChannelViewerCountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.AwsChannelViewerCountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.AwsChannelViewerCountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.AwsChannelViewerCountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    health: jspb.Message.getFieldWithDefault(msg, 1, ""),
    starttime: (f = msg.getStarttime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    state: jspb.Message.getFieldWithDefault(msg, 3, ""),
    viewercount: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.AwsChannelViewerCountResponse}
 */
proto.unifiedplatform.liveMeditation.AwsChannelViewerCountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.AwsChannelViewerCountResponse;
  return proto.unifiedplatform.liveMeditation.AwsChannelViewerCountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.AwsChannelViewerCountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.AwsChannelViewerCountResponse}
 */
proto.unifiedplatform.liveMeditation.AwsChannelViewerCountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHealth(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStarttime(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setViewercount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.AwsChannelViewerCountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.AwsChannelViewerCountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.AwsChannelViewerCountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.AwsChannelViewerCountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHealth();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStarttime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getViewercount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional string health = 1;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.AwsChannelViewerCountResponse.prototype.getHealth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.AwsChannelViewerCountResponse} returns this
 */
proto.unifiedplatform.liveMeditation.AwsChannelViewerCountResponse.prototype.setHealth = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp startTime = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.liveMeditation.AwsChannelViewerCountResponse.prototype.getStarttime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.AwsChannelViewerCountResponse} returns this
*/
proto.unifiedplatform.liveMeditation.AwsChannelViewerCountResponse.prototype.setStarttime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.AwsChannelViewerCountResponse} returns this
 */
proto.unifiedplatform.liveMeditation.AwsChannelViewerCountResponse.prototype.clearStarttime = function() {
  return this.setStarttime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.AwsChannelViewerCountResponse.prototype.hasStarttime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string state = 3;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.AwsChannelViewerCountResponse.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.AwsChannelViewerCountResponse} returns this
 */
proto.unifiedplatform.liveMeditation.AwsChannelViewerCountResponse.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 viewerCount = 4;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.AwsChannelViewerCountResponse.prototype.getViewercount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.AwsChannelViewerCountResponse} returns this
 */
proto.unifiedplatform.liveMeditation.AwsChannelViewerCountResponse.prototype.setViewercount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.requestAddLiveMeditationForNextFewDays.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.requestAddLiveMeditationForNextFewDays.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.requestAddLiveMeditationForNextFewDays} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.requestAddLiveMeditationForNextFewDays.toObject = function(includeInstance, msg) {
  var f, obj = {
    currentdate: (f = msg.getCurrentdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.requestAddLiveMeditationForNextFewDays}
 */
proto.unifiedplatform.liveMeditation.requestAddLiveMeditationForNextFewDays.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.requestAddLiveMeditationForNextFewDays;
  return proto.unifiedplatform.liveMeditation.requestAddLiveMeditationForNextFewDays.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.requestAddLiveMeditationForNextFewDays} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.requestAddLiveMeditationForNextFewDays}
 */
proto.unifiedplatform.liveMeditation.requestAddLiveMeditationForNextFewDays.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCurrentdate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.requestAddLiveMeditationForNextFewDays.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.requestAddLiveMeditationForNextFewDays.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.requestAddLiveMeditationForNextFewDays} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.requestAddLiveMeditationForNextFewDays.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrentdate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp currentDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.liveMeditation.requestAddLiveMeditationForNextFewDays.prototype.getCurrentdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.requestAddLiveMeditationForNextFewDays} returns this
*/
proto.unifiedplatform.liveMeditation.requestAddLiveMeditationForNextFewDays.prototype.setCurrentdate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.requestAddLiveMeditationForNextFewDays} returns this
 */
proto.unifiedplatform.liveMeditation.requestAddLiveMeditationForNextFewDays.prototype.clearCurrentdate = function() {
  return this.setCurrentdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.requestAddLiveMeditationForNextFewDays.prototype.hasCurrentdate = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.ResponseAverageRating.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.ResponseAverageRating.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.ResponseAverageRating} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ResponseAverageRating.toObject = function(includeInstance, msg) {
  var f, obj = {
    livesessionid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    status: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    averageraring: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    totalusersrating: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseAverageRating}
 */
proto.unifiedplatform.liveMeditation.ResponseAverageRating.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.ResponseAverageRating;
  return proto.unifiedplatform.liveMeditation.ResponseAverageRating.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.ResponseAverageRating} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseAverageRating}
 */
proto.unifiedplatform.liveMeditation.ResponseAverageRating.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLivesessionid(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAverageraring(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalusersrating(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.ResponseAverageRating.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.ResponseAverageRating.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.ResponseAverageRating} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ResponseAverageRating.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLivesessionid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getAverageraring();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getTotalusersrating();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
};


/**
 * optional int64 liveSessionId = 1;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.ResponseAverageRating.prototype.getLivesessionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseAverageRating} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseAverageRating.prototype.setLivesessionid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool status = 2;
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.ResponseAverageRating.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseAverageRating} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseAverageRating.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional float averageRaring = 3;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.ResponseAverageRating.prototype.getAverageraring = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseAverageRating} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseAverageRating.prototype.setAverageraring = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float totalUsersRating = 4;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.ResponseAverageRating.prototype.getTotalusersrating = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseAverageRating} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseAverageRating.prototype.setTotalusersrating = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.RequestForLiveSessionsBetween.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.RequestForLiveSessionsBetween.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.RequestForLiveSessionsBetween} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestForLiveSessionsBetween.toObject = function(includeInstance, msg) {
  var f, obj = {
    currentdate: (f = msg.getCurrentdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    enddate: (f = msg.getEnddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.RequestForLiveSessionsBetween}
 */
proto.unifiedplatform.liveMeditation.RequestForLiveSessionsBetween.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.RequestForLiveSessionsBetween;
  return proto.unifiedplatform.liveMeditation.RequestForLiveSessionsBetween.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.RequestForLiveSessionsBetween} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.RequestForLiveSessionsBetween}
 */
proto.unifiedplatform.liveMeditation.RequestForLiveSessionsBetween.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCurrentdate(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.RequestForLiveSessionsBetween.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.RequestForLiveSessionsBetween.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.RequestForLiveSessionsBetween} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestForLiveSessionsBetween.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrentdate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnddate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp currentDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.liveMeditation.RequestForLiveSessionsBetween.prototype.getCurrentdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestForLiveSessionsBetween} returns this
*/
proto.unifiedplatform.liveMeditation.RequestForLiveSessionsBetween.prototype.setCurrentdate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.RequestForLiveSessionsBetween} returns this
 */
proto.unifiedplatform.liveMeditation.RequestForLiveSessionsBetween.prototype.clearCurrentdate = function() {
  return this.setCurrentdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.RequestForLiveSessionsBetween.prototype.hasCurrentdate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp endDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.liveMeditation.RequestForLiveSessionsBetween.prototype.getEnddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestForLiveSessionsBetween} returns this
*/
proto.unifiedplatform.liveMeditation.RequestForLiveSessionsBetween.prototype.setEnddate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.RequestForLiveSessionsBetween} returns this
 */
proto.unifiedplatform.liveMeditation.RequestForLiveSessionsBetween.prototype.clearEnddate = function() {
  return this.setEnddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.RequestForLiveSessionsBetween.prototype.hasEnddate = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.ResponseForLiveSessionsBetween.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.ResponseForLiveSessionsBetween.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.ResponseForLiveSessionsBetween.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.ResponseForLiveSessionsBetween} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ResponseForLiveSessionsBetween.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionidList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    nextschedulesessiontime: (f = msg.getNextschedulesessiontime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForLiveSessionsBetween}
 */
proto.unifiedplatform.liveMeditation.ResponseForLiveSessionsBetween.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.ResponseForLiveSessionsBetween;
  return proto.unifiedplatform.liveMeditation.ResponseForLiveSessionsBetween.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.ResponseForLiveSessionsBetween} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForLiveSessionsBetween}
 */
proto.unifiedplatform.liveMeditation.ResponseForLiveSessionsBetween.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSessionid(values[i]);
      }
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setNextschedulesessiontime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.ResponseForLiveSessionsBetween.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.ResponseForLiveSessionsBetween.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.ResponseForLiveSessionsBetween} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ResponseForLiveSessionsBetween.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionidList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getNextschedulesessiontime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * repeated int64 sessionId = 1;
 * @return {!Array<number>}
 */
proto.unifiedplatform.liveMeditation.ResponseForLiveSessionsBetween.prototype.getSessionidList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForLiveSessionsBetween} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseForLiveSessionsBetween.prototype.setSessionidList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForLiveSessionsBetween} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseForLiveSessionsBetween.prototype.addSessionid = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForLiveSessionsBetween} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseForLiveSessionsBetween.prototype.clearSessionidList = function() {
  return this.setSessionidList([]);
};


/**
 * optional google.protobuf.Timestamp nextScheduleSessionTime = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.liveMeditation.ResponseForLiveSessionsBetween.prototype.getNextschedulesessiontime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForLiveSessionsBetween} returns this
*/
proto.unifiedplatform.liveMeditation.ResponseForLiveSessionsBetween.prototype.setNextschedulesessiontime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForLiveSessionsBetween} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseForLiveSessionsBetween.prototype.clearNextschedulesessiontime = function() {
  return this.setNextschedulesessiontime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.ResponseForLiveSessionsBetween.prototype.hasNextschedulesessiontime = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.RequestForLiveSessionExist.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.RequestForLiveSessionExist.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.RequestForLiveSessionExist.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.RequestForLiveSessionExist} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestForLiveSessionExist.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    delayexpirybygivenminutes: jspb.Message.getFieldWithDefault(msg, 2, 0),
    sessionidsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.RequestForLiveSessionExist}
 */
proto.unifiedplatform.liveMeditation.RequestForLiveSessionExist.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.RequestForLiveSessionExist;
  return proto.unifiedplatform.liveMeditation.RequestForLiveSessionExist.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.RequestForLiveSessionExist} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.RequestForLiveSessionExist}
 */
proto.unifiedplatform.liveMeditation.RequestForLiveSessionExist.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDelayexpirybygivenminutes(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSessionids(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.RequestForLiveSessionExist.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.RequestForLiveSessionExist.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.RequestForLiveSessionExist} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestForLiveSessionExist.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDelayexpirybygivenminutes();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getSessionidsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.RequestForLiveSessionExist.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestForLiveSessionExist} returns this
 */
proto.unifiedplatform.liveMeditation.RequestForLiveSessionExist.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 delayExpiryByGivenMinutes = 2;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.RequestForLiveSessionExist.prototype.getDelayexpirybygivenminutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestForLiveSessionExist} returns this
 */
proto.unifiedplatform.liveMeditation.RequestForLiveSessionExist.prototype.setDelayexpirybygivenminutes = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated int64 sessionIds = 3;
 * @return {!Array<number>}
 */
proto.unifiedplatform.liveMeditation.RequestForLiveSessionExist.prototype.getSessionidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestForLiveSessionExist} returns this
 */
proto.unifiedplatform.liveMeditation.RequestForLiveSessionExist.prototype.setSessionidsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.RequestForLiveSessionExist} returns this
 */
proto.unifiedplatform.liveMeditation.RequestForLiveSessionExist.prototype.addSessionids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.RequestForLiveSessionExist} returns this
 */
proto.unifiedplatform.liveMeditation.RequestForLiveSessionExist.prototype.clearSessionidsList = function() {
  return this.setSessionidsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.RequestForGetLiveSessionByCoachId.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.RequestForGetLiveSessionByCoachId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.RequestForGetLiveSessionByCoachId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestForGetLiveSessionByCoachId.toObject = function(includeInstance, msg) {
  var f, obj = {
    coachid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pagesize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pagenumber: jspb.Message.getFieldWithDefault(msg, 3, 0),
    selecteddate: (f = msg.getSelecteddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.RequestForGetLiveSessionByCoachId}
 */
proto.unifiedplatform.liveMeditation.RequestForGetLiveSessionByCoachId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.RequestForGetLiveSessionByCoachId;
  return proto.unifiedplatform.liveMeditation.RequestForGetLiveSessionByCoachId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.RequestForGetLiveSessionByCoachId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.RequestForGetLiveSessionByCoachId}
 */
proto.unifiedplatform.liveMeditation.RequestForGetLiveSessionByCoachId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCoachid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPagesize(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPagenumber(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSelecteddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.RequestForGetLiveSessionByCoachId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.RequestForGetLiveSessionByCoachId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.RequestForGetLiveSessionByCoachId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestForGetLiveSessionByCoachId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCoachid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPagesize();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getPagenumber();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getSelecteddate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 coachId = 1;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.RequestForGetLiveSessionByCoachId.prototype.getCoachid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestForGetLiveSessionByCoachId} returns this
 */
proto.unifiedplatform.liveMeditation.RequestForGetLiveSessionByCoachId.prototype.setCoachid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 pageSize = 2;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.RequestForGetLiveSessionByCoachId.prototype.getPagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestForGetLiveSessionByCoachId} returns this
 */
proto.unifiedplatform.liveMeditation.RequestForGetLiveSessionByCoachId.prototype.setPagesize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 pageNumber = 3;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.RequestForGetLiveSessionByCoachId.prototype.getPagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestForGetLiveSessionByCoachId} returns this
 */
proto.unifiedplatform.liveMeditation.RequestForGetLiveSessionByCoachId.prototype.setPagenumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp selectedDate = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.liveMeditation.RequestForGetLiveSessionByCoachId.prototype.getSelecteddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestForGetLiveSessionByCoachId} returns this
*/
proto.unifiedplatform.liveMeditation.RequestForGetLiveSessionByCoachId.prototype.setSelecteddate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.RequestForGetLiveSessionByCoachId} returns this
 */
proto.unifiedplatform.liveMeditation.RequestForGetLiveSessionByCoachId.prototype.clearSelecteddate = function() {
  return this.setSelecteddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.RequestForGetLiveSessionByCoachId.prototype.hasSelecteddate = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.ResponseForGetLiveSessionByCoachId.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.ResponseForGetLiveSessionByCoachId.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.ResponseForGetLiveSessionByCoachId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.ResponseForGetLiveSessionByCoachId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ResponseForGetLiveSessionByCoachId.toObject = function(includeInstance, msg) {
  var f, obj = {
    livesessionidsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    totalcount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForGetLiveSessionByCoachId}
 */
proto.unifiedplatform.liveMeditation.ResponseForGetLiveSessionByCoachId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.ResponseForGetLiveSessionByCoachId;
  return proto.unifiedplatform.liveMeditation.ResponseForGetLiveSessionByCoachId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.ResponseForGetLiveSessionByCoachId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForGetLiveSessionByCoachId}
 */
proto.unifiedplatform.liveMeditation.ResponseForGetLiveSessionByCoachId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLivesessionids(values[i]);
      }
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalcount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.ResponseForGetLiveSessionByCoachId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.ResponseForGetLiveSessionByCoachId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.ResponseForGetLiveSessionByCoachId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ResponseForGetLiveSessionByCoachId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLivesessionidsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getTotalcount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated int64 liveSessionIds = 1;
 * @return {!Array<number>}
 */
proto.unifiedplatform.liveMeditation.ResponseForGetLiveSessionByCoachId.prototype.getLivesessionidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForGetLiveSessionByCoachId} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseForGetLiveSessionByCoachId.prototype.setLivesessionidsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForGetLiveSessionByCoachId} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseForGetLiveSessionByCoachId.prototype.addLivesessionids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForGetLiveSessionByCoachId} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseForGetLiveSessionByCoachId.prototype.clearLivesessionidsList = function() {
  return this.setLivesessionidsList([]);
};


/**
 * optional int64 totalCount = 2;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.ResponseForGetLiveSessionByCoachId.prototype.getTotalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForGetLiveSessionByCoachId} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseForGetLiveSessionByCoachId.prototype.setTotalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.requestScheduleSessionsDateRange.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.requestScheduleSessionsDateRange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.requestScheduleSessionsDateRange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.requestScheduleSessionsDateRange.toObject = function(includeInstance, msg) {
  var f, obj = {
    startdate: (f = msg.getStartdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    enddate: (f = msg.getEnddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.liveMeditation.RequestPageable.toObject(includeInstance, f),
    getdefinesession: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    selectedlanguage: (f = msg.getSelectedlanguage()) && proto.unifiedplatform.liveMeditation.LocaleType.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.requestScheduleSessionsDateRange}
 */
proto.unifiedplatform.liveMeditation.requestScheduleSessionsDateRange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.requestScheduleSessionsDateRange;
  return proto.unifiedplatform.liveMeditation.requestScheduleSessionsDateRange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.requestScheduleSessionsDateRange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.requestScheduleSessionsDateRange}
 */
proto.unifiedplatform.liveMeditation.requestScheduleSessionsDateRange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartdate(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnddate(value);
      break;
    case 3:
      var value = new proto.unifiedplatform.liveMeditation.RequestPageable;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.RequestPageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGetdefinesession(value);
      break;
    case 5:
      var value = new proto.unifiedplatform.liveMeditation.LocaleType;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.LocaleType.deserializeBinaryFromReader);
      msg.setSelectedlanguage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.requestScheduleSessionsDateRange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.requestScheduleSessionsDateRange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.requestScheduleSessionsDateRange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.requestScheduleSessionsDateRange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartdate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnddate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.unifiedplatform.liveMeditation.RequestPageable.serializeBinaryToWriter
    );
  }
  f = message.getGetdefinesession();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getSelectedlanguage();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.unifiedplatform.liveMeditation.LocaleType.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp startDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.liveMeditation.requestScheduleSessionsDateRange.prototype.getStartdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.requestScheduleSessionsDateRange} returns this
*/
proto.unifiedplatform.liveMeditation.requestScheduleSessionsDateRange.prototype.setStartdate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.requestScheduleSessionsDateRange} returns this
 */
proto.unifiedplatform.liveMeditation.requestScheduleSessionsDateRange.prototype.clearStartdate = function() {
  return this.setStartdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.requestScheduleSessionsDateRange.prototype.hasStartdate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp endDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.liveMeditation.requestScheduleSessionsDateRange.prototype.getEnddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.requestScheduleSessionsDateRange} returns this
*/
proto.unifiedplatform.liveMeditation.requestScheduleSessionsDateRange.prototype.setEnddate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.requestScheduleSessionsDateRange} returns this
 */
proto.unifiedplatform.liveMeditation.requestScheduleSessionsDateRange.prototype.clearEnddate = function() {
  return this.setEnddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.requestScheduleSessionsDateRange.prototype.hasEnddate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional RequestPageable pageable = 3;
 * @return {?proto.unifiedplatform.liveMeditation.RequestPageable}
 */
proto.unifiedplatform.liveMeditation.requestScheduleSessionsDateRange.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.liveMeditation.RequestPageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.liveMeditation.RequestPageable, 3));
};


/**
 * @param {?proto.unifiedplatform.liveMeditation.RequestPageable|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.requestScheduleSessionsDateRange} returns this
*/
proto.unifiedplatform.liveMeditation.requestScheduleSessionsDateRange.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.requestScheduleSessionsDateRange} returns this
 */
proto.unifiedplatform.liveMeditation.requestScheduleSessionsDateRange.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.requestScheduleSessionsDateRange.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool getDefineSession = 4;
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.requestScheduleSessionsDateRange.prototype.getGetdefinesession = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.liveMeditation.requestScheduleSessionsDateRange} returns this
 */
proto.unifiedplatform.liveMeditation.requestScheduleSessionsDateRange.prototype.setGetdefinesession = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional LocaleType selectedLanguage = 5;
 * @return {?proto.unifiedplatform.liveMeditation.LocaleType}
 */
proto.unifiedplatform.liveMeditation.requestScheduleSessionsDateRange.prototype.getSelectedlanguage = function() {
  return /** @type{?proto.unifiedplatform.liveMeditation.LocaleType} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.liveMeditation.LocaleType, 5));
};


/**
 * @param {?proto.unifiedplatform.liveMeditation.LocaleType|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.requestScheduleSessionsDateRange} returns this
*/
proto.unifiedplatform.liveMeditation.requestScheduleSessionsDateRange.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.requestScheduleSessionsDateRange} returns this
 */
proto.unifiedplatform.liveMeditation.requestScheduleSessionsDateRange.prototype.clearSelectedlanguage = function() {
  return this.setSelectedlanguage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.requestScheduleSessionsDateRange.prototype.hasSelectedlanguage = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.scheduleSessionWithCoach.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.scheduleSessionWithCoach.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.scheduleSessionWithCoach} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.scheduleSessionWithCoach.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessioninfo: (f = msg.getSessioninfo()) && proto.unifiedplatform.liveMeditation.SessionInfo.toObject(includeInstance, f),
    coachprofile: (f = msg.getCoachprofile()) && proto.unifiedplatform.liveMeditation.CoachProfile.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.scheduleSessionWithCoach}
 */
proto.unifiedplatform.liveMeditation.scheduleSessionWithCoach.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.scheduleSessionWithCoach;
  return proto.unifiedplatform.liveMeditation.scheduleSessionWithCoach.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.scheduleSessionWithCoach} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.scheduleSessionWithCoach}
 */
proto.unifiedplatform.liveMeditation.scheduleSessionWithCoach.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.liveMeditation.SessionInfo;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.SessionInfo.deserializeBinaryFromReader);
      msg.setSessioninfo(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.liveMeditation.CoachProfile;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.CoachProfile.deserializeBinaryFromReader);
      msg.setCoachprofile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.scheduleSessionWithCoach.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.scheduleSessionWithCoach.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.scheduleSessionWithCoach} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.scheduleSessionWithCoach.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessioninfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.unifiedplatform.liveMeditation.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getCoachprofile();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.liveMeditation.CoachProfile.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo sessionInfo = 1;
 * @return {?proto.unifiedplatform.liveMeditation.SessionInfo}
 */
proto.unifiedplatform.liveMeditation.scheduleSessionWithCoach.prototype.getSessioninfo = function() {
  return /** @type{?proto.unifiedplatform.liveMeditation.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.liveMeditation.SessionInfo, 1));
};


/**
 * @param {?proto.unifiedplatform.liveMeditation.SessionInfo|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.scheduleSessionWithCoach} returns this
*/
proto.unifiedplatform.liveMeditation.scheduleSessionWithCoach.prototype.setSessioninfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.scheduleSessionWithCoach} returns this
 */
proto.unifiedplatform.liveMeditation.scheduleSessionWithCoach.prototype.clearSessioninfo = function() {
  return this.setSessioninfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.scheduleSessionWithCoach.prototype.hasSessioninfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CoachProfile coachProfile = 2;
 * @return {?proto.unifiedplatform.liveMeditation.CoachProfile}
 */
proto.unifiedplatform.liveMeditation.scheduleSessionWithCoach.prototype.getCoachprofile = function() {
  return /** @type{?proto.unifiedplatform.liveMeditation.CoachProfile} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.liveMeditation.CoachProfile, 2));
};


/**
 * @param {?proto.unifiedplatform.liveMeditation.CoachProfile|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.scheduleSessionWithCoach} returns this
*/
proto.unifiedplatform.liveMeditation.scheduleSessionWithCoach.prototype.setCoachprofile = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.scheduleSessionWithCoach} returns this
 */
proto.unifiedplatform.liveMeditation.scheduleSessionWithCoach.prototype.clearCoachprofile = function() {
  return this.setCoachprofile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.scheduleSessionWithCoach.prototype.hasCoachprofile = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.responseScheduleSessionsDateRange.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.responseScheduleSessionsDateRange.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.responseScheduleSessionsDateRange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.responseScheduleSessionsDateRange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.responseScheduleSessionsDateRange.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessioninfodetailsList: jspb.Message.toObjectList(msg.getSessioninfodetailsList(),
    proto.unifiedplatform.liveMeditation.scheduleSessionWithCoach.toObject, includeInstance),
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.liveMeditation.ResponsePageable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.responseScheduleSessionsDateRange}
 */
proto.unifiedplatform.liveMeditation.responseScheduleSessionsDateRange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.responseScheduleSessionsDateRange;
  return proto.unifiedplatform.liveMeditation.responseScheduleSessionsDateRange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.responseScheduleSessionsDateRange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.responseScheduleSessionsDateRange}
 */
proto.unifiedplatform.liveMeditation.responseScheduleSessionsDateRange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.liveMeditation.scheduleSessionWithCoach;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.scheduleSessionWithCoach.deserializeBinaryFromReader);
      msg.addSessioninfodetails(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.liveMeditation.ResponsePageable;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.ResponsePageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.responseScheduleSessionsDateRange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.responseScheduleSessionsDateRange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.responseScheduleSessionsDateRange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.responseScheduleSessionsDateRange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessioninfodetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.liveMeditation.scheduleSessionWithCoach.serializeBinaryToWriter
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.liveMeditation.ResponsePageable.serializeBinaryToWriter
    );
  }
};


/**
 * repeated scheduleSessionWithCoach sessionInfoDetails = 1;
 * @return {!Array<!proto.unifiedplatform.liveMeditation.scheduleSessionWithCoach>}
 */
proto.unifiedplatform.liveMeditation.responseScheduleSessionsDateRange.prototype.getSessioninfodetailsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.liveMeditation.scheduleSessionWithCoach>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.liveMeditation.scheduleSessionWithCoach, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.liveMeditation.scheduleSessionWithCoach>} value
 * @return {!proto.unifiedplatform.liveMeditation.responseScheduleSessionsDateRange} returns this
*/
proto.unifiedplatform.liveMeditation.responseScheduleSessionsDateRange.prototype.setSessioninfodetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.liveMeditation.scheduleSessionWithCoach=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.scheduleSessionWithCoach}
 */
proto.unifiedplatform.liveMeditation.responseScheduleSessionsDateRange.prototype.addSessioninfodetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.liveMeditation.scheduleSessionWithCoach, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.responseScheduleSessionsDateRange} returns this
 */
proto.unifiedplatform.liveMeditation.responseScheduleSessionsDateRange.prototype.clearSessioninfodetailsList = function() {
  return this.setSessioninfodetailsList([]);
};


/**
 * optional ResponsePageable pageable = 2;
 * @return {?proto.unifiedplatform.liveMeditation.ResponsePageable}
 */
proto.unifiedplatform.liveMeditation.responseScheduleSessionsDateRange.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.liveMeditation.ResponsePageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.liveMeditation.ResponsePageable, 2));
};


/**
 * @param {?proto.unifiedplatform.liveMeditation.ResponsePageable|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.responseScheduleSessionsDateRange} returns this
*/
proto.unifiedplatform.liveMeditation.responseScheduleSessionsDateRange.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.responseScheduleSessionsDateRange} returns this
 */
proto.unifiedplatform.liveMeditation.responseScheduleSessionsDateRange.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.responseScheduleSessionsDateRange.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefits.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefits.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefits.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefits} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefits.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionbenefitsList: jspb.Message.toObjectList(msg.getSessionbenefitsList(),
    proto.unifiedplatform.liveMeditation.SessionBenefits.toObject, includeInstance),
    selectedpagesize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    selectedpagenumber: jspb.Message.getFieldWithDefault(msg, 3, 0),
    totalcount: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefits}
 */
proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefits.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefits;
  return proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefits.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefits} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefits}
 */
proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefits.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.liveMeditation.SessionBenefits;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.SessionBenefits.deserializeBinaryFromReader);
      msg.addSessionbenefits(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSelectedpagesize(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSelectedpagenumber(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalcount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefits.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefits.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefits} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefits.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionbenefitsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.liveMeditation.SessionBenefits.serializeBinaryToWriter
    );
  }
  f = message.getSelectedpagesize();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getSelectedpagenumber();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getTotalcount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * repeated SessionBenefits sessionBenefits = 1;
 * @return {!Array<!proto.unifiedplatform.liveMeditation.SessionBenefits>}
 */
proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefits.prototype.getSessionbenefitsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.liveMeditation.SessionBenefits>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.liveMeditation.SessionBenefits, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.liveMeditation.SessionBenefits>} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefits} returns this
*/
proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefits.prototype.setSessionbenefitsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.liveMeditation.SessionBenefits=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.SessionBenefits}
 */
proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefits.prototype.addSessionbenefits = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.liveMeditation.SessionBenefits, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefits} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefits.prototype.clearSessionbenefitsList = function() {
  return this.setSessionbenefitsList([]);
};


/**
 * optional uint32 selectedPageSize = 2;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefits.prototype.getSelectedpagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefits} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefits.prototype.setSelectedpagesize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 selectedPageNumber = 3;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefits.prototype.getSelectedpagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefits} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefits.prototype.setSelectedpagenumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 totalCount = 4;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefits.prototype.getTotalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefits} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefits.prototype.setTotalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.RequestForGetAllBenefits.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.RequestForGetAllBenefits.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.RequestForGetAllBenefits} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestForGetAllBenefits.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagesize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pagenumber: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.RequestForGetAllBenefits}
 */
proto.unifiedplatform.liveMeditation.RequestForGetAllBenefits.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.RequestForGetAllBenefits;
  return proto.unifiedplatform.liveMeditation.RequestForGetAllBenefits.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.RequestForGetAllBenefits} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.RequestForGetAllBenefits}
 */
proto.unifiedplatform.liveMeditation.RequestForGetAllBenefits.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPagesize(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPagenumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.RequestForGetAllBenefits.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.RequestForGetAllBenefits.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.RequestForGetAllBenefits} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestForGetAllBenefits.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagesize();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getPagenumber();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 pageSize = 1;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.RequestForGetAllBenefits.prototype.getPagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestForGetAllBenefits} returns this
 */
proto.unifiedplatform.liveMeditation.RequestForGetAllBenefits.prototype.setPagesize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 pageNumber = 2;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.RequestForGetAllBenefits.prototype.getPagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestForGetAllBenefits} returns this
 */
proto.unifiedplatform.liveMeditation.RequestForGetAllBenefits.prototype.setPagenumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.RequestSetLiveSessionRegisterCount.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.RequestSetLiveSessionRegisterCount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.RequestSetLiveSessionRegisterCount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestSetLiveSessionRegisterCount.toObject = function(includeInstance, msg) {
  var f, obj = {
    livesessionid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.RequestSetLiveSessionRegisterCount}
 */
proto.unifiedplatform.liveMeditation.RequestSetLiveSessionRegisterCount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.RequestSetLiveSessionRegisterCount;
  return proto.unifiedplatform.liveMeditation.RequestSetLiveSessionRegisterCount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.RequestSetLiveSessionRegisterCount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.RequestSetLiveSessionRegisterCount}
 */
proto.unifiedplatform.liveMeditation.RequestSetLiveSessionRegisterCount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLivesessionid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.RequestSetLiveSessionRegisterCount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.RequestSetLiveSessionRegisterCount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.RequestSetLiveSessionRegisterCount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestSetLiveSessionRegisterCount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLivesessionid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional int64 liveSessionId = 1;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.RequestSetLiveSessionRegisterCount.prototype.getLivesessionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestSetLiveSessionRegisterCount} returns this
 */
proto.unifiedplatform.liveMeditation.RequestSetLiveSessionRegisterCount.prototype.setLivesessionid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 count = 2;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.RequestSetLiveSessionRegisterCount.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestSetLiveSessionRegisterCount} returns this
 */
proto.unifiedplatform.liveMeditation.RequestSetLiveSessionRegisterCount.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.RequestForGetAllBenefitsByCoachId.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.RequestForGetAllBenefitsByCoachId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.RequestForGetAllBenefitsByCoachId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestForGetAllBenefitsByCoachId.toObject = function(includeInstance, msg) {
  var f, obj = {
    coachid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pagesize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pagenumber: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.RequestForGetAllBenefitsByCoachId}
 */
proto.unifiedplatform.liveMeditation.RequestForGetAllBenefitsByCoachId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.RequestForGetAllBenefitsByCoachId;
  return proto.unifiedplatform.liveMeditation.RequestForGetAllBenefitsByCoachId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.RequestForGetAllBenefitsByCoachId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.RequestForGetAllBenefitsByCoachId}
 */
proto.unifiedplatform.liveMeditation.RequestForGetAllBenefitsByCoachId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCoachid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPagesize(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPagenumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.RequestForGetAllBenefitsByCoachId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.RequestForGetAllBenefitsByCoachId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.RequestForGetAllBenefitsByCoachId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestForGetAllBenefitsByCoachId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCoachid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPagesize();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getPagenumber();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional int64 coachId = 1;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.RequestForGetAllBenefitsByCoachId.prototype.getCoachid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestForGetAllBenefitsByCoachId} returns this
 */
proto.unifiedplatform.liveMeditation.RequestForGetAllBenefitsByCoachId.prototype.setCoachid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 pageSize = 2;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.RequestForGetAllBenefitsByCoachId.prototype.getPagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestForGetAllBenefitsByCoachId} returns this
 */
proto.unifiedplatform.liveMeditation.RequestForGetAllBenefitsByCoachId.prototype.setPagesize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 pageNumber = 3;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.RequestForGetAllBenefitsByCoachId.prototype.getPagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestForGetAllBenefitsByCoachId} returns this
 */
proto.unifiedplatform.liveMeditation.RequestForGetAllBenefitsByCoachId.prototype.setPagenumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefitsByCoachId.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefitsByCoachId.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefitsByCoachId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefitsByCoachId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefitsByCoachId.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionbenefitsList: jspb.Message.toObjectList(msg.getSessionbenefitsList(),
    proto.unifiedplatform.liveMeditation.SessionBenefits.toObject, includeInstance),
    selectedpagesize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    selectedpagenumber: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefitsByCoachId}
 */
proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefitsByCoachId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefitsByCoachId;
  return proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefitsByCoachId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefitsByCoachId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefitsByCoachId}
 */
proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefitsByCoachId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.liveMeditation.SessionBenefits;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.SessionBenefits.deserializeBinaryFromReader);
      msg.addSessionbenefits(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSelectedpagesize(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSelectedpagenumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefitsByCoachId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefitsByCoachId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefitsByCoachId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefitsByCoachId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionbenefitsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.liveMeditation.SessionBenefits.serializeBinaryToWriter
    );
  }
  f = message.getSelectedpagesize();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getSelectedpagenumber();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * repeated SessionBenefits sessionBenefits = 1;
 * @return {!Array<!proto.unifiedplatform.liveMeditation.SessionBenefits>}
 */
proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefitsByCoachId.prototype.getSessionbenefitsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.liveMeditation.SessionBenefits>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.liveMeditation.SessionBenefits, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.liveMeditation.SessionBenefits>} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefitsByCoachId} returns this
*/
proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefitsByCoachId.prototype.setSessionbenefitsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.liveMeditation.SessionBenefits=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.SessionBenefits}
 */
proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefitsByCoachId.prototype.addSessionbenefits = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.liveMeditation.SessionBenefits, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefitsByCoachId} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefitsByCoachId.prototype.clearSessionbenefitsList = function() {
  return this.setSessionbenefitsList([]);
};


/**
 * optional uint32 selectedPageSize = 2;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefitsByCoachId.prototype.getSelectedpagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefitsByCoachId} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefitsByCoachId.prototype.setSelectedpagesize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 selectedPageNumber = 3;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefitsByCoachId.prototype.getSelectedpagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefitsByCoachId} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseForGetAllBenefitsByCoachId.prototype.setSelectedpagenumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.RequestForGetAllQuestionsWithRegisterCount.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.RequestForGetAllQuestionsWithRegisterCount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.RequestForGetAllQuestionsWithRegisterCount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestForGetAllQuestionsWithRegisterCount.toObject = function(includeInstance, msg) {
  var f, obj = {
    livesessionid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.RequestForGetAllQuestionsWithRegisterCount}
 */
proto.unifiedplatform.liveMeditation.RequestForGetAllQuestionsWithRegisterCount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.RequestForGetAllQuestionsWithRegisterCount;
  return proto.unifiedplatform.liveMeditation.RequestForGetAllQuestionsWithRegisterCount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.RequestForGetAllQuestionsWithRegisterCount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.RequestForGetAllQuestionsWithRegisterCount}
 */
proto.unifiedplatform.liveMeditation.RequestForGetAllQuestionsWithRegisterCount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLivesessionid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.RequestForGetAllQuestionsWithRegisterCount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.RequestForGetAllQuestionsWithRegisterCount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.RequestForGetAllQuestionsWithRegisterCount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestForGetAllQuestionsWithRegisterCount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLivesessionid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 liveSessionId = 1;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.RequestForGetAllQuestionsWithRegisterCount.prototype.getLivesessionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestForGetAllQuestionsWithRegisterCount} returns this
 */
proto.unifiedplatform.liveMeditation.RequestForGetAllQuestionsWithRegisterCount.prototype.setLivesessionid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.ResponseForGetAllQuestionsWithRegisterCount.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.ResponseForGetAllQuestionsWithRegisterCount.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.ResponseForGetAllQuestionsWithRegisterCount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.ResponseForGetAllQuestionsWithRegisterCount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ResponseForGetAllQuestionsWithRegisterCount.toObject = function(includeInstance, msg) {
  var f, obj = {
    questionList: jspb.Message.toObjectList(msg.getQuestionList(),
    proto.unifiedplatform.liveMeditation.Question.toObject, includeInstance),
    sessioninfo: (f = msg.getSessioninfo()) && proto.unifiedplatform.liveMeditation.SessionInfo.toObject(includeInstance, f),
    coachprofile: (f = msg.getCoachprofile()) && proto.unifiedplatform.liveMeditation.CoachProfile.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForGetAllQuestionsWithRegisterCount}
 */
proto.unifiedplatform.liveMeditation.ResponseForGetAllQuestionsWithRegisterCount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.ResponseForGetAllQuestionsWithRegisterCount;
  return proto.unifiedplatform.liveMeditation.ResponseForGetAllQuestionsWithRegisterCount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.ResponseForGetAllQuestionsWithRegisterCount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForGetAllQuestionsWithRegisterCount}
 */
proto.unifiedplatform.liveMeditation.ResponseForGetAllQuestionsWithRegisterCount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.liveMeditation.Question;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.Question.deserializeBinaryFromReader);
      msg.addQuestion(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.liveMeditation.SessionInfo;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.SessionInfo.deserializeBinaryFromReader);
      msg.setSessioninfo(value);
      break;
    case 3:
      var value = new proto.unifiedplatform.liveMeditation.CoachProfile;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.CoachProfile.deserializeBinaryFromReader);
      msg.setCoachprofile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.ResponseForGetAllQuestionsWithRegisterCount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.ResponseForGetAllQuestionsWithRegisterCount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.ResponseForGetAllQuestionsWithRegisterCount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ResponseForGetAllQuestionsWithRegisterCount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.liveMeditation.Question.serializeBinaryToWriter
    );
  }
  f = message.getSessioninfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.liveMeditation.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getCoachprofile();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.unifiedplatform.liveMeditation.CoachProfile.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Question question = 1;
 * @return {!Array<!proto.unifiedplatform.liveMeditation.Question>}
 */
proto.unifiedplatform.liveMeditation.ResponseForGetAllQuestionsWithRegisterCount.prototype.getQuestionList = function() {
  return /** @type{!Array<!proto.unifiedplatform.liveMeditation.Question>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.liveMeditation.Question, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.liveMeditation.Question>} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForGetAllQuestionsWithRegisterCount} returns this
*/
proto.unifiedplatform.liveMeditation.ResponseForGetAllQuestionsWithRegisterCount.prototype.setQuestionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.liveMeditation.Question=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.Question}
 */
proto.unifiedplatform.liveMeditation.ResponseForGetAllQuestionsWithRegisterCount.prototype.addQuestion = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.liveMeditation.Question, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForGetAllQuestionsWithRegisterCount} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseForGetAllQuestionsWithRegisterCount.prototype.clearQuestionList = function() {
  return this.setQuestionList([]);
};


/**
 * optional SessionInfo sessionInfo = 2;
 * @return {?proto.unifiedplatform.liveMeditation.SessionInfo}
 */
proto.unifiedplatform.liveMeditation.ResponseForGetAllQuestionsWithRegisterCount.prototype.getSessioninfo = function() {
  return /** @type{?proto.unifiedplatform.liveMeditation.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.liveMeditation.SessionInfo, 2));
};


/**
 * @param {?proto.unifiedplatform.liveMeditation.SessionInfo|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForGetAllQuestionsWithRegisterCount} returns this
*/
proto.unifiedplatform.liveMeditation.ResponseForGetAllQuestionsWithRegisterCount.prototype.setSessioninfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForGetAllQuestionsWithRegisterCount} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseForGetAllQuestionsWithRegisterCount.prototype.clearSessioninfo = function() {
  return this.setSessioninfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.ResponseForGetAllQuestionsWithRegisterCount.prototype.hasSessioninfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional CoachProfile coachProfile = 3;
 * @return {?proto.unifiedplatform.liveMeditation.CoachProfile}
 */
proto.unifiedplatform.liveMeditation.ResponseForGetAllQuestionsWithRegisterCount.prototype.getCoachprofile = function() {
  return /** @type{?proto.unifiedplatform.liveMeditation.CoachProfile} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.liveMeditation.CoachProfile, 3));
};


/**
 * @param {?proto.unifiedplatform.liveMeditation.CoachProfile|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForGetAllQuestionsWithRegisterCount} returns this
*/
proto.unifiedplatform.liveMeditation.ResponseForGetAllQuestionsWithRegisterCount.prototype.setCoachprofile = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForGetAllQuestionsWithRegisterCount} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseForGetAllQuestionsWithRegisterCount.prototype.clearCoachprofile = function() {
  return this.setCoachprofile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.ResponseForGetAllQuestionsWithRegisterCount.prototype.hasCoachprofile = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.RequestForUserFeedBack.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.RequestForUserFeedBack.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.RequestForUserFeedBack} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestForUserFeedBack.toObject = function(includeInstance, msg) {
  var f, obj = {
    livesessionid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    username: jspb.Message.getFieldWithDefault(msg, 3, ""),
    textissue: jspb.Message.getFieldWithDefault(msg, 4, ""),
    userreporttype: jspb.Message.getFieldWithDefault(msg, 5, 0),
    email: jspb.Message.getFieldWithDefault(msg, 6, ""),
    mobilenumber: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.RequestForUserFeedBack}
 */
proto.unifiedplatform.liveMeditation.RequestForUserFeedBack.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.RequestForUserFeedBack;
  return proto.unifiedplatform.liveMeditation.RequestForUserFeedBack.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.RequestForUserFeedBack} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.RequestForUserFeedBack}
 */
proto.unifiedplatform.liveMeditation.RequestForUserFeedBack.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLivesessionid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTextissue(value);
      break;
    case 5:
      var value = /** @type {!proto.unifiedplatform.liveMeditation.UserReportType} */ (reader.readEnum());
      msg.setUserreporttype(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobilenumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.RequestForUserFeedBack.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.RequestForUserFeedBack.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.RequestForUserFeedBack} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestForUserFeedBack.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLivesessionid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTextissue();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUserreporttype();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getMobilenumber();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional int64 liveSessionId = 1;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.RequestForUserFeedBack.prototype.getLivesessionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestForUserFeedBack} returns this
 */
proto.unifiedplatform.liveMeditation.RequestForUserFeedBack.prototype.setLivesessionid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 userId = 2;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.RequestForUserFeedBack.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestForUserFeedBack} returns this
 */
proto.unifiedplatform.liveMeditation.RequestForUserFeedBack.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string userName = 3;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.RequestForUserFeedBack.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestForUserFeedBack} returns this
 */
proto.unifiedplatform.liveMeditation.RequestForUserFeedBack.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string textIssue = 4;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.RequestForUserFeedBack.prototype.getTextissue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestForUserFeedBack} returns this
 */
proto.unifiedplatform.liveMeditation.RequestForUserFeedBack.prototype.setTextissue = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional UserReportType userReportType = 5;
 * @return {!proto.unifiedplatform.liveMeditation.UserReportType}
 */
proto.unifiedplatform.liveMeditation.RequestForUserFeedBack.prototype.getUserreporttype = function() {
  return /** @type {!proto.unifiedplatform.liveMeditation.UserReportType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.unifiedplatform.liveMeditation.UserReportType} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestForUserFeedBack} returns this
 */
proto.unifiedplatform.liveMeditation.RequestForUserFeedBack.prototype.setUserreporttype = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string email = 6;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.RequestForUserFeedBack.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestForUserFeedBack} returns this
 */
proto.unifiedplatform.liveMeditation.RequestForUserFeedBack.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string mobileNumber = 7;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.RequestForUserFeedBack.prototype.getMobilenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestForUserFeedBack} returns this
 */
proto.unifiedplatform.liveMeditation.RequestForUserFeedBack.prototype.setMobilenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    livesessionid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    username: jspb.Message.getFieldWithDefault(msg, 4, ""),
    textissue: jspb.Message.getFieldWithDefault(msg, 5, ""),
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    userreporttype: jspb.Message.getFieldWithDefault(msg, 7, 0),
    email: jspb.Message.getFieldWithDefault(msg, 8, ""),
    mobilenumber: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack}
 */
proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack;
  return proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack}
 */
proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLivesessionid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTextissue(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 7:
      var value = /** @type {!proto.unifiedplatform.liveMeditation.UserReportType} */ (reader.readEnum());
      msg.setUserreporttype(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobilenumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLivesessionid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTextissue();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUserreporttype();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getMobilenumber();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack} returns this
 */
proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 liveSessionId = 2;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack.prototype.getLivesessionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack} returns this
 */
proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack.prototype.setLivesessionid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 userId = 3;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack} returns this
 */
proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string userName = 4;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack} returns this
 */
proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string textIssue = 5;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack.prototype.getTextissue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack} returns this
 */
proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack.prototype.setTextissue = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp timestamp = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack} returns this
*/
proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack} returns this
 */
proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional UserReportType userReportType = 7;
 * @return {!proto.unifiedplatform.liveMeditation.UserReportType}
 */
proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack.prototype.getUserreporttype = function() {
  return /** @type {!proto.unifiedplatform.liveMeditation.UserReportType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.unifiedplatform.liveMeditation.UserReportType} value
 * @return {!proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack} returns this
 */
proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack.prototype.setUserreporttype = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional string email = 8;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack} returns this
 */
proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string mobileNumber = 9;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack.prototype.getMobilenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack} returns this
 */
proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack.prototype.setMobilenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.ListOfUserFeedBacks.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.ListOfUserFeedBacks.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.ListOfUserFeedBacks.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.ListOfUserFeedBacks} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ListOfUserFeedBacks.toObject = function(includeInstance, msg) {
  var f, obj = {
    userlivesessionfeedbackList: jspb.Message.toObjectList(msg.getUserlivesessionfeedbackList(),
    proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack.toObject, includeInstance),
    responsepageable: (f = msg.getResponsepageable()) && proto.unifiedplatform.liveMeditation.ResponsePageable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.ListOfUserFeedBacks}
 */
proto.unifiedplatform.liveMeditation.ListOfUserFeedBacks.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.ListOfUserFeedBacks;
  return proto.unifiedplatform.liveMeditation.ListOfUserFeedBacks.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.ListOfUserFeedBacks} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.ListOfUserFeedBacks}
 */
proto.unifiedplatform.liveMeditation.ListOfUserFeedBacks.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack.deserializeBinaryFromReader);
      msg.addUserlivesessionfeedback(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.liveMeditation.ResponsePageable;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.ResponsePageable.deserializeBinaryFromReader);
      msg.setResponsepageable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.ListOfUserFeedBacks.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.ListOfUserFeedBacks.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.ListOfUserFeedBacks} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ListOfUserFeedBacks.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserlivesessionfeedbackList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack.serializeBinaryToWriter
    );
  }
  f = message.getResponsepageable();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.liveMeditation.ResponsePageable.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UserLiveSessionFeedBack userLiveSessionFeedBack = 1;
 * @return {!Array<!proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack>}
 */
proto.unifiedplatform.liveMeditation.ListOfUserFeedBacks.prototype.getUserlivesessionfeedbackList = function() {
  return /** @type{!Array<!proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack>} value
 * @return {!proto.unifiedplatform.liveMeditation.ListOfUserFeedBacks} returns this
*/
proto.unifiedplatform.liveMeditation.ListOfUserFeedBacks.prototype.setUserlivesessionfeedbackList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack}
 */
proto.unifiedplatform.liveMeditation.ListOfUserFeedBacks.prototype.addUserlivesessionfeedback = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.ListOfUserFeedBacks} returns this
 */
proto.unifiedplatform.liveMeditation.ListOfUserFeedBacks.prototype.clearUserlivesessionfeedbackList = function() {
  return this.setUserlivesessionfeedbackList([]);
};


/**
 * optional ResponsePageable responsePageable = 2;
 * @return {?proto.unifiedplatform.liveMeditation.ResponsePageable}
 */
proto.unifiedplatform.liveMeditation.ListOfUserFeedBacks.prototype.getResponsepageable = function() {
  return /** @type{?proto.unifiedplatform.liveMeditation.ResponsePageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.liveMeditation.ResponsePageable, 2));
};


/**
 * @param {?proto.unifiedplatform.liveMeditation.ResponsePageable|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.ListOfUserFeedBacks} returns this
*/
proto.unifiedplatform.liveMeditation.ListOfUserFeedBacks.prototype.setResponsepageable = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.ListOfUserFeedBacks} returns this
 */
proto.unifiedplatform.liveMeditation.ListOfUserFeedBacks.prototype.clearResponsepageable = function() {
  return this.setResponsepageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.ListOfUserFeedBacks.prototype.hasResponsepageable = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.RequestPageable.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.RequestPageable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.RequestPageable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestPageable.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagesize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pagenumber: jspb.Message.getFieldWithDefault(msg, 3, 0),
    isunpublished: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.RequestPageable}
 */
proto.unifiedplatform.liveMeditation.RequestPageable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.RequestPageable;
  return proto.unifiedplatform.liveMeditation.RequestPageable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.RequestPageable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.RequestPageable}
 */
proto.unifiedplatform.liveMeditation.RequestPageable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPagesize(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPagenumber(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsunpublished(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.RequestPageable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.RequestPageable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.RequestPageable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestPageable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagesize();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getPagenumber();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getIsunpublished();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional uint32 pageSize = 2;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.RequestPageable.prototype.getPagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestPageable} returns this
 */
proto.unifiedplatform.liveMeditation.RequestPageable.prototype.setPagesize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 pageNumber = 3;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.RequestPageable.prototype.getPagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestPageable} returns this
 */
proto.unifiedplatform.liveMeditation.RequestPageable.prototype.setPagenumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool isUnPublished = 4;
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.RequestPageable.prototype.getIsunpublished = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestPageable} returns this
 */
proto.unifiedplatform.liveMeditation.RequestPageable.prototype.setIsunpublished = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.ResponsePageable.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.ResponsePageable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.ResponsePageable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ResponsePageable.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalcount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    selectedpagesize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    selectedpagenumber: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.ResponsePageable}
 */
proto.unifiedplatform.liveMeditation.ResponsePageable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.ResponsePageable;
  return proto.unifiedplatform.liveMeditation.ResponsePageable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.ResponsePageable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.ResponsePageable}
 */
proto.unifiedplatform.liveMeditation.ResponsePageable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalcount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSelectedpagesize(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSelectedpagenumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.ResponsePageable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.ResponsePageable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.ResponsePageable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ResponsePageable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalcount();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSelectedpagesize();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getSelectedpagenumber();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional int64 totalCount = 1;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.ResponsePageable.prototype.getTotalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponsePageable} returns this
 */
proto.unifiedplatform.liveMeditation.ResponsePageable.prototype.setTotalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 selectedPageSize = 2;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.ResponsePageable.prototype.getSelectedpagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponsePageable} returns this
 */
proto.unifiedplatform.liveMeditation.ResponsePageable.prototype.setSelectedpagesize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 selectedPageNumber = 3;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.ResponsePageable.prototype.getSelectedpagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponsePageable} returns this
 */
proto.unifiedplatform.liveMeditation.ResponsePageable.prototype.setSelectedpagenumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.RequestPageableWithId.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.RequestPageableWithId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.RequestPageableWithId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestPageableWithId.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.liveMeditation.RequestPageable.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.RequestPageableWithId}
 */
proto.unifiedplatform.liveMeditation.RequestPageableWithId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.RequestPageableWithId;
  return proto.unifiedplatform.liveMeditation.RequestPageableWithId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.RequestPageableWithId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.RequestPageableWithId}
 */
proto.unifiedplatform.liveMeditation.RequestPageableWithId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.liveMeditation.RequestPageable;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.RequestPageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.RequestPageableWithId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.RequestPageableWithId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.RequestPageableWithId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestPageableWithId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.unifiedplatform.liveMeditation.RequestPageable.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional RequestPageable pageable = 1;
 * @return {?proto.unifiedplatform.liveMeditation.RequestPageable}
 */
proto.unifiedplatform.liveMeditation.RequestPageableWithId.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.liveMeditation.RequestPageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.liveMeditation.RequestPageable, 1));
};


/**
 * @param {?proto.unifiedplatform.liveMeditation.RequestPageable|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestPageableWithId} returns this
*/
proto.unifiedplatform.liveMeditation.RequestPageableWithId.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.RequestPageableWithId} returns this
 */
proto.unifiedplatform.liveMeditation.RequestPageableWithId.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.RequestPageableWithId.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 Id = 2;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.RequestPageableWithId.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestPageableWithId} returns this
 */
proto.unifiedplatform.liveMeditation.RequestPageableWithId.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.RequestForLiveSessionState.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.RequestForLiveSessionState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.RequestForLiveSessionState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestForLiveSessionState.toObject = function(includeInstance, msg) {
  var f, obj = {
    livesessionid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.RequestForLiveSessionState}
 */
proto.unifiedplatform.liveMeditation.RequestForLiveSessionState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.RequestForLiveSessionState;
  return proto.unifiedplatform.liveMeditation.RequestForLiveSessionState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.RequestForLiveSessionState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.RequestForLiveSessionState}
 */
proto.unifiedplatform.liveMeditation.RequestForLiveSessionState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLivesessionid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.RequestForLiveSessionState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.RequestForLiveSessionState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.RequestForLiveSessionState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestForLiveSessionState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLivesessionid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 liveSessionId = 1;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.RequestForLiveSessionState.prototype.getLivesessionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestForLiveSessionState} returns this
 */
proto.unifiedplatform.liveMeditation.RequestForLiveSessionState.prototype.setLivesessionid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.ResponseForLiveSessionState.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.ResponseForLiveSessionState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.ResponseForLiveSessionState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ResponseForLiveSessionState.toObject = function(includeInstance, msg) {
  var f, obj = {
    livesessionstate: jspb.Message.getFieldWithDefault(msg, 1, 0),
    livesessionid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    awschannelviewercountresponse: (f = msg.getAwschannelviewercountresponse()) && proto.unifiedplatform.liveMeditation.AwsChannelViewerCountResponse.toObject(includeInstance, f),
    scheduledtime: (f = msg.getScheduledtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForLiveSessionState}
 */
proto.unifiedplatform.liveMeditation.ResponseForLiveSessionState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.ResponseForLiveSessionState;
  return proto.unifiedplatform.liveMeditation.ResponseForLiveSessionState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.ResponseForLiveSessionState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForLiveSessionState}
 */
proto.unifiedplatform.liveMeditation.ResponseForLiveSessionState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.unifiedplatform.liveMeditation.LiveSessionState} */ (reader.readEnum());
      msg.setLivesessionstate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLivesessionid(value);
      break;
    case 3:
      var value = new proto.unifiedplatform.liveMeditation.AwsChannelViewerCountResponse;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.AwsChannelViewerCountResponse.deserializeBinaryFromReader);
      msg.setAwschannelviewercountresponse(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setScheduledtime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.ResponseForLiveSessionState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.ResponseForLiveSessionState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.ResponseForLiveSessionState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ResponseForLiveSessionState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLivesessionstate();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getLivesessionid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getAwschannelviewercountresponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.unifiedplatform.liveMeditation.AwsChannelViewerCountResponse.serializeBinaryToWriter
    );
  }
  f = message.getScheduledtime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional LiveSessionState liveSessionState = 1;
 * @return {!proto.unifiedplatform.liveMeditation.LiveSessionState}
 */
proto.unifiedplatform.liveMeditation.ResponseForLiveSessionState.prototype.getLivesessionstate = function() {
  return /** @type {!proto.unifiedplatform.liveMeditation.LiveSessionState} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.unifiedplatform.liveMeditation.LiveSessionState} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForLiveSessionState} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseForLiveSessionState.prototype.setLivesessionstate = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int64 liveSessionId = 2;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.ResponseForLiveSessionState.prototype.getLivesessionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForLiveSessionState} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseForLiveSessionState.prototype.setLivesessionid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional AwsChannelViewerCountResponse awsChannelViewerCountResponse = 3;
 * @return {?proto.unifiedplatform.liveMeditation.AwsChannelViewerCountResponse}
 */
proto.unifiedplatform.liveMeditation.ResponseForLiveSessionState.prototype.getAwschannelviewercountresponse = function() {
  return /** @type{?proto.unifiedplatform.liveMeditation.AwsChannelViewerCountResponse} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.liveMeditation.AwsChannelViewerCountResponse, 3));
};


/**
 * @param {?proto.unifiedplatform.liveMeditation.AwsChannelViewerCountResponse|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForLiveSessionState} returns this
*/
proto.unifiedplatform.liveMeditation.ResponseForLiveSessionState.prototype.setAwschannelviewercountresponse = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForLiveSessionState} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseForLiveSessionState.prototype.clearAwschannelviewercountresponse = function() {
  return this.setAwschannelviewercountresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.ResponseForLiveSessionState.prototype.hasAwschannelviewercountresponse = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp scheduledTime = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.liveMeditation.ResponseForLiveSessionState.prototype.getScheduledtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForLiveSessionState} returns this
*/
proto.unifiedplatform.liveMeditation.ResponseForLiveSessionState.prototype.setScheduledtime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForLiveSessionState} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseForLiveSessionState.prototype.clearScheduledtime = function() {
  return this.setScheduledtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.ResponseForLiveSessionState.prototype.hasScheduledtime = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.RequestPreceptorSession.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.RequestPreceptorSession.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.RequestPreceptorSession} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestPreceptorSession.toObject = function(includeInstance, msg) {
  var f, obj = {
    livesessionid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    livesessionstate: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.RequestPreceptorSession}
 */
proto.unifiedplatform.liveMeditation.RequestPreceptorSession.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.RequestPreceptorSession;
  return proto.unifiedplatform.liveMeditation.RequestPreceptorSession.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.RequestPreceptorSession} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.RequestPreceptorSession}
 */
proto.unifiedplatform.liveMeditation.RequestPreceptorSession.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLivesessionid(value);
      break;
    case 2:
      var value = /** @type {!proto.unifiedplatform.liveMeditation.LiveSessionState} */ (reader.readEnum());
      msg.setLivesessionstate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.RequestPreceptorSession.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.RequestPreceptorSession.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.RequestPreceptorSession} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestPreceptorSession.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLivesessionid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLivesessionstate();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional int64 liveSessionId = 1;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.RequestPreceptorSession.prototype.getLivesessionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestPreceptorSession} returns this
 */
proto.unifiedplatform.liveMeditation.RequestPreceptorSession.prototype.setLivesessionid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional LiveSessionState liveSessionState = 2;
 * @return {!proto.unifiedplatform.liveMeditation.LiveSessionState}
 */
proto.unifiedplatform.liveMeditation.RequestPreceptorSession.prototype.getLivesessionstate = function() {
  return /** @type {!proto.unifiedplatform.liveMeditation.LiveSessionState} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.unifiedplatform.liveMeditation.LiveSessionState} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestPreceptorSession} returns this
 */
proto.unifiedplatform.liveMeditation.RequestPreceptorSession.prototype.setLivesessionstate = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.Headers.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.Headers.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.Headers} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.Headers.toObject = function(includeInstance, msg) {
  var f, obj = {
    contenttype: jspb.Message.getFieldWithDefault(msg, 1, ""),
    authorization: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.Headers}
 */
proto.unifiedplatform.liveMeditation.Headers.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.Headers;
  return proto.unifiedplatform.liveMeditation.Headers.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.Headers} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.Headers}
 */
proto.unifiedplatform.liveMeditation.Headers.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setContenttype(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthorization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.Headers.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.Headers.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.Headers} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.Headers.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContenttype();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuthorization();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string contentType = 1;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.Headers.prototype.getContenttype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.Headers} returns this
 */
proto.unifiedplatform.liveMeditation.Headers.prototype.setContenttype = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string authorization = 2;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.Headers.prototype.getAuthorization = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.Headers} returns this
 */
proto.unifiedplatform.liveMeditation.Headers.prototype.setAuthorization = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.RequestForGetZoomToken.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.RequestForGetZoomToken.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.RequestForGetZoomToken} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestForGetZoomToken.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, ""),
    method: jspb.Message.getFieldWithDefault(msg, 2, ""),
    headers: (f = msg.getHeaders()) && proto.unifiedplatform.liveMeditation.Headers.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.RequestForGetZoomToken}
 */
proto.unifiedplatform.liveMeditation.RequestForGetZoomToken.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.RequestForGetZoomToken;
  return proto.unifiedplatform.liveMeditation.RequestForGetZoomToken.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.RequestForGetZoomToken} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.RequestForGetZoomToken}
 */
proto.unifiedplatform.liveMeditation.RequestForGetZoomToken.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMethod(value);
      break;
    case 3:
      var value = new proto.unifiedplatform.liveMeditation.Headers;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.Headers.deserializeBinaryFromReader);
      msg.setHeaders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.RequestForGetZoomToken.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.RequestForGetZoomToken.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.RequestForGetZoomToken} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestForGetZoomToken.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMethod();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHeaders();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.unifiedplatform.liveMeditation.Headers.serializeBinaryToWriter
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.RequestForGetZoomToken.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestForGetZoomToken} returns this
 */
proto.unifiedplatform.liveMeditation.RequestForGetZoomToken.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string method = 2;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.RequestForGetZoomToken.prototype.getMethod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestForGetZoomToken} returns this
 */
proto.unifiedplatform.liveMeditation.RequestForGetZoomToken.prototype.setMethod = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Headers headers = 3;
 * @return {?proto.unifiedplatform.liveMeditation.Headers}
 */
proto.unifiedplatform.liveMeditation.RequestForGetZoomToken.prototype.getHeaders = function() {
  return /** @type{?proto.unifiedplatform.liveMeditation.Headers} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.liveMeditation.Headers, 3));
};


/**
 * @param {?proto.unifiedplatform.liveMeditation.Headers|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestForGetZoomToken} returns this
*/
proto.unifiedplatform.liveMeditation.RequestForGetZoomToken.prototype.setHeaders = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.RequestForGetZoomToken} returns this
 */
proto.unifiedplatform.liveMeditation.RequestForGetZoomToken.prototype.clearHeaders = function() {
  return this.setHeaders(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.RequestForGetZoomToken.prototype.hasHeaders = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.ResponseForGetZoomToken.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.ResponseForGetZoomToken.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.ResponseForGetZoomToken} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ResponseForGetZoomToken.toObject = function(includeInstance, msg) {
  var f, obj = {
    starturl: jspb.Message.getFieldWithDefault(msg, 1, ""),
    encryptedpassword: jspb.Message.getFieldWithDefault(msg, 2, ""),
    joinurl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForGetZoomToken}
 */
proto.unifiedplatform.liveMeditation.ResponseForGetZoomToken.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.ResponseForGetZoomToken;
  return proto.unifiedplatform.liveMeditation.ResponseForGetZoomToken.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.ResponseForGetZoomToken} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForGetZoomToken}
 */
proto.unifiedplatform.liveMeditation.ResponseForGetZoomToken.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStarturl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEncryptedpassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setJoinurl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.ResponseForGetZoomToken.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.ResponseForGetZoomToken.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.ResponseForGetZoomToken} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ResponseForGetZoomToken.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStarturl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEncryptedpassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getJoinurl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string startUrl = 1;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.ResponseForGetZoomToken.prototype.getStarturl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForGetZoomToken} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseForGetZoomToken.prototype.setStarturl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string encryptedPassword = 2;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.ResponseForGetZoomToken.prototype.getEncryptedpassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForGetZoomToken} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseForGetZoomToken.prototype.setEncryptedpassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string joinUrl = 3;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.ResponseForGetZoomToken.prototype.getJoinurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForGetZoomToken} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseForGetZoomToken.prototype.setJoinurl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.RequestForZoomWrapper.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.RequestForZoomWrapper.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.RequestForZoomWrapper} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestForZoomWrapper.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, ""),
    method: jspb.Message.getFieldWithDefault(msg, 2, ""),
    headers: (f = msg.getHeaders()) && proto.unifiedplatform.liveMeditation.Headers.toObject(includeInstance, f),
    actionMap: (f = msg.getActionMap()) ? f.toObject(includeInstance, undefined) : [],
    setting: (f = msg.getSetting()) && proto.unifiedplatform.liveMeditation.Setting.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.RequestForZoomWrapper}
 */
proto.unifiedplatform.liveMeditation.RequestForZoomWrapper.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.RequestForZoomWrapper;
  return proto.unifiedplatform.liveMeditation.RequestForZoomWrapper.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.RequestForZoomWrapper} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.RequestForZoomWrapper}
 */
proto.unifiedplatform.liveMeditation.RequestForZoomWrapper.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMethod(value);
      break;
    case 3:
      var value = new proto.unifiedplatform.liveMeditation.Headers;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.Headers.deserializeBinaryFromReader);
      msg.setHeaders(value);
      break;
    case 4:
      var value = msg.getActionMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 5:
      var value = new proto.unifiedplatform.liveMeditation.Setting;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.Setting.deserializeBinaryFromReader);
      msg.setSetting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.RequestForZoomWrapper.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.RequestForZoomWrapper.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.RequestForZoomWrapper} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestForZoomWrapper.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMethod();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHeaders();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.unifiedplatform.liveMeditation.Headers.serializeBinaryToWriter
    );
  }
  f = message.getActionMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getSetting();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.unifiedplatform.liveMeditation.Setting.serializeBinaryToWriter
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.RequestForZoomWrapper.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestForZoomWrapper} returns this
 */
proto.unifiedplatform.liveMeditation.RequestForZoomWrapper.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string method = 2;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.RequestForZoomWrapper.prototype.getMethod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestForZoomWrapper} returns this
 */
proto.unifiedplatform.liveMeditation.RequestForZoomWrapper.prototype.setMethod = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Headers headers = 3;
 * @return {?proto.unifiedplatform.liveMeditation.Headers}
 */
proto.unifiedplatform.liveMeditation.RequestForZoomWrapper.prototype.getHeaders = function() {
  return /** @type{?proto.unifiedplatform.liveMeditation.Headers} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.liveMeditation.Headers, 3));
};


/**
 * @param {?proto.unifiedplatform.liveMeditation.Headers|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestForZoomWrapper} returns this
*/
proto.unifiedplatform.liveMeditation.RequestForZoomWrapper.prototype.setHeaders = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.RequestForZoomWrapper} returns this
 */
proto.unifiedplatform.liveMeditation.RequestForZoomWrapper.prototype.clearHeaders = function() {
  return this.setHeaders(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.RequestForZoomWrapper.prototype.hasHeaders = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * map<string, string> action = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.unifiedplatform.liveMeditation.RequestForZoomWrapper.prototype.getActionMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.unifiedplatform.liveMeditation.RequestForZoomWrapper} returns this
 */
proto.unifiedplatform.liveMeditation.RequestForZoomWrapper.prototype.clearActionMap = function() {
  this.getActionMap().clear();
  return this;};


/**
 * optional Setting setting = 5;
 * @return {?proto.unifiedplatform.liveMeditation.Setting}
 */
proto.unifiedplatform.liveMeditation.RequestForZoomWrapper.prototype.getSetting = function() {
  return /** @type{?proto.unifiedplatform.liveMeditation.Setting} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.liveMeditation.Setting, 5));
};


/**
 * @param {?proto.unifiedplatform.liveMeditation.Setting|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestForZoomWrapper} returns this
*/
proto.unifiedplatform.liveMeditation.RequestForZoomWrapper.prototype.setSetting = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.RequestForZoomWrapper} returns this
 */
proto.unifiedplatform.liveMeditation.RequestForZoomWrapper.prototype.clearSetting = function() {
  return this.setSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.RequestForZoomWrapper.prototype.hasSetting = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.Setting.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.Setting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.Setting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.Setting.toObject = function(includeInstance, msg) {
  var f, obj = {
    settingMap: (f = msg.getSettingMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.Setting}
 */
proto.unifiedplatform.liveMeditation.Setting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.Setting;
  return proto.unifiedplatform.liveMeditation.Setting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.Setting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.Setting}
 */
proto.unifiedplatform.liveMeditation.Setting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getSettingMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.Setting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.Setting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.Setting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.Setting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSettingMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * map<string, string> setting = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.unifiedplatform.liveMeditation.Setting.prototype.getSettingMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.unifiedplatform.liveMeditation.Setting} returns this
 */
proto.unifiedplatform.liveMeditation.Setting.prototype.clearSettingMap = function() {
  this.getSettingMap().clear();
  return this;};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.unifiedplatform.liveMeditation.Value.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.unifiedplatform.liveMeditation.Value.KindCase = {
  KIND_NOT_SET: 0,
  STRING_VALUE: 1
};

/**
 * @return {proto.unifiedplatform.liveMeditation.Value.KindCase}
 */
proto.unifiedplatform.liveMeditation.Value.prototype.getKindCase = function() {
  return /** @type {proto.unifiedplatform.liveMeditation.Value.KindCase} */(jspb.Message.computeOneofCase(this, proto.unifiedplatform.liveMeditation.Value.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.Value.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.Value.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.Value} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.Value.toObject = function(includeInstance, msg) {
  var f, obj = {
    stringValue: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.Value}
 */
proto.unifiedplatform.liveMeditation.Value.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.Value;
  return proto.unifiedplatform.liveMeditation.Value.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.Value} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.Value}
 */
proto.unifiedplatform.liveMeditation.Value.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStringValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.Value.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.Value.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.Value} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.Value.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string string_value = 1;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.Value.prototype.getStringValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.Value} returns this
 */
proto.unifiedplatform.liveMeditation.Value.prototype.setStringValue = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.unifiedplatform.liveMeditation.Value.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.Value} returns this
 */
proto.unifiedplatform.liveMeditation.Value.prototype.clearStringValue = function() {
  return jspb.Message.setOneofField(this, 1, proto.unifiedplatform.liveMeditation.Value.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.Value.prototype.hasStringValue = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.ResponseForZoomWrapper.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.ResponseForZoomWrapper.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.ResponseForZoomWrapper} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ResponseForZoomWrapper.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForZoomWrapper}
 */
proto.unifiedplatform.liveMeditation.ResponseForZoomWrapper.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.ResponseForZoomWrapper;
  return proto.unifiedplatform.liveMeditation.ResponseForZoomWrapper.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.ResponseForZoomWrapper} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForZoomWrapper}
 */
proto.unifiedplatform.liveMeditation.ResponseForZoomWrapper.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.ResponseForZoomWrapper.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.ResponseForZoomWrapper.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.ResponseForZoomWrapper} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ResponseForZoomWrapper.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint32 code = 1;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.ResponseForZoomWrapper.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForZoomWrapper} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseForZoomWrapper.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.ResponseForZoomWrapper.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForZoomWrapper} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseForZoomWrapper.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.ResponseForLiveSessionFeedBacks.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.ResponseForLiveSessionFeedBacks.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.ResponseForLiveSessionFeedBacks.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.ResponseForLiveSessionFeedBacks} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ResponseForLiveSessionFeedBacks.toObject = function(includeInstance, msg) {
  var f, obj = {
    userlivesessionfeedbackList: jspb.Message.toObjectList(msg.getUserlivesessionfeedbackList(),
    proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack.toObject, includeInstance),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForLiveSessionFeedBacks}
 */
proto.unifiedplatform.liveMeditation.ResponseForLiveSessionFeedBacks.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.ResponseForLiveSessionFeedBacks;
  return proto.unifiedplatform.liveMeditation.ResponseForLiveSessionFeedBacks.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.ResponseForLiveSessionFeedBacks} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForLiveSessionFeedBacks}
 */
proto.unifiedplatform.liveMeditation.ResponseForLiveSessionFeedBacks.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack.deserializeBinaryFromReader);
      msg.addUserlivesessionfeedback(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.ResponseForLiveSessionFeedBacks.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.ResponseForLiveSessionFeedBacks.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.ResponseForLiveSessionFeedBacks} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ResponseForLiveSessionFeedBacks.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserlivesessionfeedbackList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack.serializeBinaryToWriter
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated UserLiveSessionFeedBack userLiveSessionFeedBack = 1;
 * @return {!Array<!proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack>}
 */
proto.unifiedplatform.liveMeditation.ResponseForLiveSessionFeedBacks.prototype.getUserlivesessionfeedbackList = function() {
  return /** @type{!Array<!proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack>} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForLiveSessionFeedBacks} returns this
*/
proto.unifiedplatform.liveMeditation.ResponseForLiveSessionFeedBacks.prototype.setUserlivesessionfeedbackList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack}
 */
proto.unifiedplatform.liveMeditation.ResponseForLiveSessionFeedBacks.prototype.addUserlivesessionfeedback = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.liveMeditation.UserLiveSessionFeedBack, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForLiveSessionFeedBacks} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseForLiveSessionFeedBacks.prototype.clearUserlivesessionfeedbackList = function() {
  return this.setUserlivesessionfeedbackList([]);
};


/**
 * optional int64 count = 2;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.ResponseForLiveSessionFeedBacks.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForLiveSessionFeedBacks} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseForLiveSessionFeedBacks.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.RequestForUpcomingGetAllLiveMediationV2.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.RequestForUpcomingGetAllLiveMediationV2.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.RequestForUpcomingGetAllLiveMediationV2} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestForUpcomingGetAllLiveMediationV2.toObject = function(includeInstance, msg) {
  var f, obj = {
    scheduledtime: (f = msg.getScheduledtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    delayexpirybygivenminutes: jspb.Message.getFieldWithDefault(msg, 2, 0),
    requestpageable: (f = msg.getRequestpageable()) && proto.unifiedplatform.liveMeditation.RequestPageable.toObject(includeInstance, f),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.RequestForUpcomingGetAllLiveMediationV2}
 */
proto.unifiedplatform.liveMeditation.RequestForUpcomingGetAllLiveMediationV2.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.RequestForUpcomingGetAllLiveMediationV2;
  return proto.unifiedplatform.liveMeditation.RequestForUpcomingGetAllLiveMediationV2.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.RequestForUpcomingGetAllLiveMediationV2} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.RequestForUpcomingGetAllLiveMediationV2}
 */
proto.unifiedplatform.liveMeditation.RequestForUpcomingGetAllLiveMediationV2.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setScheduledtime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDelayexpirybygivenminutes(value);
      break;
    case 3:
      var value = new proto.unifiedplatform.liveMeditation.RequestPageable;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.RequestPageable.deserializeBinaryFromReader);
      msg.setRequestpageable(value);
      break;
    case 4:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.RequestForUpcomingGetAllLiveMediationV2.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.RequestForUpcomingGetAllLiveMediationV2.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.RequestForUpcomingGetAllLiveMediationV2} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestForUpcomingGetAllLiveMediationV2.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScheduledtime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDelayexpirybygivenminutes();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getRequestpageable();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.unifiedplatform.liveMeditation.RequestPageable.serializeBinaryToWriter
    );
  }
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp scheduledTime = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.liveMeditation.RequestForUpcomingGetAllLiveMediationV2.prototype.getScheduledtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestForUpcomingGetAllLiveMediationV2} returns this
*/
proto.unifiedplatform.liveMeditation.RequestForUpcomingGetAllLiveMediationV2.prototype.setScheduledtime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.RequestForUpcomingGetAllLiveMediationV2} returns this
 */
proto.unifiedplatform.liveMeditation.RequestForUpcomingGetAllLiveMediationV2.prototype.clearScheduledtime = function() {
  return this.setScheduledtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.RequestForUpcomingGetAllLiveMediationV2.prototype.hasScheduledtime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 delayExpiryByGivenMinutes = 2;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.RequestForUpcomingGetAllLiveMediationV2.prototype.getDelayexpirybygivenminutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestForUpcomingGetAllLiveMediationV2} returns this
 */
proto.unifiedplatform.liveMeditation.RequestForUpcomingGetAllLiveMediationV2.prototype.setDelayexpirybygivenminutes = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional RequestPageable requestPageable = 3;
 * @return {?proto.unifiedplatform.liveMeditation.RequestPageable}
 */
proto.unifiedplatform.liveMeditation.RequestForUpcomingGetAllLiveMediationV2.prototype.getRequestpageable = function() {
  return /** @type{?proto.unifiedplatform.liveMeditation.RequestPageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.liveMeditation.RequestPageable, 3));
};


/**
 * @param {?proto.unifiedplatform.liveMeditation.RequestPageable|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestForUpcomingGetAllLiveMediationV2} returns this
*/
proto.unifiedplatform.liveMeditation.RequestForUpcomingGetAllLiveMediationV2.prototype.setRequestpageable = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.RequestForUpcomingGetAllLiveMediationV2} returns this
 */
proto.unifiedplatform.liveMeditation.RequestForUpcomingGetAllLiveMediationV2.prototype.clearRequestpageable = function() {
  return this.setRequestpageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.RequestForUpcomingGetAllLiveMediationV2.prototype.hasRequestpageable = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 4;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.liveMeditation.RequestForUpcomingGetAllLiveMediationV2.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestForUpcomingGetAllLiveMediationV2} returns this
 */
proto.unifiedplatform.liveMeditation.RequestForUpcomingGetAllLiveMediationV2.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.ResponseForUpcomingGetAllLiveMeditationsV2.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.ResponseForUpcomingGetAllLiveMeditationsV2.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.ResponseForUpcomingGetAllLiveMeditationsV2.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.ResponseForUpcomingGetAllLiveMeditationsV2} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ResponseForUpcomingGetAllLiveMeditationsV2.toObject = function(includeInstance, msg) {
  var f, obj = {
    responselivemeditationwithselectedlanguageList: jspb.Message.toObjectList(msg.getResponselivemeditationwithselectedlanguageList(),
    proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage.toObject, includeInstance),
    responsepageable: (f = msg.getResponsepageable()) && proto.unifiedplatform.liveMeditation.ResponsePageable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForUpcomingGetAllLiveMeditationsV2}
 */
proto.unifiedplatform.liveMeditation.ResponseForUpcomingGetAllLiveMeditationsV2.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.ResponseForUpcomingGetAllLiveMeditationsV2;
  return proto.unifiedplatform.liveMeditation.ResponseForUpcomingGetAllLiveMeditationsV2.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.ResponseForUpcomingGetAllLiveMeditationsV2} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForUpcomingGetAllLiveMeditationsV2}
 */
proto.unifiedplatform.liveMeditation.ResponseForUpcomingGetAllLiveMeditationsV2.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage.deserializeBinaryFromReader);
      msg.addResponselivemeditationwithselectedlanguage(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.liveMeditation.ResponsePageable;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.ResponsePageable.deserializeBinaryFromReader);
      msg.setResponsepageable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.ResponseForUpcomingGetAllLiveMeditationsV2.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.ResponseForUpcomingGetAllLiveMeditationsV2.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.ResponseForUpcomingGetAllLiveMeditationsV2} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ResponseForUpcomingGetAllLiveMeditationsV2.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResponselivemeditationwithselectedlanguageList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage.serializeBinaryToWriter
    );
  }
  f = message.getResponsepageable();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.liveMeditation.ResponsePageable.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ResponseLiveMeditationWithSelectedLanguage responseLiveMeditationWithSelectedLanguage = 1;
 * @return {!Array<!proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage>}
 */
proto.unifiedplatform.liveMeditation.ResponseForUpcomingGetAllLiveMeditationsV2.prototype.getResponselivemeditationwithselectedlanguageList = function() {
  return /** @type{!Array<!proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage>} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForUpcomingGetAllLiveMeditationsV2} returns this
*/
proto.unifiedplatform.liveMeditation.ResponseForUpcomingGetAllLiveMeditationsV2.prototype.setResponselivemeditationwithselectedlanguageList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage}
 */
proto.unifiedplatform.liveMeditation.ResponseForUpcomingGetAllLiveMeditationsV2.prototype.addResponselivemeditationwithselectedlanguage = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.liveMeditation.ResponseLiveMeditationWithSelectedLanguage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForUpcomingGetAllLiveMeditationsV2} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseForUpcomingGetAllLiveMeditationsV2.prototype.clearResponselivemeditationwithselectedlanguageList = function() {
  return this.setResponselivemeditationwithselectedlanguageList([]);
};


/**
 * optional ResponsePageable responsePageable = 2;
 * @return {?proto.unifiedplatform.liveMeditation.ResponsePageable}
 */
proto.unifiedplatform.liveMeditation.ResponseForUpcomingGetAllLiveMeditationsV2.prototype.getResponsepageable = function() {
  return /** @type{?proto.unifiedplatform.liveMeditation.ResponsePageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.liveMeditation.ResponsePageable, 2));
};


/**
 * @param {?proto.unifiedplatform.liveMeditation.ResponsePageable|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForUpcomingGetAllLiveMeditationsV2} returns this
*/
proto.unifiedplatform.liveMeditation.ResponseForUpcomingGetAllLiveMeditationsV2.prototype.setResponsepageable = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseForUpcomingGetAllLiveMeditationsV2} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseForUpcomingGetAllLiveMeditationsV2.prototype.clearResponsepageable = function() {
  return this.setResponsepageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.ResponseForUpcomingGetAllLiveMeditationsV2.prototype.hasResponsepageable = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionV2.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionV2.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionV2.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionV2} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionV2.toObject = function(includeInstance, msg) {
  var f, obj = {
    scheduledtime: (f = msg.getScheduledtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    delayexpirybygivenminutes: jspb.Message.getFieldWithDefault(msg, 2, 0),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 3, 0),
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.liveMeditation.RequestPageable.toObject(includeInstance, f),
    livesessionidsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionV2}
 */
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionV2.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionV2;
  return proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionV2.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionV2} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionV2}
 */
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionV2.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setScheduledtime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDelayexpirybygivenminutes(value);
      break;
    case 3:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    case 4:
      var value = new proto.unifiedplatform.liveMeditation.RequestPageable;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.RequestPageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLivesessionids(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionV2.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionV2.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionV2} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionV2.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScheduledtime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDelayexpirybygivenminutes();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.unifiedplatform.liveMeditation.RequestPageable.serializeBinaryToWriter
    );
  }
  f = message.getLivesessionidsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      5,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp scheduledTime = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionV2.prototype.getScheduledtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionV2} returns this
*/
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionV2.prototype.setScheduledtime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionV2} returns this
 */
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionV2.prototype.clearScheduledtime = function() {
  return this.setScheduledtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionV2.prototype.hasScheduledtime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 delayExpiryByGivenMinutes = 2;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionV2.prototype.getDelayexpirybygivenminutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionV2} returns this
 */
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionV2.prototype.setDelayexpirybygivenminutes = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 3;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionV2.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionV2} returns this
 */
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionV2.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional RequestPageable pageable = 4;
 * @return {?proto.unifiedplatform.liveMeditation.RequestPageable}
 */
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionV2.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.liveMeditation.RequestPageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.liveMeditation.RequestPageable, 4));
};


/**
 * @param {?proto.unifiedplatform.liveMeditation.RequestPageable|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionV2} returns this
*/
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionV2.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionV2} returns this
 */
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionV2.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionV2.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated int64 liveSessionIds = 5;
 * @return {!Array<number>}
 */
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionV2.prototype.getLivesessionidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionV2} returns this
 */
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionV2.prototype.setLivesessionidsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionV2} returns this
 */
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionV2.prototype.addLivesessionids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionV2} returns this
 */
proto.unifiedplatform.liveMeditation.RequestGetAllLiveSessionV2.prototype.clearLivesessionidsList = function() {
  return this.setLivesessionidsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.RequestLiveSessionV2ByCoachIdOnSpecifiedTime.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.RequestLiveSessionV2ByCoachIdOnSpecifiedTime.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.RequestLiveSessionV2ByCoachIdOnSpecifiedTime} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestLiveSessionV2ByCoachIdOnSpecifiedTime.toObject = function(includeInstance, msg) {
  var f, obj = {
    coachid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    starttime: (f = msg.getStarttime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endtime: (f = msg.getEndtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 4, 0),
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.liveMeditation.RequestPageable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.RequestLiveSessionV2ByCoachIdOnSpecifiedTime}
 */
proto.unifiedplatform.liveMeditation.RequestLiveSessionV2ByCoachIdOnSpecifiedTime.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.RequestLiveSessionV2ByCoachIdOnSpecifiedTime;
  return proto.unifiedplatform.liveMeditation.RequestLiveSessionV2ByCoachIdOnSpecifiedTime.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.RequestLiveSessionV2ByCoachIdOnSpecifiedTime} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.RequestLiveSessionV2ByCoachIdOnSpecifiedTime}
 */
proto.unifiedplatform.liveMeditation.RequestLiveSessionV2ByCoachIdOnSpecifiedTime.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCoachid(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStarttime(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndtime(value);
      break;
    case 4:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    case 5:
      var value = new proto.unifiedplatform.liveMeditation.RequestPageable;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.RequestPageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.RequestLiveSessionV2ByCoachIdOnSpecifiedTime.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.RequestLiveSessionV2ByCoachIdOnSpecifiedTime.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.RequestLiveSessionV2ByCoachIdOnSpecifiedTime} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestLiveSessionV2ByCoachIdOnSpecifiedTime.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCoachid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getStarttime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndtime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.unifiedplatform.liveMeditation.RequestPageable.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 coachId = 1;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.RequestLiveSessionV2ByCoachIdOnSpecifiedTime.prototype.getCoachid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestLiveSessionV2ByCoachIdOnSpecifiedTime} returns this
 */
proto.unifiedplatform.liveMeditation.RequestLiveSessionV2ByCoachIdOnSpecifiedTime.prototype.setCoachid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp startTime = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.liveMeditation.RequestLiveSessionV2ByCoachIdOnSpecifiedTime.prototype.getStarttime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestLiveSessionV2ByCoachIdOnSpecifiedTime} returns this
*/
proto.unifiedplatform.liveMeditation.RequestLiveSessionV2ByCoachIdOnSpecifiedTime.prototype.setStarttime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.RequestLiveSessionV2ByCoachIdOnSpecifiedTime} returns this
 */
proto.unifiedplatform.liveMeditation.RequestLiveSessionV2ByCoachIdOnSpecifiedTime.prototype.clearStarttime = function() {
  return this.setStarttime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.RequestLiveSessionV2ByCoachIdOnSpecifiedTime.prototype.hasStarttime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp endTime = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.liveMeditation.RequestLiveSessionV2ByCoachIdOnSpecifiedTime.prototype.getEndtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestLiveSessionV2ByCoachIdOnSpecifiedTime} returns this
*/
proto.unifiedplatform.liveMeditation.RequestLiveSessionV2ByCoachIdOnSpecifiedTime.prototype.setEndtime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.RequestLiveSessionV2ByCoachIdOnSpecifiedTime} returns this
 */
proto.unifiedplatform.liveMeditation.RequestLiveSessionV2ByCoachIdOnSpecifiedTime.prototype.clearEndtime = function() {
  return this.setEndtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.RequestLiveSessionV2ByCoachIdOnSpecifiedTime.prototype.hasEndtime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 4;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.liveMeditation.RequestLiveSessionV2ByCoachIdOnSpecifiedTime.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestLiveSessionV2ByCoachIdOnSpecifiedTime} returns this
 */
proto.unifiedplatform.liveMeditation.RequestLiveSessionV2ByCoachIdOnSpecifiedTime.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional RequestPageable pageable = 5;
 * @return {?proto.unifiedplatform.liveMeditation.RequestPageable}
 */
proto.unifiedplatform.liveMeditation.RequestLiveSessionV2ByCoachIdOnSpecifiedTime.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.liveMeditation.RequestPageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.liveMeditation.RequestPageable, 5));
};


/**
 * @param {?proto.unifiedplatform.liveMeditation.RequestPageable|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestLiveSessionV2ByCoachIdOnSpecifiedTime} returns this
*/
proto.unifiedplatform.liveMeditation.RequestLiveSessionV2ByCoachIdOnSpecifiedTime.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.RequestLiveSessionV2ByCoachIdOnSpecifiedTime} returns this
 */
proto.unifiedplatform.liveMeditation.RequestLiveSessionV2ByCoachIdOnSpecifiedTime.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.RequestLiveSessionV2ByCoachIdOnSpecifiedTime.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessioninfoList: jspb.Message.toObjectList(msg.getSessioninfoList(),
    proto.unifiedplatform.liveMeditation.SessionInfo.toObject, includeInstance),
    coachdetails: (f = msg.getCoachdetails()) && proto.unifiedplatform.liveMeditation.LocaleCoachProfile.toObject(includeInstance, f),
    starttime: (f = msg.getStarttime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endtime: (f = msg.getEndtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    responsepageable: (f = msg.getResponsepageable()) && proto.unifiedplatform.liveMeditation.ResponsePageable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime}
 */
proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime;
  return proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime}
 */
proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.liveMeditation.SessionInfo;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.SessionInfo.deserializeBinaryFromReader);
      msg.addSessioninfo(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.liveMeditation.LocaleCoachProfile;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.LocaleCoachProfile.deserializeBinaryFromReader);
      msg.setCoachdetails(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStarttime(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndtime(value);
      break;
    case 5:
      var value = new proto.unifiedplatform.liveMeditation.ResponsePageable;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.ResponsePageable.deserializeBinaryFromReader);
      msg.setResponsepageable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessioninfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.liveMeditation.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getCoachdetails();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.liveMeditation.LocaleCoachProfile.serializeBinaryToWriter
    );
  }
  f = message.getStarttime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndtime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getResponsepageable();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.unifiedplatform.liveMeditation.ResponsePageable.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SessionInfo sessionInfo = 1;
 * @return {!Array<!proto.unifiedplatform.liveMeditation.SessionInfo>}
 */
proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime.prototype.getSessioninfoList = function() {
  return /** @type{!Array<!proto.unifiedplatform.liveMeditation.SessionInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.liveMeditation.SessionInfo, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.liveMeditation.SessionInfo>} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime} returns this
*/
proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime.prototype.setSessioninfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.liveMeditation.SessionInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfo}
 */
proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime.prototype.addSessioninfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.liveMeditation.SessionInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime.prototype.clearSessioninfoList = function() {
  return this.setSessioninfoList([]);
};


/**
 * optional LocaleCoachProfile coachDetails = 2;
 * @return {?proto.unifiedplatform.liveMeditation.LocaleCoachProfile}
 */
proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime.prototype.getCoachdetails = function() {
  return /** @type{?proto.unifiedplatform.liveMeditation.LocaleCoachProfile} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.liveMeditation.LocaleCoachProfile, 2));
};


/**
 * @param {?proto.unifiedplatform.liveMeditation.LocaleCoachProfile|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime} returns this
*/
proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime.prototype.setCoachdetails = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime.prototype.clearCoachdetails = function() {
  return this.setCoachdetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime.prototype.hasCoachdetails = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp startTime = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime.prototype.getStarttime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime} returns this
*/
proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime.prototype.setStarttime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime.prototype.clearStarttime = function() {
  return this.setStarttime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime.prototype.hasStarttime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp endTime = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime.prototype.getEndtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime} returns this
*/
proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime.prototype.setEndtime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime.prototype.clearEndtime = function() {
  return this.setEndtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime.prototype.hasEndtime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ResponsePageable responsePageable = 5;
 * @return {?proto.unifiedplatform.liveMeditation.ResponsePageable}
 */
proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime.prototype.getResponsepageable = function() {
  return /** @type{?proto.unifiedplatform.liveMeditation.ResponsePageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.liveMeditation.ResponsePageable, 5));
};


/**
 * @param {?proto.unifiedplatform.liveMeditation.ResponsePageable|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime} returns this
*/
proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime.prototype.setResponsepageable = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime} returns this
 */
proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime.prototype.clearResponsepageable = function() {
  return this.setResponsepageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.ResponseLiveSessionV2DetailsByCoachIdOnSpecifiedTime.prototype.hasResponsepageable = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.ZoomUrlsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.ZoomUrlsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.ZoomUrlsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ZoomUrlsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    joinUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    startUrl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.ZoomUrlsResponse}
 */
proto.unifiedplatform.liveMeditation.ZoomUrlsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.ZoomUrlsResponse;
  return proto.unifiedplatform.liveMeditation.ZoomUrlsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.ZoomUrlsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.ZoomUrlsResponse}
 */
proto.unifiedplatform.liveMeditation.ZoomUrlsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setJoinUrl(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.ZoomUrlsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.ZoomUrlsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.ZoomUrlsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ZoomUrlsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getJoinUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStartUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional uint32 code = 1;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.ZoomUrlsResponse.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.ZoomUrlsResponse} returns this
 */
proto.unifiedplatform.liveMeditation.ZoomUrlsResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string join_url = 2;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.ZoomUrlsResponse.prototype.getJoinUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.ZoomUrlsResponse} returns this
 */
proto.unifiedplatform.liveMeditation.ZoomUrlsResponse.prototype.setJoinUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string start_url = 3;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.ZoomUrlsResponse.prototype.getStartUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.ZoomUrlsResponse} returns this
 */
proto.unifiedplatform.liveMeditation.ZoomUrlsResponse.prototype.setStartUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.ZoomEventRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.ZoomEventRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.ZoomEventRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ZoomEventRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    event: jspb.Message.getFieldWithDefault(msg, 1, ""),
    eventTs: jspb.Message.getFieldWithDefault(msg, 2, 0),
    payload: (f = msg.getPayload()) && proto.unifiedplatform.liveMeditation.zoomPayload.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.ZoomEventRequest}
 */
proto.unifiedplatform.liveMeditation.ZoomEventRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.ZoomEventRequest;
  return proto.unifiedplatform.liveMeditation.ZoomEventRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.ZoomEventRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.ZoomEventRequest}
 */
proto.unifiedplatform.liveMeditation.ZoomEventRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEvent(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEventTs(value);
      break;
    case 3:
      var value = new proto.unifiedplatform.liveMeditation.zoomPayload;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.zoomPayload.deserializeBinaryFromReader);
      msg.setPayload(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.ZoomEventRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.ZoomEventRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.ZoomEventRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ZoomEventRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEventTs();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPayload();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.unifiedplatform.liveMeditation.zoomPayload.serializeBinaryToWriter
    );
  }
};


/**
 * optional string event = 1;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.ZoomEventRequest.prototype.getEvent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.ZoomEventRequest} returns this
 */
proto.unifiedplatform.liveMeditation.ZoomEventRequest.prototype.setEvent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 event_ts = 2;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.ZoomEventRequest.prototype.getEventTs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.ZoomEventRequest} returns this
 */
proto.unifiedplatform.liveMeditation.ZoomEventRequest.prototype.setEventTs = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional zoomPayload payload = 3;
 * @return {?proto.unifiedplatform.liveMeditation.zoomPayload}
 */
proto.unifiedplatform.liveMeditation.ZoomEventRequest.prototype.getPayload = function() {
  return /** @type{?proto.unifiedplatform.liveMeditation.zoomPayload} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.liveMeditation.zoomPayload, 3));
};


/**
 * @param {?proto.unifiedplatform.liveMeditation.zoomPayload|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.ZoomEventRequest} returns this
*/
proto.unifiedplatform.liveMeditation.ZoomEventRequest.prototype.setPayload = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.ZoomEventRequest} returns this
 */
proto.unifiedplatform.liveMeditation.ZoomEventRequest.prototype.clearPayload = function() {
  return this.setPayload(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.ZoomEventRequest.prototype.hasPayload = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.zoomPayload.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.zoomPayload.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.zoomPayload} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.zoomPayload.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    operator: jspb.Message.getFieldWithDefault(msg, 2, ""),
    operatorId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    operation: jspb.Message.getFieldWithDefault(msg, 4, ""),
    object: (f = msg.getObject()) && proto.unifiedplatform.liveMeditation.zoomObject.toObject(includeInstance, f),
    plaintoken: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.zoomPayload}
 */
proto.unifiedplatform.liveMeditation.zoomPayload.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.zoomPayload;
  return proto.unifiedplatform.liveMeditation.zoomPayload.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.zoomPayload} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.zoomPayload}
 */
proto.unifiedplatform.liveMeditation.zoomPayload.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperator(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperatorId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperation(value);
      break;
    case 5:
      var value = new proto.unifiedplatform.liveMeditation.zoomObject;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.zoomObject.deserializeBinaryFromReader);
      msg.setObject(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaintoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.zoomPayload.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.zoomPayload.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.zoomPayload} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.zoomPayload.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOperator();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOperatorId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOperation();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getObject();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.unifiedplatform.liveMeditation.zoomObject.serializeBinaryToWriter
    );
  }
  f = message.getPlaintoken();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.zoomPayload.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.zoomPayload} returns this
 */
proto.unifiedplatform.liveMeditation.zoomPayload.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string operator = 2;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.zoomPayload.prototype.getOperator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.zoomPayload} returns this
 */
proto.unifiedplatform.liveMeditation.zoomPayload.prototype.setOperator = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string operator_id = 3;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.zoomPayload.prototype.getOperatorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.zoomPayload} returns this
 */
proto.unifiedplatform.liveMeditation.zoomPayload.prototype.setOperatorId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string operation = 4;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.zoomPayload.prototype.getOperation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.zoomPayload} returns this
 */
proto.unifiedplatform.liveMeditation.zoomPayload.prototype.setOperation = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional zoomObject object = 5;
 * @return {?proto.unifiedplatform.liveMeditation.zoomObject}
 */
proto.unifiedplatform.liveMeditation.zoomPayload.prototype.getObject = function() {
  return /** @type{?proto.unifiedplatform.liveMeditation.zoomObject} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.liveMeditation.zoomObject, 5));
};


/**
 * @param {?proto.unifiedplatform.liveMeditation.zoomObject|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.zoomPayload} returns this
*/
proto.unifiedplatform.liveMeditation.zoomPayload.prototype.setObject = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.zoomPayload} returns this
 */
proto.unifiedplatform.liveMeditation.zoomPayload.prototype.clearObject = function() {
  return this.setObject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.zoomPayload.prototype.hasObject = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string plainToken = 6;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.zoomPayload.prototype.getPlaintoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.zoomPayload} returns this
 */
proto.unifiedplatform.liveMeditation.zoomPayload.prototype.setPlaintoken = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.zoomObject.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.zoomObject.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.zoomObject} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.zoomObject.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    uuid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    hostId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    topic: jspb.Message.getFieldWithDefault(msg, 4, ""),
    type: jspb.Message.getFieldWithDefault(msg, 5, 0),
    startTime: jspb.Message.getFieldWithDefault(msg, 6, ""),
    timezone: jspb.Message.getFieldWithDefault(msg, 7, ""),
    duration: jspb.Message.getFieldWithDefault(msg, 8, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.zoomObject}
 */
proto.unifiedplatform.liveMeditation.zoomObject.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.zoomObject;
  return proto.unifiedplatform.liveMeditation.zoomObject.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.zoomObject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.zoomObject}
 */
proto.unifiedplatform.liveMeditation.zoomObject.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setHostId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTopic(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartTime(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDuration(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.zoomObject.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.zoomObject.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.zoomObject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.zoomObject.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHostId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTopic();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getType();
  if (f !== 0) {
    writer.writeSint32(
      5,
      f
    );
  }
  f = message.getStartTime();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getEndTime();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.zoomObject.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.zoomObject} returns this
 */
proto.unifiedplatform.liveMeditation.zoomObject.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string uuid = 2;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.zoomObject.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.zoomObject} returns this
 */
proto.unifiedplatform.liveMeditation.zoomObject.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string host_id = 3;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.zoomObject.prototype.getHostId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.zoomObject} returns this
 */
proto.unifiedplatform.liveMeditation.zoomObject.prototype.setHostId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string topic = 4;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.zoomObject.prototype.getTopic = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.zoomObject} returns this
 */
proto.unifiedplatform.liveMeditation.zoomObject.prototype.setTopic = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional sint32 type = 5;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.zoomObject.prototype.getType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.zoomObject} returns this
 */
proto.unifiedplatform.liveMeditation.zoomObject.prototype.setType = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string start_time = 6;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.zoomObject.prototype.getStartTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.zoomObject} returns this
 */
proto.unifiedplatform.liveMeditation.zoomObject.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string timezone = 7;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.zoomObject.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.zoomObject} returns this
 */
proto.unifiedplatform.liveMeditation.zoomObject.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 duration = 8;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.zoomObject.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.zoomObject} returns this
 */
proto.unifiedplatform.liveMeditation.zoomObject.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string end_time = 9;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.zoomObject.prototype.getEndTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.zoomObject} returns this
 */
proto.unifiedplatform.liveMeditation.zoomObject.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.ZoomLiveStreamActionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.ZoomLiveStreamActionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.ZoomLiveStreamActionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ZoomLiveStreamActionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    livestreamaction: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.ZoomLiveStreamActionRequest}
 */
proto.unifiedplatform.liveMeditation.ZoomLiveStreamActionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.ZoomLiveStreamActionRequest;
  return proto.unifiedplatform.liveMeditation.ZoomLiveStreamActionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.ZoomLiveStreamActionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.ZoomLiveStreamActionRequest}
 */
proto.unifiedplatform.liveMeditation.ZoomLiveStreamActionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLivestreamaction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.ZoomLiveStreamActionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.ZoomLiveStreamActionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.ZoomLiveStreamActionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ZoomLiveStreamActionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLivestreamaction();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string liveStreamAction = 1;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.ZoomLiveStreamActionRequest.prototype.getLivestreamaction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.ZoomLiveStreamActionRequest} returns this
 */
proto.unifiedplatform.liveMeditation.ZoomLiveStreamActionRequest.prototype.setLivestreamaction = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.ReportTypeWithIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.ReportTypeWithIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.ReportTypeWithIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ReportTypeWithIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userreporttype: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.ReportTypeWithIdRequest}
 */
proto.unifiedplatform.liveMeditation.ReportTypeWithIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.ReportTypeWithIdRequest;
  return proto.unifiedplatform.liveMeditation.ReportTypeWithIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.ReportTypeWithIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.ReportTypeWithIdRequest}
 */
proto.unifiedplatform.liveMeditation.ReportTypeWithIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.unifiedplatform.liveMeditation.UserReportType} */ (reader.readEnum());
      msg.setUserreporttype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.ReportTypeWithIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.ReportTypeWithIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.ReportTypeWithIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ReportTypeWithIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUserreporttype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.ReportTypeWithIdRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.ReportTypeWithIdRequest} returns this
 */
proto.unifiedplatform.liveMeditation.ReportTypeWithIdRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional UserReportType userReportType = 2;
 * @return {!proto.unifiedplatform.liveMeditation.UserReportType}
 */
proto.unifiedplatform.liveMeditation.ReportTypeWithIdRequest.prototype.getUserreporttype = function() {
  return /** @type {!proto.unifiedplatform.liveMeditation.UserReportType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.unifiedplatform.liveMeditation.UserReportType} value
 * @return {!proto.unifiedplatform.liveMeditation.ReportTypeWithIdRequest} returns this
 */
proto.unifiedplatform.liveMeditation.ReportTypeWithIdRequest.prototype.setUserreporttype = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.ZoomEventResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.ZoomEventResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.ZoomEventResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ZoomEventResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    plaintoken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    encryptedtoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.ZoomEventResponse}
 */
proto.unifiedplatform.liveMeditation.ZoomEventResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.ZoomEventResponse;
  return proto.unifiedplatform.liveMeditation.ZoomEventResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.ZoomEventResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.ZoomEventResponse}
 */
proto.unifiedplatform.liveMeditation.ZoomEventResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaintoken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEncryptedtoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.ZoomEventResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.ZoomEventResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.ZoomEventResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ZoomEventResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlaintoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEncryptedtoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string plainToken = 1;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.ZoomEventResponse.prototype.getPlaintoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.ZoomEventResponse} returns this
 */
proto.unifiedplatform.liveMeditation.ZoomEventResponse.prototype.setPlaintoken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string encryptedToken = 2;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.ZoomEventResponse.prototype.getEncryptedtoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.ZoomEventResponse} returns this
 */
proto.unifiedplatform.liveMeditation.ZoomEventResponse.prototype.setEncryptedtoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.ListOfSessionViewerCount.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.ListOfSessionViewerCount.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.ListOfSessionViewerCount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.ListOfSessionViewerCount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ListOfSessionViewerCount.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionviewercountList: jspb.Message.toObjectList(msg.getSessionviewercountList(),
    proto.unifiedplatform.liveMeditation.SessionViewerCountResponse.toObject, includeInstance),
    timeinterval: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.ListOfSessionViewerCount}
 */
proto.unifiedplatform.liveMeditation.ListOfSessionViewerCount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.ListOfSessionViewerCount;
  return proto.unifiedplatform.liveMeditation.ListOfSessionViewerCount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.ListOfSessionViewerCount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.ListOfSessionViewerCount}
 */
proto.unifiedplatform.liveMeditation.ListOfSessionViewerCount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.liveMeditation.SessionViewerCountResponse;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.SessionViewerCountResponse.deserializeBinaryFromReader);
      msg.addSessionviewercount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTimeinterval(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.ListOfSessionViewerCount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.ListOfSessionViewerCount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.ListOfSessionViewerCount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ListOfSessionViewerCount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionviewercountList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.liveMeditation.SessionViewerCountResponse.serializeBinaryToWriter
    );
  }
  f = message.getTimeinterval();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated SessionViewerCountResponse sessionViewerCount = 1;
 * @return {!Array<!proto.unifiedplatform.liveMeditation.SessionViewerCountResponse>}
 */
proto.unifiedplatform.liveMeditation.ListOfSessionViewerCount.prototype.getSessionviewercountList = function() {
  return /** @type{!Array<!proto.unifiedplatform.liveMeditation.SessionViewerCountResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.liveMeditation.SessionViewerCountResponse, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.liveMeditation.SessionViewerCountResponse>} value
 * @return {!proto.unifiedplatform.liveMeditation.ListOfSessionViewerCount} returns this
*/
proto.unifiedplatform.liveMeditation.ListOfSessionViewerCount.prototype.setSessionviewercountList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.liveMeditation.SessionViewerCountResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.SessionViewerCountResponse}
 */
proto.unifiedplatform.liveMeditation.ListOfSessionViewerCount.prototype.addSessionviewercount = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.liveMeditation.SessionViewerCountResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.ListOfSessionViewerCount} returns this
 */
proto.unifiedplatform.liveMeditation.ListOfSessionViewerCount.prototype.clearSessionviewercountList = function() {
  return this.setSessionviewercountList([]);
};


/**
 * optional int32 timeInterval = 2;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.ListOfSessionViewerCount.prototype.getTimeinterval = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.ListOfSessionViewerCount} returns this
 */
proto.unifiedplatform.liveMeditation.ListOfSessionViewerCount.prototype.setTimeinterval = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.SessionViewerCountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.SessionViewerCountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.SessionViewerCountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.SessionViewerCountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    livesessionid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    recurringTime: (f = msg.getRecurringTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    viwerCount: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.SessionViewerCountResponse}
 */
proto.unifiedplatform.liveMeditation.SessionViewerCountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.SessionViewerCountResponse;
  return proto.unifiedplatform.liveMeditation.SessionViewerCountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.SessionViewerCountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.SessionViewerCountResponse}
 */
proto.unifiedplatform.liveMeditation.SessionViewerCountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLivesessionid(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRecurringTime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setViwerCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.SessionViewerCountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.SessionViewerCountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.SessionViewerCountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.SessionViewerCountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLivesessionid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getRecurringTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getViwerCount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.SessionViewerCountResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionViewerCountResponse} returns this
 */
proto.unifiedplatform.liveMeditation.SessionViewerCountResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 liveSessionId = 2;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.SessionViewerCountResponse.prototype.getLivesessionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionViewerCountResponse} returns this
 */
proto.unifiedplatform.liveMeditation.SessionViewerCountResponse.prototype.setLivesessionid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp recurring_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.liveMeditation.SessionViewerCountResponse.prototype.getRecurringTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionViewerCountResponse} returns this
*/
proto.unifiedplatform.liveMeditation.SessionViewerCountResponse.prototype.setRecurringTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.SessionViewerCountResponse} returns this
 */
proto.unifiedplatform.liveMeditation.SessionViewerCountResponse.prototype.clearRecurringTime = function() {
  return this.setRecurringTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.SessionViewerCountResponse.prototype.hasRecurringTime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 viwer_count = 4;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.SessionViewerCountResponse.prototype.getViwerCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.SessionViewerCountResponse} returns this
 */
proto.unifiedplatform.liveMeditation.SessionViewerCountResponse.prototype.setViwerCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.PreRecordedRequest.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.PreRecordedRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.PreRecordedRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.PreRecordedRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.PreRecordedRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: (f = msg.getTitle()) && common_common_pb.Locale.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && common_common_pb.Locale.toObject(includeInstance, f),
    videourl: (f = msg.getVideourl()) && common_common_pb.Locale.toObject(includeInstance, f),
    meditationsteps: (f = msg.getMeditationsteps()) && common_common_pb.Locale.toObject(includeInstance, f),
    thumbnail: jspb.Message.getFieldWithDefault(msg, 5, ""),
    imgurl: jspb.Message.getFieldWithDefault(msg, 6, ""),
    prerecordedtype: jspb.Message.getFieldWithDefault(msg, 7, 0),
    videoduration: jspb.Message.getFieldWithDefault(msg, 9, 0),
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    proto.unifiedplatform.liveMeditation.Tag.toObject, includeInstance),
    attribute: (f = msg.getAttribute()) && common_common_pb.Locale.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedRequest}
 */
proto.unifiedplatform.liveMeditation.PreRecordedRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.PreRecordedRequest;
  return proto.unifiedplatform.liveMeditation.PreRecordedRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.PreRecordedRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedRequest}
 */
proto.unifiedplatform.liveMeditation.PreRecordedRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setTitle(value);
      break;
    case 2:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 3:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setVideourl(value);
      break;
    case 4:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setMeditationsteps(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbnail(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setImgurl(value);
      break;
    case 7:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.PreRecordedType} */ (reader.readEnum());
      msg.setPrerecordedtype(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVideoduration(value);
      break;
    case 10:
      var value = new proto.unifiedplatform.liveMeditation.Tag;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.Tag.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    case 11:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setAttribute(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.PreRecordedRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.PreRecordedRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.PreRecordedRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.PreRecordedRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getVideourl();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getMeditationsteps();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getThumbnail();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getImgurl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPrerecordedtype();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getVideoduration();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.unifiedplatform.liveMeditation.Tag.serializeBinaryToWriter
    );
  }
  f = message.getAttribute();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
};


/**
 * optional unifiedplatform.heartintune.common.Locale title = 1;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.liveMeditation.PreRecordedRequest.prototype.getTitle = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 1));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedRequest} returns this
*/
proto.unifiedplatform.liveMeditation.PreRecordedRequest.prototype.setTitle = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedRequest} returns this
 */
proto.unifiedplatform.liveMeditation.PreRecordedRequest.prototype.clearTitle = function() {
  return this.setTitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.PreRecordedRequest.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale description = 2;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.liveMeditation.PreRecordedRequest.prototype.getDescription = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 2));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedRequest} returns this
*/
proto.unifiedplatform.liveMeditation.PreRecordedRequest.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedRequest} returns this
 */
proto.unifiedplatform.liveMeditation.PreRecordedRequest.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.PreRecordedRequest.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale videoUrl = 3;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.liveMeditation.PreRecordedRequest.prototype.getVideourl = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 3));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedRequest} returns this
*/
proto.unifiedplatform.liveMeditation.PreRecordedRequest.prototype.setVideourl = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedRequest} returns this
 */
proto.unifiedplatform.liveMeditation.PreRecordedRequest.prototype.clearVideourl = function() {
  return this.setVideourl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.PreRecordedRequest.prototype.hasVideourl = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale meditationSteps = 4;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.liveMeditation.PreRecordedRequest.prototype.getMeditationsteps = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 4));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedRequest} returns this
*/
proto.unifiedplatform.liveMeditation.PreRecordedRequest.prototype.setMeditationsteps = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedRequest} returns this
 */
proto.unifiedplatform.liveMeditation.PreRecordedRequest.prototype.clearMeditationsteps = function() {
  return this.setMeditationsteps(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.PreRecordedRequest.prototype.hasMeditationsteps = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string thumbnail = 5;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.PreRecordedRequest.prototype.getThumbnail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedRequest} returns this
 */
proto.unifiedplatform.liveMeditation.PreRecordedRequest.prototype.setThumbnail = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string imgUrl = 6;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.PreRecordedRequest.prototype.getImgurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedRequest} returns this
 */
proto.unifiedplatform.liveMeditation.PreRecordedRequest.prototype.setImgurl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional unifiedplatform.heartintune.common.PreRecordedType preRecordedType = 7;
 * @return {!proto.unifiedplatform.heartintune.common.PreRecordedType}
 */
proto.unifiedplatform.liveMeditation.PreRecordedRequest.prototype.getPrerecordedtype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.PreRecordedType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.PreRecordedType} value
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedRequest} returns this
 */
proto.unifiedplatform.liveMeditation.PreRecordedRequest.prototype.setPrerecordedtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional int64 videoDuration = 9;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.PreRecordedRequest.prototype.getVideoduration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedRequest} returns this
 */
proto.unifiedplatform.liveMeditation.PreRecordedRequest.prototype.setVideoduration = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * repeated Tag tags = 10;
 * @return {!Array<!proto.unifiedplatform.liveMeditation.Tag>}
 */
proto.unifiedplatform.liveMeditation.PreRecordedRequest.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.liveMeditation.Tag>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.liveMeditation.Tag, 10));
};


/**
 * @param {!Array<!proto.unifiedplatform.liveMeditation.Tag>} value
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedRequest} returns this
*/
proto.unifiedplatform.liveMeditation.PreRecordedRequest.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.unifiedplatform.liveMeditation.Tag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.Tag}
 */
proto.unifiedplatform.liveMeditation.PreRecordedRequest.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.unifiedplatform.liveMeditation.Tag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedRequest} returns this
 */
proto.unifiedplatform.liveMeditation.PreRecordedRequest.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * optional unifiedplatform.heartintune.common.Locale attribute = 11;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.liveMeditation.PreRecordedRequest.prototype.getAttribute = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 11));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedRequest} returns this
*/
proto.unifiedplatform.liveMeditation.PreRecordedRequest.prototype.setAttribute = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedRequest} returns this
 */
proto.unifiedplatform.liveMeditation.PreRecordedRequest.prototype.clearAttribute = function() {
  return this.setAttribute(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.PreRecordedRequest.prototype.hasAttribute = function() {
  return jspb.Message.getField(this, 11) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.PreRecordedResponse.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.PreRecordedResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.PreRecordedResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.PreRecordedResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.PreRecordedResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: (f = msg.getTitle()) && common_common_pb.Locale.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && common_common_pb.Locale.toObject(includeInstance, f),
    videourl: (f = msg.getVideourl()) && common_common_pb.Locale.toObject(includeInstance, f),
    meditationsteps: (f = msg.getMeditationsteps()) && common_common_pb.Locale.toObject(includeInstance, f),
    thumbnail: jspb.Message.getFieldWithDefault(msg, 6, ""),
    imgurl: jspb.Message.getFieldWithDefault(msg, 7, ""),
    prerecordedtype: jspb.Message.getFieldWithDefault(msg, 8, 0),
    videoduration: jspb.Message.getFieldWithDefault(msg, 9, 0),
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    proto.unifiedplatform.liveMeditation.Tag.toObject, includeInstance),
    attribute: (f = msg.getAttribute()) && common_common_pb.Locale.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedResponse}
 */
proto.unifiedplatform.liveMeditation.PreRecordedResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.PreRecordedResponse;
  return proto.unifiedplatform.liveMeditation.PreRecordedResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.PreRecordedResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedResponse}
 */
proto.unifiedplatform.liveMeditation.PreRecordedResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setTitle(value);
      break;
    case 3:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 4:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setVideourl(value);
      break;
    case 5:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setMeditationsteps(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbnail(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setImgurl(value);
      break;
    case 8:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.PreRecordedType} */ (reader.readEnum());
      msg.setPrerecordedtype(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVideoduration(value);
      break;
    case 10:
      var value = new proto.unifiedplatform.liveMeditation.Tag;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.Tag.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    case 11:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setAttribute(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.PreRecordedResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.PreRecordedResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.PreRecordedResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.PreRecordedResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getVideourl();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getMeditationsteps();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getThumbnail();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getImgurl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPrerecordedtype();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getVideoduration();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.unifiedplatform.liveMeditation.Tag.serializeBinaryToWriter
    );
  }
  f = message.getAttribute();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.PreRecordedResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedResponse} returns this
 */
proto.unifiedplatform.liveMeditation.PreRecordedResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.Locale title = 2;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.liveMeditation.PreRecordedResponse.prototype.getTitle = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 2));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedResponse} returns this
*/
proto.unifiedplatform.liveMeditation.PreRecordedResponse.prototype.setTitle = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedResponse} returns this
 */
proto.unifiedplatform.liveMeditation.PreRecordedResponse.prototype.clearTitle = function() {
  return this.setTitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.PreRecordedResponse.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale description = 3;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.liveMeditation.PreRecordedResponse.prototype.getDescription = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 3));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedResponse} returns this
*/
proto.unifiedplatform.liveMeditation.PreRecordedResponse.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedResponse} returns this
 */
proto.unifiedplatform.liveMeditation.PreRecordedResponse.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.PreRecordedResponse.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale videoUrl = 4;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.liveMeditation.PreRecordedResponse.prototype.getVideourl = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 4));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedResponse} returns this
*/
proto.unifiedplatform.liveMeditation.PreRecordedResponse.prototype.setVideourl = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedResponse} returns this
 */
proto.unifiedplatform.liveMeditation.PreRecordedResponse.prototype.clearVideourl = function() {
  return this.setVideourl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.PreRecordedResponse.prototype.hasVideourl = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale meditationSteps = 5;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.liveMeditation.PreRecordedResponse.prototype.getMeditationsteps = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 5));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedResponse} returns this
*/
proto.unifiedplatform.liveMeditation.PreRecordedResponse.prototype.setMeditationsteps = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedResponse} returns this
 */
proto.unifiedplatform.liveMeditation.PreRecordedResponse.prototype.clearMeditationsteps = function() {
  return this.setMeditationsteps(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.PreRecordedResponse.prototype.hasMeditationsteps = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string thumbnail = 6;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.PreRecordedResponse.prototype.getThumbnail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedResponse} returns this
 */
proto.unifiedplatform.liveMeditation.PreRecordedResponse.prototype.setThumbnail = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string imgUrl = 7;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.PreRecordedResponse.prototype.getImgurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedResponse} returns this
 */
proto.unifiedplatform.liveMeditation.PreRecordedResponse.prototype.setImgurl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional unifiedplatform.heartintune.common.PreRecordedType preRecordedType = 8;
 * @return {!proto.unifiedplatform.heartintune.common.PreRecordedType}
 */
proto.unifiedplatform.liveMeditation.PreRecordedResponse.prototype.getPrerecordedtype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.PreRecordedType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.PreRecordedType} value
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedResponse} returns this
 */
proto.unifiedplatform.liveMeditation.PreRecordedResponse.prototype.setPrerecordedtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional int64 videoDuration = 9;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.PreRecordedResponse.prototype.getVideoduration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedResponse} returns this
 */
proto.unifiedplatform.liveMeditation.PreRecordedResponse.prototype.setVideoduration = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * repeated Tag tags = 10;
 * @return {!Array<!proto.unifiedplatform.liveMeditation.Tag>}
 */
proto.unifiedplatform.liveMeditation.PreRecordedResponse.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.liveMeditation.Tag>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.liveMeditation.Tag, 10));
};


/**
 * @param {!Array<!proto.unifiedplatform.liveMeditation.Tag>} value
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedResponse} returns this
*/
proto.unifiedplatform.liveMeditation.PreRecordedResponse.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.unifiedplatform.liveMeditation.Tag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.Tag}
 */
proto.unifiedplatform.liveMeditation.PreRecordedResponse.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.unifiedplatform.liveMeditation.Tag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedResponse} returns this
 */
proto.unifiedplatform.liveMeditation.PreRecordedResponse.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * optional unifiedplatform.heartintune.common.Locale attribute = 11;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.liveMeditation.PreRecordedResponse.prototype.getAttribute = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 11));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedResponse} returns this
*/
proto.unifiedplatform.liveMeditation.PreRecordedResponse.prototype.setAttribute = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedResponse} returns this
 */
proto.unifiedplatform.liveMeditation.PreRecordedResponse.prototype.clearAttribute = function() {
  return this.setAttribute(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.PreRecordedResponse.prototype.hasAttribute = function() {
  return jspb.Message.getField(this, 11) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.LocaleType.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.LocaleType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.LocaleType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.LocaleType.toObject = function(includeInstance, msg) {
  var f, obj = {
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 1, 0),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0),
    respectiveid: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.LocaleType}
 */
proto.unifiedplatform.liveMeditation.LocaleType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.LocaleType;
  return proto.unifiedplatform.liveMeditation.LocaleType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.LocaleType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.LocaleType}
 */
proto.unifiedplatform.liveMeditation.LocaleType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRespectiveid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.LocaleType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.LocaleType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.LocaleType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.LocaleType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getRespectiveid();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 1;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.liveMeditation.LocaleType.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.liveMeditation.LocaleType} returns this
 */
proto.unifiedplatform.liveMeditation.LocaleType.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int64 id = 2;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.LocaleType.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.LocaleType} returns this
 */
proto.unifiedplatform.liveMeditation.LocaleType.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 respectiveId = 3;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.LocaleType.prototype.getRespectiveid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.LocaleType} returns this
 */
proto.unifiedplatform.liveMeditation.LocaleType.prototype.setRespectiveid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    videourl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    meditationsteps: jspb.Message.getFieldWithDefault(msg, 5, ""),
    thumbnail: jspb.Message.getFieldWithDefault(msg, 6, ""),
    imgurl: jspb.Message.getFieldWithDefault(msg, 7, ""),
    prerecordedtype: jspb.Message.getFieldWithDefault(msg, 8, 0),
    videoduration: jspb.Message.getFieldWithDefault(msg, 9, 0),
    attribute: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse}
 */
proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse;
  return proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse}
 */
proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setVideourl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeditationsteps(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbnail(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setImgurl(value);
      break;
    case 8:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.PreRecordedType} */ (reader.readEnum());
      msg.setPrerecordedtype(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVideoduration(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttribute(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getVideourl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMeditationsteps();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getThumbnail();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getImgurl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPrerecordedtype();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getVideoduration();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getAttribute();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse} returns this
 */
proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse} returns this
 */
proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse} returns this
 */
proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string videoUrl = 4;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse.prototype.getVideourl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse} returns this
 */
proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse.prototype.setVideourl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string meditationSteps = 5;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse.prototype.getMeditationsteps = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse} returns this
 */
proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse.prototype.setMeditationsteps = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string thumbnail = 6;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse.prototype.getThumbnail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse} returns this
 */
proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse.prototype.setThumbnail = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string imgUrl = 7;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse.prototype.getImgurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse} returns this
 */
proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse.prototype.setImgurl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional unifiedplatform.heartintune.common.PreRecordedType preRecordedType = 8;
 * @return {!proto.unifiedplatform.heartintune.common.PreRecordedType}
 */
proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse.prototype.getPrerecordedtype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.PreRecordedType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.PreRecordedType} value
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse} returns this
 */
proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse.prototype.setPrerecordedtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional int64 videoDuration = 9;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse.prototype.getVideoduration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse} returns this
 */
proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse.prototype.setVideoduration = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string attribute = 10;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse.prototype.getAttribute = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse} returns this
 */
proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse.prototype.setAttribute = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.GetAllPreRecordedResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.GetAllPreRecordedResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.GetAllPreRecordedResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.GetAllPreRecordedResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.GetAllPreRecordedResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    prerecordedsList: jspb.Message.toObjectList(msg.getPrerecordedsList(),
    proto.unifiedplatform.liveMeditation.PreRecordedResponse.toObject, includeInstance),
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.liveMeditation.ResponsePageable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.GetAllPreRecordedResponse}
 */
proto.unifiedplatform.liveMeditation.GetAllPreRecordedResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.GetAllPreRecordedResponse;
  return proto.unifiedplatform.liveMeditation.GetAllPreRecordedResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.GetAllPreRecordedResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.GetAllPreRecordedResponse}
 */
proto.unifiedplatform.liveMeditation.GetAllPreRecordedResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.liveMeditation.PreRecordedResponse;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.PreRecordedResponse.deserializeBinaryFromReader);
      msg.addPrerecordeds(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.liveMeditation.ResponsePageable;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.ResponsePageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.GetAllPreRecordedResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.GetAllPreRecordedResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.GetAllPreRecordedResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.GetAllPreRecordedResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrerecordedsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.liveMeditation.PreRecordedResponse.serializeBinaryToWriter
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.liveMeditation.ResponsePageable.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PreRecordedResponse preRecordeds = 1;
 * @return {!Array<!proto.unifiedplatform.liveMeditation.PreRecordedResponse>}
 */
proto.unifiedplatform.liveMeditation.GetAllPreRecordedResponse.prototype.getPrerecordedsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.liveMeditation.PreRecordedResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.liveMeditation.PreRecordedResponse, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.liveMeditation.PreRecordedResponse>} value
 * @return {!proto.unifiedplatform.liveMeditation.GetAllPreRecordedResponse} returns this
*/
proto.unifiedplatform.liveMeditation.GetAllPreRecordedResponse.prototype.setPrerecordedsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.liveMeditation.PreRecordedResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedResponse}
 */
proto.unifiedplatform.liveMeditation.GetAllPreRecordedResponse.prototype.addPrerecordeds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.liveMeditation.PreRecordedResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.GetAllPreRecordedResponse} returns this
 */
proto.unifiedplatform.liveMeditation.GetAllPreRecordedResponse.prototype.clearPrerecordedsList = function() {
  return this.setPrerecordedsList([]);
};


/**
 * optional ResponsePageable pageable = 2;
 * @return {?proto.unifiedplatform.liveMeditation.ResponsePageable}
 */
proto.unifiedplatform.liveMeditation.GetAllPreRecordedResponse.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.liveMeditation.ResponsePageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.liveMeditation.ResponsePageable, 2));
};


/**
 * @param {?proto.unifiedplatform.liveMeditation.ResponsePageable|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.GetAllPreRecordedResponse} returns this
*/
proto.unifiedplatform.liveMeditation.GetAllPreRecordedResponse.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.GetAllPreRecordedResponse} returns this
 */
proto.unifiedplatform.liveMeditation.GetAllPreRecordedResponse.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.GetAllPreRecordedResponse.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.GroupsRequest.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.GroupsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.GroupsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.GroupsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.GroupsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    scheduletime: (f = msg.getScheduletime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    prerecordedid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    userjoincount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    recurrence: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    recurringendtime: (f = msg.getRecurringendtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    dayfrequency: jspb.Message.getFieldWithDefault(msg, 6, 0),
    weekdaysList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    userregistercount: jspb.Message.getFieldWithDefault(msg, 8, 0),
    zonaldifference: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.GroupsRequest}
 */
proto.unifiedplatform.liveMeditation.GroupsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.GroupsRequest;
  return proto.unifiedplatform.liveMeditation.GroupsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.GroupsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.GroupsRequest}
 */
proto.unifiedplatform.liveMeditation.GroupsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setScheduletime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPrerecordedid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserjoincount(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRecurrence(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRecurringendtime(value);
      break;
    case 6:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.DayFrequency} */ (reader.readEnum());
      msg.setDayfrequency(value);
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addWeekdays(values[i]);
      }
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserregistercount(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setZonaldifference(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.GroupsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.GroupsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.GroupsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.GroupsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScheduletime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPrerecordedid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUserjoincount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getRecurrence();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getRecurringendtime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDayfrequency();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getWeekdaysList();
  if (f.length > 0) {
    writer.writePackedInt32(
      7,
      f
    );
  }
  f = message.getUserregistercount();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getZonaldifference();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp scheduleTime = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.liveMeditation.GroupsRequest.prototype.getScheduletime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.GroupsRequest} returns this
*/
proto.unifiedplatform.liveMeditation.GroupsRequest.prototype.setScheduletime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.GroupsRequest} returns this
 */
proto.unifiedplatform.liveMeditation.GroupsRequest.prototype.clearScheduletime = function() {
  return this.setScheduletime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.GroupsRequest.prototype.hasScheduletime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 preRecordedId = 2;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.GroupsRequest.prototype.getPrerecordedid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.GroupsRequest} returns this
 */
proto.unifiedplatform.liveMeditation.GroupsRequest.prototype.setPrerecordedid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 userJoinCount = 3;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.GroupsRequest.prototype.getUserjoincount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.GroupsRequest} returns this
 */
proto.unifiedplatform.liveMeditation.GroupsRequest.prototype.setUserjoincount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool recurrence = 4;
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.GroupsRequest.prototype.getRecurrence = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.liveMeditation.GroupsRequest} returns this
 */
proto.unifiedplatform.liveMeditation.GroupsRequest.prototype.setRecurrence = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp recurringEndTime = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.liveMeditation.GroupsRequest.prototype.getRecurringendtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.GroupsRequest} returns this
*/
proto.unifiedplatform.liveMeditation.GroupsRequest.prototype.setRecurringendtime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.GroupsRequest} returns this
 */
proto.unifiedplatform.liveMeditation.GroupsRequest.prototype.clearRecurringendtime = function() {
  return this.setRecurringendtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.GroupsRequest.prototype.hasRecurringendtime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional unifiedplatform.heartintune.common.DayFrequency dayFrequency = 6;
 * @return {!proto.unifiedplatform.heartintune.common.DayFrequency}
 */
proto.unifiedplatform.liveMeditation.GroupsRequest.prototype.getDayfrequency = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.DayFrequency} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.DayFrequency} value
 * @return {!proto.unifiedplatform.liveMeditation.GroupsRequest} returns this
 */
proto.unifiedplatform.liveMeditation.GroupsRequest.prototype.setDayfrequency = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * repeated int32 weekDays = 7;
 * @return {!Array<number>}
 */
proto.unifiedplatform.liveMeditation.GroupsRequest.prototype.getWeekdaysList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.liveMeditation.GroupsRequest} returns this
 */
proto.unifiedplatform.liveMeditation.GroupsRequest.prototype.setWeekdaysList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.GroupsRequest} returns this
 */
proto.unifiedplatform.liveMeditation.GroupsRequest.prototype.addWeekdays = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.GroupsRequest} returns this
 */
proto.unifiedplatform.liveMeditation.GroupsRequest.prototype.clearWeekdaysList = function() {
  return this.setWeekdaysList([]);
};


/**
 * optional int64 userRegisterCount = 8;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.GroupsRequest.prototype.getUserregistercount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.GroupsRequest} returns this
 */
proto.unifiedplatform.liveMeditation.GroupsRequest.prototype.setUserregistercount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 zonalDifference = 9;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.GroupsRequest.prototype.getZonaldifference = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.GroupsRequest} returns this
 */
proto.unifiedplatform.liveMeditation.GroupsRequest.prototype.setZonaldifference = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.GroupsResponse.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.GroupsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.GroupsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.GroupsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.GroupsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    scheduletime: (f = msg.getScheduletime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createat: (f = msg.getCreateat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    prerecorded: (f = msg.getPrerecorded()) && proto.unifiedplatform.liveMeditation.PreRecordedResponse.toObject(includeInstance, f),
    userjoincount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    recurrence: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    recurringendtime: (f = msg.getRecurringendtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    dayfrequency: jspb.Message.getFieldWithDefault(msg, 8, 0),
    weekdaysList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    userregistercount: jspb.Message.getFieldWithDefault(msg, 10, 0),
    firebasetopicname: jspb.Message.getFieldWithDefault(msg, 11, ""),
    publish: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    groupoccurrenceid: jspb.Message.getFieldWithDefault(msg, 13, 0),
    zonaldifference: jspb.Message.getFieldWithDefault(msg, 14, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.GroupsResponse}
 */
proto.unifiedplatform.liveMeditation.GroupsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.GroupsResponse;
  return proto.unifiedplatform.liveMeditation.GroupsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.GroupsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.GroupsResponse}
 */
proto.unifiedplatform.liveMeditation.GroupsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setScheduletime(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateat(value);
      break;
    case 4:
      var value = new proto.unifiedplatform.liveMeditation.PreRecordedResponse;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.PreRecordedResponse.deserializeBinaryFromReader);
      msg.setPrerecorded(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserjoincount(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRecurrence(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRecurringendtime(value);
      break;
    case 8:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.DayFrequency} */ (reader.readEnum());
      msg.setDayfrequency(value);
      break;
    case 9:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addWeekdays(values[i]);
      }
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserregistercount(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirebasetopicname(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPublish(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGroupoccurrenceid(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setZonaldifference(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.GroupsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.GroupsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.GroupsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.GroupsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getScheduletime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreateat();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPrerecorded();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.unifiedplatform.liveMeditation.PreRecordedResponse.serializeBinaryToWriter
    );
  }
  f = message.getUserjoincount();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getRecurrence();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getRecurringendtime();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDayfrequency();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getWeekdaysList();
  if (f.length > 0) {
    writer.writePackedInt32(
      9,
      f
    );
  }
  f = message.getUserregistercount();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getFirebasetopicname();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPublish();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getGroupoccurrenceid();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getZonaldifference();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.GroupsResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.GroupsResponse} returns this
 */
proto.unifiedplatform.liveMeditation.GroupsResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp scheduleTime = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.liveMeditation.GroupsResponse.prototype.getScheduletime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.GroupsResponse} returns this
*/
proto.unifiedplatform.liveMeditation.GroupsResponse.prototype.setScheduletime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.GroupsResponse} returns this
 */
proto.unifiedplatform.liveMeditation.GroupsResponse.prototype.clearScheduletime = function() {
  return this.setScheduletime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.GroupsResponse.prototype.hasScheduletime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp createAt = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.liveMeditation.GroupsResponse.prototype.getCreateat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.GroupsResponse} returns this
*/
proto.unifiedplatform.liveMeditation.GroupsResponse.prototype.setCreateat = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.GroupsResponse} returns this
 */
proto.unifiedplatform.liveMeditation.GroupsResponse.prototype.clearCreateat = function() {
  return this.setCreateat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.GroupsResponse.prototype.hasCreateat = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional PreRecordedResponse preRecorded = 4;
 * @return {?proto.unifiedplatform.liveMeditation.PreRecordedResponse}
 */
proto.unifiedplatform.liveMeditation.GroupsResponse.prototype.getPrerecorded = function() {
  return /** @type{?proto.unifiedplatform.liveMeditation.PreRecordedResponse} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.liveMeditation.PreRecordedResponse, 4));
};


/**
 * @param {?proto.unifiedplatform.liveMeditation.PreRecordedResponse|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.GroupsResponse} returns this
*/
proto.unifiedplatform.liveMeditation.GroupsResponse.prototype.setPrerecorded = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.GroupsResponse} returns this
 */
proto.unifiedplatform.liveMeditation.GroupsResponse.prototype.clearPrerecorded = function() {
  return this.setPrerecorded(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.GroupsResponse.prototype.hasPrerecorded = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int64 userJoinCount = 5;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.GroupsResponse.prototype.getUserjoincount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.GroupsResponse} returns this
 */
proto.unifiedplatform.liveMeditation.GroupsResponse.prototype.setUserjoincount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bool recurrence = 6;
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.GroupsResponse.prototype.getRecurrence = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.liveMeditation.GroupsResponse} returns this
 */
proto.unifiedplatform.liveMeditation.GroupsResponse.prototype.setRecurrence = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp recurringEndTime = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.liveMeditation.GroupsResponse.prototype.getRecurringendtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.GroupsResponse} returns this
*/
proto.unifiedplatform.liveMeditation.GroupsResponse.prototype.setRecurringendtime = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.GroupsResponse} returns this
 */
proto.unifiedplatform.liveMeditation.GroupsResponse.prototype.clearRecurringendtime = function() {
  return this.setRecurringendtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.GroupsResponse.prototype.hasRecurringendtime = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional unifiedplatform.heartintune.common.DayFrequency dayFrequency = 8;
 * @return {!proto.unifiedplatform.heartintune.common.DayFrequency}
 */
proto.unifiedplatform.liveMeditation.GroupsResponse.prototype.getDayfrequency = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.DayFrequency} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.DayFrequency} value
 * @return {!proto.unifiedplatform.liveMeditation.GroupsResponse} returns this
 */
proto.unifiedplatform.liveMeditation.GroupsResponse.prototype.setDayfrequency = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * repeated int32 weekDays = 9;
 * @return {!Array<number>}
 */
proto.unifiedplatform.liveMeditation.GroupsResponse.prototype.getWeekdaysList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.liveMeditation.GroupsResponse} returns this
 */
proto.unifiedplatform.liveMeditation.GroupsResponse.prototype.setWeekdaysList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.GroupsResponse} returns this
 */
proto.unifiedplatform.liveMeditation.GroupsResponse.prototype.addWeekdays = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.GroupsResponse} returns this
 */
proto.unifiedplatform.liveMeditation.GroupsResponse.prototype.clearWeekdaysList = function() {
  return this.setWeekdaysList([]);
};


/**
 * optional int64 userRegisterCount = 10;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.GroupsResponse.prototype.getUserregistercount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.GroupsResponse} returns this
 */
proto.unifiedplatform.liveMeditation.GroupsResponse.prototype.setUserregistercount = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string firebaseTopicName = 11;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.GroupsResponse.prototype.getFirebasetopicname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.GroupsResponse} returns this
 */
proto.unifiedplatform.liveMeditation.GroupsResponse.prototype.setFirebasetopicname = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional bool publish = 12;
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.GroupsResponse.prototype.getPublish = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.liveMeditation.GroupsResponse} returns this
 */
proto.unifiedplatform.liveMeditation.GroupsResponse.prototype.setPublish = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional int64 groupOccurrenceId = 13;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.GroupsResponse.prototype.getGroupoccurrenceid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.GroupsResponse} returns this
 */
proto.unifiedplatform.liveMeditation.GroupsResponse.prototype.setGroupoccurrenceid = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int64 zonalDifference = 14;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.GroupsResponse.prototype.getZonaldifference = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.GroupsResponse} returns this
 */
proto.unifiedplatform.liveMeditation.GroupsResponse.prototype.setZonaldifference = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    scheduletime: (f = msg.getScheduletime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createat: (f = msg.getCreateat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    prerecorded: (f = msg.getPrerecorded()) && proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse.toObject(includeInstance, f),
    userjoincount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    recurrence: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    recurringendtime: (f = msg.getRecurringendtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    dayfrequency: jspb.Message.getFieldWithDefault(msg, 8, 0),
    weekdaysList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    userregistercount: jspb.Message.getFieldWithDefault(msg, 10, 0),
    firebasetopicname: jspb.Message.getFieldWithDefault(msg, 11, ""),
    publish: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    groupoccurrenceid: jspb.Message.getFieldWithDefault(msg, 13, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType}
 */
proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType;
  return proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType}
 */
proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setScheduletime(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateat(value);
      break;
    case 4:
      var value = new proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse.deserializeBinaryFromReader);
      msg.setPrerecorded(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserjoincount(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRecurrence(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRecurringendtime(value);
      break;
    case 8:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.DayFrequency} */ (reader.readEnum());
      msg.setDayfrequency(value);
      break;
    case 9:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addWeekdays(values[i]);
      }
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserregistercount(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirebasetopicname(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPublish(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGroupoccurrenceid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getScheduletime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreateat();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPrerecorded();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse.serializeBinaryToWriter
    );
  }
  f = message.getUserjoincount();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getRecurrence();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getRecurringendtime();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDayfrequency();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getWeekdaysList();
  if (f.length > 0) {
    writer.writePackedInt32(
      9,
      f
    );
  }
  f = message.getUserregistercount();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getFirebasetopicname();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPublish();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getGroupoccurrenceid();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType} returns this
 */
proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp scheduleTime = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType.prototype.getScheduletime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType} returns this
*/
proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType.prototype.setScheduletime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType} returns this
 */
proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType.prototype.clearScheduletime = function() {
  return this.setScheduletime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType.prototype.hasScheduletime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp createAt = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType.prototype.getCreateat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType} returns this
*/
proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType.prototype.setCreateat = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType} returns this
 */
proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType.prototype.clearCreateat = function() {
  return this.setCreateat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType.prototype.hasCreateat = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional PreRecordedWithLocaleResponse preRecorded = 4;
 * @return {?proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse}
 */
proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType.prototype.getPrerecorded = function() {
  return /** @type{?proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse, 4));
};


/**
 * @param {?proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType} returns this
*/
proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType.prototype.setPrerecorded = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType} returns this
 */
proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType.prototype.clearPrerecorded = function() {
  return this.setPrerecorded(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType.prototype.hasPrerecorded = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int64 userJoinCount = 5;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType.prototype.getUserjoincount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType} returns this
 */
proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType.prototype.setUserjoincount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bool recurrence = 6;
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType.prototype.getRecurrence = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType} returns this
 */
proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType.prototype.setRecurrence = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp recurringEndTime = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType.prototype.getRecurringendtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType} returns this
*/
proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType.prototype.setRecurringendtime = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType} returns this
 */
proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType.prototype.clearRecurringendtime = function() {
  return this.setRecurringendtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType.prototype.hasRecurringendtime = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional unifiedplatform.heartintune.common.DayFrequency dayFrequency = 8;
 * @return {!proto.unifiedplatform.heartintune.common.DayFrequency}
 */
proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType.prototype.getDayfrequency = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.DayFrequency} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.DayFrequency} value
 * @return {!proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType} returns this
 */
proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType.prototype.setDayfrequency = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * repeated int32 weekDays = 9;
 * @return {!Array<number>}
 */
proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType.prototype.getWeekdaysList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType} returns this
 */
proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType.prototype.setWeekdaysList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType} returns this
 */
proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType.prototype.addWeekdays = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType} returns this
 */
proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType.prototype.clearWeekdaysList = function() {
  return this.setWeekdaysList([]);
};


/**
 * optional int64 userRegisterCount = 10;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType.prototype.getUserregistercount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType} returns this
 */
proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType.prototype.setUserregistercount = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string firebaseTopicName = 11;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType.prototype.getFirebasetopicname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType} returns this
 */
proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType.prototype.setFirebasetopicname = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional bool publish = 12;
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType.prototype.getPublish = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType} returns this
 */
proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType.prototype.setPublish = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional int64 groupOccurrenceId = 13;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType.prototype.getGroupoccurrenceid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType} returns this
 */
proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType.prototype.setGroupoccurrenceid = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.ListOfGroups.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.ListOfGroups.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.ListOfGroups.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.ListOfGroups} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ListOfGroups.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupsList: jspb.Message.toObjectList(msg.getGroupsList(),
    proto.unifiedplatform.liveMeditation.GroupsResponse.toObject, includeInstance),
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.liveMeditation.ResponsePageable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.ListOfGroups}
 */
proto.unifiedplatform.liveMeditation.ListOfGroups.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.ListOfGroups;
  return proto.unifiedplatform.liveMeditation.ListOfGroups.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.ListOfGroups} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.ListOfGroups}
 */
proto.unifiedplatform.liveMeditation.ListOfGroups.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.liveMeditation.GroupsResponse;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.GroupsResponse.deserializeBinaryFromReader);
      msg.addGroups(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.liveMeditation.ResponsePageable;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.ResponsePageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.ListOfGroups.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.ListOfGroups.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.ListOfGroups} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ListOfGroups.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.liveMeditation.GroupsResponse.serializeBinaryToWriter
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.liveMeditation.ResponsePageable.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GroupsResponse groups = 1;
 * @return {!Array<!proto.unifiedplatform.liveMeditation.GroupsResponse>}
 */
proto.unifiedplatform.liveMeditation.ListOfGroups.prototype.getGroupsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.liveMeditation.GroupsResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.liveMeditation.GroupsResponse, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.liveMeditation.GroupsResponse>} value
 * @return {!proto.unifiedplatform.liveMeditation.ListOfGroups} returns this
*/
proto.unifiedplatform.liveMeditation.ListOfGroups.prototype.setGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.liveMeditation.GroupsResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.GroupsResponse}
 */
proto.unifiedplatform.liveMeditation.ListOfGroups.prototype.addGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.liveMeditation.GroupsResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.ListOfGroups} returns this
 */
proto.unifiedplatform.liveMeditation.ListOfGroups.prototype.clearGroupsList = function() {
  return this.setGroupsList([]);
};


/**
 * optional ResponsePageable pageable = 2;
 * @return {?proto.unifiedplatform.liveMeditation.ResponsePageable}
 */
proto.unifiedplatform.liveMeditation.ListOfGroups.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.liveMeditation.ResponsePageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.liveMeditation.ResponsePageable, 2));
};


/**
 * @param {?proto.unifiedplatform.liveMeditation.ResponsePageable|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.ListOfGroups} returns this
*/
proto.unifiedplatform.liveMeditation.ListOfGroups.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.ListOfGroups} returns this
 */
proto.unifiedplatform.liveMeditation.ListOfGroups.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.ListOfGroups.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.ListOfGroupsWithSelectLanguage.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.ListOfGroupsWithSelectLanguage.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.ListOfGroupsWithSelectLanguage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.ListOfGroupsWithSelectLanguage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ListOfGroupsWithSelectLanguage.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupsList: jspb.Message.toObjectList(msg.getGroupsList(),
    proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType.toObject, includeInstance),
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.liveMeditation.ResponsePageable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.ListOfGroupsWithSelectLanguage}
 */
proto.unifiedplatform.liveMeditation.ListOfGroupsWithSelectLanguage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.ListOfGroupsWithSelectLanguage;
  return proto.unifiedplatform.liveMeditation.ListOfGroupsWithSelectLanguage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.ListOfGroupsWithSelectLanguage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.ListOfGroupsWithSelectLanguage}
 */
proto.unifiedplatform.liveMeditation.ListOfGroupsWithSelectLanguage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType.deserializeBinaryFromReader);
      msg.addGroups(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.liveMeditation.ResponsePageable;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.ResponsePageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.ListOfGroupsWithSelectLanguage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.ListOfGroupsWithSelectLanguage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.ListOfGroupsWithSelectLanguage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ListOfGroupsWithSelectLanguage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType.serializeBinaryToWriter
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.liveMeditation.ResponsePageable.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GroupsResponseWithLocaleType groups = 1;
 * @return {!Array<!proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType>}
 */
proto.unifiedplatform.liveMeditation.ListOfGroupsWithSelectLanguage.prototype.getGroupsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType>} value
 * @return {!proto.unifiedplatform.liveMeditation.ListOfGroupsWithSelectLanguage} returns this
*/
proto.unifiedplatform.liveMeditation.ListOfGroupsWithSelectLanguage.prototype.setGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType}
 */
proto.unifiedplatform.liveMeditation.ListOfGroupsWithSelectLanguage.prototype.addGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.liveMeditation.GroupsResponseWithLocaleType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.ListOfGroupsWithSelectLanguage} returns this
 */
proto.unifiedplatform.liveMeditation.ListOfGroupsWithSelectLanguage.prototype.clearGroupsList = function() {
  return this.setGroupsList([]);
};


/**
 * optional ResponsePageable pageable = 2;
 * @return {?proto.unifiedplatform.liveMeditation.ResponsePageable}
 */
proto.unifiedplatform.liveMeditation.ListOfGroupsWithSelectLanguage.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.liveMeditation.ResponsePageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.liveMeditation.ResponsePageable, 2));
};


/**
 * @param {?proto.unifiedplatform.liveMeditation.ResponsePageable|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.ListOfGroupsWithSelectLanguage} returns this
*/
proto.unifiedplatform.liveMeditation.ListOfGroupsWithSelectLanguage.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.ListOfGroupsWithSelectLanguage} returns this
 */
proto.unifiedplatform.liveMeditation.ListOfGroupsWithSelectLanguage.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.ListOfGroupsWithSelectLanguage.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.RequestGroupsBetweenDateRange.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.RequestGroupsBetweenDateRange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.RequestGroupsBetweenDateRange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestGroupsBetweenDateRange.toObject = function(includeInstance, msg) {
  var f, obj = {
    startdate: (f = msg.getStartdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    enddate: (f = msg.getEnddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.liveMeditation.RequestPageable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.RequestGroupsBetweenDateRange}
 */
proto.unifiedplatform.liveMeditation.RequestGroupsBetweenDateRange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.RequestGroupsBetweenDateRange;
  return proto.unifiedplatform.liveMeditation.RequestGroupsBetweenDateRange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.RequestGroupsBetweenDateRange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.RequestGroupsBetweenDateRange}
 */
proto.unifiedplatform.liveMeditation.RequestGroupsBetweenDateRange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartdate(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnddate(value);
      break;
    case 3:
      var value = new proto.unifiedplatform.liveMeditation.RequestPageable;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.RequestPageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.RequestGroupsBetweenDateRange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.RequestGroupsBetweenDateRange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.RequestGroupsBetweenDateRange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestGroupsBetweenDateRange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartdate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnddate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.unifiedplatform.liveMeditation.RequestPageable.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp startDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.liveMeditation.RequestGroupsBetweenDateRange.prototype.getStartdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestGroupsBetweenDateRange} returns this
*/
proto.unifiedplatform.liveMeditation.RequestGroupsBetweenDateRange.prototype.setStartdate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.RequestGroupsBetweenDateRange} returns this
 */
proto.unifiedplatform.liveMeditation.RequestGroupsBetweenDateRange.prototype.clearStartdate = function() {
  return this.setStartdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.RequestGroupsBetweenDateRange.prototype.hasStartdate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp endDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.liveMeditation.RequestGroupsBetweenDateRange.prototype.getEnddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestGroupsBetweenDateRange} returns this
*/
proto.unifiedplatform.liveMeditation.RequestGroupsBetweenDateRange.prototype.setEnddate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.RequestGroupsBetweenDateRange} returns this
 */
proto.unifiedplatform.liveMeditation.RequestGroupsBetweenDateRange.prototype.clearEnddate = function() {
  return this.setEnddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.RequestGroupsBetweenDateRange.prototype.hasEnddate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional RequestPageable pageable = 3;
 * @return {?proto.unifiedplatform.liveMeditation.RequestPageable}
 */
proto.unifiedplatform.liveMeditation.RequestGroupsBetweenDateRange.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.liveMeditation.RequestPageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.liveMeditation.RequestPageable, 3));
};


/**
 * @param {?proto.unifiedplatform.liveMeditation.RequestPageable|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestGroupsBetweenDateRange} returns this
*/
proto.unifiedplatform.liveMeditation.RequestGroupsBetweenDateRange.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.RequestGroupsBetweenDateRange} returns this
 */
proto.unifiedplatform.liveMeditation.RequestGroupsBetweenDateRange.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.RequestGroupsBetweenDateRange.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.UpcomingGroupsRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.UpcomingGroupsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.UpcomingGroupsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.UpcomingGroupsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.UpcomingGroupsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.liveMeditation.RequestPageable.toObject(includeInstance, f),
    currenttime: (f = msg.getCurrenttime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    groupidList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    registeredlist: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.UpcomingGroupsRequest}
 */
proto.unifiedplatform.liveMeditation.UpcomingGroupsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.UpcomingGroupsRequest;
  return proto.unifiedplatform.liveMeditation.UpcomingGroupsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.UpcomingGroupsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.UpcomingGroupsRequest}
 */
proto.unifiedplatform.liveMeditation.UpcomingGroupsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.liveMeditation.RequestPageable;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.RequestPageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCurrenttime(value);
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addGroupid(values[i]);
      }
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRegisteredlist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.UpcomingGroupsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.UpcomingGroupsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.UpcomingGroupsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.UpcomingGroupsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.liveMeditation.RequestPageable.serializeBinaryToWriter
    );
  }
  f = message.getCurrenttime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getGroupidList();
  if (f.length > 0) {
    writer.writePackedInt64(
      4,
      f
    );
  }
  f = message.getRegisteredlist();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 1;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.liveMeditation.UpcomingGroupsRequest.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.liveMeditation.UpcomingGroupsRequest} returns this
 */
proto.unifiedplatform.liveMeditation.UpcomingGroupsRequest.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional RequestPageable pageable = 2;
 * @return {?proto.unifiedplatform.liveMeditation.RequestPageable}
 */
proto.unifiedplatform.liveMeditation.UpcomingGroupsRequest.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.liveMeditation.RequestPageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.liveMeditation.RequestPageable, 2));
};


/**
 * @param {?proto.unifiedplatform.liveMeditation.RequestPageable|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.UpcomingGroupsRequest} returns this
*/
proto.unifiedplatform.liveMeditation.UpcomingGroupsRequest.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.UpcomingGroupsRequest} returns this
 */
proto.unifiedplatform.liveMeditation.UpcomingGroupsRequest.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.UpcomingGroupsRequest.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp currentTime = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.liveMeditation.UpcomingGroupsRequest.prototype.getCurrenttime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.UpcomingGroupsRequest} returns this
*/
proto.unifiedplatform.liveMeditation.UpcomingGroupsRequest.prototype.setCurrenttime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.UpcomingGroupsRequest} returns this
 */
proto.unifiedplatform.liveMeditation.UpcomingGroupsRequest.prototype.clearCurrenttime = function() {
  return this.setCurrenttime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.UpcomingGroupsRequest.prototype.hasCurrenttime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated int64 groupId = 4;
 * @return {!Array<number>}
 */
proto.unifiedplatform.liveMeditation.UpcomingGroupsRequest.prototype.getGroupidList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.liveMeditation.UpcomingGroupsRequest} returns this
 */
proto.unifiedplatform.liveMeditation.UpcomingGroupsRequest.prototype.setGroupidList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.UpcomingGroupsRequest} returns this
 */
proto.unifiedplatform.liveMeditation.UpcomingGroupsRequest.prototype.addGroupid = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.UpcomingGroupsRequest} returns this
 */
proto.unifiedplatform.liveMeditation.UpcomingGroupsRequest.prototype.clearGroupidList = function() {
  return this.setGroupidList([]);
};


/**
 * optional bool registeredList = 5;
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.UpcomingGroupsRequest.prototype.getRegisteredlist = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.liveMeditation.UpcomingGroupsRequest} returns this
 */
proto.unifiedplatform.liveMeditation.UpcomingGroupsRequest.prototype.setRegisteredlist = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.GroupsUserCountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.GroupsUserCountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.GroupsUserCountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.GroupsUserCountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    increment: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    decrement: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.GroupsUserCountRequest}
 */
proto.unifiedplatform.liveMeditation.GroupsUserCountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.GroupsUserCountRequest;
  return proto.unifiedplatform.liveMeditation.GroupsUserCountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.GroupsUserCountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.GroupsUserCountRequest}
 */
proto.unifiedplatform.liveMeditation.GroupsUserCountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncrement(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDecrement(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.GroupsUserCountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.GroupsUserCountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.GroupsUserCountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.GroupsUserCountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getIncrement();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getDecrement();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.GroupsUserCountRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.GroupsUserCountRequest} returns this
 */
proto.unifiedplatform.liveMeditation.GroupsUserCountRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool increment = 2;
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.GroupsUserCountRequest.prototype.getIncrement = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.liveMeditation.GroupsUserCountRequest} returns this
 */
proto.unifiedplatform.liveMeditation.GroupsUserCountRequest.prototype.setIncrement = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool decrement = 3;
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.GroupsUserCountRequest.prototype.getDecrement = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.liveMeditation.GroupsUserCountRequest} returns this
 */
proto.unifiedplatform.liveMeditation.GroupsUserCountRequest.prototype.setDecrement = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.PreRecordedTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.PreRecordedTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.PreRecordedTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.PreRecordedTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 1, 0),
    prerecordedtype: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.liveMeditation.RequestPageable.toObject(includeInstance, f),
    searchelement: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedTypeRequest}
 */
proto.unifiedplatform.liveMeditation.PreRecordedTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.PreRecordedTypeRequest;
  return proto.unifiedplatform.liveMeditation.PreRecordedTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.PreRecordedTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedTypeRequest}
 */
proto.unifiedplatform.liveMeditation.PreRecordedTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    case 2:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.PreRecordedType} */ (reader.readEnum());
      msg.setPrerecordedtype(value);
      break;
    case 3:
      var value = new proto.unifiedplatform.liveMeditation.RequestPageable;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.RequestPageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchelement(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.PreRecordedTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.PreRecordedTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.PreRecordedTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.PreRecordedTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getPrerecordedtype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.unifiedplatform.liveMeditation.RequestPageable.serializeBinaryToWriter
    );
  }
  f = message.getSearchelement();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 1;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.liveMeditation.PreRecordedTypeRequest.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedTypeRequest} returns this
 */
proto.unifiedplatform.liveMeditation.PreRecordedTypeRequest.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.PreRecordedType preRecordedType = 2;
 * @return {!proto.unifiedplatform.heartintune.common.PreRecordedType}
 */
proto.unifiedplatform.liveMeditation.PreRecordedTypeRequest.prototype.getPrerecordedtype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.PreRecordedType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.PreRecordedType} value
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedTypeRequest} returns this
 */
proto.unifiedplatform.liveMeditation.PreRecordedTypeRequest.prototype.setPrerecordedtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional RequestPageable pageable = 3;
 * @return {?proto.unifiedplatform.liveMeditation.RequestPageable}
 */
proto.unifiedplatform.liveMeditation.PreRecordedTypeRequest.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.liveMeditation.RequestPageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.liveMeditation.RequestPageable, 3));
};


/**
 * @param {?proto.unifiedplatform.liveMeditation.RequestPageable|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedTypeRequest} returns this
*/
proto.unifiedplatform.liveMeditation.PreRecordedTypeRequest.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedTypeRequest} returns this
 */
proto.unifiedplatform.liveMeditation.PreRecordedTypeRequest.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.PreRecordedTypeRequest.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string searchElement = 4;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.PreRecordedTypeRequest.prototype.getSearchelement = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedTypeRequest} returns this
 */
proto.unifiedplatform.liveMeditation.PreRecordedTypeRequest.prototype.setSearchelement = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.ListOfPreRecordedLocaleResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.ListOfPreRecordedLocaleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.ListOfPreRecordedLocaleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.ListOfPreRecordedLocaleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ListOfPreRecordedLocaleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    prerecordedList: jspb.Message.toObjectList(msg.getPrerecordedList(),
    proto.unifiedplatform.liveMeditation.PreRecordedResponse.toObject, includeInstance),
    prerecordedlocaleList: jspb.Message.toObjectList(msg.getPrerecordedlocaleList(),
    proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse.toObject, includeInstance),
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.liveMeditation.ResponsePageable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.ListOfPreRecordedLocaleResponse}
 */
proto.unifiedplatform.liveMeditation.ListOfPreRecordedLocaleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.ListOfPreRecordedLocaleResponse;
  return proto.unifiedplatform.liveMeditation.ListOfPreRecordedLocaleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.ListOfPreRecordedLocaleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.ListOfPreRecordedLocaleResponse}
 */
proto.unifiedplatform.liveMeditation.ListOfPreRecordedLocaleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.liveMeditation.PreRecordedResponse;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.PreRecordedResponse.deserializeBinaryFromReader);
      msg.addPrerecorded(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse.deserializeBinaryFromReader);
      msg.addPrerecordedlocale(value);
      break;
    case 3:
      var value = new proto.unifiedplatform.liveMeditation.ResponsePageable;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.ResponsePageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.ListOfPreRecordedLocaleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.ListOfPreRecordedLocaleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.ListOfPreRecordedLocaleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ListOfPreRecordedLocaleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrerecordedList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.liveMeditation.PreRecordedResponse.serializeBinaryToWriter
    );
  }
  f = message.getPrerecordedlocaleList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse.serializeBinaryToWriter
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.unifiedplatform.liveMeditation.ResponsePageable.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PreRecordedResponse preRecorded = 1;
 * @return {!Array<!proto.unifiedplatform.liveMeditation.PreRecordedResponse>}
 */
proto.unifiedplatform.liveMeditation.ListOfPreRecordedLocaleResponse.prototype.getPrerecordedList = function() {
  return /** @type{!Array<!proto.unifiedplatform.liveMeditation.PreRecordedResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.liveMeditation.PreRecordedResponse, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.liveMeditation.PreRecordedResponse>} value
 * @return {!proto.unifiedplatform.liveMeditation.ListOfPreRecordedLocaleResponse} returns this
*/
proto.unifiedplatform.liveMeditation.ListOfPreRecordedLocaleResponse.prototype.setPrerecordedList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.liveMeditation.PreRecordedResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedResponse}
 */
proto.unifiedplatform.liveMeditation.ListOfPreRecordedLocaleResponse.prototype.addPrerecorded = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.liveMeditation.PreRecordedResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.ListOfPreRecordedLocaleResponse} returns this
 */
proto.unifiedplatform.liveMeditation.ListOfPreRecordedLocaleResponse.prototype.clearPrerecordedList = function() {
  return this.setPrerecordedList([]);
};


/**
 * repeated PreRecordedWithLocaleResponse preRecordedLocale = 2;
 * @return {!Array<!proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse>}
 */
proto.unifiedplatform.liveMeditation.ListOfPreRecordedLocaleResponse.prototype.getPrerecordedlocaleList = function() {
  return /** @type{!Array<!proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse, 2));
};


/**
 * @param {!Array<!proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse>} value
 * @return {!proto.unifiedplatform.liveMeditation.ListOfPreRecordedLocaleResponse} returns this
*/
proto.unifiedplatform.liveMeditation.ListOfPreRecordedLocaleResponse.prototype.setPrerecordedlocaleList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse}
 */
proto.unifiedplatform.liveMeditation.ListOfPreRecordedLocaleResponse.prototype.addPrerecordedlocale = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.unifiedplatform.liveMeditation.PreRecordedWithLocaleResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.ListOfPreRecordedLocaleResponse} returns this
 */
proto.unifiedplatform.liveMeditation.ListOfPreRecordedLocaleResponse.prototype.clearPrerecordedlocaleList = function() {
  return this.setPrerecordedlocaleList([]);
};


/**
 * optional ResponsePageable pageable = 3;
 * @return {?proto.unifiedplatform.liveMeditation.ResponsePageable}
 */
proto.unifiedplatform.liveMeditation.ListOfPreRecordedLocaleResponse.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.liveMeditation.ResponsePageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.liveMeditation.ResponsePageable, 3));
};


/**
 * @param {?proto.unifiedplatform.liveMeditation.ResponsePageable|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.ListOfPreRecordedLocaleResponse} returns this
*/
proto.unifiedplatform.liveMeditation.ListOfPreRecordedLocaleResponse.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.ListOfPreRecordedLocaleResponse} returns this
 */
proto.unifiedplatform.liveMeditation.ListOfPreRecordedLocaleResponse.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.ListOfPreRecordedLocaleResponse.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.PublishOrUnPublishGroupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.PublishOrUnPublishGroupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.PublishOrUnPublishGroupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.PublishOrUnPublishGroupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    ispublish: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.PublishOrUnPublishGroupRequest}
 */
proto.unifiedplatform.liveMeditation.PublishOrUnPublishGroupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.PublishOrUnPublishGroupRequest;
  return proto.unifiedplatform.liveMeditation.PublishOrUnPublishGroupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.PublishOrUnPublishGroupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.PublishOrUnPublishGroupRequest}
 */
proto.unifiedplatform.liveMeditation.PublishOrUnPublishGroupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGroupid(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIspublish(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.PublishOrUnPublishGroupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.PublishOrUnPublishGroupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.PublishOrUnPublishGroupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.PublishOrUnPublishGroupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getIspublish();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional int64 groupId = 1;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.PublishOrUnPublishGroupRequest.prototype.getGroupid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.PublishOrUnPublishGroupRequest} returns this
 */
proto.unifiedplatform.liveMeditation.PublishOrUnPublishGroupRequest.prototype.setGroupid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool isPublish = 2;
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.PublishOrUnPublishGroupRequest.prototype.getIspublish = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.liveMeditation.PublishOrUnPublishGroupRequest} returns this
 */
proto.unifiedplatform.liveMeditation.PublishOrUnPublishGroupRequest.prototype.setIspublish = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.GroupIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.GroupIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.GroupIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.GroupIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    startdate: (f = msg.getStartdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    enddate: (f = msg.getEnddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.liveMeditation.RequestPageable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.GroupIdRequest}
 */
proto.unifiedplatform.liveMeditation.GroupIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.GroupIdRequest;
  return proto.unifiedplatform.liveMeditation.GroupIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.GroupIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.GroupIdRequest}
 */
proto.unifiedplatform.liveMeditation.GroupIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGroupid(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartdate(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnddate(value);
      break;
    case 4:
      var value = new proto.unifiedplatform.liveMeditation.RequestPageable;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.RequestPageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.GroupIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.GroupIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.GroupIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.GroupIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getStartdate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnddate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.unifiedplatform.liveMeditation.RequestPageable.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 groupId = 1;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.GroupIdRequest.prototype.getGroupid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.GroupIdRequest} returns this
 */
proto.unifiedplatform.liveMeditation.GroupIdRequest.prototype.setGroupid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp startDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.liveMeditation.GroupIdRequest.prototype.getStartdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.GroupIdRequest} returns this
*/
proto.unifiedplatform.liveMeditation.GroupIdRequest.prototype.setStartdate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.GroupIdRequest} returns this
 */
proto.unifiedplatform.liveMeditation.GroupIdRequest.prototype.clearStartdate = function() {
  return this.setStartdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.GroupIdRequest.prototype.hasStartdate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp endDate = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.liveMeditation.GroupIdRequest.prototype.getEnddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.GroupIdRequest} returns this
*/
proto.unifiedplatform.liveMeditation.GroupIdRequest.prototype.setEnddate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.GroupIdRequest} returns this
 */
proto.unifiedplatform.liveMeditation.GroupIdRequest.prototype.clearEnddate = function() {
  return this.setEnddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.GroupIdRequest.prototype.hasEnddate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional RequestPageable pageable = 4;
 * @return {?proto.unifiedplatform.liveMeditation.RequestPageable}
 */
proto.unifiedplatform.liveMeditation.GroupIdRequest.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.liveMeditation.RequestPageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.liveMeditation.RequestPageable, 4));
};


/**
 * @param {?proto.unifiedplatform.liveMeditation.RequestPageable|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.GroupIdRequest} returns this
*/
proto.unifiedplatform.liveMeditation.GroupIdRequest.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.GroupIdRequest} returns this
 */
proto.unifiedplatform.liveMeditation.GroupIdRequest.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.GroupIdRequest.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.GroupOccurrencesDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.GroupOccurrencesDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.GroupOccurrencesDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.GroupOccurrencesDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    scheduledtime: (f = msg.getScheduledtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    userjoincount: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.GroupOccurrencesDetails}
 */
proto.unifiedplatform.liveMeditation.GroupOccurrencesDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.GroupOccurrencesDetails;
  return proto.unifiedplatform.liveMeditation.GroupOccurrencesDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.GroupOccurrencesDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.GroupOccurrencesDetails}
 */
proto.unifiedplatform.liveMeditation.GroupOccurrencesDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setScheduledtime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserjoincount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.GroupOccurrencesDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.GroupOccurrencesDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.GroupOccurrencesDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.GroupOccurrencesDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getScheduledtime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUserjoincount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.GroupOccurrencesDetails.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.GroupOccurrencesDetails} returns this
 */
proto.unifiedplatform.liveMeditation.GroupOccurrencesDetails.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp scheduledTime = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.liveMeditation.GroupOccurrencesDetails.prototype.getScheduledtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.GroupOccurrencesDetails} returns this
*/
proto.unifiedplatform.liveMeditation.GroupOccurrencesDetails.prototype.setScheduledtime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.GroupOccurrencesDetails} returns this
 */
proto.unifiedplatform.liveMeditation.GroupOccurrencesDetails.prototype.clearScheduledtime = function() {
  return this.setScheduledtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.GroupOccurrencesDetails.prototype.hasScheduledtime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 userJoinCount = 3;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.GroupOccurrencesDetails.prototype.getUserjoincount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.GroupOccurrencesDetails} returns this
 */
proto.unifiedplatform.liveMeditation.GroupOccurrencesDetails.prototype.setUserjoincount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.ListOfGroupOccurrences.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.ListOfGroupOccurrences.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.ListOfGroupOccurrences.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.ListOfGroupOccurrences} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ListOfGroupOccurrences.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupoccurrencesList: jspb.Message.toObjectList(msg.getGroupoccurrencesList(),
    proto.unifiedplatform.liveMeditation.GroupOccurrencesDetails.toObject, includeInstance),
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.liveMeditation.ResponsePageable.toObject(includeInstance, f),
    recurrenceenddate: (f = msg.getRecurrenceenddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.ListOfGroupOccurrences}
 */
proto.unifiedplatform.liveMeditation.ListOfGroupOccurrences.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.ListOfGroupOccurrences;
  return proto.unifiedplatform.liveMeditation.ListOfGroupOccurrences.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.ListOfGroupOccurrences} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.ListOfGroupOccurrences}
 */
proto.unifiedplatform.liveMeditation.ListOfGroupOccurrences.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.liveMeditation.GroupOccurrencesDetails;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.GroupOccurrencesDetails.deserializeBinaryFromReader);
      msg.addGroupoccurrences(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.liveMeditation.ResponsePageable;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.ResponsePageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRecurrenceenddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.ListOfGroupOccurrences.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.ListOfGroupOccurrences.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.ListOfGroupOccurrences} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.ListOfGroupOccurrences.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupoccurrencesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.liveMeditation.GroupOccurrencesDetails.serializeBinaryToWriter
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.liveMeditation.ResponsePageable.serializeBinaryToWriter
    );
  }
  f = message.getRecurrenceenddate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GroupOccurrencesDetails GroupOccurrences = 1;
 * @return {!Array<!proto.unifiedplatform.liveMeditation.GroupOccurrencesDetails>}
 */
proto.unifiedplatform.liveMeditation.ListOfGroupOccurrences.prototype.getGroupoccurrencesList = function() {
  return /** @type{!Array<!proto.unifiedplatform.liveMeditation.GroupOccurrencesDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.liveMeditation.GroupOccurrencesDetails, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.liveMeditation.GroupOccurrencesDetails>} value
 * @return {!proto.unifiedplatform.liveMeditation.ListOfGroupOccurrences} returns this
*/
proto.unifiedplatform.liveMeditation.ListOfGroupOccurrences.prototype.setGroupoccurrencesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.liveMeditation.GroupOccurrencesDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.GroupOccurrencesDetails}
 */
proto.unifiedplatform.liveMeditation.ListOfGroupOccurrences.prototype.addGroupoccurrences = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.liveMeditation.GroupOccurrencesDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.ListOfGroupOccurrences} returns this
 */
proto.unifiedplatform.liveMeditation.ListOfGroupOccurrences.prototype.clearGroupoccurrencesList = function() {
  return this.setGroupoccurrencesList([]);
};


/**
 * optional ResponsePageable pageable = 2;
 * @return {?proto.unifiedplatform.liveMeditation.ResponsePageable}
 */
proto.unifiedplatform.liveMeditation.ListOfGroupOccurrences.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.liveMeditation.ResponsePageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.liveMeditation.ResponsePageable, 2));
};


/**
 * @param {?proto.unifiedplatform.liveMeditation.ResponsePageable|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.ListOfGroupOccurrences} returns this
*/
proto.unifiedplatform.liveMeditation.ListOfGroupOccurrences.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.ListOfGroupOccurrences} returns this
 */
proto.unifiedplatform.liveMeditation.ListOfGroupOccurrences.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.ListOfGroupOccurrences.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp recurrenceEndDate = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.liveMeditation.ListOfGroupOccurrences.prototype.getRecurrenceenddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.ListOfGroupOccurrences} returns this
*/
proto.unifiedplatform.liveMeditation.ListOfGroupOccurrences.prototype.setRecurrenceenddate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.ListOfGroupOccurrences} returns this
 */
proto.unifiedplatform.liveMeditation.ListOfGroupOccurrences.prototype.clearRecurrenceenddate = function() {
  return this.setRecurrenceenddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.ListOfGroupOccurrences.prototype.hasRecurrenceenddate = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.Tag.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.Tag.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.Tag} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.Tag.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.Tag}
 */
proto.unifiedplatform.liveMeditation.Tag.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.Tag;
  return proto.unifiedplatform.liveMeditation.Tag.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.Tag} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.Tag}
 */
proto.unifiedplatform.liveMeditation.Tag.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.Tag.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.Tag.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.Tag} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.Tag.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.Tag.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.Tag} returns this
 */
proto.unifiedplatform.liveMeditation.Tag.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.Tag.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.Tag} returns this
 */
proto.unifiedplatform.liveMeditation.Tag.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.TagWithSession.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.TagWithSession.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.TagWithSession.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.TagWithSession} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.TagWithSession.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    contentsList: jspb.Message.toObjectList(msg.getContentsList(),
    proto.unifiedplatform.liveMeditation.SessionInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.TagWithSession}
 */
proto.unifiedplatform.liveMeditation.TagWithSession.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.TagWithSession;
  return proto.unifiedplatform.liveMeditation.TagWithSession.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.TagWithSession} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.TagWithSession}
 */
proto.unifiedplatform.liveMeditation.TagWithSession.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.unifiedplatform.liveMeditation.SessionInfo;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.SessionInfo.deserializeBinaryFromReader);
      msg.addContents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.TagWithSession.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.TagWithSession.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.TagWithSession} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.TagWithSession.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getContentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.unifiedplatform.liveMeditation.SessionInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.TagWithSession.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.TagWithSession} returns this
 */
proto.unifiedplatform.liveMeditation.TagWithSession.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.TagWithSession.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.TagWithSession} returns this
 */
proto.unifiedplatform.liveMeditation.TagWithSession.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated SessionInfo contents = 3;
 * @return {!Array<!proto.unifiedplatform.liveMeditation.SessionInfo>}
 */
proto.unifiedplatform.liveMeditation.TagWithSession.prototype.getContentsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.liveMeditation.SessionInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.liveMeditation.SessionInfo, 3));
};


/**
 * @param {!Array<!proto.unifiedplatform.liveMeditation.SessionInfo>} value
 * @return {!proto.unifiedplatform.liveMeditation.TagWithSession} returns this
*/
proto.unifiedplatform.liveMeditation.TagWithSession.prototype.setContentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.unifiedplatform.liveMeditation.SessionInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.SessionInfo}
 */
proto.unifiedplatform.liveMeditation.TagWithSession.prototype.addContents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.unifiedplatform.liveMeditation.SessionInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.TagWithSession} returns this
 */
proto.unifiedplatform.liveMeditation.TagWithSession.prototype.clearContentsList = function() {
  return this.setContentsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.TagWithPrerecorded.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.TagWithPrerecorded.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.TagWithPrerecorded.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.TagWithPrerecorded} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.TagWithPrerecorded.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    contentsList: jspb.Message.toObjectList(msg.getContentsList(),
    proto.unifiedplatform.liveMeditation.PreRecordedResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.TagWithPrerecorded}
 */
proto.unifiedplatform.liveMeditation.TagWithPrerecorded.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.TagWithPrerecorded;
  return proto.unifiedplatform.liveMeditation.TagWithPrerecorded.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.TagWithPrerecorded} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.TagWithPrerecorded}
 */
proto.unifiedplatform.liveMeditation.TagWithPrerecorded.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.unifiedplatform.liveMeditation.PreRecordedResponse;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.PreRecordedResponse.deserializeBinaryFromReader);
      msg.addContents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.TagWithPrerecorded.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.TagWithPrerecorded.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.TagWithPrerecorded} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.TagWithPrerecorded.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getContentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.unifiedplatform.liveMeditation.PreRecordedResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.TagWithPrerecorded.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.TagWithPrerecorded} returns this
 */
proto.unifiedplatform.liveMeditation.TagWithPrerecorded.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.unifiedplatform.liveMeditation.TagWithPrerecorded.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.liveMeditation.TagWithPrerecorded} returns this
 */
proto.unifiedplatform.liveMeditation.TagWithPrerecorded.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated PreRecordedResponse contents = 3;
 * @return {!Array<!proto.unifiedplatform.liveMeditation.PreRecordedResponse>}
 */
proto.unifiedplatform.liveMeditation.TagWithPrerecorded.prototype.getContentsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.liveMeditation.PreRecordedResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.liveMeditation.PreRecordedResponse, 3));
};


/**
 * @param {!Array<!proto.unifiedplatform.liveMeditation.PreRecordedResponse>} value
 * @return {!proto.unifiedplatform.liveMeditation.TagWithPrerecorded} returns this
*/
proto.unifiedplatform.liveMeditation.TagWithPrerecorded.prototype.setContentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.unifiedplatform.liveMeditation.PreRecordedResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.PreRecordedResponse}
 */
proto.unifiedplatform.liveMeditation.TagWithPrerecorded.prototype.addContents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.unifiedplatform.liveMeditation.PreRecordedResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.TagWithPrerecorded} returns this
 */
proto.unifiedplatform.liveMeditation.TagWithPrerecorded.prototype.clearContentsList = function() {
  return this.setContentsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.TagsList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.TagsList.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.TagsList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.TagsList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.TagsList.toObject = function(includeInstance, msg) {
  var f, obj = {
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    proto.unifiedplatform.liveMeditation.Tag.toObject, includeInstance),
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.liveMeditation.ResponsePageable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.TagsList}
 */
proto.unifiedplatform.liveMeditation.TagsList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.TagsList;
  return proto.unifiedplatform.liveMeditation.TagsList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.TagsList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.TagsList}
 */
proto.unifiedplatform.liveMeditation.TagsList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.liveMeditation.Tag;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.Tag.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.liveMeditation.ResponsePageable;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.ResponsePageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.TagsList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.TagsList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.TagsList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.TagsList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.liveMeditation.Tag.serializeBinaryToWriter
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.liveMeditation.ResponsePageable.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Tag tags = 1;
 * @return {!Array<!proto.unifiedplatform.liveMeditation.Tag>}
 */
proto.unifiedplatform.liveMeditation.TagsList.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.liveMeditation.Tag>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.liveMeditation.Tag, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.liveMeditation.Tag>} value
 * @return {!proto.unifiedplatform.liveMeditation.TagsList} returns this
*/
proto.unifiedplatform.liveMeditation.TagsList.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.liveMeditation.Tag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.Tag}
 */
proto.unifiedplatform.liveMeditation.TagsList.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.liveMeditation.Tag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.TagsList} returns this
 */
proto.unifiedplatform.liveMeditation.TagsList.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * optional ResponsePageable pageable = 2;
 * @return {?proto.unifiedplatform.liveMeditation.ResponsePageable}
 */
proto.unifiedplatform.liveMeditation.TagsList.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.liveMeditation.ResponsePageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.liveMeditation.ResponsePageable, 2));
};


/**
 * @param {?proto.unifiedplatform.liveMeditation.ResponsePageable|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.TagsList} returns this
*/
proto.unifiedplatform.liveMeditation.TagsList.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.TagsList} returns this
 */
proto.unifiedplatform.liveMeditation.TagsList.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.TagsList.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.RequestUserWeeklyStats.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.RequestUserWeeklyStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.RequestUserWeeklyStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestUserWeeklyStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    currentdate: (f = msg.getCurrentdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    noofdays: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.RequestUserWeeklyStats}
 */
proto.unifiedplatform.liveMeditation.RequestUserWeeklyStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.RequestUserWeeklyStats;
  return proto.unifiedplatform.liveMeditation.RequestUserWeeklyStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.RequestUserWeeklyStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.RequestUserWeeklyStats}
 */
proto.unifiedplatform.liveMeditation.RequestUserWeeklyStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCurrentdate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNoofdays(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.RequestUserWeeklyStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.RequestUserWeeklyStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.RequestUserWeeklyStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.RequestUserWeeklyStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCurrentdate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getNoofdays();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int64 UserId = 1;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.RequestUserWeeklyStats.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestUserWeeklyStats} returns this
 */
proto.unifiedplatform.liveMeditation.RequestUserWeeklyStats.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp currentDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.liveMeditation.RequestUserWeeklyStats.prototype.getCurrentdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestUserWeeklyStats} returns this
*/
proto.unifiedplatform.liveMeditation.RequestUserWeeklyStats.prototype.setCurrentdate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.liveMeditation.RequestUserWeeklyStats} returns this
 */
proto.unifiedplatform.liveMeditation.RequestUserWeeklyStats.prototype.clearCurrentdate = function() {
  return this.setCurrentdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.liveMeditation.RequestUserWeeklyStats.prototype.hasCurrentdate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 noOfdays = 3;
 * @return {number}
 */
proto.unifiedplatform.liveMeditation.RequestUserWeeklyStats.prototype.getNoofdays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.liveMeditation.RequestUserWeeklyStats} returns this
 */
proto.unifiedplatform.liveMeditation.RequestUserWeeklyStats.prototype.setNoofdays = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.liveMeditation.Tags.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.liveMeditation.Tags.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.liveMeditation.Tags.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.liveMeditation.Tags} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.Tags.toObject = function(includeInstance, msg) {
  var f, obj = {
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    proto.unifiedplatform.liveMeditation.Tag.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.liveMeditation.Tags}
 */
proto.unifiedplatform.liveMeditation.Tags.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.liveMeditation.Tags;
  return proto.unifiedplatform.liveMeditation.Tags.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.liveMeditation.Tags} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.liveMeditation.Tags}
 */
proto.unifiedplatform.liveMeditation.Tags.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.liveMeditation.Tag;
      reader.readMessage(value,proto.unifiedplatform.liveMeditation.Tag.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.liveMeditation.Tags.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.liveMeditation.Tags.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.liveMeditation.Tags} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.liveMeditation.Tags.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.liveMeditation.Tag.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Tag tags = 1;
 * @return {!Array<!proto.unifiedplatform.liveMeditation.Tag>}
 */
proto.unifiedplatform.liveMeditation.Tags.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.liveMeditation.Tag>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.liveMeditation.Tag, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.liveMeditation.Tag>} value
 * @return {!proto.unifiedplatform.liveMeditation.Tags} returns this
*/
proto.unifiedplatform.liveMeditation.Tags.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.liveMeditation.Tag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.liveMeditation.Tag}
 */
proto.unifiedplatform.liveMeditation.Tags.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.liveMeditation.Tag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.liveMeditation.Tags} returns this
 */
proto.unifiedplatform.liveMeditation.Tags.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * @enum {number}
 */
proto.unifiedplatform.liveMeditation.LiveSessionState = {
  INIT: 0,
  READY: 1,
  START: 2,
  STOP: 3,
  CONTINUE: 4,
  END: 5,
  NO_STATE_FOUND: 6,
  PRE_RECORDED_VIDEO_PLAYING: 7
};

/**
 * @enum {number}
 */
proto.unifiedplatform.liveMeditation.UserReportType = {
  FEEDBACK: 0,
  ISSUE: 1
};

goog.object.extend(exports, proto.unifiedplatform.liveMeditation);
