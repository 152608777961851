import React from "react";
import { ReactElement } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, RouteProps, useRouteMatch } from "react-router";
import { RootState } from "redux/store/store";
import { useAuth } from "services/AuthService";

interface Props extends RouteProps {
  component: any;
  restricted:boolean
}

export default function PublicRoute({
  component: Component,
  restricted,
  ...rest
}: Props): ReactElement {
  const { currentUser } = useAuth();
  const { path } = useRouteMatch();
  const state = useSelector((state: RootState) => state.user);
  return (
    <Route
      {...rest}
      render={(props) =>
        currentUser && restricted ? <Redirect to ={state.userRole===3?`${path}/guided` :`${path}/live`} /> :  <Component {...props} />
      }
    ></Route>
  );
}
