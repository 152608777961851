// source: donation.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.donation.BoolValue', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.donation.DonateResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.donation.Donation', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.donation.DonationIdWrapper', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.donation.DonationItem', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.donation.DonationReceipt', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.donation.DonationRegion', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.donation.DonationStatus', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.donation.EditDonationUserDetailRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.donation.EmailDonationReportRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.donation.IdentityType', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.donation.UserProfile', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.donation.DonationItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.donation.DonationItem.displayName = 'proto.unifiedplatform.heartIntuneProfile.donation.DonationItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem.displayName = 'proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.donation.UserProfile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.donation.UserProfile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.displayName = 'proto.unifiedplatform.heartIntuneProfile.donation.UserProfile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationIdWrapper = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.donation.DonationIdWrapper, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.donation.DonationIdWrapper.displayName = 'proto.unifiedplatform.heartIntuneProfile.donation.DonationIdWrapper';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.donation.EditDonationUserDetailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.donation.EditDonationUserDetailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.donation.EditDonationUserDetailRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.donation.EditDonationUserDetailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.donation.Donation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.donation.Donation.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.donation.Donation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.donation.Donation.displayName = 'proto.unifiedplatform.heartIntuneProfile.donation.Donation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment.displayName = 'proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReceipt = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.donation.DonationReceipt, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.donation.DonationReceipt.displayName = 'proto.unifiedplatform.heartIntuneProfile.donation.DonationReceipt';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsResponse.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsResponse.displayName = 'proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.donation.DonateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.donation.DonateResponse.displayName = 'proto.unifiedplatform.heartIntuneProfile.donation.DonateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsResponse.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsResponse.displayName = 'proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse.displayName = 'proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.donation.EmailDonationReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.donation.EmailDonationReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.donation.EmailDonationReportRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.donation.EmailDonationReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.donation.BoolValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.donation.BoolValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.donation.BoolValue.displayName = 'proto.unifiedplatform.heartIntuneProfile.donation.BoolValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.displayName = 'proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity.displayName = 'proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationItem.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.donation.DonationItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.DonationItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    region: jspb.Message.getFieldWithDefault(msg, 4, ""),
    currency: jspb.Message.getFieldWithDefault(msg, 5, ""),
    billingaccountname: jspb.Message.getFieldWithDefault(msg, 6, ""),
    isactive: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationItem}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.donation.DonationItem;
  return proto.unifiedplatform.heartIntuneProfile.donation.DonationItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.DonationItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationItem}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegion(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBillingaccountname(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsactive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.donation.DonationItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.DonationItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRegion();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBillingaccountname();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getIsactive();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationItem.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationItem} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationItem.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationItem} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationItem.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationItem} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationItem.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string region = 4;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationItem.prototype.getRegion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationItem} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationItem.prototype.setRegion = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string currency = 5;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationItem.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationItem} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationItem.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string billingAccountName = 6;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationItem.prototype.getBillingaccountname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationItem} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationItem.prototype.setBillingaccountname = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool isActive = 7;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationItem.prototype.getIsactive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationItem} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationItem.prototype.setIsactive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    donationid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    donationitem: (f = msg.getDonationitem()) && proto.unifiedplatform.heartIntuneProfile.donation.DonationItem.toObject(includeInstance, f),
    currency: jspb.Message.getFieldWithDefault(msg, 4, ""),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    isrecurring: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    recurringstartdate: jspb.Message.getFieldWithDefault(msg, 7, ""),
    recurringfrequency: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem;
  return proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDonationid(value);
      break;
    case 3:
      var value = new proto.unifiedplatform.heartIntuneProfile.donation.DonationItem;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.donation.DonationItem.deserializeBinaryFromReader);
      msg.setDonationitem(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsrecurring(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecurringstartdate(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecurringfrequency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDonationid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getDonationitem();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.unifiedplatform.heartIntuneProfile.donation.DonationItem.serializeBinaryToWriter
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getIsrecurring();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getRecurringstartdate();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getRecurringfrequency();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 donationId = 2;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem.prototype.getDonationid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem.prototype.setDonationid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional DonationItem donationItem = 3;
 * @return {?proto.unifiedplatform.heartIntuneProfile.donation.DonationItem}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem.prototype.getDonationitem = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.donation.DonationItem} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.donation.DonationItem, 3));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.donation.DonationItem|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem} returns this
*/
proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem.prototype.setDonationitem = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem.prototype.clearDonationitem = function() {
  return this.setDonationitem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem.prototype.hasDonationitem = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string currency = 4;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional double amount = 5;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional bool isRecurring = 6;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem.prototype.getIsrecurring = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem.prototype.setIsrecurring = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string recurringStartDate = 7;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem.prototype.getRecurringstartdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem.prototype.setRecurringstartdate = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string recurringFrequency = 8;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem.prototype.getRecurringfrequency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem.prototype.setRecurringfrequency = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    donationitem: (f = msg.getDonationitem()) && proto.unifiedplatform.heartIntuneProfile.donation.DonationItem.toObject(includeInstance, f),
    currency: jspb.Message.getFieldWithDefault(msg, 2, ""),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    isrecurring: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    recurringstartdate: jspb.Message.getFieldWithDefault(msg, 5, ""),
    recurringfrequency: jspb.Message.getFieldWithDefault(msg, 6, ""),
    extrasMap: (f = msg.getExtrasMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest;
  return proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.heartIntuneProfile.donation.DonationItem;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.donation.DonationItem.deserializeBinaryFromReader);
      msg.setDonationitem(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsrecurring(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecurringstartdate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecurringfrequency(value);
      break;
    case 9:
      var value = msg.getExtrasMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDonationitem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.unifiedplatform.heartIntuneProfile.donation.DonationItem.serializeBinaryToWriter
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getIsrecurring();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getRecurringstartdate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRecurringfrequency();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getExtrasMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(9, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional DonationItem donationItem = 1;
 * @return {?proto.unifiedplatform.heartIntuneProfile.donation.DonationItem}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest.prototype.getDonationitem = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.donation.DonationItem} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.donation.DonationItem, 1));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.donation.DonationItem|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest} returns this
*/
proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest.prototype.setDonationitem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest.prototype.clearDonationitem = function() {
  return this.setDonationitem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest.prototype.hasDonationitem = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string currency = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double amount = 3;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional bool isRecurring = 4;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest.prototype.getIsrecurring = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest.prototype.setIsrecurring = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string recurringStartDate = 5;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest.prototype.getRecurringstartdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest.prototype.setRecurringstartdate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string recurringFrequency = 6;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest.prototype.getRecurringfrequency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest.prototype.setRecurringfrequency = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * map<string, string> extras = 9;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest.prototype.getExtrasMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 9, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest.prototype.clearExtrasMap = function() {
  this.getExtrasMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.UserProfile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    profileid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    firstname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 4, ""),
    emailaddress: jspb.Message.getFieldWithDefault(msg, 5, ""),
    phonenumber: jspb.Message.getFieldWithDefault(msg, 6, ""),
    addressline1: jspb.Message.getFieldWithDefault(msg, 7, ""),
    addressline2: jspb.Message.getFieldWithDefault(msg, 8, ""),
    city: jspb.Message.getFieldWithDefault(msg, 9, ""),
    state: jspb.Message.getFieldWithDefault(msg, 10, ""),
    postalcode: jspb.Message.getFieldWithDefault(msg, 11, ""),
    country: jspb.Message.getFieldWithDefault(msg, 12, ""),
    memberid: jspb.Message.getFieldWithDefault(msg, 13, ""),
    taxid: jspb.Message.getFieldWithDefault(msg, 14, ""),
    citizenshipcountry: jspb.Message.getFieldWithDefault(msg, 15, ""),
    passportnumber: jspb.Message.getFieldWithDefault(msg, 16, ""),
    firebaseid: jspb.Message.getFieldWithDefault(msg, 19, ""),
    donationidentity: (f = msg.getDonationidentity()) && proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity.toObject(includeInstance, f),
    mysrcmid: jspb.Message.getFieldWithDefault(msg, 21, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.UserProfile}
 */
proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.donation.UserProfile;
  return proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.UserProfile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.UserProfile}
 */
proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailaddress(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhonenumber(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressline1(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressline2(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalcode(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setMemberid(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxid(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setCitizenshipcountry(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassportnumber(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirebaseid(value);
      break;
    case 20:
      var value = new proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity.deserializeBinaryFromReader);
      msg.setDonationidentity(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMysrcmid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.UserProfile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getProfileid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEmailaddress();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPhonenumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAddressline1();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAddressline2();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getPostalcode();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getMemberid();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getTaxid();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getCitizenshipcountry();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getPassportnumber();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getFirebaseid();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getDonationidentity();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity.serializeBinaryToWriter
    );
  }
  f = message.getMysrcmid();
  if (f !== 0) {
    writer.writeInt64(
      21,
      f
    );
  }
};


/**
 * optional int64 userId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.UserProfile} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string profileId = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.prototype.getProfileid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.UserProfile} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.prototype.setProfileid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string firstName = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.UserProfile} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string lastName = 4;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.UserProfile} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string emailAddress = 5;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.prototype.getEmailaddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.UserProfile} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.prototype.setEmailaddress = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string phoneNumber = 6;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.prototype.getPhonenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.UserProfile} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.prototype.setPhonenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string addressLine1 = 7;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.prototype.getAddressline1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.UserProfile} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.prototype.setAddressline1 = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string addressLine2 = 8;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.prototype.getAddressline2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.UserProfile} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.prototype.setAddressline2 = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string city = 9;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.UserProfile} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string state = 10;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.UserProfile} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string postalCode = 11;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.prototype.getPostalcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.UserProfile} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.prototype.setPostalcode = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string country = 12;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.UserProfile} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string memberId = 13;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.prototype.getMemberid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.UserProfile} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.prototype.setMemberid = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string taxId = 14;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.prototype.getTaxid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.UserProfile} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.prototype.setTaxid = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string citizenshipCountry = 15;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.prototype.getCitizenshipcountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.UserProfile} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.prototype.setCitizenshipcountry = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string passportNumber = 16;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.prototype.getPassportnumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.UserProfile} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.prototype.setPassportnumber = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string firebaseId = 19;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.prototype.getFirebaseid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.UserProfile} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.prototype.setFirebaseid = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional DonationIdentity donationIdentity = 20;
 * @return {?proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity}
 */
proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.prototype.getDonationidentity = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity, 20));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.UserProfile} returns this
*/
proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.prototype.setDonationidentity = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.UserProfile} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.prototype.clearDonationidentity = function() {
  return this.setDonationidentity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.prototype.hasDonationidentity = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional int64 mysrcmId = 21;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.prototype.getMysrcmid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.UserProfile} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.prototype.setMysrcmid = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationIdWrapper.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.donation.DonationIdWrapper.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.DonationIdWrapper} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationIdWrapper.toObject = function(includeInstance, msg) {
  var f, obj = {
    donationid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationIdWrapper}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationIdWrapper.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.donation.DonationIdWrapper;
  return proto.unifiedplatform.heartIntuneProfile.donation.DonationIdWrapper.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.DonationIdWrapper} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationIdWrapper}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationIdWrapper.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDonationid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationIdWrapper.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.donation.DonationIdWrapper.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.DonationIdWrapper} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationIdWrapper.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDonationid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 donationId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationIdWrapper.prototype.getDonationid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationIdWrapper} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationIdWrapper.prototype.setDonationid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string userId = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationIdWrapper.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationIdWrapper} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationIdWrapper.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.donation.EditDonationUserDetailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.donation.EditDonationUserDetailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.EditDonationUserDetailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.donation.EditDonationUserDetailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    donationid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userprofile: (f = msg.getUserprofile()) && proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.toObject(includeInstance, f),
    userid: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.EditDonationUserDetailRequest}
 */
proto.unifiedplatform.heartIntuneProfile.donation.EditDonationUserDetailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.donation.EditDonationUserDetailRequest;
  return proto.unifiedplatform.heartIntuneProfile.donation.EditDonationUserDetailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.EditDonationUserDetailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.EditDonationUserDetailRequest}
 */
proto.unifiedplatform.heartIntuneProfile.donation.EditDonationUserDetailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDonationid(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.heartIntuneProfile.donation.UserProfile;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.deserializeBinaryFromReader);
      msg.setUserprofile(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.donation.EditDonationUserDetailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.donation.EditDonationUserDetailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.EditDonationUserDetailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.donation.EditDonationUserDetailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDonationid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUserprofile();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.serializeBinaryToWriter
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 donationId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.donation.EditDonationUserDetailRequest.prototype.getDonationid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.EditDonationUserDetailRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.EditDonationUserDetailRequest.prototype.setDonationid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional UserProfile userProfile = 2;
 * @return {?proto.unifiedplatform.heartIntuneProfile.donation.UserProfile}
 */
proto.unifiedplatform.heartIntuneProfile.donation.EditDonationUserDetailRequest.prototype.getUserprofile = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.donation.UserProfile} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.donation.UserProfile, 2));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.donation.UserProfile|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.EditDonationUserDetailRequest} returns this
*/
proto.unifiedplatform.heartIntuneProfile.donation.EditDonationUserDetailRequest.prototype.setUserprofile = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.EditDonationUserDetailRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.EditDonationUserDetailRequest.prototype.clearUserprofile = function() {
  return this.setUserprofile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.donation.EditDonationUserDetailRequest.prototype.hasUserprofile = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string userId = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.EditDonationUserDetailRequest.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.EditDonationUserDetailRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.EditDonationUserDetailRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.donation.Donation.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.donation.Donation.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.donation.Donation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.Donation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.donation.Donation.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userprofile: (f = msg.getUserprofile()) && proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 3, ""),
    donationdate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    currency: jspb.Message.getFieldWithDefault(msg, 5, ""),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    donationlineitemsList: jspb.Message.toObjectList(msg.getDonationlineitemsList(),
    proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem.toObject, includeInstance),
    payment: (f = msg.getPayment()) && proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment.toObject(includeInstance, f),
    donationreceipt: (f = msg.getDonationreceipt()) && proto.unifiedplatform.heartIntuneProfile.donation.DonationReceipt.toObject(includeInstance, f),
    donationid: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.Donation}
 */
proto.unifiedplatform.heartIntuneProfile.donation.Donation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.donation.Donation;
  return proto.unifiedplatform.heartIntuneProfile.donation.Donation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.Donation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.Donation}
 */
proto.unifiedplatform.heartIntuneProfile.donation.Donation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.heartIntuneProfile.donation.UserProfile;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.deserializeBinaryFromReader);
      msg.setUserprofile(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDonationdate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 7:
      var value = new proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem.deserializeBinaryFromReader);
      msg.addDonationlineitems(value);
      break;
    case 8:
      var value = new proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment.deserializeBinaryFromReader);
      msg.setPayment(value);
      break;
    case 9:
      var value = new proto.unifiedplatform.heartIntuneProfile.donation.DonationReceipt;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.donation.DonationReceipt.deserializeBinaryFromReader);
      msg.setDonationreceipt(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDonationid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.donation.Donation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.donation.Donation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.Donation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.donation.Donation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUserprofile();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDonationdate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getDonationlineitemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem.serializeBinaryToWriter
    );
  }
  f = message.getPayment();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment.serializeBinaryToWriter
    );
  }
  f = message.getDonationreceipt();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.unifiedplatform.heartIntuneProfile.donation.DonationReceipt.serializeBinaryToWriter
    );
  }
  f = message.getDonationid();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.donation.Donation.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.Donation} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.Donation.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional UserProfile userProfile = 2;
 * @return {?proto.unifiedplatform.heartIntuneProfile.donation.UserProfile}
 */
proto.unifiedplatform.heartIntuneProfile.donation.Donation.prototype.getUserprofile = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.donation.UserProfile} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.donation.UserProfile, 2));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.donation.UserProfile|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.Donation} returns this
*/
proto.unifiedplatform.heartIntuneProfile.donation.Donation.prototype.setUserprofile = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.Donation} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.Donation.prototype.clearUserprofile = function() {
  return this.setUserprofile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.donation.Donation.prototype.hasUserprofile = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string status = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.Donation.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.Donation} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.Donation.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string donationDate = 4;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.Donation.prototype.getDonationdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.Donation} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.Donation.prototype.setDonationdate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string currency = 5;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.Donation.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.Donation} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.Donation.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional double amount = 6;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.donation.Donation.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.Donation} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.Donation.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * repeated DonationLineItem donationLineItems = 7;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem>}
 */
proto.unifiedplatform.heartIntuneProfile.donation.Donation.prototype.getDonationlineitemsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem, 7));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.Donation} returns this
*/
proto.unifiedplatform.heartIntuneProfile.donation.Donation.prototype.setDonationlineitemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem}
 */
proto.unifiedplatform.heartIntuneProfile.donation.Donation.prototype.addDonationlineitems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.Donation} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.Donation.prototype.clearDonationlineitemsList = function() {
  return this.setDonationlineitemsList([]);
};


/**
 * optional DonationPayment payment = 8;
 * @return {?proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment}
 */
proto.unifiedplatform.heartIntuneProfile.donation.Donation.prototype.getPayment = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment, 8));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.Donation} returns this
*/
proto.unifiedplatform.heartIntuneProfile.donation.Donation.prototype.setPayment = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.Donation} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.Donation.prototype.clearPayment = function() {
  return this.setPayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.donation.Donation.prototype.hasPayment = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional DonationReceipt donationReceipt = 9;
 * @return {?proto.unifiedplatform.heartIntuneProfile.donation.DonationReceipt}
 */
proto.unifiedplatform.heartIntuneProfile.donation.Donation.prototype.getDonationreceipt = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.donation.DonationReceipt} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.donation.DonationReceipt, 9));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.donation.DonationReceipt|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.Donation} returns this
*/
proto.unifiedplatform.heartIntuneProfile.donation.Donation.prototype.setDonationreceipt = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.Donation} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.Donation.prototype.clearDonationreceipt = function() {
  return this.setDonationreceipt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.donation.Donation.prototype.hasDonationreceipt = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional int64 donationId = 10;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.donation.Donation.prototype.getDonationid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.Donation} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.Donation.prototype.setDonationid = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    donationid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    paymentstatus: jspb.Message.getFieldWithDefault(msg, 3, ""),
    currency: jspb.Message.getFieldWithDefault(msg, 4, ""),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    trackingid: jspb.Message.getFieldWithDefault(msg, 6, ""),
    transactionid: jspb.Message.getFieldWithDefault(msg, 7, ""),
    transactiondate: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment;
  return proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDonationid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentstatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackingid(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionid(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactiondate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDonationid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPaymentstatus();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getTrackingid();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTransactionid();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTransactiondate();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string donationId = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment.prototype.getDonationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment.prototype.setDonationid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string paymentStatus = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment.prototype.getPaymentstatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment.prototype.setPaymentstatus = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string currency = 4;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional double amount = 5;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional string trackingId = 6;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment.prototype.getTrackingid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment.prototype.setTrackingid = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string transactionId = 7;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment.prototype.getTransactionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment.prototype.setTransactionid = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string transactionDate = 8;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment.prototype.getTransactiondate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationPayment.prototype.setTransactiondate = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReceipt.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.donation.DonationReceipt.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.DonationReceipt} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReceipt.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    booknumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    receiptnumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
    refnumber: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationReceipt}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReceipt.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.donation.DonationReceipt;
  return proto.unifiedplatform.heartIntuneProfile.donation.DonationReceipt.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.DonationReceipt} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationReceipt}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReceipt.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBooknumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceiptnumber(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRefnumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReceipt.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.donation.DonationReceipt.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.DonationReceipt} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReceipt.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getBooknumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getReceiptnumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRefnumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReceipt.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationReceipt} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReceipt.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string bookNumber = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReceipt.prototype.getBooknumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationReceipt} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReceipt.prototype.setBooknumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string receiptNumber = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReceipt.prototype.getReceiptnumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationReceipt} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReceipt.prototype.setReceiptnumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string refNumber = 4;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReceipt.prototype.getRefnumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationReceipt} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReceipt.prototype.setRefnumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    citizenshipcountry: jspb.Message.getFieldWithDefault(msg, 2, ""),
    idtoken: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sortby: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsRequest}
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsRequest;
  return proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsRequest}
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCitizenshipcountry(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdtoken(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSortby(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCitizenshipcountry();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIdtoken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSortby();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string userId = 1;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsRequest.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string citizenshipCountry = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsRequest.prototype.getCitizenshipcountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsRequest.prototype.setCitizenshipcountry = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string idToken = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsRequest.prototype.getIdtoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsRequest.prototype.setIdtoken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string sortBy = 4;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsRequest.prototype.getSortby = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsRequest.prototype.setSortby = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    citizenshipcountry: jspb.Message.getFieldWithDefault(msg, 2, ""),
    donationitemsList: jspb.Message.toObjectList(msg.getDonationitemsList(),
    proto.unifiedplatform.heartIntuneProfile.donation.DonationItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsResponse}
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsResponse;
  return proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsResponse}
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCitizenshipcountry(value);
      break;
    case 3:
      var value = new proto.unifiedplatform.heartIntuneProfile.donation.DonationItem;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.donation.DonationItem.deserializeBinaryFromReader);
      msg.addDonationitems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCitizenshipcountry();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDonationitemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.unifiedplatform.heartIntuneProfile.donation.DonationItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string userId = 1;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsResponse.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsResponse.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string citizenshipCountry = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsResponse.prototype.getCitizenshipcountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsResponse.prototype.setCitizenshipcountry = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated DonationItem donationItems = 3;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.donation.DonationItem>}
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsResponse.prototype.getDonationitemsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.donation.DonationItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.donation.DonationItem, 3));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.donation.DonationItem>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsResponse} returns this
*/
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsResponse.prototype.setDonationitemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.DonationItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationItem}
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsResponse.prototype.addDonationitems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.unifiedplatform.heartIntuneProfile.donation.DonationItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationItemsResponse.prototype.clearDonationitemsList = function() {
  return this.setDonationitemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    donationlineitemsList: jspb.Message.toObjectList(msg.getDonationlineitemsList(),
    proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest.toObject, includeInstance),
    userprofile: (f = msg.getUserprofile()) && proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.toObject(includeInstance, f),
    clientid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    extrasjsonstring: jspb.Message.getFieldWithDefault(msg, 5, ""),
    clientsuccessredirecturl: jspb.Message.getFieldWithDefault(msg, 6, ""),
    clientfailureredirecturl: jspb.Message.getFieldWithDefault(msg, 7, ""),
    idtoken: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest;
  return proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest.deserializeBinaryFromReader);
      msg.addDonationlineitems(value);
      break;
    case 3:
      var value = new proto.unifiedplatform.heartIntuneProfile.donation.UserProfile;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.deserializeBinaryFromReader);
      msg.setUserprofile(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setExtrasjsonstring(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientsuccessredirecturl(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientfailureredirecturl(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdtoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDonationlineitemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest.serializeBinaryToWriter
    );
  }
  f = message.getUserprofile();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.unifiedplatform.heartIntuneProfile.donation.UserProfile.serializeBinaryToWriter
    );
  }
  f = message.getClientid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getExtrasjsonstring();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getClientsuccessredirecturl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getClientfailureredirecturl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIdtoken();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional int64 userId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated DonationLineItemRequest donationLineItems = 2;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest>}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest.prototype.getDonationlineitemsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest, 2));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest} returns this
*/
proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest.prototype.setDonationlineitemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest.prototype.addDonationlineitems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.unifiedplatform.heartIntuneProfile.donation.DonationLineItemRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest.prototype.clearDonationlineitemsList = function() {
  return this.setDonationlineitemsList([]);
};


/**
 * optional UserProfile userProfile = 3;
 * @return {?proto.unifiedplatform.heartIntuneProfile.donation.UserProfile}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest.prototype.getUserprofile = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.donation.UserProfile} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.donation.UserProfile, 3));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.donation.UserProfile|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest} returns this
*/
proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest.prototype.setUserprofile = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest.prototype.clearUserprofile = function() {
  return this.setUserprofile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest.prototype.hasUserprofile = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string clientId = 4;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest.prototype.getClientid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest.prototype.setClientid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string extrasJsonString = 5;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest.prototype.getExtrasjsonstring = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest.prototype.setExtrasjsonstring = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string clientSuccessRedirectUrl = 6;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest.prototype.getClientsuccessredirecturl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest.prototype.setClientsuccessredirecturl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string clientFailureRedirectUrl = 7;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest.prototype.getClientfailureredirecturl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest.prototype.setClientfailureredirecturl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string idToken = 8;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest.prototype.getIdtoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonateRequest.prototype.setIdtoken = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.donation.DonateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.DonateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    donation: (f = msg.getDonation()) && proto.unifiedplatform.heartIntuneProfile.donation.Donation.toObject(includeInstance, f),
    paymentgatewayurl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    paymentgatewayrequestmethod: jspb.Message.getFieldWithDefault(msg, 4, ""),
    paymentgatewayrequestparammap: jspb.Message.getFieldWithDefault(msg, 5, ""),
    donationtypeid: jspb.Message.getFieldWithDefault(msg, 6, 0),
    paymentfrequency: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonateResponse}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.donation.DonateResponse;
  return proto.unifiedplatform.heartIntuneProfile.donation.DonateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.DonateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonateResponse}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.heartIntuneProfile.donation.Donation;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.donation.Donation.deserializeBinaryFromReader);
      msg.setDonation(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentgatewayurl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentgatewayrequestmethod(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentgatewayrequestparammap(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDonationtypeid(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentfrequency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.donation.DonateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.DonateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDonation();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.heartIntuneProfile.donation.Donation.serializeBinaryToWriter
    );
  }
  f = message.getPaymentgatewayurl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPaymentgatewayrequestmethod();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPaymentgatewayrequestparammap();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDonationtypeid();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getPaymentfrequency();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional int64 userId = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonateResponse.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonateResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonateResponse.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Donation donation = 2;
 * @return {?proto.unifiedplatform.heartIntuneProfile.donation.Donation}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonateResponse.prototype.getDonation = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.donation.Donation} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.donation.Donation, 2));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.donation.Donation|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonateResponse} returns this
*/
proto.unifiedplatform.heartIntuneProfile.donation.DonateResponse.prototype.setDonation = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonateResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonateResponse.prototype.clearDonation = function() {
  return this.setDonation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonateResponse.prototype.hasDonation = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string paymentGatewayUrl = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonateResponse.prototype.getPaymentgatewayurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonateResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonateResponse.prototype.setPaymentgatewayurl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string paymentGatewayRequestMethod = 4;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonateResponse.prototype.getPaymentgatewayrequestmethod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonateResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonateResponse.prototype.setPaymentgatewayrequestmethod = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string paymentGatewayRequestParamMap = 5;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonateResponse.prototype.getPaymentgatewayrequestparammap = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonateResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonateResponse.prototype.setPaymentgatewayrequestparammap = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 donationTypeID = 6;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonateResponse.prototype.getDonationtypeid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonateResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonateResponse.prototype.setDonationtypeid = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string paymentFrequency = 7;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonateResponse.prototype.getPaymentfrequency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonateResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonateResponse.prototype.setPaymentfrequency = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    idtoken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sortby: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsRequest}
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsRequest;
  return proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsRequest}
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdtoken(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSortby(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIdtoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSortby();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string userId = 1;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsRequest.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string idToken = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsRequest.prototype.getIdtoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsRequest.prototype.setIdtoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string sortBy = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsRequest.prototype.getSortby = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsRequest.prototype.setSortby = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    donationsList: jspb.Message.toObjectList(msg.getDonationsList(),
    proto.unifiedplatform.heartIntuneProfile.donation.Donation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsResponse}
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsResponse;
  return proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsResponse}
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.heartIntuneProfile.donation.Donation;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.donation.Donation.deserializeBinaryFromReader);
      msg.addDonations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDonationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.unifiedplatform.heartIntuneProfile.donation.Donation.serializeBinaryToWriter
    );
  }
};


/**
 * optional string userId = 1;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsResponse.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsResponse.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Donation donations = 2;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.donation.Donation>}
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsResponse.prototype.getDonationsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.donation.Donation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.donation.Donation, 2));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.donation.Donation>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsResponse} returns this
*/
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsResponse.prototype.setDonationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.Donation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.Donation}
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsResponse.prototype.addDonations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.unifiedplatform.heartIntuneProfile.donation.Donation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.FetchDonationsResponse.prototype.clearDonationsList = function() {
  return this.setDonationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    idtoken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fromdate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    todate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    paymentstatus: jspb.Message.getFieldWithDefault(msg, 5, ""),
    emailaddress: jspb.Message.getFieldWithDefault(msg, 6, ""),
    firstname: jspb.Message.getFieldWithDefault(msg, 7, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 8, ""),
    sortby: jspb.Message.getFieldWithDefault(msg, 9, ""),
    pagesize: jspb.Message.getFieldWithDefault(msg, 10, 0),
    pagenum: jspb.Message.getFieldWithDefault(msg, 11, 0),
    totalrows: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest;
  return proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdtoken(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromdate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTodate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentstatus(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailaddress(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSortby(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPagesize(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPagenum(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalrows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIdtoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFromdate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTodate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPaymentstatus();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEmailaddress();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSortby();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPagesize();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getPagenum();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getTotalrows();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
};


/**
 * optional string userId = 1;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string idToken = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest.prototype.getIdtoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest.prototype.setIdtoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string fromDate = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest.prototype.getFromdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest.prototype.setFromdate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string toDate = 4;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest.prototype.getTodate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest.prototype.setTodate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string paymentStatus = 5;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest.prototype.getPaymentstatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest.prototype.setPaymentstatus = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string emailAddress = 6;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest.prototype.getEmailaddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest.prototype.setEmailaddress = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string firstName = 7;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string lastName = 8;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string sortBy = 9;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest.prototype.getSortby = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest.prototype.setSortby = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int32 pageSize = 10;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest.prototype.getPagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest.prototype.setPagesize = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 pageNum = 11;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest.prototype.getPagenum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest.prototype.setPagenum = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 totalRows = 12;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest.prototype.getTotalrows = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest.prototype.setTotalrows = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fromdate: jspb.Message.getFieldWithDefault(msg, 2, ""),
    todate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    paymentstatus: jspb.Message.getFieldWithDefault(msg, 4, ""),
    emailaddress: jspb.Message.getFieldWithDefault(msg, 5, ""),
    firstname: jspb.Message.getFieldWithDefault(msg, 6, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 7, ""),
    sortby: jspb.Message.getFieldWithDefault(msg, 8, ""),
    pagesize: jspb.Message.getFieldWithDefault(msg, 9, 0),
    pagenum: jspb.Message.getFieldWithDefault(msg, 10, 0),
    headerrowjson: jspb.Message.getFieldWithDefault(msg, 11, ""),
    datarowsjson: jspb.Message.getFieldWithDefault(msg, 12, ""),
    totalrows: jspb.Message.getFieldWithDefault(msg, 13, 0),
    numofpages: jspb.Message.getFieldWithDefault(msg, 14, 0),
    booknumber: jspb.Message.getFieldWithDefault(msg, 15, ""),
    receiptnumber: jspb.Message.getFieldWithDefault(msg, 16, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse;
  return proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromdate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTodate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentstatus(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailaddress(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSortby(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPagesize(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPagenum(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setHeaderrowjson(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatarowsjson(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalrows(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumofpages(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setBooknumber(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReceiptnumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFromdate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTodate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPaymentstatus();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEmailaddress();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSortby();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPagesize();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getPagenum();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getHeaderrowjson();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getDatarowsjson();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getTotalrows();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getNumofpages();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getBooknumber();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getReceiptnumber();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
};


/**
 * optional string userId = 1;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string fromDate = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse.prototype.getFromdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse.prototype.setFromdate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string toDate = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse.prototype.getTodate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse.prototype.setTodate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string paymentStatus = 4;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse.prototype.getPaymentstatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse.prototype.setPaymentstatus = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string emailAddress = 5;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse.prototype.getEmailaddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse.prototype.setEmailaddress = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string firstName = 6;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string lastName = 7;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string sortBy = 8;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse.prototype.getSortby = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse.prototype.setSortby = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int32 pageSize = 9;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse.prototype.getPagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse.prototype.setPagesize = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 pageNum = 10;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse.prototype.getPagenum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse.prototype.setPagenum = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string headerRowJson = 11;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse.prototype.getHeaderrowjson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse.prototype.setHeaderrowjson = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string dataRowsJson = 12;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse.prototype.getDatarowsjson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse.prototype.setDatarowsjson = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional int32 totalRows = 13;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse.prototype.getTotalrows = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse.prototype.setTotalrows = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int32 numOfPages = 14;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse.prototype.getNumofpages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse.prototype.setNumofpages = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional string bookNumber = 15;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse.prototype.getBooknumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse.prototype.setBooknumber = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional int64 receiptNumber = 16;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse.prototype.getReceiptnumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationReportResponse.prototype.setReceiptnumber = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.donation.EmailDonationReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.donation.EmailDonationReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.EmailDonationReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.donation.EmailDonationReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    donationreportrequest: (f = msg.getDonationreportrequest()) && proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest.toObject(includeInstance, f),
    email: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.EmailDonationReportRequest}
 */
proto.unifiedplatform.heartIntuneProfile.donation.EmailDonationReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.donation.EmailDonationReportRequest;
  return proto.unifiedplatform.heartIntuneProfile.donation.EmailDonationReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.EmailDonationReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.EmailDonationReportRequest}
 */
proto.unifiedplatform.heartIntuneProfile.donation.EmailDonationReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest.deserializeBinaryFromReader);
      msg.setDonationreportrequest(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.donation.EmailDonationReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.donation.EmailDonationReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.EmailDonationReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.donation.EmailDonationReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDonationreportrequest();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest.serializeBinaryToWriter
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string userId = 1;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.EmailDonationReportRequest.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.EmailDonationReportRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.EmailDonationReportRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional DonationReportRequest donationReportRequest = 2;
 * @return {?proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest}
 */
proto.unifiedplatform.heartIntuneProfile.donation.EmailDonationReportRequest.prototype.getDonationreportrequest = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest, 2));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.donation.DonationReportRequest|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.EmailDonationReportRequest} returns this
*/
proto.unifiedplatform.heartIntuneProfile.donation.EmailDonationReportRequest.prototype.setDonationreportrequest = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.EmailDonationReportRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.EmailDonationReportRequest.prototype.clearDonationreportrequest = function() {
  return this.setDonationreportrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.donation.EmailDonationReportRequest.prototype.hasDonationreportrequest = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.EmailDonationReportRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.EmailDonationReportRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.EmailDonationReportRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.donation.BoolValue.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.donation.BoolValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.BoolValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.donation.BoolValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.BoolValue}
 */
proto.unifiedplatform.heartIntuneProfile.donation.BoolValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.donation.BoolValue;
  return proto.unifiedplatform.heartIntuneProfile.donation.BoolValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.BoolValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.BoolValue}
 */
proto.unifiedplatform.heartIntuneProfile.donation.BoolValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.donation.BoolValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.donation.BoolValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.BoolValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.donation.BoolValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool value = 1;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.donation.BoolValue.prototype.getValue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.BoolValue} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.BoolValue.prototype.setValue = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.repeatedFields_ = [26];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    mobilenumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
    displayname: jspb.Message.getFieldWithDefault(msg, 4, ""),
    country: jspb.Message.getFieldWithDefault(msg, 5, ""),
    city: jspb.Message.getFieldWithDefault(msg, 6, ""),
    state: jspb.Message.getFieldWithDefault(msg, 7, ""),
    addressline: jspb.Message.getFieldWithDefault(msg, 8, ""),
    postalcode: jspb.Message.getFieldWithDefault(msg, 9, ""),
    citizenshipcountry: jspb.Message.getFieldWithDefault(msg, 10, ""),
    taxid: jspb.Message.getFieldWithDefault(msg, 11, ""),
    passportid: jspb.Message.getFieldWithDefault(msg, 12, ""),
    userid: jspb.Message.getFieldWithDefault(msg, 13, 0),
    donationtitle: jspb.Message.getFieldWithDefault(msg, 14, ""),
    donationtypeid: jspb.Message.getFieldWithDefault(msg, 15, 0),
    donationamount: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    paymentstatus: jspb.Message.getFieldWithDefault(msg, 17, ""),
    paymentfrequency: jspb.Message.getFieldWithDefault(msg, 18, ""),
    paymenturl: jspb.Message.getFieldWithDefault(msg, 19, ""),
    hasavailedtaxexemption: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    modifiedat: (f = msg.getModifiedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    mysrcmid: jspb.Message.getFieldWithDefault(msg, 25, 0),
    identitiesList: jspb.Message.toObjectList(msg.getIdentitiesList(),
    proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails;
  return proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobilenumber(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayname(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressline(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalcode(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCitizenshipcountry(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxid(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassportid(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setDonationtitle(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDonationtypeid(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDonationamount(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentstatus(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentfrequency(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymenturl(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasavailedtaxexemption(value);
      break;
    case 23:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    case 24:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setModifiedat(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMysrcmid(value);
      break;
    case 26:
      var value = new proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity.deserializeBinaryFromReader);
      msg.addIdentities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMobilenumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDisplayname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAddressline();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPostalcode();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCitizenshipcountry();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getTaxid();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPassportid();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getDonationtitle();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getDonationtypeid();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getDonationamount();
  if (f !== 0.0) {
    writer.writeDouble(
      16,
      f
    );
  }
  f = message.getPaymentstatus();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getPaymentfrequency();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getPaymenturl();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getHasavailedtaxexemption();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getModifiedat();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMysrcmid();
  if (f !== 0) {
    writer.writeInt32(
      25,
      f
    );
  }
  f = message.getIdentitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      26,
      f,
      proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string mobileNumber = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.getMobilenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.setMobilenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string displayName = 4;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.getDisplayname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.setDisplayname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string country = 5;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string city = 6;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string state = 7;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string addressLine = 8;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.getAddressline = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.setAddressline = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string postalCode = 9;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.getPostalcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.setPostalcode = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string citizenshipCountry = 10;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.getCitizenshipcountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.setCitizenshipcountry = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string taxId = 11;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.getTaxid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.setTaxid = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string passportId = 12;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.getPassportid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.setPassportid = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional int64 userId = 13;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional string donationTitle = 14;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.getDonationtitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.setDonationtitle = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional int64 donationTypeID = 15;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.getDonationtypeid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.setDonationtypeid = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional double donationAmount = 16;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.getDonationamount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.setDonationamount = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional string paymentStatus = 17;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.getPaymentstatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.setPaymentstatus = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string paymentFrequency = 18;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.getPaymentfrequency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.setPaymentfrequency = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string paymentUrl = 19;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.getPaymenturl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.setPaymenturl = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional bool hasAvailedTaxExemption = 20;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.getHasavailedtaxexemption = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.setHasavailedtaxexemption = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional google.protobuf.Timestamp createdAt = 23;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 23));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails} returns this
*/
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.setCreatedat = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.clearCreatedat = function() {
  return this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional google.protobuf.Timestamp modifiedAt = 24;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.getModifiedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 24));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails} returns this
*/
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.setModifiedat = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.clearModifiedat = function() {
  return this.setModifiedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.hasModifiedat = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional int32 mysrcmId = 25;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.getMysrcmid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.setMysrcmid = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * repeated DonationIdentity identities = 26;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity>}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.getIdentitiesList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity, 26));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails} returns this
*/
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.setIdentitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 26, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.addIdentities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 26, opt_value, proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationResponseUserDetails.prototype.clearIdentitiesList = function() {
  return this.setIdentitiesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    identitynumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    identitytype: jspb.Message.getFieldWithDefault(msg, 3, 0),
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    modifiedat: (f = msg.getModifiedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    mysrcmdonationprofileid: jspb.Message.getFieldWithDefault(msg, 6, 0),
    mysrcmidentitytype: jspb.Message.getFieldWithDefault(msg, 7, 0),
    mysrcmidentityname: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity;
  return proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentitynumber(value);
      break;
    case 3:
      var value = /** @type {!proto.unifiedplatform.heartIntuneProfile.donation.IdentityType} */ (reader.readEnum());
      msg.setIdentitytype(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setModifiedat(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMysrcmdonationprofileid(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMysrcmidentitytype(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setMysrcmidentityname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getIdentitynumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIdentitytype();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getModifiedat();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMysrcmdonationprofileid();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getMysrcmidentitytype();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getMysrcmidentityname();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string identityNumber = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity.prototype.getIdentitynumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity.prototype.setIdentitynumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional IdentityType identityType = 3;
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.IdentityType}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity.prototype.getIdentitytype = function() {
  return /** @type {!proto.unifiedplatform.heartIntuneProfile.donation.IdentityType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.donation.IdentityType} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity.prototype.setIdentitytype = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp createdAt = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity} returns this
*/
proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity.prototype.setCreatedat = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity.prototype.clearCreatedat = function() {
  return this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp modifiedAt = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity.prototype.getModifiedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity} returns this
*/
proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity.prototype.setModifiedat = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity.prototype.clearModifiedat = function() {
  return this.setModifiedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity.prototype.hasModifiedat = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int64 mySrcmDonationProfileId = 6;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity.prototype.getMysrcmdonationprofileid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity.prototype.setMysrcmdonationprofileid = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 mySrcmIdentityType = 7;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity.prototype.getMysrcmidentitytype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity.prototype.setMysrcmidentitytype = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string mySrcmIdentityName = 8;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity.prototype.getMysrcmidentityname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity} returns this
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationIdentity.prototype.setMysrcmidentityname = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * @enum {number}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationRegion = {
  INDIA: 0,
  OUTSIDE_INDIA: 1,
  NORTH_AMERICA: 2,
  EUROPE: 3
};

/**
 * @enum {number}
 */
proto.unifiedplatform.heartIntuneProfile.donation.DonationStatus = {
  CREATED: 0,
  PAID: 1,
  COMPLETED: 2
};

/**
 * @enum {number}
 */
proto.unifiedplatform.heartIntuneProfile.donation.IdentityType = {
  UNKNOWN_IDENTITY_TYPE: 0,
  PAN: 1,
  AADHAAR: 2,
  VOTER_ID: 3,
  DRIVING_LICENSE: 4,
  PASSPORT: 5,
  RATION_CARD: 6
};

goog.object.extend(exports, proto.unifiedplatform.heartIntuneProfile.donation);
