import {
  Grid,
  CardActionArea,
  CardContent,
  Typography,
  CardActions,
  Button,
  Card,
} from "@mui/material";
import { Box } from "@mui/system";
import DialogBox from "components/dialogBox/dialog";
import makeStyles from "@mui/styles/makeStyles";
import DeleteIcon from "@mui/icons-material/Delete";
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";
import InfoIcon from "@mui/icons-material/Info";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import {
  getAllBenifitsBySessionId,
  getAllLiveMeditationsBetweenDatesV2,
} from "redux/actions/liveMeditationAction";
import { grpc } from "@improbable-eng/grpc-web";
import { ProtobufMessage } from "@improbable-eng/grpc-web/dist/typings/message";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import { HeartIntuneProfileService } from "api/models/heartintune-profile-service/heartintune-profile_pb_service";
import { HEARTINTUNE_PROFILE_SERVICE_HOST } from "api/serviceEndpoints";
import { firebaseAuth } from "config/firebase";
import { useState } from "react";
import {
  liveSessionsBetweenLoading,
  liveSessionsBetweenError,
} from "redux/reducers/liveMeditationReducer";
import { ID } from "api/models/heartintune-profile-service/heartintune-profile_pb";

const useStyles = makeStyles((theme) => ({
  coachProfile: {
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
    },
  },
  cardInfo: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  cardLabels: {
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      justifyContent: "center",
    },
  },
}));
const SessionCard = ({
  item,
  dispatch,
  rowsPerPage,
  setsessionId,
  page,
  setCurrentState,
  setCurrentScreen,
  setSnackBar,
}: any) => {
  const languages = [
    "",
    "Default",
    "English",
    "Hindi",
    "Telugu",
    "Gujarati",
    "Tamil",
    "kannada",
    "Marathi",
    "Italian",
    "Russian",
    "Spanish",
    "French",
    "Portuguese",
    "German",
    "Bengali",
    "Malayalam",
    "Ukrainian",
    "Odia",
    "English US",
    "English AUS",
  ];

  const classes = useStyles();

  const [deleteSession, setDeleteSession] = useState({
    showDeleteModal: false,
  });

  const handleDeleteClose = () => {
    setDeleteSession({ showDeleteModal: false });
  };

  const _DeleteLiveSessionId = async (data: any) => {
    try {
      dispatch(liveSessionsBetweenLoading());
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: ID = new ID();
      reqBody.setId(item.sessioninfo?.id);
      setDeleteSession({ showDeleteModal: false });
      console.log("_DeleteLiveSessionId data", reqBody.toObject());
      grpc.unary(HeartIntuneProfileService.CancelLiveSessionById, {
        host: HEARTINTUNE_PROFILE_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<ProtobufMessage>) => {
          const { status, message } = res;
          console.log("Res", res);
          if (status === grpc.Code.OK && message) {
            if (message) {
              console.log(
                "data coming form ClearUserDataForDeletedLiveSessions",
                message.toObject()
              );
              setSnackBar({
                open: true,
                severity: "success",
                message: "Session canceled successfully",
              });
              dispatch(
                getAllLiveMeditationsBetweenDatesV2(rowsPerPage, page, true)
              );
            }
          } else {
            setSnackBar({
              open: true,
              severity: "error",
              message: "error in canceling Session",
            });
            setDeleteSession({ showDeleteModal: false });
          }
        },
      });
    } catch (err) {
      setSnackBar({
        open: true,
        severity: "error",
        message: "error in canceling Session",
      });
      console.log(err);
      dispatch(liveSessionsBetweenError());
    }
  };
  return (
    <>
      <Card
        sx={{
          borderRadius: "1rem",
          margin: "10px",
          padding: "5px",
        }}
      >
        <Grid container>
          <Grid item xs={12} md={6} lg={8}>
            <CardActionArea disabled>
              <Grid container sx={{ display: "flex", alignItems: "center" }}>
                <Grid item xs={12} sm={8}>
                  <CardContent
                    sx={{
                      paddingLeft: 0,
                    }}
                  >
                    {/* <Box
                      className={classes.cardInfo}
                      sx={{
                        justifyContent: "space-between",
                        marginLeft: "10px",
                      }}
                    >
                      <Typography component="div" variant="h6">
                        {item.sessioninfo?.sessiontitle}
                      </Typography>
                      <Typography component="div" variant="body1">
                        {item.sessioninfo?.aboutsession}
                      </Typography>
                    </Box> */}
                    <Box
                      className={classes.cardInfo}
                      sx={{
                        justifyContent: "space-between",
                        marginLeft: "10px",
                      }}
                    >
                      <Typography component="div" variant="h6">
                        {item.sessioninfo?.sessiontitle}
                      </Typography>
                      <Typography
                        component="div"
                        variant="body2"
                        sx={{
                          opacity: "0.7",
                        }}
                      >
                        {item.sessioninfo?.aboutsession}
                      </Typography>
                      {languages[item.sessioninfo?.selectedlanguage] && (
                        <Typography
                          component="div"
                          variant="body1"
                          sx={{
                            opacity: "0.7",
                          }}
                        >
                          Language -{" "}
                          {languages[item.sessioninfo?.selectedlanguage]}
                        </Typography>
                      )}
                    </Box>
                  </CardContent>
                </Grid>
              </Grid>
            </CardActionArea>
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            md={6}
            lg={4}
            display={"flex"}
            justifyContent={"center"}
          >
            <CardActions>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    fullWidth
                    startIcon={<RateReviewOutlinedIcon />}
                    onClick={async () => {
                      await dispatch(
                        getAllBenifitsBySessionId(Number(item.sessioninfo?.id))
                      );
                      setsessionId(item.sessioninfo?.id);
                      setCurrentState({
                        isEditing: true,
                        item: item,
                        type: "edit",
                      });
                      setTimeout(() => {
                        setCurrentScreen(1);
                      }, 500);
                    }}
                  >
                    <Typography variant="body2">Edit</Typography>
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    fullWidth
                    startIcon={<FileCopyIcon />}
                    onClick={() => {
                      setCurrentState({
                        isEditing: true,
                        item: item,
                        type: "copy",
                      });
                      setCurrentScreen(1);
                    }}
                  >
                    <Typography variant="body2">Copy</Typography>
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="medium"
                    fullWidth
                    startIcon={<DeleteIcon />}
                    onClick={() => {
                      setDeleteSession({
                        showDeleteModal: true,
                      });
                    }}
                  >
                    <Typography variant="body2">Delete</Typography>
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    fullWidth
                    startIcon={<InfoIcon />}
                    onClick={() => {
                      setCurrentState({
                        isEditing: true,
                        item: item,
                        type: "view",
                      });
                      setCurrentScreen(1);
                    }}
                  >
                    <Typography variant="body2">Info</Typography>
                  </Button>
                </Grid>
              </Grid>
            </CardActions>
          </Grid>
        </Grid>
      </Card>
      <DialogBox
        open={deleteSession.showDeleteModal || false}
        onClose={handleDeleteClose}
        onCancel={handleDeleteClose}
        onConfirm={_DeleteLiveSessionId}
        type={"cancel"}
        title={`Are you sure that you want to delete this session ?`}
      />
    </>
  );
};

export default SessionCard;
