import { createSlice } from "@reduxjs/toolkit";
import {
  ArchivesEventsDetails,
  ArchivesSubEventsDetails,
  ContentWithLanguageSupport,
  GetAllCardsRequest,
  ResponsePageable,
  TagsList,
} from "api/models/hfn-events-service/hfn-events_pb";

interface bhandaraDefaultState {
  events: {
    scheduledEventsList: {
      loading: boolean;
      eventsList: [];
    };
  };
  locations: {
    keyLocations: { loading: boolean; keyLocationsList: [] };
    keyLocationCategories: {
      loading: boolean;
      categoriesList: [];
    };
  };
  announcements: {
    announcementsData: { loading: boolean; announcementsList: [] };
  };
  essestials: {
    essentialsData: { loading: boolean; essentialsList: [] };
  };
  lostAndFound: {
    lostAndFoundData: {
      loading: boolean;
      lostAndFoundLData: {
        lostandfounddetailsList: [];
        pageable: {
          totalcount: string | number;
          selectedpagesize: number;
          selectedpagenumber: number;
        };
      };
    };
  };
  archive: {
    loading: boolean;
    archiveList: {
      archiveseventsdetailsList: ArchivesEventsDetails[];
      pageable: {
        totalcount: number;
        selectedpagesize: number;
        selectedpagenumber: number;
      };
    };
  };
  archiveSubEvent: {
    loading: boolean;
    eventList: {
      archivessubeventsdetailsList: ArchivesSubEventsDetails[];
      pageable: {
        totalcount: number;
        selectedpagesize: number;
        selectedpagenumber: number;
      };
    };
  };
  tagList: {
    loading: boolean;
    tags: TagsList.AsObject;
  };
  daajiMessageList: {
    loading: boolean;
    messages: {
      contentwithlanguagesupportList: ContentWithLanguageSupport[];
      responsepageable: ResponsePageable.AsObject;
    };
  };
  homeBannerList: {
    loading: boolean;
    messages: {
      listofcardsList: GetAllCardsRequest[];
      responsepageable: ResponsePageable.AsObject;
    };
  };
  yatraGardenList: {
    loading: boolean;
    contents: {
      contentwithlanguagesupportList: ContentWithLanguageSupport[];
      responsepageable: ResponsePageable.AsObject;
    };
  };
}

const initialState: bhandaraDefaultState = {
  events: {
    scheduledEventsList: { loading: true, eventsList: [] },
  },
  locations: {
    keyLocations: {
      loading: true,
      keyLocationsList: [],
    },
    keyLocationCategories: {
      loading: true,
      categoriesList: [],
    },
  },
  announcements: {
    announcementsData: { loading: true, announcementsList: [] },
  },
  essestials: { essentialsData: { loading: true, essentialsList: [] } },
  lostAndFound: {
    lostAndFoundData: {
      loading: true,
      lostAndFoundLData: {
        lostandfounddetailsList: [],
        pageable: {
          totalcount: 0,
          selectedpagesize: 10,
          selectedpagenumber: 0,
        },
      },
    },
  },
  archive: {
    loading: false,
    archiveList: {
      archiveseventsdetailsList: [],
      pageable: { totalcount: 3, selectedpagesize: 10, selectedpagenumber: 0 },
    },
  },
  archiveSubEvent: {
    loading: false,
    eventList: {
      archivessubeventsdetailsList: [],
      pageable: { totalcount: 3, selectedpagesize: 10, selectedpagenumber: 0 },
    },
  },
  tagList: {
    loading: true,
    tags: {
      tagsList: [],
    },
  },
  daajiMessageList: {
    loading: true,
    messages: {
      contentwithlanguagesupportList: [],
      responsepageable: {
        selectedpagenumber: 0,
        selectedpagesize: 10,
        totalcount: 2,
      },
    },
  },
  homeBannerList: {
    loading: true,
    messages: {
      listofcardsList: [],
      responsepageable: {
        selectedpagenumber: 0,
        selectedpagesize: 10,
        totalcount: 2,
      },
    },
  },
  yatraGardenList: {
    loading: true,
    contents: {
      contentwithlanguagesupportList: [],
      responsepageable: {
        selectedpagenumber: 0,
        selectedpagesize: 10,
        totalcount: 2,
      },
    },
  }
};
export const bhandaraSlice = createSlice({
  name: "bhandaraList",
  initialState,
  reducers: {
    scheduledEventsList: (state, action) => {
      state.events.scheduledEventsList.eventsList = action.payload;
    },
    setEventsListLoading: (state, action) => {
      state.events.scheduledEventsList.loading = action.payload;
    },
    keyLocationsList: (state, action) => {
      state.locations.keyLocations.keyLocationsList = action.payload;
    },
    setKeyLocationListLoading: (state, action) => {
      state.locations.keyLocations.loading = action.payload;
    },
    keyLocationCategories: (state, action) => {
      state.locations.keyLocationCategories.categoriesList = action.payload;
    },
    setAnnouncementsListLoading: (state, action) => {
      state.announcements.announcementsData.loading = action.payload;
    },
    announcementsList: (state, action) => {
      state.announcements.announcementsData.announcementsList = action.payload;
    },
    essesntialsList: (state, action) => {
      state.essestials.essentialsData.essentialsList = action.payload;
    },
    lostAndFoundList: (state, action) => {
      state.lostAndFound.lostAndFoundData.lostAndFoundLData = action.payload;
    },
    lostAndFoundLoading: (state, action) => {
      state.lostAndFound.lostAndFoundData.loading = action.payload;
    },
    archiveListLoading: (state) => {
      state.archive.loading = true;
    },
    setArchiveList: (state, action) => {
      state.archive.archiveList = action.payload;
      state.archive.loading = false;
    },
    archiveListLoadingError: (state) => {
      state.archive.loading = false;
    },
    archiveSubEventListLoading: (state) => {
      state.archiveSubEvent.loading = true;
    },
    setArchiveSubEventList: (state, action) => {
      state.archiveSubEvent.eventList = action.payload;
      state.archiveSubEvent.loading = false;
    },
    archiveSubEventListLoadingError: (state) => {
      state.archiveSubEvent.loading = false;
    },
    tagsListLoading(state, action) {
      state.tagList.loading = action.payload;
    },
    setTagsList(state, action) {
      state.tagList.loading = false;
      state.tagList.tags = action.payload;
    },
    tagsListError(state) {
      state.tagList.loading = false;
    },
    daajiMessageListLoading(state, action) {
      state.daajiMessageList.loading = action.payload || true;
    },
    setDaajiMessageList(state, action) {
      state.daajiMessageList.loading = false;
      state.daajiMessageList.messages = action.payload;
    },
    daajiMessageListError(state) {
      state.daajiMessageList.loading = false;
    },
    yatraGardenListLoading(state, action) {
      state.yatraGardenList.loading = action.payload || true;
    },
    setYatraGardenList(state, action) {
      state.yatraGardenList.loading = false;
      state.yatraGardenList.contents = action.payload;
    },
    yatraGardenListError(state) {
      state.yatraGardenList.loading = false;
    },
    homeBannerListLoading(state, action) {
      state.homeBannerList.loading = action.payload || true;
    },
    setHomeBannerList(state, action) {
      state.homeBannerList.loading = false;
      state.homeBannerList.messages = action.payload;
    },
    homeBannerListError(state) {
      state.homeBannerList.loading = false;
    },
  },
});

export const {
  scheduledEventsList,
  setEventsListLoading,
  keyLocationsList,
  setKeyLocationListLoading,
  keyLocationCategories,
  setAnnouncementsListLoading,
  announcementsList,
  essesntialsList,
  lostAndFoundList,
  lostAndFoundLoading,
  archiveListLoading,
  setArchiveList,
  archiveListLoadingError,
  archiveSubEventListLoading,
  setArchiveSubEventList,
  archiveSubEventListLoadingError,
  tagsListLoading,
  setTagsList,
  tagsListError,
  daajiMessageListLoading,
  setDaajiMessageList,
  daajiMessageListError,
  homeBannerListLoading,
  setHomeBannerList,
  homeBannerListError,
  yatraGardenListLoading,
  setYatraGardenList,
  yatraGardenListError

} = bhandaraSlice.actions;

export default bhandaraSlice.reducer;
