// source: Goal.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var common_common_pb = require('./common/common_pb.js');
goog.object.extend(proto, common_common_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.goal.AddNewGoalRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.goal.Goal', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.goal.GoalDetails', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.goal.Goal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.goal.Goal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.goal.Goal.displayName = 'proto.unifiedplatform.gamification.goal.Goal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.goal.AddNewGoalRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.goal.AddNewGoalRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.goal.AddNewGoalRequest.displayName = 'proto.unifiedplatform.gamification.goal.AddNewGoalRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.goal.GoalDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.goal.GoalDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.goal.GoalDetails.displayName = 'proto.unifiedplatform.gamification.goal.GoalDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest.displayName = 'proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.goal.Goal.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.goal.Goal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.goal.Goal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.goal.Goal.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    days: jspb.Message.getFieldWithDefault(msg, 2, 0),
    durationlimitindays: jspb.Message.getFieldWithDefault(msg, 3, 0),
    typeofmeditationassociated: jspb.Message.getFieldWithDefault(msg, 4, 0),
    associatedbadgeid: jspb.Message.getFieldWithDefault(msg, 5, 0),
    title: jspb.Message.getFieldWithDefault(msg, 6, ""),
    subtitle: jspb.Message.getFieldWithDefault(msg, 7, ""),
    badgecount: jspb.Message.getFieldWithDefault(msg, 8, 0),
    expirydates: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.goal.Goal}
 */
proto.unifiedplatform.gamification.goal.Goal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.goal.Goal;
  return proto.unifiedplatform.gamification.goal.Goal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.goal.Goal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.goal.Goal}
 */
proto.unifiedplatform.gamification.goal.Goal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDays(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDurationlimitindays(value);
      break;
    case 4:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.MeditationType} */ (reader.readEnum());
      msg.setTypeofmeditationassociated(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAssociatedbadgeid(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubtitle(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBadgecount(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setExpirydates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.goal.Goal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.goal.Goal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.goal.Goal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.goal.Goal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDays();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getDurationlimitindays();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getTypeofmeditationassociated();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getAssociatedbadgeid();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSubtitle();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getBadgecount();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getExpirydates();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.goal.Goal.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.goal.Goal} returns this
 */
proto.unifiedplatform.gamification.goal.Goal.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 days = 2;
 * @return {number}
 */
proto.unifiedplatform.gamification.goal.Goal.prototype.getDays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.goal.Goal} returns this
 */
proto.unifiedplatform.gamification.goal.Goal.prototype.setDays = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 durationLimitInDays = 3;
 * @return {number}
 */
proto.unifiedplatform.gamification.goal.Goal.prototype.getDurationlimitindays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.goal.Goal} returns this
 */
proto.unifiedplatform.gamification.goal.Goal.prototype.setDurationlimitindays = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional unifiedplatform.heartintune.common.MeditationType typeOfMeditationAssociated = 4;
 * @return {!proto.unifiedplatform.heartintune.common.MeditationType}
 */
proto.unifiedplatform.gamification.goal.Goal.prototype.getTypeofmeditationassociated = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.MeditationType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.MeditationType} value
 * @return {!proto.unifiedplatform.gamification.goal.Goal} returns this
 */
proto.unifiedplatform.gamification.goal.Goal.prototype.setTypeofmeditationassociated = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int64 associatedBadgeID = 5;
 * @return {number}
 */
proto.unifiedplatform.gamification.goal.Goal.prototype.getAssociatedbadgeid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.goal.Goal} returns this
 */
proto.unifiedplatform.gamification.goal.Goal.prototype.setAssociatedbadgeid = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string title = 6;
 * @return {string}
 */
proto.unifiedplatform.gamification.goal.Goal.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.goal.Goal} returns this
 */
proto.unifiedplatform.gamification.goal.Goal.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string subTitle = 7;
 * @return {string}
 */
proto.unifiedplatform.gamification.goal.Goal.prototype.getSubtitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.goal.Goal} returns this
 */
proto.unifiedplatform.gamification.goal.Goal.prototype.setSubtitle = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional uint32 badgeCount = 8;
 * @return {number}
 */
proto.unifiedplatform.gamification.goal.Goal.prototype.getBadgecount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.goal.Goal} returns this
 */
proto.unifiedplatform.gamification.goal.Goal.prototype.setBadgecount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 expiryDates = 9;
 * @return {number}
 */
proto.unifiedplatform.gamification.goal.Goal.prototype.getExpirydates = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.goal.Goal} returns this
 */
proto.unifiedplatform.gamification.goal.Goal.prototype.setExpirydates = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.goal.AddNewGoalRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.goal.AddNewGoalRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.goal.AddNewGoalRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.goal.AddNewGoalRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    days: jspb.Message.getFieldWithDefault(msg, 1, 0),
    durationlimitindays: jspb.Message.getFieldWithDefault(msg, 2, 0),
    typeofmeditationassociated: jspb.Message.getFieldWithDefault(msg, 3, 0),
    associatedbadgeid: jspb.Message.getFieldWithDefault(msg, 4, 0),
    title: jspb.Message.getFieldWithDefault(msg, 5, ""),
    subtitle: jspb.Message.getFieldWithDefault(msg, 6, ""),
    badgecount: jspb.Message.getFieldWithDefault(msg, 7, 0),
    expirydays: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.goal.AddNewGoalRequest}
 */
proto.unifiedplatform.gamification.goal.AddNewGoalRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.goal.AddNewGoalRequest;
  return proto.unifiedplatform.gamification.goal.AddNewGoalRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.goal.AddNewGoalRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.goal.AddNewGoalRequest}
 */
proto.unifiedplatform.gamification.goal.AddNewGoalRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDays(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDurationlimitindays(value);
      break;
    case 3:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.MeditationType} */ (reader.readEnum());
      msg.setTypeofmeditationassociated(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAssociatedbadgeid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubtitle(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBadgecount(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setExpirydays(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.goal.AddNewGoalRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.goal.AddNewGoalRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.goal.AddNewGoalRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.goal.AddNewGoalRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDays();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getDurationlimitindays();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getTypeofmeditationassociated();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getAssociatedbadgeid();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSubtitle();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBadgecount();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getExpirydays();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
};


/**
 * optional uint32 days = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.goal.AddNewGoalRequest.prototype.getDays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.goal.AddNewGoalRequest} returns this
 */
proto.unifiedplatform.gamification.goal.AddNewGoalRequest.prototype.setDays = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 durationLimitInDays = 2;
 * @return {number}
 */
proto.unifiedplatform.gamification.goal.AddNewGoalRequest.prototype.getDurationlimitindays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.goal.AddNewGoalRequest} returns this
 */
proto.unifiedplatform.gamification.goal.AddNewGoalRequest.prototype.setDurationlimitindays = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional unifiedplatform.heartintune.common.MeditationType typeOfMeditationAssociated = 3;
 * @return {!proto.unifiedplatform.heartintune.common.MeditationType}
 */
proto.unifiedplatform.gamification.goal.AddNewGoalRequest.prototype.getTypeofmeditationassociated = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.MeditationType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.MeditationType} value
 * @return {!proto.unifiedplatform.gamification.goal.AddNewGoalRequest} returns this
 */
proto.unifiedplatform.gamification.goal.AddNewGoalRequest.prototype.setTypeofmeditationassociated = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int64 associatedBadgeID = 4;
 * @return {number}
 */
proto.unifiedplatform.gamification.goal.AddNewGoalRequest.prototype.getAssociatedbadgeid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.goal.AddNewGoalRequest} returns this
 */
proto.unifiedplatform.gamification.goal.AddNewGoalRequest.prototype.setAssociatedbadgeid = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string title = 5;
 * @return {string}
 */
proto.unifiedplatform.gamification.goal.AddNewGoalRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.goal.AddNewGoalRequest} returns this
 */
proto.unifiedplatform.gamification.goal.AddNewGoalRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string subTitle = 6;
 * @return {string}
 */
proto.unifiedplatform.gamification.goal.AddNewGoalRequest.prototype.getSubtitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.goal.AddNewGoalRequest} returns this
 */
proto.unifiedplatform.gamification.goal.AddNewGoalRequest.prototype.setSubtitle = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional uint32 badgeCount = 7;
 * @return {number}
 */
proto.unifiedplatform.gamification.goal.AddNewGoalRequest.prototype.getBadgecount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.goal.AddNewGoalRequest} returns this
 */
proto.unifiedplatform.gamification.goal.AddNewGoalRequest.prototype.setBadgecount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 expiryDays = 8;
 * @return {number}
 */
proto.unifiedplatform.gamification.goal.AddNewGoalRequest.prototype.getExpirydays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.goal.AddNewGoalRequest} returns this
 */
proto.unifiedplatform.gamification.goal.AddNewGoalRequest.prototype.setExpirydays = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.goal.GoalDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.goal.GoalDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.goal.GoalDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.goal.GoalDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    days: jspb.Message.getFieldWithDefault(msg, 2, 0),
    durationlimitindays: jspb.Message.getFieldWithDefault(msg, 3, 0),
    typeofmeditationassociated: jspb.Message.getFieldWithDefault(msg, 4, 0),
    associatedbadgeid: jspb.Message.getFieldWithDefault(msg, 5, 0),
    title: (f = msg.getTitle()) && common_common_pb.Locale.toObject(includeInstance, f),
    subtitle: (f = msg.getSubtitle()) && common_common_pb.Locale.toObject(includeInstance, f),
    badgecount: jspb.Message.getFieldWithDefault(msg, 8, 0),
    expirydays: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.goal.GoalDetails}
 */
proto.unifiedplatform.gamification.goal.GoalDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.goal.GoalDetails;
  return proto.unifiedplatform.gamification.goal.GoalDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.goal.GoalDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.goal.GoalDetails}
 */
proto.unifiedplatform.gamification.goal.GoalDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDays(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDurationlimitindays(value);
      break;
    case 4:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.MeditationType} */ (reader.readEnum());
      msg.setTypeofmeditationassociated(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAssociatedbadgeid(value);
      break;
    case 6:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setTitle(value);
      break;
    case 7:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setSubtitle(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBadgecount(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setExpirydays(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.goal.GoalDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.goal.GoalDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.goal.GoalDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.goal.GoalDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDays();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getDurationlimitindays();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getTypeofmeditationassociated();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getAssociatedbadgeid();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getTitle();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getSubtitle();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getBadgecount();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getExpirydays();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.goal.GoalDetails.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.goal.GoalDetails} returns this
 */
proto.unifiedplatform.gamification.goal.GoalDetails.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 days = 2;
 * @return {number}
 */
proto.unifiedplatform.gamification.goal.GoalDetails.prototype.getDays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.goal.GoalDetails} returns this
 */
proto.unifiedplatform.gamification.goal.GoalDetails.prototype.setDays = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 durationLimitInDays = 3;
 * @return {number}
 */
proto.unifiedplatform.gamification.goal.GoalDetails.prototype.getDurationlimitindays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.goal.GoalDetails} returns this
 */
proto.unifiedplatform.gamification.goal.GoalDetails.prototype.setDurationlimitindays = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional unifiedplatform.heartintune.common.MeditationType typeOfMeditationAssociated = 4;
 * @return {!proto.unifiedplatform.heartintune.common.MeditationType}
 */
proto.unifiedplatform.gamification.goal.GoalDetails.prototype.getTypeofmeditationassociated = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.MeditationType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.MeditationType} value
 * @return {!proto.unifiedplatform.gamification.goal.GoalDetails} returns this
 */
proto.unifiedplatform.gamification.goal.GoalDetails.prototype.setTypeofmeditationassociated = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int64 associatedBadgeID = 5;
 * @return {number}
 */
proto.unifiedplatform.gamification.goal.GoalDetails.prototype.getAssociatedbadgeid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.goal.GoalDetails} returns this
 */
proto.unifiedplatform.gamification.goal.GoalDetails.prototype.setAssociatedbadgeid = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional unifiedplatform.heartintune.common.Locale title = 6;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.goal.GoalDetails.prototype.getTitle = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 6));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.goal.GoalDetails} returns this
*/
proto.unifiedplatform.gamification.goal.GoalDetails.prototype.setTitle = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.goal.GoalDetails} returns this
 */
proto.unifiedplatform.gamification.goal.GoalDetails.prototype.clearTitle = function() {
  return this.setTitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.goal.GoalDetails.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale subTitle = 7;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.goal.GoalDetails.prototype.getSubtitle = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 7));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.goal.GoalDetails} returns this
*/
proto.unifiedplatform.gamification.goal.GoalDetails.prototype.setSubtitle = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.goal.GoalDetails} returns this
 */
proto.unifiedplatform.gamification.goal.GoalDetails.prototype.clearSubtitle = function() {
  return this.setSubtitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.goal.GoalDetails.prototype.hasSubtitle = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional uint32 badgeCount = 8;
 * @return {number}
 */
proto.unifiedplatform.gamification.goal.GoalDetails.prototype.getBadgecount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.goal.GoalDetails} returns this
 */
proto.unifiedplatform.gamification.goal.GoalDetails.prototype.setBadgecount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 expiryDays = 9;
 * @return {number}
 */
proto.unifiedplatform.gamification.goal.GoalDetails.prototype.getExpirydays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.goal.GoalDetails} returns this
 */
proto.unifiedplatform.gamification.goal.GoalDetails.prototype.setExpirydays = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    days: jspb.Message.getFieldWithDefault(msg, 1, 0),
    durationlimitindays: jspb.Message.getFieldWithDefault(msg, 2, 0),
    typeofmeditationassociated: jspb.Message.getFieldWithDefault(msg, 3, 0),
    associatedbadgeid: jspb.Message.getFieldWithDefault(msg, 4, 0),
    title: (f = msg.getTitle()) && common_common_pb.Locale.toObject(includeInstance, f),
    subtitle: (f = msg.getSubtitle()) && common_common_pb.Locale.toObject(includeInstance, f),
    badgecount: jspb.Message.getFieldWithDefault(msg, 7, 0),
    expirydays: jspb.Message.getFieldWithDefault(msg, 8, 0),
    meditationcategory: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest}
 */
proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest;
  return proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest}
 */
proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDays(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDurationlimitindays(value);
      break;
    case 3:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.MeditationType} */ (reader.readEnum());
      msg.setTypeofmeditationassociated(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAssociatedbadgeid(value);
      break;
    case 5:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setTitle(value);
      break;
    case 6:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setSubtitle(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBadgecount(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setExpirydays(value);
      break;
    case 9:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.MeditationCategory} */ (reader.readEnum());
      msg.setMeditationcategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDays();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getDurationlimitindays();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getTypeofmeditationassociated();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getAssociatedbadgeid();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getTitle();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getSubtitle();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getBadgecount();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getExpirydays();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getMeditationcategory();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
};


/**
 * optional uint32 days = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest.prototype.getDays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest} returns this
 */
proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest.prototype.setDays = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 durationLimitInDays = 2;
 * @return {number}
 */
proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest.prototype.getDurationlimitindays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest} returns this
 */
proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest.prototype.setDurationlimitindays = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional unifiedplatform.heartintune.common.MeditationType typeOfMeditationAssociated = 3;
 * @return {!proto.unifiedplatform.heartintune.common.MeditationType}
 */
proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest.prototype.getTypeofmeditationassociated = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.MeditationType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.MeditationType} value
 * @return {!proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest} returns this
 */
proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest.prototype.setTypeofmeditationassociated = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int64 associatedBadgeID = 4;
 * @return {number}
 */
proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest.prototype.getAssociatedbadgeid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest} returns this
 */
proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest.prototype.setAssociatedbadgeid = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional unifiedplatform.heartintune.common.Locale title = 5;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest.prototype.getTitle = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 5));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest} returns this
*/
proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest.prototype.setTitle = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest} returns this
 */
proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest.prototype.clearTitle = function() {
  return this.setTitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale subTitle = 6;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest.prototype.getSubtitle = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 6));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest} returns this
*/
proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest.prototype.setSubtitle = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest} returns this
 */
proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest.prototype.clearSubtitle = function() {
  return this.setSubtitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest.prototype.hasSubtitle = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional uint32 badgeCount = 7;
 * @return {number}
 */
proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest.prototype.getBadgecount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest} returns this
 */
proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest.prototype.setBadgecount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 expiryDays = 8;
 * @return {number}
 */
proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest.prototype.getExpirydays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest} returns this
 */
proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest.prototype.setExpirydays = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional unifiedplatform.heartintune.common.MeditationCategory meditationCategory = 9;
 * @return {!proto.unifiedplatform.heartintune.common.MeditationCategory}
 */
proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest.prototype.getMeditationcategory = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.MeditationCategory} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.MeditationCategory} value
 * @return {!proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest} returns this
 */
proto.unifiedplatform.gamification.goal.AddNewGoalDetailsRequest.prototype.setMeditationcategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


goog.object.extend(exports, proto.unifiedplatform.gamification.goal);
